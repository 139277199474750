import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import {
  trigger,
  transition,
  style,
  animate,
  state,
} from '@angular/animations';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { addItemToCart } from 'src/app/redux/actions/cart.actions';
import { setRoute } from '../../redux/actions/router.actions';
import { EditComicDialogComponent } from 'src/app/dialogs/edit-comic-dialog/edit-comic-dialog.component';
import { CollectibleService } from 'src/app/services/collectible.service';
import { selectedCollectible } from 'src/app/redux/actions/collecible.actions';
import { takeUntil } from 'rxjs/operators';
import { SellSimilarCollectibleDialogComponent } from 'src/app/dialogs/sell-similar-collectible-dialog/sell-similar-collectible-dialog.component';
import { AddWishlistCollectibleDialogComponent } from 'src/app/dialogs/add-wishlist-collectible-dialog/add-wishlist-collectible-dialog.component';

interface AppState {
  comics: any;
  userProfile: User;
  auth: User;
  collectibles: any;
}

@Component({
  selector: 'app-view-collectible',
  templateUrl: './view-collectible.component.html',
  styleUrls: ['./view-collectible.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        state('invisible', style({ opacity: 0 })),
        style({ opacity: 0 }),
        animate(800, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ViewCollectibleComponent implements OnInit {
  public user: Observable<User>;
  public authUser;
  public authId;
  public collectible: any;
  allCollectibles;
  filteredCollectibles;
  filteredBrand;
  shuffledCollectibles;
  noCollectibles;
  noMatchingBrand;
  private onDestroy$ = new Subject<void>();
  public selectedImage: any;
  public isLoggedInWithStripe: boolean;
  public loading = false;
  public sameUniverse = 1;

  constructor(
    private store: Store<AppState>,
    private mdDialog: MatDialog,
    private router: Router,
    private matSnackbar: MatSnackBar,
    private collectibleService: CollectibleService
  ) {
    this.store
      .select('collectibles')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        this.collectible = res.selectedCollectible;
        this.allCollectibles = res;
      });
    this.user = this.store.select('userProfile');
    this.store.select('auth').subscribe(res => {
      if (res) {
        this.authUser = res;
        this.authId = res.id;
      }
    });
    if (!this.authUser) {
      this.authUser = 'GuestUser';
      this.isLoggedInWithStripe = false;
    }
    if (this.authUser !== 'GuestUser') {
      this.isLoggedInWithStripe = this.authUser && this.authUser.stripeId ? true : false;
    }
  }

  ngOnInit() {
    this.store.dispatch(setRoute({ route: this.router.url }));
    this.selectedImage = this.collectible.images[0];
    this.loadPage();
  }

  loadPage() {
    if (this.allCollectibles.collectibles.length === 0) {
      this.noCollectibles = true;
      this.noMatchingBrand = true;
    }
    this.shuffledCollectibles = this.shuffle(this.allCollectibles.collectibles);
    this.filteredCollectibles = this.shuffle(
      this.allCollectibles.collectibles.filter((toy) => {
        return (
          toy.universe === this.collectible.universe &&
          toy.id !== this.collectible.id
          // toy.quantity > 0 &&
          // toy.ownerId !== this.authId
        );
      })
    );
    this.filteredBrand = this.shuffle(
      this.allCollectibles.collectibles.filter((toy) => {
        return (
          toy.brand === this.collectible.brand &&
          toy.id !== this.collectible.id &&
          toy.universe !== this.collectible.universe
          // toy.quantity > 0 &&
          // toy.ownerId !== this.authId
        );
      })
    );
    if (this.filteredCollectibles.length === 0) {
      this.noCollectibles = true;
    }
    if (this.filteredBrand.length === 0) {
      this.noMatchingBrand = true;
    }
  }

  refreshPage() {
    this.gotoTop();
    this.collectibleService
      .getAllCollectibles()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        this.allCollectibles = res;
        if (this.allCollectibles.collectibles.length === 0) {
          this.noCollectibles = true;
          this.noMatchingBrand = true;
        }
        this.shuffledCollectibles = this.shuffle(this.allCollectibles.collectibles);
        this.filteredCollectibles = this.shuffle(
          this.allCollectibles.collectibles.filter((toy) => {
            return (
              toy.universe === this.collectible.universe &&
              toy.id !== this.collectible.id
              // toy.quantity > 0 &&
              // toy.ownerId !== this.authId
            );
          })
        );
        this.filteredBrand = this.shuffle(
          this.allCollectibles.collectibles.filter((toy) => {
            return (
              toy.brand === this.collectible.brand &&
              toy.id !== this.collectible.id &&
              toy.universe !== this.collectible.universe
              // toy.quantity > 0 &&
              // toy.ownerId !== this.authId
            );
          })
        );
        if (this.filteredCollectibles.length === 0) {
          this.noCollectibles = true;
        }
        if (this.filteredBrand.length === 0) {
          this.noMatchingBrand = true;
        }
      });
  }

  async goToUsersProfile(selectedUser) {
    this.router.navigateByUrl(`/profile/${selectedUser.username}`);
  }

  submit() {
    this.store.dispatch(
      addItemToCart({ product: this.collectible, shipping: this.collectible })
    );
    this.matSnackbar.open(
      `You added "${this.collectible.name}" to your cart`,
      'OK',
      {
        duration: 5000,
        panelClass: ['cvSnack'],
      }
    );
  }

  goToEditComics() {
    const dialogRef = this.mdDialog.open(EditComicDialogComponent, {
      data: this.collectible,
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        this.ngOnInit();
      });
  }

  goToViewToy(toy) {
    this.handleSelectedImage(toy.images[0]);
    this.store.dispatch(selectedCollectible({ id: toy.id }));
    this.router.navigateByUrl('/collectible');
    this.refreshPage();
  }

  shuffle(list) {
    return list.reduce((p, n) => {
      const size = p.length;
      const index = Math.trunc(Math.random() * (size - 1));
      p.splice(index, 0, n);
      return p;
    }, []);
  }

  handleSelectedImage = (image) => {
    this.selectedImage = image;
  }

  goToSellSimilarCollectible() {
    const dialogRef = this.mdDialog.open(
      SellSimilarCollectibleDialogComponent,
      {
        data: this.collectible,
        autoFocus: false,
        panelClass: 'custom-dialog-container',
        disableClose: true,
      }
    );
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => { });
  }

  goToWishlistCollectible() {
    const dialogRef = this.mdDialog.open(
      AddWishlistCollectibleDialogComponent,
      {
        data: this.collectible,
        autoFocus: false,
        panelClass: 'custom-dialog-container',
        disableClose: true,
      }
    );
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => { });
  }

  gotoTop() {
    document.getElementById('comic-container').scrollIntoView();
  }
}
