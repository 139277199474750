export const allBrands = [
  "Bachmann Industries",
  "Ertl Company‎",
  "Hasbro",
  "Ideal Toy Company‎",
  "Mattel‎",
  "Tonka",
  "Toyfinity",
  "A. C. Gilbert Company",
  "Alexander Doll Company",
  "All Metal Products Company",
  "American Character Doll Company",
  "Annalee Dolls",
  "Applause",
  "Artifact Puzzles",
  "Aurora Plastics Corporation",
  "Azrak-Hamway",
  "Baby Einstein",
  "Bachmann Industries",
  "Berkeley Models",
  "Bif Bang Pow!",
  "Big Monster Toys",
  "Bleacher Creatures",
  "Bowen Designs",
  "Bradford Exchange",
  "Buddy L",
  "Build-A-Bear Workshop",
  "Bunnies By The Bay",
  "CelebriDucks",
  "Chessex",
  "Child World",
  "Circus World",
  "Coleco",
  "Colorforms",
  "Crayola",
  "Creative Playthings",
  "Deluxe Reading",
  "Diamond Select Toys",
  "Discovery Toys",
  "Dispensa's Castle of Toys",
  "Doepke Toys",
  "Dorfan",
  "Duncan Toys Company",
  "Eldon",
  "Entertainment Earth",
  "Entex Industries",
  "EToys.com",
  "Explore Technologies",
  "FAO Schwarz",
  "Fat Brain Toys",
  "Ferdinand Strauss Company",
  "Fisher-Price",
  "Four Horsemen Studios",
  "Fundex Games",
  "Funko",
  "Galoob",
  "Garton Toy Company",
  "Gendron, Inc.",
  "GIANTmicrobes",
  "GoldieBlox",
  "Greenlight Collectibles",
  "Paul K. Guillow, Inc.",
  "Gund",
  "Hasbro",
  "Hess Corporation",
  "Ideal Toy Company",
  "J. Chein & Company",
  "Jakks Pacific",
  "Juno Baby",
  "Juro Novelty Company",
  "K'Nex",
  "Kahootz Toys",
  "KB Toys",
  "Kenner Products",
  "Kid Brands",
  "Kids II, Inc.",
  "Kids Preferred",
  "Larami",
  "LeapFrog Enterprises",
  "Learning Express Toys",
  "Liberty Puzzles",
  "Lionel Kiddie City",
  "Little Laureate",
  "Little Tikes",
  "Louis Marx and Company",
  "Maco",
  "Manoil Manufacturing Co.",
  "Maple Landmark Woodcraft",
  "Marvin Glass and Associates",
  "Mary Meyer Corporation",
  "Mattel",
  "McFarlane Toys",
  "Mega Brands America",
  "Mego Corporation",
  "Melissa & Doug",
  "Mezco Toyz",
  "MGA Entertainment",
  "Muttpop",
  "Nakajima USA",
  "National Entertainment Collectibles Association",
  "National Sewing Machine Company",
  "Nelsonic Industries",
  "Noodle Kidoodle",
  "Ohio Art Company",
  "Ollimania",
  "Onell Design",
  "Palisades Toys",
  "Parker Brothers",
  "Parris Manufacturing Company",
  "Patch Products",
  "Performo Toy Company",
  "Plan B Toys",
  "Play Along Toys",
  "Play Arts KAI",
  "Porter Chemical Company",
  "Pressman Toy Corporation",
  "Pyro Plastics Corporation",
  "R. John Wright Dolls",
  "Radica Games",
  "Radio Flyer",
  "Rainbow Crafts",
  "RC2 Corporation",
  "Remco",
  "Ridemakerz",
  "Robot Galaxy",
  "Rokenbok",
  "Roominate",
  "Round 2",
  "S.W. Randall Toyes and Giftes",
  "Schaper Toys",
  "Schoenhut Piano Company",
  "Shindana Toys",
  "Shocker Toys",
  "Sideshow Collectibles",
  "Sifo Company",
  "Slinky",
  "SOTA Toys",
  "Spectre Studios",
  "Sphero",
  "Squeezable Skylines",
  "Stave Puzzles",
  "TDC Games",
  "Tegu",
  "The Armory",
  "ThinkFun",
  "Tiger Electronics",
  "Tonka",
  "Tonner Doll Company",
  "Toy Biz",
  "Toy Vault",
  "Toyfinity",
  "Toynami",
  "Toys 'R' Us",
  "Transogram",
  "Trendmasters",
  "Tru Kids",
  "Ty",
  "Vermont Teddy Bear Company",
  "Wham-O",
  "Wonder Workshop",
  "Worlds of Wonder",
  "Zizzle",
];
