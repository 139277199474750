import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { Subject } from 'rxjs';
import { OptOutDialogComponent } from 'src/app/dialogs/opt-out-dialog/opt-out-dialog.component';
import { TermsOfServiceDialogComponent } from 'src/app/dialogs/terms-of-service-dialog/terms-of-service-dialog.component';
import { Store } from '@ngrx/store';
import { setRoute } from 'src/app/redux/actions/router.actions';
import { Router } from '@angular/router';

interface AppState {
  auth: any;
}

@Component({
  selector: 'app-origin',
  templateUrl: './origin.component.html',
  styleUrls: ['./origin.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        state('invisible', style({ opacity: 0 })),
        style({ opacity: 0 }),
        animate(800, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class OriginComponent implements OnInit {
  private onDestroy$ = new Subject<void>();

  constructor(
    private mdDialog: MatDialog,
    private store: Store<AppState>,
    private router: Router) { }

  ngOnInit() {
    this.store.dispatch(setRoute({ route: this.router.url }));
    window.scrollTo(0, 0);
  }

  goToOptOut() {
    this.mdDialog.open(OptOutDialogComponent);
  }

  goToTermsOfService() {
    this.mdDialog.open(TermsOfServiceDialogComponent);
  }

}
