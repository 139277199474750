// tslint:disable: quotemark
export const allCharacters = [
  "Abin Sur",
  "Abra Kadabra",
  "Adam Strange",
  "Alfred Pennyworth",
  "Alice - Elizabeth Kane",
  "Amanda Waller",
  "Amazo",
  "Ambush Bug",
  "Amethyst",
  "Andrew Bennett",
  "Animal Man",
  "Animal-Vegetable-Mineral Man",
  "Aquagirl (Lorena Marquez)",
  "Aquaman",
  "Ares (DC)",
  "Artemis",
  "Atrocitus",
  "Astra Logue",
  "The Atom",
  "Azrael",
  "Aqualad",
  "Apache chief",
  "Arsenal",
  "Archer",
  "Ava Sharpe",
  "Bane",
  "Batgirl",
  "Batman",
  "Batwoman",
  "Bat-Mite",
  "Beast Boy",
  "Bizarro",
  "Big Barda",
  "Billy Numerous",
  "Black Adam",
  "Black Beetle",
  "Black Canary",
  "Black Hand",
  "Black Lightning",
  "Black Manta",
  "Black Mask",
  "Blok",
  "Blue Beetle (Jaime Reyes)",
  "Blue Devil",
  "Booster Gold",
  "Bouncing Boy",
  "Brain",
  "Brainiac",
  "Brother Blood",
  "Bumblebee",
  "B'wana Beast",
  "Black Flash(Jay Garrick)",
  "Black vulcan",
  "Baby face",
  "Batman of zur-en arrh",
  "Bleez",
  "Bookwarm",
  "Cain",
  "Calendar Man",
  "Captain Atom",
  "Captain Boomerang",
  "Captain Comet",
  "Captain Carrot",
  "Captain Cold",
  "Captain Marvel (DC)",
  "Catherine Hamilton-Kane",
  "Carol Ferris (Star Sapphire)",
  "Catwoman",
  "Chameleon Boy",
  "Channel",
  "Cheetah",
  "Chemo",
  "Cheshire",
  "Circe",
  "Clayface",
  "Cosmic Boy",
  "Cosmic King",
  "Cyborg",
  "Cyborg Superman",
  "Copperhead",
  "Crazy quilt",
  "Colonel Hardy",
  "Condiment king",
  "Catman",
  "Clocking",
  "Darkseid",
  "Dawnstar",
  "Deadshot",
  "Deathstroke",
  "Desperaux",
  "Dr. Death",
  "Dr. Fate",
  "Dr. Light",
  "Dr. Manhattan",
  "Dr. Martin Stein",
  "Dr. Psycho",
  "Dr. Sivana",
  "Dolphin",
  "Doomsday",
  "Dream Girl",
  "Duo Damsel",
  "Dex-starr",
  "Dr. Phosphorus",
  "Elasti-Girl (Doom Patrol)",
  "Element Lad",
  "Elongated Man",
  "Etrigan",
  "Enchantress",
  "Eraser",
  "Egghead",
  "Flash (Barry Allen)",
  "Fire",
  "Firefly",
  "the Fly",
  "Firestorm",
  "Frankenstein (DC)",
  "The Flash(Jay Garrick)",
  "False face",
  "Fury 6",
  "Green Lantern",
  "Guy Gardner",
  "Green Arrow",
  "Gorilla Grodd",
  "Granny Goodness",
  "Giganta",
  "Gideon",
  "General Immortus",
  "General Zod",
  "Gizmo",
  "Great white shark",
  "Gentleman ghost",
  "Grifter",
  "Hercules",
  "Huntress",
  "Hawkgirl",
  "Hawkman",
  "Hawk & Dove",
  "Harley Quinn",
  "Hush",
  "Heatwave",
  "Hellhound",
  "Hitman",
  "Haggor",
  "Humpty dumpty",
  "Hugo strange",
  "Ice",
  "Inertia",
  "Invisible Boy",
  "Iris West-Allen",
  "Jacob Kane",
  "Jaguar",
  'Jefferson Jax" Jackson',
  "Jericho",
  "Jimmy Olsen",
  "Jinx",
  "John Constantine",
  "John Diggle",
  "Johnny Thunder",
  "John Stewart",
  "Jonah Hex",
  "Joker",
  "Joker's Daughter",
  "Katana",
  "Kilowog",
  "Kid Flash",
  "Kid Devil",
  "Killer Croc",
  "Krypto",
  "Kryptonite Man",
  "Killer Frost",
  "Key",
  "Karate Kid",
  "Kite man",
  "King tut",
  "Killermoth",
  "Kabuki twins",
  "Lady Blackhawk",
  "Larfleeze",
  "Lex Luthor",
  "Libra",
  "Light Lass",
  "Lightning Lad",
  "Lightning Lord",
  "Livewire",
  "Lobo",
  "Louie the lilac",
  "Lagoon boy",
  "Lucius Fox",
  "Luke Fox",
  "Lucifer",
  "Mar Novu / Monitor",
  "Martian Manhunter",
  "Mr. Terrific",
  "Mister Miracle",
  "Mon-El",
  "Metamorpho",
  "Metal Men",
  "Man-Bat",
  "Metallo",
  "Mr. Mxyzsptlk",
  "Mirror master",
  "Mad Hatter",
  "Mongul",
  "Mera",
  "Mr. Freeze",
  "Mr. Mind",
  "Miss Martian",
  "Madame Rouge",
  "Monseiur Mallah",
  "Music Meister",
  "Mas y Menos",
  "Mammoth",
  "Mother Mae Eye",
  "Mr. Camera",
  "Ministrel",
  "March Harriet",
  "Magpie",
  "Mime",
  "Maxie zeus",
  "Mona Wu",
  "Mobius / Anti-Monitor",
  "Nightwing",
  "Nightrunner",
  "Nora Darhk",
  "Oracle",
  "Ocean Master",
  "Outsider",
  "Orion",
  "Omac brother eye",
  "Orca",
  "Power Girl",
  "professor pyg",
  "Poison Ivy",
  "Penguin",
  "Parallax",
  "Plastique",
  "Parasite",
  "Plastic Man",
  "Princess Projecta",
  "Polka dot man",
  "Prometheus",
  "Question",
  "Queen Bee",
  "Ragdoll",
  "Ra's Al Ghul",
  "Ravager",
  "Raven",
  "Razorsharp",
  "Red Bee",
  "Red Hood",
  "Red Tornado",
  "Red X",
  "Riddler",
  "Rip Hunter",
  "Robin",
  "Rocket Red",
  "Robotman",
  "Reverse Flash",
  "Ratchet",
  "Ratcatcher",
  "ragman",
  "Red Robin",
  "Sophie Moore",
  "Static Shock",
  "Sandman (Wesley Dodds)",
  "Superman",
  "Supergirl & Streaky",
  "Sinestro",
  "Star Girl",
  "Scarecrow",
  "Steel",
  "Spectre",
  "Shark King",
  "Sherlock Holmes",
  "Starfire",
  "Superboy (Connor Kent )",
  "Spoiler",
  "Speedy",
  "Solomon Grundy",
  "Shazam",
  "Simon",
  "Shadow Thief",
  "Shade",
  "Swamp Thing",
  "Static Shock",
  "See-More",
  "Saturn Girl",
  "Saturn Queen",
  "Sun Boy",
  "Shadow Lass",
  "Saint Walker",
  "Shame",
  "Skallox",
  "Samurai",
  "Sweet tooth",
  "Siren",
  "Simon the pieman",
  "Starfire",
  "talia al ghul",
  "thunder",
  "Tarzan",
  "Two-Face",
  "Trickster",
  "Trigon",
  "Tweedle Dee & Tweedle Dum",
  "Toyman",
  "Timber Wolf",
  "Turtle",
  "Tarantula",
  "Ultra-Humanite",
  "Ultra Boy",
  "Vandal Savage",
  "Ventriloquist and Scarface",
  "Vixen",
  "Vibe",
  "Veom",
  "Vice",
  "Wally West",
  "Weather Wizard",
  "Wildcat",
  "Wildebeest",
  "Wildfire",
  "Wonder Girl",
  "Wonder Woman",
  "White Canary",
  "The Wasp",
  "Zari Tomas",
  "Zatanna",
  "Zatara",
  "Zoom",
  "Zarrell",
  "Zodiac master",
  "Zilius zox",
  "Zebra man",
  "Zod",
  "A-Bomb (HAS)",
  "A.I.M.",
  "Aaron Stack",
  "Abomination (Emil Blonsky)",
  "Abomination (Ultimate)",
  "Absorbing Man",
  "Abyss",
  "Abyss (Age of Apocalypse)",
  "Adam Destine",
  "Adam Warlock",
  "Aegis (Trey Rollins)",
  "Agent Brand",
  "Agent X (Nijo)",
  "Agent Zero",
  "Agents of Atlas",
  "Aginar",
  "Air-Walker (Gabriel Lan)",
  "Ajak",
  "Ajaxis",
  "Akemi",
  "Alain",
  "Albert Cleary",
  "Albion",
  "Alex Power",
  "Alex Wilder",
  "Alexa Mendez",
  "Alexander Pierce",
  "Alice",
  "Alicia Masters",
  "Alpha Flight",
  "Alpha Flight (Ultimate)",
  "Alvin Maker",
  "Amadeus Cho",
  "Amanda Sefton",
  "Amazoness",
  "American Eagle (Jason Strongbow)",
  "Amiko",
  "Amora",
  "Amphibian (Earth-712)",
  "Amun",
  "Ancient One",
  "Ancient One (Ultimate)",
  "Angel (Thomas Halloway)",
  "Angel (Ultimate)",
  "Angel (Warren Worthington III)",
  "Angela (Aldrif Odinsdottir)",
  "Anita Blake",
  "Anne Marie Hoag",
  "Annihilus",
  "Anole",
  "Ant-Man (Eric O'Grady)",
  "Ant-Man (Scott Lang)",
  "Anthem",
  "Apocalypse",
  "Apocalypse (Ultimate)",
  "Aqueduct",
  "Arachne",
  "Araña",
  "Arcade",
  "Arcana",
  "Archangel",
  "Arclight",
  "Ares",
  "Argent",
  "Armadillo",
  "Armor (Hisako Ichiki)",
  "Armory",
  "Arnim Zola",
  "Arsenic",
  "Artiee",
  "Asgardian",
  "Askew-Tronics",
  "Asylum",
  "Atlas (Team)",
  "Aurora",
  "Avalanche",
  "Avengers",
  "Avengers (Ultimate)",
  "Azazel (Mutant)",
  "Banshee",
  "Banshee (Theresa Rourke)",
  "Baron Strucker",
  "Baron Zemo (Heinrich Zemo)",
  "Baron Zemo (Helmut Zemo)",
  "Baroness S'Bak",
  "Barracuda",
  "Bart Rozum",
  "Bastion",
  "Batroc the Leaper",
  "Battering Ram",
  "Beak",
  "Beast",
  "Beast (Earth-311)",
  "Beast (Ultimate)",
  "Becatron",
  "Bedlam",
  "Beef",
  "Beetle (Abner Jenkins)",
  "Ben Grimm",
  "Ben Parker",
  "Ben Reilly",
  "Ben Urich",
  "Bengal",
  "Beta-Ray Bill",
  "Betty Brant",
  "Betty Ross",
  "Beyonder",
  "Bi-Beast",
  "Big Bertha",
  "Big Wheel",
  "Bill Hollister",
  "Bishop",
  "Bishop (Ultimate)",
  "Black Bird",
  "Black Bolt",
  "Black Bolt (Marvel War of Heroes)",
  "Black Cat",
  "Black Cat (Ultimate)",
  "Black Crow",
  "Black Knight (Sir Percy of Scandia)",
  "Black Panther",
  "Black Panther (Ultimate)",
  "Black Queen",
  "Black Tarantula",
  "Black Tom",
  "Black Widow",
  "Black Widow (LEGO Marvel Super Heroes)",
  "Black Widow (Ultimate)",
  "Black Widow/Natasha Romanoff (MAA)",
  "Blackheart",
  "Blacklash",
  "Blackout",
  "Blade",
  "Blastaar",
  "Blazing Skull",
  "Blindfold",
  "Blink",
  "Blizzard",
  "Blob",
  "Blob (Ultimate)",
  "Blockbuster",
  "Blok",
  "Bloke",
  "Blonde Phantom",
  "Bloodaxe",
  "Bloodscream",
  "Bloodstorm",
  "Bloodstrike",
  "Blue Blade",
  "Blue Marvel",
  "Blue Shield",
  "Blur",
  "Bob, Agent of Hydra",
  "Boom Boom",
  "Boomer",
  "Boomerang",
  "Box",
  "Bride of Nine Spiders (Immortal Weapons)",
  "Bromley",
  "Brood",
  "Brother Voodoo",
  "Brotherhood of Evil Mutants",
  "Brotherhood of Mutants (Ultimate)",
  "Bruce Banner",
  "Brute",
  "Bucky",
  "Bug",
  "Bulldozer",
  "Bullseye",
  "Bushwacker",
  "Butterfly",
  "Cable",
  "Cable (Deadpool)",
  "Cable (Marvel: Avengers Alliance)",
  "Cable (Ultimate)",
  "Cable (X-Men: Battle of the Atom)",
  "Calamity",
  "Caliban",
  "Callisto",
  "Callisto (Age of Apocalypse)",
  "Calypso",
  "Cammi",
  "Cannonball",
  "Cap'n Oz",
  "Captain America",
  "Captain America (House of M)",
  "Captain America (LEGO Marvel Super Heroes)",
  "Captain America (Marvel War of Heroes)",
  "Captain America (Sam Wilson)",
  "Captain America (Ultimate)",
  "Captain America/Steve Rogers (MAA)",
  "Captain Britain",
  "Captain Britain (Ultimate)",
  "Captain Cross",
  "Captain Flint",
  "Captain Marvel (Carol Danvers)",
  "Captain Marvel (Genis-Vell)",
  "Captain Marvel (Mar-Vell)",
  "Captain Marvel (Monica Rambeau)",
  "Captain Marvel (Phyla-Vell)",
  "Captain Midlands",
  "Captain Stacy",
  "Captain Universe",
  "Cardiac",
  "Caretaker",
  "Cargill",
  "Carlie Cooper",
  "Carmella Unuscione",
  "Carnage",
  "Carnage (Ultimate)",
  "Carol Danvers",
  "Carol Hines",
  "Cassandra Nova",
  "Catseye",
  "Cecilia Reyes",
  "Celestials",
  "Centennial",
  "Centurions",
  "Cerebro",
  "Cerise",
  "Ch'od",
  "Chamber",
  "Chameleon",
  "Champions",
  "Changeling",
  "Charles Xavier",
  "Charlie Campion",
  "Chase Stein",
  "Chat",
  "Chimera",
  "Chores MacGillicudy",
  "Christian Walker",
  "Chronomancer",
  "ClanDestine",
  "Clea",
  "Clea (Ultimate)",
  "Clint Barton",
  "Cloak",
  "Cloud 9",
  "Cobalt Man",
  "Colleen Wing",
  "Colonel America",
  "Colossus",
  "Colossus (Ultimate)",
  "Confederates of the Curious",
  "Constrictor",
  "Contessa (Vera Vidal)",
  "Controller",
  "Cornelius",
  "Corsair",
  "Cosmo (dog)",
  "Cottonmouth",
  "Count Nefaria",
  "Countess",
  "Crimson Crusader",
  "Crimson Dynamo",
  "Crimson Dynamo (Iron Man 3 - The Official Game)",
  "Crimson King",
  "Crossbones",
  "Crule",
  "Crusher Hogan",
  "Crusher Hogan (Ultimate)",
  "Crystal",
  "Cuckoo",
  "Curt Conners",
  "Cuthbert",
  "Cyber",
  "Cyclops",
  "Cyclops (Ultimate)",
  "Cyclops (X-Men: Battle of the Atom)",
  "Cypher",
  "D'Ken Neramani",
  "Dagger",
  "Daily Bugle",
  "Daimon Hellstrom",
  "Daken",
  "Dakota North",
  "Damage Control",
  "Dani Moonstar",
  "Danny Rand",
  "Daredevil",
  "Daredevil (LEGO Marvel Super Heroes)",
  "Daredevil (Marvel Heroes)",
  "Daredevil (Ultimate)",
  "Dargo Ktor",
  "Dark Avengers",
  "Dark Beast",
  "Dark Phoenix",
  "Dark X-Men",
  "Darkhawk",
  "Darkstar",
  "Darwin",
  "Dazzler",
  "Dazzler (Ultimate)",
  "Deacon Frost",
  "Dead Girl",
  "Deadpool",
  "Deadpool (Deadpool)",
  "Deadpool (LEGO Marvel Super Heroes)",
  "Deadpool (X-Men: Battle of the Atom)",
  "Death",
  "Deathbird",
  "Deathcry",
  "Deathlok",
  "Deathstrike (Ultimate)",
  "Debra Whitman",
  "Debrii",
  "Deena Pilgrim",
  "Defenders",
  "Demogoblin",
  "Destiny",
  "Detective Soap",
  "Deviants",
  "Devil Dinosaur (Devil Dinosaur)",
  "Devil Dinosaur (HAS)",
  "Devos",
  "Dexter Bennett",
  "Diablo",
  "Diamondback (Rachel Leighton)",
  "Dinah Soar",
  "Dirk Anger",
  "Doc Samson",
  "Doctor Doom",
  "Doctor Doom (Ultimate)",
  "Doctor Faustus",
  "Doctor Octopus",
  "Doctor Octopus (Ultimate)",
  "Doctor Spectrum",
  "Doctor Strange",
  "Doctor Strange (Ultimate)",
  "Dog Brother #1",
  "Domino",
  "Donald Blake",
  "Doomsday Man",
  "Doop",
  "Doorman",
  "Dorian Gray",
  "Dormammu",
  "Dormammu (Ultimate)",
  "Dr. Strange (Marvel: Avengers Alliance)",
  "Dracula",
  "Dragon Lord",
  "Dragon Man",
  "Drax",
  "Dreadnoughts",
  "Dreaming Celestial",
  "Druig",
  "Dum Dum Dugan",
  "Dust",
  "Earthquake",
  "Echo",
  "Eddie Brock",
  "Eddie Lau",
  "Edward 'Ted' Forrester",
  "Edwin Jarvis",
  "Ego",
  "Electro",
  "Electro (Ultimate)",
  "Elektra",
  "Elektra (Ultimate)",
  "Elements of Doom",
  "Elite",
  "Elixir",
  "Elloe Kaifi",
  "Elsa Bloodstone",
  "Emma Frost",
  "Empath",
  "Emplate",
  "Enchantress (Amora)",
  "Enchantress (Sylvie Lushton)",
  "Ender Wiggin",
  "Energizer",
  "Epoch",
  "Erik the Red",
  "Eternals",
  "Eternity",
  "Excalibur",
  "Exiles",
  "Exodus",
  "Expediter",
  "Ezekiel",
  "Ezekiel Stane",
  "Fabian Cortez",
  "Falcon",
  "Falcon/Sam Wilson (MAA)",
  "Fallen One",
  "Famine",
  "Fantastic Four",
  "Fantastic Four (Ultimate)",
  "Fantastick Four",
  "Fantomex",
  "Fat Cobra",
  "Felicia Hardy",
  "Fenris",
  "Feral",
  "Fin Fang Foom",
  "Firebird",
  "Firebrand",
  "Firedrake",
  "Firelord",
  "Firestar",
  "Firestar (Ultimate)",
  "Fixer (Paul Norbert Ebersol)",
  "Flatman",
  "Flying Dutchman",
  "Foggy Nelson",
  "Force Works",
  "Forearm",
  "Forge",
  "Forge (Ultimate)",
  "Forgotten One",
  "Frank Castle",
  "Frankenstein's Monster",
  "Franklin Richards",
  "Franklin Storm",
  "Freak",
  "Frightful Four",
  "Frog Thor",
  "Frog-Man",
  "Gabe Jones",
  "Galactus",
  "Galia",
  "Gambit",
  "Gamma Corps",
  "Gamora",
  "Gamora (Marvel War of Heroes)",
  "Gargoyle",
  "Gargoyle (Yuri Topolov)",
  "Garia",
  "Garrison Kane",
  "Gateway",
  "Gauntlet (Joseph Green)",
  "Geiger",
  "Gene Sailors",
  "Generation X",
  "Genesis",
  "Genis-Vell",
  "George Stacy (Ultimate)",
  "Gertrude Yorkes",
  "Ghost Rider (Daniel Ketch)",
  "Ghost Rider (Johnny Blaze)",
  "Ghost Rider (Marvel War of Heroes)",
  "Giant Girl",
  "Giant Man",
  "Giant-dok",
  "Giant-Man (Ultimate)",
  "Gideon",
  "Git Hoskins",
  "Gladiator (Kallark)",
  "Gladiator (Melvin Potter)",
  "Glenn Talbot",
  "Glorian",
  "Goblin Queen",
  "Golden Guardian",
  "Goliath (Bill Foster)",
  "Gorgon",
  "Gorilla Man",
  "Grandmaster",
  "Gravity",
  "Great Lakes Avengers",
  "Green Goblin (Barry Norman Osborn)",
  "Green Goblin (Harry Osborn)",
  "Green Goblin (Ultimate)",
  "Gressill",
  "Grey Gargoyle",
  "Greymalkin",
  "Grim Reaper",
  "Groot",
  "Guardian",
  "Guardians of the Galaxy",
  "Guardsmen",
  "Gunslinger",
  "GW Bridge",
  "Gwen Stacy",
  "Gwen Stacy (Ultimate)",
  "H.A.M.M.E.R.",
  "H.E.R.B.I.E.",
  "Hairball",
  "Half-Life (Tony Masterson)",
  "Hammerhead",
  "Hammerhead (Ultimate)",
  "Hank Pym",
  "Hannibal King",
  "Happy Hogan",
  "Hardball",
  "Harley Davidson Cooper",
  "Harpoon",
  "Harrier",
  "Harry Heck",
  "Harry Osborn",
  "Harry Osborn (Ultimate)",
  "Hate-Monger (Adolf Hitler)",
  "Havok",
  "Hawkeye",
  "Hawkeye (Kate Bishop)",
  "Hawkeye (Marvel Heroes)",
  "Hawkeye (Ultimate)",
  "Hawkeye/Clint Barton (MAA)",
  "Hedge Knight",
  "Hellcat (Patsy Walker)",
  "Hellfire Club",
  "Hellfire Club (Ultimate)",
  "Hellion",
  "Hellions (Squad)",
  "Hemingway",
  "Henry Peter Gyrich",
  "Hepzibah",
  "Hercules",
  "Heroes For Hire",
  "Hex",
  "High Evolutionary",
  "Hindsight Lad",
  "Hiroim",
  "Hitman",
  "Hitomi Sakuma",
  "Hobgoblin (Jason Macendale)",
  "Hobgoblin (Robin Borne)",
  "Hobgoblin (Roderick Kingsley)",
  "Holocaust (Age of Apocalypse)",
  "Holy",
  "Hope Summers",
  "Howard Saint",
  "Howard The Duck",
  "Hulk",
  "Hulk (HAS)",
  "Hulk (LEGO Marvel Super Heroes)",
  "Hulk (Marvel Zombies)",
  "Hulk (Marvel: Avengers Alliance)",
  "Hulk (Ultimate)",
  "Hulk-dok",
  "Hulk/Bruce Banner (MAA)",
  "Hulkling",
  "Human Cannonball",
  "Human Fly (Richard Deacon)",
  "Human Robot",
  "Human Torch",
  "Human Torch (Jim Hammond)",
  "Human Torch (Ultimate)",
  "Humbug",
  "Husk",
  "Hussar",
  "Hydra",
  "Hydro-Man",
  "Hyperion (Earth-712)",
  "Hypno-Hustler",
  "Iceman",
  "Iceman (Ultimate)",
  "Iceman (X-Men: Battle of the Atom)",
  "Ikaris",
  "Illuminati",
  "Ilyana Rasputin",
  "Imp",
  "Imperfects",
  "Imperial Guard",
  "Impossible Man",
  "In-Betweener",
  "Inertia",
  "Infant Terrible",
  "Inhumans",
  "Ink",
  "Invaders",
  "Invisible Woman",
  "Invisible Woman (Marvel: Avengers Alliance)",
  "Invisible Woman (Ultimate)",
  "Iron Cross Army",
  "Iron Fist (Bei Bang-Wen)",
  "Iron Fist (Danny Rand)",
  "Iron Fist (Orson Randall)",
  "Iron Fist (Quan Yaozu)",
  "Iron Fist (USM)",
  "Iron Fist (Wu Ao-Shi)",
  "Iron Lad",
  "Iron Man",
  "Iron Man (Iron Man 3 - The Official Game)",
  "Iron Man (LEGO Marvel Super Heroes)",
  "Iron Man (Marvel Heroes)",
  "Iron Man (Marvel War of Heroes)",
  "Iron Man (Ultimate)",
  "Iron Man/Tony Stark (MAA)",
  "Iron Monger",
  "Iron Patriot",
  "Iron Patriot (James Rhodes)",
  "Ironclad",
  "J. Jonah Jameson",
  "Jack Flag",
  "Jack Murdock",
  "Jack O' Lantern",
  "Jack Power",
  "Jackal",
  "Jackpot",
  "James Buchanan Barnes",
  "James Howlett",
  "Jamie Braddock",
  "Jane Foster",
  "Janus, the Nega-Man",
  "Jasper Sitwell",
  "Jazinda",
  "Jean Grey",
  "Jean Grey",
  "Jean Grey (Ultimate)",
  "Jennifer Smith",
  "Jeryn Hogarth",
  "Jessica Drew",
  "Jessica Jones",
  "Jetstream",
  "Jigsaw",
  "Jimmy Woo",
  "Joan the Mouse",
  "Jocasta",
  "John Farson",
  "John Jameson",
  "John Porter",
  "John Wraith",
  "Johnny Blaze",
  "Johnny Storm",
  "Joseph",
  "Joshua Kane",
  "Josiah X",
  "Joystick",
  "Jubilee",
  "Jubilee (Age of Apocalypse)",
  "Juggernaut",
  "Jule Carpenter",
  "Julian Keller",
  "Junta",
  "Justice",
  "Justin Hammer",
  "Ka-Zar",
  "Kabuki",
  "Kang",
  "Karen O'Malley",
  "Karen Page",
  "Karma",
  "Karnak",
  "Karolina Dean",
  "Kat Farrell",
  "Kate Bishop",
  "Katie Power",
  "Ken Ellis",
  "Khan",
  "Kid Colt",
  "Killer Shrike",
  "Killmonger",
  "Killraven",
  "King Bedlam",
  "King Cobra",
  "Kingpin",
  "Kinsey Walden",
  "Kitty Pryde",
  "Kitty Pryde (X-Men: Battle of the Atom)",
  "Klaw",
  "Komodo (Melati Kusuma)",
  "Korath",
  "Korg",
  "Korvac",
  "Kraven the Hunter",
  "Kree",
  "Krista Starr",
  "Kronos",
  "Kulan Gath",
  "Kylun",
  "La Nuit",
  "Lady Bullseye",
  "Lady Deathstrike",
  "Lady Mastermind",
  "Lady Ursula",
  "Lady Vermin",
  "Lake",
  "Landau",
  "Lava-Man",
  "Layla Miller",
  "Leader",
  "Leech",
  "Legion",
  "Lei Kung, The Thunderer",
  "Lenny Balinger",
  "Leo (Zodiac)",
  "Leopardon",
  "Leper Queen",
  "Lester",
  "Lethal Legion",
  "Lieutenant Marcus Stone",
  "Lifeguard",
  "Lightning Lords of Nepal",
  "Lightspeed",
  "Lila Cheney",
  "Lilandra",
  "Lilith",
  "Lily Hollister",
  "Lionheart",
  "Living Lightning",
  "Living Mummy",
  "Living Tribunal",
  "Liz Osborn",
  "Lizard",
  "Lizard (Ultimate)",
  "Loa",
  "Lockheed",
  "Lockjaw",
  "Logan",
  "Loki",
  "Loki (LEGO Marvel Super Heroes)",
  "Loners",
  "Longshot",
  "Longshot (Ultimate)",
  "Lord Hawal",
  "Lord Tyger",
  "Lords of Avalon",
  "Lorna Dane",
  "Luckman",
  "Lucky Pierre",
  "Lucy in the Sky",
  "Luke Cage",
  "Luminals",
  "Lyja",
  "M (Monet St. Croix)",
  "M.O.D.A.M.",
  "M.O.D.O.G.",
  "M.O.D.O.K.",
  "M.O.D.O.K. (Iron Man 3 - The Official Game)",
  "Ma Gnuci",
  "Mac Gargan",
  "Mach IV",
  "Machine Man",
  "Mad Thinker",
  "Madame Hydra",
  "Madame Masque",
  "Madame Web (Julia Carpenter)",
  "Maddog",
  "Madelyne Pryor",
  "Madripoor",
  "Madrox",
  "Maelstrom",
  "Maestro",
  "Magdalene",
  "Maggott",
  "Magik (Amanda Sefton)",
  "Magik (Illyana Rasputin)",
  "Maginty",
  "Magma (Amara Aquilla)",
  "Magneto",
  "Magneto (Age of Apocalypse)",
  "Magneto (House of M)",
  "Magneto (Ultimate)",
  "Magneto (X-Men: Battle of the Atom)",
  "Magus (Adam Warlock)",
  "Magus (Technarch)",
  "Major Mapleleaf",
  "Makkari",
  "Malcolm Colcord",
  "Malice (Earth-161)",
  "Man-Thing",
  "Man-Wolf",
  "Mandarin",
  "Mandrill",
  "Mandroid",
  "Manta",
  "Mantis",
  "Marauders",
  "Marcus Van Sciver",
  "Maria Hill",
  "Mariko Yashida",
  "Marrow",
  "Marten Broadcloak",
  "Martin Li",
  "Marvel Apes",
  "Marvel Boy",
  "Marvel Zombies",
  "Marvex",
  "Mary Jane Watson",
  "Mary Jane Watson (House of M)",
  "Mary Jane Watson (Ultimate)",
  "Masked Marvel (Unrevealed)",
  "Masque",
  "Master Chief",
  "Master Mold",
  "Mastermind",
  "Masters of Evil",
  "Mathemanic",
  "Matsu'o Tsurayaba",
  "Matthew Murdock",
  "Mattie Franklin",
  "Mauler",
  "Maverick (Chris Bradley)",
  "Maverick (Christoph Nord)",
  "Maximus",
  "May Parker",
  "Medusa",
  "Meggan",
  "Meltdown",
  "Menace",
  "Mentallo",
  "Mentor",
  "Mephisto",
  "Mephistopheles",
  "Mercury",
  "Mesmero",
  "Metal Master",
  "Meteorite",
  "MI: 13",
  "Micro/Macro",
  "Microbe",
  "Microchip",
  "Micromax",
  "Midnight (Earth-811)",
  "Miek",
  "Mikhail Rasputin",
  "Millenium Guard",
  "Millie the Model",
  "Mimic",
  "Mindworm",
  "Miracleman",
  "Miss America",
  "Mister Fear",
  "Mister Sinister",
  "Mister Sinister (Deadpool)",
  "Mister Sinister (House of M)",
  "Mister Sinister (Ultimate)",
  "Misty Knight",
  "Mockingbird",
  "Moira MacTaggert",
  "Moira MacTaggert (Ultimate)",
  "Mojo",
  "Mole Man",
  "Molecule Man",
  "Molly Hayes",
  "Molly Von Richtofen",
  "Molten Man",
  "Mongoose",
  "Mongu (Unrevealed)",
  "Monster Badoon",
  "Moon Girl (Lunella Layfayette)",
  "Moon Knight",
  "Moon Knight (House of M)",
  "Moon Knight (Ultimate)",
  "Moondragon",
  "Moonstone",
  "Morbius",
  "Mordo",
  "Morg",
  "Morgan Stark",
  "Morlocks",
  "Morlun",
  "Morph",
  "Mother Askani",
  "Mr. Bumpo",
  "Mr. Fantastic",
  "Mr. Fantastic (Ultimate)",
  "Mr. Fish",
  "Mr. Fixit",
  "Mr. Hyde",
  "Mr. Immortal",
  "Mr. Meugniot",
  "Mr. Negative",
  "Mr. Payback",
  "Mr. X",
  "Ms. Marvel (Kamala Khan)",
  "MS2",
  "Mulholland Black",
  "Multiple Man",
  "MVP",
  "Mysterio",
  "Mysterio (Daniel Berkhart)",
  "Mysterio (Francis Klum)",
  "Mystique",
  "Mystique (Age of Apocalypse)",
  "Mystique (House of M)",
  "Mystique (Ultimate)",
  "Nakia (Nakia)",
  "Namor",
  "Namora",
  "Namorita",
  "Naoko",
  "Natasha Romanoff",
  "Nebula",
  "Nehzno",
  "Nekra",
  "Nemesis",
  "Network",
  "New Goblin",
  "New Mutants",
  "New Warriors",
  "New X-Men",
  "Newton Destine",
  "Next Avengers",
  "Nextwave",
  "Nick Fury",
  "Nick Fury (LEGO Marvel Super Heroes)",
  "Nick Fury (Ultimate)",
  "Nico Minoru",
  "Nicolaos",
  "Night Nurse (Earth-9997)",
  "Night Thrasher",
  "Night Thrasher (Dwayne Taylor)",
  "Nightcrawler",
  "Nightcrawler (Ultimate)",
  "Nighthawk",
  "Nightmare",
  "Nightshade",
  "Nine-Fold Daughters of Xao",
  "Nitro",
  "Nocturne",
  "Nomad",
  "Nomad (Rikki Barnes)",
  "Nomad (Steve Rogers)",
  "Norman Osborn",
  "Norrin Radd",
  "Northstar",
  "Nova",
  "Nova (Frankie Raye)",
  "Nova (Sam Alexander)",
  "Nova (USM)",
  "Nuke",
  "Obadiah Stane",
  "Odin",
  "Ogun",
  "Okoye",
  "Old Lace",
  "Omega Flight",
  "Omega Red",
  "Omega Sentinel",
  "Omega the Unknown",
  "Onslaught",
  "Onslaught (Ultimate)",
  "Oracle",
  "Ord",
  "Orphan",
  "Orphan-Maker",
  "Otto Octavius",
  "Outlaw Kid",
  "Overlord",
  "Owl",
  "Ozymandias",
  "Paibok",
  "Paladin",
  "Pandemic",
  "Paper Doll",
  "Patch",
  "Patriot",
  "Payback",
  "Penance (Robert Baldwin)",
  "Pepper Potts",
  "Pestilence",
  "Pet Avengers",
  "Pete Wisdom",
  "Peter Parker",
  "Peter Quill",
  "Phalanx",
  "Phantom Reporter",
  "Phil Coulson (Phil Coulson)",
  "Phil Sheldon",
  "Photon",
  "Phyla-Vell",
  "Piledriver",
  "Pip",
  "Pixie",
  "Plazm",
  "Polaris",
  "Post",
  "Power Man (USM)",
  "Power Pack",
  "Praxagora",
  "Preak",
  "Pretty Boy",
  "Pride",
  "Prima",
  "Prince of Orphans",
  "Princess Powerful",
  "Prism",
  "Prodigy",
  "Proemial Gods",
  "Professor Monster",
  "Professor X",
  "Professor X (Ultimate)",
  "Proteus",
  "Proteus (House of M)",
  "Proteus (Ultimate)",
  "Proudstar",
  "Prowler",
  "Prowler (Rick Lawson)",
  "Psycho-Man",
  "Psylocke",
  "PsyNapse",
  "Puck",
  "Puck (Zuzha Yu)",
  "Puff Adder",
  "pug",
  "Puma",
  "Punisher",
  "Punisher (2099)",
  "Punisher (Marvel: Avengers Alliance)",
  "Puppet Master",
  "Purifiers",
  "Purple Man",
  "Pyro",
  "Quake (Daisy Johnson)",
  "Quasar (Phyla-Vell)",
  "Quasar (Wendell Vaughn)",
  "Quasimodo",
  "Queen Noir",
  "Quentin Quire",
  "Quicksilver",
  "Quicksilver (Age of Apocalypse)",
  "Quicksilver (Ultimate)",
  "Rachel Grey",
  "Radioactive Man",
  "Rafael Vega",
  "Rage",
  "Raider",
  "Randall",
  "Randall Flagg",
  "Random",
  "Rattler",
  "Ravenous",
  "Rawhide Kid",
  "Raza",
  "Reaper",
  "Reavers",
  "Reavers (Ultimate)",
  "Red 9",
  "Red Ghost",
  "Red Ghost (Ultimate)",
  "Red Hulk",
  "Red Hulk (HAS)",
  "Red She-Hulk",
  "Red Shift",
  "Red Skull",
  "Red Skull (Albert Malik)",
  "Red Wolf",
  "Redwing",
  "Reptil",
  "Retro Girl",
  "Revanche",
  "Rhino",
  "Rhodey",
  "Richard Fisk",
  "Rick Jones",
  "Rick Jones (Ultimate)",
  "Ricochet",
  "Rictor",
  "Riptide",
  "Risque",
  "Robbie Robertson",
  "Robert Baldwin",
  "Robin Chapel",
  "Rocket Raccoon",
  "Rocket Raccoon (Marvel Heroes)",
  "Rocket Racer",
  "Rockslide",
  "Rogue",
  "Rogue (Age of Apocalypse)",
  "Rogue (Deadpool)",
  "Rogue (Ultimate)",
  "Rogue (X-Men: Battle of the Atom)",
  "Roland Deschain",
  "Romulus",
  "Ronan",
  "Roughhouse",
  "Roulette",
  "Roxanne Simpson",
  "Rumiko Fujikawa",
  "Runaways",
  "Russian",
  "S.H.I.E.L.D.",
  "Sabra",
  "Sabretooth",
  "Sabretooth (Age of Apocalypse)",
  "Sabretooth (House of M)",
  "Sabretooth (Ultimate)",
  "Sage",
  "Salem's Seven (Ultimate)",
  "Sally Floyd",
  "Salo",
  "Sandman",
  "Santa Claus",
  "Saracen (Muzzafar Lambert)",
  "Sasquatch (Walter Langkowski)",
  "Satana",
  "Sauron",
  "Scalphunter",
  "Scarecrow (Ebenezer Laughton)",
  "Scarlet Spider (Ben Reilly)",
  "Scarlet Spider (Kaine)",
  "Scarlet Witch",
  "Scarlet Witch (Age of Apocalypse)",
  "Scarlet Witch (Marvel Heroes)",
  "Scarlet Witch (Ultimate)",
  "Scorpion (Carmilla Black)",
  "Scorpion (Ultimate)",
  "Scourge",
  "Scrambler",
  "Scream (Donna Diego)",
  "Screwball",
  "Sebastian Shaw",
  "Secret Warriors",
  "Selene",
  "Senator Kelly",
  "Sentinel",
  "Sentinels",
  "Sentry (Robert Reynolds)",
  "Ser Duncan",
  "Serpent Society",
  "Sersi",
  "Shadow King",
  "Shadow King (Age of Apocalypse)",
  "Shadowcat",
  "Shadowcat (Age of Apocalypse)",
  "Shadowcat (Ultimate)",
  "Shadu the Shady",
  "Shalla-bal",
  "Shaman",
  "Shane Yamada-Jones",
  "Shang-Chi",
  "Shang-Chi (Ultimate)",
  "Shanna the She-Devil",
  "Shape",
  "Shard",
  "Sharon Carter",
  "Sharon Ventura",
  "Shatterstar",
  "She-Hulk (HAS)",
  "She-Hulk (Jennifer Walters)",
  "She-Hulk (Lyra)",
  "She-Hulk (Marvel War of Heroes)",
  "She-Hulk (Ultimate)",
  "Shen",
  "Sheva Callister",
  "Shi'Ar",
  "Shinko Yamashiro",
  "Shinobi Shaw",
  "Shiva",
  "Shiver Man",
  "Shocker (Herman Schultz)",
  "Shockwave",
  "Shooting Star",
  "Shotgun",
  "Shriek",
  "Sif",
  "Silhouette",
  "Silk Fever",
  "Silver Centurion",
  "Silver Fox",
  "Silver Sable",
  "Silver Samurai",
  "Silver Samurai (Age of Apocalypse)",
  "Silver Surfer",
  "Silverclaw",
  "Silvermane",
  "Sin",
  "Sinister Six",
  "Sir Ram",
  "Siren (Earth-93060)",
  "Sister Grimm",
  "Skaar",
  "Skaar (HAS)",
  "Skin",
  "Skreet",
  "Skrulls",
  "Skrulls (Ultimate)",
  "Skullbuster (Cylla Markham)",
  "Slapstick",
  "Slayback",
  "Sleeper",
  "Sleepwalker",
  "Slipstream",
  "Slyde",
  "Smasher (Vril Rokk)",
  "Smiling Tiger",
  "Snowbird",
  "Solo (James Bourne)",
  "Songbird",
  "Sons of the Tiger",
  "Spacker Dave",
  "Spectrum",
  "Speed",
  "Speed Demon",
  "Speedball (Robert Baldwin)",
  "Spencer Smythe",
  "Sphinx (Anath-Na Mut)",
  "Spider-dok",
  "Spider-Girl (Anya Corazon)",
  "Spider-Girl (May Parker)",
  "Spider-Gwen (Spider-Gwen)",
  "Spider-Ham (Larval Earth)",
  "Spider-Man",
  "Spider-Man (1602)",
  "Spider-Man (2099)",
  "Spider-Man (Ai Apaec)",
  "Spider-Man (Ben Reilly)",
  "Spider-Man (House of M)",
  "Spider-Man (LEGO Marvel Super Heroes)",
  "Spider-Man (Marvel Zombies)",
  "Spider-Man (Marvel: Avengers Alliance)",
  "Spider-Man (Miles Morales)",
  "Spider-Man (Noir)",
  "Spider-Man (Takuya Yamashiro)",
  "Spider-Man (Ultimate)",
  "Spider-Woman (Charlotte Witter)",
  "Spider-Woman (Jessica Drew)",
  "Spider-Woman (Mattie Franklin)",
  "Spiral (Rita Wayword)",
  "Spirit",
  "Spitfire",
  "Spot",
  "Sprite",
  "Spyke",
  "Squadron Sinister",
  "Squadron Supreme (Earth-712)",
  "Squirrel Girl",
  "Stacy X",
  "Stacy X (Ultimate)",
  "Star Brand",
  "Star-Lord (Peter Quill)",
  "Starbolt",
  "Stardust",
  "Starfox",
  "Starhawk (Stakar Ogord)",
  "Starjammers",
  "Stark Industries",
  "Stature",
  "Steel Serpent (Davos)",
  "Stellaris",
  "Stepford Cuckoos",
  "Stephanie de la Spiroza",
  "Stephen Strange",
  "Steve Rogers",
  "Stick",
  "Stilt-Man (Wibur Day)",
  "Stingray (Walter Newell)",
  "Stone Men",
  "Storm",
  "Storm (Age of Apocalypse)",
  "Storm (Marvel Heroes)",
  "Storm (Ultimate)",
  "Stranger",
  "Strong Guy",
  "Stryfe",
  "Stryfe (Ultimate)",
  "Sub-Mariner",
  "Sue Storm",
  "Sugar Man",
  "Sumo",
  "Sunfire",
  "Sunfire (Age of Apocalypse)",
  "Sunset Bain",
  "Sunspot",
  "Super Hero Squad",
  "Super-Adaptoid",
  "Super-Skrull",
  "Supernaut",
  "Supreme Intelligence",
  "Surge",
  "Susan Delgado",
  "Swarm",
  "Sway",
  "Switch",
  "Swordsman",
  "Swordsman (Jaques Duquesne)",
  "Sym",
  "Synch",
  "T'Challa",
  "Tag",
  "Talisman (Elizabeth Twoyoungmen)",
  "Talkback (Chase Stein)",
  "Talon (Fraternity of Raptors)",
  "Talos",
  "Tana Nile",
  "Tarantula (Luis Alvarez)",
  "Tarot",
  "Taskmaster",
  "Tattoo",
  "Ted Forrester",
  "Tempest",
  "Tenebrous",
  "Terrax",
  "Terror",
  "Texas Twister",
  "Thaddeus Ross",
  "Thanos",
  "Thanos (Ultimate)",
  "The 198",
  "The Anarchist",
  "The Call",
  "The Captain",
  "The Enforcers",
  "The Executioner",
  "The Fallen",
  "The Fury",
  "The Hand",
  "The Hood",
  "The Howling Commandos",
  "The Hunter",
  "The Initiative",
  "The Leader (HAS)",
  "The Liberteens",
  "The Liberty Legion",
  "The Order",
  "The Phantom",
  "The Professor",
  "The Renegades",
  "The Santerians",
  "The Shiver Man",
  "The Spike",
  "The Stranger",
  "The Twelve",
  "The Watchers",
  "Thena",
  "Thing",
  "Thing (Marvel Heroes)",
  "Thing (Ultimate)",
  "Thor",
  "Thor (Goddess of Thunder)",
  "Thor (MAA)",
  "Thor (Marvel Heroes)",
  "Thor (Marvel War of Heroes)",
  "Thor (Marvel: Avengers Alliance)",
  "Thor (Ultimate)",
  "Thor Girl",
  "Thunderball",
  "Thunderbird (John Proudstar)",
  "Thunderbird (Neal Shaara)",
  "Thunderbolt (Bill Carver)",
  "Thunderbolt Ross",
  "Thunderbolts",
  "Thundra",
  "Tiger Shark",
  "Tiger's Beautiful Daughter",
  "Tigra (Greer Nelson)",
  "Timeslip",
  "Tinkerer",
  "Tippy Toe",
  "Titania",
  "Titanium Man (Topolov)",
  "Toad",
  "Toad Men",
  "Tomas",
  "Tombstone",
  "Tomorrow Man",
  "Tony Stark",
  "Toro (Thomas Raymond)",
  "Toxin",
  "Toxin (Eddie Brock)",
  "Trauma",
  "Triathlon",
  "Trish Tilby",
  "Triton",
  "True Believers",
  "Turbo",
  "Tusk",
  "Two-Gun Kid",
  "Tyger Tiger",
  "Typhoid Mary",
  "Tyrannus",
  "U-Foes",
  "U-Go Girl",
  "U.S. Agent",
  "Uatu The Watcher",
  "Ulik",
  "Ultimate Spider-Man (USM)",
  "Ultimates",
  "Ultimatum",
  "Ultimo",
  "Ultra-Adaptoid",
  "Ultragirl (Earth-93060)",
  "Ultron",
  "Umar",
  "Unicorn",
  "Union Jack (Brian Falsworth)",
  "Union Jack (Joseph Chapman)",
  "Union Jack (Montgomery Falsworth)",
  "Unus",
  "Unus (Age of Apocalypse)",
  "Unus (House of M)",
  "Unus (Ultimate)",
  "Valeria Richards",
  "Valkyrie (Samantha Parrington)",
  "Valkyrie (Ultimate)",
  "Vampiro",
  "Vance Astro",
  "Vanisher (Age of Apocalypse)",
  "Vanisher (Telford Porter)",
  "Vanisher (Ultimate)",
  "Vapor",
  "Vargas",
  "Vector",
  "Veda",
  "Vengeance (Michael Badilino)",
  "Venom (Flash Thompson)",
  "Venom (Mac Gargan)",
  "Venom (Ultimate)",
  "Venus (Siren)",
  "Venus Dee Milo",
  "Vermin (Edward Whelan)",
  "Vertigo (Savage Land Mutate)",
  "Victor Mancha",
  "Victor Von Doom",
  "Vin Gonzales",
  "Vindicator",
  "Violations",
  "Viper",
  "Virginia Dare",
  "Vision",
  "Vivisector",
  "Vulcan (Gabriel Summers)",
  "Vulture (Adrian Toomes)",
  "Vulture (Blackie Drago)",
  "Wallflower",
  "Wallop",
  "Wallow",
  "War (Abraham Kieros)",
  "War Machine (Iron Man 3 - The Official Game)",
  "War Machine (Marvel: Avengers Alliance)",
  "War Machine (Parnell Jacobs)",
  "War Machine (Ultimate)",
  "Warbird",
  "Warbound",
  "Warhawk (Mitchell Tanner)",
  "Warlock (Janie Chin)",
  "Warlock (Technarchy)",
  "Warpath",
  "Warren Worthington III",
  "Warstar",
  "Wasp",
  "Wasp (Ultimate)",
  "Weapon Omega",
  "Weapon X",
  "Wendell Rand",
  "Wendell Vaughn",
  "Wendigo",
  "Werewolf By Night",
  "Whiplash (Mark Scarlotti)",
  "Whirlwind",
  "Whistler",
  "White Queen (Adrienne Frost)",
  "White Tiger (Angela Del Toro)",
  "White Tiger (USM)",
  "Whizzer (Stanley Stewart)",
  "Wiccan",
  "Wild Child",
  "Wild Child (Age of Apocalypse)",
  "Wild Pack",
  "Wildside",
  "William Stryker",
  "Wilson Fisk",
  "Wind Dancer",
  "Winter Soldier",
  "Wither",
  "Wolf Cub",
  "Wolfpack",
  "Wolfsbane",
  "Wolfsbane (Age of Apocalypse)",
  "Wolver-dok",
  "Wolverine",
  "Wolverine (LEGO Marvel Super Heroes)",
  "Wolverine (Marvel War of Heroes)",
  "Wolverine (Ultimate)",
  "Wolverine (X-Men: Battle of the Atom)",
  "Wonder Man",
  "Wong",
  "Wong (Ultimate)",
  "Wraith",
  "Wrecker",
  "Wrecking Crew",
  "X-23",
  "X-51",
  "X-Babies",
  "X-Cutioner",
  "X-Factor",
  "X-Factor Investigations",
  "X-Force",
  "X-Man",
  "X-Men",
  "X-Men (Ultimate)",
  "X-Ray (James Darnell)",
  "X-Statix",
  "X.S.E.",
  "Xavin",
  "Xorn (Kuan-Yin Xorn)",
  "Yellow Claw",
  "Yellowjacket (Rita DeMara)",
  "Young Avengers",
  "Young X-Men",
  "Zaladane",
  "Zaran",
  "Zarda",
  "Zarek",
  "Zeigeist",
  "Zemo",
  "Zodiak",
  "Zombie (Simon Garth)",
  "Zuras",
  "Zzzax",
  "3-D Man",
  "Star Wars",
  "Civil War",
  "Conan",
  "Dark Tower",
  "2-1B",
  "4-LOM",
  "8D8",
  "99",
  "0-0-0 (a.k.a. Triple-Zero)",
  "A'Koba",
  "Admiral Gial Ackbar",
  "Sim Aloo ('Imperial Dignitary')",
  "Almec",
  "Mas Amedda",
  "Amee",
  "Padmé Amidala",
  "Cassian Andor",
  "Fodesinbeed Annodue",
  "Raymus Antilles",
  "Wedge Antilles",
  "AP-5",
  "Queen Apailana",
  "Sergeant Appo (CC-1119)",
  "Doctor Chelli Lona Aphra",
  "Faro Argyus",
  "The Armorer",
  "Aiolin and Morit Astarte",
  "Ello Asty",
  "Attichitcuk",
  "AZI-3",
  "Name",
  "Ponda Baba ('Walrus Man')",
  "Kitster Banai",
  "Cad Bane",
  "Darth Bane",
  "Vango Bango",
  "Barada",
  "Jom Barell",
  "Moradmin Bast",
  "BB-8",
  "BB-9E",
  "BD-1",
  "Tobias Beckett",
  "Val Beckett",
  "The Bendu",
  "Shara Bey",
  "Sio Bibble",
  "Depa Billaba",
  "Jar Jar Binks",
  "Temiri Blagg",
  "Zorii Bliss",
  "Commander Bly",
  "Bobbajo",
  "Dud Bolt",
  "Mister Bones",
  "Lux Bonteri",
  "Mina Bonteri",
  "Boolio",
  "Borvo the Hutt",
  "Bossk",
  "Ezra Bridger",
  "BT-1 (a.k.a. Bee-Tee)",
  "Sora Bulq",
  "Burg",
  "C1-10P (a.k.a. 'Chopper')",
  "C-3PO",
  "Caben",
  "Toro Calican",
  "Lando Calrissian",
  "Captain Moden Canady",
  "Ransolm Casterfo",
  "Chewbacca",
  "The Child",
  "Chief Chirpa",
  "The Client",
  "Rush Clovis",
  "Commander Cody",
  "Lieutenant Kaydel Ko Connix",
  "Captain Jeremoch Colton",
  "Cordé",
  "Eno Cordova",
  "Salacious B. Crumb",
  "Arvel Crynyd",
  "Dr. Cylo",
  "D-O",
  "Commander Larma D'Acy",
  "Figrin D'an",
  "Kes Dameron",
  "Poe Dameron",
  "Vober Dand",
  "Joclad Danva",
  "Dapp",
  "Biggs Darklighter",
  "General Oro Dassyne",
  "The Daughter",
  "Davan",
  "Gizor Dellso",
  "Dengar",
  "Bren Derlin",
  "Ima-Gun Di",
  "Rinnrivin Di",
  "Tan Divo",
  "DJ",
  "Lott Dod",
  "Jib Dodger",
  "Jan Dodonna",
  "Daultay Dofine",
  "Lushros Dofine",
  "Dogma",
  "Count Dooku",
  "(Darth Tyranus)",
  "Dormé",
  "Cin Drallig",
  "Garven Dreis (a.k.a. Red Leader)",
  "Greez Dritus",
  "Droidbait (CT-00-2010)",
  "Cara Dune",
  "Rio Durant",
  "Lok Durd",
  "Eirtaé",
  "Dineé Ellberger",
  "Ellé",
  "Caluan Ematt",
  "Embo",
  "Emperor's Royal Guard",
  "Jas Emari",
  "Ebe E. Endocott",
  "Galen Erso",
  "Jyn Erso",
  "Lyra Erso",
  "EV-9D9",
  "Moralo Eval",
  "Doctor Cornelius Evazan",
  "Onaconda Farr",
  "The Father",
  "Boba Fett",
  "Jango Fett",
  "Feral",
  "Commander Fil",
  "Finn",
  "Kit Fisto",
  "Clone Trooper Fives",
  "FN-1824",
  "FN-2003 (a.k.a. 'Slip')",
  "FN-2199 (a.k.a. 'Nines')",
  "Bib Fortuna",
  "Commander Fox",
  "Babu Frik",
  "FX-7",
  "GA-97",
  "Adi Gallia",
  "Gardulla the Hutt",
  "Yarna d'al' Gargan",
  "Garindan (a.k.a. Long Snoot)",
  "Gasgano",
  "Saw Gerrera",
  "Moff Gideon",
  "Protectorate Gleb",
  "Gonk droid (a.k.a. GNK power droid)",
  "Commander Gree",
  "Greedo",
  "Janus Greejatus",
  "Captain Gregor",
  "General Grievous",
  "Grummgar",
  "Gungi",
  "Nute Gunray",
  "Mars Guo",
  "Rune Haako",
  "Hardcase",
  "Rako Hardeen",
  "Gideon Hask",
  "Hevy",
  "San Hill",
  "Clegg Holdfast",
  "Vice Admiral Amilyn Holdo",
  "Tey How",
  "Huyang",
  "Armitage Hux",
  "Brendol Hux",
  "IG-11",
  "IG-88",
  "Chirrut Îmwe",
  "Inquisitors (Inquisitorius)",
  "Grand Inquisitor",
  "Inquisitor: Second Sister (Trilla Suduri)",
  "Inquisitor: Fourth Sister",
  "Inquisitor: Fifth Brother",
  "Inquisitor: Sixth Brother (Bil Valen)",
  "Inquisitor: Seventh Sister",
  "Inquisitor: Eighth Brother",
  "Inquisitor: Ninth Sister (Masana Tide)",
  "Inquisitor: Tenth Brother (Prosset Dibs)",
  "Unnamed red-skinned Inquisitor",
  "Unnamed Twi'lek Inquisitor",
  "Sidon Ithano",
  "Jabba the Hutt",
  "Queen Jamillia",
  "Jannah",
  "Wes Janson",
  "Kanan Jarrus",
  "Jaxxon",
  "Greeata Jendowanian",
  "Moff Tiaan Jerjerrod",
  "Commander Jet",
  "Dexter Jettster",
  "Qui-Gon Jinn",
  "Jira",
  "Jubnuk",
  "Cere Junda",
  "K-2SO",
  "Tee Watt Kaa",
  "General Kalani",
  "Agent Kallus",
  "Harter Kalonia",
  "Maz Kanata",
  "Colonel Kaplan",
  "Karbin",
  "Karina the Great",
  "Greef Karga",
  "Alton Kastle",
  "King Katuunko",
  "Coleman Kcaj",
  "Obi-Wan Kenobi",
  "Cal Kestis",
  "Sash Ketter",
  "Ki-Adi-Mundi",
  "Klaatu",
  "Klaud",
  "Klik-Klak",
  "Derek 'Hobbie' Klivian",
  "Agen Kolar",
  "Plo Koon",
  "Eeth Koth",
  "Sergeant Kreel",
  "Pong Krell",
  "Orson Krennic",
  "Black Krrsantan",
  "Bo-Katan Kryze",
  "Satine Kryze",
  "Kuiil",
  "Conder Kyl",
  "Thane Kyrell",
  "L3-37",
  "L'ulo L'ampar",
  "Aito Laff",
  "Beru Whitesun Lars",
  "Cliegg Lars",
  "Owen Lars",
  "Cut Lawquane",
  "Tasu Leech",
  "Xamuel Lennox",
  "Tallissan 'Tallie' Lintra",
  "Slowen Lo",
  "Lobot",
  "Logray",
  "Lumat",
  "General Crix Madine",
  "Shu Mai",
  "Malakili ('Rancor Keeper')",
  "Baze Malbus",
  "Taron Malicos",
  "Ranzar 'Ran' Malk",
  "Mama the Hutt",
  "The Mandalorian (Din Djarin)",
  "Ody Mandrell",
  "Riot Mar",
  "Darth Maul",
  "Saelt-Marae ('Yak Face')",
  "Mawhonic",
  "Mayfeld",
  "Droopy McCool",
  "Pharl McQuarrie",
  "ME-8D9",
  "Lyn Me",
  "Tion Medon",
  "Del Meeko",
  "Merrin",
  "Aks Moe",
  "Sly Moore",
  "Darth Momin",
  "Morley",
  "Moff Delian Mors",
  "Mon Mothma",
  "Admiral Conan Antonio Motti",
  "Peli Motto",
  "Unnamed Mythrol",
  "Jobal Naberrie",
  "Pooja Naberrie",
  "Ruwee Naberrie",
  "Ryoo Naberrie",
  "Sola Naberrie",
  "Momaw Nadon ('Hammerhead')",
  "Boss Rugor Nass",
  "Captain Lorth Needa",
  "Queen Neeyutnee",
  "Enfys Nest",
  "Bazine Netal",
  "Niima the Hutt",
  "Jocasta Nu",
  "Po Nudo",
  "Nien Nunb",
  "Has Obbit",
  "Ochi",
  "Barriss Offee",
  "Hondo Ohnaka",
  "Ric Olié",
  "Oma Tres",
  "Omera",
  "Omi",
  "Ketsu Onyo",
  "Oola",
  "OOM-9",
  "Savage Opress",
  "Bail Organa",
  "Queen Breha Organa",
  "Leia Organa",
  "Garazeb 'Zeb' Orrelios",
  "Orrimaarko ('Prune Face')",
  "Admiral Kendal Ozzel",
  "Odd Ball",
  "Pablo-Jill",
  "Teemto Pagalies",
  "Jessika 'Jess' Testor Pava",
  "Sheev Palpatine",
  "Darth Sidious",
  "Captain Quarsh Panaka",
  "Casca Panzoro",
  "Reeve Panzoro",
  "Baron Papanoida",
  "Che Amanwe Papanoida",
  "Chi Eekway Papanoida",
  "Paploo",
  "Doctor Pershing",
  "Captain Phasma",
  "Even Piell",
  "Admiral Firmus Piett",
  "Darth Plagueis",
  "Sarco Plank",
  "Unkar Plutt",
  "Poggle the Lesser",
  "Yarael Poof",
  "Jek Tono Porkins",
  "Nahdonnis Praji",
  "Prauf",
  "Governor Arihnda Pryce",
  "Enric Pryde",
  "PZ-4CO",
  "Q-90 (aka 'Zero')",
  "Qin",
  "Qi'ra",
  "Ben Quadinaros",
  "Quarrie",
  "Quiggold",
  "R2-D2",
  "R2-KT",
  "R3-S6",
  "R4-P17",
  "R5-D4",
  "RA-7 ('Death Star droid')",
  "Rabé",
  "Admiral Raddus",
  "Dak Ralter",
  "Oppo Rancisis",
  "Admiral Dodd Rancit",
  "Rappertunie",
  "Sinjir Rath Velus",
  "Gallius Rax",
  "(a.k.a. 'The Operator')",
  "Eneb Ray",
  "Max Rebo",
  "Ciena Ree",
  "Ree-Yees",
  "Kylo Ren",
  "Knights of Ren",
  "Captain Rex",
  "Rey Palpatine Skywalker",
  "General Carlist Rieekan",
  "Riley",
  "Rogue Squadron",
  "Romba",
  "Bodhi Rook",
  "Pagetti Rook ('Weequay')",
  "Rotta the Hutt",
  "Rukh",
  "Sabé",
  "Saché",
  "Admiral U.O. Statura",
  "Joph Seastriker",
  "Queen Miraj Scintel",
  "Admiral Terrinald Screed",
  "Sebulba",
  "Aayla Secura",
  "Korr Sella",
  "Zev Senesca",
  "Fennec Shand",
  "Echuu Shen-Jon",
  "Sifo-Dyas",
  "Aurra Sing",
  "Anakin Skywalker",
  "Darth Vader",
  "Luke Skywalker",
  "Shmi Skywalker",
  "Rae Sloane",
  "The Smuggler",
  "Snaggletooth",
  "Supreme Leader Snoke",
  "Sy Snootles",
  "Osi Sobeck",
  "Han Solo",
  "The Son",
  "Greer Sonnel",
  "Sana Starros",
  "Stoke",
  "Lama Su",
  "Shriv Suurgav",
  "Mercurial Swift",
  "Gavyn Sykes",
  "Cham Syndulla",
  "Hera Syndulla",
  "Jacen Syndulla",
  "Orn Free Taa",
  "General Cassio Tagge",
  "Mother Talzin",
  "Wat Tambor",
  "Riff Tamson",
  "Ahsoka Tano",
  "Jaro Tapal",
  "Tarfful",
  "Jova Tarkin",
  "Grand Moff Wilhuff Tarkin",
  "Captain Roos Tarpals",
  "TC-14",
  "Berch Teller",
  "Teebo",
  "Teedo",
  "Mod Terrik",
  "Tessek ('Squid Head')",
  "Lor San Tekka",
  "Petty Officer Thanisson",
  "Inspector Thanoth",
  "Lieutenant Thire",
  "Grand Admiral Thrawn",
  "C'ai Threnalli",
  "Shaak Ti",
  "Paige Tico",
  "Rose Tico",
  "Saesee Tiin",
  "Bala-Tik",
  "Tikkes",
  "Meena Tills",
  "Quay Tolsite",
  "Bargwill Tomder",
  "Wag Too",
  "Coleman Trebor",
  "Admiral Trench",
  "Strono Tuggs",
  "Clone Trooper Tup",
  "Letta Turmond",
  "Longo Two-Guns",
  "Captain Gregar Typho",
  "Ratts Tyerell",
  "U9-C4",
  "Luminara Unduli",
  "Finis Valorum",
  "Eli Vanto",
  "Nahdar Vebb",
  "General Maximilian Veers",
  "Asajj Ventress",
  "Evaan Verlaine",
  "Garrick Versio",
  "Iden Versio",
  "Zay Versio",
  "Chancellor Lanever Villecham",
  "Dr. Nuvo Vindi",
  "Paz Vizla",
  "Pre Vizsla",
  "Tulon Voidgazer",
  "Dryden Vos",
  "Quinlan Vos",
  "WAC-47",
  "Wald",
  "Warok",
  "Wicket W. Warrick",
  "Watto",
  "Taun We",
  "Zam Wesell",
  "Norra Wexley",
  "Temmin 'Snap' Wexley",
  "General Vanden Willard",
  "Mace Windu",
  "Winta",
  "Trapper Wolf",
  "Commander Wolffe",
  "Wollivan",
  "Sabine Wren",
  "Wuher",
  "Xi'an",
  "Kazuda Xiono",
  "Yaddle",
  "Yendor",
  "Yoda",
  "Joh Yowza",
  "Wullf Yularen",
  "Ziro the Hutt",
  "Zuckuss",
  "Constable Zuvio",
];

