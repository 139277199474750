import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { User } from "src/app/models/user";
import { Observable, Subject } from "rxjs";
import { Store } from "@ngrx/store";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { STRIPEID } from "../../../environments/environment.prod";
import { HostListener } from "@angular/core";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { goToUserProfile } from "src/app/redux/actions/user.actions";

interface AppState {
  auth: User;
  cart: any;
}

@Component({
  selector: "app-nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.scss"],
  animations: [
    trigger("fade", [
      transition("void => *", [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        state("invisible", style({ opacity: 0 })),
        style({ opacity: 0 }),
        animate(800, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class NavBarComponent implements OnInit {
  @HostListener('document:wheel', ['$event.target'])
  public onWheel(targetElement) {
    let navbar = document.querySelector(".warn");
    if (targetElement) {
      navbar.classList.add("scrolled");
    }
  }

  profileJson: string;
  user: Observable<User>;
  authUser: Observable<User>;
  cartCount: any;
  config;
  private onDestroy$ = new Subject<void>();

  constructor(
    public auth: AuthService,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.user = this.store.select("auth");
    this.store
      .select("cart")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (res) =>
          (this.cartCount = res.reduce(
            (acc, item) => acc + item.quantityInCart,
            0
          ))
      );
  }

  ngOnInit() {
    this.auth.userProfile$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (profile) => (this.profileJson = JSON.stringify(profile, null, 2))
      );
    this.config = STRIPEID;
  }

  async goToMyProfile() {
    await this.user.pipe(takeUntil(this.onDestroy$)).subscribe((user) => {
      this.store.dispatch(goToUserProfile({ user: user }));
      this.router.navigateByUrl(`/profile/${user.username}`);
    });
  }
}
