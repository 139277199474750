// tslint:disable: quotemark
export const allSeries = [
  "1st Issue Special",
  "3-D Batman",
  "9-11: September 11, 2001",
  "52 Aftermath: The Four Horsemen",
  "80-Page Giant Magazine",
  "100-Page Super Spectacular",
  "A. Bizarro",
  "Action Comics",
  "Adam Strange",
  "Adam Strange / Future Quest Special",
  "Adam Strange Special",
  "Advanced Dungeons and Dragons",
  "Adventure Comics",
  "Adventure Comics 80-Page Giant",
  "Adventure Comics Special Featuring the Guardian",
  "Adventures in the DC Universe",
  "The Adventures of Alan Ladd",
  "The Adventures of Bob Hope",
  "The Adventures of Dean Martin and Jerry Lewis",
  "The Adventures of Ford Fairlane",
  "The Adventures of Jerry Lewis",
  "The Adventures of Ozzie and Harriet",
  "The Adventures of Rex the Wonder Dog",
  "The Adventures of Superboy",
  "Adventures of Supergirl",
  "Adventures of Superman",
  "Adventures of the Outsiders",
  "Adventures of the Super Sons",
  "Agent Liberty Special",
  "Alien Nation",
  "All-American Comics",
  "All-American Men of War",
  "All-American Western",
  "All-Flash",
  "All Funny Comics",
  "The All-New Atom",
  "The All-New Batman: The Brave and the Bold",
  "All-New Collectors' Edition",
  "All-Out War",
  "All Star Batman",
  "All Star Batman and Robin the Boy Wonder",
  "All-Star Comics",
  "All-Star Comics 80-Page Giant",
  "All-Star Section Eight",
  "All-Star Squadron",
  "All-Star Superman",
  "All-Star Western",
  "Alpha Centurion Special",
  "Amazing Adventures of the JLA",
  "Amazing World of DC Comics",
  "Amazons Attack!",
  "Ambush Bug",
  "Ambush Bug: Nothing Special",
  "Ambush Bug Stocking Stuffer",
  "Ambush Bug: Year None",
  "Ame-Comi Girls",
  "America vs. the Justice Society",
  "Amethyst",
  "Amethyst, Princess of Gemworld",
  "Anarky",
  "Angel and the Ape",
  "Angel Love",
  "Anima",
  "Animal Antics",
  "Animal Man",
  "Animaniacs",
  "Animaniacs: A Christmas Special",
  "Anthro",
  "Aquaman",
  "Aquaman and the Others",
  "Aquaman / Jabberjaw Special",
  "Aquaman / Justice League: Drowned Earth Special",
  "Aquaman Special",
  "Aquaman: Sword of Atlantis",
  "Aquaman: Time and Tide",
  "Arak, Son of Thunder",
  "Archie Meets Batman '66",
  "Argus",
  "Arion Lord of Atlantis",
  "Arion the Immortal",
  "Arkham Asylum",
  "Arkham Asylum: Living Hell",
  "Arkham Asylum: Madness",
  "Arkham Manor",
  "Arkham Reborn",
  "Armageddon 2001",
  "Armageddon: Inferno",
  "Armageddon: The Alien Agenda",
  "Army at War",
  "Arrow",
  "Arrow Season 2.5",
  "Arsenal",
  "Artemis: Requiem",
  "Assassin's Creed: The Fall",
  "Atari Force",
  "The Atlantis Chronicles",
  "The Atom",
  "The Atom and Hawkman",
  "The Authority/Lobo: Jingle Hell",
  "The Authority/Lobo: Spring Break Massacre",
  "Avatar",
  "Avengers/JLA",
  "Azrael",
  "Azrael: Agent of the Bat",
  "Azrael / Ash",
  "Azrael: Death's Dark Knight",
  "Aztek: The Ultimate Man",
  "Babylon 5",
  "Babylon 5: In Valen's Name",
  "Bad Girls",
  "Bane: Conquest",
  "Basketful of Heads",
  "Bat Lash",
  "Batgirl",
  "Batgirl & the Birds of Prey",
  "The Batgirl Adventures",
  "Batgirl Special",
  "Batgirl: Year One",
  "Batman",
  "Batman 3-D",
  "Batman '66",
  "Batman '66 Meets Steed and Mrs Peel",
  "Batman '66 Meets the Green Hornet",
  "Batman '66 Meets the Legion of Super-Heroes",
  "Batman '66 Meets the Man from U.N.C.L.E.",
  "Batman '66 Meets Wonder Woman '77",
  "Batman 80-Page Giant",
  "Batman: Absolution",
  "The Batman Adventures",
  "The Batman Adventures: Mad Love",
  "The Batman Adventures: The Lost Years",
  "Batman/Aliens",
  "Batman/Aliens II",
  "Batman Allies Secret Files and Origins",
  "Batman and Dracula: Red Rain",
  "Batman and Robin",
  "Batman and Robin Adventures",
  "Batman and Robin Adventures: Sub-Zero",
  "Batman and Robin Eternal",
  "Batman and Robin: The Official Comic Adaptation of the Warner Bros. Motion Picture",
  "Batman and Superman: World's Finest",
  "Batman and Superman Adventures: World's Finest",
  "Batman and the Justice League",
  "Batman and the Mad Monk",
  "Batman and the Outsiders",
  "Batman and The Signal",
  "Batman: Arkham Asylum Special",
  "Batman: Arkham Asylum: Tales of Madness",
  "Batman: Arkham Asylum: The Road to Arkham",
  "Batman: Arkham City",
  "Batman: Arkham City: End Game",
  "Batman: Arkham City: Special Issue",
  "Batman: Arkham Knight",
  "Batman: Arkham Origins",
  "Batman: Arkham Unhinged",
  "Batman: Bane",
  "Batman: Bane of the Demon",
  "Batman: Batgirl (1997)",
  "Batman: Batgirl (1998)",
  "Batman: Battle for the Cowl",
  "Batman Begins: The Official Movie Adaptation",
  "Batman Beyond",
  "Batman Beyond: Return of the Joker",
  "Batman Beyond Universe",
  "Batman Beyond Unlimited",
  "Batman: Birth of the Demon",
  "Batman: Black and White",
  "Batman: Blackgate",
  "Batman: Blackgate: Isle of Men",
  "Batman: Bloodstorm",
  "Batman: Book of the Dead",
  "Batman: Bride of the Demon",
  "Batman: Brotherhood of the Bat",
  "Batman: Bullock's Law",
  "Batman: Cacophony",
  "Batman/Captain America",
  "Batman: Castle of the Bat",
  "Batman/Catwoman: Follow the Money",
  "Batman/Catwoman: Trail of the Gun",
  "Batman: Child of Dreams",
  "The Batman Chronicles",
  "Batman Chronicles: The Gauntlet",
  "Batman: City of Light",
  "Batman Confidential",
  "Batman: Creature of the Night",
  "Batman: Crimson Mist",
  "Batman: Curse of the White Knight",
  "Batman: Damned",
  "Batman/Danger Girl",
  "Batman/Daredevil",
  "Batman: Dark Allegiances",
  "Batman: Dark Detective",
  "Batman: Dark Joker - The Wild",
  "Batman: Dark Knight Dynasty",
  "Batman: Dark Knight Gallery",
  "Batman: Dark Knight of the Round Table",
  "Batman: Dark Victory",
  "Batman: Day of Judgment",
  "Batman/Deadman: Death and Glory",
  "Batman: Death and the Maidens",
  "Batman: Death by Design",
  "Batman: Death Mask",
  "Batman: Death of Innocents",
  "Batman/Deathblow: After the Fire",
  "Batman/Demon",
  "Batman/Demon: A Tragedy",
  "Batman: Detective No. 27",
  "Batman: Digital Justice",
  "Batman: DOA",
  "Batman/Doc Savage Special",
  "Batman: Dreamland",
  "Batman: Earth One",
  "Batman: Ego",
  "Batman / Elmer Fudd Special",
  "Batman Eternal",
  "Batman: Europa",
  "Batman: Family",
  "The Batman Family",
  "Batman Forever: The Official Comic Adaptation of the Warner Bros. Motion Picture",
  "Batman: Fortunate Son",
  "Batman: Full Circle",
  "The Batman Gallery",
  "Batman: Gates of Gotham",
  "Batman: GCPD",
  "Batman: Ghosts: Legends of the Dark Knight Halloween Special",
  "Batman: Gordon of Gotham",
  "Batman: Gordon's Law",
  "Batman: Gotham Adventures",
  "Batman: Gotham After Midnight",
  "Batman: Gotham City Secret Files",
  "Batman: Gotham County Line",
  "Batman: Gotham Knights",
  "Batman: Gotham Nights",
  "Batman: Gotham Nights II",
  "Batman: Gotham Noir",
  "Batman/Green Arrow: The Poison Tomorrow",
  "Batman/Grendel",
  "Batman: Harley & Ivy",
  "Batman: Harley Quinn",
  "Batman: Harvest Breed",
  "Batman: Haunted Gotham",
  "Batman/Hellboy/Starman",
  "Batman: Hidden Treasures",
  "Batman: Hollywood Knight",
  "Batman: Holy Terror",
  "Batman: Hong Kong",
  "Batman/Houdini: The Devil's Workshop",
  "Batman/Huntress: Cry for Blood",
  "Batman: Huntress / Spoiler: Blunt Trauma",
  "Batman: I, Joker",
  "Batman in Barcelona: Dragon's Knight",
  "Batman: In Darkest Knight",
  "Batman in Noir Alley",
  "Batman Incorporated",
  "Batman: Jekyll & Hyde",
  "Batman/Joker: Switch",
  "Batman: Joker Time",
  "Batman: Joker's Apprentice",
  "Batman: Joker's Daughter",
  "Batman: Journey into Knight",
  "Batman/Judge Dredd: Die Laughing",
  "Batman/Judge Dredd: Judgment on Gotham",
  "Batman/Judge Dredd: The Ultimate Riddle",
  "Batman/Judge Dredd: Vendetta in Gotham",
  "Batman: Kings of Fear",
  "Batman: KnightGallery",
  "Batman: Last Knight on Earth",
  "Batman: League of Batmen",
  "Batman: Legends of the Dark Knight",
  "Batman: Legends of the Dark Knight: Jazz",
  "Batman: Legends of the Dark Knight Special",
  "Batman: Li'l Gotham",
  "Batman/Lobo",
  "Batman/Lobo: Deadly Serious",
  "Batman: Lost",
  "Batman: Madness - A Legends of the Dark Knight Halloween Special",
  "Batman: Manbat",
  "Batman: Mask of the Phantasm",
  "Batman: Masque",
  "Batman: Master of the Future",
  "Batman: Mitefall",
  "Batman: Mr. Freeze",
  "Batman: Nevermore",
  "Batman: Night Cries",
  "Batman: Nightwalker",
  "Batman/Nightwing: Bloodborne",
  "Batman: Nine Lives",
  "Batman: No Man's Land",
  "Batman: Noël",
  "Batman: Nosferatu",
  "Batman: Odyssey",
  "The Batman of Arkham",
  "Batman: Orphans",
  "Batman: Orpheus Rising",
  "Batman: Our Worlds at War",
  "Batman: Outlaws",
  "Batman: Penguin Triumphant",
  "Batman/Phantom Stranger",
  "Batman: Poison Ivy",
  "Batman/Poison Ivy: Cast Shadows",
  "Batman / Predator III",
  "Batman: Prelude to the Wedding",
  "Batman/Punisher: Lake of Fire",
  "Batman: Reign of Terror",
  "Batman Returns: The Official Comic Adaptation of the Warner Bros. Motion Picture",
  "Batman: Riddler - The Riddle Factory",
  "Batman: Room Full of Strangers",
  "Batman: Run, Riddler, Run",
  "Batman: Scar of the Bat",
  "Batman/Scarecrow 3-D",
  "Batman/Scarecrow: Fear",
  "Batman/Scarface: A Psychodrama",
  "Batman: Scottish Connection",
  "Batman: Secrets",
  "Batman: Seduction of the Gun",
  "Batman/Shadow",
  "Batman: Shadow of the Bat",
  "Batman: Sins of the Father",
  "Batman: Son of the Demon",
  "Batman/Spawn: War Devil",
  "Batman/Spider-Man",
  "Batman: Streets of Gotham",
  "The Batman Strikes!",
  "Batman/Superman",
  "Batman/Superman/Wonder Woman: Trinity",
  "Batman: Sword of Azrael",
  "Batman/Tarzan: Claws of the Cat-Woman",
  "Batman/Teenage Mutant Ninja Turtles",
  "Batman/Teenage Mutant Ninja Turtles II",
  "Batman/Teenage Mutant Ninja Turtles III",
  "Batman/Teenage Mutant Ninja Turtles Adventures",
  "Batman: Tenses",
  "Batman: The 10 Cent Adventure",
  "Batman: The 12 Cent Adventure",
  "Batman: The Abduction",
  "Batman: The Ankh",
  "Batman: The Blue, the Grey, and the Bat",
  "Batman: The Book of Shadows",
  "Batman: The Brave and the Bold",
  "Batman: The Chalice",
  "Batman: The Cult",
  "Batman: The Dark Knight",
  "Batman: The Dark Knight Returns",
  "Batman: The Dark Prince Charming",
  "Batman: The Dawnbreaker",
  "Batman: The Devastator",
  "Batman: The Doom that Came to Gotham",
  "Batman: The Drowned",
  "Batman: The Golden Streets of Gotham",
  "Batman: The Hill",
  "Batman: The Killing Joke",
  "Batman: The Last Angel",
  "Batman: The Long Halloween",
  "Batman: The Man Who Laughs",
  "Batman/The Maxx: Arkham Dreams",
  "Batman: The Merciless",
  "Batman: The Monster Men",
  "Batman: The Murder Machine",
  "Batman: The Official Comic Book Adaptation of the Warner Bros Motion Picture",
  "Batman: The Order of Beasts",
  "Batman: The Red Death",
  "Batman: The Return",
  "Batman: The Return of Bruce Wayne",
  "Batman/The Spirit",
  "Batman: The Ultimate Evil",
  "Batman: Through the Looking Glass",
  "Batman: Toyman",
  "Batman: Turning Points",
  "Batman: Two-Face - Crime and Punishment",
  "Batman: Two-Face Strikes Twice",
  "Batman: Two Faces",
  "Batman: Universe",
  "Batman: Unseen",
  "Batman: Vengeance of Bane",
  "Batman: Vengeance of Bane II",
  "Batman Versus Predator",
  "Batman Versus Predator II: Bloodmatch",
  "Batman Villains Secret Files",
  "Batman Villains Secret Files and Origins 2005",
  "Batman vs. Ra's Al Ghul",
  "Batman: War on Crime",
  "Batman: White Knight",
  "Batman: White Knight Presents Von Freeze",
  "The Batman Who Laughs",
  "Batman: Widening Gyre",
  "Batman/Wildcat",
  "Batman: Year 100",
  "The Batman's Grave",
  "Bat-Mite",
  "Battle Classics",
  "Batwing",
  "Batwoman",
  "Beast Boy",
  "Before Watchmen",
  "Beowulf",
  "The Best of DC",
  "Best of the Brave and the Bold",
  "Beware the Batman",
  "Beware the Creeper",
  "Beyond The Fringe",
  "The Big All-American Comic Book",
  "The Big Book of Fun Comics",
  "Big Daddy Danger",
  "Big Town",
  "Bill & Ted's Excellent Adventure Movie Adaptation",
  "Billy Batson and the Magic of Shazam!",
  "Binky",
  "Binky's Buddies",
  "Bionicle",
  "Bionicle Glatorian",
  "Bionicle: Ignition",
  "Birds of Prey",
  "Birds of Prey: Batgirl",
  "Birds of Prey: Batgirl/Catwoman",
  "Birds of Prey: Catwoman/Oracle",
  "Birds of Prey: Manhunt",
  "Birds of Prey: Revolution",
  "Birds of Prey Secret Files 2003",
  "Birds of Prey: The Ravens",
  "Birds of Prey: Wolves",
  "Bizarro",
  "Bizarro Comics",
  "Bizarro World",
  "Black Adam: The Dark Age",
  "Black Adam: Year of The Villain",
  "Black Canary",
  "Black Canary and Zatanna: Bloodspell",
  "Black Canary: Ignite",
  "Black Canary/Oracle: Birds of Prey",
  "Black Canary Wedding Planner",
  "Black Condor",
  "Black Hammer/Justice League: Hammer of Justice!",
  "Black Lightning",
  "Black Lightning: Cold Dead Hands",
  "Black Lightning / Hong Kong Phooey Special",
  "Black Lightning: Year One",
  "Black Magic",
  "Black Mask: Year of The Villain",
  "Black Orchid",
  "The Black Racer and Shilo Norman Special",
  "Blackest Night",
  "Blackhawk",
  "Blackhawk Special",
  "Blackhawks",
  "Blackmask",
  "Blasters Special",
  "Blitzkrieg",
  "Blood of the Demon",
  "Blood Pack",
  "Bloodbath",
  "Bloodhound",
  "Bloodlines",
  "Blue Beetle",
  "Blue Devil",
  "Bob, the Galactic Bum",
  "Body Doubles",
  "Body Doubles (Villains)",
  "Bomba, the Jungle Boy",
  "Bombshells United",
  "The Book of Fate",
  "Books of Magic",
  "Booster Gold",
  "Booster Gold / The Flintstones Special",
  "Boy Commandos",
  "The Brave and the Bold",
  "The Brave and the Bold 80-Page Giant",
  "The Brave and the Bold: Batman and Wonder Woman",
  "Breach",
  "Breathtaker",
  "Brightest Day",
  "Brightest Day Aftermath: The Search for Swamp Thing",
  "Brother Power the Geek",
  "Bruce Wayne: The Road Home",
  "Bug! The Adventures of Forager",
  "Bugs Bunny",
  "The Bugs Bunny Monthly",
  "The Butcher",
  "Buzzy",
  "C.O.P.S.",
  "Camelot 3000",
  "Caper",
  "Capt. Storm",
  "Captain Action",
  "Captain Atom",
  "Captain Carrot and His Amazing Zoo Crew",
  "Captain Carrot and the Final Ark",
  "Cartoon Cartoons",
  "Cartoon Network Action Pack",
  "Cartoon Network Block Party",
  "Cartoon Network Presents",
  "Cartoon Network Starring",
  "Catwoman",
  "Catwoman Defiant",
  "Catwoman: Election Night",
  "Catwoman: Guardian of Gotham",
  "Catwoman Secret Files and Origins",
  "Catwoman: Selina's Big Score",
  "Catwoman: The Movie",
  "Catwoman/Tweety and Sylvester",
  "Catwoman/Vampirella: The Furies",
  "Catwoman: When in Rome",
  "Catwoman/Wildcat",
  "Cave Carson Has a Cybernetic Eye",
  "Cave Carson Has a Cybernetic Eye/Swamp Thing Special",
  "Cave Carson Has an Interstellar Eye",
  "Centurions",
  "Chain Gang War",
  "Challengers of the Unknown",
  "Champion Sports",
  "Chase",
  "Checkmate",
  "Christmas with the Super-Heroes",
  "Chronos",
  "Cinder and Ashe",
  "Cinnamon: El Ciclo",
  "Clash",
  "Claw the Unconquered",
  "Collapser",
  "Comic Cavalcade",
  "Congo Bill",
  "Congorilla",
  "Conjurors",
  "Connor Hawke: Dragon's Blood",
  "Conqueror of the Barren Earth",
  "Constantine",
  "Constantine / Hellblazer Special Edition",
  "Constantine: The Hellblazer",
  "Convergence",
  "Cool World",
  "Cosmic Boy",
  "Cosmic Odyssey",
  "Countdown",
  "Countdown: Arena",
  "Countdown Presents: Lord Havok and the Extremists",
  "Countdown Presents: The Search for Ray Palmer",
  "Countdown Special",
  "Countdown to Adventure",
  "Countdown to Final Crisis",
  "Countdown to Infinite Crisis",
  "Countdown to Mystery",
  "Creature Commandos",
  "The Creeper",
  "Crime Bible: Five Lessons of Blood",
  "The Crimson Avenger",
  "Crisis Aftermath",
  "Crisis on Infinite Earths",
  "The Curse of Brimstone",
  "Cursed Comics Cavalcade",
  "Cyborg",
  "Dale Evans Comics",
  "Damage",
  "Damian: Son of Batman",
  "Danger Trail",
  "Daphne Byrne",
  "Daredevil/Batman",
  "The Daring New Adventures of Supergirl",
  "Dark Days",
  "The Dark Knight III: The Master Race",
  "Dark Knight Returns: The Golden Child",
  "The Dark Knight Returns: The Last Crusade",
  "The Dark Knight Strikes Again",
  "Dark Knights Rising: The Wild Hunt",
  "Dark Mansion of Forbidden Love",
  "Dark Nemesis (Villains)",
  "Dark Nights: Metal",
  "Dark Nights: The Batman Who Laughs",
  "The Darkness/Batman",
  "The Darkness/Superman",
  "Darkseid Special",
  "Darkseid (Villains)",
  "Darkseid vs. Galactus: The Hunger",
  "Darkstars",
  "Dastardly and Muttley",
  "Date with Debbi",
  "A Date with Judy",
  "Day of Judgment",
  "Day of Vengeance",
  "DC 100 Page Super Spectacular",
  "DC 2000",
  "DC Challenge",
  "DC Comics Bombshells",
  "DC Comics Presents (1978)",
  "DC Comics Presents (2004)",
  "DC Countdown",
  "DC First",
  "DC Graphic Novel",
  "DC Holiday Special '09",
  "DC Holiday Special 2017",
  "DC House of Horror",
  "DC Infinite Halloween Special",
  "DC/Marvel: All Access",
  "DC Nation",
  "DC Nation Super Spectacular",
  "DC Nuclear Winter Special",
  "DC One Million",
  "DC Rebirth Holiday Special",
  "DC Retroactive: Batman",
  "DC Retroactive: Green Lantern",
  "DC Retroactive: JLA",
  "DC Retroactive: Superman",
  "DC Retroactive: The Flash",
  "DC Retroactive: Wonder Woman",
  "DC Special",
  "DC Special Blue Ribbon Digest",
  "DC Special: Cyborg",
  "DC Special: Raven",
  "DC Special Series",
  "DC Special: The Return of Donna Troy",
  "DC Super Hero Girls",
  "DC Super Stars",
  "DC: The New Frontier",
  "DC Universe",
  "DC Universe: Decisions",
  "DC Universe Holiday Bash",
  "DC Universe: Last Will and Testament",
  "DC Universe: Legacies",
  "DC Universe Online: Legends",
  "DC Universe Presents",
  "DC Universe: Rebirth",
  "DC Universe: Trinity",
  "DC Universe vs. Masters of the Universe",
  "DC vs. Marvel",
  "DC/WildStorm: DreamWar",
  "DC's Beach Blanket Bad Guys Summer Special",
  "DC's Year of The Villain Special",
  "DCeased",
  "DCU: Brave New World",
  "DCU Halloween Special",
  "DCU Heroes Secret Files",
  "DCU Holiday Bash II",
  "DCU Holiday Bash III",
  "DCU Holiday Special",
  "DCU: Infinite Holiday Special",
  "DCU Villains Secret Files",
  "Dead Romeo",
  "Deadman",
  "Deadman: Dark Mansion of Forbidden Love",
  "Deadman: Dead Again",
  "Deadman: Exorcism",
  "Deadman: Love After Death",
  "Deadshot",
  "Dear Justice League",
  "Death of Hawkman",
  "Death of the New Gods",
  "Deathstroke",
  "Deathstroke, the Hunted",
  "Deathstroke, the Terminator",
  "Deathstroke/Yogi Bear Special",
  "Debbi's Dates",
  "Demolition Man",
  "The Demon",
  "The Demon: Driven Out",
  "The Demon: Hell is Earth",
  "Demon Knights",
  "Detective Comics",
  "Detention Comics",
  "Deus Ex",
  "Dexter's Laboratory",
  "Diablo",
  "Dial H",
  "Dial H for Hero",
  "Diana, Princess of the Amazons",
  "Doc Savage",
  "Doctor Fate",
  "Doctor Mid-Nite",
  "Dodo and the Frog",
  "Dog Days of Summer",
  "The Dollhouse Family",
  "Doom Force Special",
  "Doom Patrol",
  "Doom Patrol and Suicide Squad Special",
  "Doom Patrol / JLA Special",
  "Doom Patrol: Weight of the Worlds",
  "Doomed",
  "Doomsday Annual",
  "Doomsday Clock",
  "Doorway to Nightmare",
  "Double Action Comics",
  "Dragonlance",
  "The Dragonlance Saga",
  "The Dreaming",
  "Driver: Crossing the Line",
  "Dynamic Classics",
  "Earth 2",
  "Earth 2: Society",
  "Earth 2: World's End",
  "Eclipso",
  "Eclipso: The Darkness Within",
  "El Diablo",
  "Electric Warrior",
  "Electric Warriors",
  "The Elfquest 25th Anniversary Special",
  "Elfquest: The Discovery",
  "Elfquest: The Searcher and the Sword",
  "Elongated Man",
  "Elseworlds 80-Page Giant",
  "Elseworld's Finest",
  "Elseworld's Finest: Supergirl & Batgirl",
  "Elvira's House of Mystery",
  "Empire",
  "End of Nations",
  "Enemy Ace Special",
  "Enemy Ace: War Idyll",
  "Enemy Ace: War in Heaven",
  "Enginehead",
  "Eradicator",
  "Eternity Girl",
  "Even More Secret Origins",
  "Event Leviathan",
  "Everything Happens to Harvey",
  "Exit Stage Left: The Snagglepuss Chronicles",
  "Extreme Justice",
  "F[edit]",
  "Title",
  "Faces of Evil",
  "The Fall and Rise of Captain Atom",
  "Fallen Angel",
  "Falling in Love",
  "The Family Dynamic",
  "Famous 1st Edition",
  "Fanboy",
  "Far Sector",
  "Fate",
  "Feature Films",
  "Female Furies",
  "Fifty Who Made DC Great",
  "Fighting American",
  "Final Crisis",
  "Final Crisis Aftermath",
  "The Final Night",
  "Firebrand",
  "Firestorm",
  "Firestorm the Nuclear Man",
  "First Wave",
  "The Flash",
  "Flash and Green Lantern: The Brave and the Bold",
  "Flash Comics",
  "Flash Forward",
  "Flash Gordon",
  "Flash/Green Lantern: Faster Friends",
  "The Flash: Iron Heights",
  "The Flash: Our Worlds at War",
  "The Flash: Rebirth",
  "The Flash: Season Zero",
  "Flash/Speed Buggy Special",
  "The Flash: The Fastest Man Alive",
  "The Flash: Time Flies",
  "The Flash TV Special",
  "Flashpoint (1999)",
  "Flashpoint (2011)",
  "The Flintstones",
  "The Flintstones and the Jetsons",
  "Flippity and Flop",
  "Forbidden Tales of Dark Mansion",
  "Forever Evil",
  "Forever Evil Aftermath: Batman vs. Bane",
  "Forever Maelstrom",
  "Forever People",
  "Forgotten Realms",
  "Formerly Known as the Justice League",
  "Four-Star Battle Tales",
  "Four-Star Spectacular",
  "The Fourth World Gallery",
  "The Fox and the Crow",
  "Frankenstein, Agent of S.H.A.D.E.",
  "Freedom Fighters",
  "From Beyond the Unknown",
  "Frontier Fighters",
  "Funny Folks",
  "Funny Stuff",
  "Funny Stuff Stocking Stuffer",
  "The Fury of Firestorm",
  "The Fury of Firestorm: The Nuclear Man",
  "The Fury of Firestorm: The Nuclear Men",
  "Future Quest",
  "Future Quest Presents",
  "G.I. Combat",
  "G.I. Combat (War One - Shot)",
  "G.I. War Tales",
  "Gammarauders",
  "Gang Busters",
  "Gears of War",
  "gen:LOCK",
  "Genesis",
  "Ghost/Batgirl: The Resurrection Machine",
  "Ghosts",
  "Giantkiller",
  "Giantkiller A To Z: A Field Guide To Big Monsters",
  "Giant-Size Atom",
  "Gilgamesh II",
  "GirlFrenzy!",
  "Girls' Love Stories",
  "Girls' Romances",
  "God of War",
  "Gog (Villains)",
  "The Golden Age",
  "Golden Age Secret Files and Origins",
  "Gotham Academy",
  "Gotham by Gaslight: An Alternative History of the Batman",
  "Gotham by Midnight",
  "Gotham Central",
  "Gotham City Garage",
  "Gotham City Monsters",
  "Gotham City Sirens",
  "Gotham Gazette",
  "Gotham Girls",
  "Gotham Underground",
  "Grayson",
  "The Great Ten",
  "Green Arrow",
  "Green Arrow/Black Canary",
  "Green Arrow/Black Canary Wedding Special",
  "Green Arrow: The Longbow Hunters",
  "Green Arrow: The Wonder Year",
  "Green Arrow: Year One",
  "Green Lantern",
  "Green Lantern 3-D",
  "Green Lantern: 1001 Emerald Nights",
  "Green Lantern/Adam Strange",
  "Green Lantern Annual",
  "Green Lantern/Atom",
  "Green Lantern: Blackstars",
  "Green Lantern: Brightest Day, Blackest Night",
  "Green Lantern: Circle of Fire",
  "Green Lantern Corps",
  "Green Lantern Corps: Edge of Oblivion",
  "Green Lantern Corps Quarterly",
  "Green Lantern Corps: Recharge",
  "Green Lantern: Dragon Lord",
  "Green Lantern: Earth One",
  "Green Lantern: Emerald Dawn",
  "Green Lantern: Emerald Dawn II",
  "Green Lantern: Emerald Warriors",
  "Green Lantern: Evil's Might",
  "Green Lantern: Fear Itself",
  "Green Lantern/Firestorm",
  "Green Lantern/Flash: Faster Friends",
  "Green Lantern Gallery",
  "Green Lantern: Ganthet's Tale",
  "Green Lantern/Green Arrow",
  "Green Lantern/Green Lantern",
  "Green Lantern/Huckleberry Hound Special",
  "Green Lantern: Larfleeze Christmas Special",
  "Green Lantern: Legacy",
  "Green Lantern: Legacy: The Last Will and Testament of Hal Jordan",
  "Green Lantern: Lost Army",
  "Green Lantern: Mosaic",
  "Green Lantern Movie Prequel",
  "Green Lantern/New Gods: Godhead",
  "Green Lantern: New Guardians",
  "Green Lantern: Our Worlds at War",
  "Green Lantern/Plastic Man: Weapons of Mass Deception",
  "Green Lantern/Power Girl",
  "Green Lantern: Rebirth",
  "Green Lantern/Sentinel: Heart of Darkness",
  "Green Lantern/Silver Surfer: Unholy Alliances",
  "Green Lantern/Sinestro Corps: Secret Files",
  "Green Lantern: Sinestro Corps Special",
  "Green Lantern / Space Ghost Special",
  "Green Lantern Special",
  "Green Lantern/Superman: Legend of the Green Flame",
  "Green Lantern: The Animated Series",
  "Green Lantern: The New Corps",
  "Green Lantern vs. Aliens",
  "Green Lantern: WillWorld",
  "Green Lanterns",
  "The Green Team: Teen Trillionaires",
  "The Griffin",
  "Grifter",
  "Gross Point",
  "Guardians of Metropolis",
  "Gunfire",
  "Guns of the Dragon",
  "Guy Gardner",
  "Guy Gardner: Collateral Damage",
  "Guy Gardner: Reborn",
  "Guy Gardner: Warrior",
  "The Hacker Files",
  "Hal Jordan and the Green Lantern Corps",
  "Hammerlocke",
  "Hard Time Season Two",
  "Hardcore Station",
  "Harlan Ellison's 7 Against Chaos",
  "Harleen",
  "Harley & Ivy: Love on the Lam",
  "Harley & Ivy Meet Betty & Veronica",
  "Harley Loves Joker",
  "Harley Quinn",
  "Harley Quinn 25th Anniversary Special",
  "Harley Quinn and Her Gang of Harleys",
  "Harley Quinn and Poison Ivy",
  "Harley Quinn and Power Girl",
  "Harley Quinn & the Suicide Squad April Fool's Special",
  "Harley Quinn: Be Careful What You Wish For",
  "Harley Quinn: Breaking Glass",
  "Harley Quinn/Gossamer",
  "Harley Quinn Invades Comic-Con International: San Diego",
  "Harley Quinn: Our Worlds at War",
  "Harley Quinn: Villain of The Year",
  "Harley's Little Black Book",
  "Haven: The Broken City",
  "Hawk and Dove",
  "Hawkgirl",
  "Hawkman",
  "Hawkman: Found",
  "Hawkman Secret Files and Origins",
  "Hawkman Special",
  "Hawkworld",
  "Haywire",
  "Heart Throbs",
  "The Heckler",
  "Hellblazer",
  "Helmet of Fate",
  "He-Man and the Masters of the Multiverse",
  "He-Man and the Masters of the Universe",
  "He-Man: The Eternity War",
  "He-Man / Thundercats",
  "Hercules Unbound",
  "Here's Howie",
  "H-E-R-O",
"Hero Hotline",
  "Heroes Against Hunger",
  "Heroes in Crisis",
  "Hex",
  "History of the DC Universe",
  "Hitchhiker's Guide to the Galaxy",
  "Hitman",
  "Hitman/Lobo: That Stupid Bastich",
  "Hollywood Funny Folks",
  "Hopalong Cassidy",
  "Hot Wheels",
  "Hourman",
  "House of Mystery",
  "House of Secrets",
  "House of Whispers",
  "Human Bomb",
  "Human Defense Corps",
  "The Human Race",
  "Human Target",
  "Human Target Special",
  "The Huntress",
  "Huntress",
  "Huntress: Year One",
  "I, Vampire",
  "iCandy",
  "Identity Crisis",
  "The Immortal Doctor Fate",
  "The Immortal Men",
  "Impulse",
  "Impulse / Atom Double-Shot",
  "Impulse: Bart Saves the Universe",
  "The Incredible Hulk vs Superman",
  "inFamous",
  "The Infected",
  "The Inferior Five",
  "Inferno",
  "Infinite Crisis",
  "Infinite Crisis: Fight for the Multiverse",
  "Infinity Inc.",
  "Infinity Man and the Forever People",
  "Injustice 2",
  "Injustice: Gods Among Us",
  "Injustice: Ground Zero",
  "Injustice vs. Masters of the Universe",
  "Insurgent",
  "Invasion!",
  "Ion",
  "Ironwolf",
  "Ironwolf: Fires of the Revolution",
  "Isis",
  "It's Game Time",
  "Jack Cross",
  "Jack Kirby's Fourth World",
  "Jackie Gleason and the Honeymooners",
  "Jemm, Son of Saturn",
  "The Jetsons",
  "Jimmy Olsen",
  "Jimmy Wakely",
  "JLA",
  "JLA: A League of One",
  "JLA: Act of God",
  "JLA: Age of Wonder",
  "JLA: Black Baptism",
  "JLA Classified",
  "JLA Classified: Cold Steel",
  "JLA: Created Equal",
  "JLA/Cyberforce",
  "JLA: Destiny",
  "JLA / Doom Patrol Special",
  "JLA: Earth 2",
  "JLA: Foreign Bodies",
  "JLA: Gallery",
  "JLA: Gatekeeper",
  "JLA: Gods and Monsters",
  "JLA/Haven",
  "JLA: Heaven's Ladder",
  "JLA in Crisis Secret Files",
  "JLA: Incarnations",
  "JLA/JSA Secret Files and Origins",
  "JLA/JSA: Virtue and Vice",
  "JLA: Liberty and Justice",
  "JLA: Our Worlds at War",
  "JLA: Paradise Lost",
  "JLA: Primeval",
  "JLA: Riddle of the Beast",
  "JLA: Scary Monsters",
  "JLA Secret Files and Origins 2004",
  "JLA: Secret Origins",
  "JLA: Seven Caskets",
  "JLA: Shogun of Steel",
  "JLA Showcase 80-Page Giant",
  "JLA/Spectre: Soul War",
  "JLA: Superpower",
  "JLA/The 99",
  "JLA: The Island of Dr. Moreau",
  "JLA: The Nail",
  "JLA: The Secret Society of Super-Heroes",
  "JLA/Titans",
  "JLA: Tomorrow Woman",
  "JLA Versus Predator",
  "JLA: Welcome to the Working Week",
  "JLA/WildC.A.T.s",
  "JLA/Witchblade",
  "JLA: World Without Grown-Ups",
  "JLA: Year One",
  "JLA-Z",
  "Joe Kubert Presents",
  "John Constantine: Hellblazer",
  "Johnny Thunder",
  "The Joker",
  "Joker",
  "The Joker/Daffy Duck",
  "The Joker: Devil's Advocate",
  "Joker/Harley: Criminal Sanity",
  "Joker: Killer Smile",
  "Joker: Last Laugh",
  "Joker/Mask",
  "The Joker: Year of The Villain",
  "Joker's Asylum",
  "Joker's Asylum II",
  "Jonah Hex",
  "Jonah Hex And Other Western Tales",
  "Jonah Hex: No Way Back",
  "Jonah Hex / Yosemite Sam Special",
  "Jonni Thunder",
  "JSA",
  "JSA All Stars",
  "JSA Classified",
  "JSA Kingdom Come Special",
  "JSA Liberty Files: The Whistling Skull",
  "JSA: Our Worlds at War",
  "JSA: Strange Adventures",
  "JSA: The Liberty File",
  "JSA: The Unholy Three",
  "JSA vs. Kobra",
  "The Judas Coin",
  "Judge Dredd",
  "Judge Dredd: Legends of the Law",
  "Judge Dredd: The Official Movie Adaptation",
  "Justice",
  "Justice, Inc.",
  "Justice League",
  "Justice League 3000",
  "Justice League 3001",
  "Justice League: A Midsummer's Nightmare",
  "Justice League Adventures",
  "Justice League America",
  "Justice League/Aquaman: Drowned Earth",
  "Justice League: Cry for Justice",
  "Justice League Dark",
  "Justice League Dark and Wonder Woman: The Witching Hour",
  "Justice League: Darkseid War",
  "Justice League Elite",
  "Justice League Europe",
  "Justice League: Generation Lost",
  "Justice League: Gods and Monsters",
  "Justice League / Hitman",
  "Justice League International",
  "Justice League: No Justice",
  "Justice League Odyssey",
  "Justice League of America",
  "Justice League of America: Another Nail",
  "Justice League of America: Killer Frost - Rebirth",
  "Justice League of America Super-Spectacular",
  "Justice League of America: The Atom - Rebirth",
  "Justice League of America: The Ray - Rebirth",
  "Justice League of America: Vixen - Rebirth",
  "Justice League of America's Vibe",
  "Justice League / Power Rangers",
  "Justice League Quarterly",
  "Justice League Spectacular",
  "Justice League Task Force",
  "Justice League: The New Frontier Special",
  "Justice League: The Rise & Fall Special",
  "Justice League: The Rise of Arsenal",
  "Justice League United",
  "Justice League Unlimited",
  "Justice League vs. Suicide Squad",
  "Justice League Wedding Special",
  "Justice Leagues",
  "Justice Riders",
  "Justice Society of America",
  "Justice Society of America 80-Page Giant",
  "Justice Society of America 100-Page Super Spectacular",
  "The Justice Society Returns!",
  "Just Imagine Stan Lee",
  "Kamandi: At Earth's End",
  "The Kamandi Challenge",
  "Kamandi: The Last Boy on Earth",
  "Karate Kid",
  "Katana",
  "The Kents",
  "Kid Eternity",
  "The Kingdom",
  "Kingdom Come",
  "Kissyfur",
  "Klarion",
  "Knight and Squire",
  "Kobra",
  "Kong the Untamed",
  "Korak, Son of Tarzan",
  "Krypto the Superdog",
  "Krypton Chronicles",
  "The L.A.W. (Living Assault Weapons)",
  "L.E.G.I.O.N. '89",
  "L.E.G.I.O.N. '90",
  "L.E.G.I.O.N. '91",
  "L.E.G.I.O.N. '92",
  "L.E.G.I.O.N. '93",
  "L.E.G.I.O.N. '94",
  "Lab Rats",
  "Larfleeze",
  "Larry Harmon's Laurel and Hardy",
  "The Last Days of Animal Man",
  "Last Days of the Justice Society Special",
  "The Last God",
  "Lazarus Five",
  "Leading Comics",
  "Leading Screen Comics",
  "League of Justice",
  "Leave it to Binky",
  "Legend of the Hawkman",
  "The Legend of Wonder Woman",
  "Legends",
  "The Legends of Daniel Boone",
  "Legends of the Dark Knight",
  "Legends of the Dark Knight 100-Page Super Spectacular",
  "Legends of the DC Universe",
  "Legends of the Legion",
  "Legends of the World's Finest",
  "Legends of Tomorrow",
  "The Legion",
  "Legion Lost",
  "The Legion of Substitute Heroes Special",
  "Legion of Super-Heroes",
  "Legion of Super-Heroes / Bugs Bunny Special",
  "The Legion of Super-Heroes in the 31st Century",
  "Legion of Super-Heroes: Millennium",
  "Legion of Super-Villains",
  "Legion: Science Police",
  "Legion: Secret Origin",
  "Legion Worlds",
  "Legionnaires",
  "Legionnaires 3",
  "Lex Luthor: Man of Steel",
  "Lex Luthor/Porky Pig",
  "Lex Luthor: The Unauthorized Biography",
  "Lex Luthor: Year of The Villain",
  "The Life Story of the Flash",
  "Life, the Universe and Everything",
  "The Light Brigade",
  "Limited Collectors' Edition",
  "Little Shop of Horrors",
  "Lobo",
  "Lobo: A Contract On Gawd",
  "Lobo: Blazing Chain of Love",
  "Lobo: Bounty Hunting for Fun and Profit",
  "Lobo: Chained",
  "Lobo Convention Special",
  "Lobo/Deadman: The Brave and the Bald",
  "Lobo: Death and Taxes",
  "Lobo/Demon: Helloween",
  "Lobo: Fragtastic Voyage",
  "The Lobo Gallery: Portraits of a Bastich",
  "Lobo Goes to Hollywood",
  "Lobo: Highway to Hell",
  "Lobo: I Quit",
  "Lobo: In The Chair",
  "Lobo: Infanticide",
  "Lobo/Judge Dredd: Psycho Bikers vs. the Mutants From Hell",
  "Lobo/Mask",
  "Lobo Paramilitary Christmas Special",
  "Lobo: Portrait of a Victim",
  "Lobo/Road Runner Special",
  "Lobo: Unamerican Gladiators",
  "Lobo Unbound",
  "Lobo's Back",
  "Lobo's Big Babe Spring Break Special",
  "Lobocop",
  "Lois Lane",
  "Lois Lane Annual",
  "Looney Tunes",
  "Looney Tunes: Back in Action",
  "Loose Cannon",
  "Lords of the Ultra-Realm",
  "Losers Special",
  "Lot 13",
  "Love Stories",
  "The Low, Low Woods",
  "Lucifer",
  "Lumberjanes/Gotham Academy",
  "M.A.S.K.",
  "Madame Xanadu",
  "Magog",
  "Majestic",
  "Major Bummer",
  "Man and Superman 100-Page Super Spectacular",
  "Man-Bat",
  "The Man Called A-X",
  "Manhunter",
  "Manhunter Special",
  "Mann and Superman",
  "The Man of Steel",
  "Many Loves of Dobie Gillis",
  "Martian Manhunter",
  "Martian Manhunter: American Secrets",
  "Martian Manhunter / Marvin the Martian Special",
  "Martian Manhunter Special",
  "Masters of the Universe (1982)",
  "Masters of the Universe (2012)",
  "The Masterworks Series of Great Comic Book Artists",
  "'Mazing Man",
  "Meet Angel",
  "Men of War",
  "Mera: Queen of Atlantis",
  "Mera: Tidebreaker",
  "Metal Men",
  "Metamorpho",
  "Metamorpho: Year One",
  "Metropolis S.C.U.",
  "MGM's Marvelous Wizard of Oz",
  "Michael Moorcock's Elric: The Making of a Sorcerer",
  "Midnighter",
  "Midnighter and Apollo",
  "The Mighty",
  "The Mighty Crusaders",
  "The Mighty Crusaders Special",
  "Mighty Love",
  "Milestone Forever",
  "Millennium",
  "Miss Beverly Hills of Hollywood",
  "Miss Melody Lane of Broadway",
  "Mister E",
  "Mister Miracle",
  "Mister Miracle Special",
  "Mister Terrific",
  "Modesty Blaise",
  "Monolith",
  "More Fun",
  "More Fun Comics",
  "Mortal Kombat X",
  "Mother Panic",
  "Mother Panic / Batman Special",
  "Mother Panic: Gotham A.D.",
  "The Movement",
  "Movie Comics",
  "Movietown's Animal Antics",
  "Mr. District Attorney",
  "Mr. Mxyzptlk (Villains)",
  "Ms. Tree Quarterly",
  "¡Mucha Lucha!",
  "The Multiversity",
  "Mutt and Jeff",
  "My Greatest Adventure",
  "My Name is Chaos",
  "Mysteries of Love in Space",
  "Mystery in Space",
  "Mystik U",
  "Naomi",
  "Nathaniel Dusk",
  "Nathaniel Dusk II",
  "National Comics (1999)",
  "National Comics (2012)",
  "The Nazz",
  "Nemesis: The Impostors",
  "The New 52: Futures End",
  "New Adventure Comics",
  "The New Adventures of Charlie Chan",
  "The New Adventures of Superboy",
  "New Book of Comics",
  "New Challengers",
  "New Comics",
  "New Fun",
  "New Gods",
  "New Gods Secret Files",
  "New Gods Special",
  "The New Guardians",
  "New Suicide Squad",
  "New Super-Man",
  "New Super-Man and the Justice League of China",
  "New Talent Showcase",
  "The New Teen Titans",
  "New Teen Titans: Games",
  "The New Titans",
  "New Year's Evil (1998)",
  "New Year's Evil (2020)",
  "New York World's Fair Comics",
  "The Newsboy Legion and the Boy Commandos Special",
  "Newstime",
  "The Next",
  "Night Force",
  "Nightmaster: Monsters of Rock",
  "Nightwing",
  "Nightwing: Alfred's Return",
  "Nightwing and Huntress",
  "Nightwing/Magilla Gorilla Special",
  "Nightwing: Our Worlds at War",
  "Nightwing: The New Order",
  "Nightwing: The Target",
  "Nutsy Squirrel",
  "Ocean Master: Year of The Villain",
  "Odyssey of the Amazons",
  "Old Lady Harley",
  "OMAC",
  "OMAC: One Man Army Corps",
  "The OMAC Project",
  "The Omega Men",
  "Oracle: The Cure",
  "Orion",
  "Our Army at War",
  "Our Army at War (War One-shot)",
  "Our Fighting Forces",
  "Our Fighting Forces (War One-shot)",
  "Outcasts",
  "Outlaws",
  "Outsiders",
  "Outsiders: Five of a Kind",
  "The Oz-Wonderland Wars",
  "Parallax: Emerald Night",
  "Pat Boone",
  "Peacemaker",
  "Penguin: Pain & Prejudice",
  "Peter Cannon, Thunderbolt",
  "Peter Panda",
  "Peter Porkchops",
  "The Phantom",
  "Phantom Lady",
  "The Phantom Stranger",
  "The Phantom Zone",
  "Picture Stories From The Bible",
  "Pinky and the Brain",
  "Planet of the Apes/Green Lantern",
  "Planetary/Batman: Night On Earth",
  "Planetary/JLA: Terra Occulta",
  "Plastic Man",
  "Plastic Man 80-Page Giant",
  "Plastic Man Special",
  "Plop!",
  "Poison Ivy: Cycle of Life and Death",
  "The Power Company",
  "Power Girl",
  "Power Lords",
  "The Power of Shazam!",
  "Power of the Atom",
  "The Powerpuff Girls",
  "The Powerpuff Girls Movie: The Comic",
  "Prez",
  "Primal Force",
  "The Prisoner",
  "Prometheus (Villains)",
  "The Psyba-Rats",
  "The Psycho",
  "Punisher/Batman: Deadly Knights",
  "Quarantine Zone",
  "Quest for Camelot",
  "The Question",
  "The Question: The Deaths of Vic Sage",
  "The Question Quarterly",
  "The Question Returns",
  "R.E.B.E.L.S.",
  "The Raccoon Kids",
  "Ragman",
  "Ragman: Cry of the Dead",
  "Ragman: Suit of Souls",
  "Rainbow Brite and the Star Stealer",
  "Rann/Thanagar: Holy War",
  "Rann-Thanagar War",
  "The Ravagers",
  "Raven",
  "Raven: Daughter of Darkness",
  "The Ray",
  "Real Fact Comics",
  "Real Screen Comics",
  "Real Screen Funnies",
  "Realworlds",
  "The Red Circle",
  "Red Hood/Arsenal",
  "Red Hood and the Outlaws",
  "Red Hood: Outlaw",
  "Red Hood: The Lost Days",
  "Red Lanterns",
  "Red Robin",
  "Red Tornado",
  "Reign in Hell",
  "Reign of the Zodiac",
  "Relative Heroes",
  "The Restaurant at the End of the Universe",
  "Resurrection Man",
  "Richard Dragon",
  "Richard Dragon, Kung-Fu Fighter",
  "The Riddler: Year of The Villain",
  "Rima the Jungle Girl",
  "The Ring of the Nibelung",
  "Rip Hunter...Time Master",
  "Robin",
  "Robin II",
  "Robin III: Cry of the Huntress",
  "Robin 3000",
  "Robin/Argent Double-Shot",
  "Robin Hood Tales",
  "Robin Rises",
  "Robin: Son of Batman",
  "Robin/Spoiler Special",
  "Robin War",
  "Robin: Year One",
  "Robotech Defenders",
  "Roger Zelazny's Amber: Nine Princes in Amber",
  "Roger Zelazny's Amber: The Guns of Avalon",
  "The Rogues (Villains)",
  "Rogues Gallery",
  "Romance Trail",
  "Ronin",
  "Roots of the Swamp Thing",
  "Rose & Thorn",
  "Rudolph the Red-Nosed Reindeer",
  "The Ruff & Reddy Show",
  "Rush City",
  "RWBY",
  "S.T.A.R. Corps",
  "The Saga of Ra's Al Ghul",
  "The Saga of the Swamp Thing",
  "Salvation Run",
  "Samurai Jack Special",
  "The Sandman",
  "The Sandman Special",
  "The Sandman Universe Presents Hellblazer",
  "Sasquatch Detective",
  "The Savage Hawkman",
  "Scare Tactics",
  "Scarecrow (Villains)",
  "Scarlett",
  "Science Fiction Graphic Novel",
  "Scooby Apocalypse",
  "Scooby-Doo",
  "Scooby-Doo Spooky Spectacular",
  "Scooby-Doo Spooky Summer Special",
  "Scooby-Doo Super Scarefest!",
  "Scooby-Doo Team-Up",
  "Scooby-Doo: Where Are You?",
  "Scratch",
  "Scribblenauts Unmasked: A Crisis of Imagination",
  "Scribbly",
  "Sea Devils",
  "Secret Files & Origins Guide to the DC Universe",
  "Secret Files President Luthor",
  "Secret Hearts",
  "Secret Origins",
  "Secret Origins 80-Page Giant",
  "Secret Origins of Super-Villains 80-Page Giant",
  "Secret Six",
  "Secret Society of Super-Villains",
  "The Secret Spiral Of Swamp Kid",
  "Secrets of Haunted House",
  "Secrets of Sinister House",
  "Secrets of the Legion of Super-Heroes",
  "Sensation Comics",
  "Sensation Comics Featuring Wonder Woman",
  "Sensation Mystery",
  "Sergeant Bilko",
  "Sergio Aragonés Destroys DC",
  "Seven Soldiers",
  "Sgt. Bilko's Pvt. Doberman",
  "Sgt. Rock",
  "Sgt. Rock: Between Hell and a Hard Place",
  "Sgt. Rock Special",
  "Sgt. Rock: The Lost Battalion",
  "Sgt. Rock: The Prophecy",
  "Sgt. Rock's Prize Battle Tales",
  "The Shade",
  "Shade, the Changing Girl",
  "Shade, the Changing Girl / Wonder Woman Special",
  "Shade, the Changing Man",
  "Shade, the Changing Woman",
  "Shado: Song of Dragon",
  "The Shadow",
  "The Shadow/Batman",
  "Shadow of the Batman",
  "The Shadow Strikes!",
  "The Shadow War of Hawkman",
  "Shadowdragon",
  "Shadowpact",
  "Shazam!",
  "Shazam! and the Shazam Family",
  "Shazam! Power of Hope",
  "Shazam! The Monster Society of Evil",
  "Shazam!: The New Beginning",
  "Sherlock Holmes",
  "The Shield",
  "Showcase",
  "Showcase '93",
  "Showcase '94",
  "Showcase '95",
  "Showcase '96",
  "Sideways",
  "The Silencer",
  "Silver Age",
  "Silver Surfer/Superman",
  "Silverblade",
  "Simon Dark",
  "Sinestro",
  "Sinestro: Year of The Villain",
  "The Sinister House of Secret Love",
  "Sins of Youth",
  "Sixpack and Dogwelder: Hard Travelin' Heroz",
  "Skreemer",
  "Skull & Bones",
  "Slash Maraud",
  "Smallville",
  "Smallville: Alien",
  "Smallville: Chaos",
  "Smallville: Lantern",
  "Smallville: Season 11",
  "Smallville Season 11: Continuity",
  "Smallville: The Comic",
  "Smash Comics",
  "Solo",
  "Solomon Grundy",
  "Son of Ambush Bug",
  "Son of Superman",
  "Son of Vulcan",
  "Sonic Disruptors",
  "Sovereign Seven",
  "Space Ghost",
  "Space Jam",
  "Spanner's Galaxy",
  "Spawn/Batman",
  "The Spectre",
  "Speed Force",
  "Spelljammer",
  "Spider-Man and Batman",
  "Spiral Zone",
  "The Spirit",
  "SpyBoy/Young Justice",
"Stalker",
  "Stanley and His Monster",
  "Star Hunters",
  "Star-Spangled Comics",
  "Star Spangled War Stories",
  "Star-Spangled War Stories (War One-Shot)",
  "Star Trek",
  "Star Trek VI: The Undiscovered Country",
  "Star Trek: The Ashes of Eden",
  "Star Trek: Debt of Honor",
  "Star Trek Generations",
  "Star Trek/Green Lantern",
  "Star Trek/Legion of Super-Heroes",
  "Star Trek: The Modala Imperative",
  "Star Trek Movie Special",
  "Star Trek: The Next Generation",
  "Star Trek: The Next Generation/Deep Space Nine",
  "Star Trek: The Next Generation—Ill Wind",
  "Star Trek: The Next Generation—Shadowheart",
  "Star Trek: The Next Generation: The Modala Imperative",
  "Star Trek: The Next Generation—The Series Finale",
  "Starfire",
  "Starman",
  "Starman/Congorilla",
  "Starman: The Mist",
  "Stars and S.T.R.I.P.E.",
  "Static Shock",
  "Static Shock: Rebirth of the Cool",
  "Static Shock Special",
  "Steel",
  "Steel, The Indestructible Man",
  "Steel: The Official Comic Adaptation of the Warner Bros. Motion Picture",
  "Stormwatch",
  "Strange Adventures",
  "Strange Sports Stories",
  "Street Fighter: The Battle for Shadaloo",
  "Streets",
  "Sugar and Spike",
  "Suicide Squad",
  "Suicide Squad: Amanda Waller",
  "Suicide Squad / Banana Splits Special",
  "Suicide Squad Black Files",
  "Suicide Squad Most Wanted: Deadshot & Katana",
  "Suicide Squad Most Wanted: El Diablo And Boomerang",
  "Suicide Squad: Raise the Flag",
  "Suicide Squad Special: War Crimes",
  "Sun Devils",
  "Super DC Giant",
  "Super Friends",
  "Super Powers",
  "Super Sons",
  "Super Sons / Dynomutt Special",
  "Super Sons: The Foxglove Mission",
  "Super Sons: The Polarshield Project",
  "Superboy",
  "Superboy and the Legion of Super-Heroes",
  "Superboy and the Ravers",
  "Superboy/Risk Double-Shot",
  "Superboy/Robin: World's Finest Three",
  "Superboy Spectacular",
  "Superboy's Legion",
  "Supergirl",
  "Supergirl and the Legion of Super-Heroes",
  "Supergirl: Being Super",
  "Supergirl: Cosmic Adventures in the 8th Grade",
  "Supergirl/Lex Luthor Special",
  "Supergirl Movie Special",
  "Supergirl/Prysm Double-Shot",
  "Supergirl: Wings",
  "Super-Heroes Battle Super-Gorillas",
  "Superman",
  "Superman 3-D",
  "Superman IV Movie Special",
  "Superman: A Nation Divided",
  "Superman Adventures",
  "Superman/Aliens 2: God War",
  "Superman: American Alien",
  "Superman & Batman: Generations",
  "Superman & Batman: Generations II",
  "Superman & Batman: Generations III",
  "Superman and Batman vs.Aliens and Predator",
  "Superman and Batman vs. Vampires and Werewolves",
  "Superman and Batman: World's Funnest",
  "Superman & Bugs Bunny",
  "Superman & Savage Dragon: Chicago",
  "Superman & Savage Dragon: Metropolis",
  "Superman and Spider-Man",
  "Superman: At Earth's End",
  "Superman/Batman",
  "Superman Beyond",
  "Superman: Birthright",
  "Superman: Blood of My Ancestors",
  "Superman Confidential",
  "Superman: Day of Doom",
  "Superman: Distant Fires",
  "Superman: Doomed",
  "Superman/Doomsday: Hunter/Prey",
  "Superman: Earth One",
  "Superman: Emperor Joker",
  "Superman: End of the Century",
  "The Superman Family",
  "Superman Family Adventures",
  "Superman/Fantastic Four",
  "Superman For All Seasons",
  "Superman for Earth",
  "Superman For The Animals",
  "Superman Forever",
  "The Superman Gallery",
  "Superman/Gen 13",
  "Superman, Inc.",
  "Superman: Infinite City",
  "Superman: Kal",
  "Superman: King of the World",
  "Superman: Last Son of Earth",
  "Superman: Last Stand of New Krypton",
  "Superman: Last Stand on Krypton",
  "Superman: Leviathan Rising Special",
  "Superman: Lex 2000",
  "Superman: Lois & Clark",
  "Superman: Lois Lane (1998)",
  "Superman: Lois Lane (2014)",
  "The Superman/Madman Hullabaloo",
  "Superman: Metropolis",
  "Superman Metropolis Secret Files",
  "The Superman Monster",
  "The Superman Movie Special",
  "Superman: New Krypton Special",
  "Superman of Smallville",
  "Superman: Our Worlds at War Secret Files and Origins",
  "Superman: Peace on Earth",
  "Superman: Red Son",
  "Superman Red/Superman Blue",
  "Superman Returns Prequel",
  "Superman Returns: The Official Movie Adaptation",
  "Superman: Save the Planet",
  "Superman: Secret Files 2009",
  "Superman Secret Files and Origins",
  "Superman: Secret Identity",
  "Superman: Secret Origin",
  "Superman/Shazam: First Thunder",
  "Superman: Silver Banshee",
  "Superman Smashes The Klan",
  "Superman Spectacular",
  "Superman: Speeding Bullets",
  "Superman: Strength",
  "Superman/Supergirl: Maelstrom",
  "Superman/Tarzan: Sons of the Jungle",
  "Superman: The 10-Cent Adventure",
  "Superman: The Coming of the Supermen",
  "Superman: The Dark Side",
  "Superman: The Doomsday Wars",
  "Superman: The Earth Stealers",
  "Superman: The Kansas Sighting",
  "Superman: The Last Family of Krypton",
  "Superman: The Last God of Krypton",
  "Superman: The Legacy of Superman",
  "Superman: The Man of Steel",
  "Superman: The Man of Tomorrow",
  "Superman: The Odyssey",
  "Superman: The Secret Years",
  "Superman: The Wedding Album",
  "Superman/Thundercats",
  "Superman/Top Cat Special",
  "Superman/Toyman",
  "Superman: True Brit",
  "Superman Unchained",
  "Superman: Under a Yellow Sun",
  "Superman: Up in the Sky",
  "Superman vs. Aliens",
  "Superman vs. Darkseid: Apokolips Now!",
  "Superman vs. Predator",
  "Superman vs. The Amazing Spider-Man",
  "Superman vs. The Terminator: Death to the Future",
  "Superman Villains Secret Files",
  "Superman: War of the Supermen",
  "Superman: War of the Worlds",
  "Superman: Where Is Thy Sting?",
  "Superman / Wonder Woman",
  "Superman/Wonder Woman: Whom Gods Destroy",
  "Superman: World of New Krypton",
  "Superman: Y2K",
  "Superman: Year One",
  "Superman's Girl Friend, Lois Lane",
  "Superman's Metropolis",
  "Superman's Nemesis: Lex Luthor",
  "Superman's Pal Jimmy Olsen",
  "Superman's Pal Jimmy Olsen Special",
  "Supermen of America",
  "Supernatural",
  "Super-Team Family",
  "Superwoman",
  "Swamp Thing",
  "Swamp Thing Winter Special",
  "Sweatshop",
  "Swing with Scooter",
  "Sword of Sorcery",
  "Sword of The Atom",
  "T.H.U.N.D.E.R.Agents",
  "Tailgunner Jo",
  "Takion",
  "Talent Showcase",
  "Tales from the Dark Multiverse",
  "Tales of Ghost Castle",
  "Tales of the Green Lantern Corps",
  "Tales of the Legion of Super-Heroes",
  "Tales of the New Teen Titans",
  "Tales of the Sinestro Corps",
  "Tales of the Teen Titans",
  "Tales of the Unexpected",
  "Talon",
  "Talos of the Wilderness Sea",
  "Tangent Comics",
  "Tangent: Superman's Reign",
  "Tarzan",
  "Tarzan Digest",
  "Tarzan Family",
  "Team 7",
  "Team Superman",
  "Team Superman Secret Files",
  "Team Titans",
  "Teen Beam",
  "Teen Beat",
  "Teen Titans",
  "Teen Titans Annual No.1, 1967 Issue",
  "Teen Titans: Cold Case",
  "Teen Titans: Earth One",
  "Teen Titans Go!",
  "Teen Titans/Legion Special",
  "Teen Titans Lost Annual",
  "Teen Titans/Outsiders Secret Files",
  "Teen Titans: Raven",
  "Teen Titans Spotlight",
  "Teen Titans: The Lazarus Contract Special",
  "Teen Titans: Year One",
  "Teeny Titans",
  "Tell Me, Dark",
  "Telos",
  "Tempest",
  "Tempus Fugitive",
  "Terra",
  "The Terrifics",
  "Terror Titans",
  "Three Dimension Adventures",
  "The Three Mouseketeers",
  "Threshold",
  "Thriller",
  "Thrilling Comics",
  "Thrillkiller",
  "Thrillkiller '62",
  "Timber Wolf",
  "Time Masters",
  "Time Masters: Vanishing Point",
  "Time Warp",
  "Tiny Titans",
  "Tiny Titans/Little Archie",
  "Tiny Titans: Return to the Treehouse",
  "Tiny Toon Adventures Magazine",
  "Titans",
  "Titans: Burning Rage",
  "Titans East Special",
  "Titans Hunt",
  "Titans/Legion of Super-Heroes: Universe Ablaze",
  "Titans: Scissors, Paper, Stone",
  "Titans Sell-Out Special",
  "Titans: Villains For Hire Special",
  "Titans/Young Justice: Graduation Day",
  "Toe Tags Featuring George A. Romero",
  "Tomahawk",
  "Tor",
  "Total Justice",
  "Total Recall",
  "Trials of Shazam",
  "Trigger Twins",
  "Trinity",
  "Trinity of Sin",
  "Trinity of Sin: Pandora",
  "Trinity of Sin: Phantom Stranger",
  "Triumph",
  "Trouble Magnet",
  "TSR Worlds",
  "TV Screen Cartoons",
  "Twilight",
  "Two-Face: Year One",
  "The Uncanny X-Men and the New Teen Titans",
  "Uncharted",
  "Uncle Sam and the Freedom Fighters",
  "Under the Moon: A Catwoman Tale",
  "Underworld",
  "Underworld Unleashed",
  "The Unexpected (1968)",
  "The Unexpected (2018)",
  "Unknown Soldier",
  "The Untold Legend of the Batman",
  "Untold Tales of Blackest Night",
  "V for Vendetta",
  "Valor",
  "The Vampire Diaries",
  "Vext",
  "Vigilante",
  "Vigilante: City Lights, Prairie Justice",
  "Vigilante: Southland",
  "Viking Glory - The Viking Prince",
  "Villains United",
  "Viper",
  "Vixen: Return of the Lion",
  "Voodoo",
  "Wacky Raceland",
  "The Wanderers",
  "Wanted, the World's Most Dangerous Villains",
  "War of the Gods",
  "War of the Green Lanterns: Aftermath",
  "The War that Time Forgot",
  "Warlord",
  "Wasteland",
  "Watchmen",
  "We Are Robin",
  "The Web",
  "Wednesday Comics",
  "The Weird",
  "Weird Mystery Tales",
  "Weird Secret Origins",
  "Weird War Tales",
  "Weird War Tales (War One-shot)",
  "Weird Western Tales",
  "Weird Worlds",
  "Welcome Back, Kotter",
  "Western Comics",
  "Where in the World is Carmen Sandiego?",
"Who's Who in Star Trek",
  "Who's Who in the DC Universe",
  "Who's Who in the DC Universe: Update 1993",
  "Who's Who in the Legion of Super-Heroes",
  "Who's Who: The Definitive Directory of the DC Universe",
  "Who's Who: Update '87",
  "Who's Who: Update '88",
  "Wild Dog",
  "Wild Dog Special",
  "The Wild Storm",
  "The Wild Storm: Michael Cray",
  "Windy and Willy",
  "The Witching Hour",
  "Wonder Girl",
  "Wonder Twins",
  "Wonder Woman",
  "Wonder Woman 75th Anniversary Special",
  "Wonder Woman '77 Meets The Bionic Woman",
  "Wonder Woman '77 Special",
  "The Wonder Woman 100 Project",
  "Wonder Woman: Amazonia",
  "Wonder Woman and Justice League Dark: The Witching Hour",
  "Wonder Woman: Come Back to Me",
  "Wonder Woman / Conan",
  "Wonder Woman: Dead Earth",
  "Wonder Woman: Donna Troy",
  "Wonder Woman: Earth One",
  "Wonder Woman Gallery",
  "Wonder Woman: Our Worlds at War",
  "Wonder Woman: Spirit of Truth",
  "Wonder Woman: Steve Trevor",
  "Wonder Woman / Tasmanian Devil Special",
  "Wonder Woman: The Blue Amazon",
  "Wonder Woman: The Hiketeia",
  "Wonder Woman: The Once and Future Story",
  "Wonder Woman: The True Amazon",
  "Wonder Woman: Warbringer",
  "World of Krypton",
  "World of Metropolis",
  "World of Smallville",
  "World of Warcraft: Dark Riders",
  "World of Warcraft: Pearl of Pandaria",
  "World War III",
  "World Without End",
  "World's Best Comics",
  "World's Best Comics: Golden Age Sampler",
  "World's Best Comics: Silver Age Sampler",
  "World's Finest",
  "World's Finest Comics",
  "World's Finest: Our Worlds at War",
  "Worlds Collide",
  "Worlds' Finest",
  "Wrath of the Spectre",
  "Xenobrood",
  "Xer0",
  "Xombi",
  "Yeah!",
  "Year of The Villain: Hell Arisen",
  "Year One: Batman/Ra's al Ghul",
  "Year One: Batman/Scarecrow",
  "Young All-Stars",
  "Young Heroes in Love",
  "Young Justice",
  "Young Justice in No Man's Land",
  "Young Justice: Our Worlds at War",
  "Young Justice: Sins of Youth",
  "Young Justice: The Secret",
  "Young Love",
  "Young Monsters in Love",
  "Young Romance",
  "Young Romance: The New 52 Valentine's Day Special",
  "Zatanna",
  "Zatanna Special",
  "Zero Hour: Crisis in Time",
  "Future Fight Firsts: Crescent And IO (2019)",
  "A Year of Marvels: August Infinite Comic (2016)",
  "A YEAR OF MARVELS: JULY INFINITE COMIC (2016)",
  "A YEAR OF MARVELS: JUNE INFINITE COMIC (2016)",
  "A YEAR OF MARVELS: MARCH INFINITE COMIC (2016)",
  "A YEAR OF MARVELS: MAY INFINITE COMIC (2016)",
  "A Year of Marvels: November Infinite Comic (2016)",
  "A Year of Marvels: October Infinite Comic (2016)",
  "A YEAR OF MARVELS: SEPTEMBER INFINITE COMIC (2016)",
  "A Year of Marvels: The Incredible (2016)",
  "A+X (2012 - 2014)",
  "A-Babies Vs. X-Babies (2012)",
  "A-Force (2015)",
  "A-Force (2016)",
  "A-Next (1998 - 1999)",
  "Absolute Carnage (2019 - Present)",
  "Absolute Carnage Director's Cut Edition (2019)",
  "Absolute Carnage Vs. Deadpool (2019)",
  "Absolute Carnage: Avengers (2019)",
  "Absolute Carnage: Captain Marvel (2019)",
  "Absolute Carnage: Immortal Hulk (2019)",
  "Absolute Carnage: Lethal Protectors (2019 - Present)",
  "Absolute Carnage: Miles Morales (2019)",
  "Absolute Carnage: Scream (2019)",
  "Absolute Carnage: Separation Anxiety (2019)",
  "Absolute Carnage: Symbiote Of Vengeance (2019)",
  "Absolute Carnage: Symbiote Spider-Man (2019)",
  "Absolute Carnage: Weapon Plus (2019)",
  "Actor Presents Spider-Man and the Incredible Hulk (2003)",
  "Adam: Legend of the Blue Marvel (2008)",
  "Adventures Into Fear (1970 - 1975)",
  "Adventures of Captain America (1991 - 1992)",
  "Adventures of Cyclops & Phoenix (1994)",
  "Adventures of Spider-Man (1996 - 1997)",
  "Adventures of the X-Men (1996 - 1997)",
  "Aero (2019 - Present)",
  "Age of Apocalypse (2011 - 2013)",
  "Age of Apocalypse (2015)",
  "AGE OF APOCALYPSE: THE CHOSEN 1 (1995)",
  "Age of Conan: Belit (2019)",
  "Age of Conan: Valeria (2019 - Present)",
  "Age of Heroes (2010)",
  "Age of Innocence: The Rebirth of Iron Man (1996)",
  "Age of Ultron (2013)",
  "Age of Ultron Vs. Zombies (2015)",
  "Age of Ultron: AI (2013)",
  "Age of X-Man Alpha (2019)",
  "Age Of X-Man Omega (2019)",
  "Age of X-Man: Apocalypse & the X-Tracts (2019)",
  "Age of X-Man: Nextgen (2019)",
  "Age of X-Man: Prisoner X (2019)",
  "Age of X-Man: The Amazing Nightcrawler (2019)",
  "Age of X-Man: The Marvelous X-Men (2019)",
  "Age of X-Man: X-Tremists (2019)",
  "Age of X: Alpha (2010)",
  "Age of X: Universe (2011)",
  "Agent Carter: S.H.I.E.L.D. 50th Anniversary (2015)",
  "Agent Carter: S.H.I.E.L.D. 50th Anniversary (2015)",
  "Agent May: S.H.I.E.L.D. 50th Anniversary (2015)",
  "Agent X (2002 - 2004)",
  "Agents of Atlas (2006 - 2007)",
  "Agents of Atlas (2009)",
  "Agents of Atlas (2019)",
  "Agents of Atlas MGC (2010)",
  "Agents of S.H.I.E.L.D. (2016)",
  "Alias (2001 - 2003)",
  "Alias MGC (2010)",
  "All Select Comics 70th Anniversary Special (2009)",
  "All Winners Comics 70th Anniversary Special (2009)",
  "All-New Captain America (2014 - 2015)",
  "All-New Captain America Special (2015)",
  "ALL-NEW CAPTAIN AMERICA SPECIAL (2015)",
  "All-New Captain America: Fear Him (2015)",
  "All-New Captain America: Fear Him Infinite Comic (2014)",
  "All-New Doop (2014)",
  "All-New Ghost Rider (2014 - 2015)",
  "All-New Guardians of the Galaxy (2017)",
  "All-New Guardians of the Galaxy Annual (2017)",
  "All-New Hawkeye (2015 - 2016)",
  "All-New Hawkeye (2015)",
  "All-New Inhumans (2015 - 2016)",
  "All-New Invaders (2014 - 2015)",
  "All-New Iron Manual (2008)",
  "All-New Iron Manual Digital Comic (2010 - 2008)",
  "All-New Marvel Now! Point One (2014)",
  "All-New Miracleman Annual (2014)",
  "All-New Official Handbook of the Marvel Universe A to Z (2006)",
  "All-New Official Handbook of the Marvel Universe a to Z: Update (2007)",
  "All-New Savage She-Hulk (2009)",
  "All-New Ultimates (2014 - 2015)",
  "All-New Wolverine (2015 - 2018)",
  "All-New Wolverine Annual (2016)",
  "All-New X-Factor (2014 - 2015)",
  "All-New X-Men (2012 - 2015)",
  "All-New X-Men (2015 - 2017)",
  "All-New X-Men Annual (2014)",
  "All-New X-Men Annual (2016)",
  "All-New X-Men Special (2013)",
  "All-New, All-Different Avengers (2015 - 2016)",
  "All-New, All-Different Avengers Annual (2016)",
  "All-New, All-Different Marvel Universe (2015 - 2016)",
  "All-New, All-Different Point One (2015)",
  "All-Winners Comics (1941 - 1947)",
  "All-Winners Squad: Band of Heroes (2011)",
  "Alpha Flight (1983 - 1994)",
  "Alpha Flight (2004 - 2005)",
  "Alpha Flight (2011 - 2012)",
  "Alpha Flight Facsimile Edition (2019)",
  "Alpha Flight: True North (2019)",
  "Alpha: Big Time (2013)",
  "AMADEUS CHO: GENIUS AT WORK (2016)",
  "Amazing Adult Fantasy (1961 - 1962)",
  "Amazing Adventures (1961)",
  "Amazing Adventures (1970 - 1976)",
  "Amazing Fantasy (1962)",
  "Amazing Fantasy (1995 - 1996)",
  "Amazing Fantasy (2004 - 2006)",
  "Amazing Fantasy 15: Spider-Man! (2011 - 2012)",
  "Amazing Fantasy Facsimile Edition (2019)",
  "Amazing Mary Jane (2019 - Present)",
  "Amazing Spider-Girl (2006 - 2009)",
  "Amazing Spider-Man & Silk: The Spider(Fly) Effect (2016)",
  "Amazing Spider-Man & Silk: The Spider(fly) Effect Infinite Comic (2016)",
  "Amazing Spider-Man (1999 - 2013)",
  "Amazing Spider-Man Annual (1964 - 2018)",
  "Amazing Spider-Man Annual (1999)",
  "Amazing Spider-Man Annual (2000)",
  "Amazing Spider-Man Annual (2001)",
  "Amazing Spider-Man Annual (2008)",
  "Amazing Spider-Man Annual (2010)",
  "Amazing Spider-Man Annual (2011)",
  "Amazing Spider-Man Annual (2012)",
  "Amazing Spider-Man Annual (2014)",
  "Amazing Spider-Man Annual (2016)",
  "Amazing Spider-Man Annual (2018)",
  "Amazing Spider-Man Annual (2018)",
  "Amazing Spider-Man Facsimile Edition (2019)",
  "Amazing Spider-Man Facsimile Edition (2020)",
  "Amazing Spider-Man Family (2008 - 2009)",
  "Amazing Spider-Man Magazine (2007)",
  "Amazing Spider-Man MGC (2010)",
  "Amazing Spider-Man Presents: American Son (2010)",
  "Amazing Spider-Man Presents: Anti-Venom - New Ways to Live (2009)",
  "Amazing Spider-Man Presents: Black Cat (2010)",
  "Amazing Spider-Man Presents: Jackpot (2009 - 2010)",
  "Amazing Spider-Man Special (2015)",
  "Amazing Spider-Man: Big Time (2011)",
  "Amazing Spider-Man: Ends of the Earth (2012)",
  "Amazing Spider-Man: Extra! (2008 - 2009)",
  "Amazing Spider-Man: Extra! (the Spartacus Gambit) (2009)",
  "Amazing Spider-Man: Family Business (2013)",
  "Amazing Spider-Man: Full Circle (2019)",
  "Amazing Spider-Man: Going Big (2019)",
  "Amazing Spider-Man: Hooky (1986)",
  "Amazing Spider-Man: Infested (2011)",
  "Amazing Spider-Man: Parallel Lives (2011 - 2012)",
  "Amazing Spider-Man: Renew Your Vows (2015)",
  "Amazing Spider-Man: Renew Your Vows (2016 - 2018)",
  "Amazing Spider-Man: The Daily Bugle (2020)",
  "Amazing Spider-Man: The Movie (2012)",
  "Amazing Spider-Man: Venom 3D (2019)",
  "Amazing Spider-Man: Venom Inc. Alpha (2017)",
  "Amazing Spider-Man: Venom Inc. Omega (2018)",
  "Amazing Spider-Man: Wakanda Forever (2018)",
  "Amazing X-Men (1995)",
  "Amazing X-Men (2013 - 2015)",
  "Amazing X-Men Annual (2014)",
  "America (2017 - 2018)",
  "American Dream (2008)",
  "American Eagle: Just a Little Old-Fashioned Justice (2008)",
  "Angel: Revelations (2008)",
  "Angela: Asgard's Assassin (2014 - 2015)",
  "Angela: Queen of Hel (2015 - 2016)",
  "Anita Blake, Vampire Hunter: Guilty Pleasures (2006 - 2008)",
  "Annihilation (2006 - 2007)",
  "Annihilation - Scourge Alpha (2019)",
  "Annihilation - Scourge Omega (2019)",
  "Annihilation - Scourge: Beta Ray Bill (2019)",
  "Annihilation - Scourge: Fantastic Four (2019)",
  "Annihilation - Scourge: Nova (2019)",
  "Annihilation - Scourge: Silver Surfer (2019)",
  "Annihilation Saga (2007)",
  "Annihilation: Conquest (2007)",
  "Annihilation: Conquest - Quasar (2007)",
  "Annihilation: Conquest - Starlord (2007)",
  "Annihilation: Conquest - Wraith (2007)",
  "Annihilation: Conquest Prologue (2007)",
  "Annihilation: Heralds of Galactus (2007)",
  "Annihilation: Nova (2006)",
  "Annihilation: Prologue (2006)",
  "Annihilation: Ronan (2006)",
  "Annihilation: Silver Surfer (2006)",
  "Annihilation: Super-Skrull (2006)",
  "Annihilation: The Nova Corps (2006)",
  "Annihilators (2010 - 2011)",
  "Annihilators: Earthfall (2011)",
  "Ant-Man & the Wasp (2010 - 2011)",
  "Ant-Man & the Wasp (2018)",
  "Ant-Man & the Wasp: Living Legends (2018)",
  "Ant-Man (2003 - 2004)",
  "Ant-Man (2015)",
  "Ant-Man Annual (2015)",
  "Ant-Man: Larger than Life (2016)",
  "ANT-MAN: LARGER THAN LIFE 1 (2015)",
  "ANT-MAN: LAST DAYS 1 (2015)",
  "Ant-Man: Season One (2011)",
  "Anthem BlueCross Presents: Iron Man & Habit Heroes (2013)",
  "Arana: The Heart of the Spider (2005)",
  "Ares (2006)",
  "Armor Wars (2015)",
  "Asgardians of the Galaxy (2018 - Present)",
  "Assault on New Olympus Prologue One-Shot (2009)",
  "Astonishing (1951 - 1957)",
  "Astonishing Spider-Man & Wolverine: Another Fine Mess (2011)",
  "Astonishing Spider-Man/Wolverine (2010 - 2011)",
  "Astonishing Tales (1970)",
  "Astonishing Tales (2009)",
  "Astonishing Thor (2010)",
  "Astonishing X-Men (1995)",
  "Astonishing X-Men (2004 - 2013)",
  "Astonishing X-Men (2017 - 2018)",
  "Astonishing X-Men Annual (2012)",
  "Astonishing X-Men Annual (2018)",
  "ASTONISHING X-MEN MGC 1 (2011)",
  "ASTONISHING X-MEN SAGA (2006)",
  "Astonishing X-Men: Ghost Boxes (2008)",
  "Astonishing X-Men: Xenogenesis (2010)",
  "Astonishing X-Men: Xenogenesis Director's Cut (2010)",
  "Atlantis Attacks (2020)",
  "Atlas (2010)",
  "Avengers & the Infinity Gauntlet (2010)",
  "Avengers & X-Men: Axis (2014)",
  "Avengers (1963 - 1996)",
  "Avengers (1996 - 1997)",
  "Avengers (1998 - 2004)",
  "Avengers (2010 - 2012)",
  "Avengers (2012 - 2015)",
  "Avengers (2016 - 2018)",
  "Avengers (2018 - Present)",
  "Avengers 1959 (2011 - 2012)",
  "Avengers a.I. (2013 - 2014)",
  "Avengers Academy (2010 - 2012)",
  "Avengers Academy Giant-Size (2010)",
  "Avengers and Power Pack (2017)",
  "Avengers and Power Pack Assemble! (2006)",
  "Avengers Annual (1967 - 1994)",
  "Avengers Annual (1999)",
  "Avengers Annual (2012)",
  "Avengers Annual (2013)",
  "Avengers Arena (2012 - 2013)",
  "Avengers Assemble (2012 - 2014)",
  "Avengers Assemble Annual (2013)",
  "Avengers Assemble Handbook (2010)",
  "Avengers Classic (2007 - 2008)",
  "Avengers Fairy Tales (2008)",
  "Avengers Finale (2004)",
  "Avengers Forever (1998 - 2001)",
  "Avengers Halloween Special (2018)",
  "Avengers Handbook Featuring the Mighty Avengers (2007)",
  "Avengers Icons: The Vision (2002)",
  "Avengers Next (2006 - 2007)",
  "Avengers No Road Home (2019)",
  "Avengers Now! (2014)",
  "Avengers of the Wastelands (2020)",
  "Avengers Origins: Ant-Man & the Wasp (2013)",
  "Avengers Origins: Luke Cage (2013)",
  "Avengers Origins: Scarlet Witch & Quicksilver (2011)",
  "Avengers Origins: Thor (2011)",
  "Avengers Origins: Vision (2013)",
  "Avengers Posterbook (2013)",
  "Avengers Spotlight (1989 - 1991)",
  "Avengers Spotlight (2010)",
  "AVENGERS STANDOFF: ASSAULT ON PLEASANT HILL ALPHA 1 (2016)",
  "AVENGERS STANDOFF: ASSAULT ON PLEASANT HILL OMEGA 1 (2016)",
  "Avengers Standoff: Welcome to Pleasant Hill (2016)",
  "Avengers Start Here Sampler (2019)",
  "Avengers Unplugged (1995 - 1996)",
  "Avengers Vs (2015)",
  "Avengers VS (2016)",
  "Avengers Vs Infinity (2015 - 2016)",
  "Avengers Vs. Atlas (2010)",
  "Avengers Vs. Pet Avengers (2010 - 2011)",
  "Avengers Vs. X-Men (2012)",
  "Avengers Vs. X-Men Program (2012)",
  "Avengers Vs. X-Men: Consequences (2012)",
  "Avengers Vs. X-Men: Versus (2011 - 2012)",
  "Avengers West Coast Annual (1986 - 1993)",
  "Avengers World (2014 - 2015)",
  "Avengers, Thor & Captain America: Official Index to the Marvel Universe (2010 - 2011)",
  "Avengers, Thor & Captain America: Official Index to the Marvel Universe (2011)",
  "Avengers/Invaders (2008 - 2009)",
  "Avengers/Squadron Supreme Annual (1998)",
  "Avengers/Thunderbolts (2004)",
  "Avengers: Celestial Quest (2001)",
  "Avengers: Deathtrap - The Vault (1991)",
  "Avengers: Earth's Mightiest Heroes (2004 - 2005)",
  "Avengers: Earth's Mightiest Heroes (2010)",
  "Avengers: Earth's Mightiest Heroes (2018)",
  "Avengers: Earth's Mightiest Heroes II (2006 - 2007)",
  "Avengers: Earth's Mightiest Heroes Magazine (2011)",
  "Avengers: Edge Of Infinity (2019)",
  "Avengers: Endless Wartime (2013)",
  "Avengers: Endless Wartime/ONCE Sampler (2013)",
  "Avengers: Infinity (2000)",
  "Avengers: Infinity Quest (2011)",
  "Avengers: Loki Unleashed! (2019)",
  "Avengers: Millennium (2015)",
  "Avengers: No More Bullying (2015)",
  "Avengers: Operation Hydra (2015)",
  "Avengers: Prime (2010 - 2011)",
  "Avengers: Rage of Ultron (2015)",
  "Avengers: Roll Call (2011 - 2012)",
  "Avengers: Season One (2013)",
  "Avengers: Shards of Infinity (2018)",
  "Avengers: Solo (2011 - 2012)",
  "AVENGERS: SOLO ACT Presented by DISNEY CHILD LIFE (2018)",
  "Avengers: Strikefile (1994)",
  "Avengers: The Children's Crusade (2010 - 2012)",
  "Avengers: The Children's Crusade - Young Avengers (2010)",
  "Avengers: The Coming of the Avengers! (2011 - 2012)",
  "Avengers: The Crossing (1995)",
  "Avengers: The Enemy Within (2013)",
  "Avengers: The Initiative (2007 - 2010)",
  "Avengers: The Initiative Annual (2007)",
  "Avengers: The Initiative Featuring Reptil (2009)",
  "Avengers: The Initiative Special (2008)",
  "Avengers: The Origin (2010)",
  "Avengers: The Terminatrix Objective (1993)",
  "AVENGERS: THE ULTRON IMPERATIVE 1 (2001)",
  "Avengers: Timeslide (1996)",
  "Avengers: Ultron Forever (2015)",
  "Avengers: Wakanda Forever (2018)",
  "AVENGERS: WANTED BY THE COLLECTOR! (2019)",
  "Avengers: X-Sanction (2011 - 2012)",
  "Avenging Spider-Man (2011 - 2013)",
  "Avenging Spider-Man Annual (2012)",
  "Avenging Spider-Man Daily Bugle (2011)",
  "Axis: Carnage (2014)",
  "Axis: Hobgoblin (2014)",
  "Axis: Revolutions (2014)",
  "Baby's First Deadpool Book (1998)",
  "Balder the Brave (1985)",
  "Battle Scars (2011 - 2012)",
  "Battlefield (1952 - 1953)",
  "Beauty and the Beast (1985)",
  "Before the Fantastic Four: Ben Grimm & Logan (2000)",
  "Ben Reilly: Scarlet Spider (2017 - 2018)",
  "Beta Ray Bill: Godhunter (2009)",
  "Beyond! (2006)",
  "Big Hero 6 (2008 - 2009)",
  "Big Hero Six Sketchbook (2008)",
  "Big Thunder Mountain Railroad (2015)",
  "Bishop (1994 - 1995)",
  "Bishop: The Last X-Man (1999 - 2001)",
  "Bizarre Adventures (1981)",
  "Bizarre Adventures (2019)",
  "Black Bolt (2017 - 2018)",
  "Black Bolt: Something Inhuman This Way Comes (2013)",
  "Black Cat (2019 - Present)",
  "Black Cat Annual (2019)",
  "Black Goliath (1976)",
  "Black Knight (2015 - 2016)",
  "Black Order (2018 - 2019)",
  "Black Panther (1977 - 1979)",
  "Black Panther (1988)",
  "Black Panther (1998 - 2003)",
  "Black Panther (2005 - 2008)",
  "Black Panther (2009 - 2010)",
  "Black Panther (2016 - 2018)",
  "Black Panther (2018 - Present)",
  "Black Panther - Long Live the King (2017 - 2018)",
  "Black Panther 2099 (2004)",
  "Black Panther and the Agents of Wakanda (2019 - Present)",
  "Black Panther and the Crew (2017)",
  "Black Panther Annual (2008)",
  "Black Panther Annual (2018)",
  "Black Panther Saga (2008)",
  "Black Panther Vs. Deadpool (2018 - 2019)",
  "Black Panther: Soul of a Machine – Chapter Eight (2018)",
  "Black Panther: Soul of a Machine – Chapter Five (2018)",
  "Black Panther: Soul of a Machine – Chapter Four (2017)",
  "Black Panther: Soul of a Machine – Chapter One (2017)",
  "Black Panther: Soul of a Machine – Chapter Seven (2018)",
  "Black Panther: Soul of a Machine – Chapter Six (2018)",
  "Black Panther: Soul of a Machine – Chapter Three (2017)",
  "Black Panther: Soul of a Machine – Chapter Two (2017)",
  "Black Panther: The Man Without Fear (2010 - 2011)",
  "Black Panther: The Most Dangerous Man Alive (2010 - 2012)",
  "Black Panther: The Sound and The Fury (2018)",
  "Black Panther: World of Wakanda (2016 - 2017)",
  "Black Widow & the Marvel Girls (2009 - 2010)",
  "Black Widow (1999)",
  "Black Widow (2001)",
  "Black Widow (2004 - 2005)",
  "Black Widow (2010 - 2011)",
  "Black Widow (2014 - 2015)",
  "Black Widow (2016 - 2017)",
  "Black Widow (2019 - Present)",
  "Black Widow: Deadly Origin (2009 - 2010)",
  "Black Widow: Deadly Origin (2010)",
  "Black Widow: The Things They Say About Her (2005 - 2006)",
  "Blackwulf (1994 - 1995)",
  "Blade (1998)",
  "Blade (2006 - 2007)",
  "Blade the Vampire Hunter (1994 - 1995)",
  "Blade: Crescent City Blues (1998)",
  "BLADE: SINS OF THE FATHER 1 (1998)",
  "Blade: Vampire Hunter (1999 - 2000)",
  "Blink (2001)",
  "Blockbusters of the Marvel Universe (2011)",
  "Bloodstone (2001 - 2002)",
  "Book of Lost Souls (2005 - 2006)",
  "Books of Doom (2005 - 2006)",
  "Breaking Into Comics the Marvel Way! (2010)",
  "Brilliant (2011 - 2014)",
  "Brute Force (1990)",
  "Bucky Barnes: The Winter Soldier (2014 - 2015)",
  "BUG 1 (1997)",
  "Bullet Points (2006 - 2007)",
  "Bullseye (2017)",
  "Bullseye: Greatest Hits (2004 - 2005)",
  "Bullseye: Perfect Game (2010)",
  "Cable & Deadpool (2004 - 2008)",
  "Cable & Deadpool MGC (2011)",
  "Cable (1993 - 2002)",
  "Cable (2008 - 2010)",
  "Cable (2017 - 2018)",
  "Cable and X-Force (2012 - 2014)",
  "Cable Annual (1999)",
  "Cable/Deadpool Annual (2018)",
  "Cable/Machine Man Annual (1998)",
  "Cable: Blood & Metal (1992)",
  "Cage (1992 - 1993)",
  "Cage! (2016)",
  "Cap and Thor! Avengers (2010)",
  "Cap Transport (2005 - 2006)",
  "Captain America & Citizen V Annual (1998)",
  "Captain America & the Falcon (2004 - 2005)",
  "Captain America & The Invaders: Bahamas Triangle (2019)",
  "Captain America & the Korvac Saga (2010 - 2011)",
  "Captain America & the Mighty Avengers (2014 - 2015)",
  "Captain America (1968 - 1996)",
  "Captain America (1996 - 1998)",
  "Captain America (1998 - 2002)",
  "Captain America (2002 - 2004)",
  "Captain America (2004 - 2011)",
  "Captain America (2011 - 2012)",
  "Captain America (2012 - 2014)",
  "Captain America (2017 - 2018)",
  "Captain America (2018 - Present)",
  "Captain America 65th Anniversary (2006)",
  "Captain America 70th Anniversary Edition (2011)",
  "Captain America 75th Anniversary Magazine (2016)",
  "Captain America and Batroc (2010)",
  "Captain America and Bucky (2011 - 2012)",
  "CAPTAIN AMERICA AND CROSSBONES 1 (2010 - 2011)",
  "Captain America and the Falcon (2010 - 2011)",
  "Captain America and the First Thirteen (2011)",
  "Captain America and the Secret Avengers (2011)",
  "Captain America Annual (1971 - 1991)",
  "Captain America Annual (1999)",
  "Captain America Annual (2000)",
  "Captain America Annual (2001)",
  "Captain America Annual (2018)",
  "Captain America Comics (1941 - 1954)",
  "Captain America Comics 70th Anniversary Special (2009 - 2011)",
  "Captain America Comics: 70th Anniversary Edition (2010 - 2011)",
  "Captain America Corps (2011)",
  "Captain America MGC (2010)",
  "Captain America Poster Book (2009)",
  "Captain America Poster Book (2011)",
  "Captain America Special (2015)",
  "Captain America Theater of War: A Brother in Arms (2009)",
  "Captain America Theater of War: America First! (2008)",
  "Captain America Theater of War: America the Beautiful (2009)",
  "Captain America Theater of War: Operation Zero-Point (2008)",
  "Captain America Theater of War: To Soldier on (2009)",
  "Captain America/Black Panther: Flags of Our Fathers (2010)",
  "Captain America: America's Avenger (2010)",
  "Captain America: Dead Men Running (2002)",
  "Captain America: First Vengeance (2011)",
  "Captain America: Forever Allies (2010)",
  "Captain America: Hail Hydra (2010 - 2011)",
  "Captain America: Homecoming (2014)",
  "Captain America: Living Legend (2010 - 2013)",
  "Captain America: Man Out of Time (2010 - 2011)",
  "Captain America: Patriot (2010)",
  "Captain America: Peggy Carter, Agent of S.H.I.E.L.D. (2014)",
  "Captain America: Rebirth (2010 - 2011)",
  "Captain America: Reborn (2009 - 2010)",
  "CAPTAIN AMERICA: REBORN MGC 1 (2011)",
  "Captain America: Road to War (2016)",
  "Captain America: Sam Wilson (2015 - 2017)",
  "Captain America: Serpents Unite! (2016)",
  "Captain America: Steve Rogers (2016 - 2017)",
  "Captain America: The 1940s Newspaper Strip (2010)",
  "Captain America: The Chosen (2007)",
  "Captain America: The Fighting Avenger (2010 - 2011)",
  "Captain America: Theater of War: Ghosts of My Country (2009)",
  "Captain America: Theater of War: Prisoners of Duty (2009)",
  "Captain America: Triple Threat (2014)",
  "Captain America: What Price Glory? (2003)",
  "Captain America: White (2008)",
  "Captain America: White (2015)",
  "Captain America: Who Will Wield the Shield? (2009)",
  "CAPTAIN AMERICA: WHO WON'T WIELD THE SHIELD 1 (2010)",
  "Captain America: Winter Soldier Director's Cut (2014)",
  "Captain Britain (1976 - 1977)",
  "Captain Britain (1985 - 1986)",
  "Captain Britain and Mi13 Annual (2009)",
  "Captain Britain and MI: 13 (2008 - 2009)",
  "Captain Britain and the Mighty Defenders (2015)",
  "Captain Marvel & The Carol Corps (2015)",
  "Captain Marvel (1968 - 1979)",
  "Captain Marvel (1989)",
  "Captain Marvel (1994)",
  "Captain Marvel (2000 - 2002)",
  "Captain Marvel (2002 - 2004)",
  "Captain Marvel (2008)",
  "Captain Marvel (2012 - 2013)",
  "Captain Marvel (2014 - 2015)",
  "Captain Marvel (2016 - 2017)",
  "Captain Marvel (2019 - Present)",
  "Captain Marvel: Braver & Mightier (2019)",
  "Captain Marvel: Start Here Sampler (2019)",
  "Captain Marvel: The End (2020)",
  "Captain Savage and His Leatherneck Raiders (1968 - 1970)",
  "Captain Universe (2005)",
  "Captain Universe: The Hero Who Could Be You (2013)",
  "Carnage (2010 - 2011)",
  "Carnage (2015 - 2017)",
  "Carnage, U.S.A. (2011 - 2012)",
  "Carnage: It's A Wonderful Life (1996)",
  "Carnage: Mind Bomb (1996)",
  "Cars 2 (2011)",
  "Casanova: Avarita (2011 - 2012)",
  "Casanova: Gula (2011)",
  "Castle: A Calm Before Storm (2012 - 2013)",
  "Cataclysm (2013)",
  "Cataclysm: The Ultimates' Last Stand (2013 - 2014)",
  "Cataclysm: Ultimate Spider-Man (2013 - 2014)",
  "Cataclysm: Ultimate X-Men (2013 - 2014)",
  "Cataclysm: Ultimates (2013 - 2014)",
  "Century: Distant Sons (1996)",
  "Cerebro's Guide to the X-Men (1998)",
  "Chamber (2002 - 2003)",
  "Champions (1975 - 1978)",
  "Champions (2016 - 2019)",
  "Champions (2019 - Present)",
  "Champions Annual (2018)",
  "Champions: No Time for Losers (2016)",
  "Chaos War (2010 - 2011)",
  "Chaos War: Alpha Flight (2010)",
  "Chaos War: Ares (2010)",
  "Chaos War: Chaos King (2010)",
  "Chaos War: Dead Avengers (2010)",
  "Chaos War: God Squad (2010)",
  "Chaos War: Thor (2010)",
  "Chaos War: X-Men (2010)",
  "Chewbacca (2015)",
  "Citizen V and the V-Battalion (2001)",
  "Citizen V and the V-Battalion: The Everlasting (2002)",
  "Civil War (2006 - 2007)",
  "Civil War (2015)",
  "Civil War Chronicles (2007)",
  "Civil War Files (2006)",
  "Civil War II (2016)",
  "Civil War II: Amazing Spider-Man (2016 - 2017)",
  "Civil War II: Choosing Sides (2016)",
  "Civil War II: Gods of War (2016)",
  "Civil War II: Kingpin (2016)",
  "Civil War II: The Oath (2017)",
  "Civil War II: Ulysses Infinite Comic (2016)",
  "Civil War II: X-Men (2016)",
  "Civil War MGC (2010)",
  "Civil War Poster Book (2007)",
  "Civil War Sketchbook (2006)",
  "Civil War: Battle Damage Report (2007)",
  "Civil War: Choosing Sides (2006)",
  "Civil War: Front Line (2006 - 2007)",
  "Civil War: House of M (2008 - 2009)",
  "Civil War: Opening Shot (2006)",
  "Civil War: The Confession (2007)",
  "Civil War: The Initiative (2007)",
  "Civil War: The Return (2007)",
  "Civil War: War Crimes (2006)",
  "Civil War: X-Men (2006)",
  "Civil War: Young Avengers & Runaways (2006)",
  "Clandestine (1994 - 1995)",
  "Clandestine (2008)",
  "Classic Star Wars (1992 - 1994)",
  "Classic Star Wars: Devilworlds (1996)",
  "Classic Star Wars: Han Solo at Stars' End (1997)",
  "Classic Star Wars: The Early Adventures (1994 - 1995)",
  "Classic X-Men (1986 - 1990)",
  "Claws (2006)",
  "Cloak and Dagger (1983)",
  "Cloak and Dagger (1985 - 1987)",
  "Cloak and Dagger (2010)",
  "Cloak and Dagger (2018)",
  "Cloak and Dagger: Marvel Digital Original - Negative Exposure (2018 - 2019)",
  "Cloak and Dagger: Marvel Digital Original - Shades of Gray (2018)",
  "Cloak and Dagger: Predator and Prey (1988)",
  "Code of Honor (1997)",
  "Combat Kelly and the Deadly Dozen (1972 - 1973)",
  "Combat Zone: True Tales of Gi's in Iraq (2005)",
  "Conan (2004 - 2008)",
  "Conan 2099 (2019)",
  "Conan Annual (1973)",
  "Conan the Barbarian (1970 - 1993)",
  "Conan the Barbarian (2019 - Present)",
  "Conan The Barbarian: Exodus (2019)",
  "CONAN THE BARBARIAN: THE MASK OF ACHERON 1 (2011 - Present)",
  "Conan the Cimmerian (2008 - 2010)",
  "Conan: Book of Thoth (2006 - Present)",
  "Conan: Serpent War (2019 - 2020)",
  "Conan: Serpent War Director's Cut Edition (2019)",
  "Conan: The Legend (2003)",
  "Contagion (2019 - 2020)",
  "Contest of Champions (2015 - 2016)",
  "Cosmic Ghost Rider (2018)",
  "Cosmic Ghost Rider Destroys Marvel History (2019)",
  "Cosmic Powers (1994)",
  "Crazy (2019)",
  "Creatures on the Loose (1971 - 1975)",
  "Crew (2003)",
  "Criminal (2006 - 2007)",
  "Criminal 2 (2008)",
  "Criminal: The Last of the Innocent (2011)",
  "Criminal: The Sinners (2009 - 2010)",
  "Crimson Dynamo (2003 - 2004)",
  "Crypt of Shadows (2019)",
  "CUSTOM DJI DRONES COMIC (2015)",
  "CUSTOM NETFLIX JESSICA JONES NYCC COMIC (2015)",
  "CUSTOM WESTERN UNION DIGITAL COMIC (2015)",
  "Cyclops (2010)",
  "Cyclops (2014 - 2015)",
  "D.P.7 (1986 - 1989)",
  "Daily Bugle (1996 - 1997)",
  "Daken: Dark Wolverine (2010 - 2012)",
  "Dakota North (1986 - 1987)",
  "Damage Control (1989 - 1990)",
  "Damage Control (1989)",
  "Damage Control (1991)",
  "Damnation: Johnny Blaze - Ghost Rider (2018)",
  "Daredevil & Captain America: Dead on Arrival (2008)",
  "Daredevil (1964 - 1998)",
  "Daredevil (1998 - 2011)",
  "Daredevil (2011 - 2014)",
  "Daredevil (2014 - 2015)",
  "Daredevil (2015 - 2018)",
  "Daredevil (2019 - Present)",
  "Daredevil 181 Facsimile Edition (2019)",
  "Daredevil 2099 (2004)",
  "Daredevil Annual (1967 - 1990)",
  "Daredevil Annual (2007)",
  "Daredevil Annual (2012)",
  "Daredevil Annual (2016)",
  "Daredevil Annual (2018)",
  "Daredevil Noir (2009)",
  "Daredevil Saga (2008)",
  "Daredevil Vs. Punisher (2005)",
  "DAREDEVIL/PUNISHER: SEVENTH CIRCLE INFINITE COMIC (2016)",
  "Daredevil/Spider-Man (2001)",
  "Daredevil: Battlin' Jack Murdock (2007)",
  "Daredevil: Black and White (2010)",
  "Daredevil: Blood of the Tarantula (2008)",
  "Daredevil: Cage Match (2010)",
  "Daredevil: Dark Nights (2013 - 2014)",
  "Daredevil: End of Days (2012 - 2013)",
  "Daredevil: Father (2004 - 2006)",
  "Daredevil: Love and War (1986)",
  "Daredevil: Ninja (2000 - 2001)",
  "Daredevil: Reborn (2010 - 2011)",
  "Daredevil: Redemption (2005)",
  "Daredevil: The Man Without Fear (1993)",
  "Daredevil: Yellow (2001 - 2002)",
  "Daring Comics (1940 - 1942)",
  "Daring Mystery Comics (1940 - 1942)",
  "Daring Mystery Comics 70th Anniversary Special (2009)",
  "Dark Avengers (2009 - 2010)",
  "Dark Avengers (2012 - 2013)",
  "Dark Avengers Annual (2009)",
  "Dark Avengers Poster Book (2010)",
  "Dark Avengers/Uncanny X-Men: Exodus (2009)",
  "Dark Avengers/Uncanny X-Men: Utopia (2009)",
  "Dark Avengers: Ares (2009 - 2010)",
  "Dark Reign - The List: Amazing Spider-Man One-Shot (2009)",
  "Dark Reign Previews (2008)",
  "Dark Reign: Elektra (2009)",
  "Dark Reign: Fantastic Four (2009)",
  "Dark Reign: Hawkeye (2009 - 2010)",
  "Dark Reign: Lethal Legion (2009)",
  "Dark Reign: Made Men (2009)",
  "Dark Reign: Mister Negative (2009)",
  "Dark Reign: New Nation (2008)",
  "Dark Reign: New Nation (Secret Warriors) (2008)",
  "Dark Reign: New National Special (2008)",
  "Dark Reign: Skrull Kill Krew (2009)",
  "Dark Reign: The Cabal (2009)",
  "Dark Reign: The Goblin Legacy (2009)",
  "Dark Reign: The Hood (2009)",
  "Dark Reign: The List - Amazing Spider-Man One-Shot (2009)",
  "Dark Reign: The List - Avengers (2009)",
  "Dark Reign: The List - Daredevil (2009)",
  "Dark Reign: The List - Hulk (2009)",
  "Dark Reign: The List - Punisher (2009)",
  "Dark Reign: The List - Secret Warriors (2009)",
  "Dark Reign: The List - Spider-Man One-Shot (2009)",
  "Dark Reign: The List - Wolverine (2009)",
  "Dark Reign: The List - X-Men (2009)",
  "Dark Reign: The Sinister Spider-Man (2009)",
  "Dark Reign: Young Avengers (2009)",
  "Dark Reign: Zodiac (2009)",
  "Dark Tower Sketchbook (2006)",
  "Dark Tower: End-World (2008)",
  "Dark Tower: Guide to Gilead (2009)",
  "Dark Tower: Gunslinger's Guidebook (2007)",
  "Dark Tower: Sorcerer (2009)",
  "Dark Tower: The Battle of Jericho Hill (2009 - 2010)",
  "Dark Tower: The Drawing of the Three - Bitter Medicine (2016)",
  "Dark Tower: The Drawing of the Three - House of Cards (2015)",
  "Dark Tower: The Drawing of the Three - Lady of Shadows (2015 - 2016)",
  "Dark Tower: The Drawing of the Three - The Prisoner (2014)",
  "Dark Tower: The Drawing of the Three - The Sailor (2016 - 2017)",
  "Dark Tower: The Fall of Gilead (2009)",
  "Dark Tower: The Gunslinger - Evil Ground (2013)",
  "Dark Tower: The Gunslinger - Sheemie's Tale (2014)",
  "Dark Tower: The Gunslinger - So Fell Lord Perth (2013)",
  "Dark Tower: The Gunslinger - The Battle of Tull (2011)",
  "Dark Tower: The Gunslinger - The Journey Begins (2010)",
  "Dark Tower: The Gunslinger - The Little Sisters of Eluria (2011)",
  "Dark Tower: The Gunslinger - The Man In Black (2012)",
  "Dark Tower: The Gunslinger - The Way Station (2011 - 2013)",
  "Dark Tower: The Gunslinger Born (2007)",
  "DARK TOWER: THE GUNSLINGER BORN MGC 1 (2011)",
  "Dark Tower: The Long Road Home (2008)",
  "Dark Tower: Treachery (2008 - 2009)",
  "Dark Wolverine (2009 - 2010)",
  "Dark X-Men (2009)",
  "Dark X-Men: The Beginning (2009)",
  "Dark X-Men: The Confession (2009)",
  "Darkdevil (2000)",
  "Darkhawk (1991 - 1995)",
  "Darkhawk (2017)",
  "Darkhold: Pages from The Book of Sins (1992)",
  "Darkstar and the Winter Guard (2010)",
  "Darth Vader (2015 - 2016)",
  "Darth Vader (2017 - 2018)",
  "Darth Vader Annual (2015 - 2018)",
  "Daughters of the Dragon (2006)",
  "Daughters of the Dragon: Deadly Hands Special (2005)",
  "Daughters of the Dragon: Marvel Digital Original (2018 - 2019)",
  "Dazzler (1981 - 1986)",
  "Dazzler (2010)",
  "Dazzler Facsimile Edition (2019)",
  "Dazzler: X-Song (2018)",
  "Dead Man Logan (2018 - Present)",
  "Dead of Night (1973 - 1975)",
  "Dead of Night Featuring Devil-Slayer (2008)",
  "Dead of Night Featuring Man-Thing (2008)",
  "Dead of Night Featuring Werewolf by Night (2009)",
  "Deadline (2002)",
  "Deadly Foes of Spider-Man (1991)",
  "Deadly Hands of Kung Fu (1974 - 1977)",
  "Deadly Hands of Kung Fu (2014)",
  "Deadpool & Cable (2010)",
  "Deadpool & Cable: Split Second (2015 - 2016)",
  "Deadpool & Cable: Split Second Infinite Comic (2015 - 2016)",
  "Deadpool & the Mercs for Money (2016 - 2017)",
  "Deadpool & The Mercs For Money (2016)",
  "Deadpool (1994)",
  "Deadpool (1997 - 2002)",
  "Deadpool (2008 - 2012)",
  "Deadpool (2010)",
  "Deadpool (2012 - 2015)",
  "Deadpool (2015 - 2017)",
  "Deadpool (2018 - 2019)",
  "Deadpool (2019 - 2020)",
  "Deadpool and Death Annual (1998)",
  "Deadpool Annual (2011)",
  "Deadpool Annual (2014)",
  "Deadpool Annual (2016)",
  "Deadpool Annual (2019)",
  "Deadpool Annual 2013 (2013)",
  "Deadpool Annual: Games of Death (2009)",
  "Deadpool Bi-Annual (2014)",
  "Deadpool Corps (2010 - 2011)",
  "Deadpool Corps: Rank and Foul (2010)",
  "Deadpool Family (2010 - 2011)",
  "Deadpool Kills Deadpool (2013)",
  "Deadpool Kills the Marvel Universe (2011 - 2012)",
  "Deadpool Kills the Marvel Universe Again (2017)",
  "Deadpool Killustrated (2013)",
  "Deadpool Max (2010 - 2011)",
  "Deadpool Max 2 (2011 - 2012)",
  "Deadpool Max X-Mas Special (2011)",
  "Deadpool Max: A History of Violence (2011)",
  "Deadpool Poster Book (2009)",
  "Deadpool Pulp (2010)",
  "Deadpool Saga (2008)",
  "Deadpool Team-Up (1998)",
  "Deadpool Team-Up (2009 - 2011)",
  "Deadpool Team-Up (2010 - 2011)",
  "Deadpool the Duck (2017)",
  "Deadpool V Gambit (2016)",
  "Deadpool Vs. Carnage (2014)",
  "Deadpool Vs. Old Man Logan (2017 - 2018)",
  "Deadpool Vs. Thanos (2015)",
  "Deadpool Vs. the Punisher (2017)",
  "Deadpool Vs. X-Force (2014)",
  "Deadpool's Art of War (2014 - 2015)",
  "Deadpool's Secret Secret Wars (2015)",
  "Deadpool/Gli - Summer Fun Spectacular (2007)",
  "Deadpool: Assassin (2018)",
  "Deadpool: Back in Black (2016)",
  "Deadpool: Dracula's Gauntlet (2014)",
  "Deadpool: Last Days of Magic (2016)",
  "Deadpool: Masacre (2016)",
  "Deadpool: Merc with a Mouth (2009 - 2010)",
  "Deadpool: Secret Agent Deadpool (2018)",
  "Deadpool: Suicide Kings (2009)",
  "Deadpool: The Circle Chase (1993)",
  "Deadpool: The End (2020)",
  "Deadpool: The Gauntlet (2014)",
  "Deadpool: Too Soon (2016)",
  "Deadpool: Too Soon? Infinite Comic (2016)",
  "Deadpool: Wade Wilson's War (2010)",
  "Death of Dracula (2010)",
  "Death of the Inhumans (2018)",
  "Death of Wolverine (2014)",
  "Death of Wolverine: Deadpool & Captain America (2014)",
  "DEATH OF WOLVERINE: LIFE AFTER LOGAN 1 (2014)",
  "Death of Wolverine: The Logan Legacy (2014)",
  "Death of Wolverine: The Weapon X Program (2014)",
  "Death of X (2016)",
  "Death's Head (1988)",
  "Death's Head (2019)",
  "DEATH'S HEAD: THE BODY IN QUESTION 1 (1991 - Present)",
  "Deathlok (1990)",
  "Deathlok (1991)",
  "Deathlok (1999)",
  "Deathlok (2009 - 2010)",
  "Deathlok (2014 - 2015)",
  "Deathlok Annual (1992)",
  "December Daily (2006)",
  "Decimation: House of M - The Day After (2005)",
  "Defenders (1972 - 1986)",
  "Defenders (2005 - 2006)",
  "Defenders (2011 - 2012)",
  "Defenders (2017 - 2018)",
  "Defenders Annual (1976)",
  "Defenders: From the Marvel Vault (2011)",
  "Defenders: Indefensible (2005)",
  "Defenders: Marvel Feature: Facsimile Edition (2019)",
  "Defenders: Strange Heroes (2011)",
  "Defenders: The Best Defense (2018)",
  "Defenders: The Coming of the Defenders (2011)",
  "Defenders: Tournament of Heroes (2011 - 2012)",
  "Despicable Deadpool (2017 - 2018)",
  "Destroyer (2009)",
  "Devil Dinosaur (1978)",
  "Dexter (2013)",
  "Dexter Down Under (2014)",
  "Disney Kingdoms: Seekers of the Weird (2014)",
  "Disney/Muppets Presents: Meet The Muppets (2011)",
  "Disney/Pixar Giant Size Comics (2011)",
  "Disney/Pixar Presents: Toy Story (2011 - 2012)",
  "District X (2004 - 2005)",
  "Doc Samson (2006)",
  "Doctor Doom (2019 - 2020)",
  "Doctor Doom and the Masters of Evil (2009)",
  "Doctor Spectrum (2004 - 2005)",
  "Doctor Strange (1968 - 1969)",
  "Doctor Strange (1974 - 1988)",
  "Doctor Strange (2015 - 2018)",
  "Doctor Strange (2018 - Present)",
  "Doctor Strange and the Sorcerers Supreme (2016 - 2017)",
  "Doctor Strange Annual (2016)",
  "Doctor Strange Annual (2019)",
  "Doctor Strange Sorcerer Supreme Annual (1992 - 1994)",
  "Doctor Strange, Sorcerer Supreme (1988 - 1996)",
  "Doctor Strange/Punisher: Magic Bullets (2016 - 2017)",
  "Doctor Strange/Punisher: Magic Bullets Infinite Comic (2016 - 2017)",
  "Doctor Strange: Damnation (2018)",
  "Doctor Strange: Last Days of Magic (2016)",
  "Doctor Strange: Mystic Apprentice (2016)",
  "Doctor Strange: The Best Defense (2018)",
  "Doctor Strange: The End (2020)",
  "Doctor Strange: The Oath (2006 - 2007)",
  "Doctor Voodoo: Avenger of the Supernatural (2009 - 2010)",
  "Doctor Voodoo: The Origin of Jericho Drumm One-Shot (2009)",
  "Dominic Fortune (2009)",
  "Domino (1997)",
  "Domino (2003)",
  "Domino (2018)",
  "DOMINO ANNUAL 1 (2018)",
  "Domino: Hotshots (2019)",
  "Doom (2000)",
  "Doom 2099 (1993)",
  "Doom 2099 (2019)",
  "Doom: The Emperor Returns (2002)",
  "Doomwar (2010)",
  "Dorothy & the Wizard in Oz (2011 - 2012)",
  "Dr. Strange (2019 - Present)",
  "Dracula (2010)",
  "Dragon's Claw (1988 - Present)",
  "Drax (2015 - 2016)",
  "Drax the Destroyer (2005)",
  "Dream Logic (2010)",
  "Dream Police (2005)",
  "E Is for Extinction (2015)",
  "Earth X (1999)",
  "Eden's Trail (2003)",
  "Edge of Spider-Geddon (2018)",
  "Edge of Spider-Verse (2014)",
  "Edge of Venomverse (2017)",
  "Electric Ant (0000 - 2010)",
  "Elektra (1996 - 1998)",
  "Elektra (2001 - 2004)",
  "Elektra (2014 - 2015)",
  "Elektra (2017)",
  "Elektra and Wolverine: The Redeemer (2002)",
  "Elektra: Glimpse and Echo (2002)",
  "Elektra: Root of Evil (1995)",
  "Elektra: The Hand (2004)",
  "Elektra: The Movie (2004)",
  "Elks Custom Comic Book (2006)",
  "Emma (2011)",
  "Emma Frost (2003 - 2004)",
  "Empress (2016)",
  "Enchanted Tiki Room (2016 - 2017)",
  "Ender in Exile (2010)",
  "Ender's Game (2008 - 2009)",
  "Ender's Game: Mazer in Prison Special (2010)",
  "Ender's Game: The League War (2010)",
  "Ender's Game: War of Gifts Special (2009)",
  "Ender's Shadow: Battle School (2008 - 2009)",
  "Enders Game: Command School (2009 - 2010)",
  "Enders Shadow: Command School (2009 - 2010)",
  "Enter the Heroic Age (2010)",
  "Epic Anthology (2004)",
  "EPIC GRAPHIC NOVEL: THE PUNISHER - RETURN TO BIG NOTHING 1 (1989)",
  "Essential Iron Man Vol. 2 (2010 - 2011)",
  "Essential Iron Man Vol. 3 (2010 - 2011)",
  "Eternals (1976 - 1978)",
  "Eternals (2006 - 2007)",
  "Eternals (2008 - 2009)",
  "Eternals Annual (2008 - 2009)",
  "ETERNALS ANNUAL 1 (1977)",
  "Eternals: Facsimile Edition (2019)",
  "Eternals: Secrets From The Marvel Universe (2019)",
  "Excalibur (1988 - 1998)",
  "Excalibur (2004 - 2005)",
  "Excalibur (2019 - Present)",
  "Excalibur: Air Apparent (1991)",
  "EXCALIBUR: MOJO MAYHEM 1 (1989)",
  "Excalibur: The Possession (1991)",
  "Excalibur: The Sword Is Drawn (1988)",
  "EXCALIBUR: WEIRD WAR III GRAPHIC NOVEL 1 (1990)",
  "Excalibur: XX Crossing (1992)",
  "Exiled (2011 - 2012)",
  "Exiles (2001 - 2008)",
  "Exiles (2009)",
  "Exiles (2018 - 2019)",
  "Exiles Annual (2006)",
  "Exiles Sketchbook (2009)",
  "Exiles Ultimate Collection Book 6 (2010)",
  "Exiles Vol. 5: Unnatural Instincts (2005)",
  "Exiles: Days of Then and Now (2008)",
  "Extermination (2018)",
  "Extraordinary X-Men (2015 - 2017)",
  "Extraordinary X-Men Annual (2016)",
  "Factor X (1995)",
  "Falcon (2017 - 2018)",
  "Fall of the Hulks Alpha (2009)",
  "Fall of the Hulks Gamma (2009)",
  "Fall of the Hulks: Red Hulk (2010)",
  "Fall of the Hulks: The Savage She-Hulks (2010)",
  "Fallen Angels (1987)",
  "Fallen Angels (2019 - 2020)",
  "Fallen Son: The Death of Captain America (2007)",
  "Fantastic Five (1999 - 2000)",
  "Fantastic Five (2007)",
  "Fantastic Force (1994 - 1996)",
  "Fantastic Force (2009)",
  "Fantastic Four (1961 - 1998)",
  "Fantastic Four (1996 - 1997)",
  "Fantastic Four (1998 - 2012)",
  "Fantastic Four (2012 - 2014)",
  "Fantastic Four (2014 - 2015)",
  "Fantastic Four (2018 - Present)",
  "Fantastic Four 1 2 3 4 (2001)",
  "Fantastic Four 2099 (2019)",
  "Fantastic Four and Power Pack (2007)",
  "Fantastic Four and Power Pack (2018)",
  "Fantastic Four Annual (1963 - 1994)",
  "Fantastic Four Annual (1998)",
  "Fantastic Four Annual (2001)",
  "Fantastic Four Annual (2010)",
  "Fantastic Four Annual (2012)",
  "Fantastic Four Annual (2014)",
  "FANTASTIC FOUR ANNUAL 1 (1999 - Present)",
  "FANTASTIC FOUR ANNUAL 1 (2000 - Present)",
  "Fantastic Four Annual Facsimile Edition (2020)",
  "Fantastic Four Cosmic-Size (2008)",
  "Fantastic Four Facsimile Edition (2018)",
  "Fantastic Four Giant-Size Adventures (2009)",
  "Fantastic Four in...Ataque Del M.O.D.O.K.! (2010)",
  "Fantastic Four MGC (2011)",
  "FANTASTIC FOUR PRESENTS FRANKLIN RICHARDS SON OF A GENIUS 1 (2005)",
  "Fantastic Four Roast (1982)",
  "FANTASTIC FOUR SPECIAL 2005 1 (2005)",
  "Fantastic Four Unlimited (1993 - 1995)",
  "Fantastic Four Vs. X-Men (1987)",
  "Fantastic Four/Iron Man: Big in Japan (2005 - 2006)",
  "Fantastic Four/Silver Surfer Poster (2007)",
  "Fantastic Four: 1234 (2001 - 2002)",
  "Fantastic Four: 4 Yancy Street (2019)",
  "Fantastic Four: A Death in the Family (2006)",
  "Fantastic Four: Atlantis Rising (1995)",
  "Fantastic Four: First Family (2006)",
  "Fantastic Four: Foes (2005)",
  "Fantastic Four: Grand Design (2019)",
  "Fantastic Four: House of M (2005)",
  "Fantastic Four: Isla De La Muerte (Spanish Language Edition) (2008)",
  "Fantastic Four: Isla De La Muerte! (2007)",
  "Fantastic Four: Negative Zone (2019)",
  "Fantastic Four: Resurrection of Galactus (2010)",
  "Fantastic Four: Season One (2012)",
  "Fantastic Four: The End (2006 - 2007)",
  "Fantastic Four: The Last Stand (2011)",
  "Fantastic Four: The Legend (1996)",
  "Fantastic Four: The Lost Adventure (2008)",
  "Fantastic Four: The Movie (2005)",
  "Fantastic Four: The Prodigal Sun (2019)",
  "Fantastic Four: The Wedding Special (2005)",
  "Fantastic Four: True Story (2008 - 2009)",
  "Fantastic Four: Wedding Special (2018)",
  "Fantastic Four: World's Greatest Comics Magazine (2001 - 2002)",
  "Fantasy Masterpieces (1966 - 1967)",
  "Fantomex Max (2013 - 2014)",
  "Fear Itself (2010 - 2011)",
  "Fear Itself 7.2: Thor (2011)",
  "Fear Itself Sketchbook (2011)",
  "Fear Itself Spotlight (2011)",
  "Fear Itself: Black Widow (2011)",
  "Fear Itself: Deadpool (2011)",
  "Fear Itself: Fearsome Four (2011)",
  "Fear Itself: Fellowship of Fear (2011)",
  "Fear Itself: FF (2011)",
  "Fear Itself: Monkey King (2011)",
  "Fear Itself: Sin's Past (2011)",
  "Fear Itself: Spider-Man (2011)",
  "Fear Itself: The Book of the Skull (2011)",
  "Fear Itself: The Deep (2011)",
  "Fear Itself: The Fearless (2011 - 2012)",
  "Fear Itself: The Home Front (2010)",
  "Fear Itself: Uncanny X-Force (2011)",
  "Fear Itself: Wolverine (2011)",
  "Fear Itself: Youth in Revolt (2011)",
  "Fearless (2019)",
  "Fearless Defenders (2013)",
  "February Sneak Peeks (2010)",
  "FF (2011 - 2012)",
  "FF (2012 - 2014)",
  "FF: 50 Fantastic Years (2010 - 2011)",
  "Fight Man (1993)",
  "Figment (2014)",
  "Figment 2 (2015 - 2016)",
  "Fin Fang Four Digital Comic (2008 - 2009)",
  "Fin Fang Four Return! (2009)",
  "Firestar (1986)",
  "Firestar (2010)",
  "First X-Men (2011 - 2013)",
  "Foolkiller (2007 - 2008)",
  "Foolkiller (2016 - 2017)",
  "Foolkiller: Short Time (2008)",
  "Foom (1973 - Present)",
  "Force Works (1994 - 1996)",
  "Formic Wars: Burning Earth (2011)",
  "Formic Wars: Silent Strike (2011 - 2012)",
  "Frankencastle (2010)",
  "Frankenstein (1973 - 1975)",
  "Franklin Richards Saga (2008)",
  "Franklin Richards: A Fantastic Year (2018)",
  "Franklin Richards: April Fools! (2009)",
  "Franklin Richards: Everybody Loves Franklin (2006)",
  "Franklin Richards: Fall Football Fiasco! (2007)",
  "Franklin Richards: Franklin's Birthday! (2008)",
  "Franklin Richards: Happy Franksgiving (2006)",
  "Franklin Richards: It's Dark Reigning Cats & Dogs (2009)",
  "Franklin Richards: March Madness (2007)",
  "Franklin Richards: Monster Mash (2007)",
  "Franklin Richards: Not-so-Secret Invasion (2008)",
  "Franklin Richards: Schools Out (2009)",
  "Franklin Richards: Son of a Genius (2005)",
  "Franklin Richards: Sons of Genuises (2008)",
  "Franklin Richards: Spring Break! (2008)",
  "Franklin Richards: Summer Smackdown! (2008)",
  "Franklin Richards: Super Summer Spectacular (2006)",
  "Franklin Richards: World Be Warned (2007)",
  "Free Comic Book Day (2008)",
  "Free Comic Book Day (2009)",
  "Free Comic Book Day (2010)",
  "Free Comic Book Day (All-New Guardians of the Galaxy) (2017)",
  "Free Comic Book Day (Secret Empire) (2017)",
  "Free Comic Book Day (Spider-Man) (2007)",
  "Free Comic Book Day (Spider-Man) (2011)",
  "Free Comic Book Day (Spider-Man) (2019)",
  "Free Comic Book Day (Thor the Mighty Avenger) (2011)",
  "FREE COMIC BOOK DAY 2015 0 (2015)",
  "FREE COMIC BOOK DAY 2015 1 (2015)",
  "Free Comic Book Day 2016 (Captain America) (2016)",
  "FREE COMIC BOOK DAY 2016 1 [BUNDLES OF 25] (2016)",
  "FREE COMIC BOOK DAY: STAR WARS 1 (2005)",
  "Friendly Neighborhood Spider-Man (2005 - 2007)",
  "Friendly Neighborhood Spider-Man (2019 - Present)",
  "Friendly Neighborhood Spider-Man Annual (2007)",
  "Further Adventures of Cyclops & Phoenix (1996)",
  "Fury (1994)",
  "Fury Max (2011 - 2013)",
  "Fury: Peacemaker (2006)",
  "Fury: S.H.I.E.L.D. 50th Anniversary (2015)",
  "Fury: S.H.I.E.L.D. 50th Anniversary (2015)",
  "Future Fight Firsts: Luna Snow (2019)",
  "Future Fight Firsts: White Fox (2019)",
  "Future Foundation (2019 - Present)",
  "Future Imperfect (2015)",
  "G.L.a. (2005)",
  "Galacta: Daughter of Galactus (2010)",
  "Galactic Guardians (1994)",
  "Galactus the Devourer (1999 - 2000)",
  "Gambit (1993)",
  "Gambit (1997)",
  "Gambit (1999 - 2001)",
  "Gambit (2004 - 2005)",
  "Gambit (2012 - 2013)",
  "Gambit and the X-Ternals (1995)",
  "Gambit Annual (1999)",
  "Gambit Annual (2000)",
  "Gambit: From the Marvel Vault (2011)",
  "Gamora (2016 - 2017)",
  "Gargoyle (1985)",
  "Gene Colan Tribute (2008)",
  "Generation Hope (2010 - 2012)",
  "Generation M (2005 - 2006)",
  "Generation Next (1995)",
  "Generation X (1994 - 2001)",
  "Generation X (2017 - 2018)",
  "Generation X Annual (1995)",
  "Generations: Banner Hulk & The Totally Awesome Hulk (2017)",
  "Generations: Captain Marvel & Captain Mar-Vell (2017)",
  "Generations: Hawkeye & Hawkeye (2017)",
  "GENERATIONS: IRON MAN & IRONHEART 1 (2017)",
  "Generations: Miles Morales Spider-Man & Peter Parker Spider-Man (2017)",
  "Generations: Ms. Marvel & Ms. Marvel (2017)",
  "Generations: Phoenix & Jean Grey (2017)",
  "GENERATIONS: SAM WILSON CAPTAIN AMERICA & STEVE ROGERS CAPTAIN AMERICA 1 (2017)",
  "Generations: The Unworthy Thor & the Mighty Thor (2017)",
  "Generations: Wolverine & All-New Wolverine (2017)",
  "Genext (2008)",
  "Genext: United (2009)",
  "George Romero's Empire of the Dead: Act One (2014)",
  "George Romero's Empire of the Dead: Act Three (2015)",
  "George Romero's Empire of the Dead: Act Two (2014)",
  "Ghost Racers (2015)",
  "Ghost Rider (1973 - 1983)",
  "Ghost Rider (1990 - 1998)",
  "Ghost Rider (2001 - 2002)",
  "Ghost Rider (2005 - 2006)",
  "Ghost Rider (2006 - 2009)",
  "Ghost Rider (2011 - 2012)",
  "Ghost Rider (2016 - 2017)",
  "Ghost Rider (2019 - Present)",
  "Ghost Rider 2099 (2019)",
  "Ghost Rider Annual (2007)",
  "Ghost Rider Annual: Mercy (2008)",
  "Ghost Rider Director's Cut Edition (2019)",
  "Ghost Rider Finale (2007)",
  "Ghost Rider Saga (2008)",
  "Ghost Rider X-Mas Special Infinite Comic (2016)",
  "Ghost Rider/Wolverine/Punisher: The Dark Design (1994)",
  "Ghost Rider: Cycle of Vengeance (2011 - 2012)",
  "Ghost Rider: Danny Ketch (2008 - 2009)",
  "Ghost Rider: Trail of Tears (2007)",
  "Ghost Riders: Heaven's on Fire (2009)",
  "Ghost-Spider (2019)",
  "Ghost-Spider Annual (2019)",
  "Giant Size Marvel Adventures the Avengers (2007)",
  "Giant Size Spider-Woman (2005)",
  "Giant Size Super-Stars (1974)",
  "Giant-Man (2019)",
  "Giant-Size Astonishing X-Men (2008)",
  "Giant-Size Avengers (1974)",
  "Giant-Size Avengers Special (2007)",
  "Giant-Size Avengers/Invaders (2008)",
  "Giant-Size Chillers (1975)",
  "GIANT-SIZE CHILLERS 1 (1974)",
  "Giant-Size Conan (1974 - 1975)",
  "Giant-Size Creatures (1974)",
  "Giant-Size Defenders (1974)",
  "Giant-Size Defenders: Facsimile Edition (2019)",
  "Giant-Size Dracula (1974 - 1975)",
  "Giant-Size Fantastic Four (1974 - 1975)",
  "Giant-Size Hulk (2006)",
  "Giant-Size Incredible Hulk (2008)",
  "Giant-Size Invaders (2005)",
  "GIANT-SIZE INVADERS 2 (1975)",
  "Giant-Size Little Marvel: AVX (2015)",
  "Giant-Size Man-Thing (1974 - 1975)",
  "Giant-Size Master of Kung Fu (1974 - 1975)",
  "Giant-Size Ms. Marvel (2006)",
  "Giant-Size Spider-Man (1974 - 1975)",
  "Giant-Size Spider-Man (2014)",
  "Giant-Size Super Villain Team-Up (1975)",
  "GIANT-SIZE SUPER-HEROES FEATURING SPIDER-MAN 1 (1974)",
  "Giant-Size Werewolf by Night (1974)",
  "Giant-Size Wolverine (2006)",
  "Giant-Size X-Men (1975)",
  "Giant-Size X-Men (2005)",
  "Giant-Size X-Men Facsimile Edition (2019)",
  "Giant-Size X-Men: First Class (2008)",
  "Giant-Size X-Statix (2019)",
  "Girl Comics (2010)",
  "Glx-Mas (2006)",
  "Gorilla Man (2010)",
  "Gravity (2005)",
  "Great Lakes Avengers (2016 - 2017)",
  "Green Goblin (1995 - 1996)",
  "Groot (2015)",
  "Guardians (2004)",
  "Guardians 3000 (2014 - 2015)",
  "Guardians of Infinity (2015 - 2016)",
  "Guardians of Knowhere (2015)",
  "Guardians of the Galaxy & X-Men: The Black Vortex Alpha (2015)",
  "Guardians of the Galaxy & X-Men: The Black Vortex Omega (2015)",
  "Guardians of the Galaxy (1990 - 1994)",
  "Guardians of the Galaxy (2008 - 2010)",
  "Guardians of the Galaxy (2013 - 2015)",
  "Guardians of the Galaxy (2015 - 2017)",
  "Guardians of the Galaxy (2019 - Present)",
  "Guardians of the Galaxy (2020 - Present)",
  "Guardians of the Galaxy Adaptation (2017)",
  "Guardians of the Galaxy Annual (1991)",
  "Guardians of the Galaxy Annual (2014)",
  "Guardians Of The Galaxy Annual (2019)",
  "GUARDIANS OF THE GALAXY INFINITE (2014)",
  "Guardians of the Galaxy Infinite Comic (2013)",
  "GUARDIANS OF THE GALAXY: AWESOME MIX INFINITE COMIC (2016 - 2017)",
  "Guardians of the Galaxy: Best Story Ever (2015)",
  "Guardians of the Galaxy: Dream On (2017)",
  "Guardians of the Galaxy: Galaxy's Most Wanted (2014)",
  "Guardians of the Galaxy: Marvel Presents Facsimile Edition (2019)",
  "Guardians of the Galaxy: Mission Breakout (2017)",
  "Guardians of the Galaxy: Mother Entropy (2017)",
  "Guardians of the Galaxy: Ozone Heroes (2017)",
  "Guardians of the Galaxy: Telltale Games (2017)",
  "Guardians Of The Galaxy: The Prodigal Sun (2019)",
  "Guardians of the Galaxy: Tomorrow's Avengers (2013)",
  "GUARDIANS OF THE GALAXY: WHAT IF?... ECOSPORT ADVENTURE Presented by Ford (2017)",
  "Guardians Team-Up (2015)",
  "Guidebook to the Marvel Cinematic Universe (2015 - 2017)",
  "Guidebook to the Marvel Cinematic Universe - Agent Carter Season Two (2016)",
  "Guidebook to The Marvel Cinematic Universe - Marvel's Agent Carter Season One (2016)",
  "Guidebook to The Marvel Cinematic Universe - Marvel's Agent Carter Season One (2016)",
  "Guidebook to The Marvel Cinematic Universe - Marvel's Agents of S.H.I.E.L.D. Season One (2016)",
  "Guidebook to the Marvel Cinematic Universe - Marvel's Agents of S.H.I.E.L.D. Season Three (2016)",
  "Guidebook to The Marvel Cinematic Universe - Marvel's Ant-Man (2016)",
  "Guidebook To The Marvel Cinematic Universe - Marvel's Avengers: Age Of Ultron (2016)",
  "Guidebook to The Marvel Cinematic Universe - Marvel's Doctor Strange (2017)",
  "Guidebook To The Marvel Cinematic Universe - Marvel's Thor: The Dark World Digital Comic (2016)",
  "Guidebook to The Marvel Cinematic Universe - Marvel’s Agents of S.H.I.E.L.D. Season Two (2016)",
  "Guidebook to the Marvel Cinematic Universe - Marvel’s Captain America: The First Avenger (2016)",
  "Guidebook to The Marvel Cinematic Universe - Marvel’s Guardians of the Galaxy (2016)",
  "Guidebook to the Marvel Cinematic Universe - Marvel’s Thor (2015)",
  "Guidebook to the Marvel Cinematic Universe — Marvel’s Iron Man 3/Marvel’s Thor: The Dark World (2015 - 2016)",
  "Guidebook to the Marvel Cinematic Universe — Marvel’s Thor: The Dark World (2016)",
  "Guidebook to the Marvel Cinematic Universe- Marvel’s Incredible Hulk/Marvel’s Iron Man 2 (2015)",
  "Guidebook to the Marvel Cinematic Universe- Marvel’s Iron Man (2015)",
  "Guidebook to the Marvel Cinematic Universe- Marvel’s The Avengers (2016)",
  "Gun Theory (2003)",
  "Gunhawks (1972 - 1973)",
  "Gwenpool Holiday Special: Merry Mix-Up (2016)",
  "Gwenpool Special (2015)",
  "Gwenpool Strikes Back (2019 - Present)",
  "Hail Hydra (2015)",
  "Halloween Special Issue (2008)",
  "Halo Chronicles (2009)",
  "HALO PREVIEW DIGITAL COMIC 1 (2006)",
  "Halo: Blood Line (2009 - 2010)",
  "Halo: Fall of Reach - Boot Camp (2010 - 2011)",
  "Halo: Fall of Reach - Covenant (2010 - 2011)",
  "Halo: Fall of Reach - Invasion (2010 - 2012)",
  "Halo: Helljumper (2009)",
  "Halo: Uprising (2007 - 2009)",
  "Halo: Uprising Must Have One-Shot (2008)",
  "Han Solo (2016)",
  "HANK JOHNSON, AGENT OF HYDRA 1 (2015)",
  "HARLEY DAVIDSON IRON MAN 1 (2013)",
  "Haunt of Horror: Edgar Allan Poe (2006)",
  "Haunt of Horror: Lovecraft (2008)",
  "Haunted Mansion (2016)",
  "Havok & Wolverine: Meltdown (1988)",
  "Hawkeye & Mockingbird (2010)",
  "Hawkeye (1983)",
  "Hawkeye (1994)",
  "Hawkeye (2003 - 2004)",
  "Hawkeye (2012 - 2015)",
  "Hawkeye (2016 - 2018)",
  "Hawkeye - Earth's Mightiest Marksman (1998)",
  "Hawkeye Annual (2013)",
  "Hawkeye vs Deadpool (2014)",
  "Hawkeye: Blindspot (2011)",
  "Hawkeye: Freefall (2020 - 2021)",
  "Hedge Knight II: Sworn Sword (2007 - 2008)",
  "Hellstorm: Son of Satan (2006 - 2007)",
  "Her-Oes (2010)",
  "Heralds (2010)",
  "Herc (2010 - 2011)",
  "Hercules (2005)",
  "Hercules (2015 - 2016)",
  "Hercules: Fall of an Avenger (2010)",
  "Hercules: Prince of Power (1982)",
  "Hercules: Prince of Power (1984)",
  "Hercules: Twilight of a God (2010)",
  "Heroes for Hire (1997 - 1999)",
  "Heroes for Hire (2006 - 2007)",
  "Heroes for Hire (2010 - 2011)",
  "Heroes Reborn: Ashema (2000)",
  "Heroes Reborn: Doom (2000)",
  "Heroes Reborn: Doomsday (2000)",
  "Heroes Reborn: Masters Of Evil (2000)",
  "Heroes Reborn: Rebel (2000)",
  "Heroes Reborn: Remnants (2000)",
  "Heroes Reborn: The Return (1997)",
  "Heroes Reborn: Young Allies (2000)",
  "Heroic Age Magazine (2010)",
  "Heroic Age: One Month to Live (2010)",
  "Heroic Age: Prince of Power (2010)",
  "Heroic Age: Super Heroes (2010)",
  "Heroic Age: Villains (2010)",
  "Heroic Age: X-Men (2010)",
  "HI-TECH HEROES Presented by Hanes (2017)",
  "HIP-HOP VARIANT SAMPLER 1 (2016)",
  "History of the Marvel Universe (2019)",
  "Hit-Girl (2012 - Present)",
  "Hit-Monkey (2010)",
  "Holiday Special (1969)",
  "House of Hem (2015)",
  "House of M (2005)",
  "House of M (2008)",
  "House of M (2015)",
  "House of M MGC (2011)",
  "House of M: Avengers (2007)",
  "House of M: Masters of Evil (2009 - 2010)",
  "House of X (2019)",
  "House Of X 1 Director's Cut Edition (2019)",
  "Howard the Duck (1976 - 1979)",
  "Howard the Duck (1979 - 1981)",
  "Howard the Duck (2002)",
  "Howard the Duck (2007 - 2008)",
  "Howard the Duck (2015 - 2016)",
  "Howard the Duck (2015)",
  "HOWARD THE DUCK ANNUAL 1 (1977)",
  "Howard The Duck Facsimile Edition (2019)",
  "Howard the Duck Holiday Special (1997)",
  "Howard the Duck: The Movie (1986)",
  "HOWARD THE HUMAN 1 (2015)",
  "Howling Commandos (2009)",
  "Howling Commandos of S.H.I.E.L.D. (2015 - 2016)",
  "Hulk & Thing: Hard Knocks (2004)",
  "Hulk (1999 - 2008)",
  "Hulk (2008 - 2012)",
  "Hulk (2014 - 2015)",
  "Hulk (2016 - 2017)",
  "Hulk and Power Pack (2007)",
  "Hulk Annual (1999)",
  "Hulk Annual (2000)",
  "Hulk Annual (2014)",
  "Hulk Chronicles: Wwh (2008)",
  "Hulk Custom Comic (2008)",
  "Hulk Family (2008)",
  "Hulk MGC (2010)",
  "Hulk Monster-Size Special (2008)",
  "Hulk Poster Book (2008)",
  "Hulk Saga (2008)",
  "Hulk Smash (2001)",
  "Hulk Smash Avengers (2011 - 2012)",
  "Hulk Team-Up (2009)",
  "Hulk Vs. Dracula (2011)",
  "Hulk Vs. Fin Fang Foom (2007)",
  "Hulk Vs. Hercules: When Titans Collide (2008)",
  "Hulk-Sized Mini-Hulks (2011)",
  "Hulk/Thing - The Big Change (1982)",
  "Hulk/Wolverine: Six Hours (2003)",
  "Hulk: Broken Worlds (2009)",
  "Hulk: Cutting Edge (1995)",
  "Hulk: Destruction (2005)",
  "Hulk: Future Imperfect (1992 - 1993)",
  "Hulk: Gray (2003 - 2004)",
  "Hulk: Let the Battle Begin (2010)",
  "Hulk: Nightmerica (2003 - 2004)",
  "Hulk: Raging Thunder (2008)",
  "Hulk: Season One (2011)",
  "Hulk: Winter Guard (2009)",
  "Hulkverines (2019)",
  "Human Torch & Hulk: From the Marvel Vault (2011)",
  "Human Torch (2003 - 2004)",
  "Human Torch (2003 - 2004)",
  "Human Torch Comics (1940 - 1954)",
  "Human Torch Comics 70th Anniversary Special (2009)",
  "Hunger (2013)",
  "Hunt for Wolverine (2018)",
  "Hunt for Wolverine: Adamantium Agenda (2018)",
  "Hunt for Wolverine: Claws of a Killer (2018)",
  "Hunt for Wolverine: Dead Ends (2018)",
  "Hunt for Wolverine: Mystery in Madripoor (2018)",
  "Hunt for Wolverine: Weapon Lost (2018)",
  "Hyperion (2016)",
  "I (Heart) Marvel (2006)",
  "I Am an Avenger (2010 - 2011)",
  "I Am Captain America (2011)",
  "I Am Groot (2017)",
  "Iceman (1984 - 1985)",
  "Iceman (2001 - 2002)",
  "Iceman (2017 - 2018)",
  "Iceman (2018 - 2019)",
  "Iceman and Angel (2010)",
  "Identity Disc (2004)",
  "Illuminati (2015 - 2016)",
  "Immortal Hulk (2018 - Present)",
  "Immortal Hulk Director's Cut (2019)",
  "Immortal Hulk: The Best Defense (2018)",
  "Immortal Iron Fist (2006 - 2009)",
  "Immortal Iron Fist Annual (2007)",
  "Immortal Iron Fist: Orson Randall and the Death Queen of California (2008)",
  "Immortal Iron Fist: Orson Randall and the Green Mist of Death (2008)",
  "Immortal Iron Fist: The Origin of Danny Rand (2008)",
  "Immortal Weapons (2009 - 2010)",
  "Impossible Man Summer Spectacular (1990)",
  "Incognito (2008 - 2009)",
  "Incognito Must Have One-Shot (2009)",
  "Incognito: Bad Influences (2010 - 2011)",
  "Incoming (2019)",
  "Incredible Hercules (2008 - 2010)",
  "Incredible Hulk (1962 - 1999)",
  "Incredible Hulk (2011 - 2012)",
  "Incredible Hulk (2017 - 2018)",
  "Incredible Hulk Annual (1976 - 1994)",
  "INCREDIBLE HULK ANNUAL 1 (2001)",
  "Incredible Hulk Facsimile Edition (2019)",
  "Incredible Hulk King-Size Special (1968 - 1972)",
  "Incredible Hulk Vs. Venom (1994)",
  "Incredible Hulk: Facsimile Edition (2019)",
  "Incredible Hulk: Facsimile Edition (2020)",
  "Incredible Hulk: Last Call (2019)",
  "INCREDIBLE HULK: THE END 1 (2002)",
  "Incredible Hulk: The Fury Files (2008)",
  "Incredible Hulks (2010 - 2011)",
  "Incredible Hulks Annual (2011)",
  "Incredible Hulks: Dark Son (2010 - 2011)",
  "Indestructible Hulk (2012 - 2014)",
  "Indestructible Hulk Annual (2013)",
  "Indestructible Hulk Special (2013)",
  "Indomitable Iron Man Black and White (2010)",
  "Infamous Iron Man (2016 - 2017)",
  "Infernal Man-Thing (2010 - 2012)",
  "Inferno (2015)",
  "Infinity (2013)",
  "Infinity Abyss (2002)",
  "Infinity Countdown (2018)",
  "Infinity Countdown Prime (2018)",
  "Infinity Countdown: Adam Warlock (2018)",
  "Infinity Countdown: Black Widow (2018)",
  "Infinity Countdown: Captain Marvel (2018)",
  "Infinity Countdown: Champions (2018)",
  "Infinity Countdown: Daredevil (2018)",
  "Infinity Countdown: Darkhawk (2018)",
  "Infinity Crusade (1993)",
  "Infinity Gauntlet (1991)",
  "Infinity Gauntlet (2015)",
  "Infinity War (1992)",
  "Infinity Wars (2018)",
  "Infinity Wars Prime (2018)",
  "Infinity Wars: Arachknight (2018)",
  "Infinity Wars: Fallen Guardian (2018)",
  "Infinity Wars: Ghost Panther (2018)",
  "Infinity Wars: Infinity (2019)",
  "Infinity Wars: Infinity Warps (2018)",
  "Infinity Wars: Iron Hammer (2018)",
  "Infinity Wars: Sleepwalker (2018 - 2019)",
  "Infinity Wars: Soldier Supreme (2018)",
  "Infinity Wars: Weapon Hex (2018)",
  "Infinity: Against the Tide Infinite Comic (2013)",
  "Infinity: Heist (2013 - 2014)",
  "Infinity: The Hunt (2013)",
  "Inhuman (2014 - 2015)",
  "Inhuman Annual (2015)",
  "Inhuman Special (2015)",
  "Inhuman Special (2015)",
  "Inhumanity (2013 - 2014)",
  "Inhumanity: Superior Spider-Man (2014)",
  "Inhumanity: The Awakening (2013 - 2014)",
  "Inhumans (1998 - 1999)",
  "Inhumans (2000)",
  "Inhumans (2003 - 2004)",
  "Inhumans 2099 (2004)",
  "INHUMANS PRIME 1 (2017)",
  "Inhumans: Attilan Rising (2015)",
  "Inhumans: Judgement Day (2018)",
  "Inhumans: Once and Future Kings (2017)",
  "International Iron Man (2016)",
  "Invaders (1975 - 1979)",
  "Invaders (1993)",
  "Invaders (2004)",
  "Invaders (2019 - Present)",
  "INVADERS ANNUAL 1 (1977)",
  "Invaders Now! (2010 - 2011)",
  "Invincible Iron Man (2008 - 2012)",
  "Invincible Iron Man (2015 - 2016)",
  "Invincible Iron Man (2016 - 2018)",
  "INVINCIBLE IRON MAN ANNUAL 1 (2010)",
  "Invincible Iron Man MGC (2010)",
  "Invisible Woman (2019 - Present)",
  "Iron Age (2011)",
  "Iron Age: Alpha (2010)",
  "Iron Age: Omega (2010 - 2011)",
  "Iron Fist (1975 - 1977)",
  "Iron Fist (1996)",
  "Iron Fist (1998)",
  "Iron Fist (2004)",
  "Iron Fist (2017 - 2018)",
  "Iron Fist - Marvel Digital Original (2018)",
  "Iron Fist/Wolverine (2000)",
  "Iron Fist: The Living Weapon (2014 - 2015)",
  "Iron Man & Captain America Annual (1998)",
  "Iron Man & the Armor Wars (2009)",
  "Iron Man (1968 - 1996)",
  "Iron Man (1996 - 1998)",
  "Iron Man (1998 - 2004)",
  "Iron Man (2012 - 2014)",
  "Iron Man 2.0 (2011)",
  "IRON MAN 2.0 SAGA DIGITAL COMIC 1 (2011)",
  "Iron Man 2.0: Modern Warfare (2011)",
  "Iron Man 2020 (1994)",
  "Iron Man 2020 (2020 - Present)",
  "Iron Man 2020: Director’s Cut Edition (2020)",
  "Iron Man 2: Agents of S.H.I.E.L.D. (2010)",
  "Iron Man 2: Public Identity (2010)",
  "Iron Man and Power Pack (2007 - 2008)",
  "Iron Man and Power Pack (2017)",
  "IRON MAN AND THE SUB-MARINER 1 (1968)",
  "Iron Man Annual (1976 - 1994)",
  "Iron Man Annual (1999)",
  "Iron Man Annual (2000)",
  "IRON MAN ANNUAL 1 (2014)",
  "Iron Man Armored Adventures: Heart of a Hero (2009)",
  "Iron Man by Design (2010)",
  "Iron Man Legacy (2010 - 2011)",
  "Iron Man Magazine Special Edition (2010)",
  "Iron Man Noir (2010)",
  "Iron Man Poster Book (2008)",
  "Iron Man Special (2014)",
  "Iron Man Vs. Whiplash (2009 - 2010)",
  "Iron Man/Captain America: Casualties of War (2006)",
  "IRON MAN/HULK/FURY 1 (2008)",
  "Iron Man/Thor (2010 - 2011)",
  "Iron Man: Crash (1987)",
  "Iron Man: Director of S.H.I.E.L.D. (2007)",
  "Iron Man: Director of S.H.I.E.L.D. Annual (2007)",
  "Iron Man: Enter the Mandarin (2007)",
  "Iron Man: Extremis Director's Cut (2010)",
  "Iron Man: Fast Friends (2008)",
  "Iron Man: Fatal Frontier Infinite Comic (2013)",
  "Iron Man: Golden Avenger (2008)",
  "Iron Man: Hong Kong Heroes (2018)",
  "Iron Man: House of M (2005)",
  "Iron Man: Hypervelocity (2007)",
  "Iron Man: I Am Iron Man! (2010)",
  "Iron Man: Inevitable (2005 - 2006)",
  "Iron Man: Iron Protocols (2009)",
  "Iron Man: Kiss and Kill (2010)",
  "Iron Man: Legacy of Doom (2008)",
  "Iron Man: Rapture (2010 - 2011)",
  "Iron Man: Requiem (2009)",
  "Iron Man: Season One (2013)",
  "Iron Man: The Coming of the Melter! (2013)",
  "IRON MAN: THE END 1 (2008 - 2010)",
  "Iron Man: The Iron Age (1998)",
  "Iron Man: Titanium (2010)",
  "Iron Man: Viva Las Vegas (2008)",
  "Iron Man: War Machine (2008)",
  "Iron Manual Mark 3 (2010)",
  "Iron Patriot (2014)",
  "Ironheart (2018 - Present)",
  "Irredeemable Ant-Man (2006 - 2007)",
  "Irredeemable Ant-Man Vol. 2: Small-Minded (2007)",
  "Ivx (2016 - 2017)",
  "J2 (1998 - 1999)",
  "Jack Kirby's Galactic Bounty Hunters (2006 - 2007)",
  "Jean Grey (2017 - 2018)",
  "JESSE JAMES IS A DEAD MAN COMIC (2009)",
  "Jessica Jones (2016 - 2018)",
  "Jessica Jones - Marvel Digital Original (2018)",
  "Jessica Jones - Marvel Digital Original: Purple Daughter (2019)",
  "Jessica Jones: Blind Spot (2020)",
  "John Carter of Mars: A Princess of Mars (2011 - 2012)",
  "John Carter: The Gods of Mars (2011 - 2012)",
  "John Carter: The World of Mars (2011 - 2012)",
  "John Romita Jr. 30th Anniversary (2006)",
  "Journey Into Mystery (1952 - 1966)",
  "Journey Into Mystery (1996 - 1998)",
  "Journey Into Mystery (2011 - 2013)",
  "JOURNEY INTO MYSTERY ANNUAL 1 (1965)",
  "Journey Into Mystery: The Birth of Krakoa (2018)",
  "Journey Into Unknown Worlds (2019)",
  "Journey to Star Wars: The Force Awakens - Shattered Empire (2015)",
  "Journey to Star Wars: The Last Jedi - Captain Phasma (2017)",
  "Journey to Star Wars: The Rise of Skywalker - Allegiance (2019 - 2020)",
  "Jubilee (2004 - 2005)",
  "Juggernaut (1999)",
  "Jungle Action (1972 - 1976)",
  "Justice (1986 - 1989)",
  "Ka-Zar (1970 - 1971)",
  "Ka-Zar (1974 - 1977)",
  "Ka-Zar (1997 - 1999)",
  "Ka-Zar (2011)",
  "Ka-Zar Annual (1997)",
  "Ka-Zar the Savage (1981 - 1984)",
  "Kabuki #1 (Variant) (2004)",
  "Kabuki (2004 - 2007)",
  "Kanan - The Last Padawan (2015 - 2016)",
  "Kapow! Guinness World Record Special (2011)",
  "Karnak (2015 - 2017)",
  "Kick-Ass (2008 - 2009)",
  "Kick-Ass 2 (2010 - 2012)",
  "Kick-Ass 3 (2013 - 2014)",
  "Kid Colt One-Shot (2009)",
  "Killmonger (2018 - 2019)",
  "Killraven (2002 - 2003)",
  "Killraven: Warrior of the Worlds (1983)",
  "King Thor (2019)",
  "King-Size Cable Spectacular (2008)",
  "King-Size Hulk (2008)",
  "King-Size Spider-Man Summer Special (2008)",
  "Kingpin (2003)",
  "Kingpin (2017)",
  "Kitty Pryde and Wolverine (1984)",
  "Klaws of the Panther (2010)",
  "Korvac Saga (2015)",
  "Lady Deadpool (2010)",
  "Lana (1948 - 1950)",
  "Lando (2015)",
  "Last Avengers Story (1995)",
  "Last Hero Standing (2005)",
  "Last Planet Standing (2006)",
  "League of Legends: Ashe - Warmother Special Edition (2018 - 2019)",
  "League of Legends: Lux (2019)",
  "League of Legends: Zed (2019 - 2020)",
  "Legendary Star-Lord (2014 - 2015)",
  "Legion (2018)",
  "Legion of Monsters (2011 - 2012)",
  "LEGION OF MONSTERS 1 (1975)",
  "Legion of Monsters: Man-Thing (2007)",
  "Legion of Monsters: Morbius (2007)",
  "Legion of Monsters: Satana (2007)",
  "Legion of Monsters: Werewolf by Night (2007)",
  "Lethal Foes of Spider-Man (1993)",
  "Lexus Presents: Marvel's Agents of S.H.I.E.L.D in THE CHASE (2014)",
  "Livewires (2005)",
  "Lockjaw (2018)",
  "Lockjaw and the Pet Avengers (2009)",
  "Lockjaw and the Pet Avengers (2017)",
  "Lockjaw and the Pet Avengers Unleashed (2010)",
  "Lockjaw: Dog Days (2017)",
  "Logan (2008)",
  "Logan: Path of The Warlord (1996)",
  "Logan: Shadow Society (1997)",
  "Loki (2004)",
  "Loki (2010 - 2011)",
  "Loki (2019 - Present)",
  "Loki: Agent of Asgard (2014 - 2015)",
  "Longshot (1985 - 1986)",
  "Longshot Saves the Marvel Universe (2013)",
  "Lorna the Jungle Queen (1953 - 1954)",
  "Love Romances (1949 - 1963)",
  "Love Romances (2019)",
  "Luke Cage (2017 - 2018)",
  "Luke Cage - Marvel Digital Original (2018)",
  "Luke Cage Noir (2009 - 2010)",
  "Luke Cage, Hero for Hire (1972 - 1973)",
  "M&M’s, Hazelnut Hysteria: One-Shot (2019)",
  "M.O.D.O.K. Assassin (2015)",
  "M.O.D.O.K.: Reign Delay (2009)",
  "Machine Man (1978 - 1981)",
  "Machine Man (1984 - 1985)",
  "Machine Man/Bastion Annual (1998)",
  "Machine Teen (2005)",
  "Madrox (2004 - 2005)",
  "Magician Apprentice (2006 - 2007)",
  "Magik (1983 - 1984)",
  "Magneto (2010)",
  "Magneto (2014 - 2015)",
  "Magneto Rex (1999)",
  "Magneto: Dark Seduction (2000)",
  "Magneto: Not a Hero (2011 - 2012)",
  "Magnificent Ms. Marvel (2019)",
  "Major X (2019)",
  "Man Without Fear (2019)",
  "Man-Thing (1974 - 1975)",
  "Man-Thing (1979 - 1981)",
  "Man-Thing (2004)",
  "Man-Thing (2017)",
  "Marauders (2019 - 2020)",
  "Marc Spector: Moon Knight (1989 - 1994)",
  "March on Ultimatum Saga (2008)",
  "Marvel 1602 MGC (2010)",
  "Marvel 1602: Fantastick Four (2006 - 2007)",
  "Marvel 1602: New World (2005)",
  "Marvel 1985 (2008)",
  "Marvel 2-in-One (2017 - Present)",
  "Marvel 2-In-One Annual (2018)",
  "Marvel 65th Anniversary Special (2004)",
  "Marvel 70th Anniversary Frame Art Comic (2009)",
  "Marvel 70th Anniversary Poster Book (2009)",
  "MARVEL 75TH ANNIVERSARY CELEBRATION 1 (2014)",
  "Marvel Adventures Fantastic Four (2005 - 2009)",
  "Marvel Adventures Fantastic Four (2018)",
  "Marvel Adventures Flip Magazine (2005 - 2007)",
  "Marvel Adventures Hulk (2007 - 2008)",
  "Marvel Adventures Iron Man (2007 - 2008)",
  "Marvel Adventures Spider-Man (2005 - 2010)",
  "Marvel Adventures Super Heroes (2008 - 2010)",
  "Marvel Adventures Super Heroes (2010 - 2012)",
  "Marvel Adventures Super Heroes (2018)",
  "Marvel Adventures the Avengers (2006 - 2009)",
  "Marvel Adventures the Avengers (2018)",
  "Marvel Adventures Two-in-One (2007 - 2009)",
  "Marvel Adventures: Super Heroes (2019)",
  "Marvel Age Annual (1985 - 1988)",
  "Marvel Age Fantastic Four (2004 - 2005)",
  "Marvel Age Fantastic Four Tales (2005)",
  "Marvel Age Hulk (2004 - 2005)",
  "Marvel Age Spider-Man (2004 - 2005)",
  "Marvel Age Spider-Man Team-Up (2000 - 2005)",
  "MARVEL AND SYNCHRONY PRESENT CAPTAIN AMERICA & WINTER SOLDIER: WAR BONDS (2019)",
  "Marvel Apes (2008)",
  "Marvel Apes: Amazing Spider-Monkey (2009)",
  "Marvel Apes: Grunt Line Special (2009)",
  "Marvel Apes: Speedball (2009)",
  "Marvel Assistant-Sized Spectacular (2009)",
  "Marvel Atlas (2007 - 2008)",
  "Marvel Backlist Chronology (2011)",
  "Marvel Boy (1950 - 1951)",
  "Marvel Boy (2000 - 2001)",
  "Marvel Boy: The Uranian (2010)",
  "Marvel Chillers (1975 - 1976)",
  "Marvel Comics (1939)",
  "Marvel Comics (2019)",
  "Marvel Comics 1: 70th Anniversary Edition (2009)",
  "Marvel Comics Presents (1988 - 1995)",
  "Marvel Comics Presents (2007 - 2008)",
  "Marvel Comics Presents (2019)",
  "Marvel Divas (2009)",
  "Marvel Double-Shot (2003)",
  "Marvel Fanfare (1982 - 1992)",
  "Marvel Feature (1971 - 1973)",
  "Marvel Feature (1975 - 1976)",
  "Marvel Frontier Comics Unlimited (1994)",
  "MARVEL FUTURE FIGHT: AN EYE ON THE FUTURE, presented by NETMARBLE (2017)",
  "Marvel Girl (2010)",
  "Marvel Heartbreakers (2010)",
  "MARVEL HEARTBREAKERS 1 (2010)",
  "Marvel Her-Oes (2010)",
  "Marvel Holiday Comic (2011)",
  "Marvel Holiday Special (2004)",
  "Marvel Holiday Special (2005)",
  "Marvel Holiday Special (2006)",
  "Marvel Holiday Special 2007 (2007)",
  "Marvel Holiday Special 2007 (2007)",
  "Marvel Holiday Spectacular (2009)",
  "Marvel Illustrated: Jungle Book (2007)",
  "Marvel Illustrated: Kidnapped! (2008 - 2009)",
  "Marvel Illustrated: Last of the Mohicans (2007)",
  "Marvel Illustrated: Moby Dick (2007 - 2008)",
  "Marvel Illustrated: Picture of Dorian Gray (2007)",
  "Marvel Illustrated: The Iliad (2007)",
  "Marvel Illustrated: The Man in the Iron Mask (2007)",
  "Marvel Illustrated: The Odyssey (2008 - 2009)",
  "Marvel Illustrated: The Three Musketeers (2008 - 2009)",
  "Marvel Illustrated: Treasure Island (2007)",
  "Marvel Knights (2000 - 2001)",
  "Marvel Knights (2002)",
  "Marvel Knights 20th (2018 - 2019)",
  "Marvel Knights Spider-Man (2004 - 2006)",
  "Marvel Knights: Hulk (2013 - 2014)",
  "Marvel Knights: Spider-Man (2013 - 2014)",
  "Marvel Knights: X-Men (2013 - 2014)",
  "Marvel Legacy (2017)",
  "Marvel Legacy: The 1960s (2006)",
  "Marvel Legacy: The 1970s (2006)",
  "Marvel Legacy: The 1980s (2006)",
  "Marvel Legacy: The 1990s (2007)",
  "MARVEL MAGIC HANDBOOK (2007)",
  "Marvel Mangaverse (2002)",
  "MARVEL MANGAVERSE: AVENGERS ASSEMBLE! 1 (2002)",
  "MARVEL MANGAVERSE: ETERNITY TWILIGHT 1 (2002)",
  "MARVEL MANGAVERSE: FANTASTIC FOUR 1 (2002)",
  "MARVEL MANGAVERSE: GHOST RIDERS 1 (2002)",
  "MARVEL MANGAVERSE: NEW DAWN 1 (2002)",
  "MARVEL MANGAVERSE: PUNISHER 1 (2002)",
  "MARVEL MANGAVERSE: SPIDER-MAN 1 (2002)",
  "MARVEL MANGAVERSE: X-MEN 1 (2002)",
  "Marvel Masterworks: Atlas Era Strange Tales Vol. 4 (2010 - 2011)",
  "Marvel Masterworks: Fantastic Four (2010 - 2011)",
  "Marvel Milestones (2005 - 2007)",
  "Marvel Milestones Special: Bloodstone, X-51, & Captain Marvel II (2006)",
  "Marvel Monsters (2005)",
  "Marvel Monsters (2019)",
  "Marvel Monsters Poster Book (2008)",
  "Marvel Monsters: From the Files of Ulysses Bloodstone & the Monster Hunters (2005)",
  "Marvel Must Haves: Nyx 1-3 (2005)",
  "Marvel Mystery Comics (1939 - 1949)",
  "Marvel Mystery Comics 70th Anniversary Special (2009)",
  "Marvel Mystery Handbook: 70th Anniversary Special (2009)",
  "Marvel Nemesis: The Imperfects (2005)",
  "Marvel New Year's Eve Special Infinite Comic (2017)",
  "Marvel Now! Point One (2012)",
  "Marvel Pets Handbook (2009)",
  "Marvel Premiere (1972 - 1981)",
  "Marvel Presents (1975 - 1977)",
  "Marvel Preview (1975 - 1981)",
  "Marvel Previews (2005 - 2007)",
  "Marvel Previews (2008)",
  "Marvel Reading Chronology (2009)",
  "Marvel Rising (2018)",
  "Marvel Rising (2018)",
  "Marvel Rising (2019)",
  "Marvel Rising: Alpha (2018)",
  "Marvel Rising: Ms. Marvel/Squirrel Girl (2018)",
  "Marvel Rising: Omega (2018)",
  "Marvel Rising: Squirrel Girl/Ms. Marvel (2018)",
  "Marvel Romance Redux (2006)",
  "Marvel Saga (2009 - 2011)",
  "Marvel Spotlight (1971 - 1977)",
  "Marvel Spotlight (1979 - 1977)",
  "Marvel Spotlight (2005 - 2011)",
  "Marvel Super Action (1976)",
  "Marvel Super Action (2011)",
  "Marvel Super Hero Adventures: Captain Marvel - First Day of School (2018)",
  "Marvel Super Hero Adventures: Captain Marvel - First Day of School Infinite Comic (2019)",
  "Marvel Super Hero Adventures: Captain Marvel - Frost Giants Among Us! (2018)",
  "Marvel Super Hero Adventures: Captain Marvel - Frost Giants Among Us! Infinite Comic (2019)",
  "Marvel Super Hero Adventures: Captain Marvel - Halloween Spooktacular (2018)",
  "Marvel Super Hero Adventures: Captain Marvel - Halloween Spooktacular Infinite Comic (2019 - Present)",
  "Marvel Super Hero Adventures: Captain Marvel - Mealtime Mayhem (2018)",
  "Marvel Super Hero Adventures: Captain Marvel - Mealtime Mayhem (2019)",
  "Marvel Super Hero Adventures: Inferno (2018)",
  "Marvel Super Hero Adventures: Inferno (2019)",
  "Marvel Super Hero Adventures: Ms. Marvel and the Teleporting Dog (2018)",
  "Marvel Super Hero Adventures: Ms. Marvel and the Teleporting Dog (2019)",
  "Marvel Super Hero Adventures: Spider-Man - Across the Spider-Verse (2019)",
  "Marvel Super Hero Adventures: Spider-Man - Across the Spider-Verse (2019)",
  "Marvel Super Hero Adventures: Spider-Man - Spider-Sense of Adventure (2019)",
  "Marvel Super Hero Adventures: Spider-Man - Spider-Sense of Adventure (2019)",
  "Marvel Super Hero Adventures: Spider-Man - Web Designers (2019)",
  "Marvel Super Hero Adventures: Spider-Man - Web Designers Infinite Comic (2019 - Present)",
  "Marvel Super Hero Adventures: Spider-Man - Web of Intrigue (2019)",
  "Marvel Super Hero Adventures: Spider-Man - Web of Intrigue (2019)",
  "Marvel Super Hero Adventures: Spider-Man and the Stolen Vibranium (2018)",
  "Marvel Super Hero Adventures: Spider-Man and the Stolen Vibranium (2019)",
  "Marvel Super Hero Adventures: The Spider-Doctor (2018)",
  "Marvel Super Hero Adventures: The Spider-Doctor Infinite Comic (2019)",
  "Marvel Super Hero Adventures: Webs and Arrows and Ants, Oh My! (2018)",
  "Marvel Super Hero Adventures: Webs and Arrows and Ants, Oh My! (2019)",
  "Marvel Super Hero Contest of Champions (1982)",
  "Marvel Super Hero Contest of Champions (2015)",
  "MARVEL SUPER HERO SPECTACULAR 1 (2015 - Present)",
  "Marvel Super Hero Squad (2009 - 2010)",
  "Marvel Super Heroes (1990 - 1993)",
  "Marvel Super Special (1977 - 1986)",
  "MARVEL SUPER SPECIAL 1 (2010)",
  "Marvel Super Stars Magazine (2010)",
  "Marvel Super-Heroes (1967 - 1982)",
  "Marvel Tails Starring Peter Porker, The Spectacular Spider-Ham (1983)",
  "Marvel Tails, the Spectacular Spider-Ham (2019)",
  "Marvel Tales (1966 - 1994)",
  "Marvel Tales: Annihilation (2019)",
  "Marvel Tales: Avengers (2019)",
  "Marvel Tales: Black Panther (2019)",
  "Marvel Tales: Black Widow (2019)",
  "Marvel Tales: Captain America (2019)",
  "Marvel Tales: Doctor Strange (2019)",
  "Marvel Tales: Fantastic Four (2019)",
  "Marvel Tales: Ghost Rider (2019)",
  "Marvel Tales: Hulk (2019)",
  "Marvel Tales: Iron Man (2019)",
  "Marvel Tales: Ravencroft (2020)",
  "Marvel Tales: Spider-Man (2019 - Present)",
  "Marvel Tales: Thanos (2019)",
  "Marvel Tales: Thor (2019)",
  "Marvel Tales: Venom (2019)",
  "Marvel Tales: X-Men (2019)",
  "Marvel Team-Up (1972 - 1985)",
  "Marvel Team-Up (2004 - 2006)",
  "Marvel Team-Up (2019 - Present)",
  "Marvel Team-Up Annual (1976 - 1978)",
  "Marvel Treasury Edition (1974)",
  "MARVEL TREASURY SPECIAL: CAPTAIN AMERICA'S BICENTENNIAL BATTLES 1 (1976)",
  "Marvel Triple Action (2009)",
  "Marvel Tsum Tsum (2016)",
  "Marvel TV: Galactus - The Real Story (2009)",
  "Marvel Two-in-One (1974 - 1983)",
  "Marvel Two-in-One (2007 - 2008)",
  "Marvel Two-in-One Annual (1976)",
  "Marvel Universe (1998)",
  "Marvel Universe Avengers Assemble (2013 - 2014)",
  "Marvel Universe Avengers Assemble Season Two (2014)",
  "Marvel Universe Avengers Assemble: Civil War (2016)",
  "Marvel Universe Avengers Assemble: Civil War (2017 - 2018)",
  "Marvel Universe Avengers: Earth's Mightiest Heroes (2012 - 2013)",
  "Marvel Universe Avengers: Earth's Mightiest Heroes (2018)",
  "Marvel Universe Avengers: TBD Infinite Comic (2016)",
  "Marvel Universe Avengers: Ultron Revolution (2016 - 2017)",
  "Marvel Universe Avengers: Ultron Revolution (2017 - 2018)",
  "Marvel Universe Guardians of the Galaxy (2015 - 2017)",
  "Marvel Universe Guardians of the Galaxy (2015)",
  "Marvel Universe Guardians of the Galaxy Infinite Comic (2015 - Present)",
  "Marvel Universe Hulk: Agents of S.M.A.S.H. (2013 - 2014)",
  "Marvel Universe Thor Comic Reader (2013)",
  "Marvel Universe Ultimate Spider-Man (2012 - 2014)",
  "Marvel Universe Ultimate Spider-Man Vs. the Sinister Six (2016 - Present)",
  "Marvel Universe Ultimate Spider-Man: Contest of Champions (2016)",
  "Marvel Universe Ultimate Spider-Man: Spider-Verse (2018)",
  "Marvel Universe vs. The Avengers (2012 - 2013)",
  "Marvel Universe Vs. the Punisher (2010)",
  "Marvel Universe Vs. Wolverine (2011)",
  "Marvel Universe: The End (2003 - 2004)",
  "Marvel Valentine Special (1997)",
  "Marvel Vampires Handbook (2010)",
  "Marvel Vampires Poster Book (2013)",
  "Marvel Vault: Doctor Strange (2011)",
  "Marvel Westerns (2006)",
  "Marvel Zombie (2018)",
  "Marvel Zombies (2005 - 2006)",
  "Marvel Zombies (2015 - Present)",
  "Marvel Zombies 2 (2007 - 2008)",
  "Marvel Zombies 3 (2008 - 2009)",
  "Marvel Zombies 4 (2009)",
  "Marvel Zombies 5 (2010)",
  "Marvel Zombies Destroy! (2011 - 2012)",
  "Marvel Zombies Halloween (2012)",
  "Marvel Zombies MGC (2010)",
  "Marvel Zombies Return (2009)",
  "Marvel Zombies Supreme (2010 - 2011)",
  "Marvel Zombies/Army of Darkness (2007)",
  "Marvel Zombies: Dead Days (2007)",
  "Marvel Zombies: Evil Evolution (2009)",
  "Marvel Zombies: Resurrection (2019)",
  "Marvel Zombies: The Book of Angels, Demons & Various (2007)",
  "Marvel's Ant-Man and the Wasp Prelude (2018)",
  "Marvel's Ant-Man Prelude (2015)",
  "Marvel's Avengers: Endgame Prelude (2018 - 2019)",
  "Marvel's Avengers: Infinity War Prelude (2018)",
  "Marvel's Avengers: Iron Man (2019)",
  "Marvel's Avengers: Thor (2020)",
  "Marvel's Black Panther Prelude (2017)",
  "Marvel's Black Widow Prelude (2020)",
  "Marvel's Captain America: Civil War Prelude (2015 - 2016)",
  "Marvel's Captain America: Civil War Prelude (2016)",
  "Marvel's Captain America: The First Avenger Adaptation (2013)",
  "Marvel's Captain Marvel Prelude (2018)",
  "Marvel's Doctor Strange Prelude (2016)",
  "Marvel's Doctor Strange Prelude Infinite Comic (2016)",
  "Marvel's Greatest Comics: Fantastic Four (2006)",
  "Marvel's Greatest Creators: Avengers - The Origin of Mantis! (2019)",
  "Marvel's Greatest Creators: Hulk - The Wedding of Rick Jones (2019)",
  "Marvel's Greatest Creators: Invaders (2019)",
  "Marvel's Greatest Creators: Iron Fist - Colleen Wing (2019)",
  "Marvel's Greatest Creators: Iron Fist - Misty Knight (2019)",
  "Marvel's Greatest Creators: Luke Cage, Power Man - Piranha! (2019)",
  "Marvel's Greatest Creators: Power Pack (2019)",
  "Marvel's Greatest Creators: Silver Surfer - Rude Awakening (2019)",
  "Marvel's Greatest Creators: Spider-Man - Kraven's Last Hunt (2019)",
  "Marvel's Greatest Creators: What If? - Spider-Girl (2019)",
  "Marvel's Greatest Heroes Sampler (2008)",
  "Marvel's Guardians of the Galaxy Prelude (2014)",
  "MARVEL'S HERO PROJECT SEASON 1: DYNAMIC DANIELLA [BRAZILIAN PORTUGUESE] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: DYNAMIC DANIELLA [DANISH] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: DYNAMIC DANIELLA [DUTCH] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: DYNAMIC DANIELLA [FINNISH] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: DYNAMIC DANIELLA [FRENCH CANADIAN] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: DYNAMIC DANIELLA [FRENCH] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: DYNAMIC DANIELLA [GERMAN] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: DYNAMIC DANIELLA [ITALIAN] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: DYNAMIC DANIELLA [JAPANESE] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: DYNAMIC DANIELLA [LATAM SPANISH] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: DYNAMIC DANIELLA [NORWEGIAN] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: DYNAMIC DANIELLA [PORTUGUESE] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: DYNAMIC DANIELLA [SPANISH] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: DYNAMIC DANIELLA [SWEDISH] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: HIGH-FLYING HAILEY [BRAZILIAN PORTUGUESE] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: HIGH-FLYING HAILEY [DANISH] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: HIGH-FLYING HAILEY [DUTCH] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: HIGH-FLYING HAILEY [FINNISH] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: HIGH-FLYING HAILEY [FRENCH CANADIAN] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: HIGH-FLYING HAILEY [FRENCH] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: HIGH-FLYING HAILEY [GERMAN] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: HIGH-FLYING HAILEY [ITALIAN] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: HIGH-FLYING HAILEY [JAPANESE] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: HIGH-FLYING HAILEY [LATAM SPANISH] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: HIGH-FLYING HAILEY [NORWEGIAN] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: HIGH-FLYING HAILEY [PORTUGUESE] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: HIGH-FLYING HAILEY [SPANISH] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: HIGH-FLYING HAILEY [SWEDISH] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: HIGH-KICKIN' IZZY (2019)",
  "MARVEL'S HERO PROJECT SEASON 1: ROVING ROBBIE [BRAZILIAN PORTUGUESE] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: ROVING ROBBIE [DANISH] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: ROVING ROBBIE [DUTCH] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: ROVING ROBBIE [FINNISH] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: ROVING ROBBIE [FRENCH CANADIAN] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: ROVING ROBBIE [FRENCH] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: ROVING ROBBIE [GERMAN] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: ROVING ROBBIE [ITALIAN] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: ROVING ROBBIE [JAPANESE] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: ROVING ROBBIE [LATAM SPANISH] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: ROVING ROBBIE [NORWEGIAN] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: ROVING ROBBIE [PORTUGUESE] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: ROVING ROBBIE [SPANISH] (2020 - Present)",
  "MARVEL'S HERO PROJECT SEASON 1: ROVING ROBBIE [SWEDISH] (2020 - Present)",
  "Marvel's Iron Man 2 Adaptation (2012)",
  "Marvel's Iron Man 3 Prelude (2012 - 2013)",
  "Marvel's Spider-Man: City at War (2019 - Present)",
  "Marvel's Spider-Man: Homecoming Prelude (2017)",
  "Marvel's Spider-Man: The Black Cat Strikes (2020)",
  "Marvel's Spider-Man: Velocity (2019 - Present)",
  "Marvel's the Avengers (2014 - 2015)",
  "Marvel's The Avengers Prelude: Fury's Big Week (2011 - 2012)",
  "Marvel's The Avengers: Black Widow Strikes (2012)",
  "Marvel's The Avengers: The Avengers Initiative (2011 - 2012)",
  "Marvel's Thor Adaptation (2013)",
  "Marvel's Thor: Ragnarok Prelude (2017)",
  "Marvel's Thor: The Dark World Prelude 2 (2012 - 2013)",
  "Marvel: Now What? (2013)",
  "Marvel: The Lost Generation (2000 - 2001)",
  "Marvel: Your Universe (2009)",
  "Marvelman (1954 - 1963)",
  "Marvelman Classic Primer (2010)",
  "Marvelman Family's Finest (2010)",
  "Marvelous Adventures of Gus Beezer & Spider-Man (2004)",
  "Marvelous Adventures of Gus Beezer: Hulk (2003)",
  "Marvelous Adventures of Gus Beezer: Spider-Man (2003)",
  "Marvelous Adventures of Gus Beezer: X-Men (2003)",
  "Marvels (1994)",
  "Marvels Annotated (2019)",
  "Marvels Epilogue (2019)",
  "Marvels X (2020 - Present)",
  "Marvels: Eye of the Camera (2008 - 2010)",
  "MARVEL SAMPLER FEBRUARY 2020  (2020 - Present)",
  "MARVEL’S ANT-MAN– SCOTT LANG: SMALL TIME MCU INFINITE COMIC (2015)",
  "Marville (2002)",
  "Mary Jane (2004)",
  "Mary Jane and Friends Poster Book (2009)",
  "Mary Jane: Homecoming (2005)",
  "Masked Marvel Digital Comic (2006)",
  "Master of Kung Fu (1974 - 1979)",
  "Master of Kung Fu (2015)",
  "Master of Kung Fu (2017)",
  "Master of Kung Fu Annual (1976)",
  "Master of Kung Fu: Bleeding Black (1990)",
  "Max Ride: Final Flight (2016 - 2017)",
  "Max Ride: First Flight (2015)",
  "Max Ride: Ultimate Flight (2015 - 2016)",
  "Max Sampler (2007)",
  "Maximum Security (2000 - 2001)",
  "Maximum Security: Dangerous Planet (2000)",
  "Meet the Skrulls (2019)",
  "Mega Morphs (2005)",
  "Men's Adventures (1950 - 1954)",
  "Menace (1953)",
  "Mephisto Vs. (1987)",
  "Merry X-Men Holiday Special (2018)",
  "Microverse: Enigma Force (2010)",
  "Mighty Avengers (2013 - 2014)",
  "Mighty Thor (2015 - 2018)",
  "Mighty Thor 3D (2019)",
  "Mighty Thor: At the Gates of Valhalla (2018)",
  "Miles Morales: Spider-Man (2018 - Present)",
  "Miles Morales: The End (2020)",
  "Miles Morales: Ultimate Spider-Man (2014 - 2015)",
  "Millar & Mcniven's Nemesis (2010)",
  "Millie the Model (1945 - 1965)",
  "Minimum Carnage: Alpha (2012)",
  "Minimum Carnage: Omega (2012)",
  "Miracleman (2014 - 2015)",
  "Miracleman by Gaiman & Buckingham (2015 - 2016)",
  "Miss America Comics 70th Anniversary Special (2009)",
  "Mockingbird (2016)",
  "Mockingbird: S.H.I.E.L.D. 50th Anniversary (2015)",
  "Modeling with Millie (1963 - 1966)",
  "Models, Inc. (2009 - 2010)",
  "Monsters Unleashed (1973)",
  "Monsters Unleashed (2017 - 2018)",
  "Monsters Unleashed (2017)",
  "Monsters, Inc. (2012 - 2013)",
  "Moon Girl and Devil Dinosaur (2015 - Present)",
  "Moon Girl and Devil Dinosaur (2019 - 2020)",
  "Moon Knight (1980 - 1984)",
  "Moon Knight (2006 - 2009)",
  "Moon Knight (2010 - 2012)",
  "Moon Knight (2014 - 2015)",
  "Moon Knight (2016 - 2018)",
  "Moon Knight Annual (2019)",
  "MOON KNIGHT ANNUAL 1 (2007)",
  "Moon Knight: Silent Knight (2008)",
  "Morbius (2019 - 2020)",
  "Morbius: The Living Vampire (1992 - 1995)",
  "Morbius: The Living Vampire (2013)",
  "Morlocks (2002)",
  "Mort The Dead Teenager (1993 - 1994)",
  "Mosaic (2016 - 2017)",
  "Mr. and Mrs. X (2018 - 2019)",
  "Mrs. Deadpool and the Howling Commandos (2015)",
  "Ms. Marvel (1977 - 1979)",
  "Ms. Marvel (2006 - 2010)",
  "Ms. Marvel (2014 - 2015)",
  "Ms. Marvel (2015 - 2019)",
  "Ms. Marvel (2018)",
  "Ms. Marvel Annual (2008)",
  "Ms. Marvel Annual (2019)",
  "Ms. Marvel Infinite (2014)",
  "Ms. Marvel Special (2007)",
  "Ms. Marvel Special - One Shot (2007)",
  "Ms. Marvel Special: Storyteller (2008)",
  "Ms. Marvel Vol. 2 (2018 - 2019)",
  "Ms. Marvel: Garden State of Mind (2018)",
  "Ms. Marvel: War of the Marvels Must Have One-Shot (2009)",
  "Multiple Man (2018)",
  "Muppets (2012)",
  "Mutant 2099 (2004)",
  "Mutant X (1998 - 2001)",
  "Mutant X Annual (1999)",
  "Mutant X Annual (2000)",
  "Mutant X Annual (2001)",
  "Muties (2002)",
  "Mutopia X (2005)",
  "Mystery Men (2011)",
  "Mystic (2011)",
  "Mystic Arcana (2007 - 2008)",
  "Mystic Comics (1940 - 1942)",
  "Mystic Comics 70th Anniversary Special (2009)",
  "Mystique (2003 - 2005)",
  "Mythos: Captain America (2008)",
  "Mythos: Fantastic Four (2007)",
  "Mythos: Ghost Rider (2007)",
  "Mythos: Hulk (2006)",
  "Mythos: Spider-Man (2007)",
  "MYTHOS: X-MEN 1 (2006)",
  "Namor (2003 - 2004)",
  "Namor: The Best Defense (2018)",
  "Namor: The First Mutant (2010 - 2011)",
  "Namor: The First Mutant Annual (2011)",
  "Namor: The Sub-Mariner (1990 - 1995)",
  "Namora (2010)",
  "Nation X (2009 - 2010)",
  "Nation X: X-Factor (2010)",
  "NAVY 101 STANDARD COMIC (2008)",
  "New Avengers (2004 - 2010)",
  "New Avengers (2010 - 2012)",
  "New Avengers (2013 - 2015)",
  "New Avengers (2015 - 2016)",
  "New Avengers Annual (2006)",
  "New Avengers Annual (2011)",
  "New Avengers Annual (2014)",
  "New Avengers Finale (2010)",
  "New Avengers Poster Book (2008)",
  "New Avengers Vol.2: Sentry (2006)",
  "New Avengers/Transformers (2007 - 2008)",
  "New Avengers: Illuminati (2006 - 2008)",
  "New Avengers: Luke Cage (2010)",
  "NEW AVENGERS: MOST WANTED FILES (2005)",
  "New Avengers: The Reunion (2009)",
  "New Avengers: Ultron Forever (2015)",
  "New Excalibur (2005 - 2007)",
  "New Exiles (2008 - 2009)",
  "New Exiles Annual (2008)",
  "New Invaders (2004 - 2005)",
  "New Mangaverse (2006)",
  "New Mutants (1983 - 1991)",
  "New Mutants (2003 - 2004)",
  "New Mutants (2009 - 2012)",
  "New Mutants (2019 - 2020)",
  "New Mutants 98 Facsimile Edition (2019)",
  "New Mutants Annual (1984 - 1991)",
  "New Mutants Forever (2010)",
  "New Mutants Saga (2009)",
  "New Mutants Special Edition (1985)",
  "New Mutants: Dead Souls (2018)",
  "New Mutants: War Children (2019)",
  "New Thunderbolts (2004 - 2006)",
  "New Warriors (1990 - 1996)",
  "New Warriors (2005)",
  "New Warriors (2007 - 2009)",
  "New Warriors (2014)",
  "New Warriors Annual (1991 - 1994)",
  "New X-Men (2001 - 2004)",
  "New X-Men (2004 - 2008)",
  "New X-Men Annual (2001)",
  "New X-Men: Academy X Yearbook Special (2005)",
  "New X-Men: Hellions (2005)",
  "New-Gen (2008)",
  "Newuniversal (2006 - 2007)",
  "Newuniversal: 1959 (2008)",
  "Newuniversal: Conqueror (2008)",
  "Newuniversal: Shockfront (2008)",
  "Nextwave: Agents of H.a.T.E. (2006 - 2010)",
  "Nick Fury (2017)",
  "Nick Fury Vs. S.H.I.E.L.D. (1988)",
  "Nick Fury's Howling Commandos (2005 - 2006)",
  "Nick Fury, Agent of S.H.I.E.L.D. (1968 - 1971)",
  "Nick Fury, Agent of S.H.I.E.L.D. (1989 - 1992)",
  "Nick Fury, Agent of Shield (1983 - 1984)",
  "Night Nurse (1972)",
  "Night Nurse (2015)",
  "Night of the Living Deadpool (2014)",
  "Night Thrasher: Four Control (1992 - 1993)",
  "Nightcrawler (2004 - 2006)",
  "Nightcrawler (2014 - 2015)",
  "Nighthawk (2016)",
  "Nightmask (1986 - 1987)",
  "Nightside (2001 - 2002)",
  "Nomad (1992 - 1994)",
  "Nomad: Girl Without a World (2009 - 2010)",
  "Northanger Abbey (2011 - 2012)",
  "Not Brand Echh (1967 - 1969)",
  "Not Brand Echh (2017)",
  "Nova (1976 - 1979)",
  "Nova (2007 - 2010)",
  "Nova (2013 - 2015)",
  "Nova (2015 - 2016)",
  "Nova (2016 - 2017)",
  "Nova Annual (2008)",
  "Nova Annual (2015)",
  "Nova Special (2014)",
  "Nova: Origin of Richard Rider (2009)",
  "November 2010 Sneak Peeks (2010)",
  "NYX (2003 - 2005)",
  "NYX: No Way Home (2008 - 2009)",
  "Obi-Wan and Anakin (2016)",
  "Obnoxio The Clown vs. X-Men (1983)",
  "Occupy Avengers (2016 - 2017)",
  "Official Handbook of the Marvel Universe (2004)",
  "Official Handbook of the Marvel Universe a to Z Update (2010)",
  "Official Handbook of the Ultimate Marvel Universe #1 Book 2 (2006)",
  "Official Handbook of the Ultimate Marvel Universe #2 Book 1 (2006)",
  "Official Handbook of the Ultimate Marvel Universe #2 Book 2 (2006)",
  "Official Handbook of the Ultimate Marvel Universe 2005 (2005)",
  "Official Index to the Marvel Universe (2009 - 2010)",
  "Old Man Hawkeye (2018)",
  "Old Man Logan (2015)",
  "Old Man Logan (2016 - 2018)",
  "Old Man Logan Annual (2018)",
  "Old Man Quill (2019 - Present)",
  "Omega Flight (2007)",
  "Omega the Unknown (1976 - 1977)",
  "Omega: The Unknown (2007 - 2008)",
  "ONSLAUGHT EPILOGUE 1 (1997)",
  "Onslaught Reborn (2006 - 2007)",
  "Onslaught Unleashed (2010 - 2011)",
  "ONSLAUGHT: MARVEL UNIVERSE 1 (1996)",
  "ONSLAUGHT: X-MEN 1 (1996)",
  "Operation: S.I.N. (2015)",
  "Origin (2001 - 2002)",
  "Origin II (2013 - 2014)",
  "Original Sin (2014)",
  "Original Sin Annual (2014)",
  "Original Sin: Secret Avengers Infinite Comic (2014)",
  "Original Sins (2014)",
  "Origins of Marvel Comics (2010)",
  "Origins of Marvel Comics: X-Men (2010)",
  "Origins of Siege (2009)",
  "Ororo: Before the Storm (2005)",
  "Orson Scott Card's Ender in Exile (2010)",
  "Orson Scott Card's Speaker for the Dead (2010 - 2011)",
  "Osborn (2010 - 2011)",
  "Our Love Story (1973)",
  "Outlaw Kid (1970 - 1975)",
  "Oz Primer (2011)",
  "Ozma of Oz (2010 - 2011)",
  "Painkiller Jane: The Price of Freedom (2013 - 2014)",
  "Paradise X (2002 - 2003)",
  "Paradise X: A (2003)",
  "PARADISE X: DEVILS 1 (2002)",
  "Paradise X: Heralds (2001 - 2002)",
  "Paradise X: Ragnarok (2003)",
  "Paradise X: XEN (2002)",
  "Patsy and Hedy Annual (1963)",
  "Patsy Walker, a.K.a. Hellcat! (2015 - 2017)",
  "Patsy Walker: Hellcat (2008 - 2009)",
  "Penance: Relentless (2007)",
  "Penance: Relentless (2008)",
  "Peter Parker (2009 - 2010)",
  "Peter Parker Spider-Man Annual (2000)",
  "PETER PARKER SPIDER-MAN ANNUAL 1 (1999)",
  "Peter Parker, Spider-Man (2012)",
  "Peter Parker, the Spectacular Spider-Man (1976 - 1998)",
  "Peter Parker, the Spectacular Spider-Man Annual (1979 - 1991)",
  "Peter Parker: Spider-Man (1999 - 2003)",
  "Peter Parker: The Spectacular Spider-Man (2017 - 2018)",
  "Peter Parker: The Spectacular Spider-Man Annual (2018)",
  "Peter Porker, the Spectacular Spider-Ham (1985 - 1987)",
  "Phoenix Resurrection: The Return of Jean Grey (2017 - 2018)",
  "Phoenix: The Untold Story (1983)",
  "Pint-Sized X-Babies: Murderama (1998)",
  "Pirate's Booty & The Avengers (2014)",
  "Planet Hulk (2015)",
  "Planet Hulk: Gladiator Guidebook (2006)",
  "Planet Skaar Prologue (2009)",
  "Planet Terry (1985 - 1986)",
  "Poe Dameron (2016 - 2018)",
  "Point One (2011)",
  "Power Man (1974 - 1978)",
  "Power Man and Iron Fist (1978 - 1986)",
  "Power Man and Iron Fist (2010 - 2011)",
  "Power Man and Iron Fist (2016 - 2017)",
  "Power Man and Iron Fist: Sweet Christmas Annual (2016)",
  "Power Man Annual (1976)",
  "Power Pachyderms (1989)",
  "Power Pack (1984 - 1991)",
  "Power Pack (2005)",
  "Power Pack (2017)",
  "Power Pack: Day One (2008)",
  "Power Pack: Grow Up! (2019)",
  "Powerless (2004)",
  "Powers (2000 - 2004)",
  "Powers (2004 - 2008)",
  "Powers (2009 - 2012)",
  "Powers (2015 - 2017)",
  "Powers Annual (2008)",
  "Powers Encyclopedia Vol. (2009)",
  "Powers Firsts (2015)",
  "Powers of X (2019)",
  "Powers: Bureau (2012 - 2013)",
  "Powers: Bureau (2013 - 2014)",
  "Prelude to Deadpool Corps (2010)",
  "Pride & Prejudice (2009)",
  "Princess Leia (2015)",
  "Prowler (2016 - 2017)",
  "Pryde and Wisdom (1996)",
  "Psi-Force (1986 - 1989)",
  "Psylocke (2009 - 2010)",
  "Ptolus: City by the Spire (2006 - 2007)",
  "Pulse (2004 - 2006)",
  "Punisher (1995 - 1996)",
  "Punisher (2000 - 2001)",
  "Punisher (2001 - 2003)",
  "Punisher (2009 - 2010)",
  "Punisher 2099 (1993)",
  "Punisher 2099 (2004)",
  "Punisher Annual (2009)",
  "Punisher Annual (2019)",
  "Punisher Invades The 'Nam: Final Invasion (1994)",
  "Punisher Kill Krew (2019 - Present)",
  "Punisher Kills the Marvel Universe (1995)",
  "Punisher Kills the Marvel Universe One-Shot (2008)",
  "Punisher Max (2004 - 2008)",
  "Punisher Max Annual (2008)",
  "Punisher Max MGC (2010)",
  "Punisher Max Special: Little Black Book (2008)",
  "Punisher Max X-Mas Special (2008)",
  "Punisher Max: Butterfly One-Shot (2010)",
  "Punisher Max: Get Castle (2010)",
  "Punisher Max: Hot Rods of Death (2010)",
  "Punisher Max: Naked Kill (2009)",
  "Punisher Max: Tiny Ugly World (2013 - 2010)",
  "Punisher Noir (2009 - 2010)",
  "Punisher Poster Book (2008)",
  "Punisher Presents: Barracuda Max (2007)",
  "Punisher Saga (2008)",
  "Punisher Valentine's One (2006)",
  "Punisher Vs. Bullseye (2005 - 2006)",
  "Punisher War Journal (1988 - 1995)",
  "Punisher War Journal (2006 - 2009)",
  "Punisher War Journal Annual (2008)",
  "Punisher/Black Widow: Spinning Doomsday's Web (1992)",
  "Punisher/Captain America: Blood and Glory (1992 - Present)",
  "Punisher: Bloody Valentine (2006)",
  "Punisher: Force of Nature (2008)",
  "Punisher: Frank Castle (2009)",
  "Punisher: Frank Castle Max (2009)",
  "Punisher: Franken-Castle - The Birth of the Monster (2010)",
  "Punisher: In the Blood (2010 - 2011)",
  "Punisher: Nightmare (2013)",
  "Punisher: Red X-Mas (2004)",
  "Punisher: Silent Night (2005)",
  "Punisher: Soviet (2019 - 2020)",
  "Punisher: The Cell (2005)",
  "Punisher: The End (2004)",
  "Punisher: The Movie (2004)",
  "Punisher: The Platoon (2017 - 2018)",
  "Punisher: The Trial of the Punisher (2013)",
  "Punisher: The Tyger (2006)",
  "Punisher: War Zone (2008 - 2009)",
  "Punisher: War Zone (2012 - 2013)",
  "Punisher: X-Mas Special (2006)",
  "Punishermax (2009 - 2011)",
  "Punishermax (2010 - 2012)",
  "Punishermax: Kingpin (2010)",
  "Quake: S.H.I.E.L.D. 50th Anniversary (2015)",
  "Quasar (1989 - 1994)",
  "Quasar (1999 - 1994)",
  "Quicksilver (1997 - 1998)",
  "Quicksilver: No Surrender (2018)",
  "Rampaging Wolverine (2009)",
  "Ravage 2099 (1992)",
  "Ravencroft (2020 - Present)",
  "Rawhide Kid (1955 - 1979)",
  "Rawhide Kid (2003)",
  "REALM OF KINGS (2009)",
  "Realm of Kings: Imperial Guard (2009 - 2010)",
  "Realm of Kings: Inhumans (2009 - 2010)",
  "Realm of Kings: Son of Hulk (2010)",
  "Red Goblin: Red Death (2019)",
  "Red Prophet: The Tales of Alvin Maker (2006 - 2008)",
  "Red Raven Comics (1940)",
  "Red She-Hulk (2012 - 2013)",
  "Red Skull (2010 - 2011)",
  "Red Skull (2015)",
  "Red Wolf (2015 - 2016)",
  "Rescue (2010)",
  "Return of the Living Deadpool (2015)",
  "Return of Wolverine (2018 - 2019)",
  "Revenge of the Cosmic Ghost Rider (2019 - 2020)",
  "Revolutionary War: Alpha (2014)",
  "Revolutionary War: Dark Angel (2014)",
  "REVOLUTIONARY WAR: DEATH'S HEAD II 1 SHARP VARIANT (2014)",
  "Revolutionary War: Knights of Pendragon (2014)",
  "Revolutionary War: Motormouth (2014)",
  "Revolutionary War: Omega (2014)",
  "Revolutionary War: Supersoldiers (2014)",
  "Revolutionary War: Warheads (2014)",
  "Riftwar (2009)",
  "Rise of Apocalypse (1996 - 1997)",
  "Rise of the Black Panther (2018)",
  "Road to Oz (2011 - 2013)",
  "Rocket (2017)",
  "Rocket Raccoon & Groot (2016)",
  "Rocket Raccoon (1985)",
  "Rocket Raccoon (2014 - 2015)",
  "Rocket Raccoon (2016 - 2017)",
  "Rocket Raccoon: Tales from Half-World (2013)",
  "Rogue & Gambit (2018)",
  "Rogue (2004 - 2005)",
  "Royal Roy (1985 - 1986)",
  "Royals (2017)",
  "Ruins (1995)",
  "Ruins Of Ravencroft: Carnage (2020)",
  "Ruins Of Ravencroft: Dracula (2020)",
  "Ruins Of Ravencroft: Sabretooth (2020)",
  "Runaways (2003 - 2004)",
  "Runaways (2005 - 2008)",
  "Runaways (2008 - 2009)",
  "Runaways (2015)",
  "Runaways (2017 - Present)",
  "Runaways Saga (2007)",
  "Ruse (2011)",
  "S.H.I.E.L.D. (2010 - 2011)",
  "S.H.I.E.L.D. (2011 - 2018)",
  "S.H.I.E.L.D. (2014 - 2015)",
  "S.H.I.E.L.D. by Hickman & Weaver: The Rebirth (2018)",
  "S.H.I.E.L.D. Origins (2013)",
  "S.W.O.R.D. (2009 - 2010)",
  "Sable & Fortune (2006)",
  "Sabretooth (2004)",
  "Sabretooth Special (1995)",
  "Sabretooth: Mary Shelley Overdrive (2002)",
  "Saga of the Squadron Supreme (2006)",
  "Saga of the Sub-Mariner (1988 - 1989)",
  "Savage Avengers (2019 - Present)",
  "Savage Avengers Annual (2019)",
  "Savage Hulk (1996)",
  "Savage Hulk (2014)",
  "Savage She-Hulk (1980 - 1982)",
  "Savage She-Hulk (2009)",
  "Savage Sword of Conan (2019 - Present)",
  "SAVAGE SWORD OF CONAN ANNUAL 1 (1975)",
  "Savage Tales (1971 - 1975)",
  "Savage Wolverine (2013 - 2014)",
  "Scarlet (2010 - 2016)",
  "Scarlet Spider (2011 - 2012)",
  "Scarlet Spider (2012 - 2013)",
  "Scarlet Spiders (2014 - 2015)",
  "Scarlet Witch (2016 - 2017)",
  "Scream: Curse of Carnage (2019)",
  "Season's Beatings (2018)",
  "Second Coming: Prepare (2010)",
  "Secret Avengers (2010 - 2012)",
  "Secret Avengers (2013 - 2014)",
  "Secret Avengers (2014 - 2015)",
  "Secret Defenders (1993 - 1995)",
  "Secret Empire (2017)",
  "Secret Empire Omega (2017)",
  "Secret Empire: Brave New World (2017)",
  "Secret Empire: Underground (2017)",
  "Secret Empire: United (2017)",
  "Secret Empire: Uprising (2017)",
  "Secret Invasion (2008)",
  "Secret Invasion Aftermath: Beta Ray Bill - The Green Of Eden (2009 - 2010)",
  "Secret Invasion Chronicles (2009)",
  "Secret Invasion Extended Cut One-Shot (2008)",
  "Secret Invasion Poster Book (2009)",
  "Secret Invasion Saga (2008)",
  "Secret Invasion: Amazing Spider-Man (2008)",
  "Secret Invasion: Dark Reign (2008)",
  "Secret Invasion: Fantastic Four (2008)",
  "Secret Invasion: Front Line (2008 - 2009)",
  "Secret Invasion: Home Invasion Digital Comic (2008)",
  "Secret Invasion: Inhumans (2008 - 2009)",
  "SECRET INVASION: REQUIEM 1 (2008)",
  "Secret Invasion: Runaways/Young Avengers (2008)",
  "Secret Invasion: Thor (2008 - 2009)",
  "Secret Invasion: War of Kings (2009)",
  "Secret Invasion: Who Do You Trust? (2008)",
  "Secret Invasion: X-Men (2008)",
  "Secret Service (2012 - 2013)",
  "Secret War (2004 - 2005)",
  "Secret War: From the Files of Nick Fury (2005)",
  "Secret Warps: Arachknight Annual (2019)",
  "Secret Warps: Ghost Panther Annual (2019)",
  "Secret Warps: Iron Hammer Annual (2019)",
  "Secret Warps: Soldier Supreme Annual (2019)",
  "Secret Warps: Weapon Hex Annual (2019)",
  "Secret Warriors (2017 - 2018)",
  "Secret Warriors (2009 - 2011)",
  "Secret Wars (1984 - 1985)",
  "Secret Wars (2015 - 2016)",
  "Secret Wars 2099 (2015)",
  "Secret Wars II (1985)",
  "Secret Wars Journal (2015)",
  "Secret Wars, Too (2015)",
  "Secret Wars: Agents of Atlas (2015)",
  "Secret Wars: Battleworld (2015)",
  "SECRET WARS: OFFICIAL GUIDE TO THE MARVEL MULTIVERSE 1 (2015)",
  "SECRET WARS: SECRET LOVE 1 (2015)",
  "Secrets Of The House Of M (2005)",
  "Sensational She-Hulk (1989 - 1994)",
  "Sensational Spider-Man (1996)",
  "Sensational Spider-Man (2006 - 2007)",
  "Sensational Spider-Man Annual (2007)",
  "Sensational Spider-Man: Self-Improvement (2019)",
  "Sense & Sensibility (2010)",
  "Sentinel (2003 - 2004)",
  "Sentinel (2005 - 2006)",
  "Sentinel Squad O*N*E (2006)",
  "Sentry (2000 - 2001)",
  "Sentry (2005 - 2006)",
  "Sentry (2018)",
  "Sentry: Fallen Sun (2010)",
  "Sentry: The Void (2001)",
  "SERGIO ARAGONES STOMPS STAR WARS 1 (2000)",
  "Sgt. Fury (1963 - 1974)",
  "Sgt. Fury and His Howling Commandos Annual (1965 - 1971)",
  "Shadowland (2010)",
  "Shadowland: Blood on the Streets (2010)",
  "Shadowland: Bullseye (2010)",
  "Shadowland: Daughters of the Shadow (2010)",
  "Shadowland: Elektra (2010)",
  "Shadowland: Ghost Rider (2010)",
  "Shadowland: Moon Knight (2010)",
  "Shadowland: Power Man (2010)",
  "Shadowland: Spider-Man (2010)",
  "Shadowland:After the Fall (2010)",
  "Shadowmasters (1989 - 1990)",
  "Shame Itself (2011)",
  "Shang-Chi: Master of Kung Fu (2009)",
  "Shanna the She-Devil (1972 - 1973)",
  "Shanna, the She-Devil (2005)",
  "Shanna, the She-Devil: Survival of the Fittest (2007)",
  "Sharon Carter & Black Widow (2010 - 2011)",
  "Shatterstar (2018 - 2019)",
  "She-Hulk (2004 - 2005)",
  "She-Hulk (2005 - 2009)",
  "She-Hulk (2014 - 2015)",
  "She-Hulk (2017 - 2018)",
  "She-Hulk Annual (2019)",
  "She-Hulk Sensational (2010)",
  "She-Hulk: Cosmic Collision (2008)",
  "She-Hulks (2010 - 2011)",
  "Shuri (2018 - Present)",
  "Siege (2009)",
  "Siege (2015)",
  "Siege Director's Cut (2010)",
  "Siege: Captain America (2010)",
  "Siege: Embedded (2010)",
  "Siege: Loki (2010)",
  "Siege: Secret Warriors (2010)",
  "Siege: Spider-Man (2010)",
  "Siege: Storming Asgard - Heroes & Villains (2009)",
  "Siege: The Cabal (2010)",
  "Siege: Young Avengers (2010)",
  "Sif (2010)",
  "Sigil (2011)",
  "Silent War (2007)",
  "Silk (2015 - 2017)",
  "Silk (2015)",
  "Silver Sable & the Wild Pack (1992)",
  "Silver Sable and the Wild Pack (2017)",
  "Silver Surfer (1968 - 1969)",
  "Silver Surfer (1982)",
  "Silver Surfer (1987 - 1998)",
  "Silver Surfer (1988 - 1989)",
  "Silver Surfer (2003 - 2004)",
  "Silver Surfer (2010 - 2011)",
  "Silver Surfer (2014 - 2015)",
  "Silver Surfer (2016 - 2017)",
  "Silver Surfer Annual (1988 - 1997)",
  "Silver Surfer Annual (1997)",
  "Silver Surfer Annual (2018)",
  "Silver Surfer Facsimile Edition (2019)",
  "Silver Surfer/Warlock: Resurrection (1993)",
  "Silver Surfer: Black (2019 - Present)",
  "Silver Surfer: Enslavers (1990)",
  "Silver Surfer: In Thy Name (2007)",
  "Silver Surfer: Parable (1988)",
  "Silver Surfer: Requiem (2007)",
  "Silver Surfer: The Best Defense (2018)",
  "Silver Surfer: The Prodigal Sun (2019)",
  "Six Guns (2011 - 2012)",
  "Skaar: King of the Savage Land (2011)",
  "Skaar: Son of Hulk (2008 - 2010)",
  "Skaar: Son of Hulk Presents - Savage World of Sakaar (2008)",
  "Skrull Kill Krew (1995)",
  "Skrulls Vs. Power Pack (2008)",
  "Skrulls! (2008)",
  "Skull the Slayer (1975 - 1976)",
  "Sky Doll Space Ship (2010)",
  "Slapstick (2016 - 2017)",
  "Slapstick Infinite Comic (2016 - 2017)",
  "Slingers (1998 - 1999)",
  "Soldier X (2002 - 2003)",
  "Solo (2016 - 2017)",
  "Solo Avengers (1987 - 1989)",
  "Solo: A Star Wars Story Adaptation (2018 - 2019)",
  "Son of Hulk (2009)",
  "Son of M (2005 - 2006)",
  "Son of Satan (1975 - 1977)",
  "Son Of Satan: Marvel Spotlight Facsimile Edition (2019)",
  "Space: Punisher (2011 - 2012)",
  "Spaceknights (2012)",
  "Special Edition: X-Men (1983)",
  "Special Marvel Edition (1971 - 1974)",
  "Spectacular Spider-Girl (2010)",
  "Spectacular Spider-Man (1968)",
  "Spectacular Spider-Man (2003 - 2005)",
  "Spectacular Spider-Man (2011)",
  "Speedball (1988 - 1989)",
  "Spellbinders (2005)",
  "Spider-Force (2018)",
  "Spider-Geddon (2018 - 2019)",
  "Spider-Geddon Handbook (2018)",
  "Spider-Girl (1998 - 2006)",
  "Spider-Girl (2010 - 2011)",
  "SPIDER-GIRL ANNUAL 1 (1999)",
  "Spider-Girl Presents: The Buzz (2000)",
  "Spider-Girl: The End! (2010)",
  "Spider-Girls (2018)",
  "Spider-Gwen (2015 - 2018)",
  "Spider-Gwen (2015)",
  "Spider-Gwen Annual (2016)",
  "Spider-Gwen: Ghost-Spider (2018 - Present)",
  "Spider-Ham (2019 - 2020)",
  "Spider-Ham 25th Anniversary Special (2010)",
  "Spider-Island (2015)",
  "Spider-Island Spotlight (2005)",
  "Spider-Island: Avengers (2011)",
  "Spider-Island: Cloak & Dagger (2011)",
  "Spider-Island: Daily Bugle (2011)",
  "Spider-Island: Deadly Foes (2011)",
  "Spider-Island: Deadly Hands of Kung Fu (2011)",
  "Spider-Island: Emergence of Evil - Jackal & Hobgoblin (2011)",
  "Spider-Island: Heroes for Hire (2011)",
  "Spider-Island: I Love New York City (2011)",
  "Spider-Island: Spider-Woman (2011)",
  "Spider-Island: The Amazing Spider-Girl (2011)",
  "Spider-Man & Arana Special: The Hunter (2006)",
  "Spider-Man & the Human Torch in...Bahia De Los Muertos! (2009)",
  "Spider-Man & the League of Realms (2019)",
  "Spider-Man & the Secret Wars (2009 - 2010)",
  "Spider-Man & the X-Men (2014 - 2015)",
  "Spider-Man & Venom: Double Trouble (2019 - 2020)",
  "Spider-Man & Wolverine (2003)",
  "Spider-Man (1990 - 1998)",
  "Spider-Man (2016 - 2018)",
  "Spider-Man (2019 - Present)",
  "Spider-Man 1602 (2009 - 2010)",
  "Spider-Man 2099 (1992 - 1996)",
  "Spider-Man 2099 (2014 - 2015)",
  "Spider-Man 2099 (2015 - 2017)",
  "Spider-Man 2099 (2019)",
  "Spider-Man 2099 Annual (1994)",
  "Spider-Man 2099 Meets Spider-Man (1995)",
  "Spider-Man 2099 Special (1995)",
  "Spider-Man 2: The Movie (2004)",
  "Spider-Man and Power Pack (2007)",
  "Spider-Man and the Fantastic Four (2007)",
  "Spider-Man Annual (2018)",
  "Spider-Man Annual (2019)",
  "Spider-Man Fairy Tales (2007)",
  "Spider-Man Family (2005)",
  "Spider-Man Family (2007 - 2008)",
  "Spider-Man Family Featuring Spider-Clan (2006)",
  "SPIDER-MAN FAMILY FEATURING SPIDER-MAN'S AMAZING FRIENDS 1 (2006)",
  "SPIDER-MAN FEATURING THE SILVER SURFER MAGAZINE WAL-MART VARIANT (2007)",
  "Spider-Man Loves Mary Jane (2005 - 2007)",
  "Spider-Man Loves Mary Jane (2008 - 2009)",
  "Spider-Man Magazine (2008 - 2037)",
  "Spider-Man Magazine: Great Power (2007)",
  "Spider-Man Marvel Adventures (2010 - 2012)",
  "Spider-Man Noir (2008 - 2009)",
  "Spider-Man Noir: Eyes Without a Face (2009 - 2010)",
  "Spider-Man Poster (2007)",
  "Spider-Man Special: Black and Blue and Read All Over (2006)",
  "Spider-Man Spectacular (2014)",
  "Spider-Man Team-Up (1995)",
  "Spider-Man Team-Up Special (2005)",
  "Spider-Man Team-Up Vol. 1: A Little Help From My Friends (2005)",
  "Spider-Man Unlimited (1993)",
  "Spider-Man Unlimited (2004 - 2006)",
  "SPIDER-MAN VERSUS WOLVERINE 1 (1987)",
  "Spider-Man Vs. Punisher (2000)",
  "Spider-Man vs. Vampires (2010)",
  "Spider-Man Y La Antorcha Humana En...Bahia De Los Muertos! (2009)",
  "Spider-Man's Tangled Web (2001 - 2003)",
  "Spider-Man, Peter Parker: Back in Black (2007)",
  "Spider-Man/Black Cat: Evil That Men Do (2002 - 2006)",
  "SPIDER-MAN/DAREDEVIL 1 (2002)",
  "Spider-Man/Deadpool (2016 - 2019)",
  "Spider-Man/Doctor Octopus: Negative Exposure (2003 - 2004)",
  "Spider-Man/Doctor Octopus: Out of Reach (2004)",
  "Spider-Man/Doctor Octopus: Year One (2004)",
  "Spider-Man/Doctor Strange: The Way to Dusty Death (1992)",
  "Spider-Man/Fantastic Four (2010)",
  "Spider-Man/Fantastic Four Magazine (2007)",
  "Spider-Man/Human Torch (2005)",
  "Spider-Man/Punisher: Family Plot (1996)",
  "Spider-Man/Red Sonja (2007)",
  "Spider-Man: 101 Ways to End the Clone Saga (1997)",
  "Spider-Man: Back in Black (2007)",
  "Spider-Man: Back in Quack (2010)",
  "Spider-Man: Blue (2002 - 2003)",
  "Spider-Man: Brand New Day (2008 - 2009)",
  "Spider-Man: Brand New Day - Extra!! (2008)",
  "Spider-Man: Breakout (2005)",
  "Spider-Man: Chapter One (1998 - 1999)",
  "Spider-Man: Death and Destiny (2000)",
  "Spider-Man: Enter the Spider-Verse (2018)",
  "Spider-Man: Far from Home Prelude (2019)",
  "Spider-Man: Fear Itself (1992)",
  "Spider-Man: Fear Itself (2009)",
  "Spider-Man: Fever (2010)",
  "Spider-Man: Get Kraven (2002 - 2003)",
  "Spider-Man: Grim Hunt - The Kraven Saga (2010)",
  "Spider-Man: Hobgoblin Lives (1997)",
  "Spider-Man: House of M (2005)",
  "Spider-Man: India (2004 - 2005)",
  "Spider-Man: Legend of the Spider-Clan (2002)",
  "Spider-Man: Life Story (2019)",
  "Spider-Man: Lifeline (2001)",
  "Spider-Man: Master Plan (2017)",
  "SPIDER-MAN: ONE MORE DAY SKETCHBOOK (2007)",
  "Spider-Man: Origin of the Hunter (2010)",
  "Spider-Man: Quality of Life (2002)",
  "Spider-Man: Redemption (1996)",
  "Spider-Man: Reign (2006 - 2007)",
  "Spider-Man: Reptilian Rage (2019)",
  "Spider-Man: Revenge of the Green Goblin (2000)",
  "Spider-Man: Season One (2011)",
  "Spider-Man: Spirits of the Earth Graphic Novel (1990)",
  "Spider-Man: Swing Shift Director's Cut (2008)",
  "Spider-Man: The Clone Saga (2009 - 2010)",
  "Spider-Man: The Final Adventure (1995 - 1996)",
  "Spider-Man: The Gauntlet Vol.3 - Vulture & Morbius (2010)",
  "Spider-Man: The Gauntlet Vol.3 - Vulture and Morbius (2010)",
  "Spider-Man: The Mutant Agenda (1994)",
  "Spider-Man: The Other Sketchbook (2005)",
  "Spider-Man: The Parker Years (1995)",
  "Spider-Man: The Short Halloween (2009)",
  "Spider-Man: The Trial of Venom (1992)",
  "Spider-Man: The Venom Agenda (1998)",
  "Spider-Man: With Great Power Comes Great Responsibility (2010 - 2011)",
  "Spider-Man: With Great Power... (2008)",
  "Spider-Man: You're Hired! (2011)",
  "Spider-Men (2012)",
  "Spider-Men II (2017)",
  "Spider-Verse (2014 - 2015)",
  "Spider-Verse (2015)",
  "Spider-Verse (2019 - Present)",
  "Spider-Verse Team-Up (2014 - 2015)",
  "Spider-Woman (1978 - 1983)",
  "Spider-Woman (1993)",
  "Spider-Woman (1999 - 2000)",
  "Spider-Woman (2009 - 2010)",
  "Spider-Woman (2014 - 2015)",
  "Spider-Woman (2015 - 2017)",
  "Spider-Woman Facsimile Edition (2019)",
  "Spider-Woman: Origin (2005 - 2006)",
  "Spider-Women Alpha (2016)",
  "Spider-Women Omega (2016)",
  "Spidey (2015 - 2016)",
  "SPIDEY AND THE MINI-MARVELS 1 (2003)",
  "Spidey Sunday Spectacular! (2011)",
  "Spirits of Vengeance (2017)",
  "Spitfire (2010)",
  "Squadron Sinister (2015)",
  "Squadron Supreme (1985 - 1986)",
  "Squadron Supreme (2006)",
  "Squadron Supreme (2008 - 2009)",
  "Squadron Supreme (2015 - 2017)",
  "SQUADRON SUPREME: DEATH OF A UNIVERSE GRAPHIC NOVEL 1 (1989)",
  "Squadron Supreme: Hyperion Vs. Nighthawk (2007)",
  "SQUADRON SUPREME: NEW WORLD ORDER 1 (1999)",
  "Stan Lee Meets Doctor Strange (2006)",
  "Stan Lee Meets Dr. Doom (2006)",
  "Stan Lee Meets Silver Surfer (2006)",
  "Stan Lee Meets Spider-Man (2006)",
  "Stan Lee Meets the Thing (2006)",
  "Star (2020)",
  "Star Brand (1986 - 1989)",
  "Star Brand Annual (1987)",
  "Star Wars (1977 - 1986)",
  "Star Wars (1998 - 2002)",
  "Star Wars (2013 - 2014)",
  "Star Wars (2015 - Present)",
  "Star Wars (2020 - Present)",
  "Star Wars Annual (1979 - 1983)",
  "Star Wars Annual (2015 - Present)",
  "Star Wars Director's Cut (2015)",
  "Star Wars Facsimile Edition (2019)",
  "Star Wars Handbook (1998)",
  "Star Wars Handbook 1: X-Wing Rogue Squadron (1998)",
  "Star Wars Handbook 2: Crimson Empire (1998)",
  "Star Wars Infinities: A New Hope (2001)",
  "Star Wars Infinities: Return of the Jedi (2003 - 2004)",
  "Star Wars Infinities: The Empire Strikes Back (2002)",
  "STAR WARS MOVIE SAMPLER 1 (2015)",
  "Star Wars Saga (2019)",
  "Star Wars Special: C-3PO (2016)",
  "Star Wars Tales (1999 - 2005)",
  "Star Wars Visionaries (2005)",
  "Star Wars: A New Hope - Special Edition (1997)",
  "STAR WARS: A VALENTINE STORY 1 (2003)",
  "Star Wars: Age of Rebellion - Boba Fett (2019)",
  "Star Wars: Age Of Rebellion - Darth Vader (2019)",
  "Star Wars: Age Of Rebellion - Grand Moff Tarkin (2019)",
  "Star Wars: Age of Rebellion - Han Solo (2019)",
  "Star Wars: Age Of Rebellion - Jabba the Hutt (2019)",
  "Star Wars: Age Of Rebellion - Lando Calrissian (2019)",
  "Star Wars: Age of Rebellion - Luke Skywalker (2019)",
  "Star Wars: Age Of Rebellion - Princess Leia (2019)",
  "Star Wars: Age of Rebellion Special (2019)",
  "Star Wars: Age Of Republic - Anakin Skywalker (2019)",
  "Star Wars: Age of Republic - Count Dooku (2019)",
  "Star Wars: Age of Republic - Darth Maul (2018)",
  "Star Wars: Age Of Republic - General Grievous (2019)",
  "Star Wars: Age of Republic - Jango Fett (2019)",
  "Star Wars: Age of Republic - Obi-Wan Kenobi (2019)",
  "Star Wars: Age of Republic - Padme Amidala (2019)",
  "Star Wars: Age of Republic - Qui-Gon Jinn (2018)",
  "Star Wars: Age of Republic Special (2019)",
  "Star Wars: Age Of Resistance - Captain Phasma (2019)",
  "Star Wars: Age Of Resistance - Finn (2019)",
  "Star Wars: Age Of Resistance - General Hux (2019)",
  "Star Wars: Age Of Resistance - Kylo Ren (2019)",
  "Star Wars: Age Of Resistance - Poe Dameron (2019)",
  "Star Wars: Age Of Resistance - Rey (2019)",
  "Star Wars: Age Of Resistance - Rose Tico (2019)",
  "Star Wars: Age Of Resistance - Supreme Leader Snoke (2019)",
  "Star Wars: Age of Resistance Special (2019)",
  "Star Wars: Agent Of The Empire - Hard Targets (2012 - 2013)",
  "Star Wars: Agent of the Empire - Iron Eclipse (2011 - 2012)",
  "Star Wars: Beckett (2018)",
  "Star Wars: Blood Ties (2010)",
  "Star Wars: Blood Ties - Boba Fett Is Dead (2012)",
  "Star Wars: Boba Fett (1997 - 1996)",
  "STAR WARS: BOBA FETT - AGENT OF DOOM 1 (2000)",
  "Star Wars: Boba Fett - Bounty On Bar-Kooda (1995)",
  "Star Wars: Boba Fett - Enemy of the Empire (1999)",
  "Star Wars: Boba Fett - Murder Most Foul (1997)",
  "STAR WARS: BOBA FETT - OVERKILL 1 (2006)",
  "STAR WARS: BOBA FETT - TWIN ENGINES OF DESTRUCTION 1 (1997)",
  "Star Wars: Chewbacca (2000)",
  "Star Wars: Crimson Empire (1997 - 1998)",
  "Star Wars: Crimson Empire II - Council of Blood (1998 - 1999)",
  "Star Wars: Crimson Empire III - Empire Lost (2011 - 2012)",
  "Star Wars: Dark Empire (1991 - 1992)",
  "Star Wars: Dark Empire II (1994 - 1995)",
  "Star Wars: Dark Force Rising (1997)",
  "Star Wars: Dark Times (2006 - 2010)",
  "Star Wars: Dark Times - A Spark Remains (2013)",
  "STAR WARS: DARK TIMES - BLUE HARVEST 0 (2009)",
  "Star Wars: Dark Times - Fire Carrier (2013)",
  "Star Wars: Dark Times - Out of the Wilderness (2011 - 2012)",
  "Star Wars: Darth Maul (2000)",
  "Star Wars: Darth Maul (2017)",
  "Star Wars: Darth Maul - Death Sentence (2012)",
  "Star Wars: Darth Maul - Son Of Dathomir (2014)",
  "Star Wars: Darth Vader (2020 - Present)",
  "Star Wars: Darth Vader and the Cry of Shadows (2013 - 2014)",
  "Star Wars: Darth Vader and the Ghost Prison (2012)",
  "Star Wars: Darth Vader and the Lost Command (2011)",
  "Star Wars: Darth Vader and the Ninth Assassin (2013)",
  "Star Wars: Dawn of the Jedi - Force Storm (2012)",
  "Star Wars: Dawn of the Jedi - Force War (2013 - 2014)",
  "Star Wars: Dawn of the Jedi - Prisoner of Bogan (2012 - 2013)",
  "STAR WARS: DAWN OF THE JEDI 0 (2012)",
  "Star Wars: Doctor Aphra (2016 - Present)",
  "Star Wars: Doctor Aphra Annual (2017 - 2018)",
  "Star Wars: Doctor Aphra Annual (2019)",
  "Star Wars: Droids (1986)",
  "Star Wars: Droids (1994)",
  "Star Wars: Droids (1995)",
  "Star Wars: Droids - The Protocol Offensive (1997)",
  "STAR WARS: DROIDS SPECIAL 1 (1995)",
  "Star Wars: Droids Unplugged (2017)",
  "Star Wars: Empire (2002 - 2006)",
  "Star Wars: Empire Ascendant (2019)",
  "Star Wars: Empire's End (1995)",
  "STAR WARS: EPISODE I - ANAKIN SKYWALKER 1 (1999)",
  "STAR WARS: EPISODE I - OBI-WAN KENOBI 1 (1999)",
  "STAR WARS: EPISODE I - QUEEN AMIDALA 1 (1999)",
  "STAR WARS: EPISODE I - QUI-GON JINN 1 (1999)",
  "Star Wars: Episode I - The Phantom Menace (1999)",
  "STAR WARS: EPISODE I - THE PHANTOM MENACE MANGA VOL. 1 DIGEST (1999 - 2000)",
  "Star Wars: Episode II - Attack of the Clones (2002)",
  "Star Wars: Episode III - Revenge of the Sith (2005)",
  "Star Wars: Ewoks (1985 - 1987)",
  "Star Wars: Galaxy's Edge (2019)",
  "Star Wars: General Grievous (2005)",
  "Star Wars: Han Solo - Imperial Cadet (2018 - 2019)",
  "Star Wars: Heir to the Empire (1995 - 1996)",
  "Star Wars: Invasion (2009)",
  "Star Wars: Invasion - Rescues (2010)",
  "Star Wars: Invasion - Revelations (2011)",
  "STAR WARS: JABBA THE HUTT - BETRAYAL 1 (1995)",
  "STAR WARS: JABBA THE HUTT - THE DYNASTY TRAP 1 (1995)",
  "STAR WARS: JABBA THE HUTT - THE GAAR SUPPOON HIT 1 (1995)",
  "STAR WARS: JABBA THE HUTT - THE HUNGER OF PRINCESS NAMPI 1 (1995)",
  "Star Wars: Jango Fett (2002)",
  "Star Wars: Jango Fett - Open Seasons (2002)",
  "STAR WARS: JEDI - AAYLA SECURA 1 (2003)",
  "STAR WARS: JEDI - COUNT DOOKU 1 (2003)",
  "STAR WARS: JEDI - MACE WINDU 1 (2003)",
  "STAR WARS: JEDI - SHAAK TI 1 (2003)",
  "Star Wars: Jedi - The Dark Side (2011)",
  "STAR WARS: JEDI - YODA 1 (2004)",
  "Star Wars: Jedi Academy - Leviathan (1998 - 1999)",
  "Star Wars: Jedi Council - Acts of War (2000)",
  "Star Wars: Jedi Fallen Order - Dark Temple (2019)",
  "Star Wars: Jedi of the Republic – Mace Windu (2017)",
  "Star Wars: Jedi Quest (2001)",
  "Star Wars: Jedi Vs. Sith (2001)",
  "Star Wars: Knight Errant (2010 - 2011)",
  "Star Wars: Knight Errant - Deluge (2011)",
  "Star Wars: Knight Errant - Escape (2012)",
  "Star Wars: Knights of the Old Republic (2006 - 2010)",
  "Star Wars: Knights of the Old Republic - War (2012)",
  "STAR WARS: KNIGHTS OF THE OLD REPUBLIC HANDBOOK 1 (2007)",
  "Star Wars: Knights Of The Old Republic/Rebellion (2006)",
  "Star Wars: Lando - Double or Nothing (2018)",
  "Star Wars: Legacy (2006 - 2010)",
  "Star Wars: Legacy (2013 - 2014)",
  "Star Wars: Legacy - War (2010 - 2011)",
  "Star Wars: Lost Tribe of the Sith - Spiral (2012)",
  "Star Wars: Mara Jade - By the Emperor's Hand (1998 - 1999)",
  "Star Wars: Obsession (2004 - 2005)",
  "Star Wars: Poe Dameron Annual (2017 - 2018)",
  "STAR WARS: PURGE - SECONDS TO DIE 1 (2009)",
  "STAR WARS: PURGE - THE HIDDEN BLADE 1 (2010)",
  "Star Wars: Purge - The Tyrant's Fist (2012 - 2013)",
  "STAR WARS: PURGE 1 (2005)",
  "Star Wars: Qui-Gon & Obi-Wan - Last Stand on Ord Mantell (2000 - 2001)",
  "Star Wars: Qui-Gon & Obi-Wan - The Aurorient Express (2002)",
  "Star Wars: Rebel Heist (2014)",
  "Star Wars: Rebellion (2006 - 2008)",
  "Star Wars: Republic (2002 - 2006)",
  "Star Wars: Return of the Jedi (1983 - 1984)",
  "Star Wars: Return Of The Jedi Manga (1999)",
  "Star Wars: River of Chaos (1995)",
  "Star Wars: Rogue One - Cassian & K-2SO Special (2017)",
  "Star Wars: Rogue One Adaptation (2017)",
  "STAR WARS: SHADOW STALKER 1 (1997)",
  "Star Wars: Shadows of the Empire (1996)",
  "Star Wars: Shadows of the Empire - Evolution (1998)",
  "Star Wars: Splinter of the Mind's Eye (1995 - 1996)",
  "Star Wars: Starfighter - Crossbones (2002)",
  "Star Wars: Tag & Bink Are Dead (2001)",
  "Star Wars: Tag & Bink II (2006)",
  "Star Wars: Tag & Bink Were Here (2018)",
  "STAR WARS: TALES FROM MOS EISLEY 1 (1996)",
  "Star Wars: Tales of the Jedi (1993 - 1994)",
  "Star Wars: Tales of the Jedi - Dark Lords of the Sith (1994 - 1995)",
  "Star Wars: Tales of the Jedi - Redemption (1998)",
  "Star Wars: Tales of the Jedi - The Fall of the Sith Empire (1997)",
  "Star Wars: Tales of the Jedi - The Freedon Nadd Uprising (1994)",
  "Star Wars: Tales of the Jedi - The Golden Age of the Sith (1996 - 1997)",
  "Star Wars: Tales of the Jedi - The Sith War (1995 - 1996)",
  "Star Wars: Target Vader (2019 - Present)",
  "Star Wars: The Bounty Hunters (1999)",
  "Star Wars: The Bounty Hunters - Aurra Sing (1999)",
  "Star Wars: The Bounty Hunters - Kenix Kil (1999)",
  "STAR WARS: THE EMPIRE STRIKES BACK MANGA VOL. 1 DIGEST (1999)",
  "Star Wars: The Force Awakens Adaptation (2016)",
  "Star Wars: The Force Unleashed (2008)",
  "STAR WARS: THE JABBA TAPE 1 (1998)",
  "Star Wars: The Last Command (1997 - 1998)",
  "Star Wars: The Last Jedi - DJ - Most Wanted (2018)",
  "Star Wars: The Last Jedi - The Storms of Crait (2017)",
  "Star Wars: The Last Jedi Adaptation (2018)",
  "Star Wars: The Old Republic (2010)",
  "Star Wars: The Old Republic - The Lost Suns (2011)",
  "Star Wars: The Original Marvel Years 50 Facsimile Edition (2019)",
  "Star Wars: The Rise of Kylo Ren (2019 - Present)",
  "Star Wars: The Screaming Citadel (2017)",
  "Star Wars: Thrawn (2018)",
  "Star Wars: Tie Fighter (2019)",
  "Star Wars: Underworld - The Yavin Vassilika (2000 - 2001)",
  "Star Wars: Union (1999 - 2000)",
  "Star Wars: Vader - Dark Visions (2019)",
  "Star Wars: Vader Down (2015)",
  "Star Wars: Vader's Quest (1999)",
  "Star Wars: X-Wing Rogue Leader (2005)",
  "Star Wars: X-Wing Rogue Squadron (1995 - 1998)",
  "STAR WARS: X-WING ROGUE SQUADRON SPECIAL 1 (1995)",
  "Star Wars: Zam Wesell (2002)",
  "Star-Lord (2015 - 2016)",
  "Star-Lord (2016 - 2017)",
  "Star-Lord and Kitty Pryde (2015)",
  "Star-Lord Annual (2017)",
  "Star-Lord: Annihilation - Conquest (2014)",
  "Star-Lord: Tears for Heaven (2014)",
  "STAR-LORD: THE HOLLOW CROWN (2013)",
  "Star-Lord: Worlds on the Brink (2013)",
  "Starbrand & Nightmask (2015 - 2016)",
  "Starjammers (1995 - 1996)",
  "Starjammers (2004)",
  "Starlord (1996 - 1997)",
  "Starr the Slayer (2009)",
  "Startling Stories: Banner (2001)",
  "Startling Stories: Fantastic Four - Unstable Molecules (2003)",
  "Startling Stories: The Incorrigible Hulk (2004)",
  "Startling Stories: The Megalomaniacal Spider-Man (2003)",
  "Startling Stories: The Thing - Night Falls on Yancy Street (2003)",
  "STARTLING STORIES: THE THING 1 (2003)",
  "Stephen King's 'N.' (2010)",
  "Steve Rogers: Super Soldier Annual (2010)",
  "Steve Rogers: Super-Soldier (2010)",
  "Stoker's Dracula (2004 - 2005)",
  "Storm (2006)",
  "Storm (2014 - 2015)",
  "Stormbreaker: The Saga of Beta Ray Bill (2005)",
  "Strange (2004 - 2005)",
  "Strange (2009 - 2010)",
  "Strange Tales (1951 - 1968)",
  "Strange Tales (1987 - 1988)",
  "Strange Tales (1994)",
  "Strange Tales (1998)",
  "Strange Tales (2009 - 2010)",
  "Strange Tales Annual (1962 - 1963)",
  "Strange Tales II (2010)",
  "Strange Tales of the Unusual (1956 - 1957)",
  "Strange Tales: Dark Corners (1998)",
  "Strange Worlds (1958 - 1959)",
  "Strikeforce (2019 - 2020)",
  "Strikeforce Morituri: Electric Undertow (1989 - 1990)",
  "Strikeforce: Director's Cut Edition (2019)",
  "Strikeforce: Morituri (1986 - 1989)",
  "Strikeforce: Morituri - We Who Are About to Die (2011 - 2012)",
  "Stryfe's Strike File (1993)",
  "Sub-Mariner (1968 - 1974)",
  "Sub-Mariner (2007 - 2008)",
  "Sub-Mariner Comics (1941 - 1955)",
  "Sub-Mariner Comics 70th Anniversary Special (2009)",
  "Sub-Mariner: The Depths (2008 - 2009)",
  "Super Hero Squad (2010)",
  "Super Hero Squad MMO Magazine (2011)",
  "Super Hero Squad Online Game: Hero Up! (2011)",
  "Super Hero Squad Spectacular (2011)",
  "Super Hero Squad: Hero Up! One-Shot (2009)",
  "Super-Villain Classics (1983)",
  "Super-Villain Team-Up (1975 - 1980)",
  "Super-Villain Team-Up/Modok's 11 (2007 - 2008)",
  "Supercrooks (2012)",
  "Superior (2010 - 2012)",
  "Superior Carnage (2012 - 2013)",
  "Superior Carnage Annual (2014)",
  "Superior Iron Man (2014 - 2015)",
  "Superior Octopus (2018)",
  "Superior Spider-Man (2013 - 2014)",
  "Superior Spider-Man (2018 - Present)",
  "Superior Spider-Man Annual (2013 - 2014)",
  "Superior Spider-Man Team-Up (2013 - 2014)",
  "Superior Spider-Man Team-Up Special (2013)",
  "Supernatural Thrillers (1972 - 1975)",
  "Supreme Power (2003 - 2005)",
  "Supreme Power (2011)",
  "Supreme Power MGC (2011)",
  "Supreme Power: Hyperion (2005 - 2006)",
  "Supreme Power: Nighthawk (2005 - 2006)",
  "Survive! (2014)",
  "Sword Master (2019 - Present)",
  "Symbiote Spider-Man (2019 - Present)",
  "Symbiote Spider-Man: Alien Reality (2019 - Present)",
  "Tails of the Pet Avengers (2010)",
  "TAILS OF THE PET AVENGERS 1 (2009)",
  "Tails of the Pet Avengers: The Dogs of Summer (2010)",
  "Takio (2012 - 2013)",
  "Tales from the Age of Apocalypse: Sinster Bloodlines (1997)",
  "Tales from the Age of Apocalyspse: By the Light (1996)",
  "Tales of Suspense (1959 - 1968)",
  "Tales of Suspense (1995)",
  "Tales of Suspense (2017 - 2018)",
  "Tales of Suspense: Captain America/Iron Man 1 Commemorative Edition (2004)",
  "Tales of the Thing (2005)",
  "Tales to Astonish (1959 - 1968)",
  "Tales to Astonish (1994)",
  "TARGET IRON MAN CUSTOM COMIC (2010)",
  "Tarot (2020 - 2021)",
  "Taskmaster (2002)",
  "Taskmaster (2010)",
  "Teen-Age Romance (1960 - Present)",
  "Terror, Inc. (2007 - 2008)",
  "Terror, Inc. - Apocalypse Soon (2009)",
  "Thanos (2003 - 2004)",
  "Thanos (2016 - 2018)",
  "Thanos (2019)",
  "Thanos Annual (2014)",
  "Thanos Annual (2018)",
  "Thanos Legacy (2018)",
  "Thanos Quest (1990)",
  "Thanos Quest (2012)",
  "Thanos Rising (2013)",
  "Thanos Sourcebook (2010)",
  "Thanos Vol. 3: Marvel Universe - The End (2003)",
  "Thanos Vs. Hulk (2014 - 2015)",
  "Thanos: A God Up There Listening Infinite Comic (2014)",
  "Thanos: The Infinity Finale (2016)",
  "Thanos: The Infinity Relativity (2015)",
  "Thanos: The Infinity Revelation (2014)",
  "Thanos: The Infinity Siblings (2018)",
  "The 'Nam (1986 - 1993)",
  "The Accused (2016)",
  "The Age of the Sentry (2008 - 2009)",
  "The Amazing Spider-Man (1963 - 1998)",
  "The Amazing Spider-Man (2014 - 2015)",
  "The Amazing Spider-Man (2015 - 2018)",
  "The Amazing Spider-Man (2018 - Present)",
  "The Amazing Spider-Man: The Movie Adaptation (2014)",
  "The Astonishing Ant-Man (2015 - 2016)",
  "The Black Knight (2009)",
  "The Brotherhood (2001 - 2002)",
  "The Call (2003)",
  "The Call of Duty: The Brotherhood (2002)",
  "The Call of Duty: The Precinct (2002)",
  "The Call of Duty: The Wagon (2002)",
  "The Cat (1972 - 1973)",
  "THE CAVALRY: S.H.I.E.L.D. 50TH ANNIVERSARY (2015)",
  "The Clone Conspiracy (2016 - 2017)",
  "The Clone Conspiracy: Omega (2017)",
  "The Contest II (1999)",
  "The Death of Captain Marvel (1982)",
  "The Destroyer (2009)",
  "The Emerald City of Oz (2013)",
  "The Eternal (2003)",
  "The Fallen (2016)",
  "The Fresh-Men (2019)",
  "The Ghost Rider (1967)",
  "The Gunhawks (2019)",
  "The Infinity Entity (2016)",
  "The Invincible Iron Man (2004 - 2007)",
  "The Last Defenders (2008)",
  "The Last Fantastic Four Story (2007)",
  "The Life of Captain Marvel (2018)",
  "The Loners (2007 - 2008)",
  "The Many Loves of the Amazing Spider-Man (2010)",
  "The Marvelous Land of Oz (2009 - 2010)",
  "The Marvels Project (2009 - 2010)",
  "The Mighty Avengers (2007 - 2010)",
  "The Mighty Captain Marvel (2017 - 2018)",
  "The Mighty Thor (2011 - 2012)",
  "The Mighty Thor Annual (2012)",
  "The Mighty Thor Saga (2011)",
  "The Mutant Misadventures of Cloak and Dagger (1988 - 1990)",
  "The Mystic Hands of Doctor Strange (2010)",
  "The New Mutants Marvel Graphic Novel (1982)",
  "The Order (2002)",
  "The Order (2007 - 2008)",
  "The Pitt Marvel Graphic Novel (1987)",
  "The Pulse: House of M Special (2005)",
  "The Punisher (1986)",
  "The Punisher (1987 - 1995)",
  "The Punisher (1998 - 1999)",
  "The Punisher (2011 - 2012)",
  "The Punisher (2014 - 2015)",
  "The Punisher (2016 - 2018)",
  "The Punisher (2018 - Present)",
  "The Punisher 2099 (2019)",
  "The Punisher Annual (1988 - 1994)",
  "The Punisher Annual (2016)",
  "The Punisher War Zone (1992 - 1995)",
  "The Punisher: Assassins' Guild Graphic Novel (1988)",
  "The Punisher: Die Hard In the Big Easy (1992)",
  "The Punisher: G-Force (1992)",
  "THE PUNISHER: NO ESCAPE 1 (1990 - Present)",
  "THE PUNISHER: THE PRIZE 1 (1990 - Present)",
  "The Punisher: Year One (1994 - Present)",
  "The Rawhide Kid (2010)",
  "The Savage Axe of Ares (2010)",
  "The Savage Sword of Conan (1974 - 1995)",
  "The Sensational She-Hulk: Ceremony (1989)",
  "The Sentry (2000 - 2001)",
  "The Sentry (2005 - 2006)",
  "THE SENTRY/FANTASTIC FOUR 1 (2001)",
  "THE SENTRY/HULK 1 (2001)",
  "THE SENTRY/SPIDER-MAN 1 (2001)",
  "THE SENTRY/X-MEN 1 (2001)",
  "The Spectacular Scarlet Spider (1995)",
  "The Stand: American Nightmares (2009)",
  "The Stand: Captain Trips (2008 - 2009)",
  "The Stand: Hardcases (2010)",
  "The Stand: No Man's Land (2010 - 2011)",
  "The Stand: Soul Survivors (2009 - 2010)",
  "The Stand: The Night Has Come (2011)",
  "The Star Wars (2013 - 2014)",
  "The Superior Foes of Spider-Man (2013 - 2015)",
  "The Thanos Imperative (2010)",
  "The Thanos Imperative: Devastation (2010)",
  "The Thanos Imperative: Ignition (2010)",
  "The Thing (2005 - 2006)",
  "The Torch (2009 - 2010)",
  "The Totally Awesome Hulk (2015 - 2017)",
  "The Trojan War (2009)",
  "The Twelve (2007 - 2008)",
  "The Twelve: Marvel Must Have (2011 - 2012)",
  "The Twelve: Spearhead (2010)",
  "THE ULTIMATES MGC 1 (2011)",
  "The Unbeatable Squirrel Girl (2015 - Present)",
  "The Unbeatable Squirrel Girl (2015)",
  "The Unbelievable Gwenpool (2016 - 2018)",
  "The United States of Murder Inc. (2014 - 2015)",
  "The United States of Murder Inc. (2014 - 2015)",
  "The Unstoppable Wasp (2017)",
  "The Unstoppable Wasp (2018 - Present)",
  "The Unworthy Thor (2016 - 2017)",
  "The Web of Black Widow (2019)",
  "The Wonderful Wizard of Oz (2008 - 2009)",
  "The X-Men Omnibus Vol. 2 (2010 - 2011)",
  "The Zombie: Simon Garth (2007 - 2008)",
  "Thing & She-Hulk: Long Night (2002)",
  "Thing (1983 - 1986)",
  "Thing: Freakshow (2002)",
  "Thor & Hercules: Encyclopaedia Mythologica (2009)",
  "Thor & the Mighty Avengers (2013)",
  "Thor (1966 - 1996)",
  "Thor (1998 - 2004)",
  "Thor (2007 - 2011)",
  "Thor (2014 - 2015)",
  "Thor (2018 - Present)",
  "Thor (2020)",
  "Thor and the Warriors Four (2010)",
  "Thor Annual (1966 - 2001)",
  "Thor Annual (1999)",
  "Thor Annual (2000)",
  "Thor Annual (2001)",
  "Thor Annual (2015)",
  "Thor Corps (1993)",
  "Thor Director's Cut Edition (2020)",
  "Thor Giant-Size Finale (2009)",
  "Thor God-Size Special (2008)",
  "Thor Goes Hollywood (2011)",
  "Thor MGC (2010)",
  "Thor MGC (2011)",
  "Thor the Mighty Avenger (2010 - 2011)",
  "Thor the Mighty Avenger - Double Rainbow (2010)",
  "Thor: Ages of Thunder (2008)",
  "Thor: Asgard's Avenger (2010 - 2011)",
  "Thor: Blood Oath (2005)",
  "Thor: Crown of Fools (2013)",
  "Thor: Defining Moments Giant-Size (2009)",
  "Thor: First Thunder (2010 - 2011)",
  "Thor: For Asgard (2010 - 2011)",
  "Thor: God of Thunder (2012 - 2014)",
  "Thor: Godstorm (2001 - 2002)",
  "Thor: Heaven & Earth (2011)",
  "Thor: Man of War (2008)",
  "Thor: Reign of Blood (2008)",
  "Thor: Season One (2013)",
  "Thor: Son of Asgard (2004 - 2005)",
  "Thor: Tales of Asgard by Stan Lee & Jack Kirby (2009)",
  "Thor: The Deviants Saga (2011 - 2012)",
  "Thor: The Legend (1996)",
  "Thor: The Rage of Thor (2010)",
  "Thor: The Trial of Thor (2009)",
  "Thor: The Worthy (2019)",
  "Thor: Truth of History (2008)",
  "Thor: Vikings (2003)",
  "Thor: Where Walk The Frost Giants (2017)",
  "Thor: Whosoever Wields This Hammer (2010)",
  "Thor: Wolves of the North (2010)",
  "Thors (2015)",
  "Thunderbolts (1997 - 2003)",
  "Thunderbolts (2006 - 2012)",
  "Thunderbolts (2012 - 2014)",
  "Thunderbolts (2016 - 2017)",
  "Thunderbolts Annual (1997)",
  "Thunderbolts Annual (2000)",
  "Thunderbolts Annual (2013)",
  "Thunderbolts Presents: Zemo - Born Better (2007)",
  "Thunderbolts: Breaking Point (2007)",
  "Thunderbolts: Desperate Measures (2007)",
  "Thunderbolts: From the Marvel Vault (2011)",
  "Thunderbolts: International Incident (2008)",
  "Thunderbolts: Reason in Madness (2008)",
  "Thunderstrike (1993 - 1995)",
  "Thunderstrike (2010 - 2011)",
  "Tigra (2002)",
  "Timely Comics: Carnage (2016)",
  "Timely Comics: Daredevil (2016)",
  "Timely Comics: Doctor Strange (2016)",
  "Timely Comics: Drax (2016)",
  "Timely Comics: Invincible Iron Man (2016)",
  "Timely Comics: Moon Girl and Devil Dinosaur (2016)",
  "Timely Comics: New Avengers (2016)",
  "Timely Comics: Scarlet Witch (2016)",
  "Timely Comics: Squadron Supreme (2016)",
  "Timely Comics: The Totally Awesome Hulk (2016)",
  "Timely Comics: Ultimates (2016)",
  "Timely Comics: Uncanny Inhumans (2016)",
  "Timely Comics: Venom: Space Knight (2016)",
  "Timely Comics: Web Warriors (2016)",
  "Timestorm 2009/2099 (2009)",
  "Timestorm 2009/2099: Spider-Man (2009)",
  "Timestorm 2009/2099: X-Men (2009)",
  "Tomb of Dracula (1972 - 1979)",
  "Tomb of Dracula (1979 - 1980)",
  "Tomb of Dracula (2004 - 2005)",
  "Tomb Of Dracula Facsimile Edition (2019)",
  "Tomb of Dracula Gene Colan Poster (2008)",
  "Tomb of Dracula Presents: Throne of Blood (2011)",
  "Tomb of Terror (2010)",
  "Tony Stark: Iron Man (2018 - Present)",
  "Top Dog (1985 - 1987)",
  "Tower of Shadows (1969 - 1971)",
  "Toxin (2005)",
  "Tron: Betrayal (2010)",
  "Tron: Original Movie Adaptation (2010)",
  "Trouble (2003)",
  "True Believers (2008 - 2009)",
  "True Believers: Absolute Carnage - Carnage (2019)",
  "True Believers: Absolute Carnage - Carnage, U.S.A. (2019)",
  "True Believers: Absolute Carnage - Mania (2019)",
  "True Believers: Absolute Carnage - Maximum Carnage (2019)",
  "True Believers: Absolute Carnage - Mind Bomb (2019)",
  "True Believers: Absolute Carnage - Planet Of The Symbiotes (2019)",
  "True Believers: Absolute Carnage - Savage Rebirth (2019)",
  "True Believers: Absolute Carnage - Separation Anxiety (2019)",
  "True Believers: Absolute Carnage - She-Venom (2019)",
  "True Believers: Absolute Carnage - Venom Vs. Carnage (2019)",
  "True Believers: Age of Apocalypse (2015)",
  "True Believers: Age of Ultron (2015 - Present)",
  "True Believers: All-New, All-Different Avengers - Cyclone (2016)",
  "True Believers: Amazing Fantasy Starring Spider-Man (2017)",
  "True Believers: Amazing Spider-Man (2019)",
  "True Believers: Amazing Spider-Man - The Dark Kingdom (2016)",
  "True Believers: Annihilation - Annihilus (2019)",
  "True Believers: Annihilation - Man-Wolf In Space (2019)",
  "True Believers: Annihilation - Mantis (2019)",
  "True Believers: Annihilation - Moondragon (2019)",
  "True Believers: Annihilation - Nova (2019)",
  "True Believers: Annihilation - Odinpower (2019)",
  "True Believers: Annihilation - Omega The Unknown (2019)",
  "True Believers: Annihilation - Quasar (2019)",
  "True Believers: Annihilation - Super-Adaptoid (2019)",
  "True Believers: Annihilation - Super-Skrull (2019)",
  "True Believers: Ant-Man - The Incredible Shrinking Doom (2018)",
  "True Believers: Ant-Man and Hawkeye - Avengers Assemble (2018)",
  "True Believers: Ant-Man and the Wasp - 'Til Death Do Us Part (2018)",
  "True Believers: Ant-Man and the Wasp - On the Trail Of Spider-Man (2018)",
  "True Believers: Ant-Man and the Wasp - The Birth of Giant-Man (2018)",
  "True Believers: Ant-Man Presents Iron Man - The Ghost and the Machine (2018)",
  "True Believers: Armor Wars (2015)",
  "True Believers: Avengers - Endgame! (2019)",
  "True Believers: Avengers - Nebula (2019)",
  "True Believers: Avengers - Rocket Raccoon (2019)",
  "True Believers: Avengers - Ronin (2019)",
  "True Believers: Avengers - Stormbreaker (2019)",
  "True Believers: Avengers - Thanos & Gamora (2019)",
  "True Believers: Avengers - Thanos Vs. The Marvel Universe (2019)",
  "True Believers: Avengers - Thanos: The Final Battle! (2019)",
  "True Believers: Avengers - The Gatherers Saga (2019)",
  "True Believers: Avengers Forever (2019)",
  "True Believers: Avengers Vs. Thanos (2018)",
  "True Believers: Captain Mar-Vell (2019)",
  "True Believers: Captain Marvel (2015)",
  "True Believers: Captain Marvel - Avenger (2019)",
  "True Believers: Captain Marvel - Betrayed! (2019)",
  "True Believers: Captain Marvel - Binary (2019)",
  "True Believers: Captain Marvel - Earth’s Mightiest Hero (2019)",
  "True Believers: Captain Marvel - Ms. Marvel (2019)",
  "True Believers: Captain Marvel - Spider-Man and Ms. Marvel (2019)",
  "True Believers: Captain Marvel - The Kree/Skrull War (2019)",
  "True Believers: Captain Marvel - The New Ms. Marvel (2019)",
  "True Believers: Captain Marvel Vs. Ronan (2019)",
  "True Believers: Carol Danvers (2018)",
  "True Believers: Chewbacca (2016)",
  "True Believers: Civil War (2015)",
  "True Believers: Conan - Curse of the Golden Skull! (2019)",
  "True Believers: Conan - Queen of the Black Coast! (2019)",
  "True Believers: Conan - Resurrection (2019)",
  "True Believers: Conan - Swords in the Night (2019)",
  "True Believers: Conan - the Devil-God of Bal-Sagoth (2019)",
  "True Believers: Conan - The Secret of Skull River (2019)",
  "True Believers: Conan - The Tower of the Elephant! (2019)",
  "True Believers: Conan the Barbarian (2019)",
  "True Believers: Conan: Serpent War - The Valley Of The Worm (2019)",
  "True Believers: Darth Vader (2016)",
  "True Believers: Deadpool (2016)",
  "True Believers: Deadpool - Deadpool Vs. Sabretooth (2016)",
  "True Believers: Deadpool Origins (2016)",
  "TRUE BELIEVERS: DEADPOOL THE MUSICAL 1 (2016)",
  "TRUE BELIEVERS: DEADPOOL VARIANTS 1 (2016)",
  "True Believers: Death of Phoenix (2017)",
  "TRUE BELIEVERS: DETECTIVE DEADPOOL 1 (2016)",
  "True Believers: Doctor Strange - The Last Days of Magic (2016)",
  "True Believers: Droids (2016)",
  "True Believers: Enter - The Phoenix! (2017)",
  "TRUE BELIEVERS: EVIL DEADPOOL 1 (2016)",
  "True Believers: Exiles (2018)",
  "True Believers: Fantastic Four - Blastaar (2018)",
  "True Believers: Fantastic Four - Dragon Man (2018)",
  "True Believers: Fantastic Four - Frightful Four (2018)",
  "True Believers: Fantastic Four - Galactus Hungers (2018)",
  "True Believers: Fantastic Four - Hulk Vs. Thing (2018)",
  "True Believers: Fantastic Four - Klaw (2018)",
  "True Believers: Fantastic Four - Mad Thinker & Awesome Android (2018)",
  "True Believers: Fantastic Four - Marvel Two-In-One (2018)",
  "True Believers: Fantastic Four - Molecule Man (2018)",
  "True Believers: Fantastic Four - Puppet Master (2018)",
  "True Believers: Fantastic Four - Ronan & The Kree (2018)",
  "True Believers: Fantastic Four - Skrulls (2018)",
  "True Believers: Fantastic Four - Super-Skrull (2018)",
  "True Believers: Fantastic Four - The Birth of Valeria (2018)",
  "True Believers: Fantastic Four - The Coming of Galactus (2018)",
  "True Believers: Fantastic Four - The Coming of H.E.R.B.I.E. (2018)",
  "True Believers: Fantastic Four - The Wedding of Reed & Sue (2018)",
  "True Believers: Fantastic Four - What If? (2018)",
  "True Believers: Fantastic Four by John Byrne (2018)",
  "True Believers: Fantastic Four by Walter Simonson (2018)",
  "True Believers: Fantastic Four Vs. Doctor Doom (2018)",
  "True Believers: Fantastic Four Vs. The New Fantastic Four (2018)",
  "True Believers: House of M (2015)",
  "True Believers: Hulk - Devil Hulk (2019)",
  "True Believers: Hulk - Gray Hulk Returns (2019)",
  "True Believers: Hulk - Head Of Banner (2019)",
  "True Believers: Hulk - Intelligent Hulk (2019)",
  "True Believers: Hulk - Joe Fixit (2019)",
  "True Believers: Hulk - Mindless Hulk (2019)",
  "True Believers: Hulk - Professor Hulk (2019)",
  "True Believers: Hulk - Red Hulk (2019)",
  "True Believers: Hulk - The Other Hulks (2019)",
  "True Believers: Hulk Returns (2019)",
  "True Believers: Infinity Gauntlet (2018)",
  "TRUE BELIEVERS: INFINITY GAUNTLET 1 (2015)",
  "True Believers: Infinity Incoming! (2018)",
  "True Believers: Infinity War (2018)",
  "True Believers: Kanan (2016)",
  "True Believers: King Conan (2019)",
  "True Believers: Kirby 100th - Ant-Man and The Wasp (2017)",
  "True Believers: Kirby 100th - Avengers: Captain America Lives Again! (2017)",
  "True Believers: Kirby 100th - Black Panther (2017)",
  "True Believers: Kirby 100th - Captain America (2017)",
  "True Believers: Kirby 100th - Devil Dinosaur (2017)",
  "True Believers: Kirby 100th - Eternals (2017)",
  "True Believers: Kirby 100th - Groot (2017)",
  "True Believers: Kirby 100th - Inhumans (2017)",
  "True Believers: Kirby 100th - Introducing...The Mighty Thor! (2017)",
  "True Believers: Kirby 100th - Iron Man (2017)",
  "True Believers: Kirby 100th - Nick Fury (2017)",
  "True Believers: Kirby 100th - Thor Vs. Hulk (2017)",
  "True Believers: Kitty Pryde and Wolverine (2018)",
  "True Believers: Lando (2016)",
  "True Believers: Marvel Knights 20th Anniversary - Daredevil and the Defenders (2018)",
  "True Believers: Marvel Knights 20th Anniversary - Daredevil by Lee & Everett (2018)",
  "True Believers: Marvel Knights 20th Anniversary - Daredevil by Smith, Quesada & Palmiotti (2018)",
  "True Believers: Marvel Knights 20th Anniversary - Jessica Jones: Alias by Bendis & Gaydos (2018)",
  "True Believers: Marvel Knights 20th Anniversary - Luke Cage, Hero for Hire (2018)",
  "True Believers: Marvel Tails Starring Peter Porker, the Spectacular Spider-Ham (2019)",
  "True Believers: Marvel Zombies (2015)",
  "True Believers: Mighty Thor - The Strongest Viking There Is (2016)",
  "True Believers: Miles Morales (2015)",
  "True Believers: Ms. Marvel (2015)",
  "True Believers: Old Man Logan (2015)",
  "True Believers: Phoenix - Bizarre Adventures (2017)",
  "True Believers: Phoenix - What If? (2017)",
  "True Believers: Phoenix Classic (2017)",
  "True Believers: Phoenix Origins (2017)",
  "True Believers: Phoenix Presents Cyclops & Marvel Girl (2017)",
  "True Believers: Phoenix Presents Jean Grey Vs. Sabretooth (2017)",
  "True Believers: Phoenix Presents the Wedding of Scott Summers & Jean Grey (2017)",
  "True Believers: Phoenix Returns (2017)",
  "True Believers: Planet Hulk (2015)",
  "True Believers: Rebirth of Thanos (2018)",
  "True Believers: Scott Lang, the Astonishing Ant-Man (2018)",
  "True Believers: Shattered Empire (2016)",
  "True Believers: Silk (2015)",
  "True Believers: Spider-Man (2017)",
  "True Believers: Spider-Man - Brand New Day (2017)",
  "TRUE BELIEVERS: SPIDER-MAN - MORBIUS 1 (2019)",
  "True Believers: Spider-Man - Spider-Armor (2019)",
  "True Believers: Spider-Man - Spidey Fights in London! (2019)",
  "True Believers: Spider-Man - The New Spider-Man! (2019)",
  "True Believers: Spider-Man - The Wedding Of Aunt May & Doc Ock (2019)",
  "TRUE BELIEVERS: SPIDER-MAN VS. HULK 1 (2019)",
  "True Believers: Spider-Man Vs. Mysterio (2019)",
  "True Believers: Star Wars (2016)",
  "True Believers: Star Wars - According To The Droids (2019)",
  "True Believers: Star Wars - Darth Maul (2019)",
  "True Believers: Star Wars - Darth Vader (2019)",
  "True Believers: Star Wars - Death Probe (2019)",
  "True Believers: Star Wars - Ewoks (2019)",
  "True Believers: Star Wars - Hutt Run (2019)",
  "True Believers: Star Wars - Rebel Jail (2019)",
  "True Believers: Star Wars - Skywalker Strikes (2019)",
  "True Believers: Star Wars - The Ashes of Jedha (2019)",
  "True Believers: Star Wars - The Hunter (2019)",
  "True Believers: Star Wars - The Original Marvel Years (2019)",
  "True Believers: Star Wars - Thrawn (2019)",
  "True Believers: Star Wars - Vader vs. Leia (2019)",
  "True Believers: Star Wars Classic (2016)",
  "True Believers: Star Wars Covers (2016)",
  "True Believers: Thanos Rising (2018)",
  "True Believers: Thanos the First (2018)",
  "True Believers: The Criminally Insane - Absorbing Man (2020)",
  "True Believers: The Criminally Insane - Bullseye (2020)",
  "True Believers: The Criminally Insane - Bushman (2020)",
  "True Believers: The Criminally Insane - Dracula (2020)",
  "True Believers: The Criminally Insane - Green Goblin (2020)",
  "True Believers: The Criminally Insane - Gypsy Moth (2020)",
  "True Believers: The Criminally Insane - Klaw (2020)",
  "True Believers: The Criminally Insane - Mandarin (2020)",
  "True Believers: The Criminally Insane - Masters Of Evil (2020)",
  "True Believers: The Criminally Insane - Purple Man (2020)",
  "TRUE BELIEVERS: THE GROOVY DEADPOOL 1 (2016)",
  "TRUE BELIEVERS: THE MEATY DEADPOOL 1 (2016)",
  "TRUE BELIEVERS: THE SINISTER SECRET OF SPIDER-MAN'S NEW COSTUME! 1 (2019)",
  "True Believers: The Unbeatable Squirrel Girl (2015)",
  "TRUE BELIEVERS: THE WEDDING OF DEADPOOL 1 (2016)",
  "TRUE BELIEVERS: UNCANNY DEADPOOL 1 (2016)",
  "True Believers: Vader Down (2016)",
  "True Believers: Venom - Agent Venom (2018)",
  "True Believers: Venom - Carnage (2018)",
  "True Believers: Venom - Dark Origin (2018)",
  "True Believers: Venom - Flashpoint (2018)",
  "True Believers: Venom - Homecoming (2018)",
  "True Believers: Venom - Lethal Protector (2018)",
  "True Believers: Venom - Shiver (2018)",
  "True Believers: Venom - Symbiosis (2018)",
  "True Believers: Venom - Toxin (2018)",
  "True Believers: Venom vs. Spider-Man (2018)",
  "True Believers: What If Conan the Barbarian Walked the Earth Today? (2019)",
  "True Believers: What If Dr. Doom Had Become a Hero? (2018)",
  "TRUE BELIEVERS: WHAT IF JANE FOSTER HAD FOUND THE HAMMER OF THOR? 1 (2018)",
  "True Believers: What If Kraven the Hunter Had Killed Spider-Man? (2018)",
  "True Believers: What If Legion Had Killed Magneto? (2018)",
  "True Believers: What If Spider-Man Had Rescued Gwen Stacy? (2018)",
  "True Believers: What If the Alien Costume had Possessed Spider-Man? (2018)",
  "TRUE BELIEVERS: WHAT IF THE AVENGERS HAD FOUGHT EVIL DURING THE 1950S? 1 (2018)",
  "True Believers: What If The Fantastic Four Had Different Super-Powers? (2018)",
  "True Believers: What If the Fantastic Four had not Gained their Super-Powers? (2018)",
  "True Believers: What If the Silver Surfer Possessed the Infinity Gauntlet? (2018)",
  "True Believers: Wolverine - Blood Hungry (2018)",
  "True Believers: Wolverine - Evolution (2018)",
  "True Believers: Wolverine - Fatal Attractions (2018)",
  "True Believers: Wolverine - Sword Quest (2018)",
  "True Believers: Wolverine - The Brothers (2018)",
  "True Believers: Wolverine - The Dying Game (2018)",
  "True Believers: Wolverine Vs. Sabretooth (2018)",
  "True Believers: Wolverine vs. Venom (2018)",
  "True Believers: X-Men - Apocalypse (2019)",
  "True Believers: X-Men - Betsy Braddock (2019)",
  "True Believers: X-Men - Bishop (2019)",
  "True Believers: X-Men - Jubilee (2019)",
  "True Believers: X-Men - Karima Shapandar, Omega Sentinel (2019)",
  "True Believers: X-Men - Kitty Pryde & Emma Frost (2019)",
  "True Believers: X-Men - Kwannon (2019)",
  "True Believers: X-Men - Moira MacTaggert (2019)",
  "True Believers: X-Men - Pyro (2019)",
  "True Believers: X-Men - Rictor (2019)",
  "Truth: Red, White & Black (2003)",
  "Two-Gun Kid (1948 - 1977)",
  "Typhoid Fever: Iron Fist (2018)",
  "Typhoid Fever: Spider-Man (2018)",
  "Typhoid Fever: X-Men (2018)",
  "U.S. War Machine (2001 - 2002)",
  "U.S.A. Comics (1941 - 1945)",
  "U.S.Agent (1993)",
  "U.S.Avengers (2017)",
  "Ultimate Adventures (2002 - 2003)",
  "Ultimate Armor Wars (2009)",
  "Ultimate Avengers (2009 - 2010)",
  "Ultimate Avengers 3 (2010 - 2011)",
  "Ultimate Avengers Vs. New Ultimates (2011)",
  "Ultimate Captain America Annual (2008)",
  "Ultimate Civil War: Spider-Ham (2007)",
  "Ultimate Comics Avengers 2 (2010)",
  "Ultimate Comics Captain America (2010 - 2011)",
  "Ultimate Comics Doom (2010 - 2011)",
  "Ultimate Comics Enemy (2010)",
  "Ultimate Comics Hawkeye (2011)",
  "Ultimate Comics Iron Man (2012 - 2013)",
  "Ultimate Comics Mystery (2010)",
  "Ultimate Comics New Ultimates (2010 - 2011)",
  "Ultimate Comics Spider-Man (2009 - 2012)",
  "Ultimate Comics Spider-Man (2011 - 2013)",
  "Ultimate Comics Spider-Man Must Have (2011)",
  "Ultimate Comics Thor (2010 - 2011)",
  "Ultimate Comics Ultimates (2011 - 2013)",
  "Ultimate Comics Ultimates Must Have (2011)",
  "Ultimate Comics Wolverine (2013)",
  "Ultimate Comics X (2010 - 2011)",
  "Ultimate Comics X-Men (2010 - 2013)",
  "Ultimate Comics X-Men Must Have (2011)",
  "Ultimate Daredevil and Elektra (2002 - 2003)",
  "Ultimate Elektra (2004)",
  "Ultimate End (2015)",
  "Ultimate Extinction (2006)",
  "Ultimate Fallout (2011)",
  "Ultimate Fantastic Four (2003 - 2009)",
  "Ultimate Fantastic Four Annual (2005 - 2006)",
  "Ultimate Fantastic Four MGC (2000)",
  "Ultimate Fantastic Four/Ultimate X-Men Annual (2008)",
  "ULTIMATE FANTASTIC FOUR/X-MEN (2006)",
  "Ultimate Ff (2014)",
  "Ultimate Hulk Annual (2008)",
  "Ultimate Human (2008)",
  "Ultimate Iron Man (2005)",
  "Ultimate Iron Man II (2007 - 2008)",
  "Ultimate Marvel Flip Magazine (2005 - 2007)",
  "Ultimate Marvel Team-Up (2001 - 2002)",
  "Ultimate Nightmare (2004 - 2005)",
  "Ultimate Origins (2007 - 2008)",
  "Ultimate Power (2006 - 2008)",
  "Ultimate Secret (2005)",
  "Ultimate Secrets (2008)",
  "Ultimate Six (2003 - 2004)",
  "Ultimate Spider-Man (2000 - 2009)",
  "Ultimate Spider-Man (Spanish Language Edition) (2000)",
  "Ultimate Spider-Man 200 (2011 - 2012)",
  "Ultimate Spider-Man Annual (2005 - 2008)",
  "Ultimate Spider-Man Infinite Comic (2016)",
  "Ultimate Spider-Man Infinite Digital Comic (2015)",
  "Ultimate Spider-Man MGC (2011)",
  "Ultimate Spider-Man Premiere Comic (2012)",
  "Ultimate Spider-Man Spider-Verse (2015 - 2016)",
  "Ultimate Spider-Man: Free Comic Book Day Edition (2002)",
  "Ultimate Spider-Man: Web Warriors (2014 - 2015)",
  "Ultimate Vision (2006 - 2007)",
  "Ultimate War (2003)",
  "Ultimate Wolverine Vs. Hulk (2005 - 2009)",
  "Ultimate X-Men (2000 - 2009)",
  "Ultimate X-Men (Spanish Language Edition) (2000)",
  "ULTIMATE X-MEN 1/2 (2002)",
  "Ultimate X-Men Annual (2005 - 2006)",
  "Ultimate X-Men MGC (2011)",
  "Ultimate X-Men Vol. 7: Blockbuster (2006)",
  "Ultimate X-Men/Fantastic Four (2005)",
  "Ultimate X-Men/Ultimate Fantastic Four Annual (2008)",
  "Ultimates (2002 - 2003)",
  "Ultimates (2015 - 2016)",
  "Ultimates 2 (2004 - 2006)",
  "Ultimates 2 (2016 - 2017)",
  "Ultimates 3 (2007 - 2008)",
  "Ultimates Annual (2005 - 2006)",
  "Ultimates Saga (2007)",
  "Ultimates Vol. 1: Super-Human (2006)",
  "Ultimatum (2008 - 2009)",
  "Ultimatum: Fantastic Four Requiem One-Shot (2009)",
  "Ultimatum: Spider-Man Requiem (2009)",
  "Ultimatum: Spider-Man Requiem Book One (2009)",
  "Ultimatum: X-Men Requiem (2009)",
  "Ultron (2013)",
  "Uncanny Avengers (2012 - 2014)",
  "Uncanny Avengers (2015 - 2017)",
  "Uncanny Avengers (2015)",
  "Uncanny Avengers Annual (2014)",
  "Uncanny Avengers Annual (2015)",
  "Uncanny Avengers: Ultron Forever (2015)",
  "Uncanny Inhumans (2015 - 2017)",
  "Uncanny Inhumans Annual (2016)",
  "Uncanny Origins (1996)",
  "Uncanny X-Force (2010 - 2012)",
  "Uncanny X-Force (2013 - 2014)",
  "Uncanny X-Force: The Apocalypse Solution (2011)",
  "Uncanny X-Men (1963 - 2011)",
  "Uncanny X-Men (2011 - 2012)",
  "Uncanny X-Men (2013 - 2015)",
  "Uncanny X-Men (2016 - 2017)",
  "Uncanny X-Men (2018 - Present)",
  "Uncanny X-Men 3D (2019)",
  "Uncanny X-Men 500 Issues Poster Book (2008)",
  "Uncanny X-Men 50th Anniversary (2013)",
  "Uncanny X-Men Annual (1999)",
  "Uncanny X-Men Annual (2001)",
  "Uncanny X-Men Annual (1995)",
  "Uncanny X-Men Annual (1996)",
  "Uncanny X-Men Annual (1997)",
  "Uncanny X-Men Annual (2006 - 2009)",
  "Uncanny X-Men Annual (2009)",
  "Uncanny X-Men Annual (2011)",
  "Uncanny X-Men Annual (2014)",
  "Uncanny X-Men Annual (2016)",
  "Uncanny X-Men Annual (2019)",
  "Uncanny X-Men Facsimile Edition (2019)",
  "Uncanny X-Men Special (2014)",
  "Uncanny X-Men/Fantastic Four Annual (1998)",
  "Uncanny X-Men: First Class (2009 - 2010)",
  "Uncanny X-Men: First Class Giant-Size Special (2009)",
  "Uncanny X-Men: The Heroic Age (2010)",
  "Uncanny X-Men: Winter's End (2019)",
  "Underworld (2006)",
  "Union Jack (1998 - 1999)",
  "Union Jack (2006)",
  "Universe X (2000 - 2001)",
  "Universe X Special: 4 (2001)",
  "Universe X Special: Beasts (2001)",
  "Universe X Special: Cap (2001)",
  "Universe X Special: Iron Men (2001)",
  "Universe X Special: Spidey (2001)",
  "Untold Tales of Punisher Max (2012)",
  "Untold Tales of Spider-Man (1995 - 1997)",
  "UNTOLD TALES OF SPIDER-MAN -1 (1997)",
  "Untold Tales of Spider-Man Annual (1996)",
  "Untold Tales of Spider-Man Annual (1997)",
  "Untold Tales of the New Universe (2006)",
  "Untold Tales of the Punisher Max (2010 - 2012)",
  "USA Comics 70th Anniversary Special (2009)",
  "Valkyrie (2010)",
  "Valkyrie: Jane Foster (2019 - Present)",
  "Vampire Tales (1973 - 1975)",
  "Vault of Spiders (2018)",
  "Vengeance (2011)",
  "Vengeance of the Moon Knight (2009 - 2010)",
  "Vengeance of the Moon Knight 1-3 Must Have (2010)",
  "Venom (2003 - 2004)",
  "Venom (2011 - 2013)",
  "Venom (2016 - 2018)",
  "Venom (2018 - Present)",
  "Venom 2099 (2019)",
  "Venom Annual (2018)",
  "Venom Annual (2019)",
  "Venom Vs. Carnage (2004)",
  "Venom: Along Came a Spider (1996)",
  "Venom: Carnage Unleashed (1995)",
  "Venom: Dark Origin (2008 - 2009)",
  "Venom: First Host (2018 - Present)",
  "Venom: Flashpoint (2011)",
  "Venom: Funeral Pyre (1993)",
  "Venom: Lethal Protector (1993)",
  "Venom: License to Kill (1997)",
  "Venom: Nights Of Vengeance (1994)",
  "Venom: On Trial (1997)",
  "Venom: Seed of Darkness (1997)",
  "Venom: Separation Anxiety (1994 - 1995)",
  "Venom: Sign of the Boss (1997)",
  "Venom: Sinner Takes All (1995)",
  "Venom: Space Knight (2015 - 2016)",
  "Venom: The End (2020)",
  "Venom: The Enemy Within (1994)",
  "Venom: The Finale (1997 - 1998)",
  "Venom: The Hunger (1996)",
  "Venom: The Hunted (1996)",
  "Venom: The Mace (1994)",
  "Venom: The Madness (1993 - 1994)",
  "Venom: Tooth and Claw (1996 - 1997)",
  "Venomized (2018)",
  "Venomverse (2017)",
  "Venomverse: War Stories (2017)",
  "Venus (1948 - 1952)",
  "Villains for Hire (2011 - 2012)",
  "Vision (1994 - 1995)",
  "Vision (2015 - 2016)",
  "Vision and the Scarlet Witch (1982 - 1983)",
  "Vision and the Scarlet Witch (1985 - 1986)",
  "Vision: Director's Cut (2017)",
  "Vote Loki (2016)",
  "Wally the Wizard (1985 - 1986)",
  "War Is Hell (1973 - 1975)",
  "War is Hell (2019)",
  "War Is Hell: The First Flight of the Phantom Eagle (2008)",
  "War Machine (1994 - 1996)",
  "War Machine (2008 - 2010)",
  "War of Kings (2009)",
  "War of Kings Saga (2008)",
  "War of Kings: Ascension (2009)",
  "War of Kings: Darkhawk (2009)",
  "War of Kings: Savage World of Sakaar (2009)",
  "War of Kings: Warriors (2009)",
  "War of Kings: Who Will Rule? One-Shot (2009)",
  "War of the Realms (2019)",
  "WAR OF THE REALMS MAGAZINE (2019)",
  "War Of The Realms Omega (2019)",
  "War of the Realms Strikeforce: The Dark Elf Realm (2019)",
  "War Of The Realms Strikeforce: The Land Of Giants (2019)",
  "War Of The Realms Strikeforce: The War Avengers (2019)",
  "War of the Realms: Journey Into Mystery (2019)",
  "War of the Realms: New Agents of Atlas (2019)",
  "War of the Realms: The Punisher (2019)",
  "War of the Realms: Uncanny X-Men (2019)",
  "War of the Realms: War Scrolls (2019)",
  "Warlock (1972 - 1976)",
  "Warlock (1998 - 1999)",
  "Warlock (2004)",
  "Warlock and the Infinity Watch (1992 - 1995)",
  "Warlock Chronicles (1993 - 1994)",
  "Warriors Three (2010 - 2011)",
  "Weapon H (2018 - 2019)",
  "Weapon Plus: World War IV (2020)",
  "Weapon X (1995)",
  "Weapon X (2002 - 2004)",
  "Weapon X (2017 - 2018)",
  "Weapon X Noir (2010)",
  "Weapon X: Days of Future Now (2005)",
  "Weapon X: First Class (2008 - 2009)",
  "WEAPON X: THE DRAFT - KANE 1 (2002)",
  "Weapon X: The Draft - Marrow (2002)",
  "Weapon X: The Draft - Sauron (2002)",
  "Weapon X: The Draft - Wild Child (2002)",
  "Weapon X: The Draft - Zero (2002)",
  "Weapons of Mutant Destruction: Alpha (2017)",
  "Web of Scarlet Spider (1995 - 1996)",
  "Web of Spider-Man (1985 - 1995)",
  "Web of Spider-Man (2009 - 2010)",
  "Web of Spider-Man Annual (1985 - 1994)",
  "Web of Venom: Carnage Born (2018)",
  "Web Of Venom: Cult Of Carnage (2019)",
  "Web Of Venom: Funeral Pyre (2019)",
  "Web Of Venom: The Good Son (2020)",
  "Web of Venom: Unleashed (2019)",
  "Web of Venom: Ve'nam (2018)",
  "Web Warriors (2015 - 2016)",
  "Webspinners: Tales of Spider-Man (1999 - 2000)",
  "Weirdworld (2015 - 2016)",
  "Weirdworld (2015)",
  "Werewolf By Night (1972 - 1977)",
  "West Coast Avengers (1984)",
  "West Coast Avengers (1985 - 1994)",
  "West Coast Avengers (2018 - 2019)",
  "West Coast Avengers Annual (1986 - 1988)",
  "What If Aunt May Died Instead Of Uncle Ben? (2004)",
  "What If Aunt May Had Died Instead Of Uncle Ben? (2004)",
  "What If Doctor Doom Had Become the Thing? (2004)",
  "What If Jessica Jones Had Joined the Avengers? (2005)",
  "What If Karen Page Had Lived? (2004)",
  "What If Magneto Had Formed the X-Men with Professor X? (2004)",
  "What If? (1977 - 1984)",
  "What If? (1989 - 1998)",
  "What If? (2004 - 2005)",
  "What If? (2005)",
  "What If? (2008)",
  "What If? 200 (2010)",
  "What If? Age of Ultron (2014)",
  "What If? Annihilation (2007)",
  "What If? Astonishing X-Men (2009)",
  "What If? Avengers Disassembled (2006)",
  "What If? Avengers Vs. X-Men (2013)",
  "What If? Civil War (2007)",
  "What If? Classic Vol. 4 (2007)",
  "What If? Daredevil Vs. Elektra (2009)",
  "What If? Dark Reign (2010)",
  "What If? Ghost Rider (2018)",
  "What If? Infinity- Dark Reign (2015)",
  "What If? Infinity- Guardians of the Galaxy (2015)",
  "What If? Infinity- Inhumans (2015)",
  "What If? Infinity- Thanos (2015)",
  "What If? Infinity- X-Men (2015)",
  "What If? Iron Man: Demon in an Armor (2010)",
  "What If? Magik (2018)",
  "What If? Planet Hulk (2007)",
  "What If? Secret Invasion (2009)",
  "What If? Spider-Man (2018)",
  "WHAT IF? SPIDER-MAN THE OTHER (2006)",
  "What If? Spider-Man Vs. Wolverine (2008)",
  "What If? Spider-Man: Grim Hunt (2010)",
  "What If? Spider-Man: House of M (2009)",
  "What If? The Punisher (2018)",
  "What If? Thor (2018)",
  "What If? Venom/Deadpool (2010 - 2011)",
  "What If? Wolverine Enemy of the State (2006)",
  "What If? Wolverine: Father (2010)",
  "What If? World War Hulk (2009)",
  "What If? X-Men (2018)",
  "What If? X-Men - Rise and Fall of the Shi'ar (2007)",
  "What If? X-Men Age of Apocalypse (2006)",
  "What If? X-Men Deadly (2006)",
  "Where Monsters Dwell (2015)",
  "White Tiger (2006 - 2007)",
  "Widow Maker (2010)",
  "Wild Thing (1999)",
  "Winter Soldier (2012 - 2013)",
  "Winter Soldier (2018 - 2019)",
  "Winter Soldier: The Bitter March (2014)",
  "Winter Soldier: Winter Kills (2006)",
  "Wisdom (2006 - 2007)",
  "Witches (2004)",
  "Wizard of Oz Sketchbook (2008)",
  "Wolfpack (1988 - 1989)",
  "Wolfpack Marvel Graphic Novel (1987)",
  "Wolverine & Black Cat: Claws 2 (2010 - 2011)",
  "Wolverine & Captain America: Weapon Plus (2019)",
  "Wolverine & Gambit: Victims (1995)",
  "Wolverine & Jubilee (2010 - 2011)",
  "Wolverine & the X-Men (2011 - 2014)",
  "Wolverine & the X-Men (2014)",
  "Wolverine & the X-Men Annual (2013)",
  "Wolverine & the X-Men: Alpha & Omega (2011 - 2012)",
  "Wolverine (1982)",
  "Wolverine (1988 - 2003)",
  "Wolverine (2003 - 2009)",
  "Wolverine (2010 - 2012)",
  "Wolverine (2013 - 2014)",
  "Wolverine (2014)",
  "WOLVERINE 1000 (2011)",
  "Wolverine and Power Pack (2008 - 2009)",
  "Wolverine and The Punisher: Damaging Evidence (1993)",
  "Wolverine and the X-Men Magazine (2009)",
  "Wolverine Annual (1995)",
  "Wolverine Annual (1999)",
  "Wolverine Annual (2012)",
  "Wolverine Annual (2014)",
  "Wolverine Annual (2019)",
  "Wolverine Annual: Deathsong (2007)",
  "Wolverine Art Appreciation (2009)",
  "Wolverine Comic Reader (2013)",
  "Wolverine First Class (2009)",
  "WOLVERINE GLOBAL JEOPARDY 1 (1993 - Present)",
  "WOLVERINE KILLING 1 (1993 - Present)",
  "Wolverine Magazine (2008)",
  "Wolverine Magazine (2009)",
  "Wolverine Max (2012)",
  "Wolverine MGC (2010)",
  "Wolverine MGC (2011)",
  "Wolverine Noir (2009)",
  "Wolverine Origins (2006 - 2010)",
  "Wolverine Poster (2006)",
  "Wolverine Poster Book (2009)",
  "Wolverine Saga (2009)",
  "Wolverine Vs. Blade Special (2019)",
  "Wolverine vs. Sabretooth 3D (2020)",
  "Wolverine Weapon X (2009 - 2010)",
  "Wolverine, Punisher & Ghost Rider: Official Index to the Marvel Universe (2011 - 2012)",
  "Wolverine/Cable: Guts and Glory (1999)",
  "Wolverine/Captain America (2004)",
  "Wolverine/Deadpool: The Decoy (2010 - 2011)",
  "Wolverine/Doop (2003)",
  "Wolverine/Hercules: Myths, Monsters & Mutants (2010 - 2011)",
  "Wolverine/Hulk (2002)",
  "Wolverine/Nick Fury: Scorpio Rising (1994)",
  "Wolverine/Punisher (2004)",
  "Wolverine/Punisher: Revelation (1999)",
  "Wolverine: Agent of Atlas (2008 - 2009)",
  "Wolverine: Bloodlust (1990)",
  "Wolverine: Chop Shop (2008)",
  "Wolverine: Dangerous Games (2008)",
  "Wolverine: Debt of Death (2011)",
  "Wolverine: Exit Wounds (2019)",
  "Wolverine: Firebreak One-Shot (2007)",
  "Wolverine: First Class (2008 - 2010)",
  "Wolverine: Flies to a Spider (2008)",
  "Wolverine: In The Flesh (2013)",
  "Wolverine: Infinity Watch (2019)",
  "WOLVERINE: INNER FURY 1 (1992 - Present)",
  "Wolverine: Japan's Most Wanted Infinite Comic (2013)",
  "Wolverine: Killing Made Simple (2008)",
  "Wolverine: Knight of Terra (1995)",
  "Wolverine: Manifest Destiny (2008 - 2009)",
  "Wolverine: Mr. X (2010)",
  "Wolverine: Netsuke (2002 - 2003)",
  "Wolverine: Old Man Logan Giant-Size (2009)",
  "Wolverine: Origins Annual (2007)",
  "Wolverine: Rahne of Terra (1991)",
  "Wolverine: Revolver (2009)",
  "Wolverine: Road to Hell (2010)",
  "Wolverine: Roar (2008)",
  "Wolverine: Saudade (2008)",
  "Wolverine: Savage (2010)",
  "Wolverine: Season One (2013)",
  "Wolverine: Snikt! (2003)",
  "Wolverine: Soultaker (2005)",
  "Wolverine: Switchback (2009)",
  "Wolverine: The Amazing Immortal Man & Other Bloody Tales (2008)",
  "Wolverine: The Anniversary (2009)",
  "Wolverine: The Best There Is (2010 - 2011)",
  "Wolverine: The Best There Is - Contagion (2011)",
  "Wolverine: The End (2003 - 2004)",
  "Wolverine: The Jungle Adventure (1989)",
  "Wolverine: The Long Night Adaptation (2019)",
  "Wolverine: Under the Boardwalk (2009)",
  "Wolverine: Weapon X Files (2009)",
  "Wolverine: Wendigo! (2010)",
  "Wolverine: Xisle (2003)",
  "Wolverines (2015)",
  "Women of Marvel (2010)",
  "Women of Marvel: Celebrating Seven Decades (2010)",
  "Women of Marvel: Celebrating Seven Decades Poster Book (2010)",
  "Wonder Man (1986)",
  "Wonder Man (1991 - 1994)",
  "Wonder Man (2006 - 2007)",
  "Wonderful Wizard of Oz MGC (2010)",
  "World War Hulk (2007)",
  "World War Hulk Prologue: World (2007)",
  "World War Hulk Prologue: World Breaker (2007)",
  "World War Hulk: Aftersmash (2007)",
  "World War Hulk: Aftersmash! - Damage Control (2008)",
  "World War Hulk: Front Line (2007)",
  "World War Hulk: Gamma Corps (2007)",
  "WORLD WAR HULK: GAMMA FILES (2007)",
  "World War Hulk: Warbound (2007)",
  "World War Hulk: X-Men (2007)",
  "World War Hulks (2010)",
  "World War Hulks: Hulked-Out Heroes (2010)",
  "World War Hulks: Spider-Man & Thor (2010)",
  "World War Hulks: Wolverine & Captain America (2010)",
  "Wyrms (2007)",
  "X Men Noir (2008 - 2009)",
  "X Necrosha (2009)",
  "X Necrosha: The Gathering (2009)",
  "X-23 (2005)",
  "X-23 (2010 - 2012)",
  "X-23 (2018 - 2019)",
  "X-23: Target X (2006 - 2007)",
  "X-51 (1999 - 2000)",
  "X-Babies (2009 - 2010)",
  "X-Babies Reborn (2000)",
  "X-Calibre (1995)",
  "X-Campus (2010)",
  "X-Club (2011 - 2012)",
  "X-Factor (1986 - 1998)",
  "X-Factor (2002)",
  "X-Factor (2005 - 2013)",
  "X-Factor 87 Facsimile Edition (2019)",
  "X-Factor Annual (1986 - 1994)",
  "X-Factor Forever (2010)",
  "X-Factor: Layla Miller (2008)",
  "X-Factor: The Quick and the Dead (2008)",
  "X-Force (1991 - 2004)",
  "X-Force (2004 - 2005)",
  "X-Force (2008 - 2010)",
  "X-Force (2014 - 2015)",
  "X-Force (2018 - 2019)",
  "X-Force (2019 - 2020)",
  "X-Force Annual (1992 - 1994)",
  "X-Force Annual (2009)",
  "X-Force Director's Cut Edition (2019)",
  "X-Force Special: Ain't No Dog (2008)",
  "X-Force/Cable Annual (1995)",
  "X-Force/Cable Annual (1996)",
  "X-Force/Cable: Messiah War Prologue (2009)",
  "X-Force: Force to Be Reckoned with (2011)",
  "X-Force: Sex and Violence (2010)",
  "X-Force: Shatterstar (2005)",
  "X-Infernus (2008 - 2009)",
  "X-Infernus Saga (2008)",
  "X-Man (1995 - 2000)",
  "X-Man Annual (1996 - 1997)",
  "X-Men '92 (2015)",
  "X-Men '92 (2016)",
  "X-Men '92 Infinite Comic (2015)",
  "X-Men (1991 - 2001)",
  "X-Men (2004 - 2007)",
  "X-Men (2010 - 2013)",
  "X-Men (2013 - 2015)",
  "X-Men (2019 - 2020)",
  "X-Men 137 Facsimile Edition (2019)",
  "X-Men 2099 (1993 - 1996)",
  "X-Men and Power Pack (2005 - 2006)",
  "X-Men Annual (1970 - 1994)",
  "X-Men Annual (1970 - 1994)",
  "X-Men Annual (1991)",
  "X-Men Annual (1995)",
  "X-Men Annual (1997)",
  "X-Men Annual (1999)",
  "X-Men Annual (2000)",
  "X-Men Annual (2007)",
  "X-Men Blue Annual (2018)",
  "X-Men Chronicles (1995)",
  "X-Men Evolutions (2011)",
  "X-Men Facsimile Edition (2019)",
  "X-Men Fairy Tales (2006)",
  "X-Men Forever (2009 - 2010)",
  "X-Men Forever 2 (2010 - 2011)",
  "X-Men Forever Alpha (2009)",
  "X-Men Forever Annual (2010)",
  "X-Men Forever Giant-Size (2010)",
  "X-Men Giant-Size (2011)",
  "X-Men Gold Annual (2018)",
  "X-Men Legacy (2008 - 2012)",
  "X-Men Legacy (2012 - 2014)",
  "X-Men Noir (2009)",
  "X-Men Noir: Mark of Cain (2009 - 2010)",
  "X-Men Origin: Colossus (2008)",
  "X-Men Origin: Jean Grey (2008)",
  "X-Men Origins: Beast (2008)",
  "X-Men Origins: Cyclops (2010)",
  "X-Men Origins: Deadpool (2010)",
  "X-Men Origins: Emma Frost (2010)",
  "X-Men Origins: Gambit (2009)",
  "X-Men Origins: Iceman (2009)",
  "X-Men Origins: Nightcrawler (2010)",
  "X-Men Origins: Sabretooth (2009)",
  "X-Men Origins: Wolverine (2009)",
  "X-Men Prime (2017)",
  "X-Men Spotlight (2011)",
  "X-Men Unlimited (1993 - 2003)",
  "X-Men Unlimited (2004 - 2006)",
  "X-Men Vs. Agents of Atlas (2009 - 2010)",
  "X-Men Vs. Avengers (1987)",
  "X-Men Vs. Brood (1996)",
  "X-Men Vs. Hulk (2009)",
  "X-Men/Alpha Flight (1985)",
  "X-Men/Alpha Flight (1998)",
  "X-Men/ClanDestine (1996)",
  "X-Men/Dr. Doom Annual (1998)",
  "X-Men/Fantastic Four (2004 - 2005)",
  "X-Men/Runaways (2006)",
  "X-Men/Spider-Man (2008 - 2009)",
  "X-Men: Age of Apocalypse (2005)",
  "X-Men: Age of Apocalypse One Shot (2005)",
  "X-Men: Alpha (1995)",
  "X-Men: Apocalypse/Dracula (2006)",
  "X-Men: Battle of the Atom (2013)",
  "X-Men: Black - Emma Frost (2018)",
  "X-Men: Black - Juggernaut (2018)",
  "X-Men: Black - Magneto (2018)",
  "X-Men: Black - Mojo (2018)",
  "X-Men: Black - Mystique (2018)",
  "X-Men: Black Sun (2000)",
  "X-Men: Blue (2017 - 2018)",
  "X-Men: Books of Askani (1995)",
  "X-Men: Children of the Atom (1999)",
  "X-Men: Clan Destine (1996)",
  "X-Men: Colossus Bloodline (2005 - 2006)",
  "X-Men: Curse of the Mutants - Blade (2010)",
  "X-Men: Curse of the Mutants - Smoke & Blood (2010)",
  "X-Men: Curse of the Mutants - Storm & Gambit (2010)",
  "X-Men: Curse of the Mutants - X-Men Vs. Vampires (2010)",
  "X-Men: Deadly Genesis (2005 - 2006)",
  "X-Men: Die by the Sword (2007 - 2008)",
  "X-Men: Divided We Stand (2008)",
  "X-Men: Earth's Mutant Heroes (2010)",
  "X-Men: Emperor Vulcan (2007 - 2008)",
  "X-Men: Endangered Species (2007)",
  "X-Men: Evolution (2001 - 2002)",
  "X-Men: Fall Of The Mutants (2010 - 2011)",
  "X-Men: First Class (2006)",
  "X-Men: First Class (2007 - 2008)",
  "X-Men: First Class (2019)",
  "X-Men: First Class Finals (2009)",
  "X-Men: First Class Magazine (2011)",
  "X-Men: First Class Special (2007)",
  "X-Men: Future History - The Messiah War Sourcebook (2009)",
  "X-Men: God Loves, Man Kills - Special Edition (1982)",
  "X-Men: Gold (2013)",
  "X-Men: Gold (2017 - 2018)",
  "X-Men: Grand Design (2017 - 2018)",
  "X-Men: Grand Design - Second Genesis (2018)",
  "X-Men: Grand Design – X-Tinction (2019)",
  "X-Men: Great Power (2011)",
  "X-Men: Hellbound (2010)",
  "X-Men: Hope (2010)",
  "X-Men: Kingbreaker (2008 - 2009)",
  "X-Men: Kitty Pryde- Shadow & Flame (2005)",
  "X-Men: Legacy Annual (2009)",
  "X-Men: Magneto Testament (2008 - 2009)",
  "X-Men: Manifest Destiny (2008 - 2009)",
  "X-Men: Manifest Destiny – Nightcrawler (2009)",
  "X-Men: Messiah Complex (2007 - 2008)",
  "X-Men: Messiah Complex - Mutant Files (2007)",
  "X-Men: No More Humans (2014)",
  "X-Men: Odd Men Out (2008)",
  "X-Men: Omega (1995)",
  "X-Men: Original Sin (2008)",
  "X-Men: Phoenix (1999 - 2000)",
  "X-Men: Phoenix - Endsong (2005)",
  "X-Men: Phoenix - Legacy of Fire (2003)",
  "X-Men: Phoenix - Warsong (2006 - 2007)",
  "X-Men: Phoenix - Warsong (2006)",
  "X-Men: Phoenix Force Handbook (2010)",
  "X-Men: Pixie Strikes Back (2009 - 2010)",
  "X-Men: Pixies and Demons Director's (2008)",
  "X-Men: Prelude to Schism (2011)",
  "X-Men: Prime (1995)",
  "X-Men: Red (2018)",
  "X-Men: Red Annual (2018)",
  "X-Men: Regenesis (2011)",
  "X-Men: Return of Magik (2008)",
  "X-Men: Road to Onslaught (1996)",
  "X-Men: Ronin (2003)",
  "X-Men: Schism (2011)",
  "X-Men: Season One (2011)",
  "X-Men: Second Coming (2010)",
  "X-Men: Second Coming - Revelations: Blind Science (2010)",
  "X-Men: Spotlight on Starjammers (1990)",
  "X-Men: Survival Guide to the Mansion (1993)",
  "X-Men: Sword of the Braddocks (2009)",
  "X-Men: The 198 (2006)",
  "X-MEN: THE 198 FILES (2006)",
  "X-Men: The Complete Onslaught Epic Vol. 4 (2009)",
  "X-Men: The End - Dreamers & Demons (2004)",
  "X-Men: The End - Heroes and Martyrs (2005)",
  "X-Men: The End - Men and X-Men (2006)",
  "X-Men: The Exterminated (2018)",
  "X-Men: The Hidden Years (1999 - 2001)",
  "X-Men: The Lives and Times of Lucas Bishop (2009)",
  "X-MEN: THE MAGNETO WAR 1 (1999)",
  "X-Men: The Search for Cyclops (2000 - 2001)",
  "X-Men: The Wedding Album (1994)",
  "X-Men: The Wedding Special (2018)",
  "X-Men: To Serve and Protect (2010 - 2011)",
  "X-Men: Wakanda Forever (2018)",
  "X-Men: Worlds Apart (2008 - 2009)",
  "X-Statix (2002 - 2004)",
  "X-Statix Presents: Dead Girl (2006)",
  "X-Termination (2013)",
  "X-Terminators (1988)",
  "X-Tinction Agenda (2015)",
  "X-Treme X-Men (2001 - 2004)",
  "X-Treme X-Men (2012 - 2013)",
  "X-Treme X-Men Annual (2001)",
  "X-Treme X-Men Vol. 6: Intifada (2004)",
  "X-Treme X-Men: Mekanix (2001 - 2004)",
  "X-Treme X-Men: The Savage Land (2001 - 2002)",
  "X-Treme X-Men: X-Pose (2003)",
  "X-Universe (1995)",
  "Xavier Institute Alumni Yearbook (1996)",
  "XXL EMINEM/PUNISHER COMIC (2009)",
  "Years of Future Past (2015)",
  "Yellow Claw (1956)",
  "Yondu (2019 - 2020)",
  "You Are Deadpool (2018)",
  "Young Allies (2010)",
  "Young Allies Comics (1941 - 1946)",
  "Young Allies Comics 70th Anniversary Special (2009)",
  "Young Avengers (2005 - 2006)",
  "Young Avengers (2013)",
  "Young Avengers Presents (2008)",
  "Young Avengers Special (2005)",
  "Young Guns Sketchbook (2009)",
  "Young Men (1953)",
  "Young X-Men (2008 - 2009)",
  "Ziggy Pig - Silly Seal Comics (2019)",
  "Zombie (2006)",
  "Zombies Assemble (2017)",
  "Zombies Assemble 2 (2017)",
  "Zombies Christmas Carol (2011)",
  "100th Anniversary Special (2014)",
  "15 Love (2011)",
  "1602 (2003 - 2004)",
  "1602 Witch Hunter Angela (2015)",
  "1872 (2015)",
  "2099 Alpha (2019)",
  "2099 Omega (2019)",
  "4 (2004 - 2006)",
  "5 Ronin (2010)",
]
