import { Component, Input, ElementRef } from '@angular/core';

@Component({
  selector: 'app-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss']
})
export class OptionComponent {

  @Input()
  value: any;

  constructor(
    public elem: ElementRef
  ) { }

}
