import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APIURL } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CollectibleService {

  constructor(private http: HttpClient) { }

  public getAllCollectibles() {
    const url = `${APIURL}/collectibles`;
    return this.http.get(url);
  }

  public addCollectible(
    universe: string,
    name: string,
    brand: string,
    series: string,
    year: string,
    upc: string,
    buildFigure: string,
    variation: string,
    accessories: string,
    condition: string,
    selling: string,
    trade: string,
    collection: string,
    wishlist: string,
    price: string,
    quantity: string,
    files: File[]) {

    const url = `${APIURL}/collectibles/add`;
    // tslint:disable: no-unused-expression
    const formData = new FormData();
    universe ? formData.append('universe', universe) : null;
    name ? formData.append('name', name) : null;
    brand ? formData.append('brand', brand) : null;
    series ? formData.append('series', series) : null;
    year ? formData.append('year', year) : null;
    upc ? formData.append('upc', upc) : null;
    buildFigure ? formData.append('buildFigure', buildFigure) : null;
    variation ? formData.append('variation', variation) : null;
    accessories ? formData.append('accessories', accessories) : null;
    formData.append('condition', condition);
    formData.append('sell', selling);
    formData.append('trade', trade);
    formData.append('collection', collection);
    formData.append('wishlist', wishlist);
    price ? formData.append('price', price) : null;
    quantity ? formData.append('quantity', quantity) : null;
    if (files) {
      if (files.length > 0) {
        files.map(file => formData.append('images', file));
      }
    }
    return this.http.post(url, formData);
  }

  public updateCollectible(
    id: string,
    universe: string,
    name: string,
    brand: string,
    series: string,
    year: string,
    upc: string,
    buildFigure: string,
    variation: string,
    accessories: string,
    condition: string,
    selling: string,
    trade: string,
    wishlist: string,
    collection: string,
    price: string,
    files: File[],
    quantity: string) {

    const url = `${APIURL}/collectibles/update/${id}`;
    // tslint:disable: no-unused-expression
    const formData = new FormData();
    universe ? formData.append('universe', universe) : null;
    name ? formData.append('name', name) : null;
    brand ? formData.append('brand', brand) : null;
    series ? formData.append('series', series) : null;
    year ? formData.append('year', year) : null;
    upc ? formData.append('upc', upc) : null;
    buildFigure ? formData.append('buildFigure', buildFigure) : null;
    variation ? formData.append('variation', variation) : null;
    accessories ? formData.append('accessories', accessories) : null;
    formData.append('condition', condition);
    formData.append('sell', selling);
    formData.append('trade', trade);
    formData.append('collection', collection);
    formData.append('wishlist', wishlist);
    price ? formData.append('price', price) : null;
    quantity ? formData.append('quantity', quantity) : null;
    if (files) {
      if (files.length > 0) {
        files.map(file => formData.append('images', file));
      }
    }
    return this.http.put(url, formData);
  }

  public updateCollectiblePhoto(id: string, files: File[]) {
    const url = `${APIURL}/collectibles/update/picture/${id}`;
    const formData = new FormData(); // Currently empty
    formData.append('image', files[0]);
    return this.http.put(url, formData);
  }

  public deleteImage(fileId: number){
    const url = `${APIURL}/collectibles/delete-image/${fileId}`;

    return this.http.delete(url)
  }

  public deleteCollectible(id: string) {
    const url = `${APIURL}/collectibles/delete/${id}`;
    return this.http.delete(url);
  }
}
