import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-terms-of-service-dialog',
  templateUrl: './terms-of-service-dialog.component.html',
  styleUrls: ['./terms-of-service-dialog.component.scss']
})
export class TermsOfServiceDialogComponent implements OnInit {

  accept;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TermsOfServiceDialogComponent>) {}

  ngOnInit() {
    this.accept = true;
  }

  closeAndDeny() {
    this.dialogRef.close();
  }

  closeAndAccept(evt) {
    this.dialogRef.close(evt);
  }

}
