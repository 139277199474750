import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-confirm-product-picture-dialog',
  templateUrl: './confirm-product-picture-dialog.component.html',
  styleUrls: ['./confirm-product-picture-dialog.component.scss']
})
export class ConfirmProductPictureDialogComponent implements OnInit {
  public productName;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private matSnackbar: MatSnackBar,
    public dialogRef: MatDialogRef<ConfirmProductPictureDialogComponent>) {}

  ngOnInit() {
    if (!this.data.product.title) {
      this.productName = this.data.product.name;
    }
    if (!this.data.product.name) {
      this.productName = this.data.product.title;
    }
  }

  close() {
    this.dialogRef.close();
  }

  submit() {
   this.dialogRef.close(this.data.picture.id);
   this.matSnackbar.open(
    `You have successfully deleted a picture from ${this.productName}'s Collectible Page`,
    'OK',
    {
      duration: 5000,
      panelClass: ['cvSnack']
    }
  );
  }

}
