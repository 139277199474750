import { Action } from "@ngrx/store";

export function routerReducer(state: {}, action: Action) {
  const results: any = action;
  switch (action.type) {
    case "SET_ROUTE":
      return { route: results.route };
    default:
      return state;
  }
}
