export const comicCondition = [
  'Mint',
  'Near Mint',
  'Very Fine',
  'Fine',
  'Very Good',
  'Good',
  'Fair',
  'Poor',
  'Incomplete'
];
