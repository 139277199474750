import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { login } from '../../redux/actions/auth.actions';
import { Store, Action } from '@ngrx/store';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ValidatorFn, FormControl } from '@angular/forms';
import { MatSnackBar, MatDialog } from '@angular/material';
import { TermsOfServiceDialogComponent } from 'src/app/dialogs/terms-of-service-dialog/terms-of-service-dialog.component';

const trimValidator: ValidatorFn = (control: FormControl) => {
  if (control.value.includes(" ")) {
    return {
      'trimError': { value: 'NO SPACES' }
    };
  }

  return null;
};

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        state('invisible', style({ opacity: 0 })),
        style({ opacity: 0 }),
        animate(800, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class CreateAccountComponent implements OnInit {
  public firstName: string;
  public lastName: string;
  public email: string;
  public username: string;

  public firstNameError: string;
  public lastNameError: string;
  public emailError: string;
  public usernameError: string;
  public tosError: string;
  public noSpacesError = 'No Spaces Allowed';

  public createdUser;
  public profileJson;
  public profile;
  public post;
  public results;
  public error;
  public usernameUnique: string;

  public tos;

  private onDestroy$ = new Subject<void>();

  control = new FormControl('', trimValidator);

  constructor(
    public auth: AuthService,
    private router: Router,
    private matSnackbar: MatSnackBar,
    private store: Store<Action>,
    private mdDialog: MatDialog,
  ) {
    this.store = this.store;
  }

  ngOnInit() {
    this.auth.userProfile$.pipe(takeUntil(this.onDestroy$)).subscribe(
      profile => (
        (this.profileJson = JSON.stringify(profile, null, 2)),
        (this.profile = profile)
      )
    );
    this.tos = false;
  }

  /* || FORM CONTROL || */

  handleFirstName(val) {
    this.firstNameError = this.validate(val);
    if (this.firstNameError) {
      return this.firstName = '';
    }
    this.firstName = val;
  }

  handleLastName(val) {
    this.lastNameError = this.validate(val);
    if (this.lastNameError) {
      return this.lastName = '';
    }
    this.lastName = val;
  }

  handleEmail(val: string) {
    this.emailError = this.validate(val);
    if (this.emailError) {
      return this.email = '';
    }
    this.email = val;
  }

  handleUsername(val: string) {
    if (val === undefined) {
      return
    }
    this.usernameError = this.validate(val);
    if (this.usernameError) {
      return this.username = '';
    }
    this.username = val;
  }

  /* || END FORM CONTROL || */

  /* || BEGIN INPUT VALIDATION || */

  validate(legit: any): any {
    if (!legit) {
      return 'Required';
    }
    return '';
  }

  validateTos(legit: any): any {
    if (!legit) {
      return 'Required';
    }
    return '';
  }

  goToTermsOfService() {
    const dialogRef = this.mdDialog.open(TermsOfServiceDialogComponent);
    dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe(result => {
      this.tos = result;
    });
  }

  /* || END INPUT VALIDATION || */

  async onSubmit() {
    this.post = ({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.profile.email,
      username: this.username,
      userId: this.profile.sub
    });

    this.firstNameError = this.validate(this.firstName);
    this.lastNameError = this.validate(this.lastName);
    this.usernameError = this.validate(this.username);
    this.tosError = this.validateTos(this.tos);


    if (
      this.firstNameError ||
      this.lastNameError ||
      this.usernameError ||
      this.tosError
    ) {
      return this.matSnackbar.open(
        `Please recheck the form. A REQUIRED field in red has been identified`,
        'OK',
        {
          duration: 5000,
          panelClass: ['cvSnack']
        }
      );
    }

    await this.auth.createUser(this.post).pipe(takeUntil(this.onDestroy$)).subscribe(
      (res: any) => {
        this.results = res;
        this.store.dispatch(login({ user: this.results.user }));
        this.router.navigateByUrl('/');
      },
      err => {
        if (err.error.err.errors[0].message === 'username must be unique') {
          this.error = err.error.err.errors[0].message;
          return;
        }
        this.error = 'There has been an unexpected error';
      }
    );
  }
}
