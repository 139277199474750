import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APIURL } from '../../environments/environment.prod';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StripeChargeService {

  constructor(
    private http: HttpClient,
    private router: Router, ) { }

  public createToken(card) {
    const { number, exp_month, exp_year, cvc } = card;
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    const form = new URLSearchParams();
    form.append('card[number]', number);
    form.append('card[exp_month]', exp_month);
    form.append('card[exp_year]', exp_year);
    form.append('card[cvc]', cvc);

    return this.http.post('https://api.stripe.com/v1/tokens', form.toString(), options);
  }

  public createCharge(payload) {
    const url = `${APIURL}/stripe/create-charge`;
    return this.http.post(url, { payload });
  }

  public checkout(card, buyer, cart){
    const url = `${APIURL}/stripe/checkout`;
    return this.http.post(url, {card, buyer, cart});
  }
}
