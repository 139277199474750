import { Component, Inject } from '@angular/core';
import { User } from 'src/app/models/user';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';

interface AppState {
  auth: User;
}

@Component({
  selector: 'app-change-profile-picture-dialog',
  templateUrl: './change-profile-picture-dialog.component.html',
  styleUrls: ['./change-profile-picture-dialog.component.scss']
})
export class ChangeProfilePictureDialogComponent {

  public id;
  public firstName: string;
  public lastName: string;
  public aboutMe: string;
  public files;

  public firstNameError: string;
  public lastNameError: string;
  public aboutMeError: string;

  public savedFirstName: string;
  public savedLastName: string;
  public savedAboutMe: string;

  public user: Observable<User>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ChangeProfilePictureDialogComponent>,
    public auth: AuthService,
    public form: FormBuilder,
    private store: Store<AppState>) {
    this.user = this.store.select('auth');
  }

  handleFile(val) {
    this.files = val;
  }

  close() {
    this.dialogRef.close();
  }

  submit() {
    this.id = this.data.userId;
    this.auth.updatePhoto(this.id, this.files);
    this.close();
  }
}
