import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef} from '@angular/core';

@Component({
  selector: 'app-file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss']
})
export class FileDropComponent {

  public error: string;
  public files: File[];

  @ViewChild('inputFile', { static: true }) nativeInputFile: ElementRef;

  @Input()
  label: string;

  @Input()
  accept: string[];

  @Input()
  multiple: boolean;

  @Output()
  fileSelect: EventEmitter<File[]> = new EventEmitter();

  @Output()
  invalidFileSelect: EventEmitter<File[]> = new EventEmitter();

  @Input()
  maxFileSizeAcceptedInMb: number;

  onNativeInputFileSelect(event: any): void {
    this.onFileSelect(event.target.files);
  }

  getMbInBytes() {
    return this.maxFileSizeAcceptedInMb * 1024 * 1024;
  }

  onFileSelect(files: File[]): void {
    this.clearError();
    if (!this.multiple && files.length > 1) {
      this.error = 'ONLY ONE FILE ALLOWED';
      return;
    }
    this.files = files;
    const validFiles: Array<File> = [];
    const invalidFiles: Array<File> = [];

    if (files.length > 0) {
      for (const file of files) {
        if (this.maxFileSizeAcceptedInMb !== undefined && file.size > this.getMbInBytes()) {
          this.error = `THE FILE SIZE EXCEEDS ${this.maxFileSizeAcceptedInMb}MB`;
          return;
        }
        if (this.checkFileType(file)) {
          validFiles.push(file);
        } else {
          invalidFiles.push(file);
        }
      }
      if (validFiles.length > 0) {
        this.fileSelect.emit(validFiles);
      }
      if (invalidFiles.length > 0) {
        this.invalidFileSelect.emit(invalidFiles);
      }
    }
  }

  clearError(): void {
    this.error = null;
    this.files = null;
  }

  onInvalidFileSelect(files: File[]): void {
    if (!this.multiple && files.length > 1) {
      this.error = 'ONLY ONE FILE ALLOWED';
      return;
    }
    this.error = 'THE FOLLOWING FILES ARE NOT VALID:';
    this.files = files;
  }

  checkFileType(file: File): boolean {
    for (const acceptedFileType of this.accept) {
      if (file.type.includes(acceptedFileType) || file.name.includes(acceptedFileType)) {
        return true;
      }
    }
    this.error = 'THE FOLLOWING FILES ARE NOT VALID:';
    return false;
  }

  selectFile(): void {
    this.nativeInputFile.nativeElement.click();
  }
}
