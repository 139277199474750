import { Injectable } from '@angular/core';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import * as config from '../../../auth_config.json';
import {
  from,
  of,
  Observable,
  BehaviorSubject,
  combineLatest,
  throwError
} from 'rxjs';
import { tap, catchError, concatMap, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Store, Action } from '@ngrx/store';
import { login, updateUser, logout } from '../redux/actions/auth.actions';
import { setAllMessages } from '../redux/actions/message.actions';
import { WebsocketService } from './websocket.service';
import { APIURL } from '../../environments/environment.prod';
import { MessengerService } from './messenger.service.js';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // Create an observable of Auth0 instance of client
  auth0Client$ = (from(
    createAuth0Client({
      domain: config.domain,
      client_id: config.clientId,
      redirect_uri: `${window.location.origin}/callback`,
      audience: config.audience
    })
  ) as Observable<Auth0Client>).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError(err => throwError(err))
  );
  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap(res => (this.loggedIn = res))
  );
  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );
  // Create subject and public observable of user profile data
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();
  // Create a local property for login status
  loggedIn: boolean = null;
  userId: string;
  results: any;

  constructor(
    private router: Router,
    private http: HttpClient,
    private ws: WebsocketService,
    private store: Store<Action>,
    private msg: MessengerService
  ) {
    this.store = this.store;
  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap(user => this.userProfileSubject$.next(user))
    );
  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#gettokensilently
  getTokenSilently$(options?): Observable<string> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getTokenSilently(options)))
    );
  }

  localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          return this.getUser$();
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      })
    );
    checkAuth$.subscribe((response: { [key: string]: any } | boolean) => {
      // If authenticated, response will be user object
      // If not authenticated, response will be 'false'
      this.loggedIn = !!response;
    });
  }

  login(redirectPath: string = '/') {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        appState: { target: redirectPath }
      });
    });
  }

  handleAuthCallback() {
    // Only the callback component should call this method
    // Call when app reloads after user logs in with Auth0
    let targetRoute: string; // Path to redirect to after login processsed
    // tslint:disable-next-line: prefer-const
    const authComplete$ = this.handleRedirectCallback$.pipe(
      tap(cbRes => {
        // Get and set target redirect route from callback results
        targetRoute =
          cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
      }),
      concatMap(() => {
        // Redirect callback complete; get user and login status
        this.auth0Client$.subscribe((client: Auth0Client) => {
          this.getUser$();
        });
        // tslint:disable-next-line: deprecation
        return combineLatest(this.getUser$(), this.isAuthenticated$);
      })
    );
    // Subscribe to authentication completion observable
    // Response will be an array of user, token, and login status
    // tslint:disable-next-line: no-shadowed-variable
    authComplete$.subscribe(([user, loggedIn]) => {
      // Redirect to target route after callback processing
      user.firstName = user.given_name;
      user.lastName = user.family_name;
      user.email = user.email;
      user.username = user.nickname;
      this.userId = user.sub;

      this.getUser(this.userId).subscribe(res => {
        if (!res) {
          return this.router.navigateByUrl('/create-account');
        }
        // tslint:disable-next-line: no-shadowed-variable
        this.logIn(user.email).subscribe(res => {
          this.results = res;
          this.ws.setClient(this.results.user.id);
          this.store.dispatch(login({ user: this.results.user }));
          this.msg.getUserMessages().subscribe((results: any) => {
            this.store.dispatch(setAllMessages(results));
          });
        });
        this.router.navigate([targetRoute]);
      });
    });
  }

  async logout() {
    // Ensure Auth0 client instance exists
    await this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log out
      client.logout({
        client_id: config.clientId,
        returnTo: window.location.origin
      });
    });
    this.store.dispatch(logout());
    this.ws.removClient(this.results.user.id);
  }

  createUser(user: any) {
    const url = `${APIURL}/users/sign-up`;
    this.ws.setClient(user.userId);
    this.msg.getUserMessages().subscribe((res: any) => {
      this.store.dispatch(setAllMessages(res));
    });
    return this.http.post(url, user);
  }

  logIn(email: string) {
    const url = `${APIURL}/users/log-in`;
    return this.http.post(url, { username: email });
  }

  getAllUsers() {
    const url = `${APIURL}/users/getall`;
    return this.http.get(url);
  }

  getUser(id: string) {
    const url = `${APIURL}/users/${id}`;
    return this.http.get(url);
  }

  getUserByUsername(username: string) {
    const url = `${APIURL}/users/getByUsername/${username}`;
    return this.http.get(url);
  }

  public updateUser(
    id: string,
    firstName: string,
    lastName: string,
    aboutMe: string
  ) {
    const url = `${APIURL}/users/update/${id}`;
    const formData = new FormData(); // Currently empty
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('aboutMe', aboutMe);
    return this.http.put(url, formData).subscribe(res => {
      this.results = res;
      this.store.dispatch(updateUser({ user: this.results }));
    });
  }

  public updatePhoto(id: string, files: File[]) {
    const url = `${APIURL}/users/update/picture/${id}`;
    const formData = new FormData(); // Currently empty
    formData.append('image', files[0], files[0].name);
    return this.http.put(url, formData).subscribe(res => {
      this.results = res;
      this.store.dispatch(updateUser({ user: this.results }));
    });
  }

  public updatePoints(rewards: number, id: string) {
    const url = `${APIURL}/users/update/${id}`;
    const body = {
      rewards
    };
    return this.http.put(url, body).subscribe(res => {
      this.results = res;
      this.store.dispatch(updateUser({ user: this.results }));
    });
  }
}
