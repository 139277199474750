import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';

import { EditProfileDialogComponent } from '../../dialogs/edit-profile-dialog/edit-profile-dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AddComicDialogComponent } from 'src/app/dialogs/add-comic-dialog/add-comic-dialog.component';
import { ChangeProfilePictureDialogComponent } from 'src/app/dialogs/change-profile-picture-dialog/change-profile-picture-dialog.component';
import { AddCollectibleDialogComponent } from 'src/app/dialogs/add-collectible-dialog/add-collectible-dialog.component';
import { ComicService } from 'src/app/services/comic.service';
import { CollectibleService } from 'src/app/services/collectible.service';
import {
  trigger,
  transition,
  style,
  animate,
  state,
} from '@angular/animations';
import { ViewProductDialogComponent } from 'src/app/dialogs/view-product-dialog/view-product-dialog.component';
import { ViewToyDialogComponent } from 'src/app/dialogs/view-toy-dialog/view-toy-dialog.component';
import {
  setAllComics,
  selectedComic,
} from 'src/app/redux/actions/comic.actions';
import { addThread, selectThread } from '../../redux/actions/message.actions';
import {
  setAllCollectibles,
  selectedCollectible,
} from 'src/app/redux/actions/collecible.actions';
import { setRoute } from '../../redux/actions/router.actions';
import { goToUserProfile } from 'src/app/redux/actions/user.actions';
import { MessengerService } from 'src/app/services/messenger.service';
import { takeUntil } from 'rxjs/operators';
import { updateUser } from 'src/app/redux/actions/auth.actions';
import { STRIPEID } from '../../../environments/environment.prod';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TransactionLogDialogComponent } from 'src/app/dialogs/transaction-log-dialog/transaction-log-dialog.component';

interface AppState {
  userProfile: User;
  auth: User;
  comics: any;
  messages: any;
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        state('invisible', style({ opacity: 1 })),
        style({ opacity: 0 }),
        animate(800, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ProfileComponent implements OnInit {
  /* || REDUX || */
  user: Observable<User>;
  userId;
  authUser;
  authUserId;
  /* || Profile Info || */
  public id;
  public aboutMeProfile;
  public firstName: string = null;
  public lastName: string = null;
  public aboutMe: string = null;
  public email: string = null;
  public profilePhoto: string = null;
  /* || Comics || */
  public unsortedComics;
  public mySellingComics;
  public myCollectionComics;
  public myTradeComics;
  public myWishlistComics;
  public myBacklogComics;
  /* || Empty Comics || */
  public noComicCollection: boolean;
  public noComicSelling: boolean;
  public noComicTrade: boolean;
  public noComicWishlist: boolean;
  public noComicBacklog: boolean;
  /* || Collectibles || */
  public unsortedCollectibles;
  public mySellingCollectibles;
  public myCollectionCollectibles;
  public myTradeCollectibles;
  public myWishlistCollectibles;
  public myBacklogCollectibles;
  /* || Empty Collectibles || */
  public noCollectiblesCollection: boolean;
  public noCollectiblesSelling: boolean;
  public noCollectiblesTrade: boolean;
  public noCollectiblesWishlist: boolean;
  public noCollectiblesBacklog: boolean;
  /* || Mat-Tab-Group || */
  public selectedTabIndex;
  /* || Button Toggle || */
  public selectedVal: string;
  /* || Checkbox Values || */
  public mySelling: boolean;
  public myCollection: boolean;
  public myTrade: boolean;
  public myBacklog: boolean;
  public myWishlist: boolean;
  /* || Redux Messages || */
  public threads: any;
  public threadId: any;
  /* || Misc || */
  public config;
  private onDestroy$ = new Subject<void>();
  // Pagination
  public collectionComicPage = 1;
  public collectionCollectiblePage = 1;
  public sellingComics = 1;
  public sellingCollectibles = 1;
  public tradingComics = 1;
  public tradingCollectibles = 1;
  public backlogComics = 1;
  public backlogCollectibles = 1;
  public wishlistComics = 1;
  public wishlistCollectibles = 1;
  // Search Filters For Comics
  public sellingComicUniverse: string;
  public sellingComicTitle: string;
  public sellingComicPublisher: string;
  public sellingComicSeries: string;
  public collectionComicUniverse: string;
  public collectionComicTitle: string;
  public collectionComicPublisher: string;
  public collectionComicSeries: string;
  public tradeComicUniverse: string;
  public tradeComicTitle: string;
  public tradeComicPublisher: string;
  public tradeComicSeries: string;
  public backlogComicUniverse: string;
  public backlogComicTitle: string;
  public backlogComicPublisher: string;
  public backlogComicSeries: string;
  public wishlistComicUniverse: string;
  public wishlistComicTitle: string;
  public wishlistComicPublisher: string;
  public wishlistComicSeries: string;
  // Select Drop Down Lists For Comics
  public sellingComicUniverseSelect;
  public sellingComicPublisherSelect;
  public collectionComicUniverseSelect;
  public collectionComicPublisherSelect;
  public tradeComicUniverseSelect;
  public tradeComicPublisherSelect;
  public backlogComicUniverseSelect;
  public backlogComicPublisherSelect;
  public wishlistComicUniverseSelect;
  public wishlistComicPublisherSelect;
  // Search Filters For Collectibles
  public sellingCollectibleUniverse: string;
  public sellingCollectibleName: string;
  public sellingCollectibleBrand: string;
  public sellingCollectibleSeries: string;
  public collectionCollectibleUniverse: string;
  public collectionCollectibleName: string;
  public collectionCollectibleBrand: string;
  public collectionCollectibleSeries: string;
  public tradeCollectibleUniverse: string;
  public tradeCollectibleName: string;
  public tradeCollectibleBrand: string;
  public tradeCollectibleSeries: string;
  public backlogCollectibleUniverse: string;
  public backlogCollectibleName: string;
  public backlogCollectibleBrand: string;
  public backlogCollectibleSeries: string;
  public wishlistCollectibleUniverse: string;
  public wishlistCollectibleName: string;
  public wishlistCollectibleBrand: string;
  public wishlistCollectibleSeries: string;
  // Select Drop Down Lists For Collectibles
  public sellingCollectibleUniverseSelect;
  public sellingCollectibleBrandSelect;
  public collectionCollectibleUniverseSelect;
  public collectionCollectibleBrandSelect;
  public tradeCollectibleUniverseSelect;
  public tradeCollectibleBrandSelect;
  public backlogCollectibleUniverseSelect;
  public backlogCollectibleBrandSelect;
  public wishlistCollectibleUniverseSelect;
  public wishlistCollectibleBrandSelect;
  // Form Control
  sellingComicsForm: FormGroup;
  collectionComicsForm: FormGroup;
  tradeComicsForm: FormGroup;
  backlogComicsForm: FormGroup;
  wishlistComicsForm: FormGroup;
  sellingCollectiblesForm: FormGroup;
  collectionCollectiblesForm: FormGroup;
  tradeCollectiblesForm: FormGroup;
  backlogCollectiblesForm: FormGroup;
  wishlistCollectiblesForm: FormGroup;
  // Comic Values
  sellComicsValues = {
    universe: '',
    series: '',
    title: '',
    publisher: '',
  };
  collectionComicsValues = {
    collectionUniverse: '',
    collectionSeries: '',
    collectionTitle: '',
    collectionPublisher: '',
  };
  tradeComicsValues = {
    tradeUniverse: '',
    tradeSeries: '',
    tradeTitle: '',
    tradePublisher: '',
  };
  backlogComicsValues = {
    backlogUniverse: '',
    backlogSeries: '',
    backlogTitle: '',
    backlogPublisher: '',
  };
  wishlistComicsValues = {
    wishlistUniverse: '',
    wishlistSeries: '',
    wishlistTitle: '',
    wishlistPublisher: '',
  };
  // Collectible Values
  sellCollectiblesValues = {
    toyUniverse: '',
    toySeries: '',
    toyName: '',
    toyBrand: '',
  };
  collectionCollectiblesValues = {
    collectionToyUniverse: '',
    collectionToySeries: '',
    collectionToyName: '',
    collectionToyBrand: '',
  };
  tradeCollectiblesValues = {
    tradeToyUniverse: '',
    tradeToySeries: '',
    tradeToyName: '',
    tradeToyBrand: '',
  };
  backlogCollectiblesValues = {
    backlogToyUniverse: '',
    backlogToySeries: '',
    backlogToyName: '',
    backlogToyBrand: '',
  };
  wishlistCollectiblesValues = {
    wishlistToyUniverse: '',
    wishlistToySeries: '',
    wishlistToyName: '',
    wishlistToyBrand: '',
  };

  public userProfile;
  public loading;

  constructor(
    public auth: AuthService,
    private store: Store<AppState>,
    private mdDialog: MatDialog,
    private router: Router,
    public comicService: ComicService,
    public collectibleService: CollectibleService,
    private matSnackbar: MatSnackBar,
    public messengerService: MessengerService,
    private fb: FormBuilder
  ) {
    this.loading = true;
    this.userProfile = window.location.pathname.split('/').pop();
    this.auth.getUserByUsername(this.userProfile).subscribe((res) => {
      const currentProfileUser: any = res;
      this.userId = currentProfileUser.id;
      this.store.dispatch(goToUserProfile({ user: currentProfileUser }));
      this.loading = false;
    });
    this.user = this.store.select('userProfile');
    this.store.select('auth').subscribe(res => {
      if (res) {
        this.authUser = res;
        this.authUserId = res.id;
      }
    });

    if (!this.authUser) {
      this.authUser = 'GuestUser';
    }

    this.sellingComicsForm = fb.group({
      universe: '',
      series: '',
      title: '',
      publisher: '',
    });
    this.collectionComicsForm = fb.group({
      collectionUniverse: '',
      collectionSeries: '',
      collectionTitle: '',
      collectionPublisher: '',
    });
    this.tradeComicsForm = fb.group({
      tradeUniverse: '',
      tradeSeries: '',
      tradeTitle: '',
      tradePublisher: '',
    });
    this.backlogComicsForm = fb.group({
      backlogUniverse: '',
      backlogSeries: '',
      backlogTitle: '',
      backlogPublisher: '',
    });
    this.wishlistComicsForm = fb.group({
      wishlistUniverse: '',
      wishlistSeries: '',
      wishlistTitle: '',
      wishlistPublisher: '',
    });

    this.sellingCollectiblesForm = fb.group({
      toyUniverse: '',
      toySeries: '',
      toyName: '',
      toyBrand: '',
    });
    this.collectionCollectiblesForm = fb.group({
      collectionToyUniverse: '',
      collectionToySeries: '',
      collectionToyName: '',
      collectionToyBrand: '',
    });
    this.tradeCollectiblesForm = fb.group({
      tradeToyUniverse: '',
      tradeToySeries: '',
      tradeToyName: '',
      tradeToyBrand: '',
    });
    this.backlogCollectiblesForm = fb.group({
      backlogToyUniverse: '',
      backlogToySeries: '',
      backlogToyName: '',
      backlogToyBrand: '',
    });
    this.wishlistCollectiblesForm = fb.group({
      wishlistToyUniverse: '',
      wishlistToySeries: '',
      wishlistToyName: '',
      wishlistToyBrand: '',
    });
  }

  async ngOnInit() {
    // This can be used to not abuse the life cycle hooks.
    await this.refreshProfilePage();
    this.store.dispatch(setRoute({ route: '/profile' }));
    if (this.authUser !== 'GuestUser') {
      this.store.select('messages').pipe(takeUntil(this.onDestroy$)).subscribe(res => {
        if (res) {
          this.threads = res.threads;
        }
      });
    }
    this.goToTop();
    // window.scrollTo(0, 0);
  }

  public async refreshProfilePage() {
    this.config = STRIPEID;
    // COMICS
    this.noComicSelling = false;
    this.noComicCollection = false;
    this.noComicTrade = false;
    this.noComicWishlist = false;
    // COLLECTIBLES
    this.noCollectiblesSelling = false;
    this.noCollectiblesCollection = false;
    this.noCollectiblesTrade = false;
    this.noCollectiblesWishlist = false;
    this.mySelling = true;
    await this.user
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((user) => (this.userId = user.id));
    if (this.authUser !== 'GuestUser') {
      this.aboutMeProfile = this.authUser;
      this.firstName = this.authUser.firstName;
      this.lastName = this.authUser.lastName;
      this.aboutMe = this.authUser.aboutMe;
      this.profilePhoto = this.authUser.profilePhoto;
      this.email = this.authUser.email;
    }
    await this.getAllComics();
    await this.getAllCollectibles();
    // Selecting what value of Mat-Tabs to display.
    if (this.selectedVal === undefined) {
      this.selectedVal = 'collection';
    }
    if (this.selectedVal === 'selling') {
      this.openSellPage();
    }
    if (this.selectedVal === 'collection') {
      this.openMyCollectionPage();
    }
    if (this.selectedVal === 'trade') {
      this.openMyTradePage();
    }
    if (this.selectedVal === 'wishlist') {
      this.openMyWishlist();
    }
    if (this.selectedVal === 'empty') {
      this.openMyBacklog();
    }
    this.store.dispatch(updateUser({ user: this.aboutMeProfile }));
  }
  // Call to refresh the page
  public update() {
    this.refreshProfilePage();
  }
  // handles selectedVal changes
  public onValChange(val: string) {
    this.selectedVal = val;
  }

  public getAllComics() {
    this.comicService
      .getAllComics()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        if (res) {
          this.unsortedComics = res;
          this.store.dispatch(
            setAllComics({ comics: this.unsortedComics.comics })
          );
          const collection = this.unsortedComics.comics.filter((el) => {
            // If you are visiting your own profile page.
            if (
              el.ownerId === this.authUserId &&
              this.userId === this.authUserId &&
              el.collection === true
            ) {
              return el.images[0] != null;
            }
            // If you are visitng another persons profile page.
            if (
              el.ownerId === this.userId &&
              this.userId !== this.authUserId &&
              el.collection === true
            ) {
              return el.images[0] != null;
            }
          });
          const selling = this.unsortedComics.comics.filter((el) => {
            // If you are visiting your own profile page.
            if (
              el.ownerId === this.authUserId &&
              this.userId === this.authUserId &&
              el.quantity > 0 &&
              el.sell === true
            ) {
              return el.images[0] != null;
            }
            // If you are visitng another persons profile page.
            if (
              el.ownerId === this.userId &&
              this.userId !== this.authUserId &&
              el.quantity > 0 &&
              el.sell === true
            ) {
              return el.images[0] != null;
            }
          });
          const trade = this.unsortedComics.comics.filter((el) => {
            // If you are visiting your own profile page.
            if (
              el.ownerId === this.authUserId &&
              this.userId === this.authUserId &&
              el.trade === true
            ) {
              return el.images[0] != null;
            }
            // If you are visitng another persons profile page.
            if (
              el.ownerId === this.userId &&
              this.userId !== this.authUserId &&
              el.trade === true
            ) {
              return el.images[0] != null;
            }
          });
          const wishlist = this.unsortedComics.comics.filter((el) => {
            // If you are visiting your own profile page.
            if (
              el.ownerId === this.authUserId &&
              this.userId === this.authUserId &&
              el.wishlist === true
            ) {
              return el.images[0] != null;
            }
            // If you are visitng another persons profile page.
            if (
              el.ownerId === this.userId &&
              this.userId !== this.authUserId &&
              el.wishlist === true
            ) {
              return el.images[0] != null;
            }
          });
          const backlog = this.unsortedComics.comics.filter((el) => {
            // If you are visiting your own profile page.
            if (
              el.ownerId === this.authUserId &&
              this.userId === this.authUserId &&
              el.sell === true &&
              el.quantity <= 0
            ) {
              return el.images[0] != null;
            }
          });
          // If you own any comics return this object
          if (selling) {
            this.mySellingComics = selling.sort((a, b) => {
              const sellingA = a.universe.toUpperCase();
              const sellingB = b.universe.toUpperCase();
              if (sellingA === sellingB) {
                if (a.series === b.series) {
                  return a.issue.localeCompare(b.issue, undefined, {
                    numeric: true,
                  });
                }
                return a.series < b.series ? -1 : a.series > b.series ? 1 : 0;
              }
              return sellingA < sellingB ? -1 : sellingA > sellingB ? 1 : 0;
            });
            this.sellingComicPublisherSelect = selling.filter((v, i, a) => {
              if (
                v.ownerId === this.authUserId &&
                this.userId === this.authUserId &&
                v.quantity > 0 &&
                v.sell === true
              ) {
                return (
                  a.findIndex(
                    (t) =>
                      t.publisher.toString().trim().toLowerCase() ===
                      v.publisher.toString().trim().toLowerCase()
                  ) === i
                );
              }
              // If you are visitng another persons profile page.
              if (
                v.ownerId === this.userId &&
                this.userId !== this.authUserId &&
                v.quantity > 0 &&
                v.sell === true
              ) {
                return (
                  a.findIndex(
                    (t) =>
                      t.publisher.toString().trim().toLowerCase() ===
                      v.publisher.toString().trim().toLowerCase()
                  ) === i
                );
              }
            });
            this.sellingComicUniverseSelect = selling.filter((v, i, a) => {
              if (
                v.ownerId === this.authUserId &&
                this.userId === this.authUserId &&
                v.quantity > 0 &&
                v.sell === true
              ) {
                return (
                  a.findIndex(
                    (t) =>
                      t.universe.toString().trim().toLowerCase() ===
                      v.universe.toString().trim().toLowerCase()
                  ) === i
                );
              }
              // If you are visitng another persons profile page.
              if (
                v.ownerId === this.userId &&
                this.userId !== this.authUserId &&
                v.quantity > 0 &&
                v.sell === true
              ) {
                return (
                  a.findIndex(
                    (t) =>
                      t.universe.toString().trim().toLowerCase() ===
                      v.universe.toString().trim().toLowerCase()
                  ) === i
                );
              }
            });
          }

          if (collection) {
            this.myCollectionComics = collection.sort((a, b) => {
              const collectionA = a.universe.toUpperCase();
              const collectionB = b.universe.toUpperCase();
              if (collectionA === collectionB) {
                if (a.series === b.series) {
                  return a.issue.localeCompare(b.issue, undefined, {
                    numeric: true,
                  });
                }
                return a.series < b.series ? -1 : a.series > b.series ? 1 : 0;
              }
              return collectionA < collectionB
                ? -1
                : collectionA > collectionB
                  ? 1
                  : 0;
            });
            this.collectionComicPublisherSelect = collection.filter(
              (v, i, a) => {
                if (
                  v.ownerId === this.authUserId &&
                  this.userId === this.authUserId &&
                  v.collection === true
                ) {
                  return (
                    a.findIndex(
                      (t) =>
                        t.publisher.toString().trim().toLowerCase() ===
                        v.publisher.toString().trim().toLowerCase()
                    ) === i
                  );
                }
                // If you are visitng another persons profile page.
                if (
                  v.ownerId === this.userId &&
                  this.userId !== this.authUserId &&
                  v.collection === true
                ) {
                  return (
                    a.findIndex(
                      (t) =>
                        t.publisher.toString().trim().toLowerCase() ===
                        v.publisher.toString().trim().toLowerCase()
                    ) === i
                  );
                }
              }
            );
            this.collectionComicUniverseSelect = collection.filter(
              (v, i, a) => {
                if (
                  v.ownerId === this.authUserId &&
                  this.userId === this.authUserId &&
                  v.collection === true
                ) {
                  return (
                    a.findIndex(
                      (t) =>
                        t.universe.toString().trim().toLowerCase() ===
                        v.universe.toString().trim().toLowerCase()
                    ) === i
                  );
                }
                // If you are visitng another persons profile page.
                if (
                  v.ownerId === this.userId &&
                  this.userId !== this.authUserId &&
                  v.collection === true
                ) {
                  return (
                    a.findIndex(
                      (t) =>
                        t.universe.toString().trim().toLowerCase() ===
                        v.universe.toString().trim().toLowerCase()
                    ) === i
                  );
                }
              }
            );
          }

          if (trade) {
            this.myTradeComics = trade.sort((a, b) => {
              const tradeA = a.universe.toUpperCase();
              const tradeB = b.universe.toUpperCase();
              if (tradeA === tradeB) {
                if (a.series === b.series) {
                  return a.issue.localeCompare(b.issue, undefined, {
                    numeric: true,
                  });
                }
                return a.series < b.series ? -1 : a.series > b.series ? 1 : 0;
              }
              return tradeA < tradeB ? -1 : tradeA > tradeB ? 1 : 0;
            });
            this.tradeComicPublisherSelect = trade.filter((v, i, a) => {
              if (
                v.ownerId === this.authUserId &&
                this.userId === this.authUserId &&
                v.trade === true
              ) {
                return (
                  a.findIndex(
                    (t) =>
                      t.publisher.toString().trim().toLowerCase() ===
                      v.publisher.toString().trim().toLowerCase()
                  ) === i
                );
              }
              // If you are visitng another persons profile page.
              if (
                v.ownerId === this.userId &&
                this.userId !== this.authUserId &&
                v.trade === true
              ) {
                return (
                  a.findIndex(
                    (t) =>
                      t.publisher.toString().trim().toLowerCase() ===
                      v.publisher.toString().trim().toLowerCase()
                  ) === i
                );
              }
            });
            this.tradeComicUniverseSelect = trade.filter((v, i, a) => {
              if (
                v.ownerId === this.authUserId &&
                this.userId === this.authUserId &&
                v.trade === true
              ) {
                return (
                  a.findIndex(
                    (t) =>
                      t.universe.toString().trim().toLowerCase() ===
                      v.universe.toString().trim().toLowerCase()
                  ) === i
                );
              }
              // If you are visitng another persons profile page.
              if (
                v.ownerId === this.userId &&
                this.userId !== this.authUserId &&
                v.trade === true
              ) {
                return (
                  a.findIndex(
                    (t) =>
                      t.universe.toString().trim().toLowerCase() ===
                      v.universe.toString().trim().toLowerCase()
                  ) === i
                );
              }
            });
          }

          if (wishlist) {
            this.myWishlistComics = wishlist.sort((a, b) => {
              const wishlistA = a.universe.toUpperCase();
              const wishlistB = b.universe.toUpperCase();
              if (wishlistA === wishlistB) {
                if (a.series === b.series) {
                  return a.issue.localeCompare(b.issue, undefined, {
                    numeric: true,
                  });
                }
                return a.series < b.series ? -1 : a.series > b.series ? 1 : 0;
              }
              return wishlistA < wishlistB ? -1 : wishlistA > wishlistB ? 1 : 0;
            });
            this.wishlistComicPublisherSelect = wishlist.filter((v, i, a) => {
              if (
                v.ownerId === this.authUserId &&
                this.userId === this.authUserId &&
                v.wishlist === true
              ) {
                return (
                  a.findIndex(
                    (t) =>
                      t.publisher.toString().trim().toLowerCase() ===
                      v.publisher.toString().trim().toLowerCase()
                  ) === i
                );
              }
              // If you are visitng another persons profile page.
              if (
                v.ownerId === this.userId &&
                this.userId !== this.authUserId &&
                v.wishlist === true
              ) {
                return (
                  a.findIndex(
                    (t) =>
                      t.publisher.toString().trim().toLowerCase() ===
                      v.publisher.toString().trim().toLowerCase()
                  ) === i
                );
              }
            });
            this.wishlistComicUniverseSelect = wishlist.filter((v, i, a) => {
              if (
                v.ownerId === this.authUserId &&
                this.userId === this.authUserId &&
                v.wishlist === true
              ) {
                return (
                  a.findIndex(
                    (t) =>
                      t.universe.toString().trim().toLowerCase() ===
                      v.universe.toString().trim().toLowerCase()
                  ) === i
                );
              }
              // If you are visitng another persons profile page.
              if (
                v.ownerId === this.userId &&
                this.userId !== this.authUserId &&
                v.wishlist === true
              ) {
                return (
                  a.findIndex(
                    (t) =>
                      t.universe.toString().trim().toLowerCase() ===
                      v.universe.toString().trim().toLowerCase()
                  ) === i
                );
              }
            });
          }

          if (backlog) {
            this.myBacklogComics = backlog.sort((a, b) => {
              const backlogA = a.universe.toUpperCase();
              const backlogB = b.universe.toUpperCase();
              if (backlogA === backlogB) {
                if (a.series === b.series) {
                  return a.issue.localeCompare(b.issue, undefined, {
                    numeric: true,
                  });
                }
                return a.series < b.series ? -1 : a.series > b.series ? 1 : 0;
              }
              return backlogA < backlogB ? -1 : backlogA > backlogB ? 1 : 0;
            });
            this.backlogComicPublisherSelect = backlog.filter((v, i, a) => {
              if (
                v.ownerId === this.authUserId &&
                this.userId === this.authUserId &&
                v.sell === true &&
                v.quantity <= 0
              ) {
                return (
                  a.findIndex(
                    (t) =>
                      t.publisher.toString().trim().toLowerCase() ===
                      v.publisher.toString().trim().toLowerCase()
                  ) === i
                );
              }
            });
            this.backlogComicUniverseSelect = backlog.filter((v, i, a) => {
              if (
                v.ownerId === this.authUserId &&
                this.userId === this.authUserId &&
                v.sell === true &&
                v.quantity <= 0
              ) {
                return (
                  a.findIndex(
                    (t) =>
                      t.universe.toString().trim().toLowerCase() ===
                      v.universe.toString().trim().toLowerCase()
                  ) === i
                );
              }
            });
          }
          // If you don't have any comics associated return this boolean.
          if (selling.length === 0) {
            this.noComicSelling = true;
          }
          if (collection.length === 0) {
            this.noComicCollection = true;
          }
          if (trade.length === 0) {
            this.noComicTrade = true;
          }
          if (wishlist.length === 0) {
            this.noComicWishlist = true;
          }
          if (backlog.length === 0) {
            this.noComicBacklog = true;
          }
        }
      });
  }

  public getAllCollectibles() {
    this.collectibleService
      .getAllCollectibles()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        if (res) {
          this.unsortedCollectibles = res;
          this.store.dispatch(
            setAllCollectibles({
              collectibles: this.unsortedCollectibles.collectibles,
            })
          );
          const collection = this.unsortedCollectibles.collectibles.filter(
            (toy) => {
              if (
                toy.ownerId === this.authUserId &&
                this.userId === this.authUserId &&
                toy.collection === true
              ) {
                return toy.images[0] != null;
              }
              // If you are visitng another persons profile page.
              if (
                toy.ownerId === this.userId &&
                this.userId !== this.authUserId &&
                toy.collection === true
              ) {
                return toy.images[0] != null;
              }
            }
          );
          const selling = this.unsortedCollectibles.collectibles.filter(
            (toy) => {
              if (
                toy.ownerId === this.authUserId &&
                this.userId === this.authUserId &&
                toy.sell === true &&
                toy.quantity > 0
              ) {
                return toy.images[0] != null;
              }
              // If you are visitng another persons profile page.
              if (
                toy.ownerId === this.userId &&
                this.userId !== this.authUserId &&
                toy.quantity > 0 &&
                toy.sell === true
              ) {
                return toy.images[0] != null;
              }
            }
          );
          const trade = this.unsortedCollectibles.collectibles.filter((toy) => {
            if (
              toy.ownerId === this.authUserId &&
              this.userId === this.authUserId &&
              toy.trade === true
            ) {
              return toy.images[0] != null;
            }
            // If you are visitng another persons profile page.
            if (
              toy.ownerId === this.userId &&
              this.userId !== this.authUserId &&
              toy.trade === true
            ) {
              return toy.images[0] != null;
            }
          });
          const wishlist = this.unsortedCollectibles.collectibles.filter(
            (toy) => {
              if (
                toy.ownerId === this.authUserId &&
                this.userId === this.authUserId &&
                toy.wishlist === true
              ) {
                return toy.images[0] != null;
              }
              // If you are visitng another persons profile page.
              if (
                toy.ownerId === this.userId &&
                this.userId !== this.authUserId &&
                toy.wishlist === true
              ) {
                return toy.images[0] != null;
              }
            }
          );
          const backlog = this.unsortedCollectibles.collectibles.filter(
            (toy) => {
              if (
                toy.ownerId === this.authUserId &&
                this.userId === this.authUserId &&
                toy.sell === true &&
                toy.quantity <= 0
              ) {
                return toy.images[0] != null;
              }
            }
          );
          // If you own any collectibles return this object
          if (collection) {
            this.myCollectionCollectibles = collection.sort((a, b) => {
              const collectionCollectA = a.universe.toUpperCase();
              const collectionCollectB = b.universe.toUpperCase();
              if (collectionCollectA === collectionCollectB) {
                if (a.series === b.series) {
                  return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
                }
                return a.series < b.series ? -1 : a.series > b.series ? 1 : 0;
              }
              return collectionCollectA < collectionCollectB
                ? -1
                : collectionCollectA > collectionCollectB
                  ? 1
                  : 0;
            });
            this.collectionCollectibleBrandSelect = collection.filter(
              (v, i, a) => {
                if (
                  v.ownerId === this.authUserId &&
                  this.userId === this.authUserId &&
                  v.collection === true
                ) {
                  return (
                    a.findIndex(
                      (t) =>
                        t.brand.toString().trim().toLowerCase() ===
                        v.brand.toString().trim().toLowerCase()
                    ) === i
                  );
                }
                // If you are visitng another persons profile page.
                if (
                  v.ownerId === this.userId &&
                  this.userId !== this.authUserId &&
                  v.collection === true
                ) {
                  return (
                    a.findIndex(
                      (t) =>
                        t.brand.toString().trim().toLowerCase() ===
                        v.brand.toString().trim().toLowerCase()
                    ) === i
                  );
                }
              }
            );

            this.collectionCollectibleUniverseSelect = collection.filter(
              (v, i, a) => {
                if (
                  v.ownerId === this.authUserId &&
                  this.userId === this.authUserId &&
                  v.collection === true
                ) {
                  return (
                    a.findIndex(
                      (t) =>
                        t.universe.toString().trim().toLowerCase() ===
                        v.universe.toString().trim().toLowerCase()
                    ) === i
                  );
                }
                // If you are visitng another persons profile page.
                if (
                  v.ownerId === this.userId &&
                  this.userId !== this.authUserId &&
                  v.collection === true
                ) {
                  return (
                    a.findIndex(
                      (t) =>
                        t.universe.toString().trim().toLowerCase() ===
                        v.universe.toString().trim().toLowerCase()
                    ) === i
                  );
                }
              }
            );
          }

          if (selling) {
            this.mySellingCollectibles = selling.sort((a, b) => {
              const sellingCollectA = a.universe.toUpperCase();
              const sellingCollectB = b.universe.toUpperCase();
              if (sellingCollectA === sellingCollectB) {
                if (a.series === b.series) {
                  return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
                }
                return a.series < b.series ? -1 : a.series > b.series ? 1 : 0;
              }
              return sellingCollectA < sellingCollectB
                ? -1
                : sellingCollectA > sellingCollectB
                  ? 1
                  : 0;
            });
            this.sellingCollectibleBrandSelect = selling.filter((v, i, a) => {
              if (
                v.ownerId === this.authUserId &&
                this.userId === this.authUserId &&
                v.sell === true &&
                v.quantity > 0
              ) {
                return (
                  a.findIndex(
                    (t) =>
                      t.brand.toString().trim().toLowerCase() ===
                      v.brand.toString().trim().toLowerCase()
                  ) === i
                );
              }
              // If you are visitng another persons profile page.
              if (
                v.ownerId === this.userId &&
                this.userId !== this.authUserId &&
                v.quantity > 0 &&
                v.sell === true
              ) {
                return (
                  a.findIndex(
                    (t) =>
                      t.brand.toString().trim().toLowerCase() ===
                      v.brand.toString().trim().toLowerCase()
                  ) === i
                );
              }
            });
            this.sellingCollectibleUniverseSelect = selling.filter(
              (v, i, a) => {
                if (
                  v.ownerId === this.authUserId &&
                  this.userId === this.authUserId &&
                  v.sell === true &&
                  v.quantity > 0
                ) {
                  return (
                    a.findIndex(
                      (t) =>
                        t.universe.toString().trim().toLowerCase() ===
                        v.universe.toString().trim().toLowerCase()
                    ) === i
                  );
                }
                // If you are visitng another persons profile page.
                if (
                  v.ownerId === this.userId &&
                  this.userId !== this.authUserId &&
                  v.quantity > 0 &&
                  v.sell === true
                ) {
                  return (
                    a.findIndex(
                      (t) =>
                        t.universe.toString().trim().toLowerCase() ===
                        v.universe.toString().trim().toLowerCase()
                    ) === i
                  );
                }
              }
            );
          }

          if (trade) {
            this.myTradeCollectibles = trade.sort((a, b) => {
              const tradeCollectA = a.universe.toUpperCase();
              const tradeCollectB = b.universe.toUpperCase();
              if (tradeCollectA === tradeCollectB) {
                if (a.series === b.series) {
                  return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
                }
                return a.series < b.series ? -1 : a.series > b.series ? 1 : 0;
              }
              return tradeCollectA < tradeCollectB
                ? -1
                : tradeCollectA > tradeCollectB
                  ? 1
                  : 0;
            });
            this.tradeCollectibleBrandSelect = trade.filter((v, i, a) => {
              if (
                v.ownerId === this.authUserId &&
                this.userId === this.authUserId &&
                v.trade === true
              ) {
                return (
                  a.findIndex(
                    (t) =>
                      t.brand.toString().trim().toLowerCase() ===
                      v.brand.toString().trim().toLowerCase()
                  ) === i
                );
              }
              // If you are visitng another persons profile page.
              if (
                v.ownerId === this.userId &&
                this.userId !== this.authUserId &&
                v.trade === true
              ) {
                return (
                  a.findIndex(
                    (t) =>
                      t.brand.toString().trim().toLowerCase() ===
                      v.brand.toString().trim().toLowerCase()
                  ) === i
                );
              }
            });
            this.tradeCollectibleUniverseSelect = trade.filter((v, i, a) => {
              if (
                v.ownerId === this.authUserId &&
                this.userId === this.authUserId &&
                v.trade === true
              ) {
                return (
                  a.findIndex(
                    (t) =>
                      t.universe.toString().trim().toLowerCase() ===
                      v.universe.toString().trim().toLowerCase()
                  ) === i
                );
              }
              // If you are visitng another persons profile page.
              if (
                v.ownerId === this.userId &&
                this.userId !== this.authUserId &&
                v.trade === true
              ) {
                return (
                  a.findIndex(
                    (t) =>
                      t.universe.toString().trim().toLowerCase() ===
                      v.universe.toString().trim().toLowerCase()
                  ) === i
                );
              }
            });
          }

          if (wishlist) {
            this.myWishlistCollectibles = wishlist.sort((a, b) => {
              const wishlistCollectA = a.universe.toUpperCase();
              const wishlistCollectB = b.universe.toUpperCase();
              if (wishlistCollectA === wishlistCollectB) {
                if (a.series === b.series) {
                  return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
                }
                return a.series < b.series ? -1 : a.series > b.series ? 1 : 0;
              }
              return wishlistCollectA < wishlistCollectB
                ? -1
                : wishlistCollectA > wishlistCollectB
                  ? 1
                  : 0;
            });
            this.wishlistCollectibleBrandSelect = wishlist.filter((v, i, a) => {
              if (
                v.ownerId === this.authUserId &&
                this.userId === this.authUserId &&
                v.wishlist === true
              ) {
                return (
                  a.findIndex(
                    (t) =>
                      t.brand.toString().trim().toLowerCase() ===
                      v.brand.toString().trim().toLowerCase()
                  ) === i
                );
              }
              // If you are visitng another persons profile page.
              if (
                v.ownerId === this.userId &&
                this.userId !== this.authUserId &&
                v.wishlist === true
              ) {
                return (
                  a.findIndex(
                    (t) =>
                      t.brand.toString().trim().toLowerCase() ===
                      v.brand.toString().trim().toLowerCase()
                  ) === i
                );
              }
            });
            this.wishlistCollectibleUniverseSelect = wishlist.filter(
              (v, i, a) => {
                if (
                  v.ownerId === this.authUserId &&
                  this.userId === this.authUserId &&
                  v.wishlist === true
                ) {
                  return (
                    a.findIndex(
                      (t) =>
                        t.universe.toString().trim().toLowerCase() ===
                        v.universe.toString().trim().toLowerCase()
                    ) === i
                  );
                }
                // If you are visitng another persons profile page.
                if (
                  v.ownerId === this.userId &&
                  this.userId !== this.authUserId &&
                  v.wishlist === true
                ) {
                  return (
                    a.findIndex(
                      (t) =>
                        t.universe.toString().trim().toLowerCase() ===
                        v.universe.toString().trim().toLowerCase()
                    ) === i
                  );
                }
              }
            );
          }

          if (backlog) {
            this.myBacklogCollectibles = backlog.sort((a, b) => {
              const backlogCollectA = a.universe.toUpperCase();
              const backlogCollectB = b.universe.toUpperCase();
              if (backlogCollectA === backlogCollectB) {
                if (a.series === b.series) {
                  return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
                }
                return a.series < b.series ? -1 : a.series > b.series ? 1 : 0;
              }
              return backlogCollectA < backlogCollectB
                ? -1
                : backlogCollectA > backlogCollectB
                  ? 1
                  : 0;
            });
            this.backlogCollectibleBrandSelect = backlog.filter((v, i, a) => {
              if (
                v.ownerId === this.authUserId &&
                this.userId === this.authUserId &&
                v.sell === true &&
                v.quantity <= 0
              ) {
                return (
                  a.findIndex(
                    (t) =>
                      t.brand.toString().trim().toLowerCase() ===
                      v.brand.toString().trim().toLowerCase()
                  ) === i
                );
              }
            });
            this.backlogCollectibleUniverseSelect = backlog.filter(
              (v, i, a) => {
                if (
                  v.ownerId === this.authUserId &&
                  this.userId === this.authUserId &&
                  v.sell === true &&
                  v.quantity <= 0
                ) {
                  return (
                    a.findIndex(
                      (t) =>
                        t.universe.toString().trim().toLowerCase() ===
                        v.universe.toString().trim().toLowerCase()
                    ) === i
                  );
                }
              }
            );
          }
          // If you don't have any collectibles associated return this boolean.
          if (selling.length === 0) {
            this.noCollectiblesSelling = true;
          }
          if (collection.length === 0) {
            this.noCollectiblesCollection = true;
          }
          if (trade.length === 0) {
            this.noCollectiblesTrade = true;
          }
          if (wishlist.length === 0) {
            this.noCollectiblesWishlist = true;
          }
          if (backlog.length === 0) {
            this.noCollectiblesBacklog = true;
          }
        }
      });
  }

  async goToMyProfile() {
    this.store.dispatch(goToUserProfile({ user: this.aboutMeProfile }));
    this.router.navigateByUrl(`/profile/${this.aboutMeProfile.username}`);

    this.update();
  }

  goToBrowse() {
    this.router.navigateByUrl('/browse');
  }

  async goToMessenger() {
    const existing = this.threads.find(
      (x) => x.userOneId === this.userId || x.userTwoId === this.userId
    );
    this.threadId = existing ? existing.id : null;

    const threadId = existing ? existing.id : null;

    if (!threadId) {
      const thread: any = {
        id: 0,
        messages: [],
      };

      this.user.pipe(takeUntil(this.onDestroy$)).subscribe((user) => {
        thread.userTwo = user;
        thread.userTwoId = user.id;
      });
      this.authUser.pipe(takeUntil(this.onDestroy$)).subscribe((user) => {
        thread.userOne = user;
        thread.userOneId = user.id;
      });
      await this.store.dispatch(addThread({ thread }));
      await this.store.dispatch(selectThread({ threadId: thread.id }));
      this.router.navigateByUrl('/messenger');
    } else {
      await this.store.dispatch(selectThread(threadId));
      this.router.navigateByUrl('/messenger');
    }
  }

  goToEditProfile() {
    const dialogRef = this.mdDialog.open(EditProfileDialogComponent, {
      data: {
        profile: this.aboutMeProfile,
      },
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        this.update();
      });
  }

  goToTransactionLog() {
    const dialogRef = this.mdDialog.open(TransactionLogDialogComponent, {
      data: this.aboutMeProfile,
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        this.update();
      });
  }

  goToUpdatePhoto() {
    const dialogRef = this.mdDialog.open(ChangeProfilePictureDialogComponent, {
      data: {
        userId: this.authUserId,
        profile: this.aboutMeProfile,
      },
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => { });
  }

  goToAddComics() {
    const dialogRef = this.mdDialog.open(AddComicDialogComponent, {
      data: {
        userId: this.authUserId,
        profile: this.aboutMeProfile,
      },
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result === undefined) {
          return [];
        }
        if (result.comic.sell === true) {
          this.selectedVal = 'selling';
          this.matSnackbar.open(
            `You just gained 5 points for adding a Comic! NEW Collector Score: ${this.aboutMeProfile.rewards}`,
            'OK',
            {
              duration: 5000,
              panelClass: ['cvSnack'],
            }
          );
          this.update();
        }
        if (result.comic.collection === true) {
          this.selectedVal = 'collection';
          this.matSnackbar.open(
            `You just gained 5 points for adding a Comic! NEW Collector Score: ${this.aboutMeProfile.rewards}`,
            'OK',
            {
              duration: 5000,
              panelClass: ['cvSnack'],
            }
          );
          this.update();
        }
        if (result.comic.trade === true) {
          this.selectedVal = 'trade';
          this.matSnackbar.open(
            `You just gained 5 points for adding a Comic! NEW Collector Score: ${this.aboutMeProfile.rewards}`,
            'OK',
            {
              duration: 5000,
              panelClass: ['cvSnack'],
            }
          );
          this.update();
        }
        if (result.comic.wishlist === true) {
          this.selectedVal = 'wishlist';
          this.matSnackbar.open(
            `You just gained 5 points for adding a Comic! NEW Collector Score: ${this.aboutMeProfile.rewards}`,
            'OK',
            {
              duration: 5000,
              panelClass: ['cvSnack'],
            }
          );
          this.update();
        }
      });
  }

  goToAddCollectible() {
    const dialogRef = this.mdDialog.open(AddCollectibleDialogComponent, {
      data: {
        userId: this.authUserId,
        profile: this.aboutMeProfile,
      },
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        this.update();
        this.selectedTabIndex = 1;
        if (result === undefined) {
          return [];
        }
        if (result.collectible.sell === true) {
          this.selectedVal = 'selling';
          this.matSnackbar.open(
            `You just gained 5 points for adding a Collectible! NEW Score: ${this.aboutMeProfile.rewards}`,
            'OK',
            {
              duration: 5000,
              panelClass: ['cvSnack'],
            }
          );
          this.update();
        }
        if (result.collectible.collection === true) {
          this.selectedVal = 'collection';
          this.matSnackbar.open(
            `You just gained 5 points for adding a Collectible! NEW Score: ${this.aboutMeProfile.rewards}`,
            'OK',
            {
              duration: 5000,
              panelClass: ['cvSnack'],
            }
          );
          this.update();
        }
        if (result.collectible.trade === true) {
          this.selectedVal = 'trade';
          this.matSnackbar.open(
            `You just gained 5 points for adding a Collectible! NEW Score: ${this.aboutMeProfile.rewards}`,
            'OK',
            {
              duration: 5000,
              panelClass: ['cvSnack'],
            }
          );
          this.update();
        }
        if (result.collectible.wishlist === true) {
          this.selectedVal = 'wishlist';
          this.matSnackbar.open(
            `You just gained 5 points for adding a Collectible! NEW Score: ${this.aboutMeProfile.rewards}`,
            'OK',
            {
              duration: 5000,
              panelClass: ['cvSnack'],
            }
          );
          this.update();
        }
      });
  }

  async goToViewProduct(chosenComic) {
    await this.store.dispatch(selectedComic({ id: chosenComic.id }));
    const dialogRef = this.mdDialog.open(ViewProductDialogComponent, {
      data: chosenComic,
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        this.update();
      });
  }

  goToViewToy(selectedToy) {
    this.store.dispatch(selectedCollectible({ id: selectedToy.id }));
    const dialogRef = this.mdDialog.open(ViewToyDialogComponent, {
      data: selectedToy,
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        this.update();
        this.selectedTabIndex = 1;
      });
  }

  // Mat-Tab Values onClick
  openSellPage() {
    this.mySelling = true;
    this.myTrade = false;
    this.myCollection = false;
    this.myBacklog = false;
    this.myWishlist = false;
  }
  openMyCollectionPage() {
    this.mySelling = false;
    this.myTrade = false;
    this.myCollection = true;
    this.myBacklog = false;
    this.myWishlist = false;
  }
  openMyTradePage() {
    this.mySelling = false;
    this.myTrade = true;
    this.myCollection = false;
    this.myBacklog = false;
    this.myWishlist = false;
  }
  openMyBacklog() {
    this.mySelling = false;
    this.myTrade = false;
    this.myCollection = false;
    this.myBacklog = true;
    this.myWishlist = false;
  }
  openMyWishlist() {
    this.mySelling = false;
    this.myTrade = false;
    this.myCollection = false;
    this.myBacklog = false;
    this.myWishlist = true;
  }

  // Clear Comics Filters
  async clearComicsSellingFilters() {
    this.sellComicsValues.universe = '';
    this.sellComicsValues.series = '';
    this.sellComicsValues.title = '';
    this.sellComicsValues.publisher = '';
    await this.sellingComicsForm.reset();
  }
  async clearComicsCollectionFilters() {
    this.collectionComicsValues.collectionUniverse = '';
    this.collectionComicsValues.collectionSeries = '';
    this.collectionComicsValues.collectionTitle = '';
    this.collectionComicsValues.collectionPublisher = '';
    await this.collectionComicsForm.reset();
  }
  async clearComicsTradeFilters() {
    this.tradeComicsValues.tradeUniverse = '';
    this.tradeComicsValues.tradeSeries = '';
    this.tradeComicsValues.tradeTitle = '';
    this.tradeComicsValues.tradePublisher = '';
    await this.tradeComicsForm.reset();
  }
  async clearComicsBacklogFilters() {
    this.backlogComicsValues.backlogUniverse = '';
    this.backlogComicsValues.backlogSeries = '';
    this.backlogComicsValues.backlogTitle = '';
    this.backlogComicsValues.backlogPublisher = '';
    await this.backlogComicsForm.reset();
  }
  async clearComicsWishlistFilters() {
    this.wishlistComicsValues.wishlistUniverse = '';
    this.wishlistComicsValues.wishlistSeries = '';
    this.wishlistComicsValues.wishlistTitle = '';
    this.wishlistComicsValues.wishlistPublisher = '';
    await this.wishlistComicsForm.reset();
  }
  // Clear Collectibles Filters
  async clearCollectiblesSellingFilters() {
    this.sellCollectiblesValues.toyUniverse = '';
    this.sellCollectiblesValues.toySeries = '';
    this.sellCollectiblesValues.toyName = '';
    this.sellCollectiblesValues.toyBrand = '';
    await this.sellingCollectiblesForm.reset();
  }
  async clearCollectiblesCollectionFilters() {
    this.collectionCollectiblesValues.collectionToyUniverse = '';
    this.collectionCollectiblesValues.collectionToySeries = '';
    this.collectionCollectiblesValues.collectionToyName = '';
    this.collectionCollectiblesValues.collectionToyBrand = '';
    await this.collectionCollectiblesForm.reset();
  }
  async clearCollectiblesTradeFilters() {
    this.tradeCollectiblesValues.tradeToyUniverse = '';
    this.tradeCollectiblesValues.tradeToySeries = '';
    this.tradeCollectiblesValues.tradeToyName = '';
    this.tradeCollectiblesValues.tradeToyBrand = '';
    await this.tradeCollectiblesForm.reset();
  }
  async clearCollectiblesBacklogFilters() {
    this.backlogCollectiblesValues.backlogToyUniverse = '';
    this.backlogCollectiblesValues.backlogToySeries = '';
    this.backlogCollectiblesValues.backlogToyName = '';
    this.backlogCollectiblesValues.backlogToyBrand = '';
    await this.backlogCollectiblesForm.reset();
  }
  async clearCollectiblesWishlistFilters() {
    this.wishlistCollectiblesValues.wishlistToyUniverse = '';
    this.wishlistCollectiblesValues.wishlistToySeries = '';
    this.wishlistCollectiblesValues.wishlistToyName = '';
    this.wishlistCollectiblesValues.wishlistToyBrand = '';
    await this.wishlistCollectiblesForm.reset();
  }

  goToTop() {
    document.getElementById('top').scrollIntoView();
  }
}
