import { Component, OnInit, Inject } from '@angular/core';
import { User } from 'src/app/models/user';
import { Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar, MatSelectChange } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ComicService } from 'src/app/services/comic.service';
import { comicCondition } from 'src/app/constants/comicCondition.constant';
import { comicTypes } from 'src/app/constants/comicType.constant';
import { comicFormat } from 'src/app/constants/comicFormat.constant';
import { debounceTime, startWith, map, takeUntil } from 'rxjs/operators';
import { allCharacters } from 'src/app/constants/allCharacters.constants';
import { allPublishers } from 'src/app/constants/allPublishers.constants';
import { allSeries } from 'src/app/constants/allSeries.constants';
import { comicGenre } from 'src/app/constants/comicGenre.constant';
import { removeImage } from '../../redux/actions/comic.actions';
import { STRIPEID } from '../../../environments/environment.prod';
import { ConfirmProductPictureDialogComponent } from '../confirm-product-picture-dialog/confirm-product-picture-dialog.component';

interface AppState {
  auth: User;
}

@Component({
  selector: 'app-edit-comic-dialog',
  templateUrl: './edit-comic-dialog.component.html',
  styleUrls: ['./edit-comic-dialog.component.scss']
})
export class EditComicDialogComponent implements OnInit {
  public loading = false;
  public id: string;
  public currentAuthUser;

  public universe: string;
  public series: string;

  public title: string;

  public issue: number;
  public volume: number;
  public collectionVolume: string;

  public publisher: string;
  public publicationYear: any;
  public publicationLocation: string;

  public genre: string;
  public type: any;
  public pageCount: number;

  public format: any;
  public language: string;
  public upc: string;

  public condition: any;
  public quantity: number;
  public price: number;

  public credits: any = [];
  public characters: any = [];
  public notes: string;

  public file;

  public titleError: string;
  public universeError: string;
  public seriesError: string;
  public issueError: string;
  public publisherError: string;
  public publicationYearError: string;
  public conditionError: string;
  public priceError: string;
  public quantityError: string;

  public selling;
  public collection;
  public trade;
  public wishlist;

  public savedTitle: string;
  public savedUniverse: string;
  public savedSeries: string;
  public savedIssue: string;
  public savedVolume: string;
  public savedCollectionVolume: string;
  public savedPublisher: string;
  public savedPublicationYear: string;
  public savedPublicationLocation: string;
  public savedGenre: string;
  public savedType: string;
  public savedPageCount: string;
  public savedLanuage: string;
  public savedFormat: string;
  public savedUpc: string;
  public savedNotes;
  public savedCondition: string;
  public savedPrice: string;
  public savedQuantity: string;

  public selectedImage: any;

  public user: Observable<User>;

  universeControl = new FormControl();
  filteredUniverses: Observable<string[]>;

  publisherControl = new FormControl();
  filteredPublishers: Observable<string[]>;

  seriesControl = new FormControl();
  filteredSeries: Observable<string[]>;

  typeControl = new FormControl();
  filteredType: Observable<string[]>;

  genreControl = new FormControl();
  filteredGenre: Observable<string[]>;

  conditionControl = new FormControl();
  filteredCondition: Observable<string[]>;

  comicCondition;
  comicFormat;
  comicType;

  public collectaverseCut;
  public usersCut;

  config;

  private onDestroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditComicDialogComponent>,
    public auth: AuthService,
    public comicService: ComicService,
    public form: FormBuilder,
    private matSnackbar: MatSnackBar,
    private store: Store<AppState>
  ) {
    this.store.select('auth').subscribe(res => (this.currentAuthUser = res));
  }

  ngOnInit() {
    this.config = STRIPEID;
    this.savedTitle = this.data.name;
    this.savedUniverse = this.data.universe;
    this.savedSeries = this.data.series;
    this.savedIssue = this.data.issue;
    this.savedVolume = this.data.volume;
    this.savedCollectionVolume = this.data.collectionVolume;
    this.savedPublisher = this.data.publisher;
    this.savedPublicationYear = this.data.publicationYear;
    this.savedPublicationLocation = this.data.publicationLocation;
    this.savedGenre = this.data.genre;
    this.savedType = this.data.type;
    this.savedPageCount = this.data.pageCount;
    this.savedLanuage = this.data.language;
    this.savedFormat = this.data.format;
    this.savedUpc = this.data.upc;
    this.savedCondition = this.data.condition;
    this.savedPrice = this.data.price;
    this.savedQuantity = this.data.quantity;
    this.credits = this.data.credits;
    this.characters = this.data.characters;
    this.selectedImage = this.data.images[0];

    this.selling = this.data.sell;
    this.collection = this.data.collection;
    this.trade = this.data.trade;
    if (this.data.wishlist === null) {
      this.wishlist = false;
    } else {
      this.wishlist = this.data.wishlist;
    }
    // Setting Options For Select Input
    this.comicCondition = comicCondition;
    this.comicType = comicTypes;
    this.comicFormat = comicFormat;
    // Setting Filters for AutoCompletes
    this.filteredUniverses = this.universeControl.valueChanges
      .pipe(
        debounceTime(500),
        startWith(''),
        map(value => value.length >= 2 ? this._filterUniverses(value) : [])
      );
    this.filteredPublishers = this.publisherControl.valueChanges
      .pipe(
        debounceTime(500),
        startWith(''),
        map(value => value.length >= 2 ? this._filterPublisher(value) : [])
      );
    this.filteredSeries = this.seriesControl.valueChanges
      .pipe(
        debounceTime(500),
        startWith(''),
        map(value => value.length >= 4 ? this._filterSeries(value) : [])
      );
    this.filteredGenre = this.genreControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterGenre(value))
      );
  }

  private _filterUniverses(value: string): string[] {
    const filterValue = value.toLowerCase();
    return allCharacters.sort().filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filterPublisher(value: string): string[] {
    const filterValue = value.toLowerCase();
    return allPublishers.sort().filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filterSeries(value: string): string[] {
    const filterValue = value.toLowerCase();
    return allSeries.sort().filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filterGenre(value: string): string[] {
    const filterValue = value.toLowerCase();
    return comicGenre.filter(option => option.toLowerCase().includes(filterValue));
  }

  handleSelectedImage = image => {
    this.selectedImage = image;
  }

  /* || FORM CONTROL || */

  handleTitle(val) {
    this.titleError = this.validate(val);
    if (this.titleError) {
      return (this.title = '');
    }
    this.title = val;
  }

  handleUniverse(val) {
    this.universeError = this.validate(val);
    if (this.universeError) {
      return (this.universe = '');
    }
    this.universe = val;
  }

  handleSeries(val) {
    this.seriesError = this.validate(val);
    if (this.seriesError) {
      return (this.series = '');
    }
    this.series = val;
  }

  handleIssue(val) {
    this.issueError = this.validate(val);
    if (this.issueError) {
      return (this.issue = null);
    }
    this.issue = val;
  }

  handleVolume(val: any) {
    this.volume = val;
  }

  handleCollectionVolume(val: any) {
    this.collectionVolume = val;
  }

  handlePublisher(val) {
    this.publisherError = this.validate(val);
    if (this.publisherError) {
      return (this.publisher = '');
    }
    this.publisher = val;
  }

  handlePublicationYear(val) {
    this.publicationYearError = this.validate(val);
    if (this.publicationYearError) {
      return (this.publicationYear = null);
    }
    this.publicationYear = val;
  }

  handlePublicationLocation(val: string) {
    this.publicationLocation = val;
  }

  handleGenre(val: string) {
    this.genre = val;
  }

  handleType(val: MatSelectChange) {
    this.type = val.value;
  }

  handlePageCount(val: number) {
    this.pageCount = val;
  }

  handleLanguage(val: string) {
    this.language = val;
  }

  handleUpc(val: string) {
    this.upc = val;
  }

  handleCondition(val: MatSelectChange) {
    this.conditionError = this.validate(val.value);
    if (this.conditionError) {
      return (this.condition = '');
    }
    this.condition = val.value;
  }

  addCharacter() {
    this.characters.push({ name: '', role: '' });
  }

  handleCharacters(val) {
    const { value, name, index } = val;

    this.characters.map((character, i) => {
      if (i === index) {
        return character[name] = value;
      }
      return;
    });
  }

  removeCharacters = value => {
    this.characters = this.characters.filter((x, index) => index !== value);
  }

  addCredit() {
    this.credits.push({ name: '', role: '' });
  }

  handleCredits(val) {
    const { value, name, index } = val;

    this.credits.map((credit, i) => {
      if (i === index) {
        return credit[name] = value;
      }
      return;
    });
  }

  removeCredit = value => {
    this.credits = this.credits.filter((x, index) => index !== value);
  }

  handleFormat(val: MatSelectChange) {
    this.format = val.value;
  }

  handleNotes(val) {
    this.notes = val;
  }

  handleFile(val) {
    this.file = val;
  }

  handlePriceQuantity(evt, name) {
    const error = `${name}Error`;
    this[error] = this.validate(evt);

    if (this[error]) {
      return (this[name] = null);
    }
    this[name] = evt;
  }

  /* || INPUT VALIDATION || */

  validate(legit: any): any {
    if (!legit) {
      return 'Required';
    }
    return '';
  }

  /* || END INPUT VALIDATION || */

  close() {
    this.dialogRef.close();
  }

  closeAndUpdate(evt) {
    this.dialogRef.close(evt);
  }

  deleteImage(id) {
    this.comicService.deleteImage(id).subscribe(async (res: any) => {
      if (res && !res.err) {
        const payload = {
          fileId: id,
          comicId: this.data.id
        };
        await this.store.dispatch(removeImage({ payload }));

        if (this.selectedImage.id === id) { this.selectedImage = this.data.images[0]; }
      }
    });
  }

  goToConfirmDelete(id) {
    const dialogRef = this.dialog.open(ConfirmProductPictureDialogComponent, {
      data: {
        picture: id,
        product: this.data
      },
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      disableClose: true
    });
    dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe(result => {
      if (result !== undefined) {
        this.deleteImage(result);
      }
    });
  }

  submit() {
    this.loading = true;
    this.id = this.data.id;
    const credits = this.credits ? this.credits.filter(credit => credit.name && credit.role) : null;
    const characters = this.characters ? this.characters.filter(character => character.name) : null;
    this.comicService.updateComic(
      this.id,
      this.title,
      this.universe,
      this.series,
      this.issue,
      this.volume,
      this.collectionVolume,
      this.publisher,
      this.publicationYear,
      this.publicationLocation,
      this.genre,
      this.type,
      this.pageCount,
      this.format,
      this.language,
      this.upc,
      JSON.stringify(credits),
      JSON.stringify(characters),
      this.notes,
      this.condition,
      this.selling,
      this.collection,
      this.trade,
      this.wishlist,
      this.file,
      this.price,
      this.quantity
    )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        this.matSnackbar.open(
          `You successfully updated the listing information on your Comic "${this.data.title}"`,
          'OK',
          {
            duration: 5000,
            panelClass: ['cvSnack']
          }
        );
        this.closeAndUpdate(res);
      });

  }
}
