import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { User } from "src/app/models/user";
import { Observable, Subject } from "rxjs";
import { Store } from "@ngrx/store";
import { Router } from "@angular/router";
import { goToUserProfile } from "../../redux/actions/user.actions";
import { takeUntil } from "rxjs/operators";
import { STRIPEID } from "../../../environments/environment.prod";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

interface AppState {
  auth: User;
  cart: any;
  route: any;
}

@Component({
  selector: "app-new-nav-bar",
  templateUrl: "./new-nav-bar.component.html",
  styleUrls: ["./new-nav-bar.component.scss"],
  animations: [
    trigger("fade", [
      transition("void => *", [
        style({ opacity: 0 }),
        animate(800, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        state("invisible", style({ opacity: 0 })),
        style({ opacity: 0 }),
        animate(800, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class NewNavBarComponent implements OnInit {
  profileJson: string;
  user: Observable<User>;
  authUser: Observable<User>;
  cartCount: any;
  config;
  currentPage;
  currentUser;
  private onDestroy$ = new Subject<void>();

  constructor(
    public auth: AuthService,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.user = this.store.select("auth");
    this.store
      .select("cart")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (res) =>
          (this.cartCount = res.reduce(
            (acc, item) => acc + item.quantityInCart,
            0
          ))
      );
    this.store
      .select("route")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        switch (res.route) {
          case "/cart":
            this.currentPage = "CHECKOUT";
            break;
          case "/origin":
            this.currentPage = "ORIGIN";
            break;
          case "/browse":
            this.currentPage = "BROWSE";
            break;
          case "/profile":
            this.currentPage = "MYVERSE";
            break;
          case "/messenger":
            this.currentPage = "MESSENGER";
            break;
          case "/browseComics":
            this.currentPage = "COMICS";
            break;
          case "/browseCollectibles":
            this.currentPage = "COLLECTIBLES";
            break;
          case "/team":
            this.currentPage = "TEAM";
            break;
          case "/about":
            this.currentPage = "ABOUT US";
            break;
          case "/comic":
            this.currentPage = "COMICS";
            break;
          case "/product":
            this.currentPage = "PRODUCTS";
            break;
          case "/comic-grading":
            this.currentPage = "GRADING SYSTEM";
            break;
          case "/browse-collectibles":
            this.currentPage = "COLLECTIBLES";
            break;
          case "/browse-comics":
            this.currentPage = "COMICS";
            break;
          case "/browse-users":
            this.currentPage = "LEADERBOARDS";
            break;
          default:
            this.currentPage = "COLLECTAVERSE";
            break;
        }
      });
  }

  ngOnInit() {
    this.auth.userProfile$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (profile) => (this.profileJson = JSON.stringify(profile, null, 2))
      );
    this.config = STRIPEID;
  }

  async goToMyProfile() {
    await this.user.pipe(takeUntil(this.onDestroy$)).subscribe((user) => {
      this.currentUser = user.username;
      this.store.dispatch(goToUserProfile({ user }));
    });
    this.router.navigateByUrl(`/profile/${this.currentUser}`);
  }
}
