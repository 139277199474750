import { createAction, props } from '@ngrx/store';

export const addItemToCart = createAction('ADD_TO_CART', props<{ product, shipping }>());

export const decreaseQuantity = createAction(
  'DECREASE_QUANTITY',
  props<{ productId }>()
);

export const removeItemFromCart = createAction(
  'REMOVE_FROM_CART',
  props<{ productId }>()
);

export const emptyCart = createAction('EMPTY_CART');
