import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-action',
  templateUrl: './card-action.component.html',
  styleUrls: ['./card-action.component.scss']
})
export class CardActionComponent implements OnInit {

  // tslint:disable-next-line: ban-types
  @Input() clear: Boolean;
  // tslint:disable-next-line: ban-types
  @Input() noPadding: Boolean;

  constructor() { }

  ngOnInit() {
  }

}
