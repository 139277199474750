import { Action } from '@ngrx/store';
import { User } from '../../models/user';

export function authReducer(state: User = null, action: Action) {
  const results: any = action;
  switch (action.type) {
    case 'LOGIN':
      return results.user;

    case 'UPDATE_USER':
      const newUser: User = results.user;
      return newUser;

    default:
      return state;
  }
}
