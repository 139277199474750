import { Component, OnInit, Inject } from '@angular/core';
import { CollectibleService } from 'src/app/services/collectible.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';
import { FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user';
import { Store } from '@ngrx/store';

interface AppState {
  auth: User;
}

@Component({
  selector: 'app-confirm-collectible-dialog',
  templateUrl: './confirm-collectible-dialog.component.html',
  styleUrls: ['./confirm-collectible-dialog.component.scss']
})
export class ConfirmCollectibleDialogComponent implements OnInit {
  public id;
  public user: Observable<User>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ConfirmCollectibleDialogComponent>,
    public auth: AuthService,
    public collectibleService: CollectibleService,
    public form: FormBuilder,
    private matSnackbar: MatSnackBar,
    private store: Store<AppState>) {
    this.user = this.store.select('auth');
  }

  ngOnInit() {

  }

  close() {
    this.dialogRef.close();
  }

  closeAndUpdate(evt) {
    this.dialogRef.close(evt);
  }

  submit() {
    this.id = this.data.id;
    this.collectibleService.deleteCollectible(this.id).subscribe(
      res => {
        this.matSnackbar.open(
          `You have successfully deleted "${this.data.name}" from your collection.`,
          'OK',
          {
            duration: 5000,
            panelClass: ['cvSnack']
          }
        );
        this.closeAndUpdate(res);
      },
      err => console.log(err)
    );
  }

}
