export const environment = {
  production: true
};

export let APIURL = '';
export let STRIPEID = '';
export let STRIPEAPIKEY = '';

switch (window.location.hostname) {
    // this is the deployed angular application
    case 'www.collectaverse.com':
        // this is the full url of your deployed API
        APIURL = 'https://collectaverse-api.herokuapp.com';
        STRIPEID = 'ca_Gshr33ViHlESumPbXWD3bokK8r9Xo6Q7';
        STRIPEAPIKEY = 'sk_live_m9EEH3viUG3ZqUa6gp73x9u80076SmpDQn';
        break;
    default:
        // this is the local host name of your API
        APIURL = 'http://localhost:3000';
        STRIPEID = 'ca_GshrnLDaXzD6lStk5XhkxZCAFIlknPxD';
        STRIPEAPIKEY = 'sk_test_VqjqAN8ujaPAMo4etAKto74h00sWQgTKYY';

}
