import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { User } from "src/app/models/user";
import { Router } from "@angular/router";
import { MatPaginator, MatSort, MatSortable, MatTableDataSource } from "@angular/material";
import {
  trigger,
  transition,
  style,
  animate,
  state,
} from "@angular/animations";
import { takeUntil } from "rxjs/operators";
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { goToUserProfile } from 'src/app/redux/actions/user.actions';

export interface UserFilter {
  username: string;
}

interface AppState {
  auth: User;
}

@Component({
  selector: "app-browse-users",
  templateUrl: "./browse-users.component.html",
  styleUrls: ["./browse-users.component.scss"],
  animations: [
    trigger("fade", [
      transition("void => *", [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        state("invisible", style({ opacity: 1 })),
        style({ opacity: 0 }),
        animate(800, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class UserDirectoryComponent implements OnInit {
  user: Observable<User>;
  allUsers;

  dataSource = new MatTableDataSource();
  globalSearch: FormGroup;
  usersFilter: FormGroup;
  usernameFilter = new FormControl("");

  displayedColumns: string[] = ["imageUrl", "username", "rewards"];
  filterValues = {
    username: "",
  };
  globalFilter = "";

  private onDestroy$ = new Subject<void>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public auth: AuthService,
    private store: Store<AppState>,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.dataSource.filterPredicate = this.createFilter();
    this.usersFilter = fb.group({
      username: "",
    });
    this.globalSearch = fb.group({
      masterSearch: "",
    });
  }

  ngOnInit() {
    this.auth
      .getAllUsers()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        this.allUsers = res;
        this.sort.sort(({ id: 'rewards', start: 'desc'}) as MatSortable);
        this.dataSource.data = this.allUsers;
      });
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.filterRefresh();
  }

  filterRefresh() {
    this.usernameFilter.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((username) => {
        this.filterValues.username = username;
        this.dataSource.filter = JSON.stringify(this.filterValues);
      });
  }

  public applyFilter(filter) {
    this.globalFilter = filter;
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  createFilter() {
    const filterFunction = (data: UserFilter, filter: string): boolean => {
      let globalMatch = !this.globalFilter;
      if (this.globalFilter) {
        // search all text fields
        globalMatch =
          data.username
            .toString()
            .trim()
            .toLowerCase()
            .indexOf(this.globalFilter.toLowerCase()) !== -1;
      }

      if (!globalMatch) {
        return;
      }
      const searchTerms = JSON.parse(filter);
      return (
        data.username
          .trim()
          .toLowerCase()
          .indexOf(searchTerms.username.toLowerCase()) !== -1
      );
    };
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    return filterFunction;
  }

  async clearFilters() {
    this.dataSource.filter = "";
    this.filterValues.username = "";
    await this.globalSearch.reset();
    this.usersFilter.reset();
  }

  async goToUsersProfile(selectedUser) {
    window.scrollTo(0, 0);
    this.store.dispatch(goToUserProfile({ user: selectedUser }))
    this.router.navigateByUrl(`/profile/${selectedUser.username}`);
  }
}
