export const comicTypes = [
  'Comic Book',
  'Comic Fanzine',
  'Comic Strips',
  'Graphic Novel',
  'Manfra',
  'Manga',
  'Manhua',
  'Manhwa',
  'Original Graphic Novel',
  'Other',
];
