import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  iconClasses: string;

  @Input()
  iconName: string;

  @Input()
  color = '#393E46';

  @Input()
  size = 14;

  ngOnInit() {
    if (this.iconName === null) {
     throw new Error('iconName is required');
   } else {
     this.iconClasses = `icon ${this.iconName}`;
   }
  }
}
