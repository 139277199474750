import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-header',
  templateUrl: './card-header.component.html',
  styleUrls: ['./card-header.component.scss']
})
export class CardHeaderComponent {

  @Input() title: string;
  // tslint:disable-next-line: ban-types
  @Input() clear: Boolean;
  // tslint:disable-next-line: ban-types
  @Input() noPadding: Boolean;

}
