import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';
import { ComicService } from 'src/app/services/comic.service';
import { FormBuilder } from '@angular/forms';
import { User } from 'src/app/models/user';
import { Store } from '@ngrx/store';

interface AppState {
  auth: User;
}

@Component({
  selector: 'app-success-checkout-dialog',
  templateUrl: './success-checkout-dialog.component.html',
  styleUrls: ['./success-checkout-dialog.component.scss']
})
export class SuccessCheckoutDialogComponent implements OnInit {
  public id;
  public user: Observable<User>;
  public soldItems;
  public comics;
  public collectibles;
  public blob;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SuccessCheckoutDialogComponent>,
    public auth: AuthService,
    public comicService: ComicService,
    public form: FormBuilder,
    private store: Store<AppState>) {
    this.user = this.store.select('auth');
  }

  ngOnInit() {
    this.blob = this.data;
    const filteredComics = this.blob.cart.filter(res => {
        return Object.prototype.hasOwnProperty.call(res, 'title');
    })
    const filteredCollectibles = this.blob.cart.filter(res => {
        return Object.prototype.hasOwnProperty.call(res, 'name');
    })
    this.comics = filteredComics;
    this.collectibles = filteredCollectibles;
  }

  close() {
    this.dialogRef.close(this.data);
  }

}
