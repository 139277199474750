// Angular
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// Components
import { AppComponent } from './app.component';
import { OriginComponent } from './core/origin/origin.component';
import { AvatarPictureComponent } from './components/avatar-picture/avatar-picture.component';
import { BrowseComicsComponent } from './components/browse-comics/browse-comics.component';
import { BrowseComponent } from './core/browse/browse.component';
import { BrowseToysComponent } from './components/browse-toys/browse-toys.component';
import { CallbackComponent } from './components/callback/callback.component';
import { ComicCharactersComponent } from './components/comic-characters/comic-characters.component';
import { CartComponent } from './core/cart/cart.component';
import { ComicGradingComponent } from './core/comic-grading/comic-grading.component';
import { CreateAccountComponent } from './core/create-account/create-account.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './core/home/home.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MessengerComponent } from './core/messenger/messenger.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { ProfileComponent } from './core/profile/profile.component';
import { UserDirectoryComponent } from './components/browse-users/browse-users.component';
import { ViewComicComponent } from './core/view-comic/view-comic.component';
import { ViewCollectibleComponent } from './core/view-collectible/view-collectible.component';
import {ComicCreditComponent} from './components/comic-credit/comic-credit.component';
// Dialogs
import { AddCollectibleDialogComponent } from './dialogs/add-collectible-dialog/add-collectible-dialog.component';
import { AddComicDialogComponent } from './dialogs/add-comic-dialog/add-comic-dialog.component';
import { AddWishlistCollectibleDialogComponent } from './dialogs/add-wishlist-collectible-dialog/add-wishlist-collectible-dialog.component';
import { AddWishlistComicDialogComponent } from './dialogs/add-wishlist-comic-dialog/add-wishlist-comic-dialog.component';
import { ChangeProfilePictureDialogComponent } from './dialogs/change-profile-picture-dialog/change-profile-picture-dialog.component';
import { ConfirmCollectibleDialogComponent } from './dialogs/confirm-collectible-dialog/confirm-collectible-dialog.component';
import { ConfirmComicDialogComponent } from './dialogs/confirm-comic-dialog/confirm-comic-dialog.component';
import { ConfirmProductPictureDialogComponent } from './dialogs/confirm-product-picture-dialog/confirm-product-picture-dialog.component';
import { EditComicDialogComponent } from './dialogs/edit-comic-dialog/edit-comic-dialog.component';
import { EditComicCoverDialogComponent } from './dialogs/edit-comic-cover-dialog/edit-comic-cover-dialog.component';
import { EditCollectibleCoverDialogComponent } from './dialogs/edit-collectible-cover-dialog/edit-collectible-cover-dialog.component';
import { EditCollectibleDialogComponent } from './dialogs/edit-collectible-dialog/edit-collectible-dialog.component';
import { EditProfileDialogComponent } from './dialogs/edit-profile-dialog/edit-profile-dialog.component';
import { SellSimilarComicDialogComponent } from './dialogs/sell-similar-comic-dialog/sell-similar-comic-dialog.component';
import { SellSimilarCollectibleDialogComponent } from './dialogs/sell-similar-collectible-dialog/sell-similar-collectible-dialog.component';
import { SuccessPageComponent } from './core/success-page/success-page.component';
import { SuccessCheckoutDialogComponent } from './dialogs/success-checkout-dialog/success-checkout-dialog.component';
import { TransactionLogDialogComponent } from './dialogs/transaction-log-dialog/transaction-log-dialog.component';
import { ViewProductDialogComponent } from './dialogs/view-product-dialog/view-product-dialog.component';
import { ViewToyDialogComponent } from './dialogs/view-toy-dialog/view-toy-dialog.component';
// Directives
import { FileDropZoneDirective } from './directives/file-drop-zone.directive';
// Environment
import { STRIPEAPIKEY } from '../environments/environment.prod';
// Form Control
import { ButtonComponent } from './form-controls/button/button.component';
import { CardActionComponent } from './form-controls/card/card-action/card-action.component';
import { CardComponent } from './form-controls/card/card/card.component';
import { CardContentComponent } from './form-controls/card/card-content/card-content.component';
import { CardHeaderComponent } from './form-controls/card/card-header/card-header.component';
import { ColComponent } from './form-controls/grid/col/col.component';
import { FileDropComponent } from './form-controls/file-drop/file-drop.component';
import { FormErrorComponent } from './form-controls/form-error/form-error.component';
import { FormFieldComponent } from './form-controls/form-field/form-field.component';
import { IconComponent } from './form-controls/icon/icon.component';
import { InputComponent } from './form-controls/input/input.component';
import { OptionComponent } from './form-controls/option/option.component';
import { PanelComponent } from './form-controls/panel/panel.component';
import { RowComponent } from './form-controls/grid/row/row.component';
import { SelectComponent } from './form-controls/select/select.component';
import { TextareaComponent } from './form-controls/textarea/textarea.component';
// Modules
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CloudinaryModule } from '@cloudinary/angular-5.x';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { HighlightModule } from 'ngx-highlightjs';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './material/material.module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Ng7MatBreadcrumbModule } from 'ng7-mat-breadcrumb';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { OrderModule } from 'ngx-order-pipe';
import { StoreModule } from '@ngrx/store';
import { NgxStripeModule } from 'ngx-stripe';
import {CardModule} from 'ngx-card/ngx-card';
// Pipes
import { FilterPipe} from './pipes/filter.pipe';
import { ArraySortPipe } from './pipes/sort.pipe';
// Random
import * as Cloudinary from 'cloudinary-core';
import json from 'highlight.js/lib/languages/json';
export function hljsLanguages() {
  return [{ name: 'json', func: json }];
}
// Reducers
import { authReducer } from './redux/reducers/auth.reducer';
import { collectibleReducer } from './redux/reducers/collectible.reducer';
import { comicReducer } from './redux/reducers/comic.reducer';
import { profileReducer } from './redux/reducers/profile.reducer';
import { cartReducer } from './redux/reducers/cart.reducer';
import { messageReducer } from './redux/reducers/message.reducer';
import { routerReducer } from './redux/reducers/router.reducer';
import { storageSyncMetaReducer } from 'ngrx-store-persist';
import { productReducer } from './redux/reducers/product.reducer';
// Services
import { WebsocketService } from './services/websocket.service';
import { TermsOfServiceDialogComponent } from './dialogs/terms-of-service-dialog/terms-of-service-dialog.component';
import { OptOutDialogComponent } from './dialogs/opt-out-dialog/opt-out-dialog.component';
import { NewNavBarComponent } from './components/new-nav-bar/new-nav-bar.component';
import { AboutUsComponent } from './core/about-us/about-us.component';
import { JustUsLeagueComponent } from './core/just-us-league/just-us-league.component';
import { ProductLandingPageComponent } from './core/product-landing-page/product-landing-page.component';
import { FooterHomeComponent } from './components/footer-home/footer-home.component';
import { BrowseOnlyComicsComponent } from './core/browse-only-comics/browse-only-comics.component';
import { BrowseOnlyCollectiblesComponent } from './core/browse-only-collectibles/browse-only-collectibles.component';
import { BrowseOnlyUsersComponent } from './core/browse-only-users/browse-only-users.component';

export function clearState(reducer) {
  // tslint:disable-next-line: only-arrow-functions
  return function(state, action) {
    if (action.type === 'LOGOUT') {
      state = undefined;
    }

    return reducer(state, action);
  };
}

@NgModule({
  declarations: [
    AppComponent,
    BrowseComponent,
    CallbackComponent,
    CreateAccountComponent,
    FooterComponent,
    HomeComponent,
    LoadingComponent,
    NavBarComponent,
    ProfileComponent,
    EditProfileDialogComponent,
    FileDropComponent,
    FileDropZoneDirective,
    FilterPipe,
    ArraySortPipe,
    AddComicDialogComponent,
    AvatarPictureComponent,
    InputComponent,
    FormErrorComponent,
    FormFieldComponent,
    SelectComponent,
    PanelComponent,
    OptionComponent,
    TextareaComponent,
    ColComponent,
    RowComponent,
    CardComponent,
    CardActionComponent,
    CardContentComponent,
    CardHeaderComponent,
    ButtonComponent,
    IconComponent,
    ChangeProfilePictureDialogComponent,
    UserDirectoryComponent,
    AddCollectibleDialogComponent,
    ViewProductDialogComponent,
    ViewToyDialogComponent,
    CartComponent,
    BrowseComicsComponent,
    BrowseToysComponent,
    EditComicDialogComponent,
    MessengerComponent,
    ViewComicComponent,
    ViewCollectibleComponent,
    EditComicCoverDialogComponent,
    EditCollectibleDialogComponent,
    OriginComponent,
    ComicCreditComponent,
    SuccessPageComponent,
    EditCollectibleCoverDialogComponent,
    ComicCharactersComponent,
    ConfirmComicDialogComponent,
    ConfirmCollectibleDialogComponent,
    ComicGradingComponent,
    SuccessCheckoutDialogComponent,
    ConfirmProductPictureDialogComponent,
    SellSimilarComicDialogComponent,
    SellSimilarCollectibleDialogComponent,
    TransactionLogDialogComponent,
    AddWishlistComicDialogComponent,
    AddWishlistCollectibleDialogComponent,
    TermsOfServiceDialogComponent,
    OptOutDialogComponent,
    NewNavBarComponent,
    AboutUsComponent,
    JustUsLeagueComponent,
    ProductLandingPageComponent,
    FooterHomeComponent,
    BrowseOnlyComicsComponent,
    BrowseOnlyCollectiblesComponent,
    BrowseOnlyUsersComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CloudinaryModule.forRoot(Cloudinary, {
      cloud_name: 'dwblincoe',
      upload_preset: 'aajt0vrh'
    }),
    CardModule,
    FontAwesomeModule,
    FormsModule,
    HighlightModule.forRoot({
      languages: hljsLanguages
    }),
    HttpClientModule,
    MaterialModule,
    NgbModule,
    Ng2SearchPipeModule,
    Ng7MatBreadcrumbModule,
    NgxPaginationModule,
    OrderModule,
    StoreModule.forRoot(
      {
        auth: authReducer,
        userProfile: profileReducer,
        cart: cartReducer,
        comics: comicReducer,
        collectibles: collectibleReducer,
        messages: messageReducer,
        route: routerReducer,
        product: productReducer
      },
      { metaReducers: [storageSyncMetaReducer, clearState] }
    ),
    StoreDevtoolsModule.instrument({
      maxAge: 10
    }),
    NgxStripeModule.forRoot(STRIPEAPIKEY)
  ],
  entryComponents: [
    AddCollectibleDialogComponent,
    AddComicDialogComponent,
    AddWishlistComicDialogComponent,
    AddWishlistCollectibleDialogComponent,
    ChangeProfilePictureDialogComponent,
    ConfirmCollectibleDialogComponent,
    ConfirmComicDialogComponent,
    ConfirmProductPictureDialogComponent,
    EditCollectibleDialogComponent,
    EditComicCoverDialogComponent,
    EditCollectibleCoverDialogComponent,
    EditComicDialogComponent,
    EditProfileDialogComponent,
    OptOutDialogComponent,
    SellSimilarCollectibleDialogComponent,
    SellSimilarComicDialogComponent,
    SuccessCheckoutDialogComponent,
    TransactionLogDialogComponent,
    TermsOfServiceDialogComponent,
    ViewProductDialogComponent,
    ViewToyDialogComponent
  ],
  exports: [FileDropZoneDirective, FileDropComponent],
  providers: [WebsocketService],
  bootstrap: [AppComponent]
})
export class AppModule {}
