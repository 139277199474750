import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSelectChange, MatSnackBar } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { CollectibleService } from 'src/app/services/collectible.service';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { Subject, Observable } from 'rxjs';
import { takeUntil, debounceTime, startWith, map } from 'rxjs/operators';
import { comicCondition } from '../../constants/comicCondition.constant';
import { allBrands } from '../../constants/allBrands.constants';
import { STRIPEID } from '../../../environments/environment.prod';

@Component({
  selector: 'app-add-collectible-dialog',
  templateUrl: './add-collectible-dialog.component.html',
  styleUrls: ['./add-collectible-dialog.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(250, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        state('invisible', style({ opacity: 1 })),
        style({ opacity: 0 }),
        animate(250, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class AddCollectibleDialogComponent implements OnInit {

  public id;

  public universe: string;
  public name: string;
  public brand: string;
  public series: string;
  public year: string;
  public upc: string;
  public buildFigure: string;
  public variation: string;
  public accessories: string;
  public condition;
  public file;
  public files;

  public universeError: string;
  public nameError: string;
  public brandError: string;
  public conditionError: string;
  public priceError: string;
  public quantityError: string;
  public fileError: string;

  public loading = false;

  public selling;
  public collection;
  public trade;
  public wishlist;

  public price: any;
  public quantity: string;

  public isBuildFigure;
  public hasVariation;
  public hasAccessories;
  public hasUpc;

  public collectibleCondition;
  public config;
  public collectaverseCut;
  public usersCut;

  private onDestroy$ = new Subject<void>();

  brandsControl = new FormControl();
  filteredBrands: Observable<string[]>;

  public fileLockedIn;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddCollectibleDialogComponent>,
    public auth: AuthService,
    public form: FormBuilder,
    private matSnackbar: MatSnackBar,
    public collectibleService: CollectibleService) { }

  ngOnInit() {
    this.fileLockedIn = false;
    this.config = STRIPEID;
    this.selling = false;
    this.collection = false;
    this.trade = false;
    this.wishlist = false;

    this.collectibleCondition = comicCondition;

    this.isBuildFigure = false;
    this.hasVariation = false;
    this.hasAccessories = false;
    this.hasUpc = false;

    this.filteredBrands = this.brandsControl.valueChanges.pipe(
      debounceTime(500),
      startWith(''),
      map(value => (value.length >= 2 ? this._filterBrands(value) : []))
    );
  }

  private _filterBrands(value: string): string[] {
    const filterValue = value.toLowerCase();
    return allBrands
      .sort()
      .filter(option => option.toLowerCase().includes(filterValue));
  }

  /* || FORM CONTROL || */

  handleUniverse(val) {
    this.universeError = this.validate(val);
    if (this.universeError) {
      return this.universe = '';
    }
    this.universe = val;
  }

  handleName(val) {
    this.nameError = this.validate(val);
    if (this.nameError) {
      return this.name = '';
    }
    this.name = val;
  }

  handleBrand(val) {
    this.brandError = this.validate(val);
    if (this.brandError) {
      return this.brand = '';
    }
    this.brand = val;
  }

  handleSeries(val) {
    this.series = val;
  }

  handleYear(val) {
    this.year = val;
  }

  handleUpc(val) {
    this.upc = val;
  }

  handleBuildFigure(val) {
    this.buildFigure = val;
  }

  handleVariation(val) {
    this.variation = val;
  }

  handleAccessories(val) {
    this.accessories = val;
  }

  handleCondition(val: MatSelectChange) {
    this.condition = val.value;
  }

  handleFile(val) {
    this.fileError = this.validate(val);
    if (this.fileError) {
      return this.file = '';
    }
    this.file = val;
    this.fileLockedIn = true;
  }

  handlePriceQuantity(evt, name) {
    const error = `${name}Error`;
    this[error] = this.validate(evt);

    if (this[error]) {
      return (this[name] = null);
    }
    this[name] = evt;
    if (name === 'price') {
      const x = (3.5 / 100) * this.price;
      this.collectaverseCut = x.toFixed(2);
      this.usersCut = this.price - this.collectaverseCut;
    }
  }

  handleSelling(event) {
    if (event) {
      this.selling = event;
    }
  }

  handleCollection(event) {
    if (event) {
      this.collection = event;
    }
  }

  handleTrading(event) {
    if (event) {
      this.trade = event;
    }
  }

  handleWishlist(event) {
    if (event) {
      this.wishlist = event;
    }
  }

  /* || INPUT VALIDATION || */

  validate(legit: any): any {
    if (!legit) {
      return 'Required';
    }
    return '';
  }

  validateFile(legit: any): any {
    if (!legit) {
      return 'A picture is required to upload a Collectible';
    }
    return '';
  }

  /* || END INPUT VALIDATION || */

  close() {
    this.dialogRef.close();
  }

  closeAndUpdate(res) {
    this.dialogRef.close(res);
  }

  async submit() {
    this.universeError = this.validate(this.universe);
    this.nameError = this.validate(this.name);
    this.brandError = this.validate(this.brand);
    this.fileError = this.validateFile(this.file);

    if (this.selling) {
      this.conditionError = this.validate(this.condition);
      this.priceError = this.validate(this.price);
      this.quantityError = this.validate(this.quantity);
    }
    // If any of these inputs are blank it won't complete the call
    if (
      this.nameError ||
      this.brandError ||
      this.universeError ||
      this.fileError ||
      (this.selling &&
        (this.conditionError || this.priceError || this.quantityError))
    ) {
      return this.matSnackbar.open(
        `Please recheck the form. A REQUIRED field in red has been identified`,
        'OK',
        {
          duration: 5000,
          panelClass: ['cvSnack']
        }
      );
    }
    this.loading = true;
    await this.collectibleService.addCollectible(
      this.universe,
      this.name,
      this.brand,
      this.series,
      this.year,
      this.upc,
      this.buildFigure,
      this.variation,
      this.accessories,
      this.condition,
      this.selling,
      this.trade,
      this.collection,
      this.wishlist,
      this.price,
      this.quantity,
      this.file).pipe(takeUntil(this.onDestroy$)).subscribe(async res => {
        await this.auth.updatePoints(this.data.profile.rewards + 5, this.data.userId);
        this.closeAndUpdate(res);
      });
  }

}
