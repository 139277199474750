import { createAction, props } from "@ngrx/store";

export const setAllMessages = createAction(
  "SET_MESSAGES",
  props<{ threads }>()
);

export const selectThread = createAction(
  "SELECT_THREAD",
  props<{ threadId }>()
);

export const addMessage = createAction("ADD_MESSAGE", props<{ message }>());

export const addThread = createAction("ADD_THREAD", props<{ thread }>());

export const removeSelectedThread = createAction("REMOVE_SELECTED_THREAD");
