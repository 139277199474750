import {
  Component,
  OnInit,
  Inject,
} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

interface AppState {
  auth: User;
}

@Component({
  selector: 'app-edit-profile-dialog',
  templateUrl: './edit-profile-dialog.component.html',
  styleUrls: ['./edit-profile-dialog.component.scss']
})
export class EditProfileDialogComponent implements OnInit {

  public loading = false;

  public id;
  public firstName: string;
  public lastName: string;
  public aboutMe: string;

  public firstNameError: string;
  public lastNameError: string;
  public aboutMeError: string;

  public savedFirstName: string;
  public savedLastName: string;
  public savedAboutMe: string;

  public user: Observable<User>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditProfileDialogComponent>,
    public auth: AuthService,
    public form: FormBuilder,
    private store: Store<AppState>,
  ) {
    this.user = this.store.select('auth');
  }

  ngOnInit() {
    this.savedFirstName = this.data.profile.firstName;
    this.savedLastName = this.data.profile.lastName;
    this.savedAboutMe = this.data.profile.aboutMe;
  }

  /* || FORM CONTROL || */

  handleFirstName(val) {
    this.firstNameError = this.validateFirstName(val);
    if (this.firstNameError) {
      return this.firstName = val;
    }
    this.firstName = val;
  }

  handleLastName(val) {
    this.lastNameError = this.validateLastName(val);
    if (this.lastNameError) {
      return this.lastName = val;
    }
    this.lastName = val;
  }

  handleAboutMe(val: string) {
    this.aboutMeError = this.validateLastName(val);
    if (this.aboutMeError) {
      return this.aboutMe = val;
    }
    this.aboutMe = val;
  }

  /* || END FORM CONTROL || */

  /* || BEGIN INPUT VALIDATION || */

  validateFirstName(legit: any): any {
    if (legit === undefined) {
      return this.firstName = this.savedFirstName;
    }
    return this.firstName;
  }

  validateLastName(legit: any): any {
    if (legit === undefined) {
      return this.lastName = this.savedLastName;
    }
    return this.lastName;
  }

  validateAboutMe(legit: any): any {
    if (legit === undefined) {
      return this.aboutMe = this.savedAboutMe;
    }
    return this.aboutMe;
  }

  /* || END INPUT VALIDATION || */

  close() {
    this.dialogRef.close();
  }

  submit() {
    this.firstNameError = this.validateFirstName(this.firstName);
    this.lastNameError = this.validateLastName(this.lastName);
    this.aboutMeError = this.validateAboutMe(this.aboutMe);
    this.id = this.data.profile.id;


    this.auth.updateUser(
      this.id,
      this.firstName,
      this.lastName,
      this.aboutMe,
    );
    this.close();
  }
}
