import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import { MatDialog, MatSnackBar } from "@angular/material";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { User } from "src/app/models/user";
import { selectedCollectible } from "src/app/redux/actions/collecible.actions";
import {
  selectedComic,
  setAllComics,
} from "src/app/redux/actions/comic.actions";
import { setRoute } from "src/app/redux/actions/router.actions";
import { AuthService } from "src/app/services/auth.service";
import { CollectibleService } from "src/app/services/collectible.service";
import { ComicService } from "src/app/services/comic.service";

interface AppState {
  comics: any;
  userProfile: User;
  auth: User;
  collectibles: any;
  product: any;
  cart: any;
}

@Component({
  selector: "app-product-landing-page",
  templateUrl: "./product-landing-page.component.html",
  styleUrls: ["./product-landing-page.component.scss"],
  animations: [
    trigger("fade", [
      transition("void => *", [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        state("invisible", style({ opacity: 0 })),
        style({ opacity: 0 }),
        animate(800, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ProductLandingPageComponent implements OnInit {
  public user: Observable<User>;
  public authUser: Observable<User>;
  public isComic;
  comicBtn;
  comic;
  collectible;
  cart: any;
  shippingInfo: any;

  public allComics;
  public allPublisher;
  public allSeries;
  public allBrands;
  public noBrands;
  public noPublisher;
  public otherSellers;
  public noSellers;
  public noSeries;
  public filteredComics;
  public filteredPublisher;
  public filteredSeries;
  public noContent;

  public loading = false;
  private onDestroy$ = new Subject<void>();
  public allCollectibles;
  public noCollectibles;
  public noMatchingBrand;
  public filteredCollectibles;
  public authId;
  public filteredBrand;

  public currentComicUniverse: 1;
  public currentComicSeries: 1;
  public currentComicPublisher: 1;
  public currentCollectibleUniverse: 1;
  public currentCollectibleBrand: 1;

  constructor(
    private store: Store<AppState>,
    public comicService: ComicService,
    private router: Router,
    private mdDialog: MatDialog,
    private matSnackbar: MatSnackBar,
    public auth: AuthService,
    public collectibleService: CollectibleService
  ) {
    this.store
      .select("collectibles")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        this.collectible = res.selectedCollectible;
        this.allCollectibles = res;
        this.allBrands = res;
      });
    this.store
      .select("comics")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        this.comic = res.selectedComic;
        this.allComics = res;
        this.allPublisher = res;
        this.allSeries = res;
      });
    this.store
      .select("product")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        if (res.product === "comic") {
          this.isComic = true;
        } else {
          this.isComic = false;
        }
      });
    this.store
      .select("cart")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        this.cart = res;
        let total = 0;
        for (const data of this.cart) {
          total += data.shippingCost;
        }
        this.shippingInfo = total.toFixed(2);
      });
    this.user = this.store.select("userProfile");
    this.authUser = this.store.select("auth");
    if (this.isComic) {
      this.comicBtn = `Explore ${this.comic.universe}`;
    } else {
      this.comicBtn = `Explore ${this.collectible.universe}`;
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.store.dispatch(setRoute({ route: this.router.url }));
    if (this.isComic) {
      if (
        this.allComics.comics.length === 0 ||
        this.allPublisher.comics.length === 0
      ) {
        this.noPublisher = true;
      }
      // This checks to see if any other current users is selling a comic with same exact title.
      this.otherSellers = this.shuffle(
        this.allComics.comics.filter((comic) => {
          return (
            comic.title.toString().trim().toLowerCase() ===
              this.comic.title.toString().trim().toLowerCase() &&
            comic.id !== this.comic.id &&
            comic.sell === true &&
            comic.quantity > 0
          );
        })
      );
      // console.log('otherSellers', this.otherSellers)
      // If there is no match of title we move into the first part of the if statement
      if (this.otherSellers.length === 0) {
        this.noSellers = true;
      }
      this.filteredComics = this.shuffle(
        this.allComics.comics.filter((v, i, a) => {
          // This function pulls all Comics in the same Universe as the selectedComic
          // If there are ANY FORM OF duplicate titles. DO NOT SHOW ANY DUPLICATES.
          return (
            a.findIndex(
              (t) =>
                t.title.toString().trim().toLowerCase() ===
                  v.title.toString().trim().toLowerCase() &&
                t.title.toString().trim().toLowerCase() !==
                  this.comic.title.toString().trim().toLowerCase() &&
                t.universe.toString().trim().toLowerCase() ===
                  this.comic.universe.toString().trim().toLowerCase() &&
                // t.ownerId.toString().trim().toLowerCase() !==
                //   this.authId.toString().trim().toLowerCase() &&
                t.id !== this.comic.id
            ) === i
          );
        })
      );

      if (this.filteredComics.length > 0) {
        this.filteredComics = this.filteredComics.sort((a, b) => {
          const sellingA = a.universe.toUpperCase();
          const sellingB = b.universe.toUpperCase();
          if (sellingA === sellingB) {
            if (a.series === b.series) {
              return a.issue.localeCompare(b.issue, undefined, {
                numeric: true,
              });
            }
            return a.series < b.series ? -1 : a.series > b.series ? 1 : 0;
          }
          return sellingA < sellingB ? -1 : sellingA > sellingB ? 1 : 0;
        });
      }

      this.filteredSeries = this.shuffle(
        this.allSeries.comics.filter((v, i, a) => {
          // This function pulls all Comics in the same Universe as the selectedComic
          // If there are ANY FORM OF duplicate titles. DO NOT SHOW ANY DUPLICATES.
          return (
            a.findIndex(
              (t) =>
                t.title.toString().trim().toLowerCase() ===
                  v.title.toString().trim().toLowerCase() &&
                t.title.toString().trim().toLowerCase() !==
                  this.comic.title.toString().trim().toLowerCase() &&
                t.series.toString().trim().toLowerCase() ===
                  this.comic.series.toString().trim().toLowerCase() &&
                // t.ownerId.toString().trim().toLowerCase() !==
                //   this.authId.toString().trim().toLowerCase() &&
                t.id !== this.comic.id
            ) === i
          );
        })
      );
      // console.log(this.filteredComics)
      this.filteredPublisher = this.shuffle(
        this.allPublisher.comics.filter((v, i, a) => {
          // This function pulls all Comics with an identical publisher as the selectedComic.
          // If there are ANY FORM OF duplicate titles. DO NOT SHOW ANY DUPLICATES.
          return (
            a.findIndex(
              (t) =>
                t.title.toString().trim().toLowerCase() ===
                  v.title.toString().trim().toLowerCase() &&
                t.title.toString().trim().toLowerCase() !==
                  this.comic.title.toString().trim().toLowerCase() &&
                t.publisher.toString().trim().toLowerCase() ===
                  this.comic.publisher.toString().trim().toLowerCase() &&
                t.universe.toString().trim().toLowerCase() !==
                  this.comic.universe.toString().trim().toLowerCase() &&
                // t.ownerId.toString().trim().toLowerCase() !==
                //   this.authId.toString().trim().toLowerCase() &&
                // t.sell === true &&
                // t.quantity > 0 &&
                t.id !== this.comic.id
            ) === i
          );
        })
      );
      if (this.filteredSeries.length > 0) {
        this.filteredSeries = this.filteredSeries.sort((a, b) => {
          const sellingA = a.universe.toUpperCase();
          const sellingB = b.universe.toUpperCase();
          if (sellingA === sellingB) {
            if (a.series === b.series) {
              return a.issue.localeCompare(b.issue, undefined, {
                numeric: true,
              });
            }
            return a.series < b.series ? -1 : a.series > b.series ? 1 : 0;
          }
          return sellingA < sellingB ? -1 : sellingA > sellingB ? 1 : 0;
        });
      }

      if (this.filteredPublisher.length > 0) {
        this.filteredPublisher = this.filteredPublisher.sort((a, b) => {
          const sellingA = a.universe.toUpperCase();
          const sellingB = b.universe.toUpperCase();
          if (sellingA === sellingB) {
            if (a.series === b.series) {
              return a.issue.localeCompare(b.issue, undefined, {
                numeric: true,
              });
            }
            return a.series < b.series ? -1 : a.series > b.series ? 1 : 0;
          }
          return sellingA < sellingB ? -1 : sellingA > sellingB ? 1 : 0;
        });
      }
      // console.log(this.filteredPublisher)
      if (this.filteredPublisher.length === 0) {
        this.noPublisher = true;
      }
      if (this.filteredSeries.length === 0) {
        this.noSeries = true;
      }

      this.noContent = this.shuffle(
        this.allComics.comics.filter((v, i, a) => {
          // This function pulls all Comics with an identical publisher as the selectedComic.
          // If there are ANY FORM OF duplicate titles. DO NOT SHOW ANY DUPLICATES.
          return (
            a.findIndex(
              (t) =>
                t.title.toString().trim().toLowerCase() ===
                  v.title.toString().trim().toLowerCase() &&
                t.title.toString().trim().toLowerCase() !==
                  this.comic.title.toString().trim().toLowerCase() &&
                t.id !== this.comic.id
            ) === i
          );
        })
      );
    }
    if (!this.isComic) {
      if (this.allCollectibles.collectibles.length === 0) {
        this.noCollectibles = true;
        this.noMatchingBrand = true;
      }
      this.filteredCollectibles = this.shuffle(
        this.allCollectibles.collectibles.filter((toy) => {
          return (
            toy.universe === this.collectible.universe &&
            toy.id !== this.collectible.id
            // toy.quantity > 0 &&
            // toy.ownerId !== this.authId
          );
        })
      );
      this.filteredBrand = this.shuffle(
        this.allBrands.collectibles.filter((toy) => {
          return (
            toy.brand === this.collectible.brand &&
            toy.id !== this.collectible.id &&
            toy.universe !== this.collectible.universe
          );
        })
      );
      if (this.filteredCollectibles.length === 0) {
        this.noCollectibles = true;
      }
      if (this.filteredBrand.length === 0) {
        this.noMatchingBrand = true;
      }
    }
    window.scrollTo(0, 0);
    this.loading = false;
  }

  goToViewProduct() {
    if (this.isComic === true) {
      this.router.navigateByUrl("/comic");
    } else {
      this.router.navigateByUrl("/collectible");
    }
  }

  viewOtherProduct(product) {
    if (this.isComic === true) {
      this.store.dispatch(selectedComic({ id: product.id }));
      this.router.navigateByUrl("/comic");
    } else {
      this.store.dispatch(selectedCollectible({ id: product.id }));
      this.router.navigateByUrl("/collectible");
    }
  }
  shuffle(list) {
    return list.reduce((p, n) => {
      const size = p.length;
      const index = Math.trunc(Math.random() * (size - 1));
      p.splice(index, 0, n);
      return p;
    }, []);
  }
}
