import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent {

  public show = false;

  @HostBinding('style.display')
  display = 'none';

  open(): void {
    this.show = true;
    this.display = 'block';
  }

  close(): void {
    this.show = false;
    this.display = 'none';
  }

}
