import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { comicCreditsRoles } from '../../constants/comicCreditsRoles';
import { comicCreditsNames } from '../../constants/comicCreditsNames';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-comic-credit',
  templateUrl: './comic-credit.component.html',
  styleUrls: ['./comic-credit.component.scss']
})
export class ComicCreditComponent implements OnInit {
  // tslint:disable no-output-rename && no-input-rename
  @Output('removeCredit') removeCredit: EventEmitter<any> = new EventEmitter();
  @Output('handleCredits') handleCredits: EventEmitter<any> = new EventEmitter();
  @Input('index') index: number;
  @Input('credit') credit: any;

  creditsRolesControl = new FormControl();
  filteredCreditsRoles: Observable<string[]>;

  creditsNamesControl = new FormControl();
  filteredCreditsNames: Observable<string[]>;

  public comicCreditsRoles;
  public comicCreditsNames;
  constructor() { }

  ngOnInit() {
    this.comicCreditsRoles = comicCreditsRoles;
    this.comicCreditsNames = comicCreditsNames;

    this.filteredCreditsRoles = this.creditsRolesControl.valueChanges.pipe(
      debounceTime(500),
      startWith(''),
      map(value => (value.length >= 2 ? this._filterCreditsRoles(value) : []))
    );

    this.filteredCreditsNames = this.creditsNamesControl.valueChanges.pipe(
      debounceTime(500),
      startWith(''),
      map(value => (value.length >= 2 ? this._filterCreditsNames(value) : []))
    );
  }

  private _filterCreditsRoles(value: string): string[] {
    const filterValue = value.toLowerCase();
    return comicCreditsRoles
      .sort()
      .filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filterCreditsNames(value: string): string[] {
    const filterValue = value.toLowerCase();
    return comicCreditsNames
      .sort()
      .filter(option => option.toLowerCase().includes(filterValue));
  }

  handleInputChange = (evt, name) => {
    const payload = {
      value: evt,
      name,
      index: this.index,
      id: this.credit ? this.credit.id : null
    };
    this.handleCredits.emit(payload);
  }

  remove = () => {
    this.removeCredit.emit(this.index);
  }

}
