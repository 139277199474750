export const comicGenre = [
  'Action/Adventure',
  'Adult',
  'Advocacy',
  'Alternate',
  'Anthology',
  'Anthropomorphic',
  'Art',
  'Auto-Biography',
  'Biography',
  'Childrens',
  'Crime',
  'Cyberpunk',
  'Fantasy',
  'Fiction',
  'History',
  'Horror',
  'Humor',
  'Literature',
  'Martial-Arts',
  'Music',
  'Mystery',
  'Noir',
  'Religious',
  'Romance',
  'Science',
  'Science Fiction',
  'Short Stories',
  'Superhero',
  'War'
];
