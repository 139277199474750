import { Component, Input, Output, EventEmitter,  } from '@angular/core';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent {

  @Input()
  placeholder: string;

  @Input()
  rows: number;

  @Input()
  maxLength: number;

  // tslint:disable-next-line: no-output-on-prefix
  @Output()
  onChange: EventEmitter<string> = new EventEmitter();

  @Input()
  value: string;

  @Input()
  disabled = false;

  @Input()
  error: string;

  updateValue(event: string): void {
    if (event) {
      this.value = event;
      this.onChange.emit(event);
    }
  }

}
