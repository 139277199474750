import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, startWith, map } from 'rxjs/operators';
import { allCharacters } from '../../constants/allCharacters.constants';

@Component({
  selector: 'app-comic-characters',
  templateUrl: './comic-characters.component.html',
  styleUrls: ['./comic-characters.component.scss']
})
export class ComicCharactersComponent implements OnInit {
  // tslint:disable no-output-rename && no-input-rename
  @Output('removeCharacter') removeCharacter: EventEmitter<any> = new EventEmitter();
  @Output('handleCharacters') handleCharacters: EventEmitter<any> = new EventEmitter();
  @Input('index') index: number;
  @Input('character') character: any;

  charactersControl = new FormControl();
  filteredCharacters: Observable<string[]>;

  public comicCharacters;

  constructor() { }

  ngOnInit() {
    this.comicCharacters = allCharacters;

    this.filteredCharacters = this.charactersControl.valueChanges.pipe(
      debounceTime(500),
      startWith(''),
      map(value => (value.length >= 2 ? this._filterCharacters(value) : []))
    );
  }

  private _filterCharacters(value: string): string[] {
    const filterValue = value.toLowerCase();
    return allCharacters
      .sort()
      .filter(option => option.toLowerCase().includes(filterValue));
  }

  handleInputChange = (evt, name) => {
    const payload = {
      value: evt,
      name,
      index: this.index,
      id: this.character ? this.character.id : null
    };
    this.handleCharacters.emit(payload);
  }

  remove = () => {
    this.removeCharacter.emit(this.index);
  }

}
