import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { APIURL } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ComicService {
  constructor(private http: HttpClient) { }

  public getAllComics() {
    const url = `${APIURL}/comics`;
    return this.http.get(url);
  }

  public getSingleComic(id) {
    const url = `${APIURL}/comics/${id}`;
    return this.http.get(url);
  }

  public addComic(
    title: string,
    universe: string,
    series: string,
    issue: any,
    volume: any,
    collectionVolume: any,
    publisher: string,
    publicationYear: any,
    publicationLocation: string,
    genre: string,
    type: string,
    pageCount: any,
    format: any,
    language: string,
    upc: string,
    credits: string,
    characters: string,
    notes: string,
    condition: string,
    selling: string,
    collection: string,
    trade: string,
    wishlist: string,
    files: File[],
    price: any,
    quantity: any
  ) {
    // tslint:disable: no-unused-expression
    const url = `${APIURL}/comics/add`;
    const formData = new FormData(); // Currently empty
    formData.append('title', title);
    formData.append('universe', universe);
    formData.append('series', series);
    formData.append('issue', issue);
    volume ? formData.append('volume', volume) : null;
    collectionVolume ? formData.append('collectionVolume', collectionVolume) : null;
    formData.append('publisher', publisher);
    formData.append('publicationYear', publicationYear);
    publicationLocation ? formData.append('publicationLocation', publicationLocation) : null;
    genre ? formData.append('genre', genre) : null;
    type ? formData.append('type', type) : null;
    pageCount ? formData.append('pageCount', pageCount) : null;
    format ? formData.append('format', format) : null;
    language ? formData.append('language', language) : null;
    upc ? formData.set('upc', upc) : null;
    credits ? formData.append('credits', credits) : null;
    notes ? formData.append('notes', notes) : null;
    formData.append('condition', condition);
    formData.append('sell', selling);
    formData.append('collection', collection);
    formData.append('trade', trade);
    formData.append('wishlist', wishlist);
    price ? formData.append('price', price) : null;
    quantity ? formData.append('quantity', quantity) : null;
    formData.append('credits', credits);
    formData.append('characters', characters);
    if (files) {
      if (files.length > 0) {
        files.map(file => {
          formData.append('images', file);
        });
      }
    }

    return this.http.post(url, formData);
  }

  public updateComic(
    id: string,
    title: string,
    universe: string,
    series: string,
    issue: any,
    volume: any,
    collectionVolume: any,
    publisher: string,
    publicationYear: any,
    publicationLocation: string,
    genre: string,
    type: string,
    pageCount: any,
    format: any,
    language: string,
    upc: string,
    credits: string,
    characters: string,
    notes: string,
    condition: string,
    selling: string,
    collection: string,
    trade: string,
    wishlist: string,
    files: File[],
    price: any,
    quantity: any
  ) {
    const url = `${APIURL}/comics/update/${id}`;
    const formData = new FormData(); // Currently empty
    title ? formData.append('title', title) : null;
    universe ? formData.append('universe', universe) : null;
    series ? formData.append('series', series) : null;
    issue ? formData.append('issue', issue) : null;
    volume ? formData.append('volume', volume) : null;
    collectionVolume ? formData.append('collectionVolume', collectionVolume) : null;
    publisher ? formData.append('publisher', publisher) : null;
    publicationYear ? formData.append('publicationYear', publicationYear) : null;
    publicationLocation ? formData.append('publicationLocation', publicationLocation) : null;
    genre ? formData.append('genre', genre) : null;
    type ? formData.append('type', type) : null;
    pageCount ? formData.append('pageCount', pageCount) : null;
    format ? formData.append('format', format) : null;
    language ? formData.append('language', language) : null;
    upc ? formData.append('upc', upc) : null;
    credits ? formData.append('credits', credits) : null;
    notes ? formData.append('notes', notes) : null;
    condition ? formData.append('condition', condition) : null;
    formData.append('sell', selling);
    formData.append('collection', collection);
    formData.append('trade', trade);
    formData.append('wishlist', wishlist);
    price ? formData.append('price', price) : null;
    quantity ? formData.append('quantity', quantity) : null;
    characters ? formData.append('characters', characters) : null;
    if (files) {
      if (files.length > 0) {
        files.map(file => {
          formData.append('images', file);
        });

      }

    }
    return this.http.put(url, formData);
  }

  public updateComicPhoto(id: string, files: File[]) {
    const url = `${APIURL}/comics/update/picture/${id}`;
    const formData = new FormData(); // Currently empty
    formData.append('image', files[0]);
    return this.http.put(url, formData);
  }

  public deleteComic(id: string) {
    const url = `${APIURL}/comics/delete/${id}`;
    return this.http.delete(url);
  }

  public deleteImage(fileId: number) {
    const url = `${APIURL}/comics/delete-image/${fileId}`;

    return this.http.delete(url);
  }

  public updateDefaultImage(files: any){
    const url = `${APIURL}/comics/update-default`;

    return this.http.put(url, {files});
  }
}
