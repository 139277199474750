import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { User } from 'src/app/models/user';
import { Observable } from 'rxjs';
import { CollectibleService } from 'src/app/services/collectible.service';
import { ComicService } from '../../services/comic.service';
import { updateCollectiblePicture } from '../../redux/actions/collecible.actions';

interface AppState {
  auth: User;
  comics: any;
}


@Component({
  selector: 'app-edit-collectible-cover-dialog',
  templateUrl: './edit-collectible-cover-dialog.component.html',
  styleUrls: ['./edit-collectible-cover-dialog.component.scss']
})
export class EditCollectibleCoverDialogComponent implements OnInit {

  public user: Observable<User>;
  public id;
  public files;
  public selectedImage;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditCollectibleCoverDialogComponent>,
    public collectibleService: CollectibleService,
    public auth: AuthService,
    public form: FormBuilder,
    private store: Store<AppState>,
    private comicService: ComicService) {
    this.user = this.store.select('auth');
  }

  ngOnInit() {
    this.selectedImage = this.data.images.find(image => image.isDefault ? image.isDefault : image);
  }

  handleSelectedImage = file => {
    this.selectedImage = file;
    this.data.images = this.data.images.map(image => image.id === file.id ? {...image, isDefault: 1} : {...image, isDefault: 0});
  }

  close() {
    this.dialogRef.close();
  }

  submit() {
    this.comicService.updateDefaultImage(JSON.stringify(this.data.images)).subscribe(async (res: any) => {
      if (res && !res.err) {
        const payload = {
          collectibleId: this.data.id,
          files: this.data.images
        };
        await this.store.dispatch(updateCollectiblePicture({payload}));
        this.close();
      }

    });
  }

}
