import { Action } from '@ngrx/store';
import { addMessage, addThread } from '../utils/message.utils';

export function messageReducer(state: any, action: Action) {
  const results: any = action;
  switch (action.type) {
    case 'SET_MESSAGES':
      return { threads: results.threads };
    case 'SELECT_THREAD':
      return { ...state, selectedThread: results.threadId };
    case 'ADD_MESSAGE':
      let newRes = {
        ...state,
        threads: addMessage(
          state.threads,
          results.message,
          state.selectedThread
        )
      };

      if (state.selectedThread === 0) {
        newRes.selectedThread = results.message.threadId;
      }

      return newRes;
    case 'ADD_THREAD':
      return {
        ...state,
        threads: addThread(state.threads, results.thread)
      };
    case 'REMOVE_SELECTED_THREAD':
      let newState: any = { ...state };
      delete newState.selectedThread;
      return newState;
    default:
      return state;
  }
}
