import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { APIURL } from "../../environments/environment.prod";

@Injectable({
  providedIn: "root"
})
export class MessengerService {
  constructor(private http: HttpClient) {}

  public sendMessage(message) {
    const url = `${APIURL}/messages/create-message`;
    return this.http.post(url, message);
  }

  public getUserMessages() {
    const url = `${APIURL}/messages`;
    return this.http.get(url);
  }

  public getThread(threadId) {
    const url = `${APIURL}/messages/get-thread`;
    return this.http.post(url, { threadId });
  }
}
