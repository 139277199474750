import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import {
  getAllDataFromLocalForage,
  default as localForage,
} from "ngrx-store-persist";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

enableProdMode();

getAllDataFromLocalForage({
  driver: localForage.INDEXEDDB,
  keys: [
    "auth",
    "userProfile",
    "cart",
    "comics",
    "collectibles",
    "route",
    "messages",
    "product",
  ],
}).then(() => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
