import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { HistoryService } from 'src/app/services/history.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { Store } from '@ngrx/store';
import { User } from 'src/app/models/user';
import { takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { addThread, selectThread } from 'src/app/redux/actions/message.actions';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { trigger, transition, style, animate, state } from '@angular/animations';

interface AppState {
  auth: User;
  messages: any;
}

@Component({
  selector: 'app-transaction-log-dialog',
  templateUrl: './transaction-log-dialog.component.html',
  styleUrls: ['./transaction-log-dialog.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(800, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        state('invisible', style({ opacity: 0 })),
        style({ opacity: 0 }),
        animate(800, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class TransactionLogDialogComponent implements OnInit {

  displayedColumns: string[] = ['imageUrl', 'item', 'seller', 'createdAt', 'messageSeller'];
  dataSource = new MatTableDataSource();

  log: any;
  public threads: any;
  public threadId: any;
  authUser: Observable<User>;
  public authUserId;
  public sellerInfo;
  public noTransactions;

  private onDestroy$ = new Subject<void>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TransactionLogDialogComponent>,
    private store: Store<AppState>,
    public auth: AuthService,
    private router: Router,
    public historyService: HistoryService) {
    this.authUserId = this.data.id;
    this.authUser = this.store.select('auth');
  }

  async ngOnInit() {
    this.historyService.getBuyerList(this.authUserId).subscribe(res => {
      this.log = res;
      this.dataSource.data = this.log.items;
      if (this.log.items.length <= 0) {
        this.noTransactions = true;
      }
    });
    this.store
      .select('messages')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => (this.threads = res.threads));

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public getUserInfo(userId) {
    this.auth.getUser(userId.id).subscribe(sellerInfo => {
      this.sellerInfo = sellerInfo;
      this.goToMessenger(this.sellerInfo);
    });
  }

  async goToMessenger(user) {
    const existing = this.threads.find(
      x => x.userOneId === user.id || x.userTwoId === user.id
    );
    this.threadId = existing ? existing.id : null;

    const threadId = existing ? existing.id : null;

    if (!threadId) {
      const thread: any = {
        id: 0,
        messages: []
      };
      thread.userTwo = user;
      thread.userTwoId = user.id;
      this.authUser.pipe(takeUntil(this.onDestroy$)).subscribe(currentAuth => {
        thread.userOne = currentAuth;
        thread.userOneId = currentAuth.id;
      });
      await this.store.dispatch(addThread({ thread }));
      await this.store.dispatch(selectThread({ threadId: thread.id }));
      this.router.navigateByUrl('/messenger');
    } else {
      await this.store.dispatch(selectThread(threadId));
      this.router.navigateByUrl('/messenger');
    }
    this.close();
  }

  close() {
    this.dialogRef.close();
  }
}

