// tslint:disable: quotemark
export const allPublishers = [
  "12 bis",
  "12-Gauge Comics",
  "G.E.E. Comics",
  "Naturist Comics",
  "Retrogame Comics",
  "Toho Comics",
  "1First Comics",
  "215 Ink",
  "451 Media Group",
  "Aardvark-Vanaheim",
  "Abacus Comics",
  "About Comics",
  "Abrams ComicArts",
  "AC Comics",
  "Academy Comics",
  "Ace Comics",
  "ACE Comics",
  "Ace Comics",
  "Actes Sud/l'AN 2",
  "Acetylene Comics",
  "Acid Rain Studios",
  "Action Lab Entertainment",
  "Action Lab Comics Signature Series",
  "AdHouse Books",
  "Adult Comics",
  "Adventure Publications",
  "African Prince Productions",
  "After Hours Press",
  "Aftershock Comics",
  "Aida-Zee Comics",
  "Aircel Comics",
  "A is A",
  "AiT/Planet Lar",
  "Ajax-Farrell",
  "Akita Shoten",
  "Alias Enterprises",
  "All-American Publications",
  "All Star DC Comics",
  "Alterna Comics",
  "Alternative Comics",
  "Amalgam Comics",
  "Amazing Comics",
  "American Comics Group",
  "America's Best Comics",
  "Americomics",
  "Anglo-American Publishing",
  "Angry Viking Press",
  "ANIA Publishing",
  "Ankama Editions",
  "Antarctic Press",
  "Ape Entertainment",
  "Apex Novelties, Inc.",
  "Apple Comics",
  "Approbation Comics",
  "Arcana Studio",
  "Arch Enemy Entertainment",
  "Archaia Studios Press",
  "Archie Adventure Comics",
  "Archie Comic Publications",
  "Ardden Entertainment",
  "Arktinen Banaani (Arctic Banana)",
  "Arrow Comics",
  "Arrow Manga",
  "Asahi Sonorama",
  "ASCII Media Works",
  "Asgardian Comics",
  "Aspen MLT",
  "L'Association",
  "Asuka",
  "Atlas Comics",
  "Atlas/Seaboard Comics",
  "Atlas Unleashed",
  "Attic Books",
  "Atomeka Press",
  "Atomic Book Company",
  "avant verlag",
  "Avatar Press",
  "Avon Comics",
  "Awesome Comics",
  "Axis Comics",
  "Azteca Productions",
  "Bad Habit",
  "Beckett Comics",
  "Bedside Press",
  "Bel-Ami edizioni",
  "Bell Features",
  "Belmont Books",
  "Beta 3 Comics",
  "Beyond Comics",
  "Big Bang Comics",
  "Big Umbrella",
  "Bitterkomix Pulp",
  "Black Eye Productions",
  "Black Flag",
  "Blackball Comics",
  "Black Hearted Press",
  "Blackthorne Publishing",
  "Blank Slate Books",
  "Blue Juice Comics",
  "Boneyard Press",
  "Bongo Comics",
  "Boom! Studios",
  "Boundless Comics",
  "Brain Scan Studios",
  "BroadSword Comics",
  "Broadway Comics",
  "Bubble Comics",
  "Bugs Comics",
  "Burlyman Entertainment",
  "Byron Preiss Visual Publications",
  "Bluewater Productions",
  "Caliber Comics",
  "Calvary Comics",
  "Capital Comics",
  "Cardal Publishing",
  "Carlsen Comics",
  "Cartoon Books",
  "Casterman",
  "Celebrity Comics",
  "Centaur Publications",
  "CFW Enterprises",
  "Champion City Comics",
  "Chaos! Comics",
  "Charlton Comics",
  "Checker Book Publishing Group",
  "Checkmate Comics",
  "Cinebook",
  "Clap Comix",
  "Class Comics",
  "Classical Comics",
  "Claypool Comics",
  "Cliffhanger",
  "CMX",
  "Coamix",
  "Coconino Press",
  "Colonia Press",
  "Columbia Comics",
  "Comely Comix",
  "Comic Chronicles",
  "Comania",
  "Comic Media",
  "Comico Comics",
  "comicplus+",
  "Comics' Greatest World",
  "Comics Interview Group",
  "ComicsOne",
  "Comic Zone Productions",
  "Com.x",
  "Conrad",
  "Continuity Comics",
  "Continüm Comics",
  "Core Magazine",
  "Crestwood Publications",
  "Cross Culture",
  "Cross Publications",
  "CrossGen Entertainment",
  "Crusade Comics",
  "Crystal Publications",
  "Cult Comics[150]",
  "Curtis Magazines",
  "Curtis Publishing Company",
  "Cutting Edge Productions",
  "Daak Comics",
  "Dabel Brothers Productions",
  "Dagger Enterprises",
  "DAPshow Press",
  "Dare Comics",
  "Dargaud",
  "Dargaud USA",
  "Dark Horse Comics",
  "Dark Horse Manga",
  "Darkside Comics",
  "David McKay Publications (also: David McKay Company)",
  "DC Comics",
  "D. C. Thomson & Co. Ltd",
  "Deadstar Publishing",
  "The Dead Timez Magazine",
  "Deep Sea Comics",
  "Defiant Comics",
  "Deimos Comics",
  "Delcourt",
  "Dell Comics",
  "Deluxe Comics",
  "Desperado Publishing",
  "Devil's Due Publishing",
  "Devil's Due Digital",
  "DieGo Comics Publishing",
  "Disney Comics",
  "Dragon Lady Press",
  "Drawn and Quarterly",
  "Dreamwave Productions",
  "DrMaster",
  "Dupuis",
  "DWAP Productions",
  "Dynamite Entertainment",
  "Eastern Color Printing",
  "EATComics",
  "EC Comics",
  "Eclipse Comics",
  "Edicions de Ponent",
  "Edition 52",
  "Eerie Publications",
  "E.F.edizioni",
  "eigoMANGA",
  "Elliot Publishing",
  "Elvifrance",
  "Emet Comics",
  "Epic Comics (also: Epic Comics Group)",
  "Eros Comix Italia",
  "Eternity Comics",
  "Eureka! Comic Labs",
  "Event Comics",
  "Evil Ink Comics",
  "Evil Twin Comics",
  "Extrem Erfolgreich Enterprises",
  "Extreme Studios",
  "FAB Comics",
  "Fan-Atic Press",
  "Fangoria Comics",
  "FantaCo Enterprises",
  "Fantagor Press",
  "Fantagraphics Books",
  "Fantasy Flight Publications",
  "Farrell Comic Group",
  "Farrell Publications",
  "Fawcett Comics",
  "Fiction House",
  "Fierce Comics",
  "Finix Comics",
  "First Comics",
  "Front Froid",
  "Forbidden Fruit",
  "Fox Atomic Comics",
  "Fox Feature Syndicate",
  "Frémok",
  "Friendly Comics",
  "Full Bleed Studios",
  "Futabasha",
  "Future Comics",
  "Futuropolis",
  "Futuropolis",
  "Gentosha",
  "Gestalt Publishing",
  "Gladstone Comics",
  "Glénat",
  "Glücklicher Montag",
  "Gold Key Comics",
  "Great Comics Publications",
  "GC Comics",
  "Greater Mercury Comics",
  "Guild Works Productions",
  "Guild Works Publications",
  "Hakusensha",
  "Hall of Heroes",
  "Harrier Comics",
  "Harris Comics",
  "Harvey Comics",
  "Heaven Sent Gaming",
  "Heeby Jeeby Comix",
  "Hellbound Media",
  "Hell Comics",
  "Hero Nation",
  "Hexagon Comics",
  "Highwater Books",
  "Hillborough Studios",
  "Hillman Periodicals",
  "Hirntot Comix",
  "Holyoke Publishing",
  "Homage",
  "Houbunsha",
  "Hound Comics",
  "Humanoids Publishing",
  "Human Comics Independent Publishing",
  "Humor Comics",
  "Humor Publications/",
  "Current Books",
  "Huuda Huuda",
  "Ichijinsha",
  "Icon",
  "Idaho Comics Group",
  "Ideenschmiede Paul & Paul (IPP)",
  "IDW Publishing",
  "I. W. Publications",
  "iBooks",
  "Image Comics",
  "Imperial Comics",
  "Imperium Comics",
  "Innovation Comics",
  "Insomnia Publications",
  "Imagine Worlds Comics",
  "Jademan Comics",
  "JBC",
  "JC Comics",
  "Jitsugyo no Nihon Sha",
  "Johnny DC",
  "Kadokawa Shoten",
  "Kami",
  "Kana",
  "Kandora Publishing",
  "Kasakura Shuppansha (Kasakura Publishing)",
  "Katzenjammer Comics",
  "Kazé",
  "King Comics",
  "Ki-oon",
  "Kitchen Sink Press",
  "Klutz Enterprises",
  "Kobunsha",
  "Kodansha",
  "Koyama Press",
  "Kymera Press",
  "Laizen Comics",
  "LAMP PoST Publications",
  "La Pastèque",
  "Laska Comix",
  "Last Gasp",
  "Comix & Stories",
  "Legend",
  "Legioncomix",
  "Le Lombard",
  "Les 400 coups",
  "Les Humanoïdes Associés",
  "Lev Gleason Publications",
  "Lightning Comics",
  "Lightning Comics",
  "Liquid Comics",
  "L. Miller & Son, Ltd.",
  "Lodestone Comics",
  "Lonely Robot Comics",
  "Ludovico Technique LLC",
  "Lucha Comics",
  "Mad Dog Graphics",
  "Magazine Enterprises",
  "Mag Garden",
  "Mainline Publications",
  "Majestic Entertainment",
  "Malibu Comics",
  "Mam Tor Publishing",
  "Manuscript Press",
  "Maple Leaf Publishing",
  "Markosia",
  "Marvel Comics",
  "Marvel Adventures",
  "Marvel Age",
  "Matrix Graphic Series",
  "Maverick",
  "Max Comics (MAX)",
  "Media Factory",
  "Michael Hunt Publishing",
  "Mighty Comics",
  "Milestone Media",
  "Mille-Îles",
  "Millennium Publications",
  "Milk Shadow Books",
  "MiniKomix",
  "Mirage Studios",
  "Mirror Comics",
  "Mirror Comics Studios",
  "M. F. Enterprises",
  "MLJ Magazines",
  "Mojo Press",
  "Moonstone Books",
  "MOSAIK Steinchen für Steinchen Verlag",
  "M Press",
  "MU Press",
  "MyInkComics.com",
  "NBM Publishing",
  "Neko Press",
  "Standard Comics",
  "New Baby Productions",
  "New Century Comics",
  "New Comics Group",
  "New England Comics",
  "New Sirius Productions",
  "Nifty Comics",
  "Nihon Bungeisha",
  "No Comprendo Press",
  "Noble Comics",
  "Northstar Publishing",
  "Novelty Press",
  "NOW Comics",
  "NPC Comics",
  "Omega Dream Distillery Publications - Oddpubs",
  "Off Shoot Comics",
  "Ohzora Publishing",
  "Ominous Press",
  "Oni Press",
  "Onward Comics",
  "Oog & Blik",
  "Oogachtend",
  "Orbit Publications",
  "Outpouring Comics",
  "Pacific Comics",
  "Paradox Press",
  "Paragon Publications",
  "Perro Muerto Producciones",
  "Penny-Farthing Press",
  "Personality Comics",
  "Phi3 COMICS",
  "Pied Piper Comics",
  "Pika Édition",
  "Pines Comics",
  "Piranha Press",
  "Piredda Verlag",
  "Planet Random Creative",
  "Plasma Heroes",
  "Plem Plem Productions!",
  "Pocket Watch Books",
  "Portal Comics",
  "Power Comics Company",
  "Pow Pow Press",
  "Print Mint",
  "Print Media",
  "Prism Comics",
  "Pyramid Publishing",
  "Quality Comics",
  "Radical Comics",
  "Radio Comics",
  "Radio Comix",
  "Raj Comics",
  "Real Life Comics",
  "Raytoons Comics",
  "Reasonably Priced Comics",
  "Rebellion Developments",
  "Rebel Studios",
  "Red 5 Comics",
  "Red Circle Comics",
  "Red Giant Entertainment",
  "Renegade Press",
  "Reprodukt",
  "Retrofit Comics",
  "Revil Comics",
  "Re-Visionary Press",
  "Revolutionary Comics",
  "Rip Off Press",
  "Robot Comics",
  "RoninComics",
  "Rolf Kauka Comics",
  "Rough Cut Comics",
  "Rural Home",
  "Scattered Comics",
  "schreiber & leser",
  "Schwarzer Turm",
  "Seoulmunhwasa",
  "Sequential Pulp Comics",
  "Seven Seas Entertainment",
  "ShadowLine",
  "Shanda Fantasy Arts",
  "Shinshokan",
  "Shinchosha",
  "Shodensha",
  "Shogakukan",
  "Shōnen Gahōsha",
  "Shooting Star Comics",
  "Shueisha",
  "Shuppan Manga",
  "Silverline",
  "Silver Moon Comics",
  "Silverwolf Comics",
  "Sin City Comics",
  "Sirius Comics",
  "Sirius Entertainment",
  "Skywald Publications",
  "Slave Labor Graphics",
  "So Cherry Studios",
  "Soleil Productions",
  "Solson Publications",
  "Spark Publications",
  "Spectrum Comics",
  "Spectrum Comics",
  "Spilt Ink",
  "Spire Christian Comics",
  "Spoof Comics",
  "Sports Comics",
  "Spotlight Comics",
  "Square Enix",
  "St. John Publications",
  "Stanley Publications",
  "Star Publications",
  "Starblaze Graphics",
  "Star*Reach",
  "Strawberry Jam Comics",
  "Street & Smith Comics",
  "Studio Ironcat",
  "Superior Publishers Limited",
  "Takeshobo",
  "Tekno Comix",
  "Terminal Press",
  "Teshkeel Comics",
  "Texas Comics",
  "Thoughts & Images",
  "Timely Comics",
  "The Library of American Comics",
  "THENEXTART",
  "Titan Books",
  "Toby Press",
  "Todd McFarlane Productions",
  "Tokuma Shoten",
  "Tokyopop",
  "Top Cow Productions",
  "Topps Comics",
  "Top Shelf Comics",
  "Totenkopf Verlag",
  "Tower Comics",
  "Trident Comics",
  "Trojan Magazines",
  "Triumphant Comics",
  "Tundra Publishing",
  "UDON",
  "Ultimate Marvel",
  "UPN-Volksverlag",
  "Valiant Comics",
  "Verotik",
  "Vertigo Comics",
  "Vimanika Comics",
  "Vimanika Comics UK",
  "Viper Comics",
  "Virgin Comics",
  "VIZ Media",
  "Volksverlag",
  "Vortex Comics",
  "Wandering Star Press",
  "Warp Graphics",
  "Warren Publishing",
  "Weildarum-Verlag",
  "Weissblech Comics",
  "Darkside Comics",
  "WildStorm",
  "Wonder Comics",
  "Yaoi Press",
  "Youthful",
  "Zenescope Entertainment",
  "Zuda",
  "Zwerchfell Verlag",
];
