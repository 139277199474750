import { Action } from '@ngrx/store';


export function comicReducer(state = {}, action: Action) {
  const results: any = action;
  const comics: any = state;
  let newState;
  let comic;
  switch (action.type) {
    case 'VIEW_COMICS':
      return { ...state, comics: results.comics };
    case 'SELECTED_COMIC':
      const selectedComic = comics.comics.find(chosenComic => chosenComic.id === results.id);
      return { ...state, selectedComic };
    case 'UPDATE_COMIC_PICTURE':
      newState = comics;
      comic = newState.comics.find(comic => comic.id === results.payload.comicId);
      comic.images = results.payload.files.sort((a, b) => b.isDefault - a.isDefault);
      return newState;
    case 'REMOVE_COMIC_IMAGE':
      newState = comics.comics;
      comic = newState.find(comic => comic.id === results.payload.comicId);
      const newImages = comic.images.filter(image => image.id !== results.payload.fileId);
      comic.images = newImages;
      return { selectedComic: comic, comics: newState }
    default:
      return state;
  }
}
