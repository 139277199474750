import { Component, Input, Output, EventEmitter, ViewChild, ContentChildren, QueryList, ElementRef } from '@angular/core';
import {
  DOWN_ARROW,
  END,
  ENTER,
  HOME,
  LEFT_ARROW,
  RIGHT_ARROW,
  SPACE,
  UP_ARROW
} from '@angular/cdk/keycodes';
import { PanelComponent } from '../panel/panel.component';
import { OptionComponent } from '../option/option.component';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent {

  @Input()
  placeholder: string;

  @Input()
  disabled = false;

  @Input()
  selectedItemValue: any;

  @Input()
  error: string;

  @Input()
  clear: boolean;

  @Output()
  selectionChange: EventEmitter<number> = new EventEmitter();

  @ViewChild('select', { static: false })
  select: ElementRef;

  @ViewChild(PanelComponent, { static: true })
  panel: PanelComponent;

  @ContentChildren(OptionComponent, { descendants: true })
  options: QueryList<OptionComponent>;

  focusedItemIndex = 0;
  focusedElement: HTMLElement;

  selectKeys = [ ENTER, SPACE ];
  pageKeys = [ HOME, END ];
  advanceSelectionKeys = [ DOWN_ARROW, RIGHT_ARROW ];
  reverseSelectionKeys = [ UP_ARROW, LEFT_ARROW ];

  getSelectedItemOption(): string {
    let selectedItemDisplayValue = '';

    const optionsArr = this.options.toArray();
    if (optionsArr.length === 0) {
      return;
    }

    const selectedOptionComponent = optionsArr.find(op => op.value === this.selectedItemValue);
    if (selectedOptionComponent) {
      selectedItemDisplayValue = selectedOptionComponent.elem.nativeElement.firstElementChild.innerText;
    }

    return selectedItemDisplayValue;
  }

  handleKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.disabled) {
      return;
    }

    this.panel.show ? this.handleOpenKeyDown(event) : this.handleClosedKeyDown(event);
  }

  handleOpenKeyDown(event: KeyboardEvent): void {
    const { keyCode } = event;
    const optionsArr = this.options.toArray();
    if (optionsArr.length === 0) {
      return;
    }

    if (this.pageKeys.includes(keyCode)) {
      const optionIndex = keyCode === HOME ? 0 : optionsArr.length - 1;
      this.focusItem(optionIndex);
    } else if (this.selectKeys.includes(keyCode)) {
      this.selectActiveItem(this.options.toArray()[this.focusedItemIndex].value)
    } else if (this.advanceSelectionKeys.includes(keyCode)) {
      this.focusItem(this.focusedItemIndex + 1);
    } else if (this.reverseSelectionKeys.includes(keyCode)) {
      this.focusItem(this.focusedItemIndex - 1);
    }
  }

  handleClosedKeyDown(event: KeyboardEvent): void {
    const { keyCode } = event;
    const optionsArr = this.options.toArray();
    if (optionsArr.length === 0) {
      return;
    }

    if (this.pageKeys.includes(keyCode)) {
      const option = keyCode === HOME ? this.options.first.value : this.options.last.value;
      this.selectActiveItem(option);
    } else if (this.selectKeys.includes(keyCode)) {
      this.open(event);
    } else if (this.advanceSelectionKeys.includes(keyCode)) {
      const newItemIndex = optionsArr.findIndex(item => item.value === this.selectedItemValue) + 1;

      if (newItemIndex === optionsArr.length) {
        return;
      }
      this.selectActiveItem(optionsArr[newItemIndex].value);
    } else if (this.reverseSelectionKeys.includes(keyCode)) {
      const selectedItemIndex = optionsArr.findIndex(item => item.value === this.selectedItemValue);
      if (selectedItemIndex <= 0) {
        this.selectActiveItem(null);
        return;
      }
      this.selectActiveItem(optionsArr[selectedItemIndex - 1].value);
    }
  }


  focusItem(index: number = 0): void {
      const options = document.getElementsByClassName('option');

      if (options && options.length) {
        if (index > options.length - 1 || index < 0) {
          return;
        }

        (options[index] as HTMLElement).focus();
        this.focusedItemIndex = index;
        this.focusedElement = options[index] as HTMLElement;
      }
  }

  selectActiveItem(val: number): void {
    this.selectedItemValue = val;
    this.selectionChange.emit(val);
    this.panel.close();
    if (document.activeElement === this.focusedElement) {
      this.select.nativeElement.focus();
    }
  }

  open(event: KeyboardEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (!this.disabled) {
      this.panel.open();
      const selectedItemIndex = this.options.toArray().findIndex(option => option.value === this.selectedItemValue);
      setTimeout(() => this.focusItem(Math.max(selectedItemIndex, 0)));
    }
  }

  close(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.panel.close();
    const options = document.getElementsByClassName('option');
    if (options && options.length) {
      // tslint:disable-next-line: deprecation
      this.focusedElement = event.srcElement as HTMLElement;
      const selectedOptionIndex = Array.from(options).findIndex(op => op === this.focusedElement);
      this.focusedItemIndex = Math.max(selectedOptionIndex, 0);
      this.selectActiveItem(this.options.toArray()[this.focusedItemIndex].value)
    }

    setTimeout(() => this.select.nativeElement.focus());
  }
}
