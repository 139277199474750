import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { ComicService } from "src/app/services/comic.service";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { Store } from "@ngrx/store";
import { setAllComics, selectedComic } from "../../redux/actions/comic.actions";
import { setRoute } from "../../redux/actions/router.actions";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material";
import { goToUserProfile } from "src/app/redux/actions/user.actions";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { updateUser } from "src/app/redux/actions/auth.actions";
import { CollectibleService } from "src/app/services/collectible.service";
import {
  selectedCollectible,
  setAllCollectibles,
} from "src/app/redux/actions/collecible.actions";
import { ElementRef } from "@angular/core";
import { setProduct } from "src/app/redux/actions/product.actions";

interface AppState {
  comics: {};
  collectibles: any;
}

@Component({
  selector: "app-home",
  templateUrl: "./hometest.component.html",
  styleUrls: ["./hometest.component.scss"],
  animations: [
    trigger("fade", [
      transition("void => *", [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        state("invisible", style({ opacity: 0 })),
        style({ opacity: 0 }),
        animate(800, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class HomeComponent implements OnInit {
  comics: any;
  collectibles: any;
  getAllFilteredComics;
  getAllFilteredCollectibles;
  allUsers;
  filteredUsers;
  empty;
  loading;
  footerCheck;
  authUserId;
  authUserRefresh;
  collection = [];
  p = 1;
  private onDestroy$ = new Subject<void>();

  constructor(
    public auth: AuthService,
    public comicService: ComicService,
    public collectibleService: CollectibleService,
    private store: Store<AppState>,
    private matSnackbar: MatSnackBar,
    private router: Router,
    public myElement: ElementRef
  ) {
    this.auth.getUser$().subscribe((res) => {
      if (res) {
        this.auth.getUser(res.sub).subscribe((currentAuthUser) => {
          // this.authUserRefresh = currentAuthUser;
          // console.log(this.authUserRefresh)
          // this.store.dispatch(updateUser({ user: this.authUserRefresh }));
        });
      }
    });
  }

  async ngOnInit() {
    this.loading = true;
    this.store.dispatch(setRoute({ route: this.router.url }));
    await this.comicService
      .getAllComics()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        this.loading = false;
        if (res) {
          this.comics = res;
          const filtered = this.comics.comics.filter(
            (v, i, a) =>
              a.findIndex(
                (t) =>
                  t.title.toString().trim().toLowerCase() ===
                    v.title.toString().trim().toLowerCase() &&
                  t.wishlist !== true
              ) === i
          );
          this.store.dispatch(setAllComics({ comics: filtered }));
          this.store
            .select("comics")
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((results) => {
              this.getAllFilteredComics = this.shuffle(results);
            });
        }
      });

    await this.collectibleService
      .getAllCollectibles()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        this.loading = false;
        if (res) {
          this.collectibles = res;
          const filtered = this.collectibles.collectibles.filter(
            (v, i, a) =>
              a.findIndex(
                (t) =>
                  t.name.toString().trim().toLowerCase() ===
                    v.name.toString().trim().toLowerCase() &&
                  t.wishlist !== true
              ) === i
          );
          this.store.dispatch(setAllCollectibles({ collectibles: filtered }));
          this.store
            .select("collectibles")
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((results) => {
              this.getAllFilteredCollectibles = this.shuffle(results);
            });
        }
      });

    this.auth
      .getAllUsers()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        this.allUsers = res;
        this.filteredUsers = this.allUsers.filter((x) => {
          return x.profilePhoto !== null;
        });
        this.filteredUsers.sort((a, b) => {
          const collectionA = a.createdAt;
          const collectionB = b.createdAt;
          return collectionA > collectionB
            ? -1
            : collectionA < collectionB
            ? 1
            : 0;
        });
      });

    this.gotoTop();
  }

  goToViewProduct(item: any, product: string) {
    this.store.dispatch(setProduct({ product }));
    if (product === "comic") {
      this.store.dispatch(selectedComic({ id: item.id }));
    }
    if (product === "collectible") {
      this.store.dispatch(selectedCollectible({ id: item.id }));
    }
    // this.store.dispatch(goToUserProfile({ user: item.owner }));
    this.router.navigateByUrl("/product");
  }

  goToUserProfile(user) {
    this.store.dispatch(goToUserProfile({ user: user }));
    this.router.navigateByUrl(`/profile/${user.username}`);
  }

  browseCollectables() {
    this.router.navigateByUrl("/browse-collectibles");
  }

  browseComics() {
    this.router.navigateByUrl("/browse-comics");
  }

  browseUsers() {
    this.router.navigateByUrl("/browse-users");
  }

  browseAll() {
    this.router.navigateByUrl("/browse");
  }

  browseScroing() {
    this.router.navigateByUrl("/comic-grading");
  }

  shuffle(list) {
    if (list.comics) {
      return list.comics.reduce((p, n) => {
        const size = p.length;
        const index = Math.trunc(Math.random() * (size - 1));
        p.splice(index, 0, n);
        return p;
      }, []);
    }
    if (list.collectibles) {
      return list.collectibles.reduce((p, n) => {
        const size = p.length;
        const index = Math.trunc(Math.random() * (size - 1));
        p.splice(index, 0, n);
        return p;
      }, []);
    }
  }

  gotoTop() {
    document.getElementById("master-container").scrollIntoView();
  }
}
