import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {

  @Input()
  placeholder: string[] = [];

  @Input()
  disabled = false;

  @Input()
  inputValue: any;

  @Input()
  error: string;

  @Input()
  type: string;

  @Input() test: string;

  @Output() inputChange: EventEmitter<number> = new EventEmitter();
  @Output() testChange = new EventEmitter<string>();

  myControl = new FormControl();
  filteredOptions: Observable<string[]>;

  updateValue(): void {
    this.inputChange.emit(this.inputValue);
  }

  updateTest(): void {
    this.testChange.emit(this.test);
  }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.placeholder.filter(option => option.toLowerCase().includes(filterValue));
  }

}
