import {
  Component,
  OnDestroy,
  ViewChild,
  ElementRef,
  OnInit
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import _ from 'lodash';
import moment from 'moment';

import { AuthService } from 'src/app/services/auth.service';
import { MessengerService } from '../../services/messenger.service';
import {
  selectThread,
  addMessage,
  removeSelectedThread
} from '../../redux/actions/message.actions';
import { User } from 'src/app/models/user';
import { setRoute } from '../../redux/actions/router.actions';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { trigger, transition, style, animate, state } from '@angular/animations';

interface AppState {
  auth: any;
  messages;
}

@Component({
  selector: 'app-messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['./messenger.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        state('invisible', style({ opacity: 0 })),
        style({ opacity: 0 }),
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class MessengerComponent implements OnInit, OnDestroy {
  @ViewChild('msgContainer', { static: false }) msgContainer: ElementRef;
  public message: string;
  public threads: any;
  public profileJson: string = null;
  public authUser: User;
  public selectedThread;
  public container;
  private onDestroy$ = new Subject<void>();

  constructor(
    public auth: AuthService,
    private msg: MessengerService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.store.dispatch(setRoute({ route: this.router.url }));
    this.store.select('auth').pipe(takeUntil(this.onDestroy$)).subscribe(res => {
      this.authUser = res;
    });
    this.store.select('messages').pipe(takeUntil(this.onDestroy$)).subscribe(res => {
      if (res.threads && res.threads.length > 0) {
        res.threads.map(thread => {
          const { messages } = thread;
          const index = messages.length > 0 ? messages.length - 1 : 0;
          thread.fromNow =
            messages.length > 0
              ? moment(messages[index].createdAt).fromNow(true)
              : null;
        });
        this.threads = res.threads.sort((a, b) => {
          const indexA = a.messages.length > 0 ? a.messages.length - 1 : 0;
          const indexB = b.messages.length > 0 ? b.messages.length - 1 : 0;
          return (a.messages[indexA].createdAt > b.messages[indexB].createdAt) ? -1 : (a.messages[indexA].createdAt < b.messages[indexB].createdAt) ? 1 : 0;
        });
        this.selectedThread = res.threads.find(
          thread => thread.id === res.selectedThread
        );
      }
    });
    window.scrollTo(0, 0);
  }

  ngOnDestroy() {
    this.store.dispatch(removeSelectedThread());
  }

  public sendMessage(evt) {
    evt.target.value = '';

    const key =
      this.selectedThread.userOne.id !== this.authUser.id
        ? 'userOne'
        : 'userTwo';
    const toId = this.selectedThread[key].id;

    const message = {
      message: this.message,
      userId: toId,
      threadId: this.selectedThread.id
    };

    this.msg.sendMessage(message).pipe(takeUntil(this.onDestroy$)).subscribe((res: any) => {
      this.store.dispatch(addMessage({ message: res.message }));
    });
    this.message = '';
  }

  public handleEnter(evt) {
    if (evt.key === 'Enter') {
      evt.preventDefault();
      this.sendMessage(evt);
    }
  }

  public async selectThread(threadId) {
    await this.store.dispatch(selectThread({ threadId }));
  }
}
