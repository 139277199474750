import { Component, OnInit } from '@angular/core';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { TermsOfServiceDialogComponent } from 'src/app/dialogs/terms-of-service-dialog/terms-of-service-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { OptOutDialogComponent } from 'src/app/dialogs/opt-out-dialog/opt-out-dialog.component';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-footer-home',
  templateUrl: './footer-home.component.html',
  styleUrls: ['./footer-home.component.scss']
})
export class FooterHomeComponent implements OnInit {
  faCoffee = faCoffee;
  name = '';
  email = ''
  private onDestroy$ = new Subject<void>();

  constructor(private mdDialog: MatDialog) { }

  ngOnInit() {
  }

  goToTermsOfService() {
    this.mdDialog.open(TermsOfServiceDialogComponent);
  }

  goToOptOut() {
    this.mdDialog.open(OptOutDialogComponent);
  }

}
