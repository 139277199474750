import { Action } from '@ngrx/store';
import { User } from '../../models/user';

export function profileReducer(state: User = null, action: Action) {
  const results: any = action;
  switch (action.type) {
    case 'SEE_USER_PROFILE':
      return results.user;
    default:
      return state;
  }
}
