import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-opt-out-dialog',
  templateUrl: './opt-out-dialog.component.html',
  styleUrls: ['./opt-out-dialog.component.scss']
})
export class OptOutDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<OptOutDialogComponent>) {}

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
