import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { STRIPEID } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  profileName;

  constructor(private auth: AuthService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.includes('http://localhost:3000/users/getByUsername' || 'https://collectaverse-api.herokuapp.com/users/getByUsername')) {
      const username: string = window.location.pathname.split('/').pop();
      if (req.url === `http://localhost:3000/users/getByUsername/${username}` || `https://collectaverse-api.herokuapp.com/users/getByUsername/${username}`) {
        return next.handle(req);
      }
    }
    if (req.url.trim() == 'http://localhost:3000/comics' || 'https://collectaverse-api.herokuapp.com/comics') {
      if (req.url.trim() === 'http://localhost:3000/comics/add' || 'https://collectaverse-api.herokuapp.com/comics/add') {
        if (this.auth.loggedIn) {
          return this.auth.getTokenSilently$().pipe(
            mergeMap(token => {
              const tokenReq = req.clone({
                setHeaders: { Authorization: `Bearer ${token}` }
              });
              return next.handle(tokenReq);
            }),
            catchError(err => throwError(err))
          );
        }
      }
      return next.handle(req);
    }
    if (req.url.trim() == 'http://localhost:3000/users/getall' || 'https://collectaverse-api.herokuapp.com/users/getall') {
      return next.handle(req);
    }
    if (req.url.trim() == 'http://localhost:3000/collectibles' || 'https://collectaverse-api.herokuapp.com/collectibles') {
      if (req.url.trim() == 'http://localhost:3000/collectibles/add' || 'https://collectaverse-api.herokuapp.com/collectibles/add') {
        if (this.auth.loggedIn) {
          return this.auth.getTokenSilently$().pipe(
            mergeMap(token => {
              const tokenReq = req.clone({
                setHeaders: { Authorization: `Bearer ${token}` }
              });
              return next.handle(tokenReq);
            }),
            catchError(err => throwError(err))
          );
        }
      }
      return next.handle(req);
    }
    return this.auth.getTokenSilently$().pipe(
      mergeMap(token => {
        const tokenReq = req.clone({
          setHeaders: { Authorization: `Bearer ${token}` }
        });
        if (req.url == 'https://api.stripe.com/v1/tokens') {
          token = STRIPEID;
        }
        return next.handle(tokenReq);
      }),
      catchError(err => throwError(err))
    );
  }
}
