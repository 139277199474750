import { Injectable } from "@angular/core";
import * as io from "socket.io-client";
import { Observable } from "rxjs";
import { APIURL } from "../../environments/environment.prod";

@Injectable({
  providedIn: "root"
})
export class WebsocketService {
  private socket: any;

  constructor() {
    this.socket = io(APIURL);
  }

  public listen(eventName: string) {
    return new Observable(subscriber => {
      this.socket.on(eventName, data => {
        subscriber.next(data);
      });
    });
  }

  public emit(eventName: string, data: any) {
    this.socket.emit(eventName, data);
  }

  public setClient(userId) {
    this.emit("setClient", userId);
  }

  public removClient(userId) {
    this.socket.emit("removeClient", userId);
  }
}
