import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { User } from 'src/app/models/user';
import { setRoute } from 'src/app/redux/actions/router.actions';

interface AppState {
  comics: any;
  userProfile: User;
  auth: User;
  collectibles: any;
  product: any;
}

@Component({
  selector: 'app-comic-grading',
  templateUrl: './comic-grading.component.html',
  styleUrls: ['./comic-grading.component.scss']
})
export class ComicGradingComponent implements OnInit {

  constructor(
    private store: Store<AppState>,
    private router: Router) { }

  ngOnInit() {
    this.store.dispatch(setRoute({ route: this.router.url }));
    window.scrollTo(0, 0);
  }

}
