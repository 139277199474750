import {
  Directive,
  HostListener,
  HostBinding,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[fileDropZone]'
})
export class FileDropZoneDirective {
  @Input() public accept: string[];
  @Output() public filesChangeEmiter: EventEmitter<File[]> = new EventEmitter();
  @Output() public filesInvalidEmiter: EventEmitter<File[]> = new EventEmitter();

  constructor() { }

  @HostListener('dragover', ['$event']) public onDragOver(evt) {
    this.stopEvents(evt);
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    this.stopEvents(evt);
  }

  @HostListener('drop', ['$event']) public onDrop(evt) {
    this.stopEvents(evt);

    const files = evt.dataTransfer.files;
    const validFiles: Array<File> = [];
    const invalidFiles: Array<File> = [];

    if (files.length > 0) {
      for (const file of files) {
        if (this.checkFileType(file)) {
          validFiles.push(file);
        } else {
          invalidFiles.push(file);
        }
      }

      if (validFiles.length > 0) {
        this.filesChangeEmiter.emit(validFiles);
      }
      if (invalidFiles.length > 0) {
        this.filesInvalidEmiter.emit(invalidFiles);
      }
    }
  }

  checkFileType(file: File): boolean {
    for (const acceptedFileType of this.accept) {
      if (file.type.includes(acceptedFileType) || file.name.includes(acceptedFileType)) {
        return true;
      }
    }
    return false;
  }

  private stopEvents(event: any): any {
    event.preventDefault();
    event.stopPropagation();
  }
}
