import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { setRoute } from 'src/app/redux/actions/router.actions';

interface AppState {
  auth: any;
}

@Component({
  selector: 'app-just-us-league',
  templateUrl: './new-just-us-league.component.html',
  styleUrls: ['./new-just-us-league.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(800, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        state('invisible', style({ opacity: 0 })),
        style({ opacity: 0 }),
        animate(800, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class JustUsLeagueComponent implements OnInit {

  constructor(
    private store: Store<AppState>,
    private router: Router
  ) { }

  ngOnInit() {
    this.store.dispatch(setRoute({ route: this.router.url }));
    window.scrollTo(0, 0);
  }

}
