import { Component, OnInit, ViewChild } from '@angular/core';
import { CollectibleService } from 'src/app/services/collectible.service';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { ViewToyDialogComponent } from 'src/app/dialogs/view-toy-dialog/view-toy-dialog.component';
import { User } from 'src/app/models/user';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { setAllCollectibles, selectedCollectible } from 'src/app/redux/actions/collecible.actions';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { goToUserProfile } from 'src/app/redux/actions/user.actions';
import { takeUntil } from 'rxjs/operators';
import { setRoute } from 'src/app/redux/actions/router.actions';
import { Router } from '@angular/router';
import { setProduct } from 'src/app/redux/actions/product.actions';

export interface ColleciblesFilter {
  universe: string;
  name: string;
  brand: string;
  sell: string;
  trade: string;
  collection: string;
  wishlist: string;
}

interface AppState {
  comics: any;
  userProfile: User;
  authUser: User;
  product: any;
}

@Component({
  selector: 'app-browse-toys',
  templateUrl: './browse-toys.component.html',
  styleUrls: ['./browse-toys.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(800, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        state('invisible', style({ opacity: 0 })),
        style({ opacity: 0 }),
        animate(800, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class BrowseToysComponent implements OnInit {

  public user: Observable<User>;
  public authUser: Observable<User>;

  public isCollapsed = true;
  public allToys;
  public noToys = false;

  globalSearch: FormGroup;
  collectibleFilter: FormGroup;
  dataSource = new MatTableDataSource();
  allFilter = new FormControl('');
  universeFilter = new FormControl('');
  nameFilter = new FormControl('');
  brandFilter = new FormControl('');
  sellFilter = new FormControl('');
  tradeFilter = new FormControl('');
  collectionFilter = new FormControl('');
  wishlistFilter = new FormControl('');

  filterValues = {
    universe: '',
    name: '',
    brand: '',
    sell: '',
    trade: '',
    collection: '',
    wishlist: ''
  };

  globalFilter = '';

  private onDestroy$ = new Subject<void>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public collectibleService: CollectibleService,
    private mdDialog: MatDialog,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private router: Router) {
    this.dataSource.filterPredicate = this.createFilter();
    this.collectibleFilter = fb.group({
      universe: '',
      name: '',
      brand: '',
      sell: '',
      trade: '',
      collection: '',
      wishlist: ''
    });
    this.globalSearch = fb.group({
      masterSearch: ''
    });
  }

  form: FormGroup = new FormGroup({
    universe: new FormControl(false),
    name: new FormControl(false),
    brand: new FormControl(false),
    sell: new FormControl(false),
    trade: new FormControl(false),
    collection: new FormControl(false),
    wishlist: new FormControl(false)
  });

  universe = this.form.get('universe');
  name = this.form.get('name');
  brand = this.form.get('brand');
  sell = this.form.get('sell');
  trade = this.form.get('trade');
  collection = this.form.get('collection');
  wishlist = this.form.get('wishlist');

  cbValues;

  displayedColumns = [
    { def: 'imageUrl', label: 'Image', hide: '' },
    { def: 'universe', label: 'Universe', hide: this.universe.value },
    { def: 'name', label: 'Name', hide: this.name.value },
    { def: 'brand', label: 'Brand', hide: this.brand.value },
    { def: 'sell', label: 'sell', hide: this.sell.value },
    { def: 'trade', label: 'trade', hide: this.trade.value },
    { def: 'collection', label: 'collection', hide: this.collection.value },
    { def: 'wishlist', label: 'wishlist', hide: this.wishlist.value }
  ];

  getDisplayedColumns(): string[] {
    return this.displayedColumns.filter(cd => !cd.hide).map(cd => cd.def);
  }

  ngOnInit() {
    // this.store.dispatch(setRoute({ route: '/browseCollectibles' }));
    this.displayedColumns[4].hide = true;
    this.displayedColumns[5].hide = true;
    this.displayedColumns[6].hide = true;
    this.displayedColumns[7].hide = true;
    if (window.screen.width < 450) { // 768px portrait
      this.displayedColumns[1].hide = true;
      this.displayedColumns[3].hide = true;
    }
    if (window.screen.width === 768) { // 768px portrait
      this.displayedColumns[1].hide = true;
    }
    if (window.screen.width === 1024 && window.screen.height === 1366) {
      this.displayedColumns[1].hide = true;
    }
    if (window.screen.width === 810 && window.screen.height === 1080) {
      this.displayedColumns[1].hide = true;
    }
    this.collectibleService.getAllCollectibles().pipe(takeUntil(this.onDestroy$)).subscribe(res => {
      this.allToys = res;
      this.allToys.collectibles.filter(removeNull => {
        if (removeNull.wishlist === null) {
          return removeNull.wishlist = false;
        }
      });
      if (this.allToys.length === 0) {
        this.noToys = true;
      }
      this.dataSource.data = this.shuffle(this.allToys.collectibles.filter(toys => {
        if (toys.sell === true) {
          return toys.quantity > 0;
        }
        return toys.images[0] != null;
      }));
      this.store.dispatch(setAllCollectibles({ collectibles: this.dataSource.data }));
    });

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.filterRefresh();
  }

  filterRefresh() {
    this.universeFilter.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        universe => {
          this.filterValues.universe = universe;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        }
      );
    this.nameFilter.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        name => {
          this.filterValues.name = name;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        }
      );
    this.brandFilter.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        brand => {
          this.filterValues.brand = brand;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        }
      );
    this.sellFilter.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        sell => {
          if (sell === false) {
            this.filterValues.sell = '';
          } else {
            this.filterValues.sell = sell.toString();
          }
          this.dataSource.filter = JSON.stringify(this.filterValues);
        }
      );
    this.tradeFilter.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        trade => {
          if (trade === false) {
            this.filterValues.trade = '';
          } else {
            this.filterValues.trade = trade.toString();
          }
          this.dataSource.filter = JSON.stringify(this.filterValues);
        }
      );
    this.collectionFilter.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        collection => {
          if (collection === false) {
            this.filterValues.collection = '';
          } else {
            this.filterValues.collection = collection.toString();
          }
          this.dataSource.filter = JSON.stringify(this.filterValues);
        }
      );
    this.wishlistFilter.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        wishlist => {
          if (wishlist === false) {
            this.filterValues.wishlist = '';
          } else {
            this.filterValues.wishlist = wishlist.toString();
          }
          this.dataSource.filter = JSON.stringify(this.filterValues);
        }
      );
  }

  public applyFilter(filter) {
    this.globalFilter = filter;
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  createFilter() {
    const filterFunction = (data: ColleciblesFilter, filter: string): boolean => {
      let globalMatch = !this.globalFilter;
      if (this.globalFilter) {
        // search all text fields
        globalMatch = data.universe.toString().trim().toLowerCase().indexOf(this.globalFilter.toLowerCase()) !== -1
          || data.name.toString().trim().toLowerCase().indexOf(this.globalFilter.toLowerCase()) !== -1
          || data.brand.toString().trim().toLowerCase().indexOf(this.globalFilter.toLowerCase()) !== -1;
      }

      if (!globalMatch) {
        return;
      }
      const searchTerms = JSON.parse(filter);
      return data.universe.trim().toLowerCase().indexOf(searchTerms.universe.toLowerCase()) !== -1
        && data.name.trim().toLowerCase().indexOf(searchTerms.name.toLowerCase()) !== -1
        && data.brand.trim().toLowerCase().indexOf(searchTerms.brand.toLowerCase()) !== -1
        && data.sell.toString().trim().toLowerCase().indexOf(searchTerms.sell.toLowerCase()) !== -1
        && data.trade.toString().trim().toLowerCase().indexOf(searchTerms.trade.toLowerCase()) !== -1
        && data.collection.toString().trim().toLowerCase().indexOf(searchTerms.collection.toLowerCase()) !== -1
        && data.wishlist.toString().trim().toLowerCase().indexOf(searchTerms.wishlist.toLowerCase()) !== -1;

    };
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    return filterFunction;
  }

  async clearFilters() {
    this.dataSource.filter = '';
    this.filterValues.universe = '';
    this.filterValues.name = '';
    this.filterValues.brand = '';
    this.filterValues.sell = '';
    this.filterValues.collection = '';
    this.filterValues.trade = '';
    this.filterValues.wishlist = '';
    await this.globalSearch.reset();
    this.collectibleFilter.reset();
  }

  async goToViewProduct(chosenCollectible) {
    await this.store.dispatch(selectedCollectible({ id: chosenCollectible.id }));
    await this.store.dispatch(goToUserProfile({ user: chosenCollectible.owner }));
    this.store.dispatch(setProduct({ product: 'collectible' }));
    this.router.navigateByUrl("/product")
  }

  shuffle(list) {
    return list.reduce((p, n) => {
      const size = p.length;
      const index = Math.trunc(Math.random() * (size - 1));
      p.splice(index, 0, n);
      return p;
    }, []);
  }
}
