import { Component, OnInit } from "@angular/core";
import { AuthService } from "./services/auth.service";
import { Ng7MatBreadcrumbService } from "ng7-mat-breadcrumb";
import { Store } from "@ngrx/store";
import { WebsocketService } from "./services/websocket.service";
import { STRIPEID } from "../environments/environment.prod";
import { addThread, addMessage } from "./redux/actions/message.actions";
import { MessengerService } from "./services/messenger.service";
import { Observable } from "rxjs";

interface AppState {
  route: any;
  messages: any;
  product: any;
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public message: string;
  public route: string;
  public threads: any;

  constructor(
    public auth: AuthService,
    private ng7MatBreadcrumbService: Ng7MatBreadcrumbService,
    private store: Store<AppState>,
    private wsService: WebsocketService,
    private msg: MessengerService
  ) {}

  handler: any = null;

  ngOnInit() {
    // here we want to listen to an event from the socket.io
    this.wsService.listen("recieved-message").subscribe((message: any) => {
      this.addMessage(message);
    });
    // On initial load, check authentication state with authorization server
    // Set up local auth streams if user is already authenticated
    this.auth.localAuthSetup();
    this.store.select("route").subscribe((res) => {
      if (res) {
        this.route = res.route;
      }
    });
  }

  async addMessage(message) {
    await this.store
      .select("messages")
      .subscribe((messages) => (this.threads = messages.threads));

    const existing = this.threads
      ? this.threads.find((x) => x.id === message.threadId)
      : null;

    if (existing) {
      return this.store.dispatch(addMessage({ message }));
    }

    if (!existing) {
      return this.msg
        .getThread(message.threadId)
        .subscribe((thread) => this.store.dispatch(addThread({ thread })));
    }
  }
}
