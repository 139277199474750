import { Component, OnInit, Input, Output, ViewChild, EventEmitter, TemplateRef } from '@angular/core';
import { Conditional } from '@angular/compiler';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  iconColor = 'white';
  @Input() condition: Boolean;
  @Input() content: String;
  @Input() disabled: Boolean;
  @Input() warn: Boolean;
  @Input() small: Boolean;
  @Input() outline: Boolean;
  @Input() icon: String;
  @Input() iconLeft: Boolean;
  @Input() btnLeft: Boolean;
  @Input() clear: Boolean;
  @Input() aria: String;
  @Input() noMargin: boolean;

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  @ViewChild('appButton', { static: false })
  private appButton: TemplateRef<any>;

  constructor() {}

  ngOnInit() {
    this.calcIconColor();
  }

  click() {
    this.onClick.emit();
  }

  calcIconColor() {
    if ( this.outline || this.clear ) {
      this.iconColor = '#393E46';
    }
    if ( this.warn && this.clear || this.warn && this.outline ) {
      this.iconColor = '#D6594A';
    }
  }

}
