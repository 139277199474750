import { Component, OnInit, Inject } from '@angular/core';
import { User } from 'src/app/models/user';
import { Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar, MatSelectChange } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ComicService } from 'src/app/services/comic.service';
import { comicCondition } from 'src/app/constants/comicCondition.constant';
import { comicTypes } from 'src/app/constants/comicType.constant';
import { comicFormat } from 'src/app/constants/comicFormat.constant';
import { debounceTime, startWith, map, takeUntil } from 'rxjs/operators';
import { allCharacters } from 'src/app/constants/allCharacters.constants';
import { allPublishers } from 'src/app/constants/allPublishers.constants';
import { allSeries } from 'src/app/constants/allSeries.constants';
import { comicGenre } from 'src/app/constants/comicGenre.constant';
import { STRIPEID } from '../../../environments/environment.prod';

interface AppState {
  auth: User;
}

@Component({
  selector: 'app-sell-similar-comic-dialog',
  templateUrl: './sell-similar-comic-dialog.component.html',
  styleUrls: ['./sell-similar-comic-dialog.component.scss']
})
export class SellSimilarComicDialogComponent implements OnInit {
  public loading = false;
  public id: string;

  public universe: string;
  public series: string;

  public title: string;

  public issue: any;
  public volume: any;
  public collectionVolume: string;

  public publisher: string;
  public publicationYear: any;
  public publicationLocation: string;

  public genre: string;
  public type: any;
  public pageCount: number;

  public format: any;
  public language: string;
  public upc: string;

  public condition: any;
  public quantity: number;
  public price: number;

  public credits: any = [];
  public characters: any = [];
  public notes: string;

  public file;

  public titleError: string;
  public universeError: string;
  public seriesError: string;
  public issueError: string;
  public publisherError: string;
  public publicationYearError: string;
  public conditionError: string;
  public priceError: string;
  public quantityError: string;
  public fileError: string;

  public selling;
  public collection;
  public trade;
  public wishlist;

  public savedTitle: string;
  public savedUniverse: string;
  public savedSeries: string;
  public savedIssue: string;
  public savedVolume: string;
  public savedCollectionVolume: string;
  public savedPublisher: string;
  public savedPublicationYear: string;
  public savedPublicationLocation: string;
  public savedGenre: string;
  public savedType: string;
  public savedPageCount: string;
  public savedLanguage: string;
  public savedFormat: string;
  public savedUpc: string;
  public savedNotes;
  public savedCondition: string;
  public savedPrice: string;
  public savedQuantity: string;

  public selectedImage: any;

  public user: Observable<User>;

  universeControl = new FormControl();
  filteredUniverses: Observable<string[]>;

  publisherControl = new FormControl();
  filteredPublishers: Observable<string[]>;

  seriesControl = new FormControl();
  filteredSeries: Observable<string[]>;

  typeControl = new FormControl();
  filteredType: Observable<string[]>;

  genreControl = new FormControl();
  filteredGenre: Observable<string[]>;

  conditionControl = new FormControl();
  filteredCondition: Observable<string[]>;

  comicCondition;
  comicFormat;
  comicType;

  public collectaverseCut;
  public usersCut;

  config;
  currentAuthUser;
  public fileLockedIn;

  private onDestroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SellSimilarComicDialogComponent>,
    public auth: AuthService,
    public comicService: ComicService,
    public form: FormBuilder,
    private matSnackbar: MatSnackBar,
    private store: Store<AppState>
  ) {
    this.store.select('auth').subscribe(res => (this.currentAuthUser = res));
  }

  ngOnInit() {
    this.fileLockedIn = false;
    this.config = STRIPEID;
    this.selling = false;
    this.collection = false;
    this.trade = false;
    this.wishlist = false;
    this.savedTitle = this.data.title;
    this.savedUniverse = this.data.universe;
    this.savedSeries = this.data.series;
    this.savedIssue = this.data.issue;
    this.savedVolume = this.data.volume;
    this.savedCollectionVolume = this.data.collectionVolume;
    this.savedPublisher = this.data.publisher;
    this.savedPublicationYear = this.data.publicationYear;
    this.savedPublicationLocation = this.data.publicationLocation;
    this.savedGenre = this.data.genre;
    this.savedType = this.data.type;
    this.savedPageCount = this.data.pageCount;
    this.savedLanguage = this.data.language;
    this.savedFormat = this.data.format;
    this.credits = this.data.credits;
    this.characters = this.data.characters;
    this.selectedImage = this.data.images[0];
    // Setting Options For Select Input
    this.comicCondition = comicCondition;
    this.comicType = comicTypes;
    this.comicFormat = comicFormat;
    // Setting Filters for AutoCompletes
    this.filteredUniverses = this.universeControl.valueChanges
      .pipe(
        debounceTime(500),
        startWith(''),
        map(value => value.length >= 2 ? this._filterUniverses(value) : [])
      );
    this.filteredPublishers = this.publisherControl.valueChanges
      .pipe(
        debounceTime(500),
        startWith(''),
        map(value => value.length >= 2 ? this._filterPublisher(value) : [])
      );
    this.filteredSeries = this.seriesControl.valueChanges
      .pipe(
        debounceTime(500),
        startWith(''),
        map(value => value.length >= 4 ? this._filterSeries(value) : [])
      );
    this.filteredGenre = this.genreControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterGenre(value))
      );
  }

  private _filterUniverses(value: string): string[] {
    const filterValue = value.toLowerCase();
    return allCharacters.sort().filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filterPublisher(value: string): string[] {
    const filterValue = value.toLowerCase();
    return allPublishers.sort().filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filterSeries(value: string): string[] {
    const filterValue = value.toLowerCase();
    return allSeries.sort().filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filterGenre(value: string): string[] {
    const filterValue = value.toLowerCase();
    return comicGenre.filter(option => option.toLowerCase().includes(filterValue));
  }

  handleSelectedImage = image => {
    this.selectedImage = image;
  }

  /* || FORM CONTROL || */

  handleTitle(val) {
    this.titleError = this.validate(val);
    if (this.titleError) {
      return (this.savedTitle = '');
    }
    this.savedTitle = val;
  }

  handleUniverse(val) {
    this.universeError = this.validate(val);
    if (this.universeError) {
      return (this.universe = '');
    }
    this.universe = val;
  }

  handleSeries(val) {
    this.seriesError = this.validate(val);
    if (this.seriesError) {
      return (this.series = '');
    }
    this.series = val;
  }

  handleIssue(val) {
    this.issueError = this.validate(val);
    if (this.issueError) {
      return (this.savedIssue = '');
    }
    this.savedIssue = val;
  }

  handleVolume(val: any) {
    this.savedVolume = val;
  }

  handleCollectionVolume(val: any) {
    this.savedCollectionVolume = val;
  }

  handlePublisher(val) {
    this.publisherError = this.validate(val);
    if (this.publisherError) {
      return (this.publisher = '');
    }
    this.publisher = val;
  }

  handlePublicationYear(val) {
    this.publicationYearError = this.validate(val);
    if (this.publicationYearError) {
      return (this.savedPublicationYear = null);
    }
    this.savedPublicationYear = val;
  }

  handlePublicationLocation(val: string) {
    this.savedPublicationLocation = val;
  }

  handleGenre(val: string) {
    this.savedGenre = val;
  }

  handleType(val: MatSelectChange) {
    this.savedType = val.value;
  }

  handlePageCount(val) {
    this.savedPageCount = val;
  }

  handleLanguage(val: string) {
    this.savedLanguage = val;
  }

  handleUpc(val: string) {
    this.upc = val;
  }

  handleCondition(val: MatSelectChange) {
    this.conditionError = this.validate(val.value);
    if (this.conditionError) {
      return (this.condition = '');
    }
    this.condition = val.value;
  }

  addCharacter() {
    this.characters.push({ name: '', role: '' });
  }

  handleCharacters(val) {
    const { value, name, index } = val;

    this.characters.map((character, i) => {
      if (i === index) {
        return character[name] = value;
      }
      return;
    });
  }

  removeCharacters = value => {
    this.characters = this.characters.filter((x, index) => index !== value);
  }

  addCredit() {
    this.credits.push({ name: '', role: '' });
  }

  handleCredits(val) {
    const { value, name, index } = val;

    this.credits.map((credit, i) => {
      if (i === index) {
        return credit[name] = value;
      }
      return;
    });
  }

  removeCredit = value => {
    this.credits = this.credits.filter((x, index) => index !== value);
  }

  handleFormat(val: MatSelectChange) {
    this.savedFormat = val.value;
  }

  handleNotes(val) {
    this.notes = val;
  }

  handleSelling(event) {
    if (event) {
      this.selling = event;
    }
  }

  handleCollection(event) {
    if (event) {
      this.collection = event;
    }
  }

  handleTrading(event) {
    if (event) {
      this.trade = event;
    }
  }

  handlePriceQuantity(evt, name) {
    const error = `${name}Error`;
    this[error] = this.validate(evt);

    if (this[error]) {
      return (this[name] = null);
    }
    this[name] = evt;
  }

  handleFile(val) {
    this.file = val;
    if (val) {
      this.fileLockedIn = true;
    }
  }

  /* || INPUT VALIDATION || */

  validate(legit: any): any {
    if (!legit) {
      return 'Required';
    }
    return '';
  }

  validateFile(legit: any): any {
    if (!legit) {
      return 'A picture is required to create a comic';
    }
    return '';
  }

  /* || END INPUT VALIDATION || */

  close() {
    this.dialogRef.close();
  }

  async submit() {
    this.universeError = this.validate(this.universe);
    this.seriesError = this.validate(this.series);
    this.titleError = this.validate(this.savedTitle);
    this.issueError = this.validate(this.savedIssue);
    this.publisherError = this.validate(this.publisher);
    this.publicationYearError = this.validate(this.savedPublicationYear);
    this.fileError = this.validateFile(this.file);

    if (this.selling) {
      this.conditionError = this.validate(this.condition);
      this.priceError = this.validate(this.price);
      this.quantityError = this.validate(this.quantity);
    }

    if (
      this.titleError ||
      this.universeError ||
      this.seriesError ||
      this.issueError ||
      this.publisherError ||
      this.publicationYearError ||
      (this.selling &&
        (this.conditionError || this.priceError || this.quantityError))
    ) {
      return this.matSnackbar.open(
        `Please recheck the form. A REQUIRED field in red has been identified`,
        'OK',
        {
          duration: 5000,
          panelClass: ['cvSnack']
        }
      );
    }

    this.loading = true;
    const credits = this.credits ? this.credits.filter(credit => credit.name && credit.role) : null;
    const characters = this.characters ? this.characters.filter(character => character.name) : null;

    await this.comicService.addComic(
      this.savedTitle,
      this.universe,
      this.series,
      this.savedIssue,
      this.savedVolume,
      this.savedCollectionVolume,
      this.publisher,
      this.savedPublicationYear,
      this.savedPublicationLocation,
      this.savedGenre,
      this.savedType,
      this.savedPageCount,
      this.savedFormat,
      this.savedLanguage,
      this.upc,
      JSON.stringify(credits),
      JSON.stringify(characters),
      this.notes,
      this.condition,
      this.selling,
      this.collection,
      this.trade,
      this.wishlist,
      this.file,
      this.price,
      this.quantity
    )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async res => {
        await this.auth.updatePoints(this.currentAuthUser.rewards + 5, this.currentAuthUser.id);
        this.matSnackbar.open(
          `You successfully added the comic "${this.data.title}" to your profile page`,
          'OK',
          {
            duration: 5000,
            panelClass: ['cvSnack']
          }
        );
        this.close();
      });

  }

}
