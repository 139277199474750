import { AuthGuard } from './services/auth.guard';
import { BrowseComponent } from './core/browse/browse.component';
import { CallbackComponent } from './components/callback/callback.component';
import { CreateAccountComponent } from './core/create-account/create-account.component';
import { HomeComponent } from './core/home/home.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './services/interceptor.service';
import { NgModule } from '@angular/core';
import { ProfileComponent } from './core/profile/profile.component';
import { Routes, RouterModule } from '@angular/router';
import { CartComponent } from './core/cart/cart.component';
import { ViewComicComponent } from './core/view-comic/view-comic.component';
import { ViewCollectibleComponent } from './core/view-collectible/view-collectible.component';
import { MessengerComponent } from './core/messenger/messenger.component';
import { OriginComponent } from './core/origin/origin.component';
import { SuccessPageComponent } from './core/success-page/success-page.component';
import { ComicGradingComponent } from './core/comic-grading/comic-grading.component';
import { AboutUsComponent } from './core/about-us/about-us.component';
import { JustUsLeagueComponent } from './core/just-us-league/just-us-league.component';
import { ProductLandingPageComponent } from './core/product-landing-page/product-landing-page.component';
import { BrowseOnlyComicsComponent } from './core/browse-only-comics/browse-only-comics.component';
import { BrowseOnlyCollectiblesComponent } from './core/browse-only-collectibles/browse-only-collectibles.component';
import { BrowseOnlyUsersComponent } from './core/browse-only-users/browse-only-users.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
  },
  {
    path: 'browse',
    component: BrowseComponent
  },
  {
    path: 'cart',
    component: CartComponent
  },
  { path: 'callback',
  component: CallbackComponent
  },
  {
    path: 'create-account',
    component: CreateAccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile/:username',
    component: ProfileComponent
  },
  {
    path: 'comic',
    component: ViewComicComponent
  },
  {
    path: 'collectible',
    component: ViewCollectibleComponent
  },
  {
    path: 'origin',
    component: OriginComponent
  },
  {
    path: 'team',
    component: JustUsLeagueComponent
  },
  {
    path: 'product',
    component: ProductLandingPageComponent
  },
  {
    path: 'about',
    component: AboutUsComponent
  },
  {
    path: 'comic-grading',
    component: ComicGradingComponent,
  },
  {
    path: 'success',
    component: SuccessPageComponent
  },
  {
    path: 'browse-comics',
    component: BrowseOnlyComicsComponent,
  },
  {
    path: 'browse-collectibles',
    component: BrowseOnlyCollectiblesComponent,
  },
  {
    path: 'browse-users',
    component: BrowseOnlyUsersComponent,
  },
  {
    path: 'messenger',
    component: MessengerComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
  ],
})
export class AppRoutingModule { }
