import { Action } from '@ngrx/store';


export function collectibleReducer(state = {}, action: Action) {
  const results: any = action;
  const collectibles: any = state;
  let newState;
  let collectible;
  switch (action.type) {
    case 'VIEW_COLLECTIBLES':
      return { ...state, collectibles: results.collectibles };
    case 'SELECTED_COLLECTIBLE':
      const selectedCollectible = collectibles.collectibles.find(toy => toy.id === results.id);
      return { ...state, selectedCollectible };
    case 'UPDATE_COLLECTIBLE_PICTURE':
      newState = collectibles;
      collectible = newState.collectibles.find(toy => toy.id === results.payload.collectibleId);
      collectible.images = results.payload.files.sort((a, b) => b.isDefault - a.isDefault);
      return newState;
    case 'REMOVE_IMAGE':
      newState = collectibles.collectibles;
      collectible = newState.find(toy => toy.id === results.payload.collectibleId);
      const newImages = collectible.images.filter(image => image.id !== results.payload.fileId);
      collectible.images = newImages;
      return {selectedCollectible: collectible, collectibles: newState}
    default:
      return state;
  }
}
