import { Component, OnInit, Inject } from "@angular/core";
import { User } from "src/app/models/user";
import {
  trigger,
  transition,
  style,
  animate,
  state,
} from "@angular/animations";
import { Observable, Subject } from "rxjs";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material";
import { AuthService } from "src/app/services/auth.service";
import { FormBuilder } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Router } from "@angular/router";
import { CollectibleService } from "src/app/services/collectible.service";
import { selectedCollectible } from "src/app/redux/actions/collecible.actions";
import { EditCollectibleDialogComponent } from "../edit-collectible-dialog/edit-collectible-dialog.component";
import { takeUntil } from "rxjs/operators";
import { EditCollectibleCoverDialogComponent } from "../edit-collectible-cover-dialog/edit-collectible-cover-dialog.component";
import { ConfirmCollectibleDialogComponent } from "../confirm-collectible-dialog/confirm-collectible-dialog.component";
import { SellSimilarCollectibleDialogComponent } from "../sell-similar-collectible-dialog/sell-similar-collectible-dialog.component";
import { AddWishlistCollectibleDialogComponent } from "../add-wishlist-collectible-dialog/add-wishlist-collectible-dialog.component";
import { setProduct } from "src/app/redux/actions/product.actions";

interface AppState {
  userProfile: User;
  authUser: User;
  comics: any;
  collectibles: any;
  auth: any;
}

@Component({
  selector: "app-view-toy-dialog",
  templateUrl: "./view-toy-dialog.component.html",
  styleUrls: ["./view-toy-dialog.component.scss"],
  animations: [
    trigger("fade", [
      transition("void => *", [
        style({ opacity: 0 }),
        animate(800, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        state("invisible", style({ opacity: 0 })),
        style({ opacity: 0 }),
        animate(800, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ViewToyDialogComponent {
  public user: Observable<User>;
  public authUser;

  public collectible;
  private onDestroy$ = new Subject<void>();

  public selectedImage: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ViewToyDialogComponent>,
    private mdDialog: MatDialog,
    public auth: AuthService,
    public form: FormBuilder,
    private store: Store<AppState>,
    private router: Router,
    public collectibleService: CollectibleService
  ) {
    this.store
      .select("collectibles")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        this.collectible = res.selectedCollectible;
      });
    this.user = this.store.select("userProfile");
    this.store.select("auth").subscribe((res) => {
      this.authUser = res.id;
    });
    this.selectedImage = this.data.images[0];
    if (!this.authUser) {
      this.authUser = "GuestUser";
    }
  }

  async goToUsersProfile(selectedUser) {
    this.router.navigateByUrl(`/profile/${selectedUser.username}`);
    this.close();
  }

  close() {
    this.dialogRef.close();
  }

  closeAndUpdate(evt) {
    this.dialogRef.close(evt);
  }

  goToViewProduct() {
    this.dialogRef.close();
    this.store.dispatch(selectedCollectible({ id: this.collectible.id }));
    this.store.dispatch(setProduct({ product: "collectible" }));
    this.router.navigateByUrl("/product");
  }

  goToEditCollectibles() {
    const dialogRef = this.mdDialog.open(EditCollectibleDialogComponent, {
      data: this.collectible,
      autoFocus: false,
      panelClass: "custom-dialog-container",
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result) {
          this.close();
        }
      });
  }

  goToUpdatePhoto() {
    const dialogRef = this.mdDialog.open(EditCollectibleCoverDialogComponent, {
      data: this.collectible,
      autoFocus: false,
      panelClass: "custom-dialog-container",
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {});
  }

  goToConfirmDelete() {
    const dialogRef = this.mdDialog.open(ConfirmCollectibleDialogComponent, {
      data: this.collectible,
      autoFocus: false,
      panelClass: "custom-dialog-container",
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        this.closeAndUpdate(result);
      });
  }

  goToSellSimilarCollectible() {
    const dialogRef = this.mdDialog.open(
      SellSimilarCollectibleDialogComponent,
      {
        data: this.collectible,
        autoFocus: false,
        panelClass: "custom-dialog-container",
        disableClose: true,
      }
    );
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {});
  }

  goToWishlistCollectible() {
    const dialogRef = this.mdDialog.open(
      AddWishlistCollectibleDialogComponent,
      {
        data: this.collectible,
        autoFocus: false,
        panelClass: "custom-dialog-container",
        disableClose: true,
      }
    );
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {});
  }

  handleSelectedImage = (image) => {
    this.selectedImage = image;
  };
}
