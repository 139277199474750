import { Action } from '@ngrx/store';

export function cartReducer(state = [], action: Action) {
  const results: any = action;
  switch (action.type) {
    case 'ADD_TO_CART':
      const existing = state.find(item => item.id === results.product.id);
      const existingSeller = state.find(item => item.ownerId === results.product.ownerId);
      // const shippingCost = existingSeller &&  results.shipping > 1.98 ? 1 : results.product.price < 10 ? 1.98 : 4.98;
      const shippingCost = existingSeller &&  results.shipping > 0 ? 0 : results.product.price < 10 ? 0 : 0;
      if (existing) {
        return state.map(item =>
          item.id === results.product.id
            ? { ...item, quantityInCart: item.quantityInCart + 1, shippingCost }
            : item
        );
      }
      return [...state, { ...results.product, quantityInCart: 1, shippingCost }];
    case 'DECREASE_QUANTITY':
      return state.map(item =>
        item.id === results.productId
          ? { ...item, quantityInCart: item.quantityInCart - 1 }
          : item
      );
    case 'REMOVE_FROM_CART':
      return state.filter(item => item.id !== results.productId);
    case 'EMPTY_CART':
      return state = undefined;
    default:
      return state;
  }
}
