export const comicCreditsRoles = [
  'Adapted By',
  'Art Director',
  'Article Writer',
  'Artist',
  'Assistant Editor',
  'Associate Editor',
  'Author',
  'Back Cover Artist',
  'Bound By',
  'Breakdowns',
  'Chairman',
  'Circulation',
  'Co-Artist',
  'Co-Author',
  'Collection Editor',
  'Colorist',
  'Concept',
  'Consulting Editor',
  'Coordinator',
  'Copyright Holder',
  'Cover Artist',
  'Cover Colorist',
  'Cover Inker',
  'Cover Layout',
  'Cover Penciler',
  'Creative Director',
  'Creator',
  'Dedicated',
  'Designer',
  'Digital Assistant',
  'Director of Publishing Operations',
  'Distributed By',
  'Editor',
  'Editor In Chief',
  'Executive Editor',
  'Executive Producer',
  'Imprint',
  'Inker',
  'Introduction By',
  'Layout',
  'Letterer',
  'Logo By',
  'Managing Director',
  'Managing Editor',
  'Manufactured By',
  'Model',
  'Original Publisher',
  'Penciler',
  'Photography By',
  'Plotter',
  'President',
  'Printed By',
  'Production Manager',
  'Project Originator',
  'Proofreader',
  'Published By',
  'Publishing Director',
  'Reprint Editor',
  'Retouch',
  'RI Cover Artist',
  'Sales Manager',
  'Senior Editor',
  'Separator',
  'Series Editor',
  'Technical Editor',
  'Title Page Art',
  'Title Page Colors',
  'Translator',
  'Vice President',
  'Writer'
];
