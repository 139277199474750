export const comicCreditsNames = [
  "Dani",
  "Dani _",
  "Jason Aaron",
  "Saida Abbott",
  "Wes Abbott",
  "Jack Abel",
  "Jessica Abel",
  "Dusty Abell",
  "Dan Abnett",
  "David Accampo",
  "Aco",
  "Daniel Acuna",
  "Vincenzo Acunzo",
  "Arthur Adams",
  "Gregory Duke Adams",
  "Josh Adams",
  "Marilyn Adams",
  "Ted Adams",
  "Dan Adkins",
  "Charlie Adlard",
  "Jack Adler",
  "Ron Adrian",
  "Sam Agro",
  "Eddie Wagner Aguiar",
  "Brian D.Ahern",
  "Saladin Ahmed",
  "Keith Aiken",
  "David Aja",
  "Tony Akins",
  "Christian Alamy",
  "Gerry Alanguilan",
  "John Albano",
  "Juan Albarran",
  "Oclair Albert",
  "Mario Alberti",
  "Jeff Albrecht",
  "Rafael Albuquerque",
  "Alfredo Alcala",
  "Quique Alcatena",
  "Vincente Alcazar",
  "Todd Alcott",
  "Shawn Aldridge",
  "Shawn Aldridge",
  "Jason Shawn Alexander",
  "Jim Alexander",
  "Aaron Alexovich",
  "Bill Alger",
  "Pascal Alixe",
  "Raul Allen",
  "David Allikas",
  "James Leroy Allred",
  "Laura Allred",
  "Lee Allred",
  "Mike Allred",
  "Sal Almendola",
  "Bob Almond",
  "Eduardo Alpuente",
  "Marlo Alquiza",
  "Rick Altergott",
  "Natasha Alterici",
  "Kevin Altieri",
  "David Alvarez",
  "Wellington Alves",
  "Aluir Amancio",
  "Yoshitaka Amano",
  "Gary Amaro",
  "Jim Amash",
  "Jonathan Ames",
  "Mar Amongo",
  "Jay Anacleto",
  "Robert Andelman",
  "Bill Anderson",
  "Brad Anderson",
  "Brent Anderson",
  "Ho Che Anderson",
  "Kevin J.Anderson",
  "Murphy Anderson",
  "Mirka Andolfo",
  "Kalman Andrasofszky",
  "Duncan Andrews",
  "Esao Andrews",
  "Kaare Andrews",
  "Marc Andreyko",
  "Ross Andru",
  "Aneke",
  "Thom Ang",
  "Warner Bros.Animation",
  "Ario Anindito",
  "Kris Anka",
  "Martin Ansin",
  "Caesar Antomattei",
  "Roge Antonio",
  "Sanya Anwar",
  "Jim Aparo",
  "Brian Apthorp",
  "Sergio Aragones",
  "Javier Sanchez Aranda",
  "Omar Aranda",
  "Andre Araujo",
  "Santiago Arcas",
  "Adam Archer",
  "John Arcudi",
  "Sergio Arino Peralta",
  "Renato Arlem",
  "Flaviano Armentaro",
  "Paolo Armitano",
  "Jason Armstrong",
  "Kim Arndt",
  "Don Arneson",
  "Darren Aronofsky",
  "David Arquette",
  "Ulises Arreola",
  "Kia Asamiya",
  "Nelson Asencio",
  "Ashley Witter",
  "Brian Ashmore",
  "Mark Askwith",
  "James Asmus",
  "Mahmud Asrar",
  "Michael Atiyeh",
  "Robert Atkins",
  "Zachary A Atkinson",
  "Derec Aucoin",
  "Brian Augustyn",
  "Chuck Austen",
  "Terry Austin",
  "Fiona Avery",
  "Jose Aviles",
  "Tony Avina",
  "Aldrin(Buzz) Aw",
  "Scott Awley",
  "Vita Ayala",
  "Dick Ayers",
  "Steve Aylett",
  "Paul Azaceta",
  "Brian Azzarello",
  "Gabriel Ba",
  "Jay Babcock",
  "Michael Babinski",
  "Chris Bachalo",
  "Darrick Eugene Ariel Bachman",
  "Ramón F.Bachs",
  "Badass",
  "Brandon Badeaux",
  "Hillary Bader",
  "Mark Badger",
  "Carlos Badilla Zamorano",
  "Jason Badower",
  "Barnaby Bagenda",
  "Peter Bagge",
  "Mark Bagley",
  "Jim Baikie",
  "Ray Bailey",
  "David Baillie",
  "Jerry Bails",
  "Bernard Baily",
  "Michael Bair",
  "Kevin Baker",
  "Kyle Baker",
  "Rick Baker",
  "David Baldeon",
  "Zach Baldus",
  "Jim Balent",
  "Georgia Ball",
  "Phil Balsman",
  "Art Baltazar",
  "Darryl Banks",
  "Matt Banning",
  "Carlo Barberi",
  "Frank Barbiere",
  "Frank J.Barbiere",
  "Leigh Bardugo",
  "Clive Barker",
  "Nat Barnett",
  "David Baron",
  "Michael Baron",
  "Glenn Barr",
  "Mike Barr",
  "Mike Barreiro",
  "Diego Barreto",
  "Eduardo Barreto",
  "Al Barrionuevo",
  "Daniel Barrionuevo",
  "Roberto Barrios Angelelli",
  "Carole E.Barrowman",
  "John Barrowman",
  "Eddy Barrows",
  "Sy Barry",
  "Barsom",
  "Hilary Barta",
  "Jen Bartel",
  "Sebastian Bartolucci",
  "Eric Basaldua",
  "Sami Basri",
  "Ben Bates",
  "Cary Bates",
  "Leo Batic",
  "Chris Batista",
  "Batt",
  "Eric Battle",
  "Moose Baumann",
  "Scott Baumann",
  "Gabriel Bautista, Jr.",
  "Oscar Bazaldua",
  "Steve Beach",
  "Adam Beane",
  "Dave Beatty",
  "John Beatty",
  "Scott Beatty",
  "Terry Beatty",
  "David Beaty",
  "Ethen Charles Beavers",
  "Alison Bechdel",
  "Corinna Bechko",
  "C.C.Beck",
  "David Michael Beck",
  "Chris Beckett",
  "Paul Becton",
  "Tony Bedard",
  "Emma Beeby",
  "Adam Beechen",
  "Morgan Beem",
  "Beeple",
  "Andy Belanger",
  "Blake Bell",
  "Jordie Bellaire",
  "Howard Bender",
  "Hy Bender",
  "Brian Michael Bendis",
  "Scott Benefiel",
  "Ed Benes",
  "Mariah Benes",
  "Bengal",
  "Joe Benitez",
  "Joel Benjamin",
  "Ryan Benjamin",
  "Anina Bennett",
  "Deron Bennett",
  "James Bennett",
  "Joe Bennett",
  "Marguerite Bennett",
  "Richard Bennett",
  "John Benson",
  "Julie Benson",
  "Michael Benson",
  "Shawna Benson",
  "Betty Bentley",
  "Greg Benton",
  "Charles Berberian",
  "Rainier Beredo",
  "Dave Berg",
  "Franco D'Angelo Bergamini",
  "Eddie Berganza",
  "Matias Bergara",
  "Aaron Bergeron",
  "Andy Bergholtz",
  "Lee Bermejo",
  "Raymund Bermudez",
  "Diego Bernard",
  "Jami Bernard",
  "Pierre Bernard Jr.",
  "Marc Bernardin",
  "Jordi Bernet",
  "Robert Bernstein",
  "J.M.Beroy",
  "Carla Berrocal",
  "D.Bruce Berry",
  "Rick Berry",
  "Michele Bertilorenzi",
  "Nick Bertozzi",
  "Ian Bertram",
  "Gael Bertrand",
  "Liz Berube",
  "Lauren Beukes",
  "Alex Bialy",
  "Simone Bianchi",
  "Danny Bilson",
  "Otto Binder",
  "Jerry Bingham",
  "J.J.Birch",
  "Brad Bird",
  "Brandon Bird",
  "Steve Bird",
  "Jason Bischoff",
  "Simon Bisley",
  "Stephen Bissette",
  "BIT",
  "Edvin Biukovic",
  "Alterton Bizarre",
  "Holly Black",
  "Jacob Black",
  "Ben Blacker",
  "W.Haden Blackman",
  "Patrick Blaine",
  "Michael Blair",
  "Tex Blaisdell",
  "Nelson Blake II",
  "Shannon Blanchard",
  "Fernando Blanco",
  "Laurel Blechman",
  "Bret Blevins",
  "Blond",
  "Erwin Blonder",
  "Stephen Blue",
  "David Bluestien",
  "Blur Studio, Inc",
  "Will Blyberg",
  "Chris Blythe",
  "David Blythe",
  "Juan Bobillo",
  "Karine Boccannfuso",
  "Jon Bogdanove",
  "Kal - El Bogdanove",
  "Viktor Bogdanovic",
  "Craig Boldman",
  "Jonathan T.Bolerjack",
  "Brian Bolland",
  "Frank Bolle",
  "Henry Boltinoff",
  "Murray Boltinoff",
  "John Bolton",
  "Grant Bond",
  "Philip Bond",
  "J.Bone",
  "Roger Bonet",
  "Richard Bonk",
  "Michael Bonner",
  "James Bonny",
  "Brett Booth",
  "Ian Boothby",
  "Ariel Bordeaux",
  "David Borden",
  "Greg Borenstein",
  "Max Borenstein",
  "Alisson Borges",
  "Geraldo Borges",
  "Wayne Boring",
  "Zev Borow",
  "Joao Bosco de Souza",
  "Gerry Boudreau",
  "Anthony Bourdain",
  "Michael Bowden",
  "Randy Bowen",
  "Ron Boyd",
  "Pat Boyette",
  "Christine Boylan",
  "Bahadir Boysal",
  "Belardino Brabo",
  "Ed Bradley",
  "Nicholas Bradshaw",
  "Tim Bradstreet",
  "Matthew Brady",
  "Laura Braga",
  "Doug Braithwaite",
  "Ken Branch",
  "Carine Brancowitz",
  "Ivan Brandon",
  "Peter Brandvold",
  "Katherine Brannock",
  "Andy Brase",
  "Russell Braun",
  "Enriqué Breccia",
  "Martin Breccia",
  "Jaques Bredy",
  "Brett Breeding",
  "Corey Breen",
  "Mitch Breitweiser",
  "Alan Brennert",
  "Daniel Brereton",
  "Andrei Bressan",
  "Kevin Breyfogle",
  "Norm Breyfogle",
  "E.Nelson Bridwell",
  "Oliver Brig",
  "M.D.Bright",
  "Mark Bright",
  "Giacomo Briglio",
  "June Brigman",
  "Brimstone",
  "David Brin",
  "Philippe Briones",
  "Ed Brisson",
  "Mark Bristow",
  "Pier Brito",
  "Dario Brizuela",
  "Pat Broderick",
  "Steve Brodie",
  "Adam Brody",
  "Mark Brooks",
  "John Broome",
  "Matthew Broome",
  "Wendy Broome",
  "Pat Brosseau",
  "Mat Brouillard",
  "Bob Brown",
  "Daniel Brown",
  "Eliot R Brown",
  "Garry Brown",
  "Len Brown",
  "Reilly Brown",
  "James Bruce Brown III",
  "Troy F.Brownfield",
  "Jimmy Broxton",
  "Ed Brubaker",
  "Tim Bruckner",
  "Matt Brundage",
  "Ivan Brunetti",
  "Richard Bruning",
  "Chris Brunner",
  "Giulia Brusco",
  "Kenneth Bruzenak",
  "Al Bryant",
  "Rick Bryant",
  "Brian Buccellato",
  "Steve Buccellato",
  "Rodney Buchemi",
  "Rebecca Buchman",
  "Mark Buckingham",
  "Richard William Buckler Jr.",
  "Richard J Buckler Sr.",
  "Gregory Budgett",
  "L.Lois Buhalis",
  "Paul Buhle",
  "Dave Bullock",
  "Angus Bungay",
  "Brian Buniak",
  "Cullen Bunn",
  "Jon Buran",
  "Rick Burchett",
  "Riccardo Burchielli",
  "Tim Burgard",
  "Cary Burkett",
  "Alan Burnett",
  "Chris Burnham",
  "Jack Burnley",
  "Ray Burnley",
  "Dan Burr",
  "Jacen Burrows",
  "Adam Burton",
  "Ryan Burton",
  "Shon C Bury",
  "John Buscema",
  "Sal Buscema",
  "Stephanie Buscema",
  "Robbie Busch",
  "Kurt Busiek",
  "Blair Butler",
  "Frederick W Butler",
  "Mitch Byrd",
  "Stephen Byrne",
  "Meg Cabot",
  "Nathan Cabrera",
  "Robert Cady",
  "Giuseppe Cafaro",
  "CAFU",
  "Julie McNally Cahill",
  "Timothy Cahill",
  "Alex Cal",
  "J.Calafiore",
  "Pepe Caldelas",
  "Ben Caldwell",
  "Talent Caldwell",
  "Dennis Calero",
  "Ignacio Calero",
  "Jim Califiore",
  "Celia Calle",
  "Peter Calloway",
  "John Calnan",
  "Donald Cameron",
  "Camm Lane Inc.",
  "Matt Camp",
  "Robert Campanella",
  "Eddie Campbell",
  "J.Scott Campbell",
  "Jamal Campbell",
  "James Campbell",
  "Ross Campbell",
  "Marc Campos",
  "Giuseppe Camuncoli",
  "Harry Candelario",
  "Eric Canete",
  "Kevin Cannon",
  "Zander Cannon",
  "Jay Cantor",
  "Kristin Cantrell",
  "Tom Canty",
  "Greg Capullo",
  "Giancarlo Caracuzzo",
  "Joseph Caramagna",
  "W.C.Carani",
  "Jennifer Carcano",
  "Orson Scott Card",
  "Nick Cardy",
  "Mike Carey",
  "Percy Carey",
  "Octavio Cariello",
  "Sergio Cariello",
  "Debora Carita",
  "Mike Carlin",
  "Eleanora Carlini",
  "Peter Carlsson",
  "Carmen Carnero",
  "Massimo Carnevale",
  "Sean Carolan",
  "Clint Carpenter",
  "John Carpenter",
  "Kai Carpenter",
  "Steve Carr",
  "Cristina C.Carracedo",
  "Sergio Carrera",
  "Fred Carrillo",
  "Emily Carroll",
  "Lynda Carter",
  "Leno Carvalho",
  "Walter Carzon",
  "Elena Casagrande",
  "Matteo Casali",
  "Santi Casas",
  "Mauro Cascioli",
  "Bernie Case",
  "Jonathan Case",
  "Richard Case",
  "Joe Casey",
  "John Cassaday",
  "Paul Henry Cassidy",
  "Nick Cassway",
  "Chris Castallo",
  "Claudio Castellini",
  "Cecil Castellucci",
  "Marco Castiello",
  "Jose Franciso Medina Castillo",
  "Tommy Castillo",
  "Anthony Castrillo",
  "Juan Castro",
  "Roberto F.Castro Marquez",
  "Vic Catan",
  "Joey Cavalieri",
  "Michael Cavallaro",
  "Kimberly Cavyan",
  "Victor Cayro",
  "Arturo Cazeneuve",
  "John Cebollero",
  "John Celardo",
  "Oscar Celestini",
  "Lauren Certo",
  "Keu Cha",
  "Michael Chabon",
  "Jim Chadwick",
  "Paul Chadwick",
  "Gary Chaloner",
  "Kody Chamberlain",
  "Keith Champagne",
  "Ernie Chan",
  "Bernard Chang",
  "Michael Chang",
  "Clay McLeod Chapman",
  "Jim Charalampidis",
  "Travis Charest",
  "Elsa Charretier",
  "Bobbie Chase",
  "Howard Chaykin",
  "Rosemary Cheetham",
  "Jo Chen",
  "Marcia Chen",
  "Mingjue Helen Chen",
  "Sean Chen",
  "Matt Cherniss",
  "Eric Cherry",
  "Jim Cheung",
  "Derrick Chew",
  "Bernard Chiang",
  "Cliff Chiang",
  "Janice Chiang",
  "Joe Chiappetta",
  "Frank Chiaramonte",
  "Mark Chiarello",
  "Daniel G Chichester",
  "Joyce Chin",
  "Brian Ching",
  "Joe Chiodo",
  "Brian Chippendale",
  "Andrew Chiu",
  "Frank Cho",
  "Julia J Cho",
  "Michael Cho",
  "David Choe",
  "Brandon Choi",
  "Mary H.K.Choi",
  "Mike Choi",
  "Sonia Choi",
  "ChrisCross",
  "Mark Andreas Christiansen",
  "Johnnie Christmas",
  "John Christopher",
  "Amy Chu",
  "Tom Chu",
  "Ernie Chua",
  "Christopher Chuckry",
  "June Chung",
  "Ian Churchill",
  "Marian Churchland",
  "Barbara Ciardo",
  "Vicente Cifuentes",
  "Yildiray Cinar",
  "Tony Cipriano",
  "Sam Citron",
  "Chris Claremont",
  "Jim Clark",
  "John Clark",
  "Matt Clark",
  "Michael Clark",
  "Richard Clark",
  "Scott Clark",
  "Andy Clarke",
  "John Cleary",
  "John Cleese",
  "Ryan Cline",
  "Ronan Cliquet",
  "Becky Cloonan",
  "Daniel Clowes",
  "Chynna Clugston Flores",
  "Martin Coccolo",
  "Dave Cockrum",
  "Matthew Cody",
  "Jamie Coe",
  "Andre Coelho",
  "Iban Coello",
  "Ivan Cohen",
  "Gary Cohn",
  "Scott Cohn",
  "Olivier Coipel",
  "Tomm Coker",
  "Mirko Colak",
  "Gene Colan",
  "Kevin Michael Colden",
  "David Cole",
  "Jack Cole",
  "Simon Coleby",
  "Jerry Coleman",
  "Hector Collazo",
  "Vince Colletta",
  "Max Allan Collins",
  "Mike Collins",
  "Nancy Collins",
  "Terry Collins",
  "Ernie Colón",
  "Comicraft",
  "Comics International",
  "Diana E.Conesa",
  "Aaron Conley",
  "Amanda Conner",
  "Kevin Conrad",
  "Will Conrad",
  "Andrew Constant",
  "Gerry Conway",
  "Christopher Cook",
  "Katie Cook",
  "Nicole Cook",
  "Steve Cook",
  "Darwyn Cooke",
  "Dave Cooper",
  "Joseph Cooper",
  "Colleen Ann Coover",
  "Richard Corben",
  "Leela Corman",
  "Paul Cornell",
  "Jorge Corona",
  "Roberto Corona",
  "Cristina Coronas",
  "Jaime Garcia Corral",
  "Jorge Correa",
  "Joe Corroney",
  "Heath Corson",
  "Dave Cortes",
  "Michael Costa",
  "John Costanza",
  "N.C.Christopher Couch",
  "Michael Coulthard",
  "Denys Cowan",
  "Michael Cowart",
  "Jeromy Cox",
  "William J Crabtree",
  "Jason Craig",
  "Wes Craig",
  "Clayton Crain",
  "Roger Dale Crain",
  "Dennis Cramer",
  "Reed Crandall",
  "John Crane",
  "Jordan Crane",
  "Saleem Crawford",
  "Creaturebox, LLC",
  "Mark Crilley",
  "Carl Critchlow",
  "Paul Crocker",
  "C.Martin Croker",
  "Tyler Crook",
  "Howard Cruse",
  "Eufronio Reyes Cruz",
  "Nardo Cruz",
  "Roger Cruz",
  "Shawn Crystal",
  "Cristiano Cucina",
  "Charles Cuidera",
  "I.N.J.Culbard",
  "Paris Cullins",
  "Steven Cummings",
  "Scott Cunningham",
  "Justino David Curiel Pena",
  "Andrew Currie",
  "Nick Cutter",
  "Toby Cypress",
  "Jef Czekaj",
  "Marco D'Alfonso",
  "Carlos D'Anda",
  "Gene D'Angelo",
  "D'Israeli",
  "Robeiro Leandro da Silva",
  "Alex Da Silva Soare",
  "Andrew Dabb",
  "Joseph Daffron",
  "Fernando Dagnino",
  "Chris Dahlberg",
  "Lawrence Daley III",
  "Andrew Dalhouse",
  "Federico Dallocchio",
  "Farel Dalrymple",
  "John Daly",
  "James M.Daly III",
  "Rodolfo Damaggio",
  "Tony S.Daniel",
  "Les Daniels",
  "Dame Darcy",
  "Steve Darnall",
  "Geof Darrow",
  "Gary Dauberman",
  "Russell Dauterman",
  "Paul Davey",
  "Donald David",
  "Peter David",
  "Rob David",
  "David Winnic",
  "Sergio Davila",
  "Alan Davis",
  "Dan Davis",
  "Ed Davis",
  "Guy Davis",
  "Michael Davis",
  "Rick Davis",
  "Rob Davis",
  "Shane Davis",
  "Simon Davis",
  "Jon Davis - Hunt",
  "Al Davison",
  "Bong Davo",
  "Dan Day",
  "Rufus Dayglo",
  "DC Lettering",
  "Alex De Campi",
  "Tom De Haven",
  "Eva De La Cruz",
  "Melissa de la Cruz",
  "Donald De Line",
  "Renae De Liz",
  "Jefferson De Los Santos",
  "Ellie De Ville",
  "Jared Deal",
  "Dan DeCarlo",
  "Mike DeCarlo",
  "Nelson Decastro",
  "Kelly Sue Deconnick",
  "Marc Deering",
  "Rachel Deering",
  "Tom DeFalco",
  "Nunzio Defilippis",
  "Albert DeGuzman",
  "Amanda Deibert",
  "Kim Deitch",
  "Jeff Dekal",
  "Adam DeKraker",
  "Maurice Del Bourgo",
  "Ronnie Del Carmen",
  "Leila Del Deca",
  "Leila del Duca",
  "Luciana Del Negro",
  "Vanesa Del Rey",
  "Valentine DeLandro",
  "John Delaney",
  "Jamie Delano",
  "Samuel R.Delany",
  "Jose Delbo",
  "Michelle Delecki",
  "Edgar Delgado",
  "John Dell",
  "Werther Dell'Edera",
  "Gabrielle Dell'Orto",
  "Gabriel Dell'Otto",
  "Al Dellinges",
  "Jesse Delperdang",
  "Vito Delsante",
  "J.M.DeMatteis",
  "Paul Demeo",
  "Kim Demulder",
  "Max Denby",
  "Brian D Denham",
  "Thomas Denrenick",
  "Abby Denson",
  "Shannon Denton",
  "Mike Deodato",
  "Vincent Deporter",
  "Laura Depuy",
  "Tom Derenick",
  "Nick Derington",
  "Flor Dery",
  "Pierre - Andre Dery",
  "F.J.DeSanto",
  "Brad Michael Desnoyer",
  "Rebecca L Dessertine",
  "Stephen DeStefano",
  "Joe Devito",
  "James Devlin",
  "Zelda Devon",
  "David M.Devries",
  "Benjamin Dewey",
  "Todd Dezago",
  "Mary DeZuniga",
  "Tony DeZuniga",
  "Marcelo Di Chiara",
  "Carmine Di Giandomenico",
  "Michael Dialynas",
  "Wellington Fabio Dias",
  "Alex Dos Diaz",
  "Netho Diaz",
  "Ruben Diaz",
  "Jean Diaz Froes",
  "Paco Diaz Luque",
  "John Dickenson",
  "Joel Dickie",
  "Samwise Didier",
  "Dan Didio",
  "James Dietz",
  "Paul Difilippo",
  "Gregory Digenti",
  "Andy Diggle",
  "Digital Chameleon",
  "Dick Dillin",
  "Glyn Dillon",
  "Ray Dillon",
  "Steve Dillon",
  "Ben Dimagmaliw",
  "Michael J Dimotta",
  "Paul Dini",
  "Danielle Dispaltro",
  "Steve Ditko",
  "Anna Dittman",
  "Chuck Dixon",
  "Justin Doble",
  "Feliks Dobrin",
  "Ed Dobrotka",
  "Cory Doctorow",
  "Joseph Dodd",
  "Rachel Dodson",
  "Terry Dodson",
  "Juan Doe",
  "Omar Dogan",
  "John Peter Doherty",
  "Horacio Domingues",
  "Luis Dominguez",
  "Kristian Donaldson",
  "Richard Donner",
  "Derec Donovan",
  "Eryk Donovan",
  "Kevin Dooley",
  "Colleen Doran",
  "Cliff Dorfman",
  "Leo Dorfman",
  "Evan Dorkin",
  "Les Dorschied",
  "Daniel Dos Santos",
  "Alberto Dose",
  "Jed Dougherty",
  "Michael Dougherty",
  "Mike Doughty",
  "Max Douglas",
  "Michael Dowling",
  "Ming Doyle",
  "Nick Dragotta",
  "Arnold Drake",
  "Bill Draut",
  "Mike Dringenberg",
  "Mort Drucker",
  "Victor Drujiniu",
  "Eric Drysdale",
  "Gustavo Duarte",
  "Nicole Dubuc",
  "Randy Duburke",
  "Christian Duce Fernandez",
  "Chris Duffy",
  "Jo Duffy",
  "Gerry Duggan",
  "Edward C.Dukeshire",
  "Kim Dullaghan",
  "Gary Dumm",
  "Max Dunbar",
  "Ben Dunn",
  "Phillippe Dupuy",
  "Armando Durruthy",
  "Michael Dutkiewicz",
  "Steve Dutro",
  "Jan Duursema - Mandrake",
  "Danielle Dwyer",
  "Kieron Dwyer",
  "Dale Dye",
  "Sarah Dyer",
  "DYM",
  "Joshua Dysart",
  "Jean - Jacques Dzialowski",
  "Wes Dzioba",
  "Timothy Dzon",
  "Dale Eaglesham",
  "Duncan Eagleson",
  "Kevin Eastman",
  "Michael Easton",
  "Cheryl Lynn Eaton",
  "Scot Eaton",
  "John Eder",
  "Ian Edginton",
  "Joe Edkin",
  "Nathan Edmondson",
  "Melissa Edwards",
  "Neil Edwards",
  "Tommy Lee Edwards",
  "Diana Egea",
  "Marty Egeland",
  "Jacob Eguren",
  "Brooke Eikmeier",
  "Will Eisner",
  "The Eisner Studio",
  "Pamela Eklund",
  "El Sevilla",
  "Andrew Elder",
  "Josh Elder",
  "Rob Elder",
  "Lee Elias",
  "Chris Eliopoulos",
  "Phil Elliott",
  "Randy Elliott",
  "Kenneth Elliott Jones",
  "Grace Ellis",
  "Steve Ellis",
  "Warren Ellis",
  "Harlan Ellison",
  "Whitney Ellsworth",
  "Richard Elson",
  "Gabe Eltaeb",
  "William Ely",
  "Hunt Emerson",
  "David Enebral",
  "Steve Englehart",
  "Garth Ennis",
  "Jordi Ensign",
  "Steve Epting",
  "Gary Erskine",
  "Steve Erwin",
  "Majid Esmaeili",
  "Joey Esposito",
  "Mike Esposito",
  "Eric M.Esquivel",
  "John C Estes",
  "Ric Estrada",
  "Maggie Estrep",
  "Kirk Albert Etienne",
  "Richard Michael Eury",
  "Mark Evanier",
  "George Evans",
  "John Evans",
  "Khari Evans",
  "Michael Evans",
  "Al Ewing",
  "Brian Ewing",
  "Brett Ewins",
  "Nathan Eyring",
  "Carlos Ezquerra",
  "Davide Fabbri",
  "Maxene Fabe",
  "Antonio Fabela Olivares",
  "Richard Faber",
  "Jason Fabok",
  "Glenn Fabry",
  "James Faerber",
  "Seamus Fahey",
  "Sean Fahey",
  "Marco Failla",
  "Nathan Fairbairn",
  "Romulo Fajardo Jr.",
  "Norman Fallon",
  "Jim Fanning",
  "Ulises Farinas",
  "Mark Farmer",
  "Todd Farmer",
  "Wayne Faucher",
  "Ray Fawkes",
  "Donald Faxon",
  "Brian Fay",
  "FCO Plascencia",
  "Riccardo Federici",
  "Mike Feehan",
  "Carla Feeny",
  "Duncan Fegredo",
  "Tom Feister",
  "Philip Felix",
  "Elitta Fell",
  "Howard Ferguson",
  "Lee Ferguson",
  "Jim Fern",
  "Ivan Fernandez",
  "Javier Fernandez",
  "Leandro Fernandez",
  "Raúl Fernández",
  "Carlos Ferreira",
  "Eber Ferreira",
  "Julio Ferreira",
  "Juan Ferreyra",
  "Pasqual Ferry",
  "Joshua Hale Fialkov",
  "Gary Fields",
  "Nicholas Filardi",
  "Joao Bosco De Souza Filho",
  "David Finch",
  "Meredith Finch",
  "Bill Finger",
  "Bob Fingerman",
  "Danny Fingeroth",
  "Joshua Finney",
  "Katherine Finney",
  "Fabrizio Fiorentino",
  "Steve Firchow",
  "Sholly Fisch",
  "Tim Fish",
  "David Fisher",
  "David Fisher",
  "Derek Fisher",
  "Seth Fisher",
  "Maximiliano Fiuimara",
  "Max Fiumara",
  "Sebastian Fiumara",
  "T.J.Fixman",
  "Walter Flanagan",
  "Michael L Fleisher",
  "Hugh Fleming",
  "Robert L.Fleming",
  "Tom Fleming",
  "Shary Flennikan",
  "Creig Flessel",
  "Brenden Fletcher",
  "Jared K Fletcher",
  "Jim Fletcher",
  "Ruth Fletcher",
  "Henry Flint",
  "Sandu Florea",
  "Irene Flores",
  "John Floyd",
  "Ian Flynn",
  "Mike Flynn",
  "Rich Fogel",
  "Phil Foglio",
  "Shea Fontana",
  "Tom Fontana",
  "Maurice Fontenot",
  "W.Scott Forbes",
  "John Gordon Ford",
  "Tana Ford",
  "Dick Foreman",
  "Travel Foreman",
  "Jorge Fornes",
  "Ellen Forney",
  "John Forte",
  "Langdon Foss",
  "Jon Foster",
  "Billy Fowler",
  "Billy Fowler",
  "Billy Fowler",
  "Billy Fowler",
  "Tess Fowler",
  "Tom Fowler",
  "Gardner Fox",
  "Gill Fox",
  "Nathan Fox",
  "Matt Fraction",
  "Ramona Fradon",
  "Dan Fraga",
  "Jason Frailey",
  "Francesco Francavilla",
  "Omar Francia",
  "James K Francis",
  "Eduardo Francisco",
  "Carlos Franco",
  "Franco",
  "Gary Frank",
  "Frank Frazetta",
  "Adam Freeman",
  "George Freeman",
  "Renee French",
  "Ron Frenz",
  "Brian Frey",
  "Derek Fridolfs",
  "Michael Jan Friedman",
  "Mike Friedrich",
  "Richard Friend",
  "Denis Frietas",
  "Jenny Frison",
  "Marcelo Frusin",
  "James Fry",
  "Ali Fuchs",
  "Conchita Mas Fuentes",
  "Artur Fujita",
  "Charlotte Louise Fullerton",
  "Jonathan Scott Fuqua",
  "Simon Furman",
  "Davide Furnò",
  "Robin Furth",
  "Antonio Fuso",
  "G2 Technologies",
  "Andersen Gabrych",
  "Carl Gafford",
  "Christos Gage",
  "Neil Gaiman",
  "Adam Gaines",
  "Manny Galam",
  "Michael Robert Gale",
  "Leigh Gallagher",
  "Steven Gallagher",
  "David Gallaher",
  "Benito Gallego Sanchez",
  "Pier Gallo",
  "Sean Galloway",
  "Romy Gamboa",
  "Kerry Gammill",
  "Simon Gane",
  "Paulina Ganucheau",
  "Eric Gapstur",
  "Lee Garbett",
  "Agnes Garbowska",
  "Eduardo Garcia",
  "German Garcia",
  "Joseph Maria Beroy Garcia",
  "Kami Garcia",
  "Manuel Garcia",
  "Michael Garcia",
  "Sergio Garcia",
  "Ricardo Garcia - Fuentes",
  "José Luis García - López",
  "Alex Garland",
  "Ralph Garman",
  "Alex Garner",
  "Catherine Garner",
  "Ron Garney",
  "Pat Garrahy",
  "Rafael Garres",
  "Javier Garron",
  "Brian Garvey",
  "Alé Garza",
  "Carlos Garzon",
  "Pol C Gas",
  "Philip Gascoine",
  "Sterling Gates",
  "Stefano Gaudiano",
  "Michael Gaydos",
  "Rick Geary",
  "Melinda Gebbie",
  "Michael J Geiger",
  "Lynn Gelfer",
  "Iagop Gemdjian",
  "Randy Gentile",
  "Stephen George",
  "Walter Geovani",
  "Steve Geppi",
  "Drew Geraci",
  "Mitch Gerads",
  "Steve Gerber",
  "Buddy Gernale",
  "Ransom Getty",
  "Kim Jung Gi",
  "Frank Giacoia",
  "Paul Giamatti",
  "Davide Gianfelice",
  "Gary Gianni",
  "Gentle Giant",
  "Vince Giarrano",
  "Dave Gibbons",
  "Chuck Gibson",
  "Ian Gibson",
  "Noelle Giddings",
  "Joe Giella",
  "Keith Giffen",
  "Armando Gil",
  "Maca Gil",
  "Michael T.Gilbert",
  "Robert Gill",
  "Aaron Gillespie",
  "Ford Lytle Gilmore",
  "Axel Gimenez",
  "Juan Gimenez",
  "Dick Giordano",
  "Tomas Giorello",
  "Alejandro Giraldo",
  "David Giraud",
  "Jean Giraud",
  "E.M.Gist",
  "Erik Gist",
  "Stephanie Gladden",
  "Sam Glanzman",
  "Jonathan Glapion",
  "Adam Glass",
  "Bryan J.L.Glass",
  "Elizabeth Glass",
  "Patrick Gleason",
  "Sarah Glidden",
  "Shane Glines",
  "Scott Godlewski",
  "Frank Godwin",
  "Owl Goingback",
  "Alan Gold",
  "Glenn David Gold",
  "Stan Goldberg",
  "Michael Golden",
  "Lloyd Goldfine",
  "Allan Goldman",
  "Kurt Goldzung",
  "Joel Gomez",
  "Adrian Gonzales",
  "Luis Gonzales",
  "Ile Gonzalez",
  "Ruben Gonzalez",
  "Isaac Goodhart",
  "Archie Goodwin",
  "Neil Googe",
  "Julius Gopez",
  "Jason Gorder",
  "Al Gordon",
  "Pat Gordon",
  "Jordan Gorfinkel",
  "Zac Gorman",
  "Ronald Goulart",
  "Amanda Gould",
  "Nicole Goux",
  "David S.Goyer",
  "Alexander Gradet",
  "Brandon Graham",
  "Rafael Grampá",
  "Jerry Grandenetti",
  "Alan Grant",
  "Jamie Grant",
  "Keron Grant",
  "Steven Grant",
  "Peter Grau",
  "Jennifer Graves",
  "Justin Gray",
  "Marcus Gray",
  "Mick Gray",
  "Devin Grayson",
  "Dan Green",
  "Lesley - Anne Green",
  "Michael Green",
  "Randy Green",
  "Sanford Green",
  "Timothy Green, II",
  "Robert Greenberger",
  "Carl Greenblatt",
  "Joe Greene",
  "Sanford Greene",
  "Sid Greene",
  "Sam Greenwell",
  "Doug Alexander Gregory",
  "Mike Grell",
  "Kevin Grevioux",
  "Terrance Griep, Jr.",
  "Kris Grimminger",
  "Tom Grindberg",
  "Paul Grist",
  "Matt Groening",
  "James Groman",
  "Peter Gross",
  "Scott Gross",
  "Chad Grothkopf",
  "Tom Grummett",
  "Ig Guara",
  "Emilio F Guardeno",
  "Fred Guardineer",
  "Rebecca Guay",
  "Renato Guedes",
  "R.M.Guera",
  "Pia Guerra",
  "Mateo Guerrero Cruz",
  "Marc Guggenheim",
  "Gianluca Gugliotta",
  "Christopher Gugliotti",
  "Butch Guice",
  "Jackson Guice",
  "Yvel Guichet",
  "Rob Guillory",
  "Andres Guinaldo",
  "Paul Guinan",
  "Tim Gula",
  "Paul Gulacy",
  "Jingxiong Guo",
  "Mike Gustovich",
  "Manuel Gutierrez",
  "Peter Guzman",
  "Gene Ha",
  "Brian Haberlin",
  "Robert Hack",
  "Merrill Hagan",
  "Eugene M.Hagan III",
  "Kim Hagen",
  "George Hagenauer",
  "Dean Hagl",
  "David Hahn",
  "Trevor Hairsine",
  "Dean Hale",
  "Phil Hale",
  "Shannon Hale",
  "Matt Haley",
  "Jason Hall",
  "Robert P Hall",
  "Marly Halpern - Graser",
  "Laurie Halse Anderson",
  "Dale Halvorsen",
  "Larry Hama",
  "Craig Hamilton",
  "Edmond Hamilton",
  "Jesse Hamm",
  "Sam Hamm",
  "P.C.Hammerlinck",
  "Cully Hamner",
  "Bo Hampton",
  "Scott Hampton",
  "Cory Hamscher",
  "Jin Han",
  "Bob Haney",
  "Scott Hanna",
  "Frank Hannah",
  "Edward Hannigan",
  "Ian Hannin",
  "Stephanie Hans",
  "Bjarne Hansen",
  "Dustin Hansen",
  "Judith Hansen",
  "Glen Hanson",
  "Asaf Hanuka",
  "Tomer Hanuka",
  "Chad Hardin",
  "Paul Harding",
  "Gabriel Hardman",
  "Tim Harkins",
  "William Harms",
  "Fred Harper",
  "Robert Harper",
  "Robert Harras",
  "Erica Harrell",
  "James Harren",
  "Dan Harris",
  "Jack Harris",
  "Joe Harris",
  "N Steven Harris",
  "Ron Harris",
  "Tony Harris",
  "Tom Hart",
  "Wes Hartman",
  "Andy Hartnell",
  "Oluf W.Hartvigson",
  "James Harvey",
  "Janet Harvey",
  "R.C.Harvey",
  "Irwin Hasen",
  "Dean Haspiel",
  "Kurt Hathaway",
  "Jeremy Haun",
  "Louise Hawes",
  "Mike Hawthorne",
  "Rob Hayes",
  "Fred Haynes",
  "Rob Haynes",
  "Doug Hazlewood",
  "Daniel HDR",
  "Russ Heath",
  "Don Heck",
  "John Heebink",
  "Sacha Heilig",
  "Allan Heinberg",
  "Tom Heintjes",
  "Karl Heitmueller",
  "Andrew Helfer",
  "Danny Hellman",
  "Marc Hempel",
  "Amos Hemsley",
  "Erica Henderson",
  "Felicia Henderson",
  "Sam Henderson",
  "Sheperd Hendrix",
  "Andrew Hennessy",
  "Niko Henrichon",
  "Daniel Henriques",
  "Clayton Henry",
  "Flint Henry",
  "Millicent Henson",
  "Scott Hepburn",
  "Jack Herbert",
  "Jaime Hernand",
  "Angel Hernandez",
  "Gilbert Hernandez",
  "Jaime Hernandez",
  "Lea Hernandez",
  "Natalia Hernandez",
  "Jason Hernandez - Rosenblatt",
  "Heroic Age",
  "Benjamin Herrera",
  "Francisco Herrera",
  "Ian Herring",
  "Ed Herron",
  "France Herron",
  "Talia Hershewe",
  "Phil Hester",
  "Meghan Hetrick",
  "Vinton T Heuck",
  "Jamie Hewlett",
  "Hi - Fi",
  "Everett E.Hibbard",
  "Faith Erin Hicks",
  "Graham Higgins",
  "John Higgins",
  "Kyle Higgins",
  "Nessim Higson",
  "Bryan Hill",
  "Joe Hill",
  "John J.Hill",
  "Mike Hill",
  "Sefton Hill",
  "Don Hillsman",
  "Selwyn Hinds",
  "David Hine",
  "Daniel Hipp",
  "Bryan Hitch",
  "John Hitchcock",
  "Don Ho",
  "Eric Hobbs",
  "Rick Hoberg",
  "James Hodgkins",
  "Erich Hoeber",
  "Jon Hoeber",
  "Rob Hoegee",
  "Derek Hoffman",
  "Mike Hoffman",
  "Peter Hogan",
  "Jonathan Holdredge",
  "Matthew Hollingsworth",
  "Frank Homiski",
  "Josh Hood",
  "Nanci Hoolahan",
  "Chris Hooten",
  "Sandra Hope",
  "Hope Larson",
  "Nalo Hopkinson",
  "Kevin Hopps",
  "Richard Horie",
  "Tanya Horie",
  "Alex Horley",
  "Daniel Horn",
  "Greg Horn",
  "Paul Hornschmeier",
  "David Hornung",
  "Dylan Horrocks",
  "Brian Horton",
  "Steve Horton",
  "Rik Hoskin",
  "Kyle Hotz",
  "Jody Houser",
  "Jason Howard",
  "Jono Howard",
  "Kat Howard",
  "Tini Howard",
  "Wayne Howard",
  "Zach Howard",
  "Matt Howarth",
  "Corin Howell",
  "Richard Howell",
  "Tan Eng Huat",
  "Troy Hubbs",
  "Mike Huddleston",
  "Reginald Hudlin",
  "Donald Hudson",
  "Jeff Huet",
  "Kurt Huggins",
  "Adam Hughes",
  "Eugene Hughes",
  "Floyd Hughes",
  "Rian Hughes",
  "Richard E.Hughes",
  "Robert Hughs",
  "Sam Humphries",
  "Leslie Hung",
  "Dave Hunt",
  "Ken Hunt",
  "Rob Hunter",
  "Brian Hurtt",
  "Gregg Hurwitz",
  "Trevor Hutchison",
  "Scott Ian",
  "Matthew Idelson",
  "Raffaele Ienco",
  "Jamal Igle",
  "The Iguana",
  "Carli M Ihde",
  "Joe Illidge",
  "Kathryn Immonen",
  "Stuart Immonen",
  "Toni Infante",
  "Carmine Infantino",
  "Joseph M Infurnari",
  "Bob Ingersoll",
  "Lora A Innes",
  "Frazer Irving",
  "Mark Irwin",
  "Rebekah Isaacs",
  "Andres G Isabel",
  "Tony Isabella",
  "Richard Isanove",
  "Geof Isherwood",
  "Christopher Ivy",
  "Scott Iwahashi",
  "Bill Jaaska",
  "Joshua Jackson",
  "Russell L Jackson",
  "Matt Jacobs",
  "Jack Jadson",
  "Joe James",
  "Jamison Service",
  "Jimmy Janes",
  "Mikel Janin",
  "Dennis Janke",
  "Fabio Jansen",
  "Klaus Janson",
  "Sandy Jarrell",
  "Kirk Jarvinen",
  "Alexander Jay",
  "James Jean",
  "Georges Jeanty",
  "Allan Jefferson",
  "Robert Jeffrey II",
  "Michael Jelenic",
  "Matt Jenkins",
  "Paul Jenkins",
  "Dennis Jensen",
  "Van Jensen",
  "Scott Jeralds",
  "Brandon Jerwa",
  "Robert Jeschonek",
  "Jorge Jimenez",
  "Oscar Jimenez",
  "Phil Jimenez",
  "Jock",
  "Jess Jodloman",
  "Geoff Johns",
  "Jeremy Johns",
  "Daniel Warren Johnson",
  "Daniel Curtis Johnson",
  "Dave Johnson",
  "Drew Johnson",
  "Eric Johnson",
  "Jason Johnson",
  "Jeff Johnson",
  "Kim 'Howard' Johnson",
  "Mat Johnson",
  "Mike Johnson",
  "Paul Johnson",
  "Peter Johnson",
  "Phillip Kennedy Johnson",
  "Richard Johnson",
  "Staz Johnson",
  "Aaron Johnston",
  "Dan Jolley",
  "Adam Jones",
  "Bruce Jones",
  "Casey Jones",
  "Christopher Jones",
  "Eric Jones",
  "Gerard Jones",
  "J.G.Jones",
  "Jeff Jones",
  "Jeffrey Catherine Jones",
  "Joëlle Jones",
  "Katie Jones",
  "Kelley Jones",
  "Tristan Jones",
  "Malcolm Jones, III",
  "Henrik Jonsson",
  "Chris Jordan",
  "Justin Jordan",
  "Arnie Jorgensen",
  "Ruy Jose",
  "Tom Joyner",
  "Juanjo",
  "Kolby Jukes",
  "Minkyu Jung",
  "Dan Jurgens",
  "Joe Jusko",
  "Kris Justice",
  "Justiniano",
  "David Justus",
  "Barbara Kaalberg",
  "Richard Kadrey",
  "David Kahn",
  "Jenette Kahn",
  "Studios Kaleidocolor",
  "David Kaler",
  "Victor Kalin",
  "John Kalisz",
  "Michael Wm.Kaluta",
  "Viktor Kalvachev",
  "George Kambadais",
  "Len Kaminski",
  "Bob Kane",
  "Gil Kane",
  "Robert Kane",
  "Robert Kanigher",
  "Kano",
  "Hal Kantor",
  "John Kantz",
  "Arie Kaplan",
  "Kate Karyus Quinn",
  "George Kashdan",
  "Alex Katsnelson",
  "Jack Katz",
  "Jeff Katz",
  "Annette Kawecki",
  "Rafael Kayanan",
  "Stan Kaye",
  "Mike Kazaleh",
  "Joe Keatinge",
  "Joe Keatinge",
  "Brian L.Keene",
  "Rick Keene",
  "Jason Keith",
  "Collin Kelly",
  "Joe Kelly",
  "Ryan Kelly",
  "Cam Kennedy",
  "Mike Kennedy",
  "Dale Keown",
  "John Kerschbaum",
  "Karl Kerschl",
  "Barbara Kesel",
  "Karl Kesel",
  "Scott Kester",
  "Rick Ketcham",
  "Lukas Ketner",
  "Joseph Howard Ketterer",
  "Hena Khan",
  "Abhay Khosla",
  "Chip Kidd",
  "Caitlín R.Kiernan",
  "Sam Kieth",
  "Kyle Killen",
  "Charles Kim",
  "Mini Kim",
  "Loh Kin Sun",
  "Matt Kindt",
  "Lovern Kindzierski",
  "Curtis King",
  "Donald King",
  "Jeff King",
  "Stephen King",
  "Tom King",
  "Jack Kirby",
  "JJ Kirby",
  "Juvaun Kirby",
  "Roz Kirby",
  "Paul Kirchner",
  "Derek Kirk",
  "Leonard Kirk",
  "Tyler Kirkham",
  "Karen Kish",
  "Denis Kitchen",
  "Barry Kitson",
  "Shawn Kittelsen",
  "Caitlin Kittredge",
  "Steve Kiwus",
  "Steve Kiwus",
  "Steve Kiwus",
  "Steven Kiwus",
  "Rick Klaw",
  "Klebs De Moura Jr",
  "Neil Kleid",
  "David G Klein",
  "George Klein",
  "Nic Klein",
  "Robert Klein",
  "Todd Klein",
  "Leslie S Klinger",
  "Lisa Klink",
  "Adam P.Knave",
  "Mark Kneece",
  "Andreas C Knigge",
  "Thompson Knox",
  "Scott Koblish",
  "James Kochalka",
  "Bill Koeb",
  "Irene Koh",
  "Scott Kolins",
  "Alexander Konat",
  "Sean Michael Konot",
  "Igor Kordey",
  "Tony Kordos",
  "Giovani Kososki",
  "Ales Kot",
  "Kotobukiya",
  "Travis Kotzebue",
  "Alex Kotzky",
  "Scott Kowalchuk",
  "David Anthony Kraft",
  "Michael Kraiger",
  "Thomas Krajewski",
  "Daniel Krall",
  "Don Kramer",
  "Kevin A Kramer",
  "Peter Krause",
  "Darryl Kravitz",
  "Andrew Kreisberg",
  "Earl Kress",
  "John Krewson",
  "Bernard Krigstein",
  "Eric Kripke",
  "Teddy Kristiansen",
  "Laurie Kronenberg",
  "George Krstic",
  "Jim Krueger",
  "J.T.Krul",
  "Brandon Kruse",
  "Ray Kryssing",
  "Min S.Ku",
  "Adam Kubert",
  "Andy Kubert",
  "Joe Kubert",
  "Asago Janice Kubo",
  "Aaron Kuder",
  "Szymon Kudranski",
  "Andy Kuhn",
  "Frank A Kuhn",
  "Sarah Kuhn",
  "James Anthony Kuhoric",
  "Jagdish Kumar",
  "Mike Kunkel",
  "Peter Kuper",
  "Alan Kupperberg",
  "Paul Kupperberg",
  "Michael Kupperman",
  "Steve Kurth",
  "Jay Kushwara",
  "Jiro Kuwata",
  "Gary Kwapisz",
  "Alisa Kwitney",
  "Jean - Marc L'Officier",
  "Randy L'Officier",
  "Charlie La Greca",
  "Terry Laban",
  "Yancey Labat",
  "Mercedes Lackey",
  "Michel Lacombe",
  "Joseph Lacroix",
  "Jose Ladronn",
  "David Lafuente",
  "Ray Lago",
  "Fabio Laguna",
  "Joseph L Lalich",
  "Jon Lam",
  "Marc Laming",
  "Harry Lampert",
  "Greg Land",
  "Bob Lander",
  "Stefano Landini",
  "John Landis",
  "Max Landis",
  "Tim Lane",
  "Roger Langridge",
  "Travis Lanham",
  "Andy Lanning",
  "Jackson Lanning",
  "David Lanphear",
  "Joe Lansdale",
  "Jackson Lanzing",
  "David Lapham",
  "Serge Lapointe",
  "Bob Lappan",
  "Michael Lark",
  "Salvador Larocca",
  "Greg LaRocque",
  "Bud Larosa",
  "Lew LaRosa",
  "Erik Larsen",
  "Jonathan Larsen",
  "Abigail Larson",
  "Batton Lash",
  "Ken Lashley",
  "Nico Lathouris",
  "Diego Latorre",
  "Jason Latour",
  "Matthew Lau",
  "Stanley 'Artgerm' Lau",
  "Ian Laughlin",
  "Greg Lauren",
  "Christine Lavaf",
  "Jeremy Lawson",
  "Carol Lay",
  "John Layman",
  "Bob Layton",
  "Rob Lea",
  "Garry Leach",
  "Rob Lean",
  "Andre LeBlanc",
  "Joe Ledbetter",
  "Alvin Lee",
  "Edward Lee",
  "Elaine Lee",
  "Jae Lee",
  "Jeehyung Lee",
  "Jim Lee",
  "Norman Lee",
  "Pat Lee",
  "Paul Lee",
  "Stan Lee",
  "Sunny Lee",
  "Tony Lee",
  "Shelly Leferman",
  "Matthew Lehosit",
  "Alex Lei",
  "Steve Leialoha",
  "Rob Leigh",
  "Jay Leisten",
  "Daniel Leister",
  "Jeff Lemire",
  "Joao Lemos",
  "Emi Lenox",
  "John Paul Leon",
  "Rick Leonardi",
  "Bob LeRose",
  "Kate Leth",
  "Al Letson",
  "Joe Letterese",
  "Sarah Leuver",
  "Brian Level",
  "Megan Levens",
  "Rob Levin",
  "Tim Levins",
  "Paul Levitz",
  "Bob Lewis",
  "Brett Lewis",
  "Jason Lewis",
  "Jon Lewis",
  "Mark Lewis",
  "Richmond Lewis",
  "Jasen Lex",
  "Christine Li",
  "Yishan Li",
  "Gaetano Liberatore",
  "Steve Lieber",
  "A.J.Lieberman",
  "Barry Liebman",
  "Rob Liefeld",
  "Sonny Liew",
  "Steve Lightle",
  "Mike Lilly",
  "Ron Lim",
  "Igor Lima",
  "Damon Lindelof",
  "Joseph Michael Linser",
  "Mark Lipka",
  "Russell Lissau",
  "Toby Litt",
  "Jason Little",
  "Livesay",
  "Victor Llamas",
  "David Lloyd",
  "Grazia Lobaccaro",
  "Scott Lobdell",
  "Beni Lobel",
  "Michael Locascio",
  "Vince Locke",
  "Inverna Lockpez",
  "Jeph Loeb",
  "Sam Loeb",
  "June Lofamia",
  "Daniel Loflin",
  "Kenneth Loh",
  "Rex Lokus",
  "Vasilis Lolos",
  "Bobby London",
  "Christopher E Long",
  "Thomas Long",
  "Alvaro Lopez",
  "David Lopez",
  "Emilio J Lopez",
  "Julian Lopez",
  "Ken Lopez",
  "Michael Lopez",
  "Ravil Lopez",
  "Aaron Lopresti",
  "Tula Lotay",
  "John Loter",
  "Sam Lotfi",
  "Lee Loughridge",
  "Warren Louw",
  "Jeffrey Alan Love",
  "Jeremy Love",
  "Jeff Loveness",
  "John Lowe",
  "Marie Lu",
  "Marie Lu",
  "John Lucas",
  "Jorge Lucas",
  "Ant Lucia",
  "Jose Luis",
  "Eric Luke",
  "Ludo Lullabi",
  "Angel B.Luna",
  "Demitria Lunetta",
  "Emanuela Lupacchino",
  "Gretel Lusky",
  "Jason Lutes",
  "Tom Luth",
  "Nelson Luty",
  "Daniel Luvisi",
  "Greg Luzniak",
  "Tim Chi Ly",
  "Tom Lyle",
  "Robert Lynders",
  "Art Lyon",
  "Steve Lyons",
  "Minh Lê",
  "Darko Macan",
  "Andrew T MacDonald",
  "Lennie Mace",
  "Carmen Maria Machado",
  "Mike Machlan",
  "David Macho Gomez",
  "Francisco Javier Macias Blanco",
  "David Mack",
  "Howard Mackie",
  "Andrew Maclean",
  "Colin Macneil",
  "Dev Madan",
  "Matt Madden",
  "Rachel Maddow",
  "James Maddox",
  "Ivory Sophia Madison",
  "Joe Madureira",
  "Jose Wilson Magalhaes",
  "Elliot Maggin",
  "Carlos Magno",
  "Jymn Magon",
  "Kevin Maguire",
  "Rick Magyar",
  "Jim Mahfood",
  "Esphidy Mahilum",
  "Larry Mahlstedt",
  "Doug Mahnke",
  "Mike Maihack",
  "Marcelo Maiolo",
  "Guy Major",
  "Nimit Malavia",
  "Alex Maleev",
  "Ronald Malfi",
  "Nestor Malgapo",
  "Paul Malmont",
  "Jesse Maloney",
  "Man Of Action LLC",
  "Francis Manapul",
  "Milo Manara",
  "Leonardo Manco",
  "Tom Mandrake",
  "Susan Mangan",
  "Carlos M.Mangual",
  "Andrew Mangum",
  "Graham Manley",
  "Mike Manley",
  "Clay Mann",
  "Seth Mann",
  "Matt Manning",
  "Steve Mannion",
  "Bill Mantlo",
  "Raymond Marais",
  "Guillem March",
  "Pablo Marcos",
  "Bill Marimon",
  "Enrico Marini",
  "V Ken Marion",
  "Jeff Mariotte",
  "Rob Markman",
  "Julie Maroh",
  "Esteban Maroto",
  "David Marquez",
  "Frank Marrafino",
  "J.J.Marreiro",
  "Chris Marrinan",
  "Lynn Marron",
  "Lee Marrs",
  "James Marsano",
  "Brian David Marshall",
  "William Moulton Marston",
  "Cynthia Martin",
  "Frank Martin",
  "Gary Martin",
  "Jason Martin",
  "Laura DePuy Martin",
  "Patrick Martin",
  "Shawn Martinbrough",
  "Alitha Martinez",
  "Allen Martinez",
  "Alvaro Martinez",
  "Jesus Salvadore Aburt Martinez",
  "Kenny Martinez",
  "Marta Martinez",
  "Roy A.Martinez",
  "Mike Marts",
  "Marcos Martín",
  "Christy Marx",
  "Marcos Marz",
  "Ron Marz",
  "José Marzán, Jr.",
  "Agustin Mas",
  "Pat Masioni",
  "Thomas Mason",
  "Alex Massacci",
  "Felipe Massafera",
  "Nathan Massengill",
  "Jason Masters",
  "Ollie Masters",
  "Irene Matar",
  "Karen Matchette",
  "Mateus",
  "Bill Matheny",
  "Jack Mathews",
  "Jeff Matsuda",
  "Joe Matt",
  "Johanne Matte",
  "Jonathan Matthews",
  "Francesco Mattina",
  "Alain Matz",
  "Kevin Maurer",
  "Mauricet",
  "Paul Maybury",
  "JP Mayer",
  "Sheldon Mayer",
  "Michael Mayhew",
  "Ralph Mayo",
  "Randy Mayor",
  "Corey Mays",
  "Rick Mays",
  "David Mazzucchelli",
  "Jim McArdle",
  "Mike McAvennie",
  "Paul McCaffrey",
  "Dave McCaig",
  "Ron McCain",
  "Pat Mccallum",
  "James McCann",
  "Jesse Leon McCann",
  "Brendan McCarthy",
  "Jim McCarthy",
  "Kevin McCarthy",
  "Ray McCarthy",
  "Shane McCarthy",
  "Trevor McCarthy",
  "Scott McCloud",
  "Marc Mcclure",
  "Ben McCool",
  "Dylan McCrae",
  "Dave - Co McCraig",
  "Tom McCraw",
  "John McCrea",
  "Laurenn McCubbin",
  "Derek McCulloch",
  "Dan McDaid",
  "Scott McDaniel",
  "Walter Antonio McDaniel",
  "Andy McDonald",
  "Gene McDonald",
  "Luke McDonnell",
  "Dwayne McDuffie",
  "Kathryn McElroy",
  "Pat McEown",
  "Todd McFarlane",
  "McFarlane Toys",
  "Cara McGee",
  "Jeanne McGee",
  "Royal McGraw",
  "Pat McGreal",
  "Don McGregor",
  "Ed McGuinness",
  "Dave McKean",
  "Sean McKeever",
  "Ted McKeever",
  "Jamie McKelvie",
  "Mark McKenna",
  "Stewart Mckenny",
  "Angus McKie",
  "Mike McKone",
  "Frank McLaughlin",
  "Bob McLeod",
  "Chris McLoughlin",
  "Mick McMahon",
  "James McManus",
  "Shawn McManus",
  "Michael McMillian",
  "Amanda Mcmurray",
  "Mark McMurray",
  "Carla 'Speed' McNeil",
  "Jim McPherson",
  "Tara McPherson",
  "John McRae",
  "Scott McRae",
  "Tom McWeeney",
  "Amy Mebberson",
  "Eduardo Medeiros",
  "Mediatoon",
  "Medicom",
  "Lan Medina",
  "Paco Medina",
  "Linda Medley",
  "Denis Medri",
  "Carlos A Meglia",
  "Nick Meglin",
  "David Meikis",
  "Hermann Mejia",
  "Sergio Melia",
  "Adriana Melo",
  "Brad Meltzer",
  "Javier Mena Guerrero",
  "Miguel Mendonça",
  "Jaime Mendoza",
  "Fiona Meng",
  "Joe Menna",
  "Bill Meredith",
  "Matt Merhoff",
  "Jesús Merino",
  "Nathaniel Merriam",
  "Andy Merrill",
  "Mort Meskin",
  "David Messina",
  "William Messner - Loebs",
  "J.D.Mettler",
  "Jonboy Meyers",
  "Pop Mhan",
  "David Michelinie",
  "Joshua Middleton",
  "Grant Miehm",
  "Duke Mighten",
  "Rodolfo Migliari",
  "Mike Mignola",
  "David Rubin Miguelez",
  "Danny Miki",
  "Ivo Milazzo",
  "Al Milgrom",
  "Mark Millar",
  "Sas Milledge",
  "Bryan Q.Miller",
  "Derek Miller",
  "Frank Miller",
  "George Miller",
  "Jack Miller",
  "Mike S.Miller",
  "Tim Miller",
  "Harris Miller II",
  "Jason Millet",
  "Peter Milligan",
  "Tony Millionaire",
  "Jonathan Mills",
  "Pat Mills",
  "Denise Mina",
  "Aaron Minier",
  "Inaki Miranda",
  "Bernie Mireault",
  "Peter Miriani",
  "Dan Mishkin",
  "Matt Mitchell",
  "Mike Mitchell",
  "Steve Mitchell",
  "T.D.Mitchell",
  "Todd Mitchell",
  "Christopher Mitten",
  "China Miéville",
  "Lee Moder",
  "Christopher Moeller",
  "Doug Moench",
  "Annie Mok",
  "Audrey Mok",
  "Sheldon Moldoff",
  "Romano Molenaar",
  "Jorge Molina",
  "Larry Molinar",
  "Karl M Moline",
  "George Moliterni",
  "Shawn Moll",
  "Stephen Molnar",
  "Bill Molno",
  "David Monahan",
  "John Caleb Monroe",
  "Monster 5",
  "Steve Montano",
  "Brandon Montclare",
  "Goni Montes",
  "Javier Montes Pampin",
  "Victor Santos Montesinos",
  "Albert Monteys",
  "Fabio Moon",
  "Jim Mooney",
  "Stephen Mooney",
  "Christopher Mooneyham",
  "Michael Moorcock",
  "Alan Moore",
  "B.Clay Moore",
  "Drew R Moore",
  "Jason Moore",
  "Jeff Moore",
  "Jennifer Moore",
  "Jerome K.Moore",
  "John Francis Moore",
  "Leah Moore",
  "Steve Moore",
  "Stuart Moore",
  "Stuart Moore",
  "Susan Moore",
  "Terry Moore",
  "Tony Moore",
  "Tradd Moore",
  "Travis Moore",
  "Dan Mora",
  "Mariano L Morales",
  "Mark Morales",
  "Rags Morales",
  "Martin Morazzo",
  "Michael Moreci",
  "Ruben Moreira",
  "Jack Morelli",
  "Tomeu Morey",
  "Alec Morgan",
  "Tom Morgan",
  "Moritat",
  "Will Morris",
  "Bill Morrison",
  "Grant Morrison",
  "Robbie Morrison",
  "Gabriele Morrissette",
  "P.C.Morrissey",
  "Paul Morrissey",
  "Cynthia Morrow",
  "Gray Morrow",
  "John Morrow",
  "Bill Morse",
  "Bob Morse",
  "Scott Morse",
  "Win Mortimer",
  "Olly Moss",
  "Marc Mostman",
  "Carlos Mota",
  "Dean Motter",
  "Ludovic Mouliere",
  "Paul Mounts",
  "Ibrahim Moustafa",
  "Jeff Moy",
  "Phil Moy",
  "Lee Moyer",
  "MSASSYK",
  "John Mueller",
  "Qing Ping Mui",
  "Kris Mukai",
  "Patricia Mulvihill",
  "Bill Mummy",
  "Miguel A.Munera Sancho",
  "Jose Munoz",
  "William Hideki Murai",
  "Glen Murakami",
  "Reiko Murakami",
  "Sho Murase",
  "Joyce Murchison",
  "Jennifer Muro",
  "Sean Murphy",
  "Charles S.Murray",
  "Jim Murray",
  "Sean Andrew Murray",
  "Peter Murrieta",
  "Jon J.Muth",
  "Andrea Mutti",
  "Marat Mychaels",
  "Lauren Myracle",
  "Thomas Nachlik",
  "Amancay Nahuelpan",
  "Ted Naifeh",
  "Mayo “SEN” Naito",
  "Dan Nakrosis",
  "Nick J.Napolitano",
  "Dara Naraghi",
  "Diogo Nascimento",
  "Miguel Nash",
  "Yoshinori Natsume",
  "Todd Nauck",
  "Paul Neary",
  "Rudy Nebres",
  "Ovidiu Nedelcu",
  "Emma Needell",
  "Scott Neely",
  "Micky Neilson",
  "Yakimoki Neilson",
  "Arvid Nelson",
  "Mark Nelson",
  "Michael Alan Nelson",
  "Nelson",
  "Michael Netzer",
  "Josh Neufeld",
  "Diogenes Neves",
  "Fabiano Neves",
  "Jess Nevins",
  "John Nevins",
  "Eddy Newell",
  "Mindy Newell",
  "Paul S Newman",
  "Don Newton",
  "Dustin Nguyen",
  "Eric Nguyen",
  "Peter Nguyen",
  "Tom Nguyen",
  "Art Nichols",
  "James Art Nichols",
  "Wayne Nichols",
  "Jeff Nicholson",
  "Fabian Nicieza",
  "Al Nickerson",
  "Tommy Nicolosi",
  "Chris Nierako",
  "Ken Niimura",
  "Marieke Nijkamp",
  "Steve Niles",
  "Stephan Nilson",
  "Alex Nino",
  "Jai Nitz",
  "Larry Niven",
  "Troy Nixey",
  "Ann Nocenti",
  "Martin Nodell",
  "Christopher Nolan",
  "Graham Nolan",
  "Michelle Nolan",
  "Oliver Nome",
  "Cary Nord",
  "Christine Norrie",
  "Paul Norris",
  "Michael Northrop",
  "Daniel Norton",
  "Michael Norton",
  "Phil Noto",
  "Gregory Noveck",
  "Irv Novick",
  "Phyllis Novin",
  "Leo Nowak",
  "Kevin Nowlan",
  "Heather Nuhfer",
  "Eduardo Nunez",
  "John Nyberg",
  "James O'Barr",
  "Mitch O O'Connell",
  "Thomas O'Connor",
  "Michael S O'Hare",
  "Walter O'Neal",
  "Bill O'Neil",
  "Dennis O'Neil",
  "Kevin O'Neill",
  "Bill Oakley",
  "Shane Oakley",
  "Sonia Oback",
  "Abe Ocampo",
  "Weston Ochse",
  "Ben Oda",
  "Ryan Odagawa",
  "Daniel V Oduber",
  "Michael Avon Oeming",
  "Rex Ogle",
  "Nnedi Okorafor",
  "Bob Oksner",
  "Victor Olazaba",
  "Jack Oleck",
  "Steve Oliff",
  "Leandro Oliveira",
  "Ben Oliver",
  "Simon Oliver",
  "Ariel Olivetti",
  "Patrick Olliffe",
  "Diego Olmos",
  "Josh Olson",
  "Jerome Opena",
  "Kris Oprisko",
  "Glen Orbik",
  "Jerry Ordway",
  "Jason Orfalas",
  "Joe Orlando",
  "Steve Orlando",
  "Toril Orlesky",
  "Dean Ormston",
  "Joshua Ortega",
  "Guillermo Ortego",
  "Juan Ortiz",
  "Richard Ortiz",
  "Tom Orzechowski",
  "Zu Orzu",
  "Guillaume Ospital",
  "John Ostrander",
  "Patton Oswalt",
  "Katsuhiro Otomo",
  "Ryan Ottley",
  "Horacio Ottolini",
  "Siya Oum",
  "Erich Owen",
  "James Owen",
  "Andy Owens",
  "Jim Owsley",
  "Tayyar Ozkan",
  "Richard Pace",
  "Carlos Pacheco",
  "Jorge Pacheco",
  "Peter Pachoumis",
  "Agustin Padilla",
  "Carlo Pagulayan",
  "Danielle Paige",
  "Mark Pajarillo",
  "Greg Pak",
  "Gregory A Palast",
  "Karen Palinko",
  "Amanda Palmer",
  "Tom Palmer",
  "Jimmy Palmiotti",
  "Peter Palmiotti",
  "Alexandre Palomaro",
  "Ulises Arreola Palomera",
  "Noly Panaligan",
  "Pander Brothers",
  "Dan Panosian",
  "Eduardo Pansica",
  "Paolo Pantalena",
  "Peter Pantazis",
  "George Papp",
  "William Paquet",
  "Yanick Paquette",
  "Gerald Parel",
  "Dan Parent",
  "Paolo Parente",
  "Lucio Parillo",
  "Charles Paris",
  "Roland Paris",
  "Andy Park",
  "Charles Park",
  "Steven Parke",
  "Bradley Parker",
  "Jeff Parker",
  "Rick Parker",
  "Tony Parker",
  "Annie Parkhouse",
  "Steve Parkhouse",
  "Gregory Parkin",
  "Ande Parks",
  "Goran Parlov",
  "Mike Parobeck",
  "Francisco Paronzini",
  "Ryan Parrott",
  "Brett Parson",
  "Dan Parsons",
  "Sean Parsons",
  "Milen Parvanov",
  "Fernando Pasarin",
  "James Pascoe",
  "Martin Pasko",
  "Allen Passalaqua",
  "Ernesto Patricio",
  "James Patrick",
  "Jason Patrick",
  "Tony Patrick",
  "Bruce Patterson",
  "Billy Patton",
  "Chuck Patton",
  "Stuart Paul",
  "Jason Paulos",
  "Jason Paz",
  "Bill Pearson",
  "Jason Pearson",
  "Ridley Pearson",
  "James Peaty",
  "Brent Peeples",
  "Harvey Pekar",
  "Marcos Pelayo",
  "Ruben Pellejero",
  "Paul Pelletier",
  "Michael J.Pellowski",
  "Justin Roy Peniston",
  "Marcus Penna",
  "Mark Pennington",
  "Laurie Penny",
  "Shea Anton Pensa",
  "Andrew Pepoy",
  "Mike Peppe",
  "German Peralta",
  "Benjamin Percy",
  "Alejandro Pereira",
  "Bruno H Pereira",
  "David Pereira",
  "Nelson Pereira",
  "Nestor Pereyra",
  "Omaha Perez",
  "Pere Perez",
  "Ramon Perez",
  "Robero C Perez",
  "MK Perker",
  "K.Perkins",
  "Mike Perkins",
  "Don Perlin",
  "Richard Perrotta",
  "Raymond Perry",
  "Stephane Peru",
  "Chris Peter",
  "Cristiane Duarte Peter",
  "Harry G.Peter",
  "Matt Peters",
  "David Petersen",
  "Roger Petersen",
  "Brandon Peterson",
  "Eric Peterson",
  "Jonathan Peterson",
  "Scott Peterson",
  "Priscilla Petraites",
  "Bob Petrecca",
  "Eduard Petrovich",
  "Hugo Petrus",
  "Lauren Petty",
  "Tom Peyer",
  "Will Pfeifer",
  "Khoi Pham",
  "Gary Phillips",
  "Jack Phillips",
  "Joe Phillips",
  "Sean Phillips",
  "Stephen John Phillips",
  "Rina Piccolo",
  "Pornsak Pichetshote",
  "Gabriel Picolo",
  "Jodi Picoult",
  "Nathan Piekos",
  "Mirco Pierfederici",
  "Joe Pimentel",
  "Javier Pina",
  "Bob Pinaha",
  "Wendy Pini",
  "Amilicar Pinna",
  "Antone W Pires",
  "Ed Piskor",
  "Thomas Pitilli",
  "Joao Paulo Pitombeira Maia",
  "Stanley Pitt",
  "Matthew Pitts",
  "Alfred Plastino",
  "Karen Platt",
  "Stephen Platt",
  "Warren Pleece",
  "Mike Ploog",
  "Kilian Plunkett",
  "Sandy Plunkett",
  "Rachel Pollack",
  "Keith Pollard",
  "Adam Pollina",
  "Paolo Pomtelenz",
  "Andres Ponce",
  "Justin Ponsor",
  "Ken Pontac",
  "Alberto Ponticelli",
  "Giorgio Pontrelli",
  "Paul Pope",
  "Robert Pope",
  "Peter Poplaski",
  "Whilce Portacio",
  "Francis Portela",
  "Howard Porter",
  "Carl Posey",
  "Howard Post",
  "Greg Potter",
  "Carl Potts",
  "Mark Poulton",
  "Peter Pound",
  "Mark Poutenis",
  "Asher Powell",
  "Eric Powell",
  "Nathan L Powell",
  "Dermont Power",
  "Joe Prado",
  "Miguelanxo Prado",
  "Hendry Prasetya",
  "Rhianna Pratchett",
  "George Pratt",
  "Pre - Sold Productions, Inc",
  "Manuel Preitano",
  "Bruno Premiani",
  "John Prentice",
  "Pat Prentice",
  "Arif Prianto",
  "Christopher Priest",
  "Ruben Procopio",
  "Desirée Proctor",
  "John Proctor",
  "J.M.Prollet",
  "Mark Propst",
  "Kelley Puckett",
  "Steve Pugh",
  "Javier Pulido",
  "CM Punk",
  "Gordon Purcell",
  "Howard Purcell",
  "Jack Purcell",
  "Steve Purcell",
  "Yasmine Putri",
  "George Pérez",
  "Alan Quah",
  "Pasquale Qualano",
  "Luciano Queiros",
  "Joe Quesada",
  "Pat Quinn",
  "Zoe Quinn",
  "Joseph A Quinones Jr.",
  "Wil Quintana",
  "Wilfredo Quintana",
  "Frank Quitely",
  "Ben Raab",
  "Jheremy Raapack",
  "Brad Rader",
  "Bobby Rae",
  "Colin Raff",
  "Stefano Raffaele",
  "Michael Raicht",
  "Pablo Raimondi",
  "James Raiz",
  "Rakes Web World",
  "Brian Ralph",
  "Elton Ramalho",
  "Pamela Rambo",
  "Phil Ramirez",
  "Philip Ramirez",
  "Victor Ibanez Ramirez",
  "Ed Ramos",
  "Humberto Ramos",
  "Jay David Ramos",
  "Manuel Ramos",
  "Rodney Ramos",
  "Ron Randall",
  "Grace Randolph",
  "Khary Randolph",
  "Gina Going Raney",
  "Tom Raney",
  "Ian Rankin",
  "Arthur Ranson",
  "Norm Rapmund",
  "Jordan Raskin",
  "Daniel Raspler",
  "Paitoon Ratan",
  "Cliff Rathburn",
  "Anthony John Rauch Jr.",
  "David Rawson",
  "Fred Ray",
  "Richard Piers Rayner",
  "Max Raynor",
  "Brian Reber",
  "Bruno Redondo",
  "Frank Redondo",
  "Nestor Redondo",
  "Quico Redondo",
  "Virgilio Redondo",
  "Brian James Reed",
  "David Vern Reed",
  "Edward E Reed",
  "John Reed",
  "Amy Reeder",
  "James P Reedy",
  "Ralph Reese",
  "Dominic Regan",
  "Sal Regla",
  "John Ney Reiber",
  "Crystal Reid",
  "Bill Reinhold",
  "Ivan Reis",
  "Rod Reis",
  "Diego Latore Relancio",
  "Rick Remender",
  "Chris Renaud",
  "Paul Renaud",
  "Jonny Rench",
  "Aaron Renier",
  "Stefani Rennee",
  "Paul Rentler",
  "Robert A.Renzas",
  "Rico Renzi",
  "John Reppion",
  "Simon Revelstroke",
  "Mac Rey",
  "Lawrence Reynolds",
  "Sandro Ribeiro",
  "Esad Ribic",
  "Cliff Richards",
  "Harvey Richards",
  "Afua Richardson",
  "Roy Richardson",
  "Ricken",
  "John Ridgway",
  "John Ridley",
  "John Ney Rieber",
  "Alan Riefe",
  "Robin Riggs",
  "Bill Riley",
  "Alvaro Rio",
  "Miguel Troncoso Riquelme",
  "Eduardo Risso",
  "Kyle D Ritter",
  "Rico Rival",
  "Robert Rivard",
  "Jon Rivera",
  "Paolo Rivera",
  "Paul Rivoche",
  "Luke Rizzo",
  "David Roach",
  "Frank Robbins",
  "Robert Robbins",
  "Trina Robbins",
  "Chris Roberson",
  "Ibraim Roberson",
  "Jeremy Roberts",
  "Thomas Scott Roberts",
  "Darick Robertson",
  "Clem Robins",
  "Andrew Robinson",
  "Angela Robinson",
  "Christopher G Robinson",
  "James Robinson",
  "Jerry Robinson",
  "Mark A.Robinson",
  "Roger Robinson",
  "Kenneth Rocafort",
  "Robson Rocha",
  "James Rochelle",
  "Robert Rodi",
  "Denis Rodier",
  "Hans Rodionoff",
  "Alecia Rodriguez",
  "Anibal Rodriguez",
  "Gabriel Rodriguez",
  "George Rodriguez",
  "Ivan Rodriguez",
  "Jasen Rodriguez",
  "Javier Rodriguez",
  "Noel Rodriguez",
  "Paulo Rodriguez",
  "Robbi Rodriguez",
  "Rodin Rodriguez",
  "Francisco Rodriguez de la Fuente",
  "Carlos Alberto Rodriguez Vazquez",
  "Pow Rodrix",
  "Claire Roe",
  "Amy Rogers",
  "John Rogers",
  "Marshall Rogers",
  "Prentis Rollins",
  "Steve Rolston",
  "Gashet Geyser Romain",
  "David Roman",
  "James Romberger",
  "George Romero",
  "George Romero",
  "Leonardo Romero",
  "John Romita, Jr.",
  "John Romita, Sr.",
  "Mireya Romo - Bowen",
  "William Rosado",
  "Joe Rosas",
  "Joel Rose",
  "Edwin Rosell",
  "Scott Rosenbaum",
  "Matthew Rosenberg",
  "Rachelle Rosenberg",
  "Adam Ross",
  "Alex Ross",
  "Dave Ross",
  "Luca Rossi",
  "Riley Rossmo",
  "Duncan Rouleau",
  "Craig Rousseau",
  "George Roussos",
  "Stephane Roux",
  "Adrienne Roy",
  "Mike Roy",
  "Jim Royal",
  "Jonathan Royce",
  "Mike Royer",
  "Bob Rozakis",
  "John Rozum",
  "Joao Ruas",
  "Joe Rubinstein",
  "Greg Rucka",
  "Steve Rude",
  "Marco Rudy",
  "Nei Ruffino",
  "James Rugg",
  "Lorenzo Ruggiero",
  "Felix Ruiz",
  "Nicholas V.Runge",
  "Douglas Rushkoff",
  "Mark Russell",
  "P.Craig Russell",
  "Vince Russell",
  "Greg Ruth",
  "Johnny Ryan",
  "Matt Ryan",
  "Michael Ryan",
  "Paul Ryan",
  "Sara Ryan",
  "Sean Ryan",
  "Juan Jose Ryp",
  "Forja S.L.",
  "Arthur 'Art' Saaf",
  "Horacio Saavedra",
  "Christopher Sabela",
  "Mark Sable",
  "Joe Sacco",
  "Bernard Sachs",
  "Stephen Sadowski",
  "Alberto Saichan",
  "Stan Sakai",
  "Gaspar Saladino",
  "Edgar Salazar",
  "Tim Sale",
  "Jimbo Salgado",
  "Daniel T Salgo",
  "Cleber Salles",
  "Tony Salmons",
  "Caleb Salstrom",
  "Javier Saltares",
  "Joe Samachson",
  "Chris Samnee",
  "Daniel Sampere",
  "Samplerman",
  "Maria Laura Sanapo",
  "Alex Sanchez",
  "Ricardo Sanchez",
  "Jim Sanders",
  "Pete Sanderson",
  "Rafa Sandoval",
  "Sergio Sandoval",
  "Joanna Sandsmark",
  "Mary Sangiovanni",
  "Derlis Santacruz",
  "Juan Santacruz",
  "Dave Santana",
  "Erika Santiago",
  "Tom Santo",
  "Mateus Santolouco",
  "Matt Santorelli",
  "Amilton Santos",
  "Marco Santucci",
  "Hainanu 'nooligan' Saulque",
  "Jose Marie Sauri",
  "Marguerite Sauvage",
  "Bill Savage",
  "Chris Savino",
  "Alex Saviuk",
  "Sylvain Savoia",
  "Brandon Archie Sawyer",
  "Jesús Saíz",
  "Matteo Scalera",
  "Matteo Scalero",
  "Rafael Scavone",
  "Kurt Schaffenberger",
  "Jordu Schell",
  "Bill Schelly",
  "Chris Schenck",
  "Patrick Scherberger",
  "Jack Schiff",
  "Candace Schinzler - Bell",
  "Adam Schlagman",
  "Otto Schmidt",
  "Ira Schnapp",
  "Brent Schoonover",
  "Ray Schott",
  "Bob Schreck",
  "Willie Schubert",
  "Debra Schulman",
  "Erica Schultz",
  "Mark Schultz",
  "Diana Schutz",
  "Rob Schwager",
  "Alvin Schwartz",
  "Dave Schwartz",
  "Julius Schwartz",
  "Lew Sayre Schwartz",
  "Jill Karla Schwarz",
  "Thomas Scioli",
  "Cavan Scott",
  "Damian Scott",
  "Damion Scott",
  "Greg Scott",
  "Mairghread Scott",
  "Nicola Scott",
  "Steve Scott",
  "Trevor Scott",
  "Kirk Scroggs",
  "Steven T.Seagle",
  "Bart Sears",
  "Kit Seaton",
  "Chris Sebela",
  "Tim Seeley",
  "Don Segall",
  "Stephen Segovia",
  "Segura Inc.",
  "Alison Seiffer",
  "Stjepan Sejic",
  "Mike Sekowsky",
  "David Self",
  "Mike Sellers",
  "Joelle M Sellner",
  "Val Semeiks",
  "John Semper, Jr.",
  "Mario Sen",
  "Miguel A Sepulveda",
  "Christopher Sequeira",
  "Jerry Serpe",
  "Felix Serrano",
  "Buzz Setzer",
  "Philip Seuling",
  "Joseph T Seung",
  "John Severin",
  "Marie Severin",
  "Mark Sexton",
  "Declan Shalvey",
  "Timothy Shamey",
  "Evan 'Doc' Shaner",
  "Kelsey Shannon",
  "Samantha Shannon",
  "Eric Shanower",
  "Lalit Sharma",
  "Liam Sharp",
  "Amy Sharpe",
  "David L.Sharpe",
  "Kevin Sharpe",
  "Anthony Shasteen",
  "Geoffrey T Shaw",
  "Jay Shaw",
  "Scott Shaw",
  "Lucius Shepard",
  "Jeremy Shepherd",
  "Thomas R Sheppard Jr.",
  "Rob Sheridan",
  "Hal Sherman",
  "Howard Sherman",
  "James Sherman",
  "Steve Sherman",
  "Alex Shibao",
  "Keto Shimizu",
  "Yuko Shimizu",
  "Lewis Shiner",
  "Kevin Shinick",
  "Kamome Shirahama",
  "Lore Shoberg",
  "Michael Shoemaker",
  "James Shoop",
  "Jim Shooter",
  "Galen Showman",
  "Michael Shoykhet",
  "Howard Shum",
  "Joe Shuster",
  "Jonathan Sibal",
  "Adrian Sibar",
  "Alejandro D Sicat Jr.",
  "Josh Siegal",
  "Alec Siegel",
  "Jerry Siegel",
  "Bill Sienkiewicz",
  "Dezi Sienty",
  "Dmytro Ezio Sienty",
  "Carl Sifakis",
  "Michael Siglain",
  "John Sikela",
  "Robert Sikoryak",
  "Thony Silas",
  "RB Silva",
  "Joseph Silver",
  "Eric Silvestri",
  "Marc Silvestri",
  "Arthur Simek",
  "Emanuel Simeoni",
  "Alex Simmons",
  "Tom Simmons",
  "Joe Simon",
  "Shaun Simon",
  "Gail Simone",
  "Dave Simons",
  "June Simonson",
  "Louise Simonson",
  "Walter Simonson",
  "Donald Simpson",
  "Howard Simpson",
  "Mark Simpson",
  "William Simpson",
  "Alex Sinclair",
  "James Sinclair",
  "Bryan Singer",
  "David M.Singer",
  "Anjali B.Singh",
  "Paulo Siqueira",
  "Charles Aubrey Sitterson",
  "Steve Skeates",
  "David Louis Skelly",
  "Jennifer Lynn Skelly",
  "Deryl Skelton",
  "Mary Skrenes",
  "Steve Skroce",
  "Ted Slampyak",
  "Barbara Slate",
  "Helen Slater",
  "Dan Slott",
  "Louis Small Jr.",
  "Edwin J.Smalle Jr.",
  "Kyle Smart",
  "Adam Smith",
  "Adrienne Smith",
  "Andy Smith",
  "Beau Smith",
  "Bob Smith",
  "Brett Smith",
  "Bryan Smith",
  "C.P.Smith",
  "Cam Smith",
  "Cory Smith",
  "David Smith",
  "Dietrich Smith",
  "Felipe Smith",
  "Jeff Smith",
  "John Smith",
  "Jonathan Smith",
  "Kevin Smith",
  "Mathew Dow Smith",
  "Matt Smith",
  "Paul Smith",
  "Robert Smith",
  "Robin Smith",
  "Sean Smith",
  "Stephen Scott Smith",
  "Todd Smith",
  "Tom Smith",
  "Timothy Smith III",
  "Snakebite",
  "Milt Snapin",
  "Peter Snejbjerg",
  "Jesse Snider",
  "Sno Cone Studio",
  "Ray Snyder",
  "Robert Snyder",
  "Robin Snyder",
  "Scott Snyder",
  "John K.Snyder, III",
  "Jose Luis Soares Pinto",
  "Ben Sokolowski",
  "Robert Solanovic",
  "Ralph Soll",
  "Kevin Somers",
  "Jon Sommariva",
  "Eugene Son",
  "Wonil Song",
  "Ned Sonntag",
  "Ryan Sook",
  "Jen Sorensen",
  "Gabe Soria",
  "Ivbn Coello Soria",
  "Carlo S.Soriano",
  "Andrea Sorrentino",
  "Erick Sosa",
  "Christopher Sotomayor",
  "Charles Soule",
  "Richard M Southworth",
  "Aaron Sowd",
  "Dexter Soy",
  "Greg Spalenka",
  "Mark Sparacio",
  "Jack Sparling",
  "Nicholas Spencer",
  "Si Spencer",
  "Carrie Spiegle",
  "Dan Spiegle",
  "Frank Spotnitz",
  "Silvio Spotti",
  "Dick Sprang",
  "John Spranger",
  "Frank Springer",
  "Chris Sprouse",
  "Tom Spurgeon",
  "Jess D Spurlock",
  "Morgan Spurlock",
  "Simon Spurrier",
  "Spyda",
  "Claude St.Aubin",
  "Jean St.Jean",
  "Joe St.Pierre",
  "Cat Staggs",
  "John Stanisci",
  "Domonike Stanton",
  "Fiona Staples",
  "Greg Staples",
  "Val Staples",
  "Jim Starlin",
  "Arne Starr",
  "Jason Starr",
  "Leonard Starr",
  "Richard Stasi",
  "John Statema",
  "Joe Staton",
  "Ken Steacy",
  "Peter Steigerwald",
  "Marvin Stein",
  "Brian Stelfreeze",
  "Jay Stephens",
  "Eric Stephenson",
  "Fiona Stephenson",
  "Jim Steranko",
  "Dave Stern",
  "Roger Stern",
  "Leslie Sternbergh",
  "Neal Sternecky",
  "Alec Stevens",
  "Chris Stevens",
  "Mike Stevens",
  "Noelle Stevenson",
  "Cameron Stewart",
  "Dave Stewart",
  "Matthew Stewart",
  "Michelle Stewart",
  "Robert Stewart",
  "Maggie Stiefvater",
  "Brandee Stilwell",
  "John Stokes",
  "Kevin Stokes",
  "Jeff Stokey",
  "Chic Stone",
  "Barron Storey",
  "Gus Storms",
  "Paul Storrie",
  "Karl Story",
  "Rachael Stott",
  "Robert Stotz",
  "William Stout",
  "Carrie Strachan",
  "Leila Strachan",
  "J.Michael Straczynski",
  "Kyle Strahm",
  "Peter Straub",
  "Michael Stribling",
  "Dani Strips",
  "Jan Strnad",
  "Frank Strom",
  "Larry Stroman",
  "Drew Struzan",
  "Lary Stucker",
  "Rob Stull",
  "Lilah Sturges",
  "Matthew Sturges",
  "Mark Stutzman",
  "Michael C.Degracia Suayan",
  "Mico Suayan",
  "Goran Sudzuka",
  "Joseph J.Suitor",
  "Andrew Suriano",
  "Josh Sutton",
  "Tom Sutton",
  "Ward Sutton",
  "Arthur Suydam",
  "Curt Swan",
  "William H Sweeney",
  "Justin Sweet",
  "Brian Swenlin",
  "Duane Swierczynski",
  "Ardian Syaf",
  "Hillary Sycamore",
  "Alex Szewczuk",
  "Andre Szymanowicz",
  "Vaughn Tada",
  "Ed Tadeo",
  "Bronwyn Taggart",
  "Sean Taggart",
  "Tom Taggart",
  "Marcio Takara",
  "Sana Takeda",
  "Gerry Talaoc",
  "Alwyn Talbot",
  "Bryan Talbot",
  "Durwin Talon",
  "Mariko Tamaki",
  "Billy Tan",
  "Philip Tan",
  "Romeo Tanghal",
  "David Tanguay",
  "Adisakdi Tantimedh",
  "Quentin Tarantino",
  "Babs Tarr",
  "Jordi Tarragona",
  "Javier Tartaglia",
  "Genndy Tartakovsky",
  "Ali Tavakoly",
  "Dan Taylor",
  "Dave Taylor",
  "Des Taylor",
  "James Taylor",
  "Matt Taylor",
  "Richard G.Taylor",
  "Richard K.Taylor",
  "Rick Taylor",
  "Tom Taylor",
  "Dylan Teague",
  "Brad Teare",
  "Julian Totino Tedesco",
  "Sidney Teles",
  "Raina Telgemeier",
  "Enis Temizel",
  "Saida Temofonte",
  "Ben Templesmith",
  "Ty Templeton",
  "Kieren T.Templeton - Smith",
  "Temujin",
  "Saverio Tenuta",
  "Frank Teran",
  "Robert Teranishi",
  "Gary Terry",
  "Shiori Teshirogi",
  "Roberta Tewes",
  "Mark Texeira",
  "Greg Theakston",
  "Art Thibert",
  "Mike Thibodeaux",
  "Ariel Thomas",
  "Dann Thomas",
  "Michael John Thomas",
  "Nadine Thomas",
  "Roy Thomas",
  "Richard A Thomas Jr.",
  "Craig Thompson",
  "Greg Thompson",
  "Jill Thompson",
  "Margaret Thompson",
  "Robert Thompson",
  "Stephen Thompson",
  "Daniel Thomsen",
  "Frank Thorne",
  "Michael G.Tidwell",
  "Michael L Tiefenbacher",
  "Frank Tieri",
  "John Tighe",
  "Bruce Timm",
  "Jonathan T Timmons",
  "Stan Timmons",
  "John Timms",
  "Ron Tiner",
  "David Tischman",
  "Tk Tk",
  "Bonnie To",
  "Marcus To",
  "Edward P Tobin",
  "Paul Tobin",
  "Greg Tocchini",
  "Jamie Tolagson",
  "Harvey Tolibao",
  "Tony Tollin",
  "Peter J.Tomasi",
  "Andie Tong",
  "Wai Lum Tong",
  "Cory Toomey",
  "Top Cow Productions",
  "Cruddie L Torian",
  "Elizabeth Torque",
  "Sigmund Torre",
  "Ruben Torreiro",
  "Daniel Torres",
  "J.Torres",
  "Wilfredo Torres",
  "Alex Toth",
  "John Totleben",
  "Lee Townsend",
  "Tim Townsend",
  "Hot Toys",
  "Artyom Trakhanov",
  "Sal Trapani",
  "Eric Trautmann",
  "Karen Traviss",
  "Matthew A Triano",
  "Francesco Trifogli",
  "Carlos Trillo",
  "Jonas Trindade",
  "Kanila Tripp",
  "Andrew James Troy",
  "Tim Truman",
  "Chas Truog",
  "Harry Tshida",
  "William Tucci",
  "James Tucker",
  "Bart Tumey",
  "Marco Turini",
  "Coy Turnbull",
  "Dwayne Turner",
  "Michael Turner",
  "Tiffany Turrill",
  "George Tuska",
  "Rurik Tyler",
  "James T Tynion IV",
  "Udon",
  "Chris Uminga",
  "LaMonte L.Underwood",
  "Le Beau Underwood",
  "Scott Underwood",
  "Angel Unzueta",
  "Joe Uriens",
  "Michelle Urry",
  "Alina Urusov",
  "Michael Uslan",
  "Emilio Utero",
  "Steve Utley",
  "Russell Uttley",
  "Steve Uy",
  "Ram V",
  "Dan Vado",
  "Christine Valada",
  "Peter Vale",
  "Genevieve Valentine",
  "Rosemary Valero - O'Connell",
  "Sherilyn Van Valkenburgh",
  "John Van Fleet",
  "Kevin Van Hook",
  "Frederick Jan Van Lente",
  "Jan Van Meter",
  "Jennifer Van Meter",
  "Ethan Van Sciver",
  "Sherilyn Van Valkenburgh",
  "Brad Vancata",
  "James Vance",
  "Steve Vance",
  "Marguerite Vancook",
  "Jonathan Vankin",
  "Lucas Varela",
  "Various",
  "Lynn Varley",
  "Gloria Vasquez",
  "Brian K.Vaughan",
  "Jeffrey C Vaughn",
  "Sarah Vaughn",
  "Byron Vaughns",
  "Gus Vazquez",
  "Christian Dalla Vecchia",
  "Luciano Vecchio",
  "Eric Vedder",
  "Rick Veitch",
  "Tom Veitch",
  "Francisco Ruiz Velasco",
  "Juan Velasco Hernandez",
  "Ivan Velez, Jr.",
  "Sal Velluto",
  "Robert Venditti",
  "Mark Verheiden",
  "Helen Vesik",
  "Charles Vess",
  "Al Vey",
  "Roberto Viacava",
  "Seth J.Vian",
  "Emma Vieceli",
  "Ray Villafane",
  "Enrique Villagran",
  "Ricardo Villagran",
  "Ramon Villalobos",
  "Jose Villarrubia",
  "Dexter Vines",
  "Magdalene Visaggio",
  "Chris Visions",
  "Igor Vitorino",
  "Alessandro Vitti",
  "Alessandro Vitti",
  "Igor Vittorino",
  "Long Vo",
  "Neil Vokes",
  "Trevor Von Eeden",
  "Wade Von Grawbadger",
  "Cy Voris",
  "Danny Vozzo",
  "Larry Wachowski",
  "Kevin Wada",
  "Brandon T Wagner",
  "Brennan Wagner",
  "Doug Wagner",
  "John Wagner",
  "Karl Wagner",
  "Matt Wagner",
  "Ron Wagner",
  "Douglas S Wagner Jr.",
  "Mark Waid",
  "Katherine Walczak",
  "Alex Wald",
  "Morris Waldinger",
  "Bradley Walker",
  "Chris Walker",
  "Cory Walker",
  "David F.Walker",
  "Kev Walker",
  "Landry Walker",
  "Robert Walker",
  "Russell Walks",
  "Art Wallace",
  "Chip Wallace",
  "Eric Wallace",
  "Adam Wallenta",
  "Reed Waller",
  "Gabriel Hernandez Walta",
  "Jason Waltrip",
  "John Waltrip",
  "Jeff Wamester",
  "Steve Wands",
  "Jen Wang",
  "Tom Warburton",
  "Christian Ward",
  "Allen Warner",
  "Adam Warren",
  "Mike Wartella",
  "Tony Washington",
  "Robert K.Washington III",
  "Robert L Washington III",
  "Felipe Watanabe",
  "Watkins / Loomis Agency Inc.",
  "John Watkiss",
  "Andi Watson",
  "John Watson",
  "Dan Watters",
  "James Waugh",
  "Gerard Way",
  "Michael Way",
  "Mikey Way",
  "Bob Wayne",
  "Matt Wayne",
  "Jonathan Gary Wayshak",
  "Sam Weber",
  "Lee Weeks",
  "Joe Weems",
  "Glynis Wein",
  "Len Wein",
  "Stephen Weiner",
  "Christina Weir",
  "Mort Weisinger",
  "Greg Weisman",
  "Steven Weismann",
  "Alan Weiss",
  "Bobbi J.G.Weiss",
  "David Weiss",
  "John Wells",
  "Claire Wendling",
  "Dooma Wendschuh",
  "Carroll 'Carl' Wessler",
  "Kevin West",
  "Chris Weston",
  "Michael Wetterhahn",
  "John Whalen",
  "Doug Wheatley",
  "Mark Wheatley",
  "Joss Whedon",
  "Zachary Whedon",
  "Ed Wheelan",
  "Shannon Wheeler",
  "Malcolm Wheeler - Nicholson",
  "Daren White",
  "Dean White",
  "Wrath James White",
  "Jeffrey R Whiting",
  "Glenn Whitmore",
  "Ron Whyte",
  "Bob Wiacek",
  "Maris Wicks",
  "Mike Wieringo",
  "Aron Wiesenfeld",
  "Eric Wight",
  "Admira Wijayadi",
  "Doug Wildey",
  "Chris Wildgoose",
  "Christian Wildgoose",
  "WildStorm FX",
  "Allison Wilgus",
  "Andrew Wilkes - Krier",
  "Dave Wilkins",
  "Mo Willems",
  "Aaron Williams",
  "Anthony Williams",
  "Bill Williams",
  "Brittney Williams",
  "David Williams",
  "David Antoine Williams",
  "Keith Williams",
  "Kent Williams",
  "Richard Williams",
  "Rob Williams",
  "Scott Williams",
  "Sean E.Williams",
  "Tad Williams",
  "Freddie E Williams II",
  "Freddie Williams III",
  "J.H.Williams, III",
  "Al Williamson",
  "Joshua Williamson",
  "Bill Willingham",
  "Mary Wilshire",
  "Colin Wilson",
  "Daniel H.Wilson",
  "G.Willow Wilson",
  "Gahan Wilson",
  "Gavin Wilson",
  "Gwendolyn Wilson",
  "Matthew Wilson",
  "Scott Bryan Wilson",
  "Charles Paul Wilson III",
  "Ronald Wimberly",
  "Barry Windsor - Smith",
  "Judd Winick",
  "Anthony Winn",
  "Ryan Winn",
  "Phil Winslade",
  "Jason Wires",
  "David Wise",
  "Stan Woch",
  "Fred Wolfe",
  "Kurt Wolfgang",
  "Marv Wolfman",
  "Michele Wolfman",
  "Amy Wolfram",
  "Woltz International Pictures Corp",
  "Tony Wong",
  "Walden Wong",
  "Ashley Wood",
  "Brian Wood",
  "Chandler Wood",
  "Dave Wood",
  "Tatjana Wood",
  "Wally Wood",
  "Pete Woods",
  "Matthew Woodson",
  "William Woolfolk",
  "John Workman",
  "Rob Worley",
  "Chris Wozniak",
  "Bill Wray",
  "Gregory Wright",
  "Jason Wright",
  "Micah Ian Wright",
  "Sharon Wright",
  "Bernie Wrightson",
  "Annie Wu",
  "Kai Wu",
  "Xermanico",
  "Mathew Yackey",
  "Boaz Yakin",
  "Erez Yakin",
  "Kim Yale",
  "Junwo Yamashita",
  "Ruben Yandoc",
  "Gene Luen Yang",
  "Ira Yarbrough",
  "David Yardin",
  "Tom Yeates",
  "Paul Yellovich",
  "Steve Yeowell",
  "Craig Yeung",
  "Victoria Ying",
  "Steven Yockey",
  "Choong Yoon",
  "Lynne Yoshii",
  "Yoshi Yoshitani",
  "Christopher Yost",
  "Cliff Young",
  "Doselle Young",
  "Chang Ting Yu",
  "Leinil Yu",
  "Tommy Yune",
  "Jason Yungbluth",
  "Constantine N.Zachary",
  "Gerardo Zaffino",
  "Jorge Zaffino",
  "Gord Zajac",
  "Richard Zajac",
  "Pablo Zamboni",
  "Nachmeh Zand",
  "Ander Zarate",
  "Marley Zarcone",
  "Chip Zdarsky",
  "Mike Zeck",
  "Edward Zeno",
  "Danijel Zezelj",
  "Patch Zircher",
  "Patrick Zircher",
  "Tom Ziuko",
  "Tonci Zonjic",
  "Jim Zub",
  "Jim Zub",
  "Michael Zulli",
  "Chrissie Zullo",
  "Christina Anna Zullo",
  "Christina Zuniga",
  "PCS Collectibles",
  "Bilquis Evely",
  "Jeremy Lambert",
  "Ilias Kyriazis",
  "LeomacsMax Fiumara",
  "Christian Duce",
  "Mayo 'SEN' Naito",
  "Rick Leonardi, Ande Parks",
  "Djordje Djokovic",
  "Tony Simione",
  "Brodie Perkins",
  "Joe Certa",
  "Chuck Wojtkiewicz",
  "Ram V.",
  "Dave Alvarez",
  "Leomacs",
  "D'IsraeliJohn Cassaday",
  "Lea H Seidman",
  "J.H.Williams III",
  "Travis G.Moore",
  "Terrance Griep Jr.",
  "Embla Malmenlid",
  "Robert W.Pope",
  "José L.García López",
  "Andy Helfer",
  "Andre Lima Araujo",
  "Nick Pitarra",
  "Brian Keene",
  "Kenny Porter",
  "Dave Wielgosz",
  "Howard Victor Chaykin",
  "Victor Ibanez",
  "W.Haden Blackman III",
  "Mike Norton",
  "LeomacsDan McDaid",
  "Sina Grace",
  "Michael Conrad",
  "Zack Kaplan",
  "N.K.Jemisin",
  "Cian Tormey",
  "Kieran McKeown",
  "Eric Esquivel",
  "Jim Krieg",
  "Karl Mostert",
  "Sumit Kumar",
  "AnekeAnthony Spay",
  "Mat Groom",
  "Gurihiru",
  "Aaron Campbell",
  "James M.Faerber",
  "Michael McKone",
  "KANODoug Mahnke",
  "Ray Winninger",
  "Mike Mayhew",
  "Laura Marks",
  "AnekeInaki Miranda",
  "Jen Van Meter",
  "Stephanie Nicole Phillips",
  "Jay Baruchel",
  "Jordan Clark",
  "Sam Johns",
  "Greg Smallwood",
  "Anthony Spay",
  "ABELPaul Fry",
  "Gleb Melnikov",
  "Jan Duursema",
  "Robbie Thompson",
  "Aaron Campbell",
  "Aaron Conley",
  "Adam McGovern",
  "Adrian Smith",
  "Alec Siegel",
  "Aleš Kot",
  "Alex de Campi",
  "Alex Grecian",
  "Ali Leriger De La Plante",
  "Alison Sampson",
  "Alissa Sallah",
  "Amy Clare",
  "Amy Reeder",
  "Andre R. Frattino",
  "Andrea Sorrentino",
  "Andrew MacLean",
  "Andy Diggle",
  "Anthony Del Col",
  "Antony Johnston",
  "Artyom Trakhanov",
  "Atilio Rojo",
  "Axel Medellin",
  "Babs Tarr",
  "Ben Templesmith",
  "Benjamin Dewey",
  "Benjamin Mackey",
  "Benjamin Marra",
  "Benjamin Roman",
  "Bob Fingerman",
  "Boo Cook",
  "Brandon Graham",
  "Brandon Montclare",
  "Brandon Thomas",
  "Brenden Fletcher",
  "Brian Azzarello",
  "Brian Haberlin",
  "Brian Joines",
  "Brian K. Vaughan",
  "Brian Schirmer",
  "Brian Stelfreeze",
  "Brian Wood",
  "Bryan Hill",
  "Bryan J.L. Glass",
  "Bryan Lee O'Malley",
  "Bryan Valenza",
  "C.P. Smith",
  "Caitlin Kittredge",
  "Caitlin Yarsky",
  "Cameron Stewart",
  "Cat Seaton",
  "Cat Staggs",
  "Chandra Free",
  "Charles Soule",
  "Charlie Adlard",
  "Chelsea Cain",
  "Chip Zdarsky",
  "Chris Brunner",
  "Chris Burnham",
  "Chris Dingess",
  "Chris O’Halloran",
  "Christian Ward",
  "Christopher Mitten",
  "Christopher Sebela",
  "Chuck Brown",
  "Chynna Clugston Flores",
  "Claudia Balboni",
  "Clayton Cowles",
  "Cliff Chiang",
  "Colin Lorimer",
  "Conley Lyons",
  "Corey Lewis",
  "Corinna Bechko",
  "Cory Walker",
  "Cullen Bunn",
  "Curt Pires",
  "D.J. Kirkbride",
  "Dan Panosian",
  "Dan Watters",
  "Dan Wickline",
  "Daniel Corey",
  "Daniel Freedman",
  "Daniel Hillyard",
  "Daniel M. Bensen",
  "Daniel Warren Johnson",
  "Daniel Way",
  "Danijel Žeželj",
  "Danny Luckert",
  "Darcy Van Poelgeest",
  "Darick Robertson",
  "Dave Stewart",
  "Dave Taylor",
  "David F. Walker",
  "David Lapham",
  "Dean Haspiel",
  "Dean Kotz",
  "Dean White",
  "Declan Shalvey",
  "Derek Hunter",
  "Devin Grayson",
  "Donny Cates",
  "Doug Wagner",
  "Duncan Fegredo",
  "Dustin Nguyen",
  "Dustin Weaver",
  "Ed Brisson",
  "Ed Brubaker",
  "Eduardo Ferigato",
  "Edward Laroche",
  "Elise McCall",
  "Elizabeth Breitweiser",
  "Elsa Charretier",
  "Emi Lenox",
  "Emma Ríos",
  "Eric Stephenson",
  "Erica Henderson",
  "Erik Larsen",
  "Ernesto Carbonetti",
  "Fabrice Sapolsky",
  "Farel Dalrymple",
  "Fil Barlow",
  "Fiona Staples",
  "Francesco Mattina",
  "Frank J. Barbiere",
  "Frank Quitely",
  "Gabriel Bá",
  "Gabriel Hardman",
  "Garry Brown",
  "Gary Whitta",
  "Gerry Duggan",
  "Giannis Milonogiannis",
  "Giovanni Timpano",
  "Gisèle Lagacé",
  "Giuseppe Camuncoli",
  "Goran Sudzuka",
  "Grace Ellis",
  "Greg Hinkle",
  "Greg Rucka",
  "Gregg Schigiel",
  "Grim Wilkins",
  "Hayden Sherman",
  "Henry Barajas",
  "Howard Chaykin",
  "Hwei Lim",
  "Ian Bertram",
  "Ian Boothby",
  "Ian Churchill",
  "Ian Macewan",
  "Ibrahim Moustafa",
  "Ig Guara",
  "Ilias Kyriazis",
  "Isaac Goodhart",
  "Ivan Brandon",
  "J. Bone",
  "J. Gonzo",
  "J. Michael Straczynski",
  "Jacob Semahn",
  "Jacopo Camagni",
  "James Robinson",
  "Jamie McKelvie",
  "Jamie S. Rich",
  "Jason A. Hurley",
  "Jason Aaron",
  "Jason Adam Katzenstein",
  "Jason Howard",
  "Jason Latour",
  "Jason McNamara",
  "Jason Shawn Alexander",
  "Jason Young",
  "Jay Faerber",
  "Jay Fotos",
  "JD Faith",
  "Jean-Francois Beaulieu",
  "Jean-Paul Bonjour",
  "Jeff Lemire",
  "Jeff Rougvie",
  "Jen Bartel",
  "Jeremy Haun",
  "Jerome Opeña",
  "Jesse Blaze Snider",
  "Jesse Lee",
  "Jim Mahfood",
  "Jim Rugg",
  "Jim Valentino",
  "Jim Zub",
  "Jimmie Robinson",
  "Jody LeHeup",
  "Joe Casey",
  "Joe Eisma",
  "Joe Harris",
  "Joe Henderson",
  "Joe Kelly",
  "John Arcudi",
  "John Bivens",
  "John J. Hill",
  "John Layman",
  "John McCrea",
  "John Rauch",
  "John Upchurch",
  "Johnnie Christmas",
  "Jonathan Hickman",
  "Jonathan Luna",
  "Jonathan Tsuei",
  "Jordan Boyd",
  "Jordan Gibson",
  "Jordie Bellaire",
  "Jorge Corona",
  "Jose Domingo",
  "José Villarrubia",
  "Joseph Keatinge",
  "Joshua Hixson",
  "Joshua Luna",
  "Joshua Williamson",
  "Juan Gedeon",
  "Justin Greenwood",
  "Justin Jordan",
  "Kagan Mcleod",
  "Kalman Andrasofszky",
  "Karl Kerschl",
  "Karl Kesel",
  "Kate Craig",
  "Kate Niemczyk",
  "Keenan Marshall Keller",
  "Kel Symons",
  "Kelly Sue DeConnick",
  "Kelsey Shannon",
  "Ken Garing",
  "Ken Kristensen",
  "Ken Niimura",
  "Khary Randolph",
  "Kieron Gillen",
  "Kit Seaton",
  "Kris Anka",
  "Kurt Busiek",
  "Kurtis J. Wiebe",
  "Kyle Higgins",
  "Kyle Starks",
  "Kyle Strahm",
  "Landry Q. Walker",
  "Larime Taylor",
  "Lauren Keely",
  "Leandro Fernández",
  "Lee Garbett",
  "Lee Loughridge",
  "Leila Del Duca",
  "Leslie Hung",
  "Lia Miternique",
  "Lisandro Estherren",
  "M.K. Perker",
  "M.SASSY.K",
  "Malachi Ward",
  "Marc Silvestri",
  "Marco B. Bucci",
  "Marcos Martin",
  "Marie Enger",
  "Marissa Louise",
  "Marjorie Liu",
  "Mark Millar",
  "Mark Torres",
  "Martín Morazzo",
  "Mat Groom",
  "Matias Bergara",
  "Matt Fraction",
  "Matt Hawkins",
  "Matt Hollingsworth",
  "Matt Wilson",
  "Matteo Scalera",
  "Matthew Roberts",
  "MD Marie",
  "Meredith Finch",
  "Meredith McClaren",
  "Michael Atiyeh",
  "Michael Avon Oeming",
  "Michael Dowling",
  "Michael Lark",
  "Michael Moreci",
  "Michel Fiffe",
  "Michelle Delecki",
  "Mike Norton",
  "Miles Gunter",
  "Mirka Andolfo",
  "Natasha Tara Petrović",
  "Nate Powell",
  "Nathan Fairbairn",
  "Nathan Fox",
  "Nic Klein",
  "Nicholas Aflleje",
  "Nick Barber",
  "Nick Dragotta",
  "Nick Pitarra",
  "Nick Spencer",
  "Nikos Koutsis",
  "Otis Frampton",
  "Owen Gieni",
  "Paolo Baron",
  "Paul Azaceta",
  "Pernille Ørum",
  "Peter Milligan",
  "Phil Hester",
  "Philip Barrett",
  "Phillip Sevy",
  "Piotr Kowalski",
  "Pornsak Pichetshote",
  "Priscilla Petraites",
  "Rafael Albuquerque",
  "Rafael Scavone",
  "Raffaele Ienco",
  "Ray Fawkes",
  "Remy Boydell",
  "Rich Tommaso",
  "Rick Burchett",
  "Rick Remender",
  "Ro Stein",
  "Rob Guillory",
  "Rob Liefeld",
  "Robert Kirkman",
  "Roberta Ingranata",
  "Roc Upchurch",
  "Rod Reis",
  "Rodin Esquejo",
  "Rodney Barnes",
  "Ronald Wimberly",
  "Rus Wooton",
  "Ryan Browne",
  "Ryan Cady",
  "Ryan Ferrier",
  "Ryan Ottley",
  "Sam Humphries",
  "Sana Takeda",
  "Sanford Greene",
  "Sara Kenney",
  "Sarah DeLaine",
  "Sarah Vaughn",
  "Scott Snyder",
  "Sean Lewis",
  "Sean Mackiewicz",
  "Sean Murphy",
  "Sean Phillips",
  "Sebastian Girner",
  "Serena Guerra",
  "Shae Beagle",
  "Shane Davis",
  "Shane Houghton",
  "Shawn Martinbrough",
  "Simon Roy",
  "Simon Spurrier",
  "Simone Bianchi",
  "Simone Guglielmini",
  "Sina Grace",
  "Skottie Young",
  "Sloane Leong",
  "Sophie Campbell",
  "Stefano Gaudiano",
  "Stéphane Paitreau",
  "Stephanie Hans",
  "Stephen Green",
  "Steve Epting",
  "Steve Niles",
  "Steve Orlando",
  "Steve Skroce",
  "Steven T. Seagle",
  "Stuart Moore",
  "Sylv Taylor",
  "Tamra Bonvillain",
  "Ted Brandt",
  "Ted McKeever",
  "Tee Franklin",
  "Terry Dodson",
  "Tessa Black",
  "Thomas Nachlik",
  "Tim Seeley",
  "Tina Horn",
  "Tini Howard",
  "Todd McFarlane",
  "Tom Grummett",
  "Tom Neely",
  "Tommy Lee Edwards",
  "Tony Moore",
  "Tradd Moore",
  "Tula Lotay",
  "Ulises Farinas",
  "Valentine De Landro",
  "Vanesa R. Del Rey",
  "Veronica Gandini",
  "Victor Santos",
  "W. Maxwell Prince",
  "Warren Ellis",
  "Wes Craig",
  "Whilce Portacio",
  "Wook Jin Clark",
  "Zack Kapla",
  "Dan Abnett",
  "Justin Aclin",
  "Neal Adams",
  "Rafael Albuquerque",
  "Shawn Aldridge",
  "Erika Alexander",
  "Scott Allie",
  "John Allison",
  "Mike Allred",
  "Robert Alter",
  "Yoshitaka Amano",
  "American McGee",
  "Jonathan Ames",
  "Cassie Anderson",
  "Kevin J. Anderson",
  "Kim W. Andersson",
  "Dana Marie Andra",
  "Kaare Andrews",
  "Marc Andreyko",
  "Sergio Aragonés",
  "John Arcudi",
  "Amy Arendts",
  "Arkane Studios",
  "Francesco Artibani",
  "David Atchison",
  "Margaret Atwood",
  "Brian Augustyn",
  "Avalanche Studios",
  "David Avallone",
  "Chris Avellone",
  "Vita Ayala",
  "Gabriel Bá",
  "Peter Bagge",
  "Fernando Baldó",
  "Art Baltazar",
  "Jack Banish",
  "Rick Barba",
  "Frank Barbiere",
  "Frank J. Barbiere",
  "Jeremy Barlow",
  "Mike Baron",
  "Neil Barrett Jr.",
  "Mike Barr",
  "Mike W. Barr",
  "Dan Barry",
  "Scott Beatty",
  "Monte Beauchamp",
  "Corinna Bechko",
  "Emma Beeby",
  "Jordie Bellaire",
  "Brad Bell",
  "Max Bemis",
  "Bend Studio",
  "Scott Benefiel",
  "Aninia Bennett",
  "Marguerite Bennett",
  "Rain Beredo",
  "Peter Bergting",
  "Ian Bertram",
  "Bethesda Softworks",
  "Tazio Bettin",
  "Bruce Bezaire",
  "Tom Bierbaum",
  "BioWare",
  "Bit",
  "Haden Blackman",
  "Christina Blanch",
  "Sergio Bleda",
  "Blizzard",
  "Loren Bouchard",
  "Duffy Boudreau",
  "Anthony Bourdain",
  "Chad Bowers",
  "Staissi Brandt",
  "Dan Braun",
  "Heather Breckel",
  "Peter Brett",
  "Chad Brewster",
  "Aleksi Briclot",
  "Dick Briefer",
  "Patricia Briggs",
  "Ed Brisson",
  "Brian Brooks",
  "Robert Brooks",
  "Wendy Broome",
  "Chuck Brown",
  "Ryan Browne",
  "Troy Brownfield",
  "Garry Brown",
  "Pierce Brown",
  "Brian Buccellato",
  "Harold Buchholz",
  "Cullen Bunn",
  "Riccardo Burchielli",
  "Bob Burden",
  "Erik Burnham",
  "Matt Burns",
  "Kurt Busiek",
  "Jim Butcher",
  "Sarah Byam",
  "John Byrne",
  "Dennis Calero",
  "Laurence Campbell",
  "Juan Díaz Canales",
  "Christopher Cantwell",
  "Joe Caramagna",
  "Christopher Paul Carey",
  "Mike Carey",
  "Fred Carillo",
  "Andrew Carl",
  "Benjamin Carré",
  "Emily Carroll",
  "Michael Carroll",
  "Santiago Caruso",
  "Jonathan Case",
  "Joe Casey",
  "Claudio Castellini",
  "Cecil Castellucci",
  "P. C. Cast",
  "Roberto Castro",
  "Donny Cates",
  "CCP Games",
  "Benito Cereno",
  "Michael Chabon",
  "Jacob Chabot",
  "Christophe Chabouté",
  "Paul Chadwick",
  "Chris Chalenor",
  "Keith Champagne",
  "Nidhi Chanani",
  "Ron Chan",
  "Howard Chaykin",
  "David Chelsea",
  "Yana Chinstova",
  "Frank Cho",
  "Johnnie Christmas",
  "Amy Chu",
  "Michael Chu",
  "Spike Chunsoft",
  "CLAMP",
  "Chris Claremont",
  "Pablo Clark",
  "Whitney Clayton",
  "Brian Clevinger",
  "Ronan Cliquet",
  "Joe Clokey",
  "Becky Cloonan",
  "The Coalition",
  "Nancy Collins",
  "Matthew Colville",
  "Amanda Connor",
  "Richard Corben",
  "Paul Cornell",
  "Andrew Cosby",
  "Nate Cosby",
  "Nathan Cosby",
  "Johnny Craig",
  "Mark Crilley",
  "Tyler Crook",
  "Dave Crosland",
  "I. N. J. Culbard",
  "Dennis Culver",
  "Spencer Cushing",
  "Nicola Cuti",
  "Fernando Dagnino",
  "Kent Dalian",
  "Adam Dalva",
  "Steve Darnell",
  "Geof Darrow",
  "David Dastmalchian",
  "Peter David",
  "Keith Davidsen",
  "Guy Davis",
  "Colin Dawkins",
  "Felicia Day",
  "Kristen Deacon",
  "Kristina Deak-Linsner",
  "Dean DeBlois",
  "Cosmic Debris",
  "Alex de Campi",
  "Kelly Sue DeConnick",
  "Rachel Deering",
  "Nunzio DeFilippis",
  "Victor de la Fuente",
  "Anthony Del Col",
  "Ricardo Delgado",
  "Simone Delladio",
  "Werther Dell'Edera",
  "Guillermo del Toro",
  "J.M. DeMatteis",
  "Shannon Eric Denton",
  "Richard Dent",
  "Cameron DeOrdio",
  "Camilla d'Errico",
  "Michael Dialynas",
  "Karla Diaz",
  "DICE",
  "Andy Diggle",
  "Davide Dileo",
  "Michael Dante DiMartino",
  "D'Israeli",
  "Tony DiTerlizzi",
  "Charles Dixon",
  "Chuck Dixon",
  "Juan Doe",
  "Colleen Doran",
  "Evan Dorkin",
  "Rachel Downing",
  "Jeff Drake",
  "Eric Drooker",
  "Neil Druckmann",
  "Gustavo Duarte",
  "Bill DuBay",
  "William B. DuBay",
  "Gaylord DuBois",
  "Sydney Duncan",
  "Martin Dunelind",
  "Judith Dupre",
  "Jan Duursema",
  "Scott Duvall",
  "Josh Dysart",
  "Joshua Dysart",
  "Wes Dzioba",
  "James Eatock",
  "Sean Edgar",
  "Ian Edginton",
  "Nathan Edmondson",
  "Carlos Eduardo",
  "Tommy Lee Edwards",
  "Ego",
  "Aaron Ehasz",
  "Borkur Eiriksson",
  "Will Eisner",
  "Warren Ellis",
  "Paul Elsy",
  "Christofer Emgård",
  "Josh Emmons",
  "Joshua Scott Emmons",
  "Steve Englehart",
  "Craig Engler",
  "Bruno Enna",
  "Garth Ennis",
  "Justin Erickson",
  "Jane Espenson",
  "Rod Espinosa",
  "Este",
  "Mark Evanier",
  "Alex Evanovich",
  "Al Ewing",
  "Al Fagaly",
  "James Farr",
  "Ray Fawkes",
  "Toni Fejzula",
  "Al Feldstein",
  "Aly Fell",
  "Al Felstein",
  "Don Ferguson",
  "Kevin Ferrara",
  "Alessandro Ferrari",
  "Marcelo Ferreira",
  "Juan Ferreyra",
  "Ryan Ferrier",
  "Joshua Hale Fialkov",
  "Meredith Finch",
  "Bob Fingerman",
  "Sholly Fisch",
  "Ben Fisher",
  "Sebastián Fiumara",
  "Walt Flanagan",
  "Ian Fleming",
  "Ian Flynn",
  "Ron Fortier",
  "Gardner Fox",
  "Francesco Francavilla",
  "Victoria Francés",
  "Douglas Franchin",
  "Omar Francia",
  "Crystal Frasier",
  "Frank Frazetta",
  "Frederator Studios",
  "Alexander Freed",
  "Chris W. Freeman",
  "Erick Freitas",
  "Kosuke Fujishima",
  "Tsukasa Fushimi",
  "Marc Gaffen",
  "Christos Gage",
  "Thierry Gagnon",
  "Neil Gaiman",
  "Bill Gaines",
  "Adam Gallardo",
  "Alex Garner",
  "Rafa Garres",
  "Andy Gavin",
  "Juan Gedeon",
  "Danielle Gelehrter",
  "Ben Gelinas",
  "Damon Gentry",
  "Donato Giancola",
  "Gary Gianni",
  "Vince Giarrano",
  "Dave Gibbons",
  "William Gibson",
  "Owen Gieni",
  "Keith Giffen",
  "Carlos Giffoni",
  "Kieron Gillen",
  "Aaron Gillespie",
  "Henry Gilroy",
  "Moebius, Jean Giraud",
  "Victor Gischler",
  "E. M. Gist",
  "Bryan J. L. Glass",
  "Don Glut",
  "Jonathan Goff",
  "Chris Golden",
  "Christopher Golden",
  "Carlos Gomez",
  "David Gonzales",
  "Cody Goodfellow",
  "David A. Goodman",
  "Archie Goodwin",
  "Julius Gopez",
  "Jason Gorder",
  "Al Gordon",
  "Alan Gordon",
  "Lawrence Gordon",
  "Florent Gorges",
  "Jimmy Gownley",
  "Meredith Gran",
  "Alan Grant",
  "Steven Grant",
  "David Greenwalt",
  "Daryl Gregory",
  "Mike Grell",
  "Andrea Greppi",
  "Eric Grissom",
  "Paul Grist",
  "Jessica Gruner",
  "Juanjo Guarnido",
  "Kristen Gudsnuk",
  "Marc Guggenheim",
  "Jackson Guice",
  "Paul Gulacy",
  "Nicholas Gurewitch",
  "Gabriel Guzmán",
  "Lela Gwenn",
  "Ken Haeser",
  "Michael Hague",
  "Brian Hall",
  "Jason Hall",
  "Larry Hama",
  "Richard Hamilton",
  "Doc Hammer",
  "Bo Hampton",
  "Herik Hanna",
  "Bjarne Hansen",
  "Stephanie Hans",
  "Gabriel Hardman",
  "Charlaine Harris",
  "Joe Harris",
  "Christopher Hastings",
  "Rachel Hastings",
  "Michael Heisler",
  "Eric Heisserer",
  "Andrew Helfer",
  "Steve Hendershot",
  "Alice Henderson",
  "Scott Hepburn",
  "Hermann",
  "Gilbert Hernandez",
  "Phil Hester",
  "Al Hewetson",
  "Faith Erin Hicks",
  "Kyle Higgins",
  "Bryan Hill",
  "Ryan Hill",
  "Kohta Hirano",
  "Amanda Hocking",
  "Joel Hodgson",
  "Peter Hogan",
  "Matthew Hollingsworth",
  "Jeremy Holt",
  "Justin Hook",
  "Dennis Hopeless",
  "Steve Horton",
  "Rik Hoskin",
  "Jody Houser",
  "Larry Houston",
  "Mike Huddleston",
  "Casey Hudson",
  "Mariah Huehner",
  "Adam Hughes",
  "Richard E. Hughes",
  "Sam Humphries",
  "Brian Hurtt",
  "id Software",
  "Sakura Ikeda",
  "Ryoichi Ikegami",
  "Insomniac Games",
  "Irrational Games",
  "Alex Irvine",
  "Mark Irwin",
  "Sean Izaakse",
  "Dan Jackson",
  "John Jacobson",
  "Sandy Jarrell",
  "Mulele Jarvis",
  "Georges Jeanty",
  "Bob Jenney",
  "Jeff Jensen",
  "Van Jensen",
  "Brandon Jerwa",
  "Lizzy John",
  "Geoff Johns",
  "Daniel Warren Johnson",
  "Drew Edward Johnson",
  "Duane Johnson",
  "Mat Johnson",
  "Matthew Johnson",
  "Mike Johnson",
  "Brian Joine",
  "Dan Jolley",
  "Bruce Jones",
  "Jeff Jones",
  "Joëlle Jones",
  "Kelley Jones",
  "Justin Jordan",
  "Robert Jordan",
  "Eric Joyner",
  "Shinji Kajio",
  "J. P. Kalonji",
  "Asuka Katsura",
  "Yushi Kawata",
  "Nick Keller",
  "Zack Keller",
  "Collin Kelly",
  "Walt Kelly",
  "Nic Kelman",
  "Chris Kemp",
  "Mike Kennedy",
  "Ben Kerslake",
  "Barbara Kesel",
  "Karl Kesel",
  "Caitlín R. Kiernan",
  "Matt Kindt",
  "Lovern Kindzierski",
  "Hirotaka Kisaragi",
  "Denis Kitchen",
  "Caitlin Kittredge",
  "Adam Knave",
  "Adam P. Knave",
  "Irene Koh",
  "Kazuo Koike",
  "Goseki Kojima",
  "Scott Kolins",
  "Paul Komoda",
  "Bryan Konietzko",
  "Satoshi Kon",
  "Dean Koontz",
  "Ales Kot",
  "Ranmaru Kotone",
  "Jim Kouf",
  "Andrew Kreisberg",
  "Bernie Krigstein",
  "Ariela Kristantina",
  "J.T. Krul",
  "Sam K",
  "Joe Kubert",
  "James Kuhoric",
  "Harvey Kurtzman",
  "Alisa Kwitney",
  "Terry LaBan",
  "Jason R. Lamb",
  "Dave Land",
  "Roger Langridge",
  "Joe R. Lansdale",
  "Tim Lapetino",
  "David Lapham",
  "Hope Larson",
  "John Layman",
  "Jenn Manley Lee",
  "Tony Lee",
  "Jeff Lemire",
  "Peter Lenkov",
  "Peter M. Lenkov",
  "Rick Leonardi",
  "Kate Leth",
  "Cory Levine",
  "Paul Levitz",
  "Budd Lewis",
  "Dana Lewis",
  "Luke Lieberman",
  "Steve Lieber",
  "Lights",
  "Ryan K Lindsay",
  "Joseph Michael Linsner",
  "David Liss",
  "Jason Little",
  "David Lloyd",
  "Scott Lobdell",
  "Jean-Marc Lofficier",
  "Vasilis Lolos",
  "Aaron Lopresti",
  "Colin Lorimer",
  "Jeremy Love",
  "Matt Mair Lowery",
  "John Lucas",
  "Eric Luke",
  "Tom Luth",
  "Jonathan Maberry",
  "Darko Macan",
  "Andy MacDonald",
  "MachineGames",
  "David Mack",
  "Andrew MacLean",
  "Joseph Maddrey",
  "Michelle Madsen",
  "Matt Maguire",
  "Alex Maleev",
  "Joseph Mallozzi",
  "Tom Mandrake",
  "Andy Mangels",
  "Stuart Manning",
  "Bill Mantlo",
  "Lucas Marangon",
  "Larry Marder",
  "Max Marks",
  "Liz Marsham",
  "Guido Martina",
  "George R. R. Martin",
  "Stefano Martino",
  "Ron Marz",
  "Shirow Masamune",
  "Marc Mason",
  "Felipe Massafera",
  "Adele Matera",
  "Brett Matthews",
  "Todd Matthy",
  "Paul Maybury",
  "Michael May",
  "Danny McBride",
  "Brendan McCarthy",
  "Dan McDaid",
  "Michael McDermott",
  "Brian McDonald",
  "Kel McDonald",
  "Don McGregor",
  "Dave McKean",
  "John McLean-Foreman",
  "Carla Speed McNeil",
  "Tara McPherson",
  "Edwin McRae",
  "Filipe Melo",
  "Javier Mena",
  "Mario Mentasti",
  "Menton3",
  "Marilyn Mercer",
  "Matthew Mercer",
  "Adam Metcalfe",
  "Chris Metzen",
  "Microsoft Studios",
  "Mike Mignola",
  "Shinji Mikami",
  "Al Milgrom",
  "John Jackson Miller",
  "Mark Alan Miller",
  "Jason Millet",
  "Peter Milligan",
  "Mingming",
  "mink",
  "Kentaro Miura",
  "Takeshi Miyazawa",
  "Moebius",
  "Doug Moench",
  "Erik Mona",
  "Sfé R. Monster",
  "Fábio Moon",
  "Alan Moore",
  "Charles Moore",
  "Ed Moore",
  "Leah Moore",
  "Stuart Moore",
  "Michael Moreci",
  "Tom Morello",
  "Edvard Moritz",
  "Bill Morrison",
  "Steve Morris",
  "Dean Motter",
  "Aleksandra Motyka",
  "Ibrahim Moustafa",
  "Lee Moyer",
  "Miroslav Mrva",
  "Sfe M.",
  "John Mueller",
  "Doug Murray",
  "Andrea Mutti",
  "Ted Naifeh",
  "Robert Place Napton",
  "Naughty Dog Studios",
  "Iwan Nazif",
  "Micky Neilson",
  "Arvid Nelson",
  "Mark A. Nelson",
  "Kim Newman",
  "Eric Nguyen",
  "Ethan Nicolle",
  "Malachai Nicolle",
  "Yasuhiro Nightow",
  "Steve Niles",
  "Nintendo",
  "Troy Nixey",
  "Claudio Nizzi",
  "Ann Nocenti",
  "Chris Northrop",
  "Mike Norton",
  "Obsidian Entertainment",
  "Michael Avon Oeming",
  "Waco O'Guin",
  "Nnedi Okorafor",
  "Erik Olofsson",
  "ONE",
  "Sean O'Neill",
  "Steve Orlando",
  "Dean Ormston",
  "Dennis O'Neil (as Sergius O'Shaughnessy)",
  "Mamoru Oshii",
  "John Ostrander",
  "others",
  "Eiji Otsuka",
  "Andy Owens",
  "Agustin Padilla",
  "Greg Pak",
  "Chuck Palahniuk",
  "Rudy Palais",
  "Amanda Palmer",
  "Jimmy Palmiotti",
  "Arnold Pander",
  "Kevin Panetta",
  "Dan Parent",
  "Bill Parente",
  "Andy Park",
  "Ian Parker",
  "Jeff Parker",
  "Tony Parker",
  "Ande Parks",
  "Jim Pascoe",
  "Jason Pearson",
  "Benjamin Percy",
  "Pere Pérez",
  "Tom Peyer",
  "Will Pfeifer",
  "Sean Phillips",
  "Stephanie Phillips",
  "Phoenix Labs",
  "Nate Piekos",
  "Nate Piekos of Blambot",
  "Richard Pini",
  "Wendy Pini",
  "Curt Pires",
  "Ken Pisani",
  "PixelOpus",
  "Warren Pleece",
  "Mike Ploog",
  "Edgar Allan Poe",
  "Pete Poplaski",
  "Cary Porter",
  "Daxton Powell",
  "Eric Powell",
  "Martin Powell",
  "Rich Powers",
  "Rhianna Pratchett",
  "Christopher Priest",
  "Andrea Priorini",
  "Jerry Prosser",
  "Joe Querio",
  "Teresa Radice",
  "Mark Rahner",
  "Mike Raicht",
  "Juan Antonio Ramirez",
  "Ron Randall",
  "Fabian Rangel Jr.",
  "Rare",
  "Raule",
  "Cezar Razekk",
  "Antonia Reed",
  "Brian Reed",
  "Rob Reger",
  "Jesse Reklaw",
  "Rick Remender",
  "Kitty Remington",
  "Gordon Rennie",
  "John Reppion",
  "Patric Reynolds",
  "Mike Richardson",
  "Jamie S. Rich",
  "John Ney Rieber",
  "José Luis Río",
  "Matthew Ritter",
  "Paolo Rivera",
  "David Roach",
  "Chris Roberson",
  "Rafer Roberts",
  "Alan Robinson",
  "Andrew Robinson",
  "Andrew C. Robinson",
  "Andrew R. Robinson",
  "James Robinson",
  "Jerry Robinson",
  "Roger Robinson",
  "Robert Rodi",
  "Ivan Rodriguez",
  "Jason Rodriguez",
  "Maurizio Rosenzweig",
  "Alex Ross",
  "David Rubín",
  "Greg Rucka",
  "Steve Rude",
  "Jim Rugg",
  "Felix Ruiz",
  "Fernando Ruiz",
  "Douglas Rushkoff",
  "Mark Russell",
  "P. Craig Russell",
  "Greg Ruth",
  "Sara Ryan",
  "Ethan Sacks",
  "Stan Sakai",
  "Giorgio Salati",
  "Edgar Salazar",
  "Tim Sale",
  "Robert Sammelin",
  "Hiroaki Samura",
  "Brandon Sanderson",
  "Matt Santoro",
  "Victor Santos",
  "Fabrice Sapolsky",
  "Dave Scheidt",
  "Chris Schlerf",
  "F. Wesley Schneider",
  "Jon Schnepp",
  "Erica Schultz",
  "Mark Schultz",
  "Dan Scott",
  "Kenneth Scott",
  "Mairghread Scott",
  "Bart Sears",
  "Chris Sebela",
  "Christopher Sebela",
  "Steve Seeley",
  "Tim Seeley",
  "Nicolas Daniel Selma",
  "Elliott Serrano",
  "Phillip Sevy",
  "Declan Shalvey",
  "Evan Shamoon",
  "Liam Sharp",
  "Shinjiro",
  "Yoji Shinkawa",
  "Masamune Shirow",
  "James Siciliano",
  "Jerry Siegel",
  "Bill Sienkiewicz",
  "R. Sikoryak",
  "Josh Simmons",
  "Gail Simone",
  "Shaun Simon",
  "Walt Simonson",
  "Aubrey Sitterson",
  "Steve Skeates",
  "Milos Slavkovic",
  "Brian Smith",
  "John Smith",
  "Kevin Smith",
  "Noah Smith",
  "Sherri L. Smith",
  "Jesse Snider",
  "Jesse Blaze Snider",
  "Tom Sniegoski",
  "Kenichi Sonoda",
  "Sony Interactive Entertainment",
  "Charles Soule",
  "JC Spence",
  "Si Spurrier",
  "Nick Stakal",
  "Phil Stanford",
  "Kyle Starks",
  "Ken Steacy",
  "Ben Stenbeck",
  "Jay Stephens",
  "Cameron Stewart",
  "Dave Stewart",
  "James Stokoe",
  "J. Michael Straczynski",
  "Randy Stradley",
  "Sonny Strait",
  "Jan Strnad",
  "Marianna Strychowska",
  "Matthew Sturges",
  "Kyousuke Suga",
  "Charlie Sultan",
  "Linda Sunshine",
  "James Sutter",
  "James L. Sutter",
  "Tom Sutton",
  "Duane Swierczynski",
  "Tom Taggart",
  "TAGRO",
  "Osamu Takahashi",
  "Tony Takezaki",
  "Bryan Talbot",
  "Mary M. Talbot",
  "Mariko Tamaki",
  "Gou Tanabe",
  "Quentin Tarantino",
  "Corey Taylor",
  "Samuel Teer",
  "Osamu Tezuka",
  "Brian Albert Thies",
  "Roy Thomas",
  "Craig Thompson",
  "Jill Thompson",
  "Kelly Thompson",
  "Nick Thornborrow",
  "Frank Tieri",
  "Timothy II",
  "Sybille Titeux",
  "Vivek J. Tiwary",
  "Paul Tobin",
  "Harvey Tolibao",
  "Peter J. Tomasi",
  "Rich Tommaso",
  "Anne Toole",
  "J. Torres",
  "Wilfredo Torres",
  "Alex Toth",
  "Eric Trautmann",
  "Carlos Trillo",
  "Ben Truman",
  "Garon Tsuchiya",
  "Takashi Tsukimi",
  "Kenji Tsuruta",
  "Type-Moon",
  "Gen Urobuchi",
  "Michael Uslan",
  "Andrew Vachss",
  "Carlos Valderrama",
  "Genevieve Valentine",
  "James Vance",
  "Fred Van Lente",
  "Various",
  "Olivier Vatine",
  "Brian K. Vaughan",
  "Francisco Ruiz Velasco",
  "Mark Verheiden",
  "Charles Vess",
  "Emma Vieceli",
  "Guiu Vilanova",
  "Jean Claudio Vinci",
  "Magdalene Visaggio",
  "Buffy Visick",
  "Fausto Vitaliano",
  "Mark Von Der Heide",
  "Dave Wachter",
  "Kevin Wada",
  "Brennan Wagner",
  "John Wagner",
  "Matt Wagner",
  "Mark Waid",
  "David Walker",
  "Landry Q. Walker",
  "Andrew Waller",
  "Mac Walters",
  "Christian Ward",
  "Aron Warner",
  "Chris Warner",
  "Adam Warren",
  "Andi Watson",
  "Derek Watts",
  "Daniel Way",
  "Gerard Way",
  "Chris Weber",
  "Len Wein",
  "Christina Weir",
  "Brett Weldele",
  "Chuck Wendig",
  "David Wenzel",
  "Rich Werner",
  "Carl Wessler",
  "Joss Whedon",
  "Zack Whedon",
  "Shannon Wheeler",
  "Jeremy Whitley",
  "Dave Wilkins",
  "Freddie Williams II",
  "Leah Williams",
  "Chet Williamson",
  "Joshua Williamson",
  "Rob Williams",
  "Bill Willingham",
  "G.Willow Wilson",
  "G. Willow Wilson",
  "Marv Wolfman",
  "Walden Wong",
  "Bob Wood",
  "Brian Wood",
  "Dick Wood",
  "Jennie Wood",
  "Jim Woodring",
  "Bill Woolfolk",
  "Greg Wright",
  "Bernie Wrightson",
  "Johnny Wu",
  "Housui Yamazaki",
  "Gene Luen Yang",
  "Thomas Yeates",
  "Jane Yolen",
  "Bunpei Yorifuji",
  "Akira Yoshida",
  "Ethan Young",
  "Alan Zelenetz",
  "Lin Zhang",
  "Bruce Zick",
  "Tonci Zonjic",
  "David Zub",
  "Jim Zubkavich",
  "Aaron, Jason",
  "Abadzis, Nick",
  "Abbot, Wes",
  "Abel, Jack",
  "Abell, Dusty",
  "Abnett, Dan",
  "Abrams, Paul",
  "Aburtov, Jesus",
  "Acerios, Paul",
  "Acker, Ben",
  "Ackins, Ron",
  "Aclin, Jeff",
  "Aclin, Justin",
  "Acosta, Chris",
  "Acosta, Richie",
  "Acuna, Daniel",
  "Acunzo, Vincenzo",
  "Adams, Arthur",
  "Adams, Greg",
  "Adams, Jon",
  "Adams, Neal",
  "Adiletta, Joseph",
  "Adkins, Dan",
  "Adlard, Charlie",
  "Adsit, Robert",
  "Affe, Lauren",
  "Agostinelli, Joe",
  "Agraphiotis, Liz",
  "Aguilar, Jorge",
  "Aguirre-Sacasa, Roberto",
  "Ahearne, Joe",
  "Ahmed, Saladin",
  "Ahn, Jung-Sik",
  "Aiken, Keith",
  "Aja, David",
  "Akin, Ian",
  "Alama, Rommel",
  "Alanguilan, Gerry",
  "Alary, Pierre",
  "Alascia, Vince",
  "Albelo, Joe",
  "Albelo, Jose",
  "Albers, Diana",
  "Albert, Oclair",
  "Alberti, Mario",
  "Albrecht, Jeff",
  "Albuquerque, Rafael",
  "Alburquerque, Alberto",
  "Alcala, Alfredo",
  "Alcatena, Quique",
  "Alcazar, Vicente",
  "Alden, Paul",
  "Alderman, Jack",
  "Alessio, Agustin",
  "Alexander, Chris",
  "Alexander, Doug",
  "Alexander, Jason",
  "Alexander, Jim",
  "Alexander, Larry",
  "Alexandrov, Steve",
  "Alexovich, Aaron",
  "Algozzino, Sergio",
  "Alixe, Pascal",
  "All Thumbs Creative",
  "Allen, Christopher",
  "Allen, Raul",
  "Allen, Sheldon",
  "Allie, Scott",
  "Allison, Grace",
  "Allison, John",
  "Allison, William",
  "Allo, Chris",
  "Allor, Paul",
  "Allred, Han",
  "Allred, Laura",
  "Allred, Lee",
  "Allred, Michael",
  "Allred, Mike",
  "Almond, Bob",
  "Alonso, Axel",
  "Alphona, Adrian",
  "Alphonso, Ralph",
  "Alpuente, Eduardo",
  "Alquiza, Marlo",
  "Altmann, Scott",
  "Altstaetter, Karl",
  "Alves, Wellington",
  "Alves, Wellinton",
  "Amanat, Sana",
  "Amancio, Aluir",
  "Amano, Yoshitaka",
  "Amaro, Lauren",
  "Amash, Jim",
  "Amici, Davide",
  "Ammerman, David",
  "Anders, Al",
  "Anderson, Bill",
  "Anderson, Brad",
  "Anderson, Brent",
  "Anderson, Chad",
  "Anderson, Craig",
  "Anderson, Harry",
  "Anderson, Kevin",
  "Andolfo, Mirka",
  "Andrade, Filipe",
  "Andrasofszky, Kalman",
  "Andreae, Jean-Baptiste",
  "Andreani, Joe",
  "Andrews, Kaare",
  "Andrews, Thomas",
  "Andreyko, Marc",
  "Andru, Ross",
  "Ang, Joy",
  "ANGE,",
  "Anglo, Mick",
  "Animation, Cream",
  "Anindito, Ario",
  "Anka, Kris",
  "Antonette, Larry",
  "Antonio, Lui",
  "Antos, Heather",
  "Aoki, Tetsuya",
  "Apiradee, Tori",
  "Aragones, Sergio",
  "Araujo, Andre",
  "Araujo, Jadson",
  "Arbona, Alejandro",
  "Archer, Adam",
  "Archibald, Patrick",
  "Arciniega, Erick",
  "Arcudi, John",
  "Arino, Sergio",
  "Arizmendi, Alex",
  "Arlem, Renato",
  "Armenta, Jose",
  "Armstrong, Jason",
  "Armstrong, Mark",
  "Arno",
  "Arrant, Chris",
  "Arreola, Ulises",
  "Artibani, Francesco",
  "Articola, Gally",
  "Artis, Tom",
  "Aruajo, Andre",
  "Asamiya, Kia",
  "Ashe, Ed",
  "Ashford, Richard",
  "Ashmore, Brian",
  "Asmus, James",
  "Asrar, Mahmud",
  "Atiyeh, Michael",
  "Atkins, Robert",
  "Auck, Darren",
  "Auclair, Audra",
  "Aucoin, Derec",
  "Aukerman, Scott",
  "Aulisio, Janet",
  "Austen, Chuck",
  "Austin, Mick",
  "Austin, Terry",
  "Avalon, MK",
  "Avellone, Chris",
  "Avery, Ben",
  "Avery, Fiona",
  "Avison, Al",
  "Avon",
  "Ayala, Vita",
  "Ayers, Dick",
  "Azaceta, Paul",
  "Azzarello, Brian",
  "B.K.",
  "Ba, Gabriel",
  "Babcock, Jon",
  "Babinski, Michael",
  "Bacellar, Julia",
  "Bachalo, Chris",
  "Bachs, Ramon",
  "Badeaux, Brandon",
  "Badger, Mark",
  "Bagge, Peter",
  "Bagley, Mark",
  "Baily, Bernard",
  "Bair, Mike",
  "Baker, Kyle",
  "Baker, Matt",
  "Balak",
  "Balcells",
  "Bald, Ken",
  "Baldeon, David",
  "Baldisimo, Kajo",
  "Baldus, Zachary",
  "Balgobis, Jason",
  "Balkans, Bedlam",
  "Bandini, Michele",
  "Banks, Darryl",
  "Banks, Mannie",
  "Bankson, Russell",
  "Banning, Matt",
  "Banning, Matthew",
  "Barber, John",
  "Barberi, Carlo",
  "Barbiere, Frank",
  "Barbucci, Alessandro",
  "Barkan, Edward",
  "Barker, Gary",
  "Barlow, Jeremy",
  "Barnes, Robert",
  "Barnes, Rodney",
  "Barnes, Samm",
  "Barnes, Sara",
  "Barnett, Charles",
  "Barnett, Charles",
  "Barnett, G.",
  "Barnett, Lee",
  "Barney, Joe",
  "Barnum, Gary",
  "Baron, Mike",
  "Barr, Ken",
  "Barr, Mike",
  "Barranco, Javier",
  "Barras, Dell",
  "Barre, Carrie",
  "Barreiro, Michael",
  "Barreiro, Mike",
  "Barreto, Eduardo",
  "Barrionuevo, Al",
  "Barron, Jonathan",
  "Barros, Ig",
  "Barta, Hilary",
  "Basaldua, Eric",
  "Basaldua, Rick",
  "Baskerville, Stephen",
  "Basner, Naomi",
  "Basso, Mark",
  "Bastianoni, Dante",
  "Bates, Cary",
  "Batista, Adriano",
  "Batista, Chris",
  "Batt",
  "Battle, Eric",
  "Baumann, William",
  "Bazaldua, Oscar",
  "Beacham, Charles",
  "Beachum, Mark",
  "Beadle, Carrie",
  "Beard, James",
  "Beaton, Kate",
  "Beatty",
  "Beatty, John",
  "Beaulieu, Jean-Francois",
  "Beazley, Mark",
  "Beccari, Maria",
  "Bechko, Corinna",
  "Beck, David",
  "Becker, Jeffrey",
  "Becker, Mike",
  "Beckerman, Charlie",
  "Beckett",
  "Becton, Paul",
  "Bedard, Tony",
  "Behling, Steve",
  "Belak-Berger, Charles",
  "Beland, Tom",
  "Beliard, George",
  "Bell, Blake",
  "Bell, Fred",
  "Bell, Nick",
  "Bellaire, Jordie",
  "Bellman, Al",
  "Bellman, Derek",
  "Bemis, Max",
  "Bender, Howard",
  "Bendis, Brian",
  "Benedetto, Adriano",
  "Benefiel, Scott",
  "Benes, Ed",
  "Benes, Mariah",
  "Bengal",
  "Bengal",
  "Benjamin",
  "Benjamin, Paul",
  "Benjamin, Ryan",
  "Benjamin, Troy",
  "Bennato, Tommasso",
  "Bennett, Deron",
  "Bennett, Joe",
  "Bennett, Marguerite",
  "Bennett, Monica",
  "Bennett, Richard",
  "Benson, Mike",
  "Benson, Paul",
  "Benson, Scott",
  "Benulis, Bill",
  "Berardi, Peter",
  "Beredo, Rain",
  "Berg, Dave",
  "Bergara, Matias",
  "Berkenkotter, Dynamite",
  "Berman, Dan",
  "Bermejo, Lee",
  "Bermudez, Raymund",
  "Bernado, Ramon",
  "Bernales, R'john",
  "Bernard, Diego",
  "Bernard, Pierre",
  "Bernardin, Marc",
  "Bernardo, Mark",
  "Bernet",
  "Bernstein, Robert",
  "Berry, D.",
  "Berry, Rick",
  "Bertilorenzi, Michele",
  "Bertozzi, Nick",
  "Bertram, Ian",
  "Betancourt, Jimmy",
  "Beveridge, Bev",
  "Beveridge, Mary",
  "Beyruth, Danilo",
  "Bianchi, Simone",
  "Biederman, Seth",
  "Bierek, Michael",
  "Bigerel, Yves",
  "Biggs, Chris",
  "Bigley, Al",
  "Bilbao, Jake",
  "Billman, Dave",
  "Bin, Benjamin",
  "Binder, Jack",
  "Binder, Otto",
  "Bingham, Jerry",
  "Birch, J.j.",
  "Bisley, Simon",
  "Bissa, Annalise",
  "Bissette, Stephen",
  "BIT",
  "Biukovic, Edvin",
  "Black, Bill",
  "Black, Jake",
  "Blackburn, James",
  "Blacker, Ben",
  "Blackshear, Thomas",
  "Blaine, Patrick",
  "Blaisdell, Tex",
  "Blanchard, Shannon",
  "Blanco, Fernando",
  "Blanco, Rafa",
  "Blank",
  "Blankier, Sarah",
  "Blasco, Jesus",
  "Blase, Andrew",
  "Blaustein, Adam",
  "Bleach, James",
  "Blee, Federico",
  "Blevins, Bret",
  "Bligh, J.",
  "Blond",
  "Blumfield, Harry",
  "Blyberg, Will",
  "Blythe, Guy",
  "Bobbett, Terrell",
  "Bobillo, Juan",
  "Bock, Rob",
  "Bodenheim, Ryan",
  "Bogdanove, Jon",
  "Bogdanove, Judy",
  "Bogdanovic, Viktor",
  "Bola, Edward",
  "Bolinger, Kathryn",
  "Bolland, Brian",
  "Bolle, Frank",
  "Boller, David",
  "Bollers, Karl",
  "Bolling, Bob",
  "Bolson, Cris",
  "Bolton, John",
  "Bond, Phillip",
  "Bondoc, Elmo",
  "Bone, Jay",
  "Bonecchi, Fabio",
  "Bonet, Roger",
  "Bonetti, Elia",
  "Bonvillain, Tamra",
  "Boo, Sweeney",
  "Boose, Nicole",
  "Booth, Brett",
  "Borax, Mark",
  "Borges, Alisson",
  "Borges, Geraldo",
  "Borges, Paul",
  "Borich, Ballard",
  "Boring, Wayne",
  "Borstel, Marc",
  "Borth, Frank",
  "Boschi, Roland",
  "Bosco, John",
  "Bot, Top",
  "Bott, Jimmy",
  "Bouchet, Philippe",
  "Boulard, Hubert",
  "Bourne, Mark",
  "Bowden, Mike",
  "Bowers, Chad",
  "Bowland, Simon",
  "Boyd, Jordan",
  "Boyd, Ron",
  "Brabo, Belardino",
  "Bradford, Aubrey",
  "Bradshaw, Nick",
  "Bradstreet, Tim",
  "Brady, Wayne",
  "Braga, Laura",
  "Bragga, Brannon",
  "Braithwaite, Doug",
  "Branch, Ken",
  "Brandon, Ivan",
  "Brandt, Ted",
  "Brase, Andy",
  "Brasfield, Craig",
  "Braun, Russ",
  "Braverman, June",
  "Breccia, Enrique",
  "Breckel, Heather",
  "Breeding, Brett",
  "Breen, Mortimer",
  "Breitweiser, Elizabeth",
  "Breitweiser, Mitch",
  "Brennan, Tom",
  "Brennert, Alan",
  "Brereton, Dan",
  "Brevoort, Tom",
  "Brewer, David",
  "Breyfogle, Norm",
  "Briclot, Aleksi",
  "Bridges, Petar",
  "Briefer, Dick",
  "Bright, Hollis",
  "Bright, Mark",
  "Brigman, June",
  "Briones, Philippe",
  "Brissenden, Rachelle",
  "Brisson, Ed",
  "Brito, Pier",
  "Britt, Mark",
  "Britto, Pier",
  "Britton, Todd",
  "Brizuela, Dario",
  "Broccardo, Andrea",
  "Brock, James",
  "Broder, Dayna",
  "Broderick, Pat",
  "Brodsky, Allyn",
  "Brodsky, Sol",
  "Brooks, Greg",
  "Brooks, Mark",
  "Brooks, Udon",
  "Broome, Digital",
  "Broome, Mat",
  "Brosseau, Pat",
  "Brothers, Dabel",
  "Brothers, Luna",
  "Broussard, Michael",
  "Broussard, Top",
  "Brown, Ben",
  "Brown, Bob",
  "Brown, Chuck",
  "Brown, Chuck",
  "Brown, Dan",
  "Brown, Eliot",
  "Brown, Eliot",
  "Brown, Elman",
  "Brown, Garry",
  "Brown, James",
  "Brown, Jeffrey",
  "Brown, Kate",
  "Brown, Katherine",
  "Brown, Lawrence",
  "Brown, Patrick",
  "Brown, Reilly",
  "Brown, Robert",
  "Brown, Roland",
  "Brown, Ryan",
  "Brown, Scott",
  "Brown, Sotocolor",
  "Brown, William",
  "Browne, Rembert",
  "Browne, Ryan",
  "Brozowski, Joe",
  "Brubaker, Ed",
  "Brunner, Chris",
  "Brunner, Frank",
  "Brunner, Jan",
  "Brunstad, Sarah",
  "Brusco, Giulia",
  "Brust, Lynaire",
  "Bruzenak, Ken",
  "Bryant, Rick",
  "Buang, Milx",
  "Bucce, Team",
  "Buccellato, Brian",
  "Buccellato, Steve",
  "Buchanan, Heather",
  "Buchemi, Rodney",
  "Buchman, Rebecca",
  "Buchner, Chris",
  "Buckingham, Mark",
  "Buckler, Rich",
  "Buckley, Dan",
  "Budiansky, Bob",
  "Buffagni, Matteo",
  "Buhalis, Lois",
  "Bulanadi, Danilo",
  "Bullock, Dave",
  "Bunkers, Crusty",
  "Bunn, Cullen",
  "Buonfantino, Simone",
  "Buran, Jon",
  "Burcham, Josh",
  "Burchett, Rick",
  "Burchielli, Riccardo",
  "Burden, Bob",
  "Burdine, Roy",
  "Burgos, Carl",
  "Burke, Kevin",
  "Burkett, Cary",
  "Burnett, Dylan",
  "Burnham, Chris",
  "Burns, John",
  "Burrows, Jacen",
  "Bury, Shon",
  "Buscema, Diane",
  "Buscema, John",
  "Buscema, Sal",
  "Buscema, Stephanie",
  "Busch, Robbie",
  "Busiek, Kurt",
  "Bustos, Natacha",
  "Butler, Jeff",
  "Butler, Nancy",
  "Butler, Steve",
  "Butler, Steven",
  "Butters, Tara",
  "Buylla, Adolfo",
  "Buzz",
  "Byrd, Ronald",
  "Byrne, John",
  "Byrne, Stephen",
  "Byron, Arthur",
  "C.I., Daewon",
  "Cabal, Juan",
  "Cabrera, Carlos",
  "Cabrera, Ralph",
  "Cabrol, Sebastian",
  "Cadenhead, MacKenzie",
  "Cady, Ryan",
  "Cafu",
  "Cafu, C",
  "Cage, Nicolas",
  "Cagno, Joe",
  "Cahill, Brendan",
  "Cain, Chelsea",
  "Cal, Alex",
  "Calafiore, James",
  "Calafiore, Jim",
  "Caldicott, Helen",
  "Caldwell, Ben",
  "Caldwell, Talent",
  "Calero, Dennis",
  "Calimee, John",
  "Callahan, Jim",
  "Calle, Cecilia",
  "Calligr, Virtual",
  "Calligraphy, Virtual",
  "Camagni, Jacopo",
  "Cameron",
  "Cameron, Don",
  "Cameron, Lou",
  "Cammuncoli, Guisseppe",
  "Camp, Bob",
  "Camp, Matt",
  "Campanella, Rob",
  "Campbell, Eddie",
  "Campbell, J.",
  "Campbell, Jamal",
  "Campbell, Jeff",
  "Campbell, Jim",
  "Campbell, Laurence",
  "Campbell, T",
  "Campion, Pascal",
  "Campos, Marc",
  "Campus, Roberto",
  "Camuncoli, Giuseppe",
  "Candelario, Harry",
  "Candido, Mel",
  "Candilance, Harry",
  "Canepa, Barbara",
  "Canete, Eric",
  "Cannon, Eric",
  "Cannon, Max",
  "Cansino, Bing",
  "Cantwell, Christopher",
  "Canwell, Bruce",
  "Capaldi, Mario",
  "Capullo, Greg",
  "Caracuzzo, Giancarlo",
  "Caragonne, George",
  "Caramagna, Joe",
  "Caramagna, Vc",
  "Carani, W.C.",
  "Card, Orson",
  "Cardillo, Brent",
  "Cardinal Rae",
  "Cardy, Nick",
  "Carey, Mike",
  "Carey, Vic",
  "Cariello, Sergio",
  "Carlin, Mike",
  "Carlo, Dan",
  "Carlos, Ze",
  "Carlson, Aleret",
  "Carlson, Chris",
  "Carlucci, Gaetano",
  "Carmen, Ronaldo",
  "Carmen, Ronnie",
  "Carnero, Carmen",
  "Carosella, Rob",
  "Carr, Larry",
  "Carr, Steve",
  "Carrabotta, Vic",
  "Carrasco Jr., Dario",
  "Carreno, Al",
  "Carreno, Oscar",
  "Carrillo, Sebastian",
  "Carrion, Enrique",
  "Carter, Madison",
  "Cartier, Eric",
  "Cartoonist, Neurotic",
  "Casagrande, Elena",
  "Casali, Matteo",
  "Case, Richard",
  "Caselli, Stefano",
  "Casey, Elliott",
  "Casey, Joe",
  "Casey, Todd",
  "Cassaday, John",
  "Cassara, Joshua",
  "Cassell, Robert",
  "Castellani, Leonel",
  "Castellini, Claudio",
  "Castiello, Marco",
  "Castrillo, Anthony",
  "Castro, Roberto",
  "Catacchio, Onofrio",
  "Cates, Donny",
  "Catron, Mike",
  "Cavalieri, Joey",
  "Cavallaro, Mike",
  "Cazeneuve, Arthur",
  "Cebollero, John",
  "Cebulski, C.B.",
  "Cenac, Wyatt",
  "Cerasi, Chris",
  "Cerasini, Marc",
  "Cerilli, John",
  "Certa, Joe",
  "Cha, Keu",
  "Chabot, Jacob",
  "Chad Bowers & Chris Sims",
  "Chadwick, Paul",
  "Chamberlain, Kody",
  "Chameleon, Digital",
  "Champagne, Keith",
  "Chan, Afu",
  "Chan, Ernie",
  "Chan, Jason",
  "Chan, Pierre-Mony",
  "Chan, Ron",
  "Chan, W.",
  "Chang, Bernard",
  "Chaplin, Martin",
  "Chapman, Clay",
  "Chapman, Clay",
  "Chapman, Hank",
  "Chappell, Russ",
  "Charalampidis, Jim",
  "Charest, Travis",
  "Chariton, Dan",
  "Charlamagne tha God",
  "Charm, Derek",
  "Charpentier, Peter",
  "Charretier, Elsa",
  "Chase, Bobbie",
  "Chater, Mack",
  "Chatham, Michael",
  "Chaykin, Howard",
  "Checchetto, Marco",
  "Check, Sid",
  "Cheeps, Orphans",
  "Chella, Andrea",
  "Chen, Helen",
  "Chen, Jo",
  "Chen, Sean",
  "Chen, Shelly",
  "Chen, Xiao",
  "Cheol, Woo",
  "Cherniss, Matt",
  "Cheung, Jim",
  "Chiang, Cliff",
  "Chiang, Clio",
  "Chiang, Janice",
  "Chiaramonte, Frank",
  "Chiarello, Mark",
  "Chichester, Dan",
  "Chin, Joyce",
  "Ching, Brian",
  "Chiodo, Joe",
  "Chlystek, Dave",
  "Cho, Frank",
  "Cho, Hee Ye",
  "Cho, Michael",
  "Choi, Brandon",
  "Choi, Jung",
  "Choi, Mary",
  "Choi, Mike",
  "Choi, Woo Bin",
  "Choo, Gary",
  "Chrioproces, Tina",
  "ChrisCross",
  "Christian, Mike",
  "Christiansen, Jeff",
  "Christopher, John",
  "Christopher, Tom",
  "Chu, Amy",
  "Chu, Tom",
  "Chua, Chris",
  "Chuckry, Chris",
  "Chung, Jimmy",
  "Chung, June",
  "Church, Ryan",
  "Churchill, Ian",
  "Churilla, Brian",
  "Ciardo, Barbara",
  "Cieslinski, Piotr",
  "Cifuentes, Vicente",
  "Cinar, Yildiray",
  "Ciregia, Francesca",
  "Cirocco, Frank",
  "Cisic, Enis",
  "Claremont, Chris",
  "Clark, Jim",
  "Clark, Joseph",
  "Clark, LeAnn",
  "Clark, Manny",
  "Clark, Margaret",
  "Clark, Matthew",
  "Clark, Richard",
  "Clark, Scott",
  "Clarke, Andy",
  "Clement, Edgar",
  "Clevinger, Brian",
  "Cliquet, Ronan",
  "Cloonan, Becky",
  "Club, Pittsburgh",
  "Clugston-Major, Chynna",
  "Coates, Andre",
  "Coates, Ta-​Nehisi",
  "Cockrum, Dave",
  "Cockrum, Paty",
  "Coelho, Andres",
  "Coelho, Jorge",
  "Coello, Iban",
  "Cohen, Ivan",
  "Cohen, Janice",
  "Cohen, Tom",
  "Coipel, Olivier",
  "Coker, Tomm",
  "Col, Anthony",
  "Colak, Mirko",
  "Colan, Gene",
  "Coleby, Simon",
  "Coleite, Aron",
  "Coleman, Ruairi",
  "Collazo, Hector",
  "COLLECTIBLES, METROPOLIS",
  "Colletta, Vince",
  "Collins, Max",
  "Collins, Michael",
  "Collins, Mike",
  "Collins, Sean",
  "Colon, Ernie",
  "color, American",
  "Color, Extreme",
  "Color, Liquid!",
  "Coloring, Tang",
  "Colors, John",
  "Comic, But",
  "Comicraft",
  "Comics, Harley",
  "Comics, Metropolis",
  "Comics, Mile",
  "Comics, Quality",
  "Comics, Terry's",
  "Comics, Udon",
  "Company, Udon",
  "Compton, John",
  "Condoy",
  "Conley, Aaron",
  "Conner, Amanda",
  "Connor, Amanda",
  "Conrad, Kevin",
  "Conrad, Michael",
  "Conrad, Tim",
  "Conrad, Will",
  "Conroy, Mike",
  "Conway, Carla",
  "Conway, David",
  "Conway, Gerry",
  "Cook, Boo",
  "Cook, Katie",
  "Cook, Monte",
  "Cook, Nicole",
  "Cook, Steven",
  "Cooke, Darwyn",
  "Cooke, Jon",
  "Cooper, Chris",
  "Cooper, Dave",
  "Cooper, Herb",
  "Cooper, James",
  "Cooper, Joe",
  "Cooper, Park",
  "Cooper, Sam",
  "Coover, Colleen",
  "Copland, Jason",
  "Corben, Richard",
  "Cordeiro, James",
  "Cordova, Isaac",
  "Corin, Joshua",
  "Cornell, Paul",
  "Corona, Adelso",
  "Corroney, Joe",
  "Corvese, Kelly",
  "Cosby, Nate",
  "Costa, Jon",
  "Costa, Mike",
  "Costanza, John",
  "Cotilletta, Anthony",
  "Couceiro, Damian",
  "Coupersmartt, Jonathan",
  "cover, Photo",
  "Covington, Aaron",
  "Cowan, Denys",
  "Cowles, Vc",
  "Cowsill, Alan",
  "Cox, Greg",
  "Cox, Jeromy",
  "Coye, Dwight",
  "Crabapple, Molly",
  "Crabtree, Bill",
  "Craig, Jason",
  "Craig, Jim",
  "Craig, Johnny",
  "Craig, Wes",
  "Crain, Barry",
  "Crain, Clayton",
  "Crain, Clayton",
  "Crain, Dale",
  "Craine, Nick",
  "Crandall, Reed",
  "Crane, Roy",
  "Crawford, Saleem",
  "Crayon, Electric",
  "Creative, All",
  "Crespi, Dan",
  "Crespi, Sue",
  "Crespo, Steve",
  "Cresta, Marika",
  "Crety, Stephane",
  "Crimelab, Danny",
  "Crisostomo, Dennis",
  "CRISSE",
  "Cristobal, Esdras",
  "Crook, Tyler",
  "Crosby, Brian",
  "Cross, Chris",
  "Cross, Sarah",
  "Crossley, Andrew",
  "Cruz, Eufrenio",
  "Cruz, Juan",
  "Cruz, Roger",
  "Crystal, Shawn",
  "Cucca, Vincenzo",
  "Cuddy, Dan",
  "Cuevas, Carlos",
  "Cuevas, Carlos",
  "Cullen, David",
  "Cullins, Paris",
  "Culver, Dennis",
  "Cummings, Ray",
  "Cummings, Steven",
  "Cunniffe, Darragh",
  "Cunningham, Kevin",
  "Curiel, David",
  "Currie, Andrew",
  "Curtis, Arthur",
  "Curtis, Gavin",
  "Cypress, Toby",
  "Czop, John",
  "D'ALFONSO, Marco",
  "D'AMICO, Elisabetta",
  "D'anda, Carlos",
  "D'ARCY, Jack",
  "D'ARMATA, Frank",
  "D'AURIA, Fabio",
  "d'Orazio, Valerie",
  "Dagostino, Jon",
  "Dahlman, Steve",
  "Dakesian, Nanci",
  "Dakin, Glenn",
  "Daley, Don",
  "Dalhouse, Andrew",
  "Dalit, Wendell",
  "Dallocchio, Federico",
  "Dalrymple, Farel",
  "Daly, James",
  "Damaggio, Rodolfo",
  "Daniel, Ferran",
  "Daniel, Nelson",
  "Daniel, Tim",
  "Daniel, Tony",
  "Daniels, Anthony",
  "DArcy, Jack",
  "Darrow, Geoff",
  "Dauterman, Russell",
  "David, Erica",
  "David, Peter",
  "Davidson, Pat",
  "Davidson, Paul",
  "Davis, Alan",
  "Davis, Guy",
  "Davis, Jack",
  "Davis, Jim",
  "Davis, Malcolm",
  "Davis, Robert",
  "Davis, Shane",
  "Dawson, Delilah S.",
  "Day, D.",
  "Day, Dan",
  "Day, David",
  "Day, Gene",
  "Dazo, Bong",
  "Dazzler, Photo",
  "De Andrade, Filipe",
  "De Longis, Matteo",
  "DeCaire, Gerald",
  "Decaire, Jerry",
  "Decarlo, Dan",
  "Decarlo, Mike",
  "Decastro, Nelson",
  "DeCesare, Angelo",
  "Deconnick, Kelly",
  "Deering, Marc",
  "Defalco, Tom",
  "Defelippo, Chris",
  "Defilippis, Nunzio",
  "DeFilippis, Weir",
  "Deinman, Reads",
  "Dekal, Jeff",
  "Dekraker, Adam",
  "Del Col, Anthony",
  "Del Duca, Leila",
  "Del'orto, Suzanne",
  "Delandro, Valentine",
  "Delano, Jamie",
  "DeLaRosa, Sam",
  "Delatorre, Roberto",
  "DeLay, Harold",
  "Delbo, Jose",
  "Delecki, Michelle",
  "Delgado, Edgar",
  "Delgado, Ferran",
  "Dell'Edera, Werther",
  "Dell'otto, Gabriele",
  "Dell, John",
  "DellOrto, Suzanne",
  "DelPennino, Mario",
  "Delperdang, Jesse",
  "Delsante, Vito",
  "DeMatteis, J.",
  "Demeo, Mike",
  "Demong, Todd",
  "Demoura, Klebs",
  "Demulder, Kim",
  "DeNatale, Bob",
  "Denatale, Robert",
  "Denham, Brian",
  "Dennings, Kat",
  "Dennis Hallum",
  "Dennis, John",
  "Denson, Abby",
  "Denson, Abigail",
  "Denton, Shannon",
  "Deodato, Mike",
  "Deodato, Mike",
  "Derek",
  "Derenick, Tom",
  "Derington, Nicholas",
  "Derington, Nick",
  "Dery, Pierre-​Andre",
  "Deschesne, Albert",
  "Design, Ew",
  "Design, Hi-​Fi",
  "Desmond, Steve",
  "Devall, Ryan",
  "Devarajan, Sharad",
  "Deville, Ellie",
  "Devries, David",
  "Dewey, Benjamin",
  "Dezago, Todd",
  "Dezuniga, Tony",
  "Di Filippo, Paul",
  "Dial, Anthony",
  "Dias, Rafael",
  "Diaz, Paco",
  "Diaz, Ruben",
  "Dichiara, Marcelo",
  "Dickey, Chris",
  "Dickey, Eric",
  "Dickey, Joe",
  "Dietz, Skip",
  "Diggle, Andy",
  "DiGiandomenico, Carmine",
  "Digital, Transparency",
  "Dillon, Steve",
  "Dimagmaliw, Ben",
  "Dini, Paul",
  "Dipreta, Tony",
  "DiSalvo, Rob",
  "Ditko, Steve",
  "Divito, Andrea",
  "Dixon, Chuck",
  "Djurdjevic, Jelena",
  "Djurdjevic, Marko",
  "Dodd, Joseph",
  "Dodson, Rachel",
  "Dodson, Terry",
  "Doe, Juan",
  "Doescher, Ian",
  "Dogan, Omar",
  "Dojo, Tom",
  "Dokes, John",
  "Dols, Guillermo",
  "Domingo, Jose",
  "Domingues, Horacio",
  "Dominguez, Luis",
  "Dominike Domo Stanton",
  "Donovan, Derec",
  "Doran, Colleen",
  "Dorian, Guy",
  "Dorkin, Evan",
  "Dorman, Dave",
  "Dose, Alberto",
  "Doughrty, Karin",
  "Douglas, Max",
  "Douglas, Steve",
  "Dowd, Vic",
  "Dowdeswell, Nicki",
  "Dowling, Michael",
  "Downs, Bob",
  "Doxsee, Richard",
  "Doyle, Ming",
  "Doyle, Rebecca",
  "Dragotta, Nick",
  "Drake, Arnold",
  "Drake, Stan",
  "Drew",
  "Dringenberg, Mike",
  "Drucker, Mort",
  "Druker, Michael",
  "Duarte, Georges",
  "Duarte, Gustavo",
  "Duca, Leila",
  "Duce, Christian",
  "Duffie, Sean",
  "Duffin, Mike",
  "Duffy, Jo",
  "Duffy, John",
  "Duffy, Mary",
  "Duggan, Gerry",
  "Duhig, Lee",
  "Duke, Patrick",
  "Dukeshire, Ed",
  "Dumas, Alexandre",
  "Dunbar, Max",
  "Dunkerley, Frank",
  "Dunlavey, Ryan",
  "Dunn, Ben",
  "Duplicate, John",
  "Duranona, Leo",
  "Duric, Sandra",
  "Durruthy, Armando",
  "Dusk, Ciane",
  "Dutro, Steve",
  "Dutter, Barry",
  "Duursema, Jan",
  "Dwyer, Kieron",
  "Dwyer, Norm",
  "Dzioba, Wes",
  "Dzon, Tim",
  "Dzuban, Kevin",
  "E.M. Gist",
  "Eadeh, Al",
  "Eaglesham, Dale",
  "Eaton, Scot",
  "Eaton, Scot",
  "Eckleberry, Jeff",
  "Ecklund, Pam",
  "Edelman, Daryl",
  "Edelman, Scott",
  "Edginton, Ian",
  "Edington, Dan",
  "Edkin, Joe",
  "Edmondson, Nathan",
  "Edwards, Melissa",
  "Edwards, Neil",
  "Edwards, Tommy",
  "Eeden, Trevor",
  "Efx, Guru",
  "Egeland, Martin",
  "Egeland, Marty",
  "Eklund, Pam",
  "Eklund, Pamela",
  "Elias, Lee",
  "Eliopoulos, Vc",
  "Elliott, Randy",
  "Ellis, Rich",
  "Ellis, Richard",
  "Ellis, Steve",
  "Ellis, Warren",
  "Ellison, Harlan",
  "Elmer, Scott",
  "Elson, Richard",
  "Eltaeb, Gabe",
  "Emberlin, Randy",
  "Engelhard, Eric",
  "Englehart, Steve",
  "Enigma",
  "Ennis, Garth",
  "Ennis, Jon",
  "Ensign, Jordi",
  "Epting, Steve",
  "Erez, Guy",
  "Erskine, Gary",
  "Espin, Salva",
  "Espin, Salvador",
  "Espinosa, Frank",
  "Esposito, Mike",
  "Esquejo, Rodin",
  "Estes, John",
  "Esther Sanz",
  "Eury, Michael",
  "Evanier, Mark",
  "Evans, Alan",
  "Evans, George",
  "Evans, Khari",
  "Evans, Rex",
  "Evans, Vince",
  "Evans, Vincent",
  "Everett, Bill",
  "Everett, Carl",
  "Ewing, Al",
  "Ewing, Eve",
  "Eyring, Nathan",
  "F Flaviano",
  "F, Studio",
  "Fabbri, Davidé",
  "Fabela, Antonio",
  "Faber, Rich",
  "Fabry, Glenn",
  "Faerber, Jay",
  "Faerber, Logan",
  "Fagaly, Al",
  "Fagan, Kirbi",
  "Fagiani, Rita",
  "Fago, Vince",
  "Failla, Marco",
  "Fairbairn, Nathan",
  "Fajardo, Romulo",
  "Fama, Christopher",
  "Familton, Herb",
  "Fan, Chris",
  "Faraci, Tito",
  "Farago, Andrew",
  "Farinas, Ulises",
  "Farmer, Mark",
  "Farrell, Triona",
  "Fass, Myron",
  "Faucher, Wayne",
  "Faulkner, Steven",
  "Faust, Christa",
  "Favreau, Jon",
  "Fawkes, Ray",
  "Fazekas, Michele",
  "Federici, Ricardo",
  "Fedunieiwicz, Ken",
  "Fee, Rob",
  "Fegredo, Duncan",
  "Fein, Eric",
  "Feist, Raymond",
  "Feister, Tom",
  "Felber, Adam",
  "Felche, Norman",
  "Felder, James",
  "Feldman, Jerry",
  "Feldstein, Al",
  "Felix, Phil",
  "Felix, Phil",
  "Fell, Elitta",
  "Fenix, Estudio",
  "Ferguson, Lee",
  "Ferguson, Matt",
  "Ferguson, Peter",
  "Fern, Jim",
  "Fernanda Rizo",
  "Fernandez, Bruno",
  "Fernández, Iván",
  "Fernandez, Javier",
  "Fernandez, Jean",
  "Fernandez, Juan",
  "Fernandez, Leandro",
  "Fernandez, Leo",
  "Fernandez, Noberto",
  "Fernandez, Raul",
  "Ferreira, Carlos",
  "Ferreira, Marcelo",
  "Ferreyra, Juan",
  "Ferrier, Ryan",
  "Ferriter, Julianna",
  "Ferry, Pasqual",
  "Ferstadt, Louis",
  "Fialkov, Joshua",
  "Fialkov, Joshua",
  "Fichera, Mike",
  "Field, Tom",
  "Fields, Gary",
  "Fiffe, Michel",
  "Figueroa, John",
  "Filardi, Nick",
  "Finch, David",
  "Finch, Meredith",
  "Fine, Joshua",
  "Fine, Tom",
  "Finer, Anders",
  "Finger, Bill",
  "Fingeroth, Danny",
  "Fiorentino, Fabrizio",
  "Fiorito, Marcio",
  "Firchow, Steve",
  "Firmansyah, Alti",
  "Firmansyah, Alti",
  "Firmin, Beth",
  "Fisch, Sholly",
  "Fish, Lost",
  "Fish, Tim",
  "Fish, Veronica",
  "Fisher, Derek",
  "Fisher, Seth",
  "Fite, Linda",
  "Fiumara, Max",
  "Fiumara, Sebastian",
  "Fiveash, Chance",
  "Flames, James",
  "Flamini, Anthony",
  "Flanagan, Bill",
  "Flaviano",
  "Flaxman, Sue",
  "Fleisher, Michael",
  "Fleishman, Hy",
  "Fleming, Robert",
  "Fleming, Tom",
  "Fletcher, Henry",
  "Fletcher, Jared",
  "Flint, Henry",
  "Florea, Sandu",
  "Florean, Ariana",
  "Florimonte, Thomas",
  "Floyd, John",
  "Focarile, Teresa",
  "Font, Sharpe",
  "Fonteriz, Jose",
  "Fonteriz, Rafael",
  "Fonts, Raul",
  "Forbeck, Matt",
  "Forbes, Jake",
  "Forbes, W.",
  "Forbes, William",
  "Forces, Dynamic",
  "Ford, Tana",
  "Foreman, Travel",
  "Forgione, Bob",
  "Fornes, Jorge",
  "Forte, John",
  "Fortier, Ron",
  "Fosco, Frank",
  "Fosgitt, Jay",
  "Foss, Langdon",
  "Fowler, Tom",
  "Fox, Gardner",
  "Fox, Gardner",
  "Fox, Greg",
  "Fox, Matt",
  "Fox, Nathan",
  "Fox, Todd",
  "Foxx, Todd",
  "Foye, Paula",
  "Fraction, Matt",
  "Fradon, Ramona",
  "Fraga, Dan",
  "Francavilla, Francesco",
  "Franchin, Douglas",
  "Francia, Omar",
  "Francisco, Eduardo",
  "Franco, Pete",
  "Frank, Gary",
  "Frank, Matthew",
  "Fredericks, Fred",
  "Fredericks, Larry",
  "Freed, Alexander",
  "Freedman, Daniel",
  "Freeman, Adam",
  "Freeman, Beck",
  "Freeman, George",
  "Freeman, Jeremy",
  "Freeman, Joe",
  "Frenz, Randall",
  "Frenz, Ron",
  "Frezzato, Max",
  "Fridolfs, Derek",
  "Friedman, Mike",
  "Friedrich, Gary",
  "Friedrich, Mike",
  "Friend, Richard",
  "Friends, Imaginary",
  "Frigeri, Juan",
  "Fris, Emil",
  "Frison, Jenny",
  "Fross, Kitty",
  "Frost, John",
  "Frusin, Marcello",
  "Frusin, Marcelo",
  "Fry, Gez",
  "Fry, James",
  "Fu, Warren",
  "Fuchs, Kolja",
  "Fujita, Artur",
  "Fujitani, Bob",
  "Fukami, Yuko",
  "Fuller",
  "Furman, Simon",
  "Furness, Ed",
  "Furth, Robin",
  "Fuso, Antonio",
  "FX, Wildstorm",
  "Fyles, Mike",
  "G Geoffo",
  "G, Ripoll",
  "Gabel, Zac",
  "Gabrie, Justin",
  "Gabriele, Al",
  "Gaffney, Suzanne",
  "Gafford, Bob",
  "Gafford, Carl",
  "Gage, Chris",
  "Gage, Christos",
  "Gage, Ruth",
  "Gagnon, Michael",
  "Gaiman, Neil",
  "Galan, Manny",
  "Galarza, Angel",
  "Gale, Bob",
  "Galindo, Ted",
  "Gallagher, Michael",
  "Gallaher, David",
  "Gallant, Shannon",
  "Gallardo, Adam",
  "Galloway, Sean",
  "Galvan, Bill",
  "Gammill, Kerry",
  "Gan, Steve",
  "Gandini, Vero",
  "Gantz",
  "Ganucheau, Paulina",
  "Garbett, Lee",
  "Garbowska, Agnes",
  "Garbowska, Agnieszka",
  "Garcia, Antonio",
  "Garcia, Chris",
  "Garcia, David",
  "Garcia, German",
  "Garcia, Jordi",
  "Garcia, Kevin",
  "Garcia, Manuel",
  "Garcia, Mike",
  "Garcia, Rey",
  "Gardner, Jaye",
  "Garland, Michael",
  "Garn, Roy",
  "Garner, Alex",
  "Garney, Ron",
  "Garrahy, Pat",
  "Garres, Rafa",
  "Garrity, Shaenon",
  "Garron, Javier",
  "Garvey, Brian",
  "Gary, Mick",
  "Garza, Ale",
  "Garza, Alejandro",
  "Garzon, Carlos",
  "Gaschet, Romain",
  "Gascoine, Phil",
  "Gaston, Pierfrancesco",
  "Gaudiano, Stefano",
  "Gaudioso, Joe",
  "Gaushell, John",
  "Gaushell, Starkings",
  "Gay, Roxane",
  "Gaydos, Michael",
  "GCW",
  "Geary, Rick",
  "Gedeon, Juan",
  "Geiger, Steve",
  "Geldhof, Jay",
  "Genet, Fred",
  "Genolet, Andres",
  "Genovese, Joe",
  "Gentile, Randy",
  "Gentile, Vc",
  "Geoffo",
  "Georgiou, Bambos",
  "Geraci, Andrew",
  "Geraci, Drew",
  "Gerads, Mitch",
  "Gerads, Mitchell",
  "Gerber, Steve",
  "Gerberg, Mort",
  "Getty, Mike",
  "Geyer, Jason",
  "Gho, Sunny",
  "Gho, Top",
  "Giacoia, Frank",
  "Giandomenico, Carmine",
  "Gianfelice, Davide",
  "Giangiordano, Valerio",
  "Giarrano, Vince",
  "Giarrusso, Chris",
  "Gibbons, Dave",
  "Gibson, Dave",
  "Gibson, Ian",
  "Gibson, Jordan",
  "Giddings, Noelle",
  "Giella, Joe",
  "Giffen, Keith",
  "Gifford, James",
  "Gikow, Louise",
  "Gil, Armando",
  "Gilbert, Michael",
  "Gill, Ray",
  "Gill, Robert",
  "Gillen, Kieron",
  "Gillis, Peter",
  "Gillis, Peter",
  "Gilmore, Linda",
  "Gilroy, Henry",
  "Gimenez, Juan",
  "Gimple, Scott",
  "Giordano, Dick",
  "Giorello, Tomas",
  "Giorgilli, Cristina",
  "Giovannetti, Nick",
  "Giraud, Jean",
  "Girner, Sebastian",
  "Gischler, Victor",
  "Gist, E.M.",
  "Giunta, John",
  "Glapion, Jonathan",
  "Glass, Adam",
  "Glass, Bryan",
  "Glass, Wil",
  "Gleason, Patrick",
  "Glut, Don",
  "Glut, Donald",
  "Gnazzo, Nick",
  "Go, Harry",
  "Godin, Jason",
  "Going, Gina",
  "Gold, Mimi",
  "Goldberg, Paula",
  "Goldberg, Petra",
  "Goldberg, Stan",
  "Golden, Christopher",
  "Golden, Michael",
  "Goldfarb, Ed",
  "Goleash, Grant",
  "Goletz, Andrew",
  "Golotoff, Bruce",
  "Gomes, Alex",
  "Gomez, Carlos",
  "Gomez, Hector",
  "Gomez, Jimmy",
  "Gongora, Oscar",
  "Gongora, Richard",
  "Gonzalez, Jorge",
  "Goodbrey, Daniel",
  "Goodhue, Heidi",
  "Goodine, Kelly",
  "Goodwin, Archie",
  "Googe, Neil",
  "Gorb, JohnMarc",
  "Gorder, Jason",
  "Gorder, Top",
  "Gordon, Al",
  "Gorham, Adam",
  "Goring, Trevor",
  "Gorman, Zac",
  "Gosier, Phil",
  "Gossett, Chris",
  "Goulart, Ron",
  "Govar, Daniel",
  "Grace, Sina",
  "Gracia, Marte",
  "Graeme, Lynn",
  "Graham, Billy",
  "Graham, William",
  "Grahame-Smith, Seth",
  "Grainger, Sam",
  "Grampa, Rafael",
  "Grandenetti, Jerry",
  "Granov, Adi",
  "Grant, Alan",
  "Grant, Keron",
  "Grant, Linda",
  "Grant, Steven",
  "Graphics, Pacific",
  "Graphix, Color",
  "Grate, Kore",
  "Grawbadger, Wade",
  "Gray, Justin",
  "Gray, Mick",
  "Gray, Scott",
  "Grayson, Devin",
  "Green, Dan",
  "Green, Jonathon",
  "Green, Randall",
  "Green, Randy",
  "Green, Richard",
  "Green, Timothy",
  "Greenberg, Glenn",
  "Greenberger, Bob",
  "Greene, Alfonso",
  "Greene, Brett",
  "Greene, Johnny",
  "Greene, Sanford",
  "Greene, Sid",
  "Greenwood, Warren",
  "Greer, Paty",
  "Gregg, Clark",
  "Gregory, Doug",
  "Gregory, L.P.",
  "Grell, Mike",
  "Grevioux, Kevin",
  "Griffiths, Martin",
  "Grillo-Marxuach, Javier",
  "Grindberg, Eva",
  "Grindberg, Tom",
  "Grist, Paul",
  "Groneman, Thomas",
  "Gross, Daerick",
  "Gross, Darrick",
  "Gross, Peter",
  "Grothkopf, Chad",
  "Group, Gotham",
  "Grudko, Sy",
  "Gruenwald, Mark",
  "Gruenwald, Michael",
  "Grummett, Tom",
  "Grundetjern, Espen",
  "Grunwald, Jennifer",
  "Guara, Ig",
  "Guedes, Renato",
  "Guerra, Pia",
  "Guerrero, Javier",
  "Guggenheim, Marc",
  "Gugliotta, Gianluca",
  "Guice, Butch",
  "Guice, Jackson",
  "Guichet, Yvel",
  "Guillory, Rob",
  "Guimaraes, Ricardo",
  "Guinaldo, Andres",
  "Guinan, Paul",
  "Guinebaud, Sylvain",
  "Gulacy, Paul",
  "Gully, Mario",
  "Gumport, Mary",
  "Gunnell, Micah",
  "Gurewitch, Nicholas",
  "Gurihiru, G",
  "Guru-eFX",
  "GUSTAVO",
  "Gustavson, Paul",
  "Gustovich, Mike",
  "Gutierrez, Dave",
  "Gutierrez, Manuel",
  "Gutwirth, Maurice",
  "Guzman, Gabriel",
  "Ha, Gene",
  "Ha, Robin",
  "Haberlin, Avalon",
  "Haberlin, Brian",
  "Hader, Bill",
  "Hagan, Brian",
  "Hagan, Merrill",
  "Hahn, David",
  "Hain-Cole, Alexandra",
  "Haining, H",
  "Hairsine, Trevor",
  "Hajeski, Nancy",
  "Hale, Phil",
  "Haley, Matt",
  "Hall, Bob",
  "Hall, Jim",
  "Hallis, Howard",
  "Halsted, Ted",
  "Hama, Larry",
  "Hamilton, Craig",
  "Hamilton, Tim",
  "Hamm, Jesse",
  "Hamme, Jean",
  "Hammock, Lee",
  "Hamner, Cully",
  "Hampton, Bo",
  "Hampton, Scott",
  "Hamscher, Cory",
  "Handoko, Rahmat",
  "Hands, Diverse",
  "Hands, Many",
  "Hang, Impacto",
  "Hang, Impacto",
  "Hanna, Greg",
  "Hanna, Scott",
  "Hanna, Siobhan",
  "Hannigan, Ed",
  "Hannin, Avalon",
  "Hannin, Ian",
  "Hans, Stephanie",
  "Hansen, Mike",
  "Hansen, Neil",
  "Hanuka, Tomer",
  "Harbin, Dustin",
  "Hardin, Chad",
  "Hardman, Gabriel",
  "Hardy, Sean",
  "Harkins, Tim",
  "Harmon, Jim",
  "Harms, William",
  "Harold, Jess",
  "Harper, Fred",
  "Harr, Will",
  "Harras, Bob",
  "Harren, James",
  "Harrington, Christina",
  "Harris, Bob",
  "Harris, Jack",
  "Harris, Jack",
  "Harris, Joe",
  "Harris, Joseph",
  "Harris, Marian",
  "Harris, Marlan",
  "Harris, Mike",
  "Harris, Steve",
  "Harris, Steven",
  "Harris, Tony",
  "Harrison, Lou",
  "Harrold, Jess",
  "Hart, Ernie",
  "Hartle, Gary",
  "Hartley, Al",
  "Harvey, Yona",
  "Hasen, Irwin",
  "Haspiel, Dean",
  "Hastings, Christopher",
  "Haun, Jeremy",
  "Hawbaker, Clarke",
  "Hawkins, Lysa",
  "Hawthorne, Mike",
  "Haynes, Fred",
  "Haynes, Hugh",
  "Haynes, Myisha",
  "Haynes, Rob",
  "Hazlewood, Doug",
  "HDR, Daniel",
  "Heath, Russ",
  "Heath, Sophie",
  "Hebert, John",
  "Heck, Don",
  "Heebink, John",
  "Height, Ray-​Anthony",
  "Heike, Mark",
  "Hein, David",
  "Heinberg, Allan",
  "Heinl, Elaine",
  "Heisler, Elaine",
  "Heisler, Michael",
  "Heisler, Phil",
  "Hembeck, Fred",
  "Hempel, Marc",
  "Henderson, Chris",
  "Henderson, Erica",
  "Henderson, Garry",
  "Henderson, Jason",
  "Henderson, Mike",
  "Henkel",
  "Hennessy, Andrew",
  "Henrichon, Niko",
  "Henrique, Marcio",
  "Henry, Clayton",
  "Henry, Flint",
  "Henry, Melville",
  "Henson, Carly",
  "Hepburn, Scott",
  "Herb Trimpe",
  "Herdling, Glenn",
  "Herman, Lennie",
  "Herman, Lenny",
  "Herms, William",
  "Hernandez, Gilbert",
  "Hernandez, Jaime",
  "Hernandez, Lea",
  "Hernandez, Vince",
  "Hernandez, Vincent",
  "Herrera, Ben",
  "Herrera, Francisco",
  "Herring, Ian",
  "Herrling, Glenn",
  "Herron, Ed",
  "Hescox, Richard",
  "Hess, Derek",
  "Hester, Phil",
  "Hetrick, Meghan",
  "Hickman, Jonathan",
  "Hickman, Troy",
  "Hicks",
  "Hicks, Faith",
  "Hicks, Matt",
  "HiFi Colour",
  "Higbee, Paul",
  "Higgins, John",
  "Higgins, Kyle",
  "Higgins, Michael",
  "Higgins, Rosy",
  "Higgins, Ul",
  "Higon, Jose",
  "Hildebrandt, Greg",
  "Hildebrandt, Tim",
  "Hill, Bryan",
  "Hill, Joe",
  "Hill, Ryan",
  "Hillsman, Don",
  "Hindzierski, Jessica",
  "Hine, David",
  "Hing, Chu",
  "Hipp, Daniel",
  "Hipp, Jean",
  "Hiromoto, Shin-Ichi",
  "Hitch, Bryan",
  "Hixson, John",
  "Ho, Don",
  "Hoberg, Rick",
  "Hodge, Paul",
  "Hodgkins, James",
  "Holden, Anthony",
  "Holder, Jose",
  "Holdredge, Jon",
  "Holland, Jesse",
  "Hollingsworth, Matt",
  "Holloway, Ray",
  "Hollowell, Morry",
  "Holman, Justin",
  "Holmdale, Red",
  "Holredge, John",
  "Homer",
  "Homs",
  "Hondru, Ovi",
  "Hood, Joshua",
  "Hoover, Dave",
  "Hoover, Richard",
  "Hope, Sandra",
  "Hopgood, Kevin",
  "Horak, Matt",
  "Horie, Rich",
  "Horie, Tanya",
  "Horn, Greg",
  "Horn, Kevin",
  "Hornschemeier, Paul",
  "Hornung, David",
  "Horowitz, Michael",
  "Horton, Brian",
  "Horwitz, Michael",
  "Hosek, Dan",
  "Hoskin, Michael",
  "Hoston, Jim",
  "Hotz, Kyle",
  "Hou, Andrew",
  "Houlihan, Ray",
  "Houser, Jody",
  "Hoveke, Steven",
  "Howard, Tini",
  "Howard, Zach",
  "Howell, Richard",
  "Huat, Tan",
  "Hubbell, Carl",
  "Hubbs, Troy",
  "Hubell, Carl",
  "Huckabee, Mary",
  "Huddleston, Mike",
  "Hudlin, Reginald",
  "Hudnall, James",
  "Hudson, Donald",
  "Hudson, Simon",
  "Hues, Malibu",
  "Hues, Violent",
  "Huet, Jeffrey",
  "Hughes, Adam",
  "Hughes, Floyd",
  "Hughes, Rian",
  "Hugonnard-​Bert, Julien",
  "Huizenga, Kevin",
  "Humphries, Sam",
  "Hunt, Andrea",
  "Hunt, Chad",
  "Hunt, Dave",
  "Hunt, Judith",
  "Hunter, Robert",
  "Huntington, Alexandria",
  "Huntley, H.e.",
  "Hurd, Damon",
  "Hurtt, Brian",
  "Hurwitz, Gregg",
  "Huston, Charlie",
  "Iacono, Mattia",
  "Ibanez, Victor",
  "Idelson, Matt",
  "Ienco, Raffaele",
  "Igle, Jamal",
  "Iglehart, James",
  "II, Don",
  "II, Nelson",
  "II, Timothy",
  "Im, Allen",
  "Immonen, Kathryn",
  "Immonen, Stuart",
  "Impacto",
  "Imperato, Carmine",
  "Inactive, Clifton",
  "Inc., Andyman",
  "Inc., Aspen",
  "Inc., First",
  "Infante, Antonio",
  "Infantino, Carmine",
  "Infurnari, Joe",
  "Inlight, Dynamite",
  "Innuksuk, Nyla",
  "Inshield, David",
  "INT'L, ART",
  "Iribarne, Alejandro",
  "Iro, Peter",
  "Irvine, Alex",
  "Irvine, Alexander",
  "Irving, Chris",
  "Irving, Frazer",
  "Irwin, Mark",
  "Isaacs, Rebekah",
  "Isabel, Andres",
  "Isabella, Tony",
  "Isanove, Richard",
  "Isherwood, Geof",
  "Ishimura, Vitor",
  "Isidro, Oscar",
  "Israeli, D'",
  "Iulis, Mattia",
  "Ivie, Larry",
  "Ivy, Chris",
  "Iwasaki, Emiko",
  "Izaakse, Sean",
  "Izzo, Jean",
  "J",
  "J.D. Smith",
  "J.L. Giles",
  "J.M. DeMatteis",
  "Jaaska, Bill",
  "Jacinto, Aaron",
  "Jacinto, Kim",
  "Jackson, Dan",
  "Jackson, Janet",
  "Jacobsen, Allan",
  "Jacobson, Sid",
  "Jadson, Jack",
  "Jaeger, Alex",
  "James, Debra",
  "James, Howard",
  "James, Jimmy",
  "Jameson, Roy",
  "Janes, Jimmy",
  "Janke, Dennis",
  "Janson, Klaus",
  "Jarrell, Sandy",
  "Jarvinen, Kirk",
  "Jasse, Hugh",
  "Javins, Marie",
  "Jay Anacleto",
  "Jay, Alex",
  "Jay, Bill",
  "Jaye, Bernie",
  "Jean, James",
  "Jean-Francious Beaulieu",
  "Jeanty, Georges",
  "Jefferson, Allan",
  "Jemas, Bill",
  "Jemas, Watson",
  "Jen Bartel",
  "Jenkins, Paul",
  "Jensen, Daron",
  "Jensen, Dennis",
  "Jensen, Jeff",
  "Jensen, Rob",
  "Jesse Blaze Snider",
  "Jetter, C.",
  "Jetter, Carl",
  "Jetter, Charlotte",
  "Jew, Benton",
  "Jg",
  "Jillette, Penn",
  "Jimenez, Martin",
  "Jimenez, Oscar",
  "Jimenez, Phil",
  "Jiminez, Jorge",
  "Jock",
  "Jock, J",
  "Johns, Geoff",
  "Johnson, Brad",
  "Johnson, Daniel",
  "Johnson, Daniel",
  "Johnson, Dave",
  "Johnson, Drew",
  "Johnson, Glen",
  "Johnson, Jacob",
  "Johnson, Jeff",
  "Johnson, Paul",
  "Johnson, Peter",
  "Johnson, Phillip K.",
  "Johnson, R.",
  "Johnson, Sheila",
  "Johnson, Staz",
  "Johnson, Stewart",
  "Johnson, William",
  "Johnsons",
  "Johnston, Aaron",
  "Johnston, Antony",
  "Jolley, Dan",
  "Jones, Arvell",
  "Jones, Arvell",
  "Jones, Bruce",
  "Jones, Casey",
  "Jones, Chris",
  "Jones, Christopher",
  "Jones, Gerard",
  "Jones, J.",
  "Jones, Jeff",
  "Jones, Jeffrey",
  "Jones, Joelle",
  "Jones, Julianna",
  "Jones, Kelley",
  "Jones, Kelly",
  "Jones, Louise",
  "Jones, Malcolm",
  "Jones, Malcolm",
  "Jones, R.",
  "Jones, R.",
  "Jones, Reggie",
  "Jones, Robert",
  "Jones, Russ",
  "Jones, Stephen",
  "Jordan, Justin",
  "Jorgensen, Arnie",
  "Jose, Ruy",
  "Jose, San",
  "Jowett, Simon",
  "Joyce, Brad",
  "Jubran, Alexander",
  "Jung, Minkyu",
  "Jungkuntz, Will",
  "Junior, Klebs",
  "Jurgens, Dan",
  "Jusko, Joe",
  "Justice, Kris",
  "Justiniano, Josue",
  "Kaalberg, Barb",
  "Kalan, Elliott",
  "Kalinowski, Jerry",
  "Kalisz, John",
  "Kaluta, Michael",
  "Kaluta, Michael",
  "Kaminski, Len",
  "Kamome Shirahama",
  "Kampe, Rosi",
  "Kane, Gil",
  "Kane, Nathan",
  "Kang, Jeevan",
  "Kang, Lou",
  "Kanigher, Robert",
  "Kaniuga, Trent",
  "Kano",
  "Kano",
  "Kanterovich, Mike",
  "Kaotic, A.",
  "Kapitan, George",
  "Karounos, Paris",
  "Karp, Steve",
  "Kato, Sam",
  "Katz, Jack",
  "Kaufman, Joe",
  "Kavanagh, Terry",
  "Kawecki, Annette",
  "Kayanan, Rafael",
  "Kaye, Annette",
  "Kaye, Stan",
  "Keatinge, Joseph",
  "Keene, Brian",
  "Kei Zama",
  "Keith, Jason",
  "Kelleher, Michael",
  "Keller, Jack",
  "Kelly, Fiona",
  "Kelly, Joe",
  "Kelly, Ken",
  "Kelly, Ryan",
  "Kelly, Tony",
  "Kemp, Avalon",
  "Kemp, Avalon",
  "Kemp, Dan",
  "Kemp, Dave",
  "Kennedy, Cam",
  "Kennedy, Phillip",
  "Kenny, Mike",
  "Keown, Dale",
  "Kerschl, Karl",
  "Kesel, Karl",
  "Kesinger, Brian",
  "Ketcham, Rick",
  "Ketchum, Daniel",
  "Kevic Djurdjevic, Jelena",
  "Kf",
  "Khazem, Daniel",
  "Kholinne, Jessica",
  "Khoury, George",
  "Kibblesmith, Daniel",
  "Kida, Fred",
  "Kieryluk, Igor",
  "Kieth, Sam",
  "Kilislian, Nick",
  "Killam, Taran",
  "Killinger, Scott",
  "Kim, Chuck",
  "Kim, Jung Gi",
  "Kim, Rock-​He",
  "Kim, Rock-He",
  "Kim, Rock-He",
  "Kim, Sanho",
  "Kim, Steve",
  "Kinderski, Jessi",
  "Kindlon, Patrick",
  "Kindt, Matt",
  "Kindzierski, Jessica",
  "Kindzierski, Lovern",
  "King, Bill",
  "King, Jeff",
  "King, Tom",
  "Kinnaird, Ryan",
  "Kipiniak, Chris",
  "Kirby, Jack",
  "Kirby, Jj",
  "Kirby, JJ",
  "Kirby, Juvaun",
  "Kirby, Lisa",
  "Kirby, Udonj.j.",
  "Kirch, Peter",
  "Kirk, Leonard",
  "Kirkham, Tyler",
  "Kirkman, Robert",
  "Kish, Karin",
  "Kitson, Barry",
  "Kitson, George",
  "Klaczak, Ken",
  "Kleid, Neil",
  "Klein, David",
  "Klein, George",
  "Klein, Nic",
  "Klein, Todd",
  "Knauf, Charles",
  "Knauf, Daniel",
  "KNIGHT AGENCY, INC.",
  "Knights, Marvel",
  "Kniivila, Irma",
  "Knisley, Lucy",
  "Knowlan, Kevin",
  "Knudtsen, Ken",
  "Kobasic, Kevin",
  "Kobayashi, Kei",
  "Koblish, Scott",
  "Koch, Aud",
  "Koch, Julia",
  "Kochalka, James",
  "Kocher, Nicholas",
  "Kocher, Nick",
  "Koda, Eric",
  "Koh, Irene",
  "Koi Carreon",
  "Koike, Kazuo",
  "Kolins, Scott",
  "Komarck, Michael",
  "Komiyama, Yusaku",
  "Kong, Brian",
  "Kordey, Igor",
  "Korok, N.",
  "Koslowski, Rich",
  "Kot, Ales",
  "Koutsis, Niko",
  "Koutsis, Nikos",
  "Kowalski, Piotr",
  "Kraft, David",
  "Kraiger, Lysa",
  "Kraiger, Mike",
  "Krause, Peter",
  "Kremer, Warren",
  "Krigstein, Bernie",
  "Kristantina, Ariela",
  "Krol, Loretta",
  "Kronenberg, Laurie",
  "Kronenberg, Michael",
  "Kruchkow, Seth",
  "Krueger, Dynamite",
  "Krueger, Jim",
  "Kruhm, Cindy",
  "Krul, J.t.",
  "Kryssing, Ray",
  "Kryssing, Ray",
  "Kubert, Adam",
  "Kubert, Andy",
  "Kubert, Joe",
  "Kubert, Katie",
  "Kubina, Monica",
  "Kuder, Aaron",
  "Kudo, Toshiki",
  "Kudranski, Szymon",
  "Kuhn, Andy",
  "Kumar, Lalit",
  "Kunkel, Bill",
  "Kuper, Peter",
  "Kupperberg, Alan",
  "Kupperberg, Paul",
  "Kupperman, Michael",
  "Kuramoto, John",
  "Kuramoto, Morrie",
  "Kurth, Steve",
  "Kurtz, Scott",
  "Kurtzman, Harvey",
  "Kurzrok, Al",
  "Kwapisz, Gary",
  "Kweskin, Sam",
  "Kwon, Annette",
  "Kwon, Top",
  "Kyle, Craig",
  "KylLs",
  "Labat, Yancey",
  "Lackey, Mike",
  "Lacombe, Michel",
  "Ladronn, Jose",
  "Lafferty, Jeff",
  "Lafuente, David",
  "Lagace, Gisele",
  "Laguna, Fabio",
  "Lai, Ben",
  "Lai, Rai",
  "Laiso, Emilio",
  "Lam, Jon",
  "Lam, Nghia",
  "Laming, Marc",
  "Lamm, Spencer",
  "Lamy, Kelly",
  "Land, Dave",
  "Land, Greg",
  "Landini, Stefano",
  "Landro, Valentine",
  "Landy, Derek",
  "Langley, Clint",
  "Langridge, Roger",
  "Lanham, Travis",
  "Lanham, Vc",
  "Lanning, Andy",
  "Lanphear, Dave",
  "Lanphear, Dave",
  "Lansdale, Joe",
  "Lapham, David",
  "Lapham, Maria",
  "Lapointe, Serge",
  "Lark, Michael",
  "Larkin, Bob",
  "Larocque, Greg",
  "Larocque, Gregory",
  "Larosa, Bud",
  "Larosa, Lewis",
  "Larraz, Pepe",
  "Larraz, Pepe",
  "Larroca, Salvador",
  "Larsen, Erik",
  "Larsen, Sven",
  "Lashley, Ken",
  "Laspina, Lance",
  "LATORRE, DIEGO",
  "Latour, David",
  "Latour, Jason",
  "Lau, Artgerm",
  "Lau, Kevin",
  "Laughlin, Craig",
  "Laughlin, Ian",
  "Lauretta, Paul",
  "Lavorgna, Bria",
  "Law, Shane",
  "Lawlis, Dan",
  "Lawrence, G.",
  "Lawrence, Mort",
  "Laws, Robin",
  "Laws, Robin",
  "Lay, Carol",
  "Layman, John",
  "Layton, Bob",
  "Lazarelli",
  "Lazarus",
  "Lazarus, Harry",
  "Lazarus, Leon",
  "Lazellari, Ed",
  "Lazer, Molly",
  "Leach, Garry",
  "Leamy, Cathy",
  "Lear, Alex",
  "Leav, Mort",
  "Leavitt, John",
  "Lebron, Erick",
  "Ledger, Peter",
  "Lee, Alvin",
  "Lee, Andy",
  "LEE, CARL",
  "Lee, Ed",
  "Lee, Elaine",
  "Lee, Eric",
  "Lee, Fonda",
  "LEE, IN YOUNG",
  "Lee, In-​Hyuk",
  "Lee, In​Hyuk",
  "Lee, Irene",
  "Lee, Jae",
  "LEE, JAE SUNG",
  "LEE, JAE WOONG",
  "Lee, Janet",
  "Lee, Jee-Hyung",
  "Lee, Jeehyung",
  "Lee, Jennifer",
  "Lee, Jim",
  "Lee, Jonathan",
  "LEE, MIN JU",
  "Lee, Norman",
  "Lee, Pat",
  "Lee, Paul",
  "Lee, Raymond",
  "Lee, Ryan",
  "Lee, Sang",
  "Lee, Stacey",
  "Lee, Stacey",
  "Lee, Stan",
  "Lee, Sunny",
  "Lee, Tony",
  "Lee, Woo",
  "Leferman, Shelly",
  "Legge, Steven",
  "Leheup, Jody",
  "Leialoha, Steve",
  "Leib, Michael",
  "Leiber, Steve",
  "Leigh, Rob",
  "Leisten, Jay",
  "Lemire, Jeff",
  "Lemos, Joao",
  "Lenox, Emi",
  "Lenshoek, Ariane",
  "Lent, Michael",
  "Lente, Fred",
  "Lente, Frederick",
  "Lentz, Bill",
  "Leon, David",
  "Leon, John",
  "Leon, Nico",
  "Leon, Nico",
  "Leonardi, Rick",
  "Lerner, Emily",
  "Lessmann, Linda",
  "Leth, Kate",
  "Lethcoe, Jason",
  "Lethem, Jonathan",
  "Level, Brian",
  "Levesque, Jason",
  "Levin, Kate",
  "Levine, Jason",
  "Levins, Richard",
  "Levins, Rik",
  "Levins, Tim",
  "Levy, Scott",
  "Lewandowski, John",
  "Lewis, Brett",
  "Lewis, Chad",
  "Lewis, Corey",
  "Lewis, Edward",
  "Lewis, Jason",
  "Libranda, Kevin",
  "Lichtner, Christian",
  "LICHTNER, JOE",
  "Liebe, Timothy",
  "Lieber, Larry",
  "Lieber, Steve",
  "Liebig, Jason",
  "Liederman, Al",
  "Liefeld, Rob",
  "Liefeld., Rob",
  "Liencooper, Barb",
  "Liew, Sonny",
  "Lightle, Marianne",
  "Lightle, Steve",
  "Likenesses, Panels",
  "Lilly, Mike",
  "Lim, Gang",
  "Lim, Ron",
  "Lima, Luciano",
  "Limcaco, Madonna",
  "Lindelof, Damon",
  "Lindsay, Jahnoy",
  "Lindsay, Jeff",
  "Linsner, Joseph",
  "Lipka, Mark",
  "Lira, Luis",
  "Lis, Andrew",
  "Liss, David",
  "listed, none",
  "Liu, Marjorie",
  "Liu, Sam",
  "Livesay, John",
  "Llamas, Victor",
  "LLC, Virgin",
  "Lloyd, David",
  "Lobdell, Scott",
  "Loeb, Audrey",
  "Loeb, Jeph",
  "Lofficier, Jean-Marc",
  "Lofficier, Jean-Marc",
  "Lofficier, Randy",
  "Lokus, Rex",
  "Lolli, Matteo",
  "Lolos, Vasilis",
  "Lomax, Don",
  "Lombardia",
  "Lombo, Mike",
  "London, Rob",
  "Long, Christopher",
  "Lopes, Mat",
  "LÓpez Ortiz, Ricardo",
  "Lopez, Abraham",
  "Lopez, Alvaro",
  "Lopez, Carlos",
  "Lopez, Carlos",
  "Lopez, David",
  "Lopez, David",
  "Lopez, Emilio",
  "Lopez, Frank",
  "Lopez, Julian",
  "Lopez, Ken",
  "Lopez, Kenny",
  "Lopez, Michael",
  "Lopresti, Aaron",
  "Loprino, Dan",
  "Lorenzana, Marco",
  "Lotay, Tula",
  "Loughridge, Lee",
  "Lovece, Frank",
  "Loveness, Jeff",
  "Loveness, Jeff",
  "Lowe, John",
  "Lowe, Nick",
  "Lozano, Alexander",
  "Lozzi, Arturo",
  "LTD, PACIFIC",
  "Ltd., Digikore",
  "Ltd., SnoCone",
  "Lubbers, Bob",
  "Lubera, Logan",
  "Lucas, Adriano",
  "Lucas, Adriano",
  "Lucas, George",
  "Lucas, John",
  "Lucas, Jorge",
  "Lucas, Jorge",
  "Luis, Jose",
  "Luis, Jose",
  "Lujan, Hector",
  "Lulis, Mattea Di",
  "Lumm, Nathan",
  "Luna, Jonathan",
  "LUO, DANNI",
  "Lupacchino, Ema",
  "LUPACCHINO, EMANUELA",
  "Luque, Paco",
  "Lusen, Aron",
  "Lustig, John",
  "Luth, Tom",
  "Luzniak, Greg",
  "Lyle, Tom",
  "Lyon, Art",
  "Lyra, Rael",
  "M Mast",
  "M Msassyk",
  "Ma, Wing",
  "Maberry, Jonathan",
  "Macan, Darko",
  "Macchio, Ralph",
  "Macdonald, Andy",
  "Machlan, Mike",
  "Mack, David",
  "Mackay, Jed",
  "Mackie, Bob",
  "Mackie, Howard",
  "Maclean, Andrew",
  "Maclin, Nora",
  "MacNeil, Colin",
  "MACNEVEN, CHRISTIAN",
  "Macsombol, Cesar",
  "Macuaga, Maria-Emiko",
  "Madden, Matt",
  "Madsen, Michelle",
  "Madureira, Joe",
  "Madureira, Jose",
  "Maese, Jorge",
  "Magalhaes, Jose",
  "Maggin, Eliot",
  "Maggin, Elliot",
  "Maggs, Sam",
  "Magno, Carlos",
  "Magnum, Jonathan",
  "Magruder, Nilah",
  "Maguire, Kevin",
  "Magyar, Rick",
  "Mahfood, Jim",
  "Mahlstedt, Larry",
  "Mahn, Pop",
  "Maiolo, Marcelo",
  "Major, Guy",
  "Malavia, Nimit",
  "Maleev, Alex",
  "Maley, Matt",
  "Malibu",
  "Malibu",
  "Malin, Jon",
  "Malisan, Luca",
  "Mallard, as",
  "Mallardy, as",
  "Mallonee, Dennis",
  "Malthys, Chris",
  "Manak, Dave",
  "Manak, David",
  "Manara, Maurilio",
  "Manara, Milo",
  "Manco, Leonardo",
  "Manco, Mariana",
  "Manco, Marianna",
  "Mandel, David",
  "Mandell, George",
  "Mandrake, Tom",
  "Maneely, Joe",
  "MANEIRO, EMMA",
  "Manfredi, Federica",
  "Mangels, Andy",
  "Manley, Mike",
  "Mann, Clay",
  "Mann, Clayton",
  "Mann, Seth",
  "Manna, Francesco",
  "Manning, Matt",
  "Manning, Russ",
  "Mannion, Steve",
  "Manson, Ashby",
  "Mantlo, Bill",
  "Mantlo, Karen",
  "MANZANERO, JORGE",
  "Marafino, Frank",
  "Marais, Raymond",
  "Marangon, Lucas",
  "Marasigan, John",
  "Marcellius, Rhoald",
  "March, Guillem",
  "Marchisio, Giada",
  "Marcio Menyz",
  "Marcos, Pablo",
  "Marcotte, Tony",
  "Maresca, Luca",
  "Margopoulos, Rich",
  "Margraf, Olivia",
  "Marguerite Sauvage",
  "Mari, Guillem",
  "Marimon, Bill",
  "Marin, Angel",
  "Marin, Rafael",
  "Marks, John",
  "Marks, Jonathan",
  "Markus Raymond",
  "Maroto, Esteban",
  "Marquez, David",
  "MARRAFFINO, FRANKLIN",
  "Marrinan, Chris",
  "Marschall, Richard",
  "Marshall, Brian",
  "Marshall, Rick",
  "Marshall, Scott",
  "Marsilia, Matthew",
  "Martello, Annapaola",
  "Martin, Cindy",
  "Martin, Cynthia",
  "Martin, Frank",
  "Martin, Gary",
  "Martin, George",
  "Martin, Jason",
  "Martin, Laura",
  "Martin, Marcos",
  "Martinbrough, Shawn",
  "Martinez, Alitha",
  "Martinez, Alitha",
  "Martinez, Allen",
  "Martinez, Alvaro",
  "Martinez, Jesus",
  "Martinez, Marta",
  "Martinez, Roy",
  "Martinez, Salvador",
  "Martinez, Salvador",
  "Martinez, Warren",
  "Martiniére, Stephan",
  "Marts, Michael",
  "Marts, Mike",
  "Marz, Marcos",
  "Marz, Ron",
  "Marzan Jr., Jose",
  "Marzan, Jose",
  "Marzan, Jose",
  "Mas",
  "Mas, Agustin",
  "Mason, Thomas",
  "MASSACCI, ALEX",
  "Massara, Jim",
  "Massengill, Nathan",
  "Massengill, Nathan",
  "Massey, Cal",
  "Mast, JL",
  "Masters, Jason",
  "Masters, Ollie",
  "Mateo, Ikari",
  "Matisson, Steve",
  "Matsuda, Jeff",
  "Matthew",
  "Matthews, Brett",
  "Matthys, Chris",
  "Mattina, Francesco",
  "Mattsson, Steve",
  "Maurer, Kevin",
  "Maurer, Norman",
  "Mavlian, Vatche",
  "Mayer, Jp",
  "Mayerik, Val",
  "Mayhew, Mike",
  "Mays, Ricardo",
  "Mays, Rick",
  "Mazzucchelli, David",
  "McAdod, Tyson",
  "Mcadoo, Tyson",
  "McBride, Aaron",
  "Mccaig, Dave",
  "Mccann, Jim",
  "McCarron, Owen",
  "Mccarthy, Brendan",
  "McCarthy, Ray",
  "Mccarthy, Shane",
  "Mccarthy, Trevor",
  "McCarty, Bob",
  "Mccarver, Chris",
  "Mcclellan, Aaron",
  "McCloud, Scott",
  "Mccool, Ben",
  "Mccrea, John",
  "Mcdaniel, Scott",
  "McDaniel, Scott",
  "McDaniel, Steve",
  "Mcdaniel, Walter",
  "McDonald, Ken",
  "Mcdonnell, Luke",
  "Mcdonnell, Pete",
  "Mcdowell, Megan",
  "Mcduffie, Dwayne",
  "Mcfarland, Whitney",
  "Mcfarlane, Todd",
  "Mcgrane, Madeline",
  "Mcgregor, Don",
  "Mcguinness, Ed",
  "Mcguire, Seanan",
  "Mckeever, Sean",
  "Mckeever, Ted",
  "Mckelvie, Jamie",
  "Mckenna, Mark",
  "MCKENZIE, CHRIS",
  "Mckenzie, Roger",
  "Mckie, Angus",
  "McKinney, Brandon",
  "Mckone, Michael",
  "Mckone, Mike",
  "McKowen, Scott",
  "Mclaughlin, Frank",
  "Mclaurin, Marcus",
  "Mcleod, Bob",
  "Mcmanus, Shawn",
  "McNabb, Mark",
  "McNabb, Robb",
  "McNeil, Carla",
  "Mcniven, Steve",
  "Mcquaid, Sean",
  "Mcteigue, Su",
  "McTergue, Sue",
  "McWeeney, Tom",
  "Mcwhiney, Andrew",
  "Mcwilliams, Al",
  "Medina, Angel",
  "Medina, Avalon",
  "MEDINA, IAN",
  "Medina, Lan",
  "Medina, Lan",
  "Medina, Milx",
  "Medina, Paco",
  "Medors, Josh",
  "Medri, Denis",
  "Medri, Dennis",
  "Meglia, Carlos",
  "Mei, Derek",
  "Meikis, Dave",
  "MEINERDING, RYAN",
  "Melendez, Virginia",
  "Melia, Sergio",
  "Mellor, Steve",
  "Melo, Adriana",
  "Meltzer, Brad",
  "MELVILLE, HERMAN",
  "Mena, Javier",
  "Mendez, Fernando",
  "Mendez, Freddy",
  "Mendoza, Jaime",
  "Meno, Joe",
  "Menon, Neeraj",
  "Menor, Javier",
  "Menor, Javier",
  "Menzie, Greg",
  "Meo, Simone",
  "MERANTE, NICOLA",
  "Merino, Jesus",
  "Meskin, Mort",
  "Messina, David",
  "Messnerloebs, William",
  "Meter, Jen",
  "Meter, Jennifer",
  "Method Man",
  "Mettier",
  "Mettler, J.",
  "Meugniot, Will",
  "Meyers, Jonboy",
  "Meyers, Seth",
  "Mhan, Pop",
  "Michael Avon Oeming",
  "Michaels, Kraig",
  "Micheletti, Rene",
  "Michelinie, David",
  "Middleton, Joshua",
  "Miehm, Grant",
  "Mielcarek, Vince",
  "Miesegaes, John",
  "Migliari, Rodolfo",
  "Mignola, Mike",
  "Miki, Danny",
  "Miki, Danny",
  "Milgrom, Al",
  "Milla, Avalon",
  "Milla, Matt",
  "Millar, Mark",
  "Miller, Charles",
  "Miller, Frank",
  "Miller, Jason",
  "Miller, John",
  "Miller, John",
  "Miller, Mark",
  "Miller, Mike",
  "Miller, Mike",
  "Miller, Mike S.",
  "Milligan, Peter",
  "Millionaire, Tony",
  "Mills, Pat",
  "Milonogiannis, Giannis",
  "Milosevic, Rajko",
  "Ming, Xu",
  "Minhaj, Hasan",
  "Minor, Jason",
  "Miranda, Emerson",
  "Mireault, Bernie",
  "Mishkin, Dan",
  "Mitchell, Colin",
  "Mitchell, Mary",
  "Mitchell, Steve",
  "MITRIC",
  "Miyazawa, Takeshi",
  "Mizuno, Junko",
  "Mizushima, Kiichi",
  "Mobili, Francesco",
  "Moder, Lee",
  "Moebius",
  "Moede, Jade",
  "Moeller, Chris",
  "Moeller, Christopher",
  "Moench, Doug",
  "Moisan, Jonathan",
  "Moldoff, Sheldon",
  "Molenaar, Romano",
  "Molina, Jorge",
  "Molina, Jose",
  "Molinar, Larry",
  "Moline, Karl",
  "Moll, Shawn",
  "Molnar, Stephen",
  "Molno, Bill",
  "Moncuse, Steve",
  "Montana, Bob",
  "Montano, Steve",
  "Montano, Yong",
  "Montclare, Brandon",
  "Montenegro, Miguel",
  "Montes, Javi",
  "Montoya, Zachary",
  "Moon, Fabio",
  "Mooney, Jim",
  "Mooney, Stephen",
  "Mooneyham, Christopher",
  "Moore, Alan",
  "MOORE, BRIAN",
  "Moore, Jeff",
  "Moore, Jeffrey",
  "Moore, John",
  "Moore, Stephanie",
  "Moore, Steve",
  "Moore, Stuart",
  "Moore, Terry",
  "Moore, Tony",
  "Moore, Tradd",
  "Mora, Daniel",
  "Morales, Lou",
  "Morales, Mark",
  "Morales, Rags",
  "Morales, Robert",
  "Moran, Ericka",
  "Moranelli, Romina",
  "Morasigan, John",
  "Morazzo, Martin",
  "More",
  "More",
  "More, And",
  "Moreci, Michael",
  "Moreels, Eric",
  "Moreira, John",
  "Morelli, Christie",
  "Morelli, Jack",
  "Morelli, Joe",
  "Morelli, John",
  "Moreno, Chris",
  "Moreno, Dinisio",
  "Moreshead, Dana",
  "Moretti, Mark",
  "Morgan, Alec",
  "Morgan, Alec",
  "Morgan, Richard",
  "Morgan, Tom",
  "Morisi, Pete",
  "Morissette-​Phan, Djibril",
  "Morrell, David",
  "Morrisey, Alex",
  "Morrison, Bill",
  "Morrison, Grant",
  "Morrison, Robbie",
  "Morrissette, Gabriel",
  "Morrissey, Alexander",
  "Morrow, Gray",
  "Morse, Scott",
  "Mortellaro, Tony",
  "Mortimer, Win",
  "Mortimer, Winslow",
  "Morvan, Jean-David",
  "Mos, Urszula",
  "Moskowitz, Sy",
  "Moss, Wilson",
  "Mossa, Andres",
  "Mossoff, Sarra",
  "Mota, Carlos",
  "Motter, Dean",
  "Mouly, Francoise",
  "Mounts, Paul",
  "Moussa, Mostafa",
  "Moustafa, Ibrahim",
  "Moy Rodriguez",
  "Moy, Jeff",
  "Moy, Phil",
  "Mrva, Miroslav",
  "Muchin, Richard",
  "Muhr, Jason",
  "Mulder, Kim",
  "Mullaney, Jim",
  "MULLIN, THOMAS",
  "Mullin, Tom",
  "Mumy, Bill",
  "Mundelo",
  "Mundo, Mike",
  "Munera, Miguel",
  "Mungiole, P.j.",
  "Muniz, Jim",
  "Muns",
  "Muraguchi, Rodolfo",
  "Murai, Impacto",
  "Murai, William",
  "Murase, Sho",
  "Murillo, Ana",
  "Murnane, Michael",
  "Murphy, Sean",
  "Murr, Ed",
  "Murray, Brian",
  "Murray, Chris",
  "Murray, Doug",
  "Murray, Will",
  "MURRAY, WILLIAM",
  "Murtaugh, Ray",
  "Mushynsky, Andy",
  "Mustard, Donald",
  "Muth, Jon",
  "Muth, Jon",
  "Mutti, Andrea",
  "Mychaels, Marat",
  "N. Y. tribe",
  "N.j.q.",
  "Nadeau, John",
  "Nadler, Lonnie",
  "Nagasawa, Shin",
  "Naifeh, Ted",
  "Nakatsuka, Makoto",
  "Nakayama, David",
  "Nakrosis, Dan",
  "Napolitano, Nick",
  "Narcisse, Evan",
  "Nasser, Mike",
  "Nauck, Christian",
  "Nauck, Todd",
  "Nausedas, Jim",
  "Nava, Victor",
  "Navarrot, David",
  "Nayoung Kim",
  "Neary, Paul",
  "Nebres, Rudy",
  "Nelson, Arvid",
  "Nelson, Mark",
  "Nelson, Matt",
  "Nemri, Karen",
  "Nero, Giovanna",
  "Nero, Monty",
  "NETZER, MICHAEL",
  "Neves, Dio",
  "Neves, Diogenes",
  "Neves, Fabiano",
  "Newbold, David",
  "Newcomen, Emily",
  "Newell, Mindy",
  "Newton, Don",
  "Nf",
  "Nguyen, Dustin",
  "Nguyen, Eric",
  "Nguyen, Hoang",
  "Nguyen, Peter",
  "NGUYEN, PETER",
  "Nguyen, Tom",
  "Ni",
  "Nicholas, Charles",
  "Nichols, Art",
  "NICHOLS, WAYNE",
  "Nicieza, Fabian",
  "Nicieza, Mariano",
  "Nicuolo, Daniele",
  "NIELSEN, CLIFF",
  "NIELSEN, TERESE",
  "Niemczyk, Kate",
  "Nihei, Tsutomu",
  "Niles, Steve",
  "Nilsen, Anders",
  "Nino, Alex",
  "Nitz, Jai",
  "Nixon, Matt",
  "Njq",
  "Noble, Douglas",
  "Nocenti, Al",
  "Nocenti, Ann",
  "Nockuls, Deon",
  "Nocon, Cedric",
  "Nolan, Graham",
  "Nome, Oliver",
  "Noor, Ifansyah",
  "Nord, Cary",
  "Norem, Earl",
  "North, Ryan",
  "Norton",
  "Norton, Dan",
  "Norton, Ed",
  "Norton, Mike",
  "Nostrand, Howard",
  "Notes, See",
  "Noto, Phil",
  "Noto, Philip",
  "Nourigat, Natalie",
  "Novak, Jim",
  "Novick, Jerry",
  "Novin, Phyllis",
  "Nowlan, Kevin",
  "Nuckols, Deon",
  "Nunis, Allen",
  "Nyberg, John",
  "O Sullivan, Mike",
  "O'Bryan, John",
  "O'Connor, Michael",
  "O'Dell, Brian",
  "O'Dell, Rebecca",
  "O'English, Mark",
  "O'grady, Len",
  "O'halloran, Chris",
  "O'hare, Michael",
  "O'Malley, Bryan",
  "O'neil, Dennis",
  "O'SULLIVAN, MICHAEL",
  "O'sullivan, Mike",
  "Oakley",
  "Oakley, Bill",
  "Oats, Kieran",
  "Oback, Sonia",
  "OBrien, Judith",
  "Ocampo, David",
  "OConnor, Jenny",
  "Odagawa, Ryan",
  "ODonnell, Howard",
  "Oduber, Daniel",
  "Oduber, Ikari",
  "Oeming, Mike",
  "OEnglish, Mark",
  "of Tan Comics, Cuzu",
  "of Tan Comics, Gadson",
  "of Tan Comics, Haining",
  "of Tan Comics, Haining with Cuzu",
  "Of, Bottom",
  "Ogle, Rex",
  "Ogrady, Len",
  "OHare, Michael",
  "Ohtsuka, Tommy",
  "Okeefe, Patrick",
  "Okorafor, Nnedi",
  "Oksner, Bob",
  "Okunev, Oleg",
  "Olazaba, Victor",
  "Oleck, Jack",
  "Oliff, Avalon",
  "Oliff, Steve",
  "Oliveira, Bruno",
  "Oliveira, Hector",
  "Oliver, Ben",
  "Oliver, Glynis",
  "Oliveras, Santiago",
  "Olivetti, Ariel",
  "Olivier, Vatine",
  "Ollerenshaw, Rod",
  "Olliffe, Pat",
  "Olliffe, Patrick",
  "Olortegui, Diego",
  "Olsen, James",
  "OlyOptics.Com",
  "Omaha",
  "Oneil, Dennis",
  "Opena, Jerome",
  "Ordway, Jerry",
  "OREN JUNIOR",
  "Orlandini, Daniele",
  "Orlando, Joe",
  "Ortega, Belen",
  "Ortega, Joshua",
  "Ortego, Guillermo",
  "Ortiz, Juan",
  "Ortiz, Ricardo",
  "Ory, Richard",
  "Orzechowski, Tom",
  "OSKNER, BOB",
  "Ossio, Fico",
  "Ostrander, John",
  "Ott, Roger",
  "Ottley, Ryan",
  "Oum, Siya",
  "Overton, Brian",
  "Owens, Andy",
  "Owens, Matt",
  "Owens, Matthew",
  "Owsley, James",
  "Owsley, Patrick",
  "Ozon",
  "P Pedi",
  "P.r., Signed",
  "Pace, Richard",
  "Pacella, Mark",
  "Pacheco, Carlos",
  "Pacheco, Karla",
  "Padilla, Agustin",
  "Page, Panel",
  "pages, including",
  "Pages, Script",
  "Pagulayan, Carlo",
  "Paige, Lou",
  "Paintbrush, Atomic",
  "painted, Mark",
  "painting, Gary",
  "painting, John",
  "painting, Jon",
  "painting, Jon",
  "Paitreau, Stephane",
  "Pajarillo, Mark",
  "Pak, Greg",
  "Pakula, Mac",
  "Paley, Hugh",
  "Palicki, Eric",
  "Pallot, Terry",
  "Palmer, Tom",
  "Palmiotti, Jimmy",
  "Palmiotti, Peter",
  "Palo, Jefte",
  "Paludo, Javier",
  "Palumbo, Dave",
  "Panarin, Slava",
  "Pander, Arnold",
  "Panel, Top",
  "Panella, Rob",
  "panels, Kirbyesque",
  "Paniccia, Mark",
  "Panosian, Dan",
  "PANTALENA, PAOLO",
  "Pantazis, Pete",
  "Pantazis, Peter",
  "PANTER, GARY",
  "Panzo, Will",
  "Paolo, Jefte",
  "Paquette, Andrew",
  "Paquette, Yanick",
  "Parel, Gerald",
  "Paris, Roland",
  "Park",
  "Park, Andy",
  "PARK, JI EUN",
  "PARK, SI YEON",
  "Parker, Bradley",
  "Parker, Jacob",
  "Parker, Jake",
  "Parker, Janice",
  "Parker, Jeff",
  "Parker, Jeffrey",
  "Parker, Rick",
  "Parkhouse, Annie",
  "Parkhouse, Stephen",
  "Parkhouse, Steve",
  "Parko",
  "Parks, Ande",
  "Parks, Jennifer",
  "Parlett, George",
  "Parlov, Goran",
  "Paronzini, Francisco",
  "Parrillo, Lucio",
  "Parsons, Mark",
  "Parsons, Sam",
  "Parsons, Sean",
  "Parsons, Sean",
  "Parwulski, Maria",
  "Pasciullo, Mike",
  "Pascoe, James",
  "Passalaqua, Allen",
  "Pastoras, Das",
  "Patch Zircher",
  "PATCHETT, BRIAN",
  "Patrick, Lisa",
  "Patterson, Bob",
  "Patterson, Bruce",
  "Patterson, James",
  "Patti, Keaton",
  "Pattison, Ronda",
  "Patton, Billy",
  "Patton, Billy",
  "Patton, Chuck",
  "Paul, Frank",
  "Paulo, Sao",
  "Paz, Jason",
  "Pearson, Charles",
  "Pearson, Eric",
  "Pearson, Jason",
  "Peaty, James",
  "Peck, Seth",
  "Peeples, Brent",
  "Peikos, Nate",
  "PEKAR, HARVEY",
  "Pelayo, Marcos",
  "Pelletier, Brian",
  "Pelletier, Paul",
  "Pelosi, Lia",
  "Pena, Justino",
  "PENALTA, XURXO",
  "Penaranda, Byron",
  "Pennington, Mark",
  "Pennino, Mario",
  "Pensa, Shea Anton",
  "Pepoy, Andrew",
  "Peralta, German",
  "Percio, Facundo",
  "Percy, Benjamin",
  "Percy, Frank",
  "Pereira, Nelson",
  "Pereyra, Nestor",
  "Perez, George",
  "Perez, Pere",
  "Perez, Ramon",
  "Perez, Ramon",
  "Perkins, Dana",
  "Perkins, Mike",
  "Perlin, Don",
  "Perlman, Nicole",
  "Perrin, David",
  "Perrotta, Rich",
  "Perrotta, Rich",
  "PERRY, STEVE",
  "Pertzborn, Ted",
  "Peru, Stephane",
  "Peruzzi, Simone",
  "Peter, Cristiane",
  "Peter, Cristiane",
  "Peter, David",
  "Peteri, Top",
  "Peteri, Troy",
  "Peterson, Brandon",
  "Peterson, Jonathan",
  "Petit, Cory",
  "Petit, Vc",
  "Petrecca, Bob",
  "Petrie, Doug",
  "Petrovich, Eduard",
  "Petrucha, Stefan",
  "Petrus, Hugo",
  "Peyer, Tom",
  "Pfeifer, Will",
  "Pfeufer, Carl",
  "Pfeuffer, Carl",
  "Pham, Khoi",
  "Phillips, Adam",
  "Phillips, Joe",
  "Phillips, Sean",
  "Phoenix, Woodrow",
  "PICCIRILLI, THOMAS",
  "Pichelli, Sara",
  "Piekos, Nate",
  "Pierce, Tamora",
  "Pierfederici, Mirco",
  "Pierre, Joe",
  "Piers-Rayner, Richard",
  "Pierson, D.C.",
  "Pike, Jay",
  "Pilgrim, Corona",
  "Pilgrim, Will",
  "Pimentel, Joe",
  "Pimentel, Jose",
  "Pina, Javier",
  "Pinaha, Bob",
  "Pini, Wendy",
  "Pinna, Amilcar",
  "Pinnock, Rachel",
  "Piotrowski, Rob",
  "Piper, Anthony",
  "Piskor, Ed",
  "Pitarra, Nicholas",
  "Pittarese, Frank",
  "Pizzari, Luca",
  "Plascencia, Fco",
  "Plascencia, Ivan",
  "Plati, Nuno",
  "Platt, Stephen",
  "Ploog, Mike",
  "Plunkett, Kilian",
  "Plunkett, Sandy",
  "Poggi, Roberto",
  "Pokaski, Joe",
  "Poletti, Nancy",
  "Pollard, Keith",
  "Pollina, Adam",
  "Ponsor, Justin",
  "Ponticelli, Alberto",
  "Pope, Paul",
  "Poplaski, Peter",
  "Portacio, Whilce",
  "Portela, Francis",
  "PORTENA, FRANCIS",
  "Porter, Howard",
  "Portman, Natalie",
  "Posehn, Brian",
  "Posella, Ashley",
  "Post, Howard",
  "Postman, Brian",
  "Potts, Carl",
  "Powell",
  "Powell, Benny",
  "Powell, Bob",
  "Powell, Eric",
  "Powell, Jeff",
  "Powers, Mark",
  "Prado, Joe",
  "Pratt, George",
  "Prentice, Pat",
  "Prez, George",
  "Prianto, Arif",
  "Priest, Christopher",
  "Prieto, Juan",
  "PRIETO, JUAN",
  "Prince, William",
  "Prism, Electric",
  "Proctor, Jon",
  "Productions, Benchmark",
  "Productions, Oogeley",
  "PRODUCTIONS, STAN",
  "Prudeaux, Mark",
  "Prudence Shen",
  "Pruett, James",
  "Pruett, Joe",
  "Pt",
  "Pugh, Steve",
  "Puig, Enrique",
  "Pulido, Javier",
  "Punk, CM",
  "Purcell, Gordon",
  "Purcell, Howard",
  "Purcell, Jack",
  "Purcell, Steve",
  "Purves, Jeff",
  "Puthad, Daisy",
  "Putney, Susan",
  "Putri, Yasmine",
  "Pyle, Elizabeth",
  "Pyle, Ellie",
  "Qualano, Pasquale",
  "Quantz, Daniel",
  "Quesada, Joe",
  "Quesada, Nanci",
  "Quijano, Rober",
  "Quinn, David",
  "Quinn, Pat",
  "Quinn, Robert",
  "Quinn, Zoe",
  "Quinones, Joe",
  "Quintana, Wil",
  "Quitely, Frank",
  "R Rahzzah",
  "R.B. Silva",
  "Raab, Ben",
  "RAAPACK, JHEREMY",
  "Raboy, Mac",
  "Rachelson, Phil",
  "Raffaele, Stefano",
  "Ragni, Alyssa",
  "Rags Morales",
  "Rahzzah",
  "Raicht, Mike",
  "Raimondi, Pablo",
  "Raiz, James",
  "Ramirez, Alfred",
  "RAMIREZ, JORGE",
  "Ramirez, Juanan",
  "Ramos, Humberto",
  "Ramos, Jay",
  "Ramos, Jay",
  "Ramos, Rodney",
  "Ramos, W.",
  "Ramos, Wilson",
  "Ramsey, H.",
  "Randall, Ron",
  "Randolph, Grace",
  "Randolph, Khary",
  "Raney, Gina",
  "Raney, Tom",
  "Rankin, Rich",
  "Rankin, Richard",
  "Ranson, Arthur",
  "Rapmund, Norm",
  "Rapoza, Dave",
  "Rasche, Richard",
  "Raske",
  "Raskin, Jordan",
  "Ratcliffe, Sonja",
  "Rathburn, Cliff",
  "Rathburn, Cliff",
  "Rauch, Anthony",
  "Rauch, John",
  "Rauch, John",
  "RAYMOND, MARKUS",
  "Rebelka, Jakub",
  "Reber, Brian",
  "Rebner, Jeff",
  "Redding, Pat",
  "Reddington, Jim",
  "Redmond, Martin",
  "Redmond, Ruth",
  "Redondo, Bruno",
  "Redondo, Virgilio",
  "Reed, Brian",
  "REED, BRIAN",
  "Reed, Dan",
  "Reed, Scott",
  "Reeder, Amy",
  "Reeder, Amy",
  "Reese, Barry",
  "Reese, Ralph",
  "Regla, Sal",
  "Regla, Salvador",
  "Reilly, Tom",
  "Reinhold, Bill",
  "Reinhold, Willian",
  "Reinholds, W",
  "Reinman, Paul",
  "Reis, Ivan",
  "Reis, Rod",
  "REISS, CAIO",
  "Reitman, Joe",
  "Remenar, Valentina",
  "Remender, Rick",
  "Renaud, Chris",
  "Renaud, Paul",
  "Renee, Stefani",
  "Renkewitz, Kris",
  "RENZI, RICO",
  "Renzi, Rico",
  "Retagi, Marco",
  "Revel, Brahm",
  "Rey, Vanesa",
  "Ribeiro, Sandro",
  "Ribic, Esad",
  "Rich, Bob",
  "Rich, Sabine",
  "Richard, Kevin",
  "Richards, Cliff",
  "Richardson, Afua",
  "Richardson, Afua",
  "Richardson, Roy",
  "RICHARDSON, SCOTT",
  "Riches, Adam",
  "Ricketts, Mark",
  "Rico, Don",
  "Ridgeway, John",
  "Ridgway, John",
  "Ridout, Cefn",
  "Rieber, John",
  "Riemer, Marc",
  "Riggs, Robin",
  "Rinaldi, Pino",
  "Rinzler, J.",
  "Rio, Al",
  "Rio, Tania",
  "Rios, David",
  "Rios, Emma",
  "Riske, David",
  "Risso, Eduardo",
  "Ritter, Mickey",
  "Rival, Rico",
  "Riveiro, David",
  "Rivera, Gabby",
  "Rivera, Gabrielle",
  "Rivera, Paolo",
  "Rivoche, Paul",
  "Ro, Avalons",
  "Ro, Rob",
  "Rob Liefeld, Fabian Nicieza",
  "Robard, Mike",
  "Robbins, Ed",
  "Robbins, Frank",
  "Robbins, Trina",
  "Roberson, Ibraim",
  "Roberson, Ibraim",
  "Roberson, Inactive",
  "Robertson, Darick",
  "Robertson, Derek",
  "Robertson, MK",
  "Robertson, Steve",
  "Robins, Clem",
  "Robinson, Alan",
  "Robinson, Andrew",
  "Robinson, Andrew",
  "Robinson, Chris",
  "Robinson, Christopher",
  "Robinson, James",
  "Robinson, Jimmie",
  "Robinson, Mark",
  "Robinson, Roger",
  "Robinson, Roger",
  "Robinson, Wayne",
  "Robson, Will",
  "Roca, Paco",
  "Rocafort, Kenneth",
  "Rocafort, Top",
  "Rocca",
  "Roche, Nick",
  "Rockwitz, Mike",
  "Rocky",
  "Rodgers, Joel",
  "Rodi, Rob",
  "Rodier, Denis",
  "Rodionoff, Hans",
  "Rodriguez, Anibal",
  "Rodriguez, Carlos",
  "Rodriguez, Diego",
  "RODRIGUEZ, IVAN",
  "Rodriguez, Jason",
  "Rodriguez, Javier",
  "Rodriguez, Robbi",
  "Rodrix, Pow",
  "Roe, Claire",
  "Roe, Mike",
  "Rogan, Mark",
  "Roge Antonio",
  "Rogers, Marshall",
  "Roiston, Tom",
  "Rojas, Jose",
  "Rollins, Prentiss",
  "Rolo, Patrick",
  "Roman, Bill",
  "Romero, George",
  "Romero, Leonardo",
  "Romita, John",
  "Romita, John",
  "Rosa, Sam",
  "Rosado, William",
  "Rosanas, Ramon",
  "Rosas",
  "Rosas, Joe",
  "Rosasnas, Ramon",
  "Rosemann, Bill",
  "Rosen, Joe",
  "Rosen, Sam",
  "Rosenberg, Matthew",
  "Rosenberg, Michael",
  "Rosenberg, Rachelle",
  "Rosenberg, Rachelle",
  "Roshell, John",
  "Roslan, Mark",
  "Ross, Alex",
  "Ross, Dave",
  "Ross, David",
  "Ross, John",
  "ROSS, LUCIANO",
  "Ross, Luke",
  "Rossbach, J.S.",
  "Rossmo, Riley",
  "Roth, Werner",
  "Rougemont, Jacob",
  "Rougemont, William",
  "Rouleau, Duncan",
  "Rousseau, Craig",
  "Roussos, Eleni",
  "Roussos, George",
  "Roux, Stéphane",
  "Roux, Stephan",
  "Roux, Stephane",
  "Rowell, Rainbow",
  "Roy, Mike",
  "Royal, Jim",
  "Royer, Mike",
  "Royle, John",
  "Rozegar, Arsia",
  "Rozegar, Avalon",
  "Rozum, John",
  "Ruan, Dike",
  "Rubenstein, Joe",
  "Rubi, Mel",
  "Rubinstein, Joe",
  "Rubinstein, Josef",
  "Rubio, Bobby",
  "Rubio, Kevin",
  "Rucka, Greg",
  "Rud, Anna",
  "Rude, Steve",
  "Rudoni, Daniele",
  "Rudy, Marco",
  "Ruffino, Nei",
  "Ruffner, Jessica",
  "RUFFOLO, ROBERT",
  "Rugg, Jim",
  "Ruggiero, Lorenzo",
  "Ruiz, Felix",
  "Ruiz, Felix",
  "Ruiz, Francisco",
  "Rule, Christopher",
  "Runge, Nick",
  "Runyan, Mark",
  "RUSCOE, MICHAEL",
  "RUSNAK, JONATHAN",
  "Rusnak, Karl",
  "Russel, Vince",
  "Russell, Craig",
  "RUSSELL, DAVE",
  "Russell, P.",
  "Russell, Vince",
  "Ruth, Greg",
  "Ryan, Johnny",
  "Ryan, Kevin",
  "Ryan, M.",
  "Ryan, Matt",
  "Ryan, Matthew",
  "Ryan, Matthew",
  "Ryan, Michael",
  "Ryan, Paul",
  "Ryan, Sean",
  "Ryp, Juan",
  "S SKAN",
  "S Soutch",
  "S Stonehouse",
  "S, Tim",
  "Sabino, Joe",
  "Sabino, Vc",
  "Sacks, Ethan",
  "Sadowski, Dynamite",
  "Sadowski, Steve",
  "Saenz, Mike",
  "Saeteroy, John",
  "Sahle, Harry",
  "Saichann, Alberto",
  "Saido, Diogo",
  "Saito, Diogo",
  "Saiz, Jesus",
  "Sakai, Stan",
  "Sakakibara, Mizuki",
  "Saladino, Gaspar",
  "Salas, Ron",
  "Salazar, Edgar",
  "Salazar, Edgar",
  "Sale, Robert",
  "Sale, Tim",
  "Salick, Roger",
  "Salicrup, Jim",
  "Salmons, Tony",
  "Salonga, Noah",
  "Saltares, Javier",
  "Salvo, Roberto",
  "Sam, Salgood",
  "Samnee, Chris",
  "Sampson, Alison",
  "Sampson, Dave",
  "Sampson, Steve",
  "Samu, Juan",
  "Sanchez, Alex",
  "Sanchez, Daniel",
  "Sanchez, Darren",
  "Sanchez-​Almara, Dono",
  "Sancho, Miguel",
  "Sanders",
  "Sanders, Jim",
  "Sanders, Jim",
  "Sanders, Steven",
  "SANDERS, STEVEN",
  "Sanderson, Peter",
  "Sandoval, Gerardo",
  "Sandoval, Rafa",
  "SANDOVAL, RAFAEL",
  "Sankovitch, Lauren",
  "Sanna, Guillermo",
  "Sanna, Guillermo",
  "Santacruz, Derlis",
  "Santacruz, Juan",
  "Santacruz, Juan",
  "Santagati, Federico",
  "Santamaria, Jorge",
  "Santangelo, Adam",
  "Santiago, Wilfred",
  "SANTOLOUCO, MATEUS",
  "SANTORO, FRANK",
  "Santos, Amilton",
  "Santos, Wilton",
  "Santucci, Marco",
  "Sapolosky, Fabrice",
  "Sapolsky, Fabrice",
  "Sauvage, Marguerite",
  "Sava, Scott",
  "Saviuk, Alex",
  "Sayers, Troy",
  "Sayif, Ardian",
  "Scalera, Buddy",
  "Scalera, Matteo",
  "Scalf, Chris",
  "Schaefer, Jeanine",
  "Schaeffer, Dan",
  "Schaffenberger, Kurt",
  "Schane, Tristan",
  "Scharf, Jonas",
  "Schechter, Gabe",
  "Schechter, Gabriel",
  "Scheele, Bob",
  "Scheele, Christie",
  "Scheele, Christie",
  "Scheele, Jack",
  "Scheele, Max",
  "Scheer, Paul",
  "Schellinger, Jennifer",
  "Scherberger, Patrick",
  "Scheuer, Tom",
  "Schigiel, Gregg",
  "Schiller, Fred",
  "Schirmer, Jana",
  "Schiti, Valerio",
  "Schmidt, Andy",
  "Schmidt, J.",
  "Schmidt, Otto",
  "Scholl, Kiff",
  "Schomburg, Alex",
  "Schoonover, Brent",
  "Schreck, Dean",
  "Schrotter, Gustav",
  "Schubert, Willie",
  "Schultz, Erica",
  "Schultz, Mark",
  "Schwager, Rob",
  "Schwartz, Dana",
  "Schwartz, Fred",
  "Scioli, Tom",
  "Sciver, Ethan",
  "Scoffield, Sean",
  "Scotese, Petra",
  "Scott, Damion",
  "Scott, Greg",
  "Scott, Mairghread",
  "Scott, Nicola",
  "Scott, Steve",
  "Scott, Tinsley",
  "Scott, Trevor",
  "Scripter",
  "Scrotese, Petra",
  "Sculpture",
  "SE, AL",
  "Seagle, Steve",
  "Sean, Ben",
  "Sears, Bart",
  "Sebela, Christopher",
  "Sebela, Christopher",
  "Secretary, The",
  "Sedlmeier, Cory",
  "Seeley, Tim",
  "Seetharaman, Suresh",
  "Segarra-Rosa, Jean",
  "Segovia, Stephen",
  "Seguin, Joel",
  "Segura, Raul",
  "Seifert, Brandon",
  "SEJIC, STJEPAN",
  "Sekowsky, Mike",
  "Self, David",
  "Sellers, Mike",
  "Sellers, Pete",
  "Selman, Matt",
  "Semaan, Jacob",
  "Semeiks, Val",
  "Semekis, Val",
  "Sen, Mario",
  "Senior, Geoff",
  "Sepulveda, Miguel",
  "Sepulveda, Miguel",
  "Sequerra, Chris",
  "Serrano, Felix",
  "Services, Jamison",
  "Seuling, Carole",
  "Seung, Joe",
  "Severin, John",
  "Severin, Marie",
  "Sexton, David",
  "Sf",
  "SG",
  "Shafer, Odin Austin",
  "Shalvey, Declan",
  "Shan, Darren",
  "Shaner, Evan",
  "Shannon, Kelsey",
  "Shanower, Eric",
  "Shanower, Eric",
  "Shapiro, Barry",
  "Sharen, Bob",
  "Sharen, Carl",
  "Sharp, Bob",
  "Sharp, Liam",
  "Sharpe",
  "Sharpe, Anita",
  "Sharpe, Dave",
  "Sharpe, David",
  "Sharpe, David",
  "Sharpe, Davide",
  "Sharpe, Kevin",
  "Sharpe, Liam",
  "Sharpe, Vc",
  "Sharpefont",
  "Sharpefront, P.T.",
  "Shavrin, Ivan",
  "Shaw, Dash",
  "Shaw, Emily",
  "Shaw, Geoffrey",
  "Shaw, Jay",
  "Shaw, Scott",
  "SHECHTER, GABRIEL",
  "Shelander",
  "Shelfer, Michael",
  "Shen, Prudence",
  "SHERBERGER, PATRICK",
  "Sherigail, Ray",
  "Sherman, Hayden",
  "Sherman, James",
  "Sherman, Jim",
  "Sherman, M.",
  "Shiga, Jason",
  "Shim, Woo",
  "Shimi, Dice",
  "Shinick, Kevin",
  "Shoemaker, Terry",
  "Shooter, Jim",
  "Shooter, Roy",
  "Shores, Syd",
  "Showman, Galen",
  "Shultz, Mark",
  "Shum, Howard",
  "SHY, CHRISTOPHER",
  "Sibal, Jon",
  "Sibal, Jonathan",
  "Sicat, Alejandro",
  "Siedell, Tim",
  "SIEGEL, ALEC",
  "Siegel, Jerry",
  "Sienkiewicz, Bill",
  "Silas, Thony",
  "Silva, Alex",
  "Silva, Anderson",
  "Silva, Israel",
  "Silva, R.B.",
  "Silveira, Joo",
  "Silverberg, Robert",
  "Silvestri, Marc",
  "Silvestri, Top",
  "Sim, David",
  "Simek, Art",
  "Simek, Artie",
  "Simek, Jean",
  "Simmonds, Martin",
  "Simmons, Ed",
  "Simmons, Mark",
  "SIMMONS, MARK",
  "Simmons, Tom",
  "Simms, Dave",
  "Simon, Allen",
  "Simon, Joe",
  "Simone, Gail",
  "Simons, Dave",
  "Simons, Jeffrey",
  "Simons, Warren",
  "Simonson, Louise",
  "Simonson, Walt",
  "Simonson, Walter",
  "Simpson, Dave",
  "Simpson, Don",
  "Simpson, Will",
  "Sims, Chris",
  "Sims, Chris",
  "SIMS, KYLE",
  "Sinclair, James",
  "Sinclair, Kirsten",
  "Singh, Arune",
  "Singh, Mukesh",
  "Sinnott, Joe",
  "Siqueira, Paulo",
  "SIQUERA, PAOLO",
  "SIQUERA, PAULO",
  "Siry, Marc",
  "Sitterson, Aubrey",
  "Sitterson, Lazer",
  "Siu-Chong, Ken",
  "Sjoerdsma, Al",
  "Skan",
  "Skeates, Steve",
  "Skelton, Deryl",
  "Skinner, Don",
  "Skinner, Tony",
  "Skolnick, Evan",
  "Skolnik, Mossoff",
  "Skrenes, Mary",
  "Skroce, Steve",
  "Slick",
  "Slifer, Roger",
  "Sliney, Will",
  "Sliney, William",
  "Slott, Dan",
  "Small, Louis",
  "Smallwood, Greg",
  "Smith, Alanna",
  "Smith, Andy",
  "Smith, Barry",
  "Smith, Barry",
  "Smith, Beau",
  "Smith, Brett",
  "Smith, Brian",
  "Smith, C.P.",
  "Smith, Cam",
  "Smith, Cory",
  "Smith, Dietrich",
  "Smith, Felipe",
  "Smith, J.",
  "Smith, J.d.",
  "Smith, Jd",
  "Smith, Jeff",
  "Smith, John",
  "Smith, Keiren",
  "Smith, Kevin",
  "Smith, Laurie",
  "Smith, Matthew",
  "Smith, Paul",
  "Smith, Sean",
  "SMITH, THOMAS",
  "Smith, Tod",
  "Smith, Todd",
  "Smith, Tom",
  "Sniegoski, Tom",
  "Snyder",
  "Snyder, Ray",
  "SNYDER, SCOTT",
  "So, Arlene",
  "Sobreiro, Felipe",
  "Sodaro, Robert",
  "SODARO, ROBERT",
  "SOLIS, JOSE",
  "Soma, Taki",
  "Somers, Kevin",
  "Sommariva, Jon",
  "Son, Eugene",
  "Sonntag, Ned",
  "Sook, Ryan",
  "Sorrentino, Andrea",
  "Sosa, Marcelo",
  "Soska, Jen",
  "Soska, Sylvia",
  "Sotelo, Beth",
  "Soto",
  "Soto, Julio",
  "Sotocolor",
  "Sotocolor Studio",
  "Sotocolor, J.",
  "Sotomayor, Chris",
  "Sotomayor, Chriscross",
  "Sotomayor, Christopher",
  "Soule, Charles",
  "Sousa, Katie",
  "SOUTCH, SOUTCH",
  "Souza, Allan",
  "Sowd, Aaron",
  "Soy, Dexter",
  "Spannuth, Kai",
  "Sparling, Jack",
  "Spaziante, Patrick",
  "Spears, Rick",
  "Spencer, Nicholas",
  "Spencer, Nick",
  "Spicer, Michael",
  "Spiegle, Carrie",
  "Spiegle, Dan",
  "Spike",
  "Spratt, Samuel",
  "Springer, Frank",
  "Sprouse, Chris",
  "Spurrier, Si",
  "Spurrier, Simon",
  "Spurrier, Simon",
  "SPURRIER, SIMON",
  "Squid",
  "Sr.",
  "Stackpole, Michael",
  "Staggs, Chris",
  "Stallman, Manny",
  "Stallmansp, Manny",
  "Stanisci, John",
  "Stanojevic, Velibor",
  "Stanton, Domo",
  "Staples, Fiona",
  "Staples, Val",
  "Starkings, Richard",
  "Starkings, Richard",
  "Starkings, Richard",
  "Starkings, Richard",
  "StarkinUs, Richard",
  "Starlin, James",
  "Starlin, Jim",
  "Starr, Arne",
  "Starr, Jason",
  "STARR, John",
  "Statema, John",
  "Staton, Joe",
  "Steacey, Ken",
  "Steacy",
  "Steacy, Ken",
  "Steele, Dave",
  "Steen, Rob",
  "Steen, Robert",
  "Stegbauer, Mark",
  "Stegman, Ryan",
  "STEIGERWALD, MICHAEL",
  "Steigerwald, Peter",
  "Steigerweld, Peter",
  "Stein",
  "Stein, Evelyn",
  "Stein, Marvin",
  "Stein, Ro",
  "Stelfreeze, Brian",
  "STENBECK, BEN",
  "Stephens, Jay",
  "Stephenson, Eric",
  "Steranko, Jim",
  "Stern, Roger",
  "Stevens, Chris",
  "Stevens, Christopher",
  "Stevens, Christopher",
  "Stevens, Mike",
  "Stevenson, Noelle",
  "Stevenson, Robert",
  "Stewart, Bhob",
  "Stewart, Cameron",
  "Stewart, Dave",
  "Stewart, David",
  "Stewart, Justin",
  "Stewart, Micahel",
  "Stewart, Michael",
  "STEWART, MICHELLE",
  "Stewart, Mike",
  "Stiles, Steve",
  "Stine, R.L.",
  "Stiner, Ernie",
  "Stock, Allison",
  "Stockbridge, Maxwell",
  "Stockman, Nathan",
  "Stockton, Franklin",
  "Stohl, Margaret",
  "Stokes, John",
  "Stokoe, James",
  "Stone",
  "Stone, Chic",
  "Stonehouse",
  "Story, Karl",
  "story., Stan",
  "Stott, Rachael",
  "Stotz, Rob",
  "Straczynski, J.",
  "Stradley, Randy",
  "Strahm, Kyle",
  "Strain, Christina",
  "Strain, Udon",
  "Strnad, Jan",
  "Strom, Frank",
  "Stroman, Larry",
  "Struzan, Drew",
  "Strychalski, Irene",
  "Stuart, John",
  "Stucker, Larry",
  "Stucker, Lary",
  "Studio, Avalon",
  "Studio, Danny",
  "Studio, Drew",
  "Studio, Laura",
  "Studio, Nova",
  "Studios, Avalon",
  "Studios, Bright",
  "Studios, Crime",
  "Studios, Homage",
  "Studios, Optic",
  "Studios, Udon",
  "Stull, Rob",
  "Stull, Robert",
  "Sturges, Lilah",
  "Sturm, James",
  "Sturm, Phil",
  "SUAYAN, MICHAEL",
  "Suayan, Mico",
  "SUDYAM, ARTHUR",
  "Sudzuka, Goran",
  "SUITOR, JOE",
  "SUITOR, JOSEPH",
  "Sullivan, Lee",
  "Suma, Nova",
  "Sumerak, Marc",
  "Sutfin, Michael",
  "Sutherland, Kev",
  "Sutton, Tom",
  "Suydam, Arthur",
  "SUYDAM, HARRY",
  "Sviuk, Alex",
  "Svorcina, Ive",
  "Swal, Christophe",
  "Swierczynski, Duane",
  "SWIERCZYSNKI, DUANE",
  "Syaf, Ardian",
  "Sykes, Larry",
  "Szenicks, Terry",
  "Szenics, Terry",
  "T, Sadia",
  "Taborda, Walter",
  "Tadeo, Avalon",
  "Tadeo, Ed",
  "Tadeo, Edgar",
  "Tadeu, Hermes",
  "Taggart, Bronwyn",
  "Tai, Sotocolors",
  "Taibo, Rodolfo",
  "Takara, Marcio",
  "Takeda, Sana",
  "Talajic, Dalibor",
  "Talajic, Dalibor",
  "Talaoc",
  "Talaoc, Gerry",
  "Talibao, Harvey",
  "Tam, Alan",
  "Tamaki, Jillian",
  "Tamaki, Mariko",
  "Tan, Billy",
  "Tan, Billy",
  "Tan, Philip",
  "Tanaka, Gracine",
  "Tanghal, Romeo",
  "Tanya",
  "Tapia, Santiago",
  "Tara Butters",
  "Tarragona, Jordi",
  "Tartag, John",
  "Tartaglia, Javier",
  "TARTAGLIA, RAFA",
  "Tartaglione, John",
  "Tartakovsky, Genndy",
  "Taylor, Dave",
  "Taylor, Tom",
  "Tchao, Cedric",
  "Tedesco, Julian",
  "Tedesco, Julian",
  "Temofonte, John",
  "Temofonte, Saida",
  "Templeton, Ty",
  "Tenney, Mark",
  "Tenney, Thomas",
  "Tenney, Tom",
  "Terada, Katsuya",
  "Teran, Frank",
  "Teranishi, Robert",
  "Tercio, Ricardo",
  "Tetelli, Marcos",
  "Texeira, Mark",
  "text, no",
  "The McElroys",
  "The Original Writer",
  "Theakston, Greg",
  "Theroux, Justin",
  "Thibert, Art",
  "Thibodeaux, Mike",
  "Thies, Brian",
  "Thiessen, Bryan",
  "Thomas, Brandon",
  "Thomas, Cathi",
  "Thomas, Dann",
  "Thomas, Frank",
  "Thomas, Jacob",
  "Thomas, Jean",
  "Thomas, Joel",
  "Thomas, John",
  "Thomas, Martin",
  "Thomas, Michael",
  "Thomas, Mike",
  "Thomas, Roy",
  "Thomases, Martha",
  "Thompson, Ben",
  "Thompson, Craig",
  "Thompson, Derek",
  "Thompson, Don",
  "Thompson, Don",
  "Thompson, Jill",
  "Thompson, Jimmy",
  "Thompson, John",
  "Thompson, Kelly",
  "Thompson, Maggie",
  "Thompson, Robbie",
  "Thompson, Robbie",
  "Thompson, Stephen",
  "Thompson, Zac",
  "Thorne, Frank",
  "Thorne, Geoffrey",
  "Thornton, Annie",
  "Thorpe, David",
  "Thorsland, Daniel",
  "Tiemens, Erik",
  "Tieri, Frank",
  "TIKULIN, TOMISLAV",
  "Tim Smith 3",
  "Timm, Bruce",
  "Timmons, John",
  "Timms, John",
  "Tinsley, Kevin",
  "Tischman, David",
  "Title",
  "Titus, Gregory",
  "Titus, Mary",
  "to, Marcus",
  "Tobin, Paul",
  "Tocchini, Greg",
  "Toinay",
  "Tokar, Rob",
  "Tolagson, Jamie",
  "Tolibao, Harvey",
  "Tomasi, Dan",
  "Tomitov, Nel",
  "Tong, Andie",
  "Tooks, Lance",
  "Toppi, Sergio",
  "Tormey, Cian",
  "Torque, Elizabeth",
  "Torrance, Jack",
  "Torre, Roberto",
  "Torre, Roberto",
  "Torres, Angelo",
  "Torres, J.",
  "Torres, Wilfredo",
  "Tortolini, Bill",
  "Torvenius, Axel",
  "Toscano, Frank",
  "TOST, JAMIE",
  "Toth, Alex",
  "Totleben, John",
  "Towe, James",
  "Townsend, Tim",
  "Trapani, Sal",
  "Trapani, Sal",
  "Trevino, Raul",
  "Tribe, The",
  "Trimpe, Alex",
  "Trinidad, Sonny",
  "Tripp, Kanila",
  "Trodglen, Dugan",
  "Tron, Studio",
  "Troy, Andrew",
  "Troy, Andy",
  "Truman, Benjamin",
  "Truman, Tim",
  "Truman, Timothy",
  "Tsai, Francis",
  "Tsang, Angelo",
  "Tsang, Arnold",
  "Tsang, Udon",
  "Tsuda, Chris",
  "Tucci, Billy",
  "Tumlinson, Pete",
  "Tuohy, Tim",
  "Tuohy, Timothy",
  "Turcotte, Chris",
  "Turini, Marco",
  "Turnbull, Koi",
  "Turner, Dwayne",
  "Turner, Frank",
  "Turner, Michael",
  "Tuska, George",
  "Tutrone, Paul",
  "Tw",
  "Tyler, Rurik",
  "Tynion, James",
  "type, Beth",
  "Uncredited, Pages",
  "Underwood, Ashley",
  "Underwood, Lebeau",
  "Ungar, Chris",
  "Unzueta, Angel",
  "Upchurch, Michelle",
  "Urbano, Carlos",
  "Urusov, Alina",
  "Uslan, Michael",
  "Uy, Steve",
  "V, Ram",
  "Vakueva, Nina",
  "Valadez, Daniel",
  "Vale, Peter",
  "Valentino, Jim",
  "Validar, Ron",
  "Valkenburgh, Sheri",
  "Valkenburgh, Sherilyn",
  "Vallejo, Boris",
  "Valleta, Giovanni",
  "Valve, Rex",
  "Van Lente, Fred",
  "Van Meter, Jennifer",
  "Vancata, Brad",
  "Vance, William",
  "Vandal, Stuart",
  "Vansant, Wayne",
  "VARANDA, ALBERTO",
  "Various",
  "Various, Various",
  "Varley, Lynn",
  "Varnado, Victor",
  "Vartanoff, Ellen",
  "Vartanoff, Irene",
  "Vasquez, Gloria",
  "VASQUEZ, JOHONEN",
  "Vassallo, Dr.",
  "Vatine, Olivier",
  "Vaughan, Brian",
  "Vazquez, Gus",
  "Vazquez, Joey",
  "VCY",
  "Vecchia, Christian",
  "Vecchio, Luciano",
  "Vedder, Eric",
  "Veitch, Rick",
  "Velarde, Julian",
  "Velasco, Francisco",
  "VELASCO, JUAN",
  "Velez Jr., Ivan",
  "Velez, Ivan",
  "Vella, Sheldon",
  "Velluto, Sal",
  "Vendetti, Robert",
  "Veregge, Jefferey",
  "Veregge, Jeffrey",
  "Verma, Dheeraj",
  "Verpoorten, John",
  "Vess, Charles",
  "VESS, LUKE",
  "Vey, Al",
  "Via, Courtney",
  "Vicente, Muntsa",
  "Vicentini, Federico",
  "Vieceli, Emma",
  "Vilanova, Guiu",
  "Villa, Carlos",
  "VILLA, CLAUDIO",
  "Villagran, Ricardo",
  "Villagrasa, Vincent",
  "Villalobos, Ramon",
  "Villamonte, Ricardo",
  "Villanelli, Paolo",
  "Villanueva, Juan",
  "Villari, Laura",
  "Villarrubia, Jose",
  "Vince, Nicholas",
  "Vincent",
  "Vincent, Tom",
  "Vincent, Wright",
  "Vines, Dexter",
  "Viola, Charles",
  "Virella, Nicole",
  "Virella, Nik",
  "Virkaitis, Tom",
  "Visaggio, Magdelene",
  "Visions, Chris",
  "Visual Development Team, Marvel Studios",
  "Vito, Andrea",
  "Vitti, Alessandro",
  "Vladimer, Denise",
  "Vlasco, Juan",
  "Vlm",
  "Vohland, Duffy",
  "Vokes, Neil",
  "Von Eeden, Trevor",
  "von Eedon, Trevor",
  "Vonallman, David",
  "Vosburg, Mike",
  "Vozzo, Daniel",
  "Vrattos, Stephen",
  "W, B",
  "W. Haden Blackman",
  "Wacker, Stephen",
  "Wacker, Stephen",
  "Wada, Kevin",
  "Wada, Kevin",
  "Wagner, Edde",
  "Wagner, John",
  "Wagner, Matt",
  "Wagner, Ron",
  "Waid, Mark",
  "Waite, Matthew",
  "Wakelin, Bob",
  "Wald, Alex",
  "Walker, Brad",
  "Walker, Chris",
  "Walker, Cory",
  "Walker, David",
  "Walker, David",
  "WALKER, DAVID",
  "Walker, Kev",
  "Walker, Kevin",
  "Walker, Robert",
  "Walker, Tigh",
  "Walkuski, Wieslaw",
  "Wallace, Charles",
  "Wallenta, Adam",
  "Waller, Karl",
  "Walsh, Brian",
  "Walsh, Michael",
  "Walta, Gabriel",
  "Walta, Gabriel",
  "Walter, Niko",
  "Walters, J.",
  "Walton",
  "Walton, Bill",
  "Wang, Chia",
  "Wang, Chia-​Chi",
  "Wantanabe, Irving",
  "Waranowitz, Abe",
  "Ward, Christian",
  "Ward, Christian",
  "Warfield, Don",
  "Warner, Chris",
  "Warner, John",
  "Warren, Adam",
  "Warren, Emily",
  "Washington, Anthony",
  "Washington, Tony",
  "Wasser, Kevin",
  "Watanabe, Irving",
  "Watson, Andi",
  "Watson, John",
  "Watson, Polly",
  "Way, Daniel",
  "Way, Gerard",
  "Weaver, Dustin",
  "Webb, Matt",
  "Weeks, Lee",
  "Weems, Joe",
  "Weems, Top",
  "Wegener, Scott",
  "Wegrzyn, Tom",
  "Wein, Glynis",
  "Wein, Len",
  "Weinberg, Robert",
  "Weir, Christina",
  "Weisman, Greg",
  "Weiss, Alan",
  "Weiss, Stacy",
  "Welch, Larry",
  "Wellington, David",
  "Wellington, John",
  "Wells, Caroline",
  "Wells, Zeb",
  "Wendig, Chuck",
  "Wendling, Claire",
  "Wenzel, Al",
  "Wenzel, David",
  "Werneck, Lucas",
  "Wesley, Irv",
  "Wessler, Carl",
  "West, Kevin",
  "Weston, Chris",
  "Weston, Christopher",
  "Wetherell, Art",
  "Whalen, Tom.",
  "Wheatley, Doug",
  "Wheatley, Mark",
  "Whedon, Joss",
  "WHEELER, SHANNON",
  "Whigham, Rod",
  "Whitaker, Stephen",
  "Whitaker, Steven",
  "White",
  "White, Caanan",
  "White, Dean",
  "White, Dean",
  "White, Jason",
  "White, Jordan",
  "White, Shane",
  "Whitherby, Mike",
  "Whithouse, Toby",
  "Whitley, Jeremy",
  "Whitney, Odgen",
  "Whitta, Gary",
  "Whyte, Ron",
  "Wiacek, Bob",
  "Wicks, Maris",
  "Wieringo, Mike",
  "Wiesenfeld, Aron",
  "Wiessmuller, Michael",
  "Wight, Eric",
  "Wijaya, Admira",
  "Wijngaard, Caspar",
  "Wilcox, Harrison",
  "Wilcox, John",
  "Wilde, Oscar",
  "Wildey, Doug",
  "Wildman, Andrew",
  "Wildman, George",
  "Wiley, Nicole",
  "Wilford, Bonnie",
  "Wiliams, Vick",
  "Wilkins, Dave",
  "Wilkinson, Kerry",
  "Willaims, Freddie",
  "William Messner-Loebs",
  "Williams",
  "Williams, Anthony",
  "Williams, Brittney",
  "Williams, Brittney",
  "WILLIAMS, BRITTNEY L.",
  "WILLIAMS, CHRISTOPHER",
  "Williams, Dave",
  "Williams, David",
  "Williams, David",
  "Williams, J.H.",
  "Williams, Keith",
  "Williams, Kent",
  "Williams, Leah",
  "Williams, Louis",
  "Williams, Rob",
  "Williams, Scott",
  "Williams, Vickie",
  "Williamson, Al",
  "Williamson, Brian",
  "Williamson, Josh",
  "Williamson, Joshua",
  "Willingham, Bill",
  "Willumsen, Connor",
  "Wilshire, Mary",
  "Wilson, Charles",
  "Wilson, Colin",
  "Wilson, G.",
  "Wilson, Gavin",
  "Wilson, Matthew",
  "Wilson, Megan",
  "Wilson, Ron",
  "Wilsons",
  "Wiltfong, Dave",
  "Wiltfong, David",
  "Wimberly, Ron",
  "Win, Ed",
  "Windham, Ryder",
  "Windsor-​Smith, Barry",
  "Windsor-Smith, Barry",
  "WindsorSmith, BW",
  "WindsorSmith, David",
  "Winiarski, Ed",
  "Winick, Judd",
  "Winn, Anthony",
  "WINN, CHARLIE",
  "Winn, Ryan",
  "Winslade, Phil",
  "Winslow, Stockbridge",
  "Wisneski, Kathleen",
  "Witherby, Mike",
  "Witter, Ashley",
  "Witterstaetter, Renee",
  "Wittert, Max",
  "Wohl, David",
  "Wohl, Denise",
  "Wohl, Denise",
  "Wojtkiewicz, Chuck",
  "Wojtkoski, Charles",
  "Wolfman, Marv",
  "Wolfman, Michele",
  "Wong",
  "Wong, Alyssa",
  "Wong, Howard",
  "Wong, Justice",
  "Wong, W.",
  "Wong, Walden",
  "Wong, Wesley",
  "Wood, Ashley",
  "Wood, Bob",
  "Wood, Brian",
  "Wood, Sam",
  "WOOD, WALLACE",
  "Wood, Wally",
  "Woodard, Nolan",
  "Woodbridge, George",
  "Woodring, Jim",
  "Woods, P.",
  "Woods, Pete",
  "Woods, Peter",
  "Woodward, J.K.",
  "Wooton, Rus",
  "Wooton, Vc",
  "Workman, John",
  "Workman, John",
  "Worley, Michael",
  "Wormer, Kirk",
  "Wozniak, Chris",
  "Wray, Bill",
  "Wright, Greg",
  "Wright, Gregory",
  "Wright, Jason",
  "Wrightson, Bernie",
  "Wrightson, Michelle",
  "Writer, Original",
  "Wu, Annie",
  "Wyatt, Chris",
  "Wyatt, Doc",
  "Wyatt, Jacob",
  "Wyatt, Jake",
  "Wycough, John",
  "Wylie, Bill",
  "Wyman, M.",
  "Wyman, M.C.",
  "Wynne, Jodi",
  "Xermanico, Xermanico",
  "Y., Nellie",
  "Yackey, Matt",
  "Yackey, Matt",
  "Yaep, Chap",
  "Yanagi, Akihide",
  "Yanchus, Andy",
  "Yandoc, Ruben",
  "YANG, GENE",
  "Yaniger, Derek",
  "Yanizeski, Rich",
  "Yap, Guang",
  "Yardin, David",
  "Yates",
  "Yates, Kevin",
  "Yau, Mok",
  "Yeadon, Clyde",
  "Yeates, Thomas",
  "Yee, Michael",
  "Yeowell, Steve",
  "Yeung, Craig",
  "Yildirim, Ozgur",
  "Yocum, Matt",
  "Yomtov, Nel",
  "Yoon, Jung-​Geun",
  "Yoon, Jung-Geun",
  "York, Jeffrey",
  "York, Jeph",
  "YORK, JEPH",
  "Yoshida, Akira",
  "Yoshii, Lynne",
  "Yost, Chris",
  "Yost, Christopher",
  "Young, Bryan",
  "Young, Skottie",
  "Youngquist, Jeff",
  "Yu, Leinil",
  "Yug",
  "Yurkovich, David",
  "Z, Christina",
  "Z, Task",
  "Zaffino, Gerardo",
  "Zaffino, Jorge",
  "Zagari, Michael",
  "Zahn, Timothy",
  "Zalben, Alex",
  "Zalme, Ron",
  "Zanfardino, Iolanda",
  "Zangari, Santa",
  "Zayas, Rodrigo",
  "Zdarsky, Chip",
  "Zeck, Michael",
  "Zeck, Mike",
  "Zelenetz, Alan",
  "Zezelj, Danijel",
  "Zhu, Feng",
  "Zick, Bruce",
  "Zimmerman, Dwight",
  "Zimmerman, Ron",
  "Zinsmeister, Karl",
  "Ziuko, Tom",
  "Zombo, Rick",
  "Zonjic, Tonci",
  "Zub, Jim",
  "Zubkavich, Jim",
  "Zulli, Michael",
  "Zulueta, Joel",
]
