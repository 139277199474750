import { Component, OnInit } from "@angular/core";
import {
  trigger,
  transition,
  animate,
  style,
  state,
} from "@angular/animations";
import { Store } from "@ngrx/store";
import { User } from "src/app/models/user";
import { addItemToCart } from "../../redux/actions/cart.actions";
import { Observable, Subject } from "rxjs";
import { EditComicDialogComponent } from "src/app/dialogs/edit-comic-dialog/edit-comic-dialog.component";
import { MatDialog, MatSnackBar } from "@angular/material";
import { Router } from "@angular/router";
import { ComicService } from "src/app/services/comic.service";
import {
  selectedComic,
  setAllComics,
} from "src/app/redux/actions/comic.actions";
import { setRoute } from "src/app/redux/actions/router.actions";
import { takeUntil } from "rxjs/operators";
import { SellSimilarComicDialogComponent } from "src/app/dialogs/sell-similar-comic-dialog/sell-similar-comic-dialog.component";
import { AddWishlistComicDialogComponent } from "src/app/dialogs/add-wishlist-comic-dialog/add-wishlist-comic-dialog.component";
import { AuthService } from "src/app/services/auth.service";

interface AppState {
  comics: any;
  userProfile: User;
  authUser: User;
  cart: any;
  auth: any;
}

@Component({
  selector: "app-view-comic",
  templateUrl: "./view-comic.component.html",
  styleUrls: ["./view-comic.component.scss"],
  animations: [
    trigger("fade", [
      transition("void => *", [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        state("invisible", style({ opacity: 0 })),
        style({ opacity: 0 }),
        animate(800, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ViewComicComponent implements OnInit {
  public user: Observable<User>;
  public authUser;
  public authId;
  comic;
  public allComics;
  public allPublisher;
  public filteredComics;
  public filteredPublisher;
  public noContent;
  public otherSellers;
  public noSellers;
  public noPublisher;
  private onDestroy$ = new Subject<void>();
  public isLoggedInWithStripe: boolean;

  public loading = false;

  public selectedImage: any;
  public comicPrice;

  cart: any;
  shippingInfo: any;

  constructor(
    private store: Store<AppState>,
    public comicService: ComicService,
    private router: Router,
    private mdDialog: MatDialog,
    private matSnackbar: MatSnackBar,
    public auth: AuthService
  ) {
    this.store
      .select("comics")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        this.comic = res.selectedComic;
        this.allComics = res;
        this.allPublisher = res;
      });
    this.store
      .select("cart")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        this.cart = res;
        let total = 0;
        for (const data of this.cart) {
          total += data.shippingCost;
        }
        this.shippingInfo = total.toFixed(2);
      });
    this.user = this.store.select("userProfile");
    this.store.select("auth").subscribe((res) => {
      if (res) {
        this.authUser = res;
        this.authId = res.id;
      }
    });
    if (!this.authUser) {
      this.authUser = "GuestUser";
      this.isLoggedInWithStripe = false;
    }
    if (this.authUser !== "GuestUser") {
      this.isLoggedInWithStripe =
        this.authUser && this.authUser.stripeId ? true : false;
    }
  }

  ngOnInit() {
    this.loadPage();
    this.selectedImage = this.comic.images[0];
  }

  refresh() {
    this.updatePage();
  }

  loadPage() {
    this.loading = true;
    this.store.dispatch(setRoute({ route: this.router.url }));
    this.noSellers = false;
    if (
      this.allComics.comics.length === 0 ||
      this.allPublisher.comics.length === 0
    ) {
      this.noPublisher = true;
    }
    // This checks to see if any other current users is selling a comic with same exact title.
    this.otherSellers = this.shuffle(
      this.allComics.comics.filter((comic) => {
        return (
          comic.title.toString().trim().toLowerCase() ===
            this.comic.title.toString().trim().toLowerCase() &&
          comic.id !== this.comic.id &&
          comic.sell === true &&
          comic.quantity > 0
        );
      })
    );
    // If there is no match of title we move into the first part of the if statement
    if (this.otherSellers.length === 0) {
      this.noSellers = true;
    }
    this.filteredComics = this.shuffle(
      this.allComics.comics.filter((v, i, a) => {
        // This function pulls all Comics in the same Universe as the selectedComic
        // If there are ANY FORM OF duplicate titles. DO NOT SHOW ANY DUPLICATES.
        return (
          a.findIndex(
            (t) =>
              t.title.toString().trim().toLowerCase() ===
                v.title.toString().trim().toLowerCase() &&
              t.title.toString().trim().toLowerCase() !==
                this.comic.title.toString().trim().toLowerCase() &&
              t.universe.toString().trim().toLowerCase() ===
                this.comic.universe.toString().trim().toLowerCase() &&
              // t.ownerId.toString().trim().toLowerCase() !==
              //   this.authId.toString().trim().toLowerCase() &&
              t.id !== this.comic.id
          ) === i
        );
      })
    );
    this.filteredPublisher = this.shuffle(
      this.allPublisher.comics.filter((v, i, a) => {
        // This function pulls all Comics with an identical publisher as the selectedComic.
        // If there are ANY FORM OF duplicate titles. DO NOT SHOW ANY DUPLICATES.
        return (
          a.findIndex(
            (t) =>
              t.title.toString().trim().toLowerCase() ===
                v.title.toString().trim().toLowerCase() &&
              t.title.toString().trim().toLowerCase() !==
                this.comic.title.toString().trim().toLowerCase() &&
              t.publisher.toString().trim().toLowerCase() ===
                this.comic.publisher.toString().trim().toLowerCase() &&
              t.universe.toString().trim().toLowerCase() !==
                this.comic.universe.toString().trim().toLowerCase() &&
              // t.ownerId.toString().trim().toLowerCase() !==
              //   this.authId.toString().trim().toLowerCase() &&
              // t.sell === true &&
              // t.quantity > 0 &&
              t.id !== this.comic.id
          ) === i
        );
      })
    );
    if (this.filteredPublisher.length === 0) {
      this.noPublisher = true;
    }

    this.noContent = this.shuffle(
      this.allComics.comics.filter((v, i, a) => {
        // This function pulls all Comics with an identical publisher as the selectedComic.
        // If there are ANY FORM OF duplicate titles. DO NOT SHOW ANY DUPLICATES.
        return (
          a.findIndex(
            (t) =>
              t.title.toString().trim().toLowerCase() ===
                v.title.toString().trim().toLowerCase() &&
              t.title.toString().trim().toLowerCase() !==
                this.comic.title.toString().trim().toLowerCase() &&
              t.id !== this.comic.id
          ) === i
        );
      })
    );
    window.scrollTo(0, 0);
    this.loading = false;
  }

  updatePage() {
    this.loading = true;
    this.gotoTop();
    this.store.dispatch(setRoute({ route: this.router.url }));
    this.noSellers = false;
    this.store.dispatch(setAllComics({ comics: this.allComics.comics }));
    if (
      this.allComics.comics.length === 0 ||
      this.allPublisher.comics.length === 0
    ) {
      this.noPublisher = true;
    }
    // This checks to see if any other current users is selling a comic with same exact title.
    this.otherSellers = this.shuffle(
      this.allComics.comics.filter((comic) => {
        return (
          comic.title.toString().trim().toLowerCase() ===
            this.comic.title.toString().trim().toLowerCase() &&
          comic.id !== this.comic.id &&
          comic.sell === true &&
          comic.quantity > 0
        );
      })
    );
    // If there is no match of title we move into the first part of the if statement
    if (this.otherSellers.length === 0) {
      this.noSellers = true;
    }
    this.filteredComics = this.shuffle(
      this.allComics.comics.filter((v, i, a) => {
        // This function pulls all Comics in the same Universe as the selectedComic
        // If there are ANY FORM OF duplicate titles. DO NOT SHOW ANY DUPLICATES.
        return (
          a.findIndex(
            (t) =>
              t.title.toString().trim().toLowerCase() ===
                v.title.toString().trim().toLowerCase() &&
              t.title.toString().trim().toLowerCase() !==
                this.comic.title.toString().trim().toLowerCase() &&
              t.universe.toString().trim().toLowerCase() ===
                this.comic.universe.toString().trim().toLowerCase() &&
              // t.ownerId.toString().trim().toLowerCase() !==
              //   this.authId.toString().trim().toLowerCase() &&
              t.id !== this.comic.id
          ) === i
        );
      })
    );
    this.filteredPublisher = this.shuffle(
      this.allPublisher.comics.filter((v, i, a) => {
        // This function pulls all Comics with an identical publisher as the selectedComic.
        // If there are ANY FORM OF duplicate titles. DO NOT SHOW ANY DUPLICATES.
        return (
          a.findIndex(
            (t) =>
              t.title.toString().trim().toLowerCase() ===
                v.title.toString().trim().toLowerCase() &&
              t.title.toString().trim().toLowerCase() !==
                this.comic.title.toString().trim().toLowerCase() &&
              t.publisher.toString().trim().toLowerCase() ===
                this.comic.publisher.toString().trim().toLowerCase() &&
              t.universe.toString().trim().toLowerCase() !==
                this.comic.universe.toString().trim().toLowerCase() &&
              // t.ownerId.toString().trim().toLowerCase() !==
              //   this.authId.toString().trim().toLowerCase() &&
              // t.sell === true &&
              // t.quantity > 0 &&
              t.id !== this.comic.id
          ) === i
        );
      })
    );
    if (this.filteredPublisher.length === 0) {
      this.noPublisher = true;
    }

    this.noContent = this.shuffle(
      this.allComics.comics.filter((v, i, a) => {
        // This function pulls all Comics with an identical publisher as the selectedComic.
        // If there are ANY FORM OF duplicate titles. DO NOT SHOW ANY DUPLICATES.
        return (
          a.findIndex(
            (t) =>
              t.title.toString().trim().toLowerCase() ===
                v.title.toString().trim().toLowerCase() &&
              t.title.toString().trim().toLowerCase() !==
                this.comic.title.toString().trim().toLowerCase() &&
              t.id !== this.comic.id
          ) === i
        );
      })
    );
    window.scrollTo(0, 0);
    this.loading = false;
  }

  async goToUsersProfile(selectedUser) {
    this.router.navigateByUrl(`/profile/${selectedUser.username}`);
  }

  async goToMyProfile() {
    await this.authUser.pipe(takeUntil(this.onDestroy$)).subscribe((user) => {
      this.router.navigateByUrl(`/profile/${user.username}`);
    });
  }

  shuffle(list) {
    return list.reduce((p, n) => {
      const size = p.length;
      const index = Math.trunc(Math.random() * (size - 1));
      p.splice(index, 0, n);
      return p;
    }, []);
  }

  submit() {
    this.store.dispatch(
      addItemToCart({ product: this.comic, shipping: this.shippingInfo })
    );
    this.matSnackbar.open(
      `You added "${this.comic.title}" to your cart`,
      "OK",
      {
        duration: 5000,
        panelClass: ["cvSnack"],
      }
    );
  }

  goToViewProduct(comic) {
    this.handleSelectedImage(comic.images[0]);
    this.store.dispatch(selectedComic({ id: comic.id }));
    this.gotoTop();
    this.refresh();
  }

  handleSelectedImage = (image) => {
    this.selectedImage = image;
  };

  goToSellSimilarComics() {
    const dialogRef = this.mdDialog.open(SellSimilarComicDialogComponent, {
      data: this.comic,
      autoFocus: false,
      panelClass: "custom-dialog-container",
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {});
  }

  goToWishlistComics() {
    const dialogRef = this.mdDialog.open(AddWishlistComicDialogComponent, {
      data: this.comic,
      autoFocus: false,
      panelClass: "custom-dialog-container",
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {});
  }

  gotoTop() {
    document.getElementById("master-container").scrollIntoView();
  }
}
