export const addMessage = (threads, message, selectedThread) =>
  threads.map(thread => {
    if (thread.id === message.threadId || thread.id === 0) {
      const res = {
        ...thread,
        id: message.threadId,
        messages: [...thread.messages, message]
      };
      return res;
    }

    return thread;
  });

export const addThread = (threads, thread) => {
  return [...threads, thread];
};
