import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';
import { FormBuilder } from '@angular/forms';
import { CollectibleService } from 'src/app/services/collectible.service';
import { User } from 'src/app/models/user';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { takeUntil } from 'rxjs/operators';
import { removeImage } from '../../redux/actions/collecible.actions';
import { STRIPEID } from '../../../environments/environment.prod';
import { comicCondition } from 'src/app/constants/comicCondition.constant';
import { ConfirmProductPictureDialogComponent } from '../confirm-product-picture-dialog/confirm-product-picture-dialog.component';

interface AppState {
  auth: User;
}

@Component({
  selector: 'app-edit-collectible-dialog',
  templateUrl: './edit-collectible-dialog.component.html',
  styleUrls: ['./edit-collectible-dialog.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(250, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        state('invisible', style({ opacity: 1 })),
        style({ opacity: 0 }),
        animate(250, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class EditCollectibleDialogComponent implements OnInit {
  public user: Observable<User>;
  public id;
  public currentAuthUser;

  public universe: string;
  public name: string;
  public brand: string;
  public series: string;
  public year: string;
  public upc: string;
  public buildFigure: string;
  public variation: string;
  public accessories: string;
  public condition;
  public file;
  public files;

  public universeError: string;
  public nameError: string;
  public brandError: string;
  public conditionError: string;
  public priceError: string;
  public quantityError: string;

  public loading = false;

  public selling;
  public collection;
  public trade;
  public wishlist;

  public price: any;
  public quantity: string;

  public isBuildFigure;
  public hasVariation;
  public hasAccessories;
  public hasUpc;
  public selectedImage: any;

  public collectibleCondition;

  public config;
  public usersCut;
  public collectaverseCut;

  private onDestroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditCollectibleDialogComponent>,
    public auth: AuthService,
    public form: FormBuilder,
    private store: Store<AppState>,
    public collectibleService: CollectibleService,
    private matSnackbar: MatSnackBar) {
    this.store.select('auth').subscribe(res => (this.currentAuthUser = res));
  }

  ngOnInit() {
    this.config = STRIPEID;
    this.selling = this.data.sell;
    this.collection = this.data.collection;
    this.trade = this.data.trade;
    if (this.data.wishlist === null) {
      this.wishlist = false;
    } else {
      this.wishlist = this.data.wishlist;
    }
    this.isBuildFigure = this.data.buildFigure;
    this.hasVariation = this.data.variation;
    this.hasAccessories = this.data.accessories;
    this.hasUpc = this.data.upc;
    this.condition = this.data.condition;
    this.selectedImage = this.data.images[0];
    this.collectibleCondition = comicCondition;
  }

  handleSelectedImage = image => {
    this.selectedImage = image;
  }

  /* || FORM CONTROL || */

  handleUniverse(val) {
    this.universeError = this.validate(val);
    if (this.universeError) {
      return this.universe = '';
    }
    this.universe = val;
  }

  handleName(val) {
    this.nameError = this.validate(val);
    if (this.nameError) {
      return this.name = '';
    }
    this.name = val;
  }

  handleBrand(val) {
    this.brandError = this.validate(val);
    if (this.brandError) {
      return this.brand = '';
    }
    this.brand = val;
  }

  handleSeries(val) {
    this.series = val;
  }

  handleYear(val) {
    this.year = val;
  }

  handleUpc(val) {
    this.upc = val;
  }

  handleBuildFigure(val) {
    this.buildFigure = val;
  }

  handleVariation(val) {
    this.variation = val;
  }

  handleAccessories(val) {
    this.accessories = val;
  }

  handleCondition(val) {
    this.condition = val;
  }

  handleFile(val) {
    this.file = val;
  }

  handlePriceQuantity(evt, name) {
    const error = `${name}Error`;
    this[error] = this.validate(evt);

    if (this[error]) {
      return (this[name] = null);
    }
    this[name] = evt;
    if (name === 'price') {
      const x = (3.5 / 100) * this.price;
      this.collectaverseCut = x.toFixed(2);
      this.usersCut = this.price - this.collectaverseCut;
    }
  }

  /* || INPUT VALIDATION || */

  validate(legit: any): any {
    if (!legit) {
      return 'Required';
    }
    return '';
  }

  /* || END INPUT VALIDATION || */

  close() {
    this.dialogRef.close();
  }

  closeAndUpdate(evt) {
    this.dialogRef.close(evt);
  }

  deleteImage(id) {
    this.collectibleService.deleteImage(id).subscribe(async (res: any) => {
      if (res && !res.err) {
        const payload = {
          fileId: id,
          collectibleId: this.data.id
        };
        await this.store.dispatch(removeImage({ payload }));
        if (this.selectedImage.id === id) { this.selectedImage = this.data.images[0]; }
      }
    });
  }

  goToConfirmDelete(id) {
    const dialogRef = this.dialog.open(ConfirmProductPictureDialogComponent, {
      data: {
        picture: id,
        product: this.data
      },
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      disableClose: true
    });
    dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe(result => {
      if (result !== undefined) {
        this.deleteImage(result);
      }
    });
  }

  async submit() {
    this.id = this.data.id;
    this.loading = true;
    await this.collectibleService.updateCollectible(
      this.id,
      this.universe,
      this.name,
      this.brand,
      this.series,
      this.year,
      this.upc,
      this.buildFigure,
      this.variation,
      this.accessories,
      this.condition,
      this.selling,
      this.trade,
      this.wishlist,
      this.collection,
      this.price,
      this.file,
      this.quantity).pipe(takeUntil(this.onDestroy$)).subscribe(res => {
        this.matSnackbar.open(
          `You successfully updated the listing information on your Collectible "${this.data.name}"`,
          'OK',
          {
            duration: 5000,
            panelClass: ['cvSnack']
          }
        );
        this.closeAndUpdate(res);
      });
  }

}
