import { Action } from "@ngrx/store";

export function productReducer(state: {}, action: Action) {
  const results: any = action;
  switch (action.type) {
    case "SET_PRODUCT":
      return { product: results.product };
    default:
      return state;
  }
}
