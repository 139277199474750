import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { setRoute } from 'src/app/redux/actions/router.actions';
interface AppState {
  auth: any;
}


@Component({
  selector: 'app-browse-only-comics',
  templateUrl: './browse-only-comics.component.html',
  styleUrls: ['./browse-only-comics.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(800, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        state('invisible', style({ opacity: 0 })),
        style({ opacity: 0 }),
        animate(800, style({ opacity: 0 }))
      ])
    ]),
  ],
})
export class BrowseOnlyComicsComponent implements OnInit {

  constructor(
    private store: Store<AppState>,
    private router: Router
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.store.dispatch(setRoute({ route: this.router.url }));
  }
}
