import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {
  MatTableDataSource,
  MatPaginator,
  MatSort,
  MatDialog
} from '@angular/material';
import { ViewProductDialogComponent } from 'src/app/dialogs/view-product-dialog/view-product-dialog.component';
import { ComicService } from 'src/app/services/comic.service';
import {
  trigger,
  transition,
  style,
  animate,
  state
} from '@angular/animations';
import { User } from 'src/app/models/user';
import { Observable, Subject, merge } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  setAllComics,
  selectedComic
} from 'src/app/redux/actions/comic.actions';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { goToUserProfile } from 'src/app/redux/actions/user.actions';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { setProduct } from 'src/app/redux/actions/product.actions';

export interface ComicsFilter {
  universe: string;
  series: string;
  title: string;
  publisher: string;
  issue: string;
  sell: string;
  trade: string;
  collection: string;
  wishlist: string;
}

interface AppState {
  comics: any;
  userProfile: User;
  authUser: User;
  product: any;
}

@Component({
  selector: 'app-browse-comics',
  templateUrl: './browse-comics.component.html',
  styleUrls: ['./browse-comics.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(800, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        state('invisible', style({ opacity: 0 })),
        style({ opacity: 0 }),
        animate(800, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class BrowseComicsComponent implements OnInit {
  public user: Observable<User>;
  public authUser: Observable<User>;

  public loading = false;

  public isCollapsed = true;
  public allComics;
  public hideDuplicates;
  public filteredComics;
  public noComics = false;

  globalSearch: FormGroup;
  table: FormGroup;
  dataSource = new MatTableDataSource();
  allFilter = new FormControl('');
  universeFilter = new FormControl('');
  seriesFilter = new FormControl('');
  titleFilter = new FormControl('');
  publisherFilter = new FormControl('');
  issueFilter = new FormControl('');
  sellFilter = new FormControl('');
  tradeFilter = new FormControl('');
  collectionFilter = new FormControl('');
  wishlistFilter = new FormControl('');

  filterValues = {
    universe: '',
    series: '',
    title: '',
    publisher: '',
    issue: '',
    sell: '',
    trade: '',
    collection: '',
    wishlist: ''
  };
  test;
  globalFilter = '';
  private onDestroy$ = new Subject<void>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public comicService: ComicService,
    private mdDialog: MatDialog,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.table = fb.group({
      universe: '',
      series: '',
      title: '',
      publisher: '',
      issue: '',
      sell: '',
      trade: '',
      collection: '',
      wishlist: ''
    });
    this.globalSearch = fb.group({
      masterSearch: ''
    });
  }

  form: FormGroup = new FormGroup({
    universe: new FormControl(false),
    series: new FormControl(false),
    title: new FormControl(false),
    publisher: new FormControl(false),
    issue: new FormControl(false),
    sell: new FormControl(false),
    trade: new FormControl(false),
    collection: new FormControl(false),
    wishlist: new FormControl(false)
  });

  universe = this.form.get('universe');
  series = this.form.get('series');
  title = this.form.get('title');
  publisher = this.form.get('publisher');
  issue = this.form.get('issue');
  sell = this.form.get('sell');
  trade = this.form.get('trade');
  collection = this.form.get('collection');
  wishlist = this.form.get('wishlist');

  cbValues;

  displayedColumns = [
    { def: 'imageUrl', label: 'Images', hide: '' },
    { def: 'universe', label: 'Universe', hide: this.universe.value },
    { def: 'series', label: 'Series', hide: this.series.value },
    { def: 'title', label: 'Title', hide: this.title.value },
    { def: 'publisher', label: 'Publisher', hide: this.publisher.value },
    { def: 'issue', label: 'Issue', hide: this.issue.value },
    { def: 'sell', label: 'sell', hide: this.sell.value },
    { def: 'trade', label: 'trade', hide: this.trade.value },
    { def: 'collection', label: 'collection', hide: this.collection.value },
    { def: 'wishlist', label: 'wishlist', hide: this.wishlist.value }
  ];

  getDisplayedColumns(): string[] {
    return this.displayedColumns.filter(cd => !cd.hide).map(cd => cd.def);
  }

  ngOnInit() {
    // this.store.dispatch(setRoute({ route: '/browseComics' }));
    this.loading = true;
    this.displayedColumns[5].hide = true;
    this.displayedColumns[6].hide = true;
    this.displayedColumns[7].hide = true;
    this.displayedColumns[8].hide = true;
    this.displayedColumns[9].hide = true;
    if (window.screen.width < 450) { // 768px portrait
      this.displayedColumns[1].hide = true;
      this.displayedColumns[2].hide = true;
      this.displayedColumns[4].hide = true;
      this.displayedColumns[5].hide = true;
    }
    if (window.screen.width === 768) { // 768px portrait
      this.displayedColumns[1].hide = true;
      this.displayedColumns[4].hide = true;
      this.displayedColumns[5].hide = true;
    }
    if (window.screen.width === 1024 && window.screen.height === 1366) {
      this.displayedColumns[1].hide = true;
      this.displayedColumns[4].hide = true;
      this.displayedColumns[5].hide = true;
    }

    if (window.screen.width === 810 && window.screen.height === 1080) {
      this.displayedColumns[1].hide = true;
      this.displayedColumns[4].hide = true;
      this.displayedColumns[5].hide = true;
    }
    this.comicService.getAllComics().pipe(takeUntil(this.onDestroy$)).subscribe(async res => {
      this.allComics = res;
      this.hideDuplicates = res;
      this.allComics.comics.filter(removeNull => {
        if (removeNull.wishlist === null) {
          return removeNull.wishlist = false;
        }
      });
      if (this.allComics.length === 0) {
        this.noComics = true;
      }
      this.dataSource.data = this.shuffle(
        this.allComics.comics.filter((v, i, a) => {
          if (v.sell === true) {
            return v.quantity > 0;
          }
          this.loading = false;
          return (
            a.findIndex(t => (
              t.title.toString().trim().toLowerCase() === v.title.toString().trim().toLowerCase()
            )) === i);
        })
      );
      this.store.dispatch(setAllComics({ comics: this.dataSource.data }));
    });

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.filterRefresh();
  }

  filterRefresh() {
    this.universeFilter.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        universe => {
          this.filterValues.universe = universe;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        }
      );
    this.seriesFilter.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        series => {
          this.filterValues.series = series;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        }
      );
    this.titleFilter.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        title => {
          this.filterValues.title = title;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        }
      );
    this.publisherFilter.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        publisher => {
          this.filterValues.publisher = publisher;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        }
      );
    this.issueFilter.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        issue => {
          this.filterValues.issue = issue;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        }
      );
    this.sellFilter.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        sell => {
          if (sell === false) {
            this.filterValues.sell = '';
          } else {
            this.filterValues.sell = sell.toString();
          }
          this.dataSource.filter = JSON.stringify(this.filterValues);
        }
      );
    this.tradeFilter.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        trade => {
          if (trade === false) {
            this.filterValues.trade = '';
          } else {
            this.filterValues.trade = trade.toString();
          }
          this.dataSource.filter = JSON.stringify(this.filterValues);
        }
      );
    this.collectionFilter.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        collection => {
          if (collection === false) {
            this.filterValues.collection = '';
          } else {
            this.filterValues.collection = collection.toString();
          }
          this.dataSource.filter = JSON.stringify(this.filterValues);
        }
      );
    this.wishlistFilter.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        wishlist => {
          if (wishlist === false) {
            this.filterValues.wishlist = '';
          } else {
            this.filterValues.wishlist = wishlist.toString();
          }
          this.dataSource.filter = JSON.stringify(this.filterValues);
        }
      );
    this.dataSource.filterPredicate = this.createFilter();
  }

  public applyFilter(filter) {
    this.globalFilter = filter;
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  createFilter() {
    const filterFunction = (data: ComicsFilter, filter: string): boolean => {
      let globalMatch = !this.globalFilter;
      if (this.globalFilter) {
        // search all text fields
        globalMatch = data.universe.toString().trim().toLowerCase().indexOf(this.globalFilter.toLowerCase()) !== -1
          || data.series.toString().trim().toLowerCase().indexOf(this.globalFilter.toLowerCase()) !== -1
          || data.title.toString().trim().toLowerCase().indexOf(this.globalFilter.toLowerCase()) !== -1
          || data.publisher.toString().trim().toLowerCase().indexOf(this.globalFilter.toLowerCase()) !== -1
          || data.issue.toString().trim().toLowerCase().indexOf(this.globalFilter.toLowerCase()) !== -1;
      }

      if (!globalMatch) {
        return;
      }
      const searchTerms = JSON.parse(filter);
      return data.universe.trim().toLowerCase().indexOf(searchTerms.universe.toLowerCase()) !== -1
        && data.series.trim().toString().toLowerCase().indexOf(searchTerms.series.toLowerCase()) !== -1
        && data.title.trim().toLowerCase().indexOf(searchTerms.title.toLowerCase()) !== -1
        && data.publisher.trim().toLowerCase().indexOf(searchTerms.publisher.toLowerCase()) !== -1
        && data.issue.trim().toLowerCase().indexOf(searchTerms.issue.toLowerCase()) !== -1
        && data.sell.toString().trim().toLowerCase().indexOf(searchTerms.sell.toLowerCase()) !== -1
        && data.trade.toString().trim().toLowerCase().indexOf(searchTerms.trade.toLowerCase()) !== -1
        && data.collection.toString().trim().toLowerCase().indexOf(searchTerms.collection.toLowerCase()) !== -1
        && data.wishlist.toString().trim().toLowerCase().indexOf(searchTerms.wishlist.toLowerCase()) !== -1;

    };
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    return filterFunction;
  }

  shuffle(list) {
    return list.reduce((p, n) => {
      const size = p.length;
      const index = Math.trunc(Math.random() * (size - 1));
      p.splice(index, 0, n);
      return p;
    }, []);
  }

  async goToViewProduct(chosenComic) {
    await this.store.dispatch(selectedComic({ id: chosenComic.id }));
    await this.store.dispatch(goToUserProfile({ user: chosenComic.owner }));
    this.store.dispatch(setProduct({ product: 'comic' }));
    this.router.navigateByUrl("/product")
  }

  async clearFilters() {
    this.dataSource.filter = '';
    this.filterValues.universe = '';
    this.filterValues.series = '';
    this.filterValues.title = '';
    this.filterValues.publisher = '';
    this.filterValues.issue = '';
    this.filterValues.sell = '';
    this.filterValues.collection = '';
    this.filterValues.wishlist = '',
      this.filterValues.trade = '';
    await this.globalSearch.reset();
    await this.table.reset();
  }
}
