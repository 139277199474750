import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import * as crypto from "crypto-js";
import {
  addItemToCart,
  decreaseQuantity,
  removeItemFromCart,
  emptyCart
} from '../../redux/actions/cart.actions';
import { setRoute } from 'src/app/redux/actions/router.actions';
import { Router } from '@angular/router';
import { MatSnackBar, MatDialog } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { StripeChargeService } from '../../services/stripe.service';
import { STRIPEID } from '../../../environments/environment.prod';
import { SuccessCheckoutDialogComponent } from 'src/app/dialogs/success-checkout-dialog/success-checkout-dialog.component';

interface AppState {
  cart: any;
  auth: any;
}

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        state('invisible', style({ opacity: 0 })),
        style({ opacity: 0 }),
        animate(800, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class CartComponent implements OnInit {
  authUser: any;
  cart: any;
  cartLength: number;
  private onDestroy$ = new Subject<void>();
  // Input Values
  firstName;
  lastName;
  streetAddress;
  city;
  state;
  zip;
  cc;
  expDate;
  cvc;
  // Validation Control
  firstNameError;
  lastNameError;
  streetAddressError;
  cityError;
  stateError;
  zipError;
  ccError;
  expDateError;
  cvcError;
  // Credit Card NPM properties
  messages = { validDate: 'valid\ndate', monthYear: 'mm/yyyy' };
  placeholders = { number: '•••• •••• •••• ••••', name: 'Full Name', expiry: '••/••', cvc: '•••' };
  masks: any;
  formatting = true;
  debug = true;
  public loading = false;

  total: any;
  createdCharge = false;
  shipping;
  shippingInfo;

  constructor(
    private store: Store<AppState>,
    private matSnackbar: MatSnackBar,
    private router: Router,
    private mdDialog: MatDialog,
    private fb: FormBuilder,
    private ss: StripeChargeService) {
    this.store
      .select('cart')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        this.cart = res;
        this.cartLength = res.length;
        this.shippingInfo = res.shippingCost;
      });
    this.store.select('auth').pipe(takeUntil(this.onDestroy$)).subscribe(res => {
      this.authUser = res;
    });
  }

  handler: any = null;

  ngOnInit() {
    this.store.dispatch(setRoute({ route: this.router.url }));
    this.totalShipping();
    window.scrollTo(0, 0);
  }

  removeQuantity = itemId => {
    this.store.dispatch(decreaseQuantity({ productId: itemId }));
  }

  addQuantity = item => {
    this.store.dispatch(addItemToCart({ product: item, shipping: item }));
  }

  totalPrice() {
    let total = 0;
    for (let data of this.cart) {
      total += data.price * data.quantityInCart;
    }
    return this.formatPrice(total);
  }

  formatPrice(value) {
    const grandTotal = (value / 1);
    return grandTotal.toFixed(2);
  }

  totalShipping() {
    let total = 0;
    for (let data of this.cart) {
      total += data.shippingCost;
    }
    return this.formatShipping(total);
  }

  formatShipping(value) {
    const grandTotal = (value / 1);
    return grandTotal.toFixed(2);
  }

  grandTotal() {
    let price = 0;
    let shipping = 0;
    for (let data of this.cart) {
      price += data.price * data.quantityInCart;
      shipping += data.shippingCost;
    }
    const grandTotal = this.formatShipping(price + shipping);
    return this.formatGrandTotal(grandTotal);
  }

  formatGrandTotal(value) {
    const grandTotal = (value / 1);
    return grandTotal.toFixed(2);
  }

  removeItemFromCart = itemId => {
    this.store.dispatch(removeItemFromCart({ productId: itemId.id }));
    if (itemId.title) {
      this.matSnackbar.open(
        `You just removed ${itemId.title} from your cart.`,
        'OK',
        {
          panelClass: ['cvSnack']
        }
      );
    }
    if (itemId.name) {
      this.matSnackbar.open(
        `You just removed ${itemId.name} from your cart.`,
        'OK',
        {
          panelClass: ['cvSnack']
        }
      );
    }
  }

  goToSuccess(event) {
    const dialogRef = this.mdDialog.open(SuccessCheckoutDialogComponent, {
      data: event,
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      disableClose: true
    });
    dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe(result => {
      result.cart.map(async item => {
        if (result) {
          this.store.dispatch(removeItemFromCart({ productId: item.id }));
        }
      });
    });
  }

  pay = async () => {
    // Validation to make sure every input has been filled out.
    this.ccError = this.validate(this.cc);
    this.firstNameError = this.validate(this.firstName);
    this.lastNameError = this.validate(this.lastName);
    this.expDateError = this.validate(this.expDate);
    this.cvcError = this.validate(this.cvc);
    this.streetAddressError = this.validate(this.streetAddress);
    this.cityError = this.validate(this.city);
    this.stateError = this.validate(this.state);
    this.zipError = this.validate(this.zip);

    if (
      this.ccError ||
      this.firstNameError ||
      this.lastNameError ||
      this.expDateError ||
      this.cvcError ||
      this.streetAddressError ||
      this.cityError ||
      this.stateError ||
      this.zipError
    ) {
      return;
    }

    this.loading = true;
    let { cart, authUser, ss, firstName, lastName, streetAddress, city, state, zip, cc, expDate, cvc } = this;
    let date = expDate.split("/");
    const cardNum = cc.replace(/[\s\t]/g, "");
    const card = {
      number: parseInt(cardNum),
      exp_month: parseInt(date[0]),
      exp_year: parseInt(date[1]),
      cvc: parseInt(cvc)
    };

    let encrypted = crypto.AES.encrypt(JSON.stringify(card), "thisisasecret").toString();


    const buyer = {
      id: authUser.id,
      firstName,
      lastName,
      streetAddress,
      city,
      state,
      zip
    };
    try {
      ss.checkout(encrypted, buyer, JSON.stringify(cart)).subscribe((res: any) => {
        if (res && !res.err) {
          this.loading = false;
          this.goToSuccess(this);
        }
      });
    } catch (err) {
      throw new Error(err)
    }
  }

  handleFirstName(val) {
    this.firstNameError = this.validate(val);
    if (this.firstNameError) {
      return this.firstName = '';
    }
    this.firstName = val;
  }

  handleLastName(val) {
    this.lastNameError = this.validate(val);
    if (this.lastNameError) {
      return this.lastName = '';
    }
    this.lastName = val;
  }

  handleStreetAddress(val) {
    this.streetAddressError = this.validate(val);
    if (this.streetAddressError) {
      return this.streetAddress = '';
    }
    this.streetAddress = val;
  }

  handleCity(val) {
    this.cityError = this.validate(val);
    if (this.cityError) {
      return this.city = '';
    }
    this.city = val;
  }

  handleState(val) {
    this.stateError = this.validate(val);
    if (this.stateError) {
      return this.state = '';
    }
    this.state = val;
  }

  handleZip(val) {
    this.zipError = this.validate(val);
    if (this.zipError) {
      return this.zip = '';
    }
    this.zip = val;
  }

  handleCc(val) {
    this.ccError = this.validate(val);
    if (this.ccError) {
      return this.cc = '';
    }
    this.cc = val;
  }

  handleExpDate(val) {
    this.expDateError = this.validate(val);
    if (this.expDateError) {
      return this.expDate = '';
    }
    this.expDate = val;
  }

  handleCvc(val) {
    this.cvcError = this.validate(val);
    if (this.cvcError) {
      return this.cvc = '';
    }
    this.cvc = val;
  }

  validate(legit: any): any {
    if (!legit) {
      return 'Required';
    }
    return '';
  }
}
