import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { FormBuilder } from "@angular/forms";
import { User } from "src/app/models/user";
import { Observable, Subject } from "rxjs";
import { Store } from "@ngrx/store";
import {
  trigger,
  transition,
  style,
  animate,
  state,
} from "@angular/animations";
import { addItemToCart } from "../../redux/actions/cart.actions";
import { Router } from "@angular/router";
import { EditComicDialogComponent } from "../edit-comic-dialog/edit-comic-dialog.component";
import { selectedComic } from "src/app/redux/actions/comic.actions";
import { EditComicCoverDialogComponent } from "../edit-comic-cover-dialog/edit-comic-cover-dialog.component";
import { takeUntil } from "rxjs/operators";
import { ComicService } from "src/app/services/comic.service";
import { ConfirmComicDialogComponent } from "../confirm-comic-dialog/confirm-comic-dialog.component";
import { SellSimilarComicDialogComponent } from "../sell-similar-comic-dialog/sell-similar-comic-dialog.component";
import { AddWishlistComicDialogComponent } from "../add-wishlist-comic-dialog/add-wishlist-comic-dialog.component";
import { setProduct } from "src/app/redux/actions/product.actions";

interface AppState {
  userProfile: User;
  authUser: User;
  comics: any;
  auth: any;
}

@Component({
  selector: "app-view-product-dialog",
  templateUrl: "./view-product-dialog.component.html",
  styleUrls: ["./view-product-dialog.component.scss"],
  animations: [
    trigger("fade", [
      transition("void => *", [
        style({ opacity: 0 }),
        animate(800, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        state("invisible", style({ opacity: 0 })),
        style({ opacity: 0 }),
        animate(800, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ViewProductDialogComponent {
  public user: Observable<User>;
  public authUser;
  public picture;
  public comic;

  public selectedImage: any;
  public authId;

  private onDestroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ViewProductDialogComponent>,
    private mdDialog: MatDialog,
    public comicService: ComicService,
    public form: FormBuilder,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.store
      .select("comics")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        this.comic = res.selectedComic;
      });
    this.user = this.store.select("userProfile");
    this.store.select("auth").subscribe((res) => {
      if (res) {
        this.authUser = res.id;
      }
    });
    this.selectedImage = this.data.images[0];
    if (!this.authUser) {
      this.authUser = "GuestUser";
    }
  }

  async goToUsersProfile(selectedUser) {
    this.router.navigateByUrl(`/profile/${selectedUser.username}`);
    this.close();
  }

  close() {
    this.dialogRef.close();
  }

  closeAndUpdate(evt) {
    this.dialogRef.close(evt);
  }

  goToViewProduct() {
    this.dialogRef.close();
    this.store.dispatch(selectedComic({ id: this.comic.id }));
    this.store.dispatch(setProduct({ product: "comic" }));
    this.router.navigateByUrl("/product");
  }

  goToEditComics() {
    const dialogRef = this.mdDialog.open(EditComicDialogComponent, {
      data: this.comic,
      autoFocus: false,
      panelClass: "custom-dialog-container",
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result) {
          this.closeAndUpdate(result);
        }
      });
  }

  goToSellSimilarComics() {
    const dialogRef = this.mdDialog.open(SellSimilarComicDialogComponent, {
      data: this.comic,
      autoFocus: false,
      panelClass: "custom-dialog-container",
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result) {
          this.closeAndUpdate(result);
        }
      });
  }

  goToWishlistComics() {
    const dialogRef = this.mdDialog.open(AddWishlistComicDialogComponent, {
      data: this.comic,
      autoFocus: false,
      panelClass: "custom-dialog-container",
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result) {
          this.closeAndUpdate(result);
        }
      });
  }

  goToUpdatePhoto() {
    const dialogRef = this.mdDialog.open(EditComicCoverDialogComponent, {
      data: this.comic,
      autoFocus: false,
      panelClass: "custom-dialog-container",
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {});
  }

  goToConfirmDelete() {
    const dialogRef = this.mdDialog.open(ConfirmComicDialogComponent, {
      data: this.comic,
      autoFocus: false,
      panelClass: "custom-dialog-container",
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        this.closeAndUpdate(result);
      });
  }

  handleSelectedImage = (image) => {
    this.selectedImage = image;
  };
}
