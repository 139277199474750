import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { User } from 'src/app/models/user';

interface AppState {
  auth: User;
}

@Component({
  selector: 'app-avatar-picture',
  templateUrl: './avatar-picture.component.html',
  styleUrls: ['./avatar-picture.component.scss']
})
export class AvatarPictureComponent implements OnInit {
  @Input() user;

  initials: string;

  constructor(private store: Store<AppState>) {
    this.user = this.store.select('auth');
  }

  ngOnInit() {
    const first = this.user.firstName.split('')[0];
    const last = this.user.lastName.split('')[0];
    this.initials = `${first}${last}`;
  }
}
