import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';
import { FormBuilder } from '@angular/forms';
import { CollectibleService } from 'src/app/services/collectible.service';
import { User } from 'src/app/models/user';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { takeUntil } from 'rxjs/operators';
import { STRIPEID } from '../../../environments/environment.prod';
import { comicCondition } from 'src/app/constants/comicCondition.constant';

interface AppState {
  auth: User;
}

@Component({
  selector: 'app-sell-similar-collectible-dialog',
  templateUrl: './sell-similar-collectible-dialog.component.html',
  styleUrls: ['./sell-similar-collectible-dialog.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(250, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        state('invisible', style({ opacity: 1 })),
        style({ opacity: 0 }),
        animate(250, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class SellSimilarCollectibleDialogComponent implements OnInit {
  public user: Observable<User>;
  public currentAuthUser;
  public id;

  public universe: string;
  public name: string;
  public brand: string;
  public series: string;
  public year: string;
  public upc: string;
  public buildFigure: string;
  public variation: string;
  public accessories: string;
  public condition;
  public file;
  public files;

  public savedUniverse: string;
  public savedName: string;
  public savedBrand: string;
  public savedSeries: string;
  public savedYear: string;

  public universeError: string;
  public nameError: string;
  public brandError: string;
  public conditionError: string;
  public priceError: string;
  public quantityError: string;
  public fileError: string;

  public loading = false;

  public selling;
  public collection;
  public trade;
  public wishlist;

  public price: any;
  public quantity: string;

  public isBuildFigure;
  public hasVariation;
  public hasAccessories;
  public hasUpc;
  public selectedImage: any;

  public collectibleCondition;

  public config;
  public fileLockedIn;

  public usersCut;
  public collectaverseCut;

  private onDestroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SellSimilarCollectibleDialogComponent>,
    public auth: AuthService,
    public form: FormBuilder,
    private store: Store<AppState>,
    public collectibleService: CollectibleService,
    private matSnackbar: MatSnackBar
  ) {
    this.store.select('auth').pipe(takeUntil(this.onDestroy$)).subscribe(res => (this.currentAuthUser = res));
  }

  ngOnInit() {
    this.fileLockedIn = false;
    this.config = STRIPEID;
    this.selling = false;
    this.collection = false;
    this.trade = false;
    this.wishlist = false;

    this.savedUniverse = this.data.universe;
    this.savedName = this.data.name;
    this.savedBrand = this.data.brand;
    this.savedSeries = this.data.series;
    this.savedYear = this.data.year;
    this.selectedImage = this.data.images[0];
    this.collectibleCondition = comicCondition;
  }

  handleSelectedImage = image => {
    this.selectedImage = image;
  }

  /* || FORM CONTROL || */

  handleUniverse(val) {
    this.universeError = this.validate(val);
    if (this.universeError) {
      return this.savedUniverse = '';
    }
    this.savedUniverse = val;
  }

  handleName(val) {
    this.nameError = this.validate(val);
    if (this.nameError) {
      return this.savedName = '';
    }
    this.savedName = val;
  }

  handleBrand(val) {
    this.brandError = this.validate(val);
    if (this.brandError) {
      return this.savedBrand = '';
    }
    this.savedBrand = val;
  }

  handleSeries(val) {
    this.savedSeries = val;
  }

  handleYear(val) {
    this.savedYear = val;
  }

  handleUpc(val) {
    this.upc = val;
  }

  handleBuildFigure(val) {
    this.buildFigure = val;
  }

  handleVariation(val) {
    this.variation = val;
  }

  handleAccessories(val) {
    this.accessories = val;
  }

  handleCondition(val) {
    this.condition = val;
  }

  handleFile(val) {
    this.file = val;
    this.fileLockedIn = true;
  }

  handlePriceQuantity(evt, name) {
    const error = `${name}Error`;
    this[error] = this.validate(evt);

    if (this[error]) {
      return (this[name] = null);
    }
    this[name] = evt;
    if (name === 'price') {
      const x = (3.5 / 100) * this.price;
      this.collectaverseCut = x.toFixed(2);
      this.usersCut = this.price - this.collectaverseCut;
    }
  }

  handleSelling(event) {
    if (event) {
      this.selling = event;
    }
  }

  handleCollection(event) {
    if (event) {
      this.collection = event;
    }
  }

  handleTrading(event) {
    if (event) {
      this.trade = event;
    }
  }

  /* || INPUT VALIDATION || */

  validate(legit: any): any {
    if (!legit) {
      return 'Required';
    }
    return '';
  }

  validateFile(legit: any): any {
    if (!legit) {
      return 'A picture is required to create a comic';
    }
    return '';
  }

  /* || END INPUT VALIDATION || */

  close() {
    this.dialogRef.close();
  }

  async submit() {
    this.id = this.currentAuthUser.id;
    this.universeError = this.validate(this.savedUniverse);
    this.nameError = this.validate(this.savedName);
    this.brandError = this.validate(this.savedBrand);
    this.fileError = this.validateFile(this.file);

    if (this.selling) {
      this.conditionError = this.validate(this.condition);
      this.priceError = this.validate(this.price);
      this.quantityError = this.validate(this.quantity);
    }
    // If any of these inputs are blank it won't complete the call
    if (
      this.nameError ||
      this.brandError ||
      this.universeError ||
      this.fileError ||
      (this.selling &&
        (this.conditionError || this.priceError || this.quantityError))
    ) {
      return this.matSnackbar.open(
        `Please recheck the form. A REQUIRED field in red has been identified`,
        'OK',
        {
          duration: 5000,
          panelClass: ['cvSnack']
        }
      );
    }
    this.loading = true;
    await this.collectibleService.addCollectible(
      this.savedUniverse,
      this.savedName,
      this.savedBrand,
      this.savedSeries,
      this.savedYear,
      this.upc,
      this.buildFigure,
      this.variation,
      this.accessories,
      this.condition,
      this.selling,
      this.trade,
      this.collection,
      this.wishlist,
      this.price,
      this.quantity,
      this.file
    ).pipe(takeUntil(this.onDestroy$))
      .subscribe(async res => {
        await this.auth.updatePoints(this.currentAuthUser.rewards + 5, this.currentAuthUser.id);
        this.matSnackbar.open(
          `You successfully added the collectible "${this.data.name}" to your profile page`,
          'OK',
          {
            duration: 5000,
            panelClass: ['cvSnack']
          }
        );
        this.close();
      });
  }

}
